import { Box, Flex, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ViewSharedDocumentDto } from "../../../autogen/bff-api";
import { DownloadDocumentIconButton } from "../DownloadDocumentIconButton/DownloadDocumentIconButton";

interface Props {
  documents: ViewSharedDocumentDto[];
}

export const SharedDocumentsTable = ({ documents }: Props) => {
  const { t } = useTranslation();
  return (
    <Box borderRadius={10} border="1px solid" borderColor="smBorder" pb="2.5">
      <Table variant="simple">
        <Tbody>
          {documents.map((e) => (
            <Tr key={e.id}>
              <Td>{e.name}</Td>
              <Td>
                <Flex justifyContent="end">
                  <DownloadDocumentIconButton documentId={e.id} />
                </Flex>
              </Td>
            </Tr>
          ))}
          {documents.length === 0 && (
            <Tr>
              <Td fontSize={"sm"} colSpan={3} width={"100%"} textAlign="center">
                {t("No documents have been uploaded")}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};
