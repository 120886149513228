import { Input } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";

export const convertISOToDate = (date?: string | null) => (date ? moment(date).format("YYYY-MM-DD") : "");
const convertDateToISO = (date?: string) => moment(date).toISOString();

export const ISODateSelector = ({
  defaultDate,
  date,
  isDisabled,
  onChange,
  autoFocus,
  onBlur,
}: {
  defaultDate?: string;
  date?: string | null;
  onChange: (isoDate?: string) => Promise<void> | void;
  isDisabled?: boolean;
  autoFocus?: boolean;
  onBlur?: () => void;
}) => {
  const [internalDate, setInternalDate] = useState<string>(convertISOToDate(defaultDate) || "");

  return (
    <Input
      fontSize="md"
      type="date"
      isDisabled={isDisabled}
      autoFocus={autoFocus}
      value={convertISOToDate(date !== undefined ? date : internalDate)}
      onChange={(e) => {
        const date = e.target.value;
        setInternalDate(date);
        onChange(convertDateToISO(date ? date : undefined));
      }}
      onBlur={onBlur}
      width="60"
      border="1px solid"
      borderColor="smBorder"
      backgroundColor="smBackground"
      rounded="md"
      p="4"
    />
  );
};
