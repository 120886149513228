import { Grid, GridItem, Heading, Icon, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { FaBell, FaBuilding, FaFileImport } from "react-icons/fa";
import { Layout } from "../../common/layout/Layout";
import { useSub } from "../../common/subscription/useSub";
import { SettingsTab, useQueryParams } from "../../common/useQueryParams";
import { CONTENT_WIDTH } from "../../config";
import { DocumentTemplates } from "./DocumentTemplates";
import { InternalParties } from "./InternalParties";
import { NotificationSettings } from "./Notifications/NotificationSettings";

const tabs: SettingsTab[] = ["notifications", "document-templates", "internal-parties"];
const tabToIndex = (tab: SettingsTab): number => tabs.indexOf(tab);
const indexToQueryValue = (index: number): SettingsTab => tabs[index];

export const Settings = () => {
  const sub = useSub();

  const [tabIndex, setTabIndex] = useState(0);
  const params = useQueryParams();

  useEffect(() => {
    const tab = params.getSettingsTab();
    if (!tab) return;
    setTabIndex(tabToIndex(tab));
  }, [params]);

  return (
    <Layout>
      <Grid
        height="full"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"minmax(0, 1fr)"}
        templateAreas={`"leftContent content rightContent"`}
      >
        <GridItem height="full" area="content" px="2" pt="5">
          <Heading width="full" as="h2" fontSize="3xl" fontWeight="semibold" pb="5">
            {t("Organization Settings")}
          </Heading>
          <Tabs
            colorScheme="cyan"
            index={tabIndex}
            onChange={(index) => {
              setTabIndex(index);
              params.setSelectedSettingsTab(indexToQueryValue(index));
            }}
          >
            <TabList>
              <Tab>
                <Icon as={FaBell} mr="2" />
                {t("Notification Settings")}
              </Tab>
              <Tab isDisabled={!sub.hasDocumentTemplateAccess}>
                <Icon as={FaFileImport} mr="2" />
                {t("Document Templates")}
              </Tab>
              <Tab>
                <Icon as={FaBuilding} mr="2" />
                {t("Internal Parties")}
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <NotificationSettings />
              </TabPanel>
              <TabPanel>
                <DocumentTemplates />
              </TabPanel>
              <TabPanel>
                <InternalParties />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </GridItem>
      </Grid>
    </Layout>
  );
};
