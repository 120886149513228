import { Button, Flex, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaCubes, FaInfo, FaPaperPlane } from "react-icons/fa";
import { useQueryParams } from "../../../common/useQueryParams";

interface Props {
  navigateToDetails: () => void;
  navigateToReview: () => void;
  navigateToProducts?: () => void;
}

export const Navigation = ({ navigateToDetails, navigateToReview, navigateToProducts }: Props) => {
  const params = useQueryParams();
  const currentStep = params.get("step");
  const { t } = useTranslation();
  return (
    <Flex w={"100%"} flexDirection={"column"} pt="4" pl="2">
      <Button
        leftIcon={<Icon as={FaInfo} w="15px" h="15px" />}
        colorScheme="teal"
        variant={currentStep === "details" ? "solid" : "outline"}
        size={"xs"}
        mr={1}
        w="100%"
        display={"flex"}
        justifyContent="start"
        onClick={navigateToDetails}
      >
        {t("Details")}
      </Button>
      {navigateToProducts && (
        <Button
          leftIcon={<Icon as={FaCubes} w="15px" h="15px" />}
          colorScheme="teal"
          variant={currentStep === "products" ? "solid" : "outline"}
          size={"xs"}
          marginRight={1}
          w="100%"
          display={"flex"}
          justifyContent="start"
          mt="1.5"
          onClick={navigateToProducts}
        >
          {t("Products")}
        </Button>
      )}
      <Button
        leftIcon={<Icon as={FaPaperPlane} w="15px" h="15px" />}
        colorScheme="teal"
        variant={currentStep === "review" ? "solid" : "outline"}
        size={"xs"}
        marginRight={1}
        w="100%"
        display={"flex"}
        justifyContent="start"
        mt="1.5"
        onClick={() => {
          navigateToReview();
        }}
      >
        {t("Review & Send")}
      </Button>
    </Flex>
  );
};
