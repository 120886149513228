import { bffApi } from "../../../autogen/bff-api";
import { useApiError } from "../../errors/useApiError";
import { useAppDispatch } from "../../redux/hooks";

export const useDocumentDownloader = () => {
  const dispatch = useAppDispatch();
  const displayer = useApiError();

  const downloadSignedDocument = async (documentId: string): Promise<string | null> => {
    const postRequestResponse = dispatch(
      bffApi.endpoints.getSignedDocumentDownloadLink.initiate({
        docId: documentId,
      })
    );
    postRequestResponse.unsubscribe();
    const postRequestResult = await postRequestResponse;

    if ("data" in postRequestResult && postRequestResult.data) {
      return postRequestResult.data.url;
    } else {
      displayer.trigger(postRequestResult.error);
      return null;
    }
  };

  const downloadDocument = async (documentId: string): Promise<string | null> => {
    const postRequestResponse = dispatch(
      bffApi.endpoints.getDownloadLink.initiate({
        docId: documentId,
      })
    );
    postRequestResponse.unsubscribe();
    const postRequestResult = await postRequestResponse;

    if ("data" in postRequestResult && postRequestResult.data) {
      return postRequestResult.data.url;
    } else {
      displayer.trigger(postRequestResult.error);
      return null;
    }
  };

  const getDownloadLink = async (documentId: string, isSignedDocument: boolean): Promise<string | null> => {
    if (isSignedDocument) {
      return await downloadSignedDocument(documentId);
    } else {
      return await downloadDocument(documentId);
    }
  };

  return {
    getDownloadLink,
  };
};
