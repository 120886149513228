import { Text } from "@chakra-ui/react";
import React from "react";
import { ContractTaskDto } from "../../../../autogen/bff-api";
import { displayPersonName } from "../sharing/AddExternalParticipantModal";
interface Props {
  task: ContractTaskDto;
  size?: "sm" | "md" | "lg";
}

export const ContractTaskResponsibleParty: React.FC<Props> = ({ task }) => {
  switch (task.responsibleParty) {
    case "Organization": {
      const organization = task.responsibleOrganization;
      if (!organization) {
        throw new Error("Should never happen");
      }
      return <Text fontSize={"sm"}>{organization.name}</Text>;
    }
    case "PersonInOrganization": {
      const person = task.responsiblePerson;
      if (!person) {
        throw new Error("Should never happen");
      }
      return <Text fontSize={"sm"}>{displayPersonName(person)}</Text>;
    }
  }
};
