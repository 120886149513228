import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { EmailInviteContextDto } from "../../../../autogen/bff-api";
import { useAuthState } from "../../../../common/auth/useAuthState";
import { SignupAsOrgForm } from "./SignupAsOrgForm";
import { SignupAsPersonForm } from "./SignupAsPersonForm";

interface Props {
  asOrg: boolean;
  isOpen: boolean;
  onClose: () => void;
  invitationContext: EmailInviteContextDto | null;
}

export const SignupDrawer = ({ asOrg, isOpen, onClose, invitationContext }: Props) => {
  const authState = useAuthState();
  const { t } = useTranslation();
  const getBody = (): React.ReactElement => {
    switch (authState.type) {
      case "LoggedInWithOrgContext":
      case "LoggedIn":
        throw Error("User already logged in");
      case "LoggedOut":
        if (asOrg) {
          return <SignupAsOrgForm invitationContext={invitationContext} />;
        } else {
          return <SignupAsPersonForm invitationContext={invitationContext} />;
        }
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} closeOnOverlayClick={false} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{t("Sign up")}</DrawerHeader>
        <DrawerBody>{getBody()}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
