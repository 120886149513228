import { Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Spinner } from "@chakra-ui/react";
import { useState } from "react";
import { RemoveDataFieldButton } from "../../../pages/contracts/edit/details/optional/RemoveDataFieldButton";
import { DateSelector } from "./DateSelector";

interface Props {
  id: string;
  title: string;
  helperText: string;
  defaultDate?: string;
  date?: string | null;
  errorMessage: string | null;
  onChange: (date?: string) => Promise<void> | void;
  removeDataField?: () => Promise<void> | void;
  isRequiredButNotProvided: boolean;
  isDisabled?: boolean;
}

export const DateFormControl = ({
  id,
  title,
  helperText,
  defaultDate,
  date,
  errorMessage,
  onChange,
  removeDataField,
  isRequiredButNotProvided,
  isDisabled,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <FormControl id={id} isInvalid={errorMessage !== null} isRequired={isRequiredButNotProvided}>
      <FormLabel fontSize="sm">
        {title}
        {isLoading && <Spinner ml="5px" size="xs" />}
      </FormLabel>
      <Flex justify="space-between" align="center">
        <DateSelector
          defaultDate={defaultDate}
          date={date}
          isDisabled={isDisabled}
          onChange={async (date) => {
            setIsLoading(true);
            await onChange(date);
            setIsLoading(false);
          }}
        />
        {removeDataField && <RemoveDataFieldButton removeDataField={removeDataField} />}
      </Flex>
      <FormHelperText>{helperText}</FormHelperText>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
