import { Flex, GridItem, Skeleton } from "@chakra-ui/react";

export const EntityViewSkeleton = () => {
  return (
    <>
      <GridItem area={"title"} display={"flex"} flexDirection={"column"} px="2" pt="2">
        <Flex flexDirection={"column"} rowGap={"2"} justifyContent={"space-between"}>
          <Skeleton
            width={"60"}
            height="8"
            rounded="md"
            startColor="smBackgroundTertiary"
            endColor="smBackgroundSecondary"
          />
          <Skeleton
            width={"60"}
            height="6"
            rounded="md"
            startColor="smBackgroundTertiary"
            endColor="smBackgroundSecondary"
          />
        </Flex>
      </GridItem>
      <GridItem area="edit" px="2" pt="2">
        <Skeleton
          width={"full"}
          height={"8"}
          rounded="md"
          startColor="smBackgroundTertiary"
          endColor="smBackgroundSecondary"
        />
      </GridItem>
      <GridItem area={"content"} display={"flex"} flexDirection={"column"} px="2" pt="8">
        <Flex columnGap={"2"} pt="2">
          <Skeleton
            width={"32"}
            height="8"
            rounded="md"
            startColor="smBackgroundTertiary"
            endColor="smBackgroundSecondary"
          />
          <Skeleton
            width={"32"}
            height="8"
            rounded="md"
            startColor="smBackgroundTertiary"
            endColor="smBackgroundSecondary"
          />
          <Skeleton
            width={"32"}
            height="8"
            rounded="md"
            startColor="smBackgroundTertiary"
            endColor="smBackgroundSecondary"
          />
          <Skeleton
            width={"32"}
            height="8"
            rounded="md"
            startColor="smBackgroundTertiary"
            endColor="smBackgroundSecondary"
          />
          <Skeleton
            width={"32"}
            height="8"
            rounded="md"
            startColor="smBackgroundTertiary"
            endColor="smBackgroundSecondary"
          />
        </Flex>
        <Flex flexDirection={"column"} rowGap={"4"} pt="2">
          <Skeleton
            width={"full"}
            height="8"
            rounded="md"
            startColor="smBackgroundTertiary"
            endColor="smBackgroundSecondary"
          />
          <Skeleton
            width={"80%"}
            height="8"
            rounded="md"
            startColor="smBackgroundTertiary"
            endColor="smBackgroundSecondary"
          />
          <Skeleton
            width={"20%"}
            height="8"
            rounded="md"
            startColor="smBackgroundTertiary"
            endColor="smBackgroundSecondary"
          />
          <Skeleton
            width={"20%"}
            height="8"
            rounded="md"
            startColor="smBackgroundTertiary"
            endColor="smBackgroundSecondary"
          />
          <Skeleton
            width={"60%"}
            height="8"
            rounded="md"
            startColor="smBackgroundTertiary"
            endColor="smBackgroundSecondary"
          />
        </Flex>
      </GridItem>
    </>
  );
};
