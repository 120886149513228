import { createAsyncThunk } from '@reduxjs/toolkit'
import { authService } from '../../../../config'
import { getInitialAuthStateThunk } from './get-initial-auth-state-thunk'

export const logoutThunk = createAsyncThunk(
    'authState/signOut',
    async (props: void, { dispatch }): Promise<void> => {
        await authService.logout()
        dispatch(getInitialAuthStateThunk())
    }
)
