import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaRegTimesCircle } from "react-icons/fa";
import { SignedDocumentDto, useCancelSignedDocumentMutation } from "../../../../../autogen/bff-api";
import { useApiError } from "../../../../../common/errors/useApiError";
import { displayDateWithTime } from "../../../../../common/formatting/displayDateWithTime";
import { DocumentSignerStatusTag } from "./DocumentSignerStatusTag";

interface Props {
  contractId: string;
  signedDocument: SignedDocumentDto;
  onClose: () => void;
  onCancelled?: () => void | Promise<void>;
}

export const DocumentSignersModal = ({ contractId, signedDocument, onClose, onCancelled }: Props) => {
  const { t } = useTranslation();
  const toast = useToast();
  const displayer = useApiError();

  const [cancel, { isLoading }] = useCancelSignedDocumentMutation();

  return (
    <Modal isOpen={true} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>{t("Status for signers")}</Text>
          <Text fontSize="sm" fontWeight="normal">
            {signedDocument.deadline && `${t("Deadline")}: ${displayDateWithTime(signedDocument.deadline, t("at"))}`}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="6">
          <Flex border="1px solid" borderColor="smBorder" rounded="md" pb="2.5">
            <Table variant="simple" borderColor="red" size="sm">
              <Thead>
                <Tr>
                  <Th borderColor="smBorder">{t("Name")}</Th>
                  <Th borderColor="smBorder">{t("Email")}</Th>
                  <Th borderColor="smBorder">{t("Status")}</Th>
                  <Th borderColor="smBorder"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {signedDocument.signers.map((e) => {
                  return (
                    <Tr key={e.id}>
                      <Td borderColor="smBorder">{`${e.firstName} ${e.lastName}`}</Td>
                      <Td borderColor="smBorder">{e.email}</Td>
                      <Td borderColor="smBorder">
                        <DocumentSignerStatusTag status={e.status} />
                      </Td>
                      <Td borderColor="smBorder">
                        {e.statusTimestamp ? new Date(e.statusTimestamp).toLocaleString() : ""}
                      </Td>
                    </Tr>
                  );
                })}
                {signedDocument.signers.length === 0 && (
                  <Tr>
                    <Td colSpan={3} width={"100%"} textAlign="center" borderColor="smBorder">
                      {t("No signers found")}
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Flex>
          {!signedDocument.signingCompleted &&
            signedDocument.status !== "Canceled" &&
            signedDocument.status !== "Expired" && (
              <Flex justifyContent="end" pt="5">
                <Button
                  size="xs"
                  colorScheme="red"
                  rightIcon={<Icon as={FaRegTimesCircle} />}
                  isLoading={isLoading}
                  onClick={async () => {
                    if (!contractId) throw Error("Undefined contract id");
                    const res = await cancel({
                      contractId,
                      cancelSignedDocumentRequest: {
                        documentId: signedDocument.id,
                        reason: t("The signing order was cancelled by the contract owner") ?? "",
                      },
                    });
                    if ("error" in res) {
                      displayer.trigger(res.error);
                      return;
                    }
                    onCancelled?.();
                    toast({ variant: "subtle", status: "info", title: t("Cancelled") });
                    onClose();
                  }}
                >
                  {t("Cancel signing")}
                </Button>
              </Flex>
            )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
