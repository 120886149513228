import { Td, Text, Tr } from "@chakra-ui/react";
import { ContractTaskDto } from "../../../autogen/bff-api";
import { displayDate } from "../../../common/formatting/displayDate";
import { displayWrappedWithEllipsis } from "../../../common/formatting/displayWrappedWithEllipsis";
import { ContractTaskResponsibleParty } from "../view-single/tasks/ContractTaskResponsibleParty";
import { ContractTaskStatusTag } from "../view-single/tasks/ContractTaskStatusTag";
import { ContractOwner } from "./ContractOwner";
import { ContractTitle } from "./ContractTitle";

interface Props {
  task: ContractTaskDto;
  showTask: (id: string) => void;
}

export const TaskRow = ({ task, showTask }: Props) => {
  return (
    <Tr
      key={task.id}
      _hover={{ backgroundColor: "smBackgroundSecondary", cursor: "pointer" }}
      onClick={() => showTask(task.id)}
    >
      <Td>
        <Text fontSize={"sm"}>{displayWrappedWithEllipsis(task.title, 40)}</Text>
      </Td>
      <Td>
        <Text fontSize={"sm"}>{displayDate(task.dueDate)}</Text>
      </Td>
      <Td>
        <ContractTaskResponsibleParty task={task} />
      </Td>
      <Td>
        <ContractTitle task={task} />
      </Td>
      <Td>
        <ContractOwner task={task} />
      </Td>
      <Td>
        <ContractTaskStatusTag status={task.status} />
      </Td>
    </Tr>
  );
};
