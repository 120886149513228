import { Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ContractListItemDto } from "../../../autogen/bff-api";
import { displayWrappedWithEllipsis } from "../../../common/formatting/displayWrappedWithEllipsis";
import { urls } from "../../../urls";
import { requireCounterparty } from "../../contracts/typeguards";
import { getCounterpartyName } from "../../contracts/view-all/ContractRow";
import { ProfileImageRounded } from "../../sourcing-events/buyer/view-all/ProfileImageRounded";
import styles from "./Contract.module.css";

interface Props {
  contract: ContractListItemDto;
}

export const Contract = ({ contract }: Props) => {
  const navigate = useNavigate();

  const counterparty = requireCounterparty(contract);
  const name = getCounterpartyName(counterparty);

  return (
    <Flex
      key={contract.id}
      w="100%"
      borderRadius={"6px"}
      mt="15px"
      padding={"10px"}
      backgroundColor="smBackgroundSecondary"
      className={styles.contract}
      onClick={() => {
        navigate(urls.contracts.viewSingle.go(contract.id));
      }}
    >
      <Grid
        w="100%"
        key={contract.id}
        gridTemplateAreas={`
            "title counterparty"
            "sub counterparty"
            `}
        gridTemplateColumns={"1fr 200px"}
      >
        <GridItem area={"title"}>
          <Flex flex={3} alignItems={"center"} paddingLeft="10px">
            <Text fontSize={"md"} color="smMuted">
              {displayWrappedWithEllipsis(contract.title, 30)}
            </Text>
          </Flex>
        </GridItem>
        <GridItem area={"sub"}>
          <Flex flex={4} alignItems={"center"} paddingLeft="10px">
            <Text
              fontSize={"sm"}
              fontWeight={"light"}
              color="smMuted"
            >{`${contract.internalOwner.firstName} ${contract.internalOwner.lastName}`}</Text>
          </Flex>
        </GridItem>
        <GridItem area={"counterparty"} display={"flex"} justifyContent={"end"} alignItems={"center"}>
          <Flex>
            <Flex>
              <ProfileImageRounded
                name={contract.internalOwner.firstName}
                backgroundColor={"smPrimary"}
                width={"20px"}
                fontSize="xx-small"
              />
            </Flex>
            <Flex mt="-2px" ml="5px">
              <Text fontSize={"sm"} color="smMuted">
                {name}
              </Text>
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
    </Flex>
  );
};
