import { Button, useClipboard } from '@chakra-ui/react'
import React, { useState } from 'react'
import styles from './DebugPrint.module.css'

interface Props {
    data: unknown
}

export const DebugPrint: React.FC<Props> = ({ data }) => {
    const [copied, setCopied] = useState(false)
    const { onCopy, value } = useClipboard(JSON.stringify(data, null, 2))

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <strong>
                    Debug
                    <Button
                        size={'xs'}
                        colorScheme="green"
                        onClick={() => {
                            onCopy()
                            setCopied(true)
                            setTimeout(() => {
                                setCopied(false)
                            }, 3000)
                        }}
                        ml="5px"
                    >
                        {copied ? 'Copied!' : 'Copy'}
                    </Button>
                </strong>
            </div>
            <pre className={styles.pre}>{value}</pre>
        </div>
    )
}
