import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { ProjectDto } from "../../../../autogen/bff-api";
import { urls } from "../../../../urls";
import { ProfileImageRounded } from "../../../sourcing-events/buyer/view-all/ProfileImageRounded";

export const Projects = ({ projects }: { projects: ProjectDto[] }) => {
  const navigate = useNavigate();
  return (
    <Box padding="15px" w="100%">
      <Text fontWeight={"bold"} fontSize="sm" color="#808B9B">
        {t("Projects")}
      </Text>
      <Divider marginTop={"5px"} marginBottom={"5px"} />
      {projects.map((project) => (
        <Flex key={project.id} mt="13px" cursor="pointer" onClick={() => navigate(urls.projects.view.go(project.id))}>
          <Flex>
            <ProfileImageRounded name={project.name} width="30px" backgroundColor="blue.400" fontSize={"xs"} />
          </Flex>
          <Flex justifyContent={"center"} mt="-1px" ml="7px">
            <Text fontSize={"xl"} fontWeight="semibold">
              {project.name}
            </Text>
          </Flex>
        </Flex>
      ))}
      {!projects.length && <Text fontStyle={"italic"}>{t("Not provided")}</Text>}
    </Box>
  );
};
