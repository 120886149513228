import { Grid, GridItem, Heading, useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Layout } from "../../../common/layout/Layout";
import { CONTENT_WIDTH } from "../../../config";
import { ChangeCorporateTitle } from "./ChangeCorporateTitle";
import { ChangeEmail } from "./ChangeEmail";
import { ChangeFirstName } from "./ChangeFirstName";
import { ChangeLastName } from "./ChangeLastName";
import { ChangePhoneNumber } from "./ChangePhoneNumber";

export const EditPersonalProfile = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { t } = useTranslation();
  return (
    <Layout>
      <Grid
        height="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"1fr"}
        templateAreas={`
                "leftContent content rightContent"
                `}
      >
        <GridItem
          area={"content"}
          paddingLeft={isLargerThan768 ? "0px" : "10px"}
          paddingRight={isLargerThan768 ? "0px" : "10px"}
        >
          <Grid h="100%" gridTemplateColumns={"1fr"} gridTemplateRows={"70px 1fr"}>
            <GridItem display="flex" alignItems="center" borderBottom="1px solid" borderColor="smBorder">
              <Heading as="h2" size="lg" px="2">
                {t("Edit personal profile")}
              </Heading>
            </GridItem>
            <GridItem px="2">
              <ChangeFirstName />
              <ChangeLastName />
              <ChangeEmail />
              <ChangeCorporateTitle />
              <ChangePhoneNumber />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};
