import { createAsyncThunk } from "@reduxjs/toolkit";
import { bffApi, EmailInviteContextDto } from "../../../../autogen/bff-api";
import { AuthState, OrgDto } from "../../reducers/authStateReducer";
import { RootState } from "../../store";

export type CreateOrgError = "UnknownError";

export const createOrgThunk = createAsyncThunk(
  "authState/createOrg",
  async (
    props: {
      companyName: string;
      countryId: string;
      invitationContext: EmailInviteContextDto | null;
    },
    { dispatch, getState }
  ): Promise<AuthState> => {
    const state = (await getState()) as RootState;

    if (state.authState.state?.type !== "LoggedIn") {
      throw Error("Should never happen");
    }
    const orgResponse = dispatch(
      bffApi.endpoints.createOrganizationForMe.initiate({
        createOrgRequest: {
          companyName: props.companyName,
          countryId: props.countryId,
          industryIds: [],
          emailInviteContext: props.invitationContext ?? undefined,
        },
      })
    );
    orgResponse.reset();
    const orgResult = await orgResponse;
    if ("data" in orgResult) {
      return {
        type: "LoggedInWithOrgContext",
        me: state.authState.state.me,
        organizations: [orgResult.data] as OrgDto[],
        selectedOrg: orgResult.data as OrgDto,
      };
    } else {
      return {
        type: "LoggedIn",
        me: state.authState.state.me,
        organizations: [],
        invitationContext: state.authState.state.invitationContext,
        createOrgError: "UnknownError",
        isCreatingOrg: false,
      };
    }
  }
);
