import { Tag } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SignedDocumentStatus } from "../../../../../autogen/bff-api";

interface Props {
  status: SignedDocumentStatus;
  size?: "sm" | "md" | "lg";
}

export const SignedDocumentStatusTag = ({ status, size }: Props) => {
  const { t } = useTranslation();
  const getEventTagMeta = (
    status: SignedDocumentStatus
  ): {
    color: string;
    text: string;
  } => {
    switch (status) {
      case "Canceled":
        return {
          color: "red",
          text: t("Cancelled"),
        };
      case "Expired":
        return {
          color: "red",
          text: t("Expired"),
        };
      case "PartiallySigned":
        return {
          color: "orange",
          text: t("Unsigned"),
        };
      case "Signed":
        return {
          color: "green",
          text: t("Signed"),
        };
      case "Unsigned":
        return {
          color: "orange",
          text: t("Unsigned"),
        };
      case "WaitingForAttachments":
        return {
          color: "gray",
          text: t("Waiting for attachments"),
        };
    }
  };

  const tagMeta = getEventTagMeta(status);

  return (
    <Tag colorScheme={tagMeta.color} size={size} textAlign="center" paddingTop={"2px"} paddingBottom={"2px"}>
      {tagMeta.text}
    </Tag>
  );
};
