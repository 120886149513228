import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useUpdateMeMutation } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { useAppDispatch } from "../../../common/redux/hooks";
import { updateMe } from "../../../common/redux/reducers/authStateReducer";

interface FormValues {
  lastName: string;
}

export const ChangeLastName: React.FC = () => {
  const [editMe, { isLoading }] = useUpdateMeMutation();
  const { t } = useTranslation();
  const { me } = useLoggedInWithOrgContextState();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const displayer = useApiError();

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      lastName: me.lastName,
    },
  });

  const value = watch("lastName");

  const onSubmit = async (values: FormValues) => {
    setIsRefreshing(true);

    const response = await editMe({
      editMeRequest: {
        lastName: values.lastName,
      },
    });
    if ("data" in response) {
      dispatch(updateMe(response.data));
    } else {
      displayer.trigger(response.error);
    }
    setIsRefreshing(false);
    toast({ title: t("Last name has been updated!"), status: "success" });
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl mt="15px" isInvalid={errors.lastName !== undefined}>
          <FormLabel htmlFor={"lastName"}>{t("Last name")}</FormLabel>
          <Input
            id={"lastName"}
            type="text"
            {...register("lastName", {
              required: t("Please provide your last name") ?? "",
              minLength: {
                value: 2,
                message: t("Must be at least 2 characters long"),
              },
            })}
          />
          <FormErrorMessage>{errors.lastName && errors.lastName.message}</FormErrorMessage>
          <FormHelperText>{t("Please provide your last name")}</FormHelperText>
        </FormControl>
        {me.lastName !== value && (
          <Button
            mt="10px"
            leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
            variant={"solid"}
            colorScheme="teal"
            size={"sm"}
            isLoading={isLoading || isRefreshing}
            type="submit"
          >
            {t("Save change")}
          </Button>
        )}
      </form>
    </>
  );
};
