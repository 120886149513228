import { useToast } from "@chakra-ui/react";
import AwsS3 from "@uppy/aws-s3";
import Uppy, { UppyFile } from "@uppy/core";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { UploadDto, ViewDocumentAsOwnerDto, bffApi } from "../../../autogen/bff-api";
import { useApiError } from "../../errors/useApiError";
import { useAppDispatch } from "../../redux/hooks";
import { FileUpload } from "./FileRow";
import { DocumentUploadContext, useDocumentsUploader } from "./useDocumentsUploader";

export const useUppyUploader = ({
  entityType,
  entityId,
  reloadEntity,
}: {
  entityType: DocumentUploadContext;
  entityId: string;
  reloadEntity: () => void | Promise<void>;
}) => {
  const [uploads, setUploads] = useState<UploadDto[]>([]);
  const [documents, setDocuments] = useState<ViewDocumentAsOwnerDto[]>([]);
  const { t } = useTranslation();
  const trigger = useApiError().trigger;
  const [tempFiles, setTempFiles] = useState<UppyFile<Record<string, unknown>, Record<string, unknown>>[]>([]);
  const toast = useToast();
  const { createPostRequest } = useDocumentsUploader({ entityId, entityType, refetch: reloadEntity });
  const dispatch = useAppDispatch();

  const uppy = useMemo(() => {
    return new Uppy({
      autoProceed: true,
      onBeforeFileAdded(currentFile) {
        currentFile["id"] = uuid();
        return currentFile;
      },
    })
      .use(AwsS3, {
        id: "AwsS3",
        limit: 1,
        async getUploadParameters(file) {
          if (isFile(file.data)) {
            const response = await createPostRequest({
              id: file.id,
              name: file.name,
              file: file.data,
              extension: file.extension,
            });
            if ("data" in response) {
              return {
                url: response.data.url,
                method: "POST",
                fields: {
                  "x-amz-date": response.data.renamedXAmzDate,
                  "x-amz-signature": response.data.renamedXAmzSignature,
                  "x-amz-algorithm": response.data.renamedXAmzAlgorithm,
                  "x-amz-credential": response.data.renamedXAmzCredential,
                  policy: response.data.policy,
                  key: response.data.key,
                },
              };
            } else {
              trigger(response.error);
              throw Error("Failed to create post request");
            }
          } else {
            throw Error("Invalid file");
          }
        },
      })
      .on("file-added", (file) => {
        setTempFiles((files) => [...files, file]);
      })
      .on("complete", reloadEntity)
      .on("error", () => {
        toast({
          title: t("Upload failed!"),
          description: t("Something went wrong, please make sure the file is valid"),
          status: "error",
        });
      })
      .on("upload-error", (failedFile) => {
        if (failedFile) {
          setTempFiles((files) => files.filter((e) => e.id !== failedFile.id));
        }
        toast({
          title: t("Upload failed!"),
          description: t("Something went wrong, please make sure the file is valid"),
          status: "error",
        });
      })
      .on("restriction-failed", (failedFile) => {
        if (failedFile) {
          setTempFiles((files) => files.filter((e) => e.id !== failedFile.id));
        }
        toast({
          title: t("Upload failed!"),
          description: t("Did you try to upload the same file twice?"),
          status: "error",
        });
      });
  }, [createPostRequest, reloadEntity, t, toast, trigger]);

  useEffect(() => {
    return () => {
      uppy.close();
    };
  }, [uppy]);

  const removeFile = (id: string) => {
    uppy.removeFile(id);
    setTempFiles((files) => files.filter((e) => e.id !== id));
    setUploads((uploads) => uploads.filter((u) => u.id !== id));
  };

  const removeDocument = ({ documentId, uploadId }: { documentId: string; uploadId: string }) => {
    setDocuments((docs) => docs.filter((d) => d.id !== documentId));
    removeFile(uploadId);
  };

  const removeUpload = async (file: FileUpload) => {
    switch (file.status) {
      case "UploadCompleted":
        switch (entityType) {
          case "Bid": {
            const response = dispatch(
              bffApi.endpoints.updateBseBid.initiate({
                bidId: entityId,
                editBidRequest: {
                  removeDocument: {
                    value: file.documentId,
                  },
                },
              })
            );
            response.reset();
            const result = await response;
            if ("data" in result) {
              removeDocument({ documentId: file.documentId, uploadId: file.uploadId });
              toast({
                title: t("Document removed!"),
                description: t("The document was removed successfully"),
                status: "success",
              });
            } else {
              trigger(result.error);
            }
            break;
          }
          case "Contract": {
            if (!file.documentId) throw Error("File to be deleted has no document id");
            const response = dispatch(
              bffApi.endpoints.updateContract.initiate({
                contractId: entityId,
                updateContractRequest: {
                  removeDocument: file.documentId,
                },
              })
            );
            response.reset();
            const result = await response;
            if ("data" in result) {
              removeDocument({ documentId: file.documentId, uploadId: file.uploadId });
              toast({
                title: t("Document removed!"),
                description: t("The document was removed successfully"),
                status: "success",
              });
            } else trigger(result.error);
            break;
          }
          case "SourcingEvent": {
            const response = dispatch(
              bffApi.endpoints.updateBasicSourcingEvent.initiate({
                eventId: entityId,
                editSourcingEventRequest: {
                  removeDocument: file.documentId,
                },
              })
            );
            response.reset();
            const result = await response;
            if ("data" in result) {
              removeDocument({ documentId: file.documentId, uploadId: file.uploadId });
              toast({
                title: t("Document removed!"),
                description: t("The document was removed successfully"),
                status: "success",
              });
            } else {
              trigger(result.error);
            }
            break;
          }
          case "Project": {
            const response = dispatch(
              bffApi.endpoints.updateProject.initiate({
                updateProjectRequest: {
                  id: entityId,
                  removeDocument: file.documentId,
                },
              })
            );
            response.reset();
            const result = await response;
            if ("data" in result) {
              removeDocument({ documentId: file.documentId, uploadId: file.uploadId });
              toast({
                title: t("Document removed!"),
                description: t("The document was removed successfully"),
                status: "success",
              });
            } else {
              trigger(result.error);
            }
            break;
          }
        }
        break;
      case "UploadFailed":
        removeFile(file.uploadId);
        break;
      case "UploadingToBrowser":
        removeFile(file.uploadId);
        break;
      case "UploadingToServer": {
        const response = dispatch(
          bffApi.endpoints.deleteUpload.initiate({
            uploadId: file.uploadId,
          })
        );
        response.reset();
        const result = await response;
        if ("data" in result) {
          removeFile(file.uploadId);
          toast({
            title: t("Upload canceled!"),
            description: t("The upload was canceled successfully"),
            status: "success",
          });
        } else {
          trigger(result.error);
        }
        break;
      }
    }
  };

  const uploadIdsInDocuments = useMemo(() => documents.map((e) => e.fromUploadId), [documents]);
  const uploadIds = useMemo(
    () => uploads.map((e) => e.id).filter((id) => uploadIdsInDocuments.indexOf(id) === -1),
    [uploadIdsInDocuments, uploads]
  );

  const filesUploadingToBrowser: FileUpload[] = useMemo(
    () =>
      tempFiles
        .filter((e) => uploadIds.indexOf(e.id) === -1 && uploadIdsInDocuments.indexOf(e.id) === -1)
        .map((e) => ({
          status: "UploadingToBrowser",
          name: e.name,
          uploadId: e.id,
        })),
    [tempFiles, uploadIds, uploadIdsInDocuments]
  );

  const filesUploadingToServer: FileUpload[] = useMemo(
    () =>
      uploads
        .filter((e) => uploadIdsInDocuments.indexOf(e.id) === -1)
        .map((e) => ({
          status: "UploadingToServer",
          name: e.fileName,
          uploadId: e.id,
        })),
    [uploadIdsInDocuments, uploads]
  );

  const uploadedFiles: FileUpload[] = useMemo(
    () =>
      documents.map((d) => ({
        status: "UploadCompleted",
        name: d.name,
        documentId: d.id,
        uploadId: d.fromUploadId,
        uploadedBy: d.uploadedBy,
        uploadedAt: d.uploadedAt,
        size: d.size,
        fileExtension: d.fileExtension,
      })),
    [documents]
  );

  const allFiles: FileUpload[] = useMemo(
    () => [...filesUploadingToBrowser, ...filesUploadingToServer, ...uploadedFiles],
    [filesUploadingToBrowser, filesUploadingToServer, uploadedFiles]
  );

  return {
    uppy,
    removeUpload,
    allFiles,
    documents,
    setDocuments,
    setUploads,
    reloadEntity,
  };
};

const isFile = (file: File | Blob): file is File => {
  return (file as File).lastModified !== undefined;
};
