import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaPaperPlane } from "react-icons/fa";

interface Props {
  isOpen: boolean;
  close: () => void;
  createNewQuestion: (values: { subject: string; content: string; announcementId: string | null }) => Promise<boolean>;
}

interface FormValues {
  subject: string;
  announcementId: string;
  question: string;
}

export const NewQuestionModal = ({ isOpen, close, createNewQuestion }: Props) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      subject: "",
      question: "",
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={close} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("New question")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <form
            onSubmit={handleSubmit(async (values: FormValues) => {
              setIsLoading(true);
              const result = await createNewQuestion({
                subject: values.subject,
                content: values.question,
                announcementId: values.announcementId ?? null,
              });
              setIsLoading(false);

              if (result) {
                toast({
                  title: t("Question added!"),
                  status: "success",
                });
                close();
              } else {
                toast({
                  title: t("Could not add question!"),
                  status: "error",
                });
              }
            })}
          >
            <FormControl mt="15px" isInvalid={errors.subject !== undefined}>
              <FormLabel>{t("Subject")}</FormLabel>
              <Input
                id={"subject"}
                type="text"
                placeholder={t("Subject") ?? ""}
                {...register("subject", {
                  required: t("Question subject") ?? "",
                  minLength: {
                    message: t("Minimum 2 characters needed!"),
                    value: 2,
                  },
                })}
              />
              <FormErrorMessage>{errors.subject && errors.subject.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt="15px" isInvalid={errors.question !== undefined}>
              <FormLabel>{t("Question")}</FormLabel>
              <Textarea
                placeholder={t("Question") ?? ""}
                {...register("question", {
                  minLength: {
                    message: t("Minimum 2 characters needed!"),
                    value: 2,
                  },
                })}
              />
              <FormErrorMessage>{errors.question && errors.question.message}</FormErrorMessage>
            </FormControl>
            <Flex mt="15px">
              <Spacer />
              <Button
                leftIcon={<Icon as={FaPaperPlane} w="15px" h="15px" mt="-2px" />}
                variant={"solid"}
                colorScheme="teal"
                size={"sm"}
                isLoading={isLoading}
                type="submit"
              >
                {t("Send")}
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
