import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useGetProductGroupsForSourcingEventQuery } from "../../../../../autogen/bff-api";
import { ProductFileUploader } from "../../../../../common/products/ProductFileUploader";
import { ProductGroupForm } from "../../../../../common/products/ProductGroupForm";
import { ProductTemplateModal } from "../../../../../common/products/ProductTemplateModal";
import { useAppDispatch, useAppSelector } from "../../../../../common/redux/hooks";
import { mapDtoToProductGroup, setProductGroups } from "../../../../../common/redux/reducers/productReducer";
import { ProductGroup } from "../../../../../common/types";
import { useBasicSourcingEventState } from "../../../useBasicSourcingEventState";

export const Products = ({ nextStep, previousStep }: { nextStep: () => void; previousStep: () => void }) => {
  const dispatch = useAppDispatch();

  const eventState = useBasicSourcingEventState();
  const { data, isLoading, error } = useGetProductGroupsForSourcingEventQuery({ sourcingEventId: eventState.id });

  const productGroups = useAppSelector((state) => state.product.data.productGroups);

  useEffect(() => {
    if ((!productGroups.length || productGroups[0]?.sourcingEventId !== eventState.id) && data?.productGroups.length) {
      dispatch(setProductGroups(data?.productGroups.map((group) => mapDtoToProductGroup(group))));
    }
    if (productGroups.length && !data?.productGroups.length) {
      dispatch(setProductGroups([]));
    }
    if (productGroups && productGroups[0]?.sourcingEventId !== eventState.id) {
      dispatch(setProductGroups(undefined));
    }
  }, [data, dispatch, eventState.id, productGroups]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productGroupDraft, setProductGroupDraft] = useState<ProductGroup>();

  if (isLoading) return <Text>{t("Loading")}...</Text>;

  if (error || !data) return <Text>{t("Something went wrong")}...</Text>;

  return (
    <Box width="full" maxWidth="full" pt="2">
      <ProductFileUploader
        showExplanation={productGroups.length === 0}
        setProductGroupDraft={setProductGroupDraft}
        setIsModalOpen={setIsModalOpen}
        sourcingEventId={eventState.id}
      />
      <Button variant={"outline"} mr="5px" colorScheme={"teal"} size={"sm"} onClick={() => setIsModalOpen(true)}>
        {t("New product group")} <Icon as={FaPlus} ml="2" />
      </Button>
      <Box maxWidth="full" py="8">
        {productGroups?.map((group) => (
          <Box key={group.id} maxWidth="full" pb="5">
            <ProductGroupForm group={group} />
          </Box>
        ))}
      </Box>
      <ProductTemplateModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        productGroupDraft={productGroupDraft}
        sourcingEventId={eventState.id}
      />
      <Flex pb="8">
        <Button variant={"outline"} mr="5px" w="50%" colorScheme={"teal"} onClick={previousStep}>
          {t("Previous")}
        </Button>
        <Button variant={"solid"} w="50%" colorScheme={"teal"} onClick={() => nextStep()}>
          {t("Next")}
        </Button>
      </Flex>
    </Box>
  );
};
