import { Tag } from "@chakra-ui/react";
import { ContractTemplateMinDto } from "../../../autogen/bff-api";
import { useTemplateMeta } from "../settings/ContractTemplates/useTemplateMeta";

interface Props {
  template: ContractTemplateMinDto;
}

export const ContractTemplateTag = ({ template }: Props) => {
  const templateMeta = useTemplateMeta();

  return (
    <Tag
      variant="outline"
      colorScheme={"purple"}
      size={"md"}
      textAlign="center"
      paddingTop={"2px"}
      paddingBottom={"2px"}
    >
      {templateMeta.getTemplateName(template)}
    </Tag>
  );
};
