import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  ThemingProps,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export interface ConfirmationModalConfig {
  title: string;
  description: string;
  onConfirmed: () => Promise<void> | void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonColor?: string;
  size?: ThemingProps<"Modal">["size"];
}

export const useConfirmationModal = (): {
  show: (config: ConfirmationModalConfig) => void;
  modal: React.ReactElement;
} => {
  const { t } = useTranslation();
  const [config, setConfig] = useState<ConfirmationModalConfig>();
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setConfig(undefined);
  };

  return {
    show: (config: ConfirmationModalConfig) => setConfig(config),
    modal: (
      <>
        {config && (
          <Modal isOpen={true} onClose={() => setConfig(undefined)} size={config.size ?? "3xl"}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{config.title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>{config.description}</Text>
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" mr={3} onClick={() => setConfig(undefined)} isDisabled={isLoading}>
                  {config.cancelButtonText ?? t("Cancel")}
                </Button>
                <Button
                  variant="outline"
                  colorScheme={config.confirmButtonColor ?? "blue"}
                  onClick={async () => {
                    setIsLoading(true);
                    await config.onConfirmed();
                    setIsLoading(false);
                    close();
                  }}
                  isLoading={isLoading}
                >
                  {config.confirmButtonText ?? t("Confirm")}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </>
    ),
  };
};
