import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useUpdateOrganizationMutation } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../common/errors/useApiError";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { OrgDto, updateSelectedOrg } from "../../../../common/redux/reducers/authStateReducer";

interface FormValues {
  canOnlySeeCreatedEvents: boolean;
  canOnlySeeCreatedContracts: boolean;
}

export const Advanced = () => {
  const toast = useToast();
  const authState = useLoggedInWithOrgContextState();
  const [editOrg, { isLoading }] = useUpdateOrganizationMutation();
  const dispatch = useAppDispatch();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { t } = useTranslation();
  const displayer = useApiError();

  const methods = useForm<FormValues>({
    defaultValues: {
      canOnlySeeCreatedEvents: authState.selectedOrg.canOnlySeeCreatedEvents,
      canOnlySeeCreatedContracts: authState.selectedOrg.canOnlySeeCreatedContracts,
    },
  });

  const onSubmit = async (values: FormValues) => {
    setIsRefreshing(true);
    const result = await editOrg({
      orgId: authState.selectedOrg.id,
      editOrganizationRequest: {
        canOnlySeeCreatedEvents: values.canOnlySeeCreatedEvents,
        canOnlySeeCreatedContracts: values.canOnlySeeCreatedContracts,
      },
    });
    if ("data" in result) {
      dispatch(updateSelectedOrg(result.data as OrgDto));
    } else {
      displayer.trigger(result.error);
    }
    setIsRefreshing(false);
    toast({ title: t("Organization has been updated!"), status: "success" });
  };
  return (
    <Box backgroundColor="smBackgroundSecondary" borderRadius={"10px"} p="20px" mt="20px">
      <Heading as="h2" size="md">
        {t("Granular permissions")}
      </Heading>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormControl mt="15px" isInvalid={methods.formState.errors.canOnlySeeCreatedEvents !== undefined}>
          <Controller
            name="canOnlySeeCreatedEvents"
            control={methods.control}
            render={({ field: { onChange, value } }) => {
              return (
                <Checkbox isChecked={value} onChange={onChange}>
                  {t("Creators and viewers can only access the events they created themselves")}
                </Checkbox>
              );
            }}
          />
          <FormErrorMessage>
            {methods.formState.errors.canOnlySeeCreatedEvents &&
              methods.formState.errors.canOnlySeeCreatedEvents.message}
          </FormErrorMessage>
          <FormHelperText>
            {t(
              "Enable this if you want to restrict creators and viewers to only access the events they themselves have created"
            )}
            .
          </FormHelperText>
        </FormControl>
        <FormControl mt="15px" isInvalid={methods.formState.errors.canOnlySeeCreatedContracts !== undefined}>
          <Controller
            name="canOnlySeeCreatedContracts"
            control={methods.control}
            render={({ field: { onChange, value } }) => {
              return (
                <Checkbox isChecked={value} onChange={onChange}>
                  {t("Creators and viewers can only access the contracts they created themselves")}
                </Checkbox>
              );
            }}
          />
          <FormErrorMessage>
            {methods.formState.errors.canOnlySeeCreatedContracts &&
              methods.formState.errors.canOnlySeeCreatedContracts.message}
          </FormErrorMessage>
          <FormHelperText>
            {t(
              "Enable this if you want to restrict creators and viewers to only access the contracts they themselves have created"
            )}
            .
          </FormHelperText>
        </FormControl>
        <Button
          mt="20px"
          leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
          variant={"solid"}
          colorScheme="teal"
          size={"sm"}
          isLoading={isLoading || isRefreshing}
          type="submit"
        >
          {t("Update")}
        </Button>
      </form>
    </Box>
  );
};
