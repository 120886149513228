import { Button, Flex, Heading, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAcceptOrgUserEmailInviteMutation, useGetOrgUserEmailInviteQuery } from "../../autogen/bff-api";
import { requireStringEnvVar } from "../../config";
import { urls } from "../../urls";
import { useAuthState } from "../auth/useAuthState";
import { useApiError } from "../errors/useApiError";
import { setOrganizationId } from "../local-storage/selected-organization";

interface Props {
  inviteId: string;
  secret: string;
}

export const AcceptOrgUserInvite = ({ inviteId, secret }: Props) => {
  const authState = useAuthState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, error } = useGetOrgUserEmailInviteQuery({
    inviteId: inviteId,
    secret: secret,
  });
  const displayer = useApiError();
  const toast = useToast();

  const [acceptInvite, { isLoading }] = useAcceptOrgUserEmailInviteMutation();

  if (error) {
    toast({
      title: t("Whooops!"),
      description: t("Did you click an expired or already accepted email invite?"),
      status: "warning",
    });
    navigate(urls.home);
  }

  if (!data) {
    return <div>{t("Loading")}...</div>;
  }
  return (
    <Flex flexDirection={"column"}>
      <Heading as="h2" size="lg">
        {t("Invitation to join")} {data.organizationName}
      </Heading>
      <br />
      <Text>
        {t("You have been invited to join")} {data.organizationName}.
      </Text>
      <Button
        mt="20px"
        variant={"solid"}
        colorScheme="blue"
        isLoading={isLoading}
        onClick={async () => {
          const result = await acceptInvite({
            inviteId: inviteId,
            secret: secret,
          });
          if ("data" in result) {
            toast({
              title: t("Invitation accepted!"),
              status: "success",
            });
            setOrganizationId(result.data.id);
            window.location.replace(requireStringEnvVar("VITE_WEBAPP_BASE_URL"));
          } else {
            displayer.trigger(result.error);
          }
        }}
      >
        {t("Click here to accept the invitation")}
      </Button>
      {authState.type === "LoggedInWithOrgContext" && (
        <>
          <Text mt="20px">{t("or you can")}</Text>
          <Button
            mt="20px"
            variant={"solid"}
            colorScheme="purple"
            onClick={() => {
              navigate(urls.home);
            }}
          >
            {t("Go home")}
          </Button>
        </>
      )}
    </Flex>
  );
};
