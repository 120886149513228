import { Box, Button, Flex, Heading, Icon } from "@chakra-ui/react";
import { t } from "i18next";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { FaPlus } from "react-icons/fa";
import {
  ProjectDto,
  SeDto,
  useListEventsForBuyerQuery,
  useUpdateBasicSourcingEventMutation,
} from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { SingleSelector } from "../../../common/input/Selector/SingleSelector";
import { CreateSourcingEventModal } from "../../sourcing-events/buyer/CreateSourcingEventModal";
import { SourcingEventsTableForBuyer } from "../../sourcing-events/buyer/view-all/SourcingEventsTableForBuyer";

export const SourcingEvents = ({ project }: { project: ProjectDto }) => {
  const authState = useLoggedInWithOrgContextState();

  const {
    data: projectEvents,
    error: projectEventsError,
    isLoading: isLoadingProjectEvents,
  } = useListEventsForBuyerQuery({
    orgId: authState.selectedOrg.id,
    status: "All",
    projectId: project.id,
  });

  const [updateEvent, { isLoading: isUpdatingEvent }] = useUpdateBasicSourcingEventMutation();

  const [showModal, setShowModal] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [debouncedSearchName, setDebouncedSearchName] = useState("");
  const [selectedEvent, setSelectedEvent] = useState<SeDto>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchName = useCallback(
    debounce((name: string) => setDebouncedSearchName(name), 300),
    []
  );

  const { data: searchedEvents, isFetching: isLoadingSearchedEvents } = useListEventsForBuyerQuery({
    orgId: authState.selectedOrg.id,
    status: "All",
    name: debouncedSearchName ? debouncedSearchName : undefined,
    limit: 10,
  });

  const onSelectedEvent = async (event: SeDto) => {
    setSelectedEvent(event);
    const updatedProjects = Array.from(new Set([...(event.projects?.map((p) => p.id) ?? []), project.id]));
    await updateEvent({
      eventId: event.id,
      editSourcingEventRequest: {
        editProjects: updatedProjects,
      },
    });
    setSearchName("");
    setSelectedEvent(undefined);
  };

  return (
    <>
      <CreateSourcingEventModal
        showModal={showModal}
        setShowModal={setShowModal}
        projectId={project.id}
        goToEventOnCreation={false}
      />
      <Box>
        {authState.selectedOrg.canCreateEvent && (
          <Flex columnGap={6} pb="5">
            <Box>
              <Button
                leftIcon={<Icon as={FaPlus} />}
                variant="outline"
                colorScheme="teal"
                size="sm"
                onClick={() => setShowModal(true)}
              >
                {t("New event")}
              </Button>
            </Box>
            <SingleSelector
              size="sm"
              isClearable
              placeholder={{ text: `${t("Connect sourcing event to this project")}...`, color: "" }}
              isLoading={isLoadingSearchedEvents || isUpdatingEvent}
              value={selectedEvent ? { label: selectedEvent.title, value: selectedEvent.id } : null}
              options={searchedEvents?.events.map((event) => ({ value: event.id, label: event.title })) ?? []}
              inputValue={searchName}
              onInputChange={(name: string) => {
                setSearchName(name);
                handleDebouncedSearchName(name);
              }}
              onChange={async (o) => {
                if (o === null) return;
                const event = searchedEvents?.events.find((e) => e.id === o?.value);
                if (!event) throw Error("Project not found");
                await onSelectedEvent(event);
              }}
            />
          </Flex>
        )}
        <Heading fontSize="lg" pb="5">
          {t("Sourcing events connected to this project")}
        </Heading>
        <SourcingEventsTableForBuyer
          status={"All"}
          events={projectEvents?.events}
          error={projectEventsError}
          isLoading={isLoadingProjectEvents}
        />
      </Box>
    </>
  );
};
