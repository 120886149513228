import { Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Select } from "@chakra-ui/react";
import { t } from "i18next";
import { Controller, useFormContext } from "react-hook-form";
import { ContractNotificationDurationType } from "../../../../autogen/bff-api";

export interface NotificationTimeInput {
  amount: number;
  unit: ContractNotificationDurationType;
}

interface FormValues {
  notificationTimeAmount: number;
  notificationTimeUnit: ContractNotificationDurationType;
}

export const NotificationTimeSelector = () => {
  const methods = useFormContext<FormValues>();

  const unit = methods.watch("notificationTimeUnit");

  const getMaxAmount = (): number => {
    switch (unit) {
      case "Days":
        return 31;
      case "Months":
        return 36;
    }
  };
  return (
    <FormControl isInvalid={methods.formState.errors.notificationTimeAmount !== undefined}>
      <FormLabel htmlFor={"notificationTime"}>{t("Reminder time")}</FormLabel>
      <Flex flexDirection={"row"}>
        <Controller
          name="notificationTimeAmount"
          control={methods.control}
          rules={{
            required: { value: true, message: t("Please select a reminder time") },
            max: { value: getMaxAmount(), message: t("Can be maximum") + " " + getMaxAmount() },
            min: { value: 1, message: t("Must be at least 1") },
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <Flex flex={1}>
                <Input
                  id={"amount"}
                  type="number"
                  value={value ?? ""}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value);
                    if (isNaN(newValue)) {
                      onChange(undefined);
                    } else {
                      onChange(newValue);
                    }
                  }}
                />
              </Flex>
            );
          }}
        />
        <Controller
          name="notificationTimeUnit"
          control={methods.control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <Flex w="200px" ml="5px">
                <Select
                  value={value ?? ""}
                  onChange={(e) => {
                    onChange(getUnit(e.target.value));
                  }}
                >
                  <option key={"Days"} value={"Days"}>
                    {t("days before")}
                  </option>
                  <option key={"Months"} value={"Months"}>
                    {t("months before")}
                  </option>
                </Select>
              </Flex>
            );
          }}
        />
      </Flex>
      <FormErrorMessage>
        {methods.formState.errors.notificationTimeAmount && methods.formState.errors.notificationTimeAmount.message}
      </FormErrorMessage>
      <FormHelperText>
        {t("The number of days (or months) before the event, that the reminder will be sent")}
      </FormHelperText>
    </FormControl>
  );
};

const getUnit = (value: string): ContractNotificationDurationType => {
  if (value === "Days") {
    return "Days";
  } else {
    return "Months";
  }
};
