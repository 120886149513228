import {
    Box,
    Button,
    ButtonGroup,
    Icon,
    Popover,
    PopoverArrow,
    PopoverContent,
    PopoverTrigger,
    useOutsideClick,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'

interface Props {
    confirmRemoval: () => Promise<void>
}

export const RemoveCommentButton: React.FC<Props> = ({ confirmRemoval }) => {
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const ref = React.useRef(null)
    const { t } = useTranslation()

    useOutsideClick({
        ref: ref,
        handler: () => setShowConfirmation(false),
    })

    return (
        <Box ref={ref}>
            <Popover
                returnFocusOnClose={true}
                isOpen={showConfirmation}
                placement="bottom"
                closeOnBlur={true}
            >
                <PopoverTrigger>
                    <Button
                        id="delete-comment-button"
                        ml="10px"
                        variant="link"
                        size="sm"
                        textAlign={'left'}
                        isLoading={isDeleting}
                        onClick={async () => setShowConfirmation(true)}
                    >
                        {t('Delete')}
                    </Button>
                </PopoverTrigger>
                <PopoverContent
                    w="200px"
                    h="35px"
                    display={'flex'}
                    justifyContent="center"
                    alignItems={'center'}
                >
                    <PopoverArrow />
                    <ButtonGroup size="sm">
                        <Button
                            leftIcon={<Icon as={FaTimes} w="15px" h="15px" />}
                            colorScheme="blue"
                            variant={'link'}
                            isLoading={isDeleting}
                            onClick={async () => {
                                setIsDeleting(true)
                                await confirmRemoval()
                                setIsDeleting(false)
                                setShowConfirmation(false)
                            }}
                        >
                            {t('Confirm removal')}
                        </Button>
                    </ButtonGroup>
                </PopoverContent>
            </Popover>
        </Box>
    )
}
