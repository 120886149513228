import { Box, Progress, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type UploadStatus = "UploadingToBrowser" | "UploadingToServer" | "UploadFailed";

export const ProgressBar = ({ status }: { status: UploadStatus }) => {
  const { t } = useTranslation();

  const progressMap: { [key in UploadStatus]: { text: string; value: number; color: string } } = {
    UploadFailed: { text: t("Upload failed!"), value: 0, color: "red" },
    UploadingToBrowser: {
      text: t("Uploading") + "...",
      value: 34,
      color: "teal",
    },
    UploadingToServer: {
      text: t("Scanning file") + "...",
      value: 64,
      color: "teal",
    },
  };

  return (
    <Box py="1">
      <Progress value={progressMap[status].value} colorScheme={progressMap[status].color} rounded="md" />
      <Text fontWeight={"bold"} fontSize="sm" pt="1">
        {progressMap[status].text}
      </Text>
    </Box>
  );
};
