import { ProductField, ProductFieldType } from "../types";

export const parseString = (str: string | number): string | number | boolean | null => {
  const trimmedStr = `${str ?? ""}`?.trim();
  if (!trimmedStr) return null;
  if (isFinite(Number(trimmedStr))) return Number(trimmedStr);
  if (trimmedStr.toLowerCase() === "true") return true;
  if (trimmedStr.toLowerCase() === "false") return false;
  return str;
};

export const getParsedType = (str: string): ProductField["type"] => {
  if (typeof parseString(str) === "number") return "Number";
  if (typeof parseString(str) === "boolean") return "Boolean";
  return "Text";
};

export const parseToType = ({ val, type }: { val?: string | number | null; type: ProductFieldType }) => {
  const trimmedStr = `${val ?? ""}`?.trim();
  if (type === "Text") return `${val ?? ""}`;
  if (type === "Number") return Number(trimmedStr);
  if (type === "Boolean") {
    if (trimmedStr.toLowerCase() === "true") return true;
    if (trimmedStr.toLowerCase() === "false") return false;
  }
  throw Error(`Unexpected error when parsing ${val} to ${type}`);
};
