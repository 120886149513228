import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaDownload, FaFileExcel } from "react-icons/fa";
import { useGetReceivedBseBidQuery } from "../../../../autogen/bff-api";
import { SharedDocumentsTable } from "../../../../common/documents/SharedDocumentsTable/SharedDocumentsTable";
import { useApiError } from "../../../../common/errors/useApiError";
import { useBidProductGroupsAsBuyer } from "../../../../common/hooks/useBidProductGroupsAsBuyer";
import { TipTap } from "../../../../common/input/TipTap/TipTap";
import { ProductTableRow } from "../../../../common/products/ProductTableRow";
import {
  displayProductGroupPrice,
  displaySumOfProductGroupPrices,
  downloadExcel,
} from "../../../../common/products/productUtils";
import { useSkeletons } from "../../../../common/useSkeletons";

interface Props {
  eventId: string;
  bidId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ViewBidAsBuyerModal = ({ eventId, bidId, isOpen, onClose }: Props) => {
  const { data: bid, error, isLoading } = useGetReceivedBseBidQuery({ eventId, bidId });
  const { t } = useTranslation();
  const displayer = useApiError();
  const skeletons = useSkeletons().stackedLines;

  const { productGroups } = useBidProductGroupsAsBuyer(bid);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent height={"80%"} maxHeight={"80%"} overflowY={"hidden"}>
        <ModalHeader>
          {bid && (
            <>
              {t("Bid from")} {bid?.owningOrganization.name}
            </>
          )}
          {isLoading && <>{t("Loading bid")}...</>}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" height="full" maxHeight={"full"} overflow="hidden" width="full" px="4" pb="5">
          {!isLoading && error && displayer.show(error)}
          {isLoading && (
            <Box width="full" pt="2.5">
              {skeletons(0, 5)}
            </Box>
          )}
          {bid && (
            <Flex
              flexDir={"column"}
              flexGrow={1}
              overflowY={"auto"}
              overflowX={"hidden"}
              minHeight={0}
              shadow="inner"
              rounded="md"
              p="2"
            >
              <Text as="h2" fontWeight={"semibold"} pb="2">
                {t("Comment from seller")}
              </Text>
              <TipTap content={bid.comment} editable={false} maxHeight="60" />
              <Box py="5">
                <Text as="h2" fontWeight={"semibold"} pb="2">
                  {t("Documents")}
                </Text>
                <SharedDocumentsTable documents={bid.documents} />
              </Box>
              {productGroups && (
                <Flex alignItems={"center"} justifyContent={"space-between"} pb="2">
                  <Text as="h2" fontWeight={"semibold"}>
                    {t("Products")}
                  </Text>
                  <Button
                    size={"sm"}
                    colorScheme="blue"
                    variant={"ghost"}
                    leftIcon={<Icon as={FaFileExcel} />}
                    rightIcon={<Icon as={FaDownload} />}
                    onClick={() => downloadExcel({ productGroups, fileName: `bid_${bid.owningOrganization.name}` })}
                  >
                    {t("Download")}
                  </Button>
                </Flex>
              )}
              {productGroups && (
                <Flex justifyContent="center" width="full" pb="6">
                  <Flex
                    flexDirection={"column"}
                    shadow={"sm"}
                    width="fit-content"
                    border="2px solid"
                    borderColor="blue.100"
                    rounded={"md"}
                    p="4"
                  >
                    <Text fontSize={"3xl"} fontWeight={"semibold"} textAlign={"center"}>
                      {displaySumOfProductGroupPrices(productGroups)}
                    </Text>
                    <Text>{t("Sum of product prices")}</Text>
                  </Flex>
                </Flex>
              )}
              {productGroups?.map((group) => (
                <Box key={group.id} border="1px solid" borderColor="teal.100" rounded="lg" pt="4" pb="2.5">
                  <Text as="h2" fontWeight={"semibold"} px="4">
                    {group.name}
                  </Text>
                  <Box overflowX={"auto"}>
                    <Table variant={"simple"} overflowX={"auto"} overflowY={"visible"} size="sm">
                      <Thead>
                        <Tr>
                          {group.productFields
                            .filter((field) => field.populatedBy === "Buyer")
                            .map((field) => (
                              <Th key={field.id}>{field.name}</Th>
                            ))}
                          <Th>{t("Quantity")}</Th>
                          <Th>{t("Unit Price")}</Th>
                          <Th>{t("Total Price")}</Th>
                          {group.productFields
                            .filter((field) => field.populatedBy === "Supplier")
                            .map((field) => (
                              <Th key={field.id}>{field.name}</Th>
                            ))}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {group.products?.map((product) => (
                          <ProductTableRow key={product.id} product={product} templateFields={group.productFields} />
                        ))}
                        <Tr fontWeight={"bold"}>
                          <Td>SUM</Td>
                          {group.productFields
                            .filter((field) => field.populatedBy === "Buyer")
                            .map((field) => (
                              <Td key={field.id}></Td>
                            ))}
                          <Td></Td>
                          <Td textAlign={"center"} verticalAlign={"top"}>
                            {displayProductGroupPrice(group)}
                          </Td>
                          {group.productFields
                            .filter((field) => field.populatedBy === "Supplier")
                            .map((field) => (
                              <Td key={field.id}></Td>
                            ))}
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                </Box>
              ))}
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
