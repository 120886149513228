import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { bffApi, EmailInviteContextDto } from "../../../../autogen/bff-api";
import { authService } from "../../../../config";
import { getOrganizationId } from "../../../local-storage/selected-organization";
import { AuthState, OrgDto } from "../../reducers/authStateReducer";
import { LoginError } from "./login-thunk";

export const getInitialAuthStateThunk = createAsyncThunk(
  "authState/getInitialAuthState",
  async (_: void, { dispatch }): Promise<AuthState> => {
    try {
      await authService.getSession();

      return await getLoggedInState({
        showUnknownError: false,
        dispatch: dispatch,
        invitationContext: null,
      });
    } catch (err) {
      return {
        type: "LoggedOut",
        isLoggingIn: false,
        loginError: null,
        isSigningUp: false,
        signupError: null,
      };
    }
  }
);

export const getLoggedInState = async (props: {
  showUnknownError: boolean;
  invitationContext: EmailInviteContextDto | null;
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
}): Promise<AuthState> => {
  const meResponse = props.dispatch(bffApi.endpoints.getMe.initiate({}));
  meResponse.unsubscribe();
  const meResult = await meResponse;

  const orgResponse = props.dispatch(bffApi.endpoints.listOrganizationsForMe.initiate({}));
  orgResponse.unsubscribe();
  const orgResult = await orgResponse;

  if (meResult.data && orgResult.data) {
    const orgs = orgResult.data.orgs as OrgDto[];
    if (orgs.length > 0) {
      const selectedOrgId = getOrganizationId();
      if (selectedOrgId) {
        const matchingOrgs = orgs.filter((e) => e.id === selectedOrgId);
        if (matchingOrgs.length > 0) {
          return {
            type: "LoggedInWithOrgContext",
            me: meResult.data,
            organizations: orgs,
            selectedOrg: matchingOrgs[0],
          };
        } else {
          return {
            type: "LoggedInWithOrgContext",
            me: meResult.data,
            organizations: orgs,
            selectedOrg: orgs[0],
          };
        }
      } else {
        return {
          type: "LoggedInWithOrgContext",
          me: meResult.data,
          organizations: orgs,
          selectedOrg: orgs[0],
        };
      }
    } else {
      return {
        type: "LoggedIn",
        me: meResult.data,
        organizations: [],
        invitationContext: props.invitationContext,
        isCreatingOrg: false,
        createOrgError: null,
      };
    }
  } else {
    await authService.logout();
    const unknownError = props.showUnknownError ? "UnknownError" : null;
    const loginError =
      (meResult.error as { data: { error: LoginError } })?.data?.error ??
      (meResult.error as { data: { errorCode: string } })?.data?.errorCode;
    return {
      type: "LoggedOut",
      isLoggingIn: false,
      loginError: loginError ?? unknownError,
      isSigningUp: false,
      signupError: null,
    };
  }
};
