import { Box, Icon, IconButton } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { FaFileDownload } from "react-icons/fa";
import { useApiError } from "../../errors/useApiError";
import { useDocumentDownloader } from "./useDocumentDownloader";

interface Props {
  documentId: string;
  isSignedDocument?: boolean;
}

export const DownloadDocumentIconButton = ({ documentId, isSignedDocument }: Props) => {
  const { getDownloadLink } = useDocumentDownloader();
  const [downloadLink, setDownloadLink] = useState<string>();
  const [isCreatingLink, setIsCreatingLink] = useState<boolean>(false);
  const ref = useRef<HTMLAnchorElement>(null);
  const displayer = useApiError();

  return (
    <Box>
      <IconButton
        id="download-file-popover"
        aria-label="Download file"
        variant={"unstyled"}
        isLoading={isCreatingLink}
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={async () => {
          if (downloadLink) ref.current?.click();
          if (!downloadLink) {
            setIsCreatingLink(true);
            const link = await getDownloadLink(documentId, isSignedDocument ?? false);
            if (link && ref.current) {
              setIsCreatingLink(false);
              setDownloadLink(link);
              ref.current.href = link;
              ref.current.click();
            } else {
              displayer.trigger({
                message: `could not generate download link, response: ${link}`,
              });
              return;
            }
          }
        }}
        icon={<Icon as={FaFileDownload} />}
      />
      <a ref={ref} style={{ display: "none" }} target="_blank" rel="noreferrer">
        Hidden Link
      </a>
    </Box>
  );
};
