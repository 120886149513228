import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Table,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { t } from "i18next";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProjectStatus, useGetProjectsQuery } from "../../../autogen/bff-api";
import { displayDate } from "../../../common/formatting/displayDate";
import { MultiSelector } from "../../../common/input/Selector/MultiSelector";
import { Layout } from "../../../common/layout/Layout";
import { useSkeletons } from "../../../common/useSkeletons";
import { CONTENT_WIDTH } from "../../../config";
import { urls } from "../../../urls";
import { Paging } from "../../contracts/view-all/Paging";
import { displayPersonName } from "../../contracts/view-single/sharing/AddExternalParticipantModal";
import { useProjectStatuses } from "../useProjectStatuses";
import { useProjectTypes } from "../useProjectTypes";

export const Projects = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const skeletons = useSkeletons();
  const projectTypes = useProjectTypes();
  const projectStatuses = useProjectStatuses();

  const [filters, setFilters] = useState(() => {
    const params = new URLSearchParams(location.search);
    return {
      name: params.get("name") || "",
      status: params.get("status") || "",
      skip: +(params.get("skip") || 0),
      limit: +(params.get("limit") || 10),
    };
  });

  useEffect(() => {
    const params = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value || value === 0) params.set(key, `${value}`);
    });

    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [filters, navigate, location.pathname]);

  const [searchName, setSearchName] = useState(filters.name);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchName = useCallback(
    debounce((name: string) => setFilters((filters) => ({ ...filters, name, skip: 0 })), 300),
    []
  );

  const { data, isLoading, isFetching } = useGetProjectsQuery({
    name: filters.name,
    status: filters.status,
    limit: filters.limit,
    skip: filters.skip,
  });

  return (
    <Layout>
      <Grid
        h="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"minmax(0, 1fr)"}
        templateAreas={`"leftContent content rightContent"`}
      >
        <GridItem h="100%" area={"content"} px="2" pb="6">
          <Heading as="h2" size="lg" pt="2.5" pb="4">
            {t("Projects")}
          </Heading>
          <Flex columnGap={2} pb="2">
            <InputGroup size="sm">
              <Input
                rounded="md"
                placeholder={`${t("Search projects by ID or name")}...`}
                value={searchName}
                onChange={(e) => {
                  const name = e.target.value;
                  setSearchName(name);
                  handleDebouncedSearchName(name);
                }}
              />
              {isFetching && (
                <InputRightElement>
                  <Spinner size="sm" color="smPrimary" />
                </InputRightElement>
              )}
            </InputGroup>
            <Flex width="container.sm">
              <MultiSelector
                placeholder={{ text: `${t("Select status")}...`, color: "" }}
                size="sm"
                value={(filters.status === "" ? [] : filters.status.split(",")).map((status) => ({
                  value: status,
                  label: projectStatuses[status as ProjectStatus].name,
                }))}
                options={Object.keys(projectStatuses).map((s) => ({
                  value: s,
                  label: projectStatuses[s as ProjectStatus].name,
                }))}
                onChange={(values) =>
                  setFilters((filters) => ({ ...filters, status: values.map((v) => v.value).join(",") }))
                }
                noOptionsAvailableMessage={t("No more options")}
              />
            </Flex>
          </Flex>
          {!isLoading && (
            <Flex flexDirection="column" border="1px solid" borderColor="smBorder" rounded="md" pt="2.5" pb="2.5">
              <Paging
                skip={filters.skip}
                limit={filters.limit}
                setSkip={(skip: number) => setFilters((filters) => ({ ...filters, skip }))}
                isLoading={isFetching}
              />
              <Box width="full" maxWidth="full" overflowX="auto">
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>{t("ID")}</Th>
                      <Th minWidth="80">{t("Name")}</Th>
                      <Th>{t("Status")}</Th>
                      <Th>{t("Type")}</Th>
                      <Th>{t("Start date")}</Th>
                      <Th>{t("End date")}</Th>
                      <Th>{t("Parent project")}</Th>
                      <Th>{t("Project responsible")}</Th>
                      <Th>{t("Sourcing responsible")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data?.projects.map((project) => (
                      <Tr
                        key={project.id}
                        onClick={() => navigate(urls.projects.view.go(project.id))}
                        _hover={{ backgroundColor: "smBackgroundSecondary", cursor: "pointer" }}
                      >
                        <Td>{project.externalId}</Td>
                        <Td fontWeight="semibold">{project.name}</Td>
                        <Td>
                          <Tag colorScheme={projectStatuses[project.status].colorScheme}>
                            {projectStatuses[project.status].name}
                          </Tag>
                        </Td>
                        <Td>
                          {project.projectType && (
                            <Tag variant="subtle">
                              {projectTypes.find((t) => t.value === project.projectType?.id)?.label}
                            </Tag>
                          )}
                        </Td>
                        <Td>{project.startDate ? displayDate(project.startDate) : null}</Td>
                        <Td>{project.endDate ? displayDate(project.endDate) : null}</Td>
                        <Td>{`${project.parent?.externalId ? `${project.parent.externalId} - ` : ""}${
                          project.parent?.name ?? ""
                        }`}</Td>
                        <Td>{project.projectResponsible ? displayPersonName(project.projectResponsible) : null}</Td>
                        <Td>{project.sourcingResponsible ? displayPersonName(project.sourcingResponsible) : null}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Paging
                skip={filters.skip}
                limit={filters.limit}
                setSkip={(skip: number) => setFilters((filters) => ({ ...filters, skip }))}
                isLoading={isFetching}
              />
            </Flex>
          )}
          {isLoading && <Box py="5">{skeletons.stackedLines(10, 5)}</Box>}
        </GridItem>
      </Grid>
    </Layout>
  );
};
