import { Organizations } from "./Companies";
import { Contracts } from "./Contracts";
import { MyAccount } from "./MyAccount";
import { Notifications } from "./Notifications";
import { Projects } from "./Projects";
import { Sourcing } from "./Sourcing";

export const LgNavbar = () => {
  return (
    <>
      <Projects />
      <Sourcing />
      <Contracts />
      <Organizations />
      <Notifications />
      <MyAccount />
    </>
  );
};
