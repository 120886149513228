import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/progress-bar/dist/style.css";
import { debounce } from "lodash";
import { DragEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFileUpload } from "react-icons/fa";
import { UploadDto, ViewDocumentAsOwnerDto, useUpdateDocumentNameMutation } from "../../../autogen/bff-api";
import { FileRow } from "./FileRow";
import { SustainabilityAnalysisModal } from "./SustainabilityAnalysisModal";
import { DocumentUploadContext } from "./useDocumentsUploader";
import { useUppyUploader } from "./useUppyUploader";

interface Props {
  uploadEntityType: DocumentUploadContext;
  entityId: string;
  uploads: UploadDto[];
  documents: ViewDocumentAsOwnerDto[];
  reloadEntity: () => void;
  target: string;
  helperText: string;
  isDisabled?: boolean;
}

export const UppyUploader = ({
  documents,
  uploads,
  uploadEntityType,
  entityId,
  reloadEntity,
  target,
  helperText,
  isDisabled = false,
}: Props) => {
  const { t } = useTranslation();
  const [updateDocumentName] = useUpdateDocumentNameMutation();
  const { uppy, allFiles, setDocuments, setUploads, removeUpload } = useUppyUploader({
    entityId,
    entityType: uploadEntityType,
    reloadEntity,
  });

  const [isDropping, setIsDropping] = useState(false);

  useEffect(() => {
    setUploads(uploads);
  }, [setUploads, uploads]);

  useEffect(() => {
    setDocuments(documents);
  }, [documents, setDocuments]);
  const [showSusModal, setShowSusModal] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnUpdateName = useCallback(
    debounce(({ docId, name }: { docId: string; name: string }) => {
      updateDocumentName({
        docId,
        updateDocumentNameRequest: { name },
      });
    }, 300),
    []
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onButtonClick = () => {
    fileInputRef.current?.click();
  };

  const onFileChange = (files?: FileList | null) => {
    if (!files) return;
    Array.from(files).forEach((file) =>
      uppy.addFile({
        name: file.name,
        type: file.type,
        data: file,
      })
    );
  };

  const onDragOver: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    setIsDropping(true);
  };

  const onDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    onFileChange(event.dataTransfer.files);
    setIsDropping(false);
  };

  const finishedUploads = allFiles.filter((e) => e.status === "UploadCompleted");

  return (
    <>
      {showSusModal && (
        <SustainabilityAnalysisModal fileRows={finishedUploads} onClose={() => setShowSusModal(false)} />
      )}
      {!isDisabled && (
        <FormControl pb="8">
          <FormLabel>{t("Upload documents")}</FormLabel>
          <div id={target}>
            <Box
              onDragOver={onDragOver}
              onDragLeave={() => setIsDropping(false)}
              onDrop={onDrop}
              border="2px dashed"
              borderColor={isDropping ? "smSecondary" : "smBorder"}
              rounded="md"
              padding="5"
              textAlign="center"
              cursor="pointer"
              onClick={onButtonClick}
            >
              <Button leftIcon={<Icon as={FaFileUpload} />}>{t("Drop or browse files here")}</Button>
            </Box>
            <Input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              multiple
              onChange={(e) => onFileChange(e.target?.files)}
            />
          </div>
          <FormHelperText>{helperText}.</FormHelperText>
        </FormControl>
      )}
      <Box width="full" border="1px solid" borderColor="smBorder" rounded="lg" pb="2">
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>{t("Name")}</Th>
              <Th>{t("Uploaded by")}</Th>
              <Th>{t("Date")}</Th>
              <Th>{t("Download")}</Th>
              <Th>{t("Remove")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            <>
              {allFiles?.map((f) => (
                <FileRow
                  key={f.uploadId}
                  file={f}
                  name={f.name}
                  remove={removeUpload}
                  onUpdateName={
                    f.status === "UploadCompleted" && !isDisabled
                      ? async (name: string) => {
                          debouncedOnUpdateName({ docId: f.documentId, name });
                          setDocuments((docs) => {
                            const i = docs.findIndex((doc) => doc.id === f.documentId);
                            const updatedDoc = { ...docs[i], name };
                            const updatedDocs = [...docs];
                            updatedDocs[i] = updatedDoc;
                            return updatedDocs;
                          });
                        }
                      : undefined
                  }
                />
              ))}
              {!allFiles?.length && (
                <Tr>
                  <Td colSpan={5} width="full" textAlign="center" fontSize="small">
                    {t("No documents found")}
                  </Td>
                </Tr>
              )}
            </>
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
