import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useAppDispatch } from "../../../common/redux/hooks";
import { reloadMeThunk } from "../../../common/redux/thunks/auth/reload-me-thunk";
import { authService } from "../../../config";
import { ReauthenticateModal } from "./ReauthenticateModal";

interface FormValues {
  email: string;
}

export const ChangeEmail = () => {
  const { me } = useLoggedInWithOrgContextState();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [isChanging, setIsChanging] = useState(false);
  const [showReauthModal, setShowReauthModal] = useState(false);
  const authState = useLoggedInWithOrgContextState();
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      email: me.email,
    },
  });

  const value = watch("email");

  const onSubmit = async (values: FormValues): Promise<boolean> => {
    setIsChanging(true);
    const result = await authService.updateTraits({
      email: values.email,
      firstName: authState.me.firstName,
      lastName: authState.me.lastName,
    });

    switch (result) {
      case "failure":
        toast({
          title: t("Could not update email, please make sure you provided a valid email address"),
          status: "error",
        });
        setIsChanging(false);
        return true;
      case "reauth":
        toast({
          title: t("Reauthentication needed!"),
          status: "info",
        });
        setValue("email", me.email);
        setShowReauthModal(true);
        setIsChanging(false);
        return true;
      case "success":
        await dispatch(reloadMeThunk());
        toast({
          title: t("Email was updated!"),
          status: "success",
        });
        setIsChanging(false);
        return true;
    }
  };

  return (
    <>
      {showReauthModal && (
        <ReauthenticateModal
          onClose={() => setShowReauthModal(false)}
          onReauthSuccessful={() => {
            setShowReauthModal(false);
            trigger();
          }}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl mt="15px" isInvalid={errors.email !== undefined}>
          <FormLabel htmlFor={"email"}>{t("Email")}</FormLabel>
          <Input
            id={"email"}
            type="text"
            {...register("email", {
              required: t("Please provide your email") ?? "",
              minLength: {
                value: 2,
                message: t("Must be at least 2 characters long"),
              },
            })}
          />
          <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
          <FormHelperText>{t("Please provide your email")}</FormHelperText>
        </FormControl>
        {me.email !== value && (
          <Button
            mt="10px"
            leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
            variant={"solid"}
            colorScheme="teal"
            size={"sm"}
            isLoading={isChanging}
            type="submit"
          >
            {t("Save change")}
          </Button>
        )}
      </form>
    </>
  );
};
