import { Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { ContractFieldOption, getDataFieldTranslationKey } from "./DataFieldSelector";
import { DataFieldTypeTag } from "./DataFieldTypeTag";

interface Props {
  field: ContractFieldOption;
  onRemove: () => void;
}

export const SelectedDataField = ({ field, onRemove }: Props) => {
  const { t } = useTranslation();
  return (
    <Flex
      mr="5px"
      mt="5px"
      borderRadius={"10px"}
      backgroundColor="smBackgroundSecondary"
      pl="10px"
      pr="10px"
      pt="5px"
      pb="5px"
    >
      <Flex>
        <Text fontSize={"sm"} fontWeight={"semibold"}>
          {t(getDataFieldTranslationKey(field.name))}
        </Text>
      </Flex>
      <Flex w="20px"></Flex>
      <Flex>
        <DataFieldTypeTag type={field.type} size="sm" />
      </Flex>
      <Flex justifyContent={"center"} alignItems={"center"} mr="-10px">
        <IconButton
          h="10px"
          w="10px"
          aria-label="Remove"
          color="#8d9298"
          variant={"unstyled"}
          icon={<Icon as={FaTimes} />}
          mt="-10px"
          onClick={onRemove}
        />
      </Flex>
    </Flex>
  );
};
