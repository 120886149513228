import { debounce } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DateFormControl } from "../../../../../../common/input/DateTimeSelectors/DateFormControl";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { requireDataFields } from "../../../../typeguards";
import { useContractState } from "../../../useContractState";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const WarrantyExpirationDate = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const contractState = useContractState();
  const dataFields = requireDataFields(contractState.contract);
  const errorMessage = containsError(contractState, "EditWarrantyExpirationDate") ? t("Update failed") : null;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDateChange = useCallback(
    debounce(async (value) => {
      await dispatch(
        editContractThunk({
          command: {
            type: "EditWarrantyExpirationDate",
            value,
          },
        })
      );
    }, 300),
    []
  );

  return (
    <DateFormControl
      id={"warrantyExpirationDate"}
      title={t("Warranty expiration date")}
      helperText={t("Provide a warranty expiration date")}
      defaultDate={dataFields.warrantyExpirationDate}
      errorMessage={errorMessage}
      onChange={handleDateChange}
      removeDataField={removeDataField}
      isRequiredButNotProvided={isRequiredButNotProvided}
    />
  );
};
