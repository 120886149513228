import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import { Moment } from "moment";
import React from "react";
import { PersonDto } from "../../../../autogen/bff-api";
import { displayDate } from "../../../../common/formatting/displayDate";
import { ProfileImageRounded } from "./ProfileImageRounded";

interface Props {
  createdAt: Moment;
  person: PersonDto;
}

export const Created: React.FC<Props> = ({ createdAt, person }) => {
  const name = `${person.firstName} ${person.lastName}`;
  return (
    <Grid
      gridTemplateAreas={`
            "image name"
            "image email"
            "image createdAt"
            `}
      gridTemplateRows="30px 20px 30px"
      gridTemplateColumns={"55px 1fr"}
    >
      <GridItem area={"image"} display={"flex"} alignItems="center">
        <Box>
          <ProfileImageRounded name={name} backgroundColor="smPrimary" width="50px" />
        </Box>
      </GridItem>
      <GridItem area={"name"} display={"flex"} alignItems="end">
        <Text fontSize={"sm"}>{name}</Text>
      </GridItem>
      <GridItem area={"email"} color="#a6aebd">
        <Text fontSize={"sm"}>{person.email}</Text>
      </GridItem>
      <GridItem area={"createdAt"} color="#a6aebd">
        <Text fontSize={"sm"}>{displayDate(createdAt.toISOString())}</Text>
      </GridItem>
    </Grid>
  );
};
