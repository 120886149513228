import { RecoveryFlow } from "@ory/client";
import { useState } from "react";
import { CompleteForgottenPasswordFlowForm } from "./CompleteForgottenPasswordFlowForm";
import { InitForgottenPasswordFlowForm } from "./InitForgottenPasswordFlowForm";

interface Props {
  onComplete: (email: string) => void;
}

export const ForgottenPassword = ({ onComplete }: Props) => {
  const [forgottenPasswordEmail, setForgottenPasswordEmail] = useState<string>();
  const [flow, setFlow] = useState<RecoveryFlow>();

  return (
    <>
      {!forgottenPasswordEmail && (
        <InitForgottenPasswordFlowForm
          onComplete={(email, recoveryFlow) => {
            setForgottenPasswordEmail(email);
            setFlow(recoveryFlow);
          }}
        />
      )}
      {forgottenPasswordEmail && flow && (
        <CompleteForgottenPasswordFlowForm
          email={forgottenPasswordEmail}
          onComplete={() => onComplete(forgottenPasswordEmail)}
          recoveryFlow={flow}
        />
      )}
    </>
  );
};
