import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AcceptedInfoDto, SupplierEmailInviteDto, useListSupplierEmailInvitesQuery } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { useSkeletons } from "../../../common/useSkeletons";
import { AcceptedInviteRow } from "./AcceptedInviteRow";

export const AcceptedInvitesTable = () => {
  const authState = useLoggedInWithOrgContextState();
  const { data, error } = useListSupplierEmailInvitesQuery({
    orgId: authState.selectedOrg.id,
  });
  const skeletons = useSkeletons();
  const { t } = useTranslation();
  const displayer = useApiError();

  if (error) {
    return displayer.show(error);
  }

  type AcceptedInvite = SupplierEmailInviteDto & {
    acceptedInfo: AcceptedInfoDto;
  };

  const acceptedInvites: AcceptedInvite[] =
    data?.invitations
      .filter((e) => e.acceptedInfo !== null && e.acceptedInfo !== undefined)
      .map((e) => ({
        ...e,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...{ acceptedInfo: e.acceptedInfo! },
      })) ?? [];

  return (
    <Box backgroundColor="smBackgroundSecondary" borderRadius={10} mt="5">
      {!data && skeletons.stackedLines(20)}
      {data && (
        <Table variant="simple" mb="30px">
          <Thead>
            <Tr>
              <Th>{t("Sent to")}</Th>
              <Th>{t("Accepted by")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {acceptedInvites.map((e) => {
              return <AcceptedInviteRow key={e.id} invite={e} acceptedInfo={e.acceptedInfo} />;
            })}
            {acceptedInvites.length === 0 && (
              <Tr>
                <Td colSpan={4} width={"100%"} textAlign="center">
                  {t("No invitations have been accepted!")}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};
