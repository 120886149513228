import { useMemo } from "react";
import { ReceivedBseBidDto } from "../../../../../autogen/bff-api";
import { ProductGroup } from "../../../../../common/types";
import { isBestProductPrice } from "./BidEvaluationUtils";

export const useIsBestProductPrice = ({
  bidId,
  productId,
  group,
  bids,
}: {
  bidId: string;
  productId?: string;
  group: ProductGroup;
  bids: ReceivedBseBidDto[];
}) => {
  return useMemo(() => isBestProductPrice({ bidId, productId, group, bids }), [bidId, bids, group, productId]);
};
