import {
    Box,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Spinner,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../../../common/redux/hooks'
import { containsError } from '../../../../../../common/redux/reducers/contractReducer'
import { editContractThunk } from '../../../../../../common/redux/thunks/contract/edit-contract-thunk'
import { useContractState } from '../../../useContractState'
import { RemoveDataFieldButton } from '../RemoveDataFieldButton'

interface Props {
    removeDataField?: () => Promise<void>
    isRequiredButNotProvided: boolean
}

export const SupplierInfo: React.FC<Props> = ({
    removeDataField,
    isRequiredButNotProvided,
}) => {
    const { t } = useTranslation()
    const contractState = useContractState()
    const [isLoading, setIsLoading] = useState(false)
    const errorMessage = containsError(contractState, 'EditInternalParties')
        ? t('Update failed')
        : null
    const dispatch = useAppDispatch()
    const [isClimateCommitted, setIsClimateCommitted] = useState<boolean>(false)
    const [hasSignedCodeOfConduct, setHasSignedCodeOfConduct] =
        useState<boolean>(false)
    const [hasCustomerContact, setHasCustomerContact] = useState<boolean>(false)

    useEffect(() => {
        if (contractState.contract.dataFields?.supplierInfo) {
            setIsClimateCommitted(
                contractState.contract.dataFields.supplierInfo
                    .isClimateCommitted
            )
            setHasSignedCodeOfConduct(
                contractState.contract.dataFields.supplierInfo
                    .hasSignedCodeOfConduct
            )
            setHasCustomerContact(
                contractState.contract.dataFields.supplierInfo
                    .hasCustomerContact
            )
        }
    }, [])

    const updater = async (values: {
        isClimateCommitted: boolean
        hasSignedCodeOfConduct: boolean
        hasCustomerContact: boolean
    }) => {
        setIsLoading(true)
        await dispatch(
            editContractThunk({
                command: {
                    type: 'EditSupplierInfo',
                    value: {
                        isClimateCommitted: values.isClimateCommitted,
                        hasCustomerContact: values.hasCustomerContact,
                        hasSignedCodeOfConduct: values.hasSignedCodeOfConduct,
                    },
                },
            })
        )
        setIsLoading(false)
    }
    return (
        <FormControl
            isInvalid={errorMessage !== null}
            isRequired={isRequiredButNotProvided}
        >
            <FormLabel htmlFor={'value'}>
                {t('Supplier info')} {isLoading && <Spinner size="xs" />}
            </FormLabel>
            <Flex w="100%">
                <Flex flexDirection={'column'} borderRadius={'10px'} w="100%">
                    <Checkbox
                        mt="10px"
                        isChecked={isClimateCommitted}
                        onChange={() => {
                            setIsClimateCommitted(!isClimateCommitted)
                            updater({
                                isClimateCommitted: !isClimateCommitted,
                                hasCustomerContact,
                                hasSignedCodeOfConduct,
                            })
                        }}
                    >
                        {t('Is climate committed')}
                    </Checkbox>
                    <Checkbox
                        mt="10px"
                        isChecked={hasSignedCodeOfConduct}
                        onChange={() => {
                            setHasSignedCodeOfConduct(!hasSignedCodeOfConduct)
                            updater({
                                isClimateCommitted,
                                hasCustomerContact,
                                hasSignedCodeOfConduct: !hasSignedCodeOfConduct,
                            })
                        }}
                    >
                        {t('Has signed code of conduct')}
                    </Checkbox>
                    <Checkbox
                        mt="10px"
                        isChecked={hasCustomerContact}
                        onChange={() => {
                            setHasCustomerContact(!hasCustomerContact)
                            updater({
                                isClimateCommitted,
                                hasCustomerContact: !hasCustomerContact,
                                hasSignedCodeOfConduct,
                            })
                        }}
                    >
                        {t('Has customer contact')}
                    </Checkbox>
                </Flex>
                <RemoveDataFieldButton removeDataField={removeDataField} />
            </Flex>
            <Box mt="30px">
                <FormHelperText>
                    {t('Provide information about the supplier')}
                </FormHelperText>
            </Box>
            <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
        </FormControl>
    )
}
