import { Button, Icon } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaEye } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { BidDto } from '../../../autogen/bff-api'
import { urls } from '../../../urls'

interface Props {
    bid: BidDto
}

export const ViewEventButton: React.FC<Props> = ({ bid }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const viewEvent = (bid: BidDto) => {
        navigate(urls.events.supplierView.go(bid.forEvent.id))
    }

    return (
        <Button
            leftIcon={<Icon as={FaEye} w="15px" h="15px" />}
            variant={'solid'}
            colorScheme="teal"
            size={'sm'}
            onClick={() => {
                viewEvent(bid)
            }}
        >
            {t('View')}
        </Button>
    )
}
