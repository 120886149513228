import { Button, Flex, Td, Tr, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SupplierEmailInviteDto,
  useCreateSupplierEmailInviteReminderMutation,
  useDeleteSupplierEmailInviteMutation,
} from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";

interface Props {
  invite: SupplierEmailInviteDto;
  refetch: () => void;
}

export const SentInviteRow: React.FC<Props> = ({ invite, refetch }) => {
  const authState = useLoggedInWithOrgContextState();
  const [deleteInvite] = useDeleteSupplierEmailInviteMutation();
  const [sendReminder] = useCreateSupplierEmailInviteReminderMutation();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSendingReminder, setIsSendingReminder] = useState(false);
  const { t } = useTranslation();
  const toast = useToast();
  const displayer = useApiError();

  return (
    <Tr key={invite.id}>
      <Td>{invite.email}</Td>
      <Td>
        {invite.personFirstName} {invite.personLastName}
      </Td>
      <Td>{invite.organizationName}</Td>
      <Td>{invite.acceptedInfo ? t("Yes") : t("No")}</Td>
      <Td>
        <Flex>
          <Button
            variant={"solid"}
            colorScheme="orange"
            size={"sm"}
            isDisabled={isDeleting}
            isLoading={isSendingReminder}
            onClick={async () => {
              setIsSendingReminder(true);
              const result = await sendReminder({
                orgId: authState.selectedOrg.id,
                inviteId: invite.id,
              });
              if ("data" in result) {
                toast({
                  title: t("Reminder sent!"),
                  status: "success",
                });
              } else {
                displayer.trigger(result.error);
              }
              refetch();
              setIsSendingReminder(false);
            }}
          >
            Send reminder
          </Button>
          <Button
            ml="5px"
            variant={"solid"}
            colorScheme="red"
            size={"sm"}
            isDisabled={isSendingReminder}
            isLoading={isDeleting}
            onClick={async () => {
              setIsDeleting(true);
              const result = await deleteInvite({
                orgId: authState.selectedOrg.id,
                inviteId: invite.id,
              });
              if ("data" in result) {
                toast({
                  title: t("Invite deleted!"),
                  status: "success",
                });
              } else {
                displayer.trigger(result.error);
              }
              refetch();
              setIsDeleting(false);
            }}
          >
            {t("Delete")}
          </Button>
        </Flex>
      </Td>
    </Tr>
  );
};
