import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ContractDataFieldsDto, ContractDto } from "../../../autogen/bff-api";
import { CustomContractField, CustomContractFieldSections } from "../../types";
import { EditContractCommandType, editContractThunk } from "../thunks/contract/edit-contract-thunk";
import { loadContractThunk } from "../thunks/contract/load-contract-thunk";

export type ContractState = {
  id: string;
  contract: Omit<ContractDto, "dataFields"> & {
    dataFields: Omit<ContractDataFieldsDto, "customFields"> & { customFields?: CustomContractFieldSections };
  };
  lastChanged: string;
  errors: EditContractCommandType[];
};

interface State {
  state?: ContractState | null;
  customFields?: CustomContractFieldSections;
}

const initialState: State = {
  state: null,
};

export const slice = createSlice<
  State,
  {
    setCustomFields: (state: State, action: PayloadAction<CustomContractFieldSections>) => void;
    setCustomField: (state: State, action: PayloadAction<{ sectionId: string; field: CustomContractField }>) => void;
  },
  "contract"
>({
  name: "contract",
  initialState,
  reducers: {
    setCustomFields: (state, action) => {
      state.customFields = action.payload;
    },
    setCustomField: (state, action) => {
      const customFields = state.customFields;
      if (!customFields) throw Error("Custom fields not defined");
      const fieldIndex = customFields[action.payload.sectionId].fields.findIndex(
        (f) => f.id === action.payload.field.id
      );
      if (fieldIndex === -1) throw Error("Field not found - cannot update");
      customFields[action.payload.sectionId].fields[fieldIndex] = action.payload.field;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadContractThunk.fulfilled, (state, action) => {
      const payload = action.payload;
      state.state = payload;
      state.customFields = payload?.contract.dataFields.customFields;
    });
    builder.addCase(editContractThunk.fulfilled, (state, action) => {
      const payload = action.payload;
      state.state = payload;
    });
  },
});

export const { setCustomField, setCustomFields } = slice.actions;
export default slice.reducer;

export const containsError = (state: ContractState, type: EditContractCommandType): boolean => {
  return state.errors.indexOf(type) !== -1;
};
