import {
  ContractDocumentFieldsDto,
  ContractDto,
  ContractEditingMetaFieldsDto,
  ContractListItemDto,
  ContractNotificationFieldsDto,
  ContractOrgParticipantDto,
  ContractPersonParticipantDto,
  ContractStatusDto,
  CounterpartyDto,
  IfrsValuesDto,
  InternalPartiesDto,
} from "../../autogen/bff-api";
import { ContractState } from "../../common/redux/reducers/contractReducer";

export const requireEditingMetaFields = (contract: ContractDto): ContractEditingMetaFieldsDto => {
  if (contract.editingMetaFields) {
    return contract.editingMetaFields;
  }
  throw new Error("Contract doesn't have editing meta fields");
};

export const requireDataFields = (contract: ContractState["contract"]): ContractState["contract"]["dataFields"] => {
  if (contract.dataFields) {
    return contract.dataFields;
  }
  throw new Error("Contract doesn't have data fields");
};

export const requireDocumentFields = (contract: ContractDto): ContractDocumentFieldsDto => {
  if (contract.documentFields) {
    return contract.documentFields;
  }
  throw new Error("Contract doesn't have document fields");
};

export const requireNotificationFields = (contract: ContractDto): ContractNotificationFieldsDto => {
  if (contract.notificationFields) {
    return contract.notificationFields;
  }
  throw new Error("Contract doesn't have notification fields");
};

export const requireCounterparty = (contract: { counterparty?: CounterpartyDto }): CounterpartyDto => {
  if (contract.counterparty) {
    return contract.counterparty;
  }
  throw new Error("Contract doesn't have counterparty");
};

export const requireInternalParties = (contract: ContractState["contract"]): InternalPartiesDto => {
  if (contract.dataFields?.internalParties) {
    return contract.dataFields?.internalParties;
  }
  throw new Error("Contract doesn't have internal parties");
};

export const requireStatus = (contract: ContractDto | ContractListItemDto): ContractStatusDto => {
  if (contract.status) {
    return contract.status;
  }
  throw new Error("Contract doesn't have status field");
};

export const isContractOrgParticipant = (
  participant: ContractOrgParticipantDto | ContractPersonParticipantDto
): participant is ContractOrgParticipantDto => {
  return (participant as ContractOrgParticipantDto).orgMembers !== undefined;
};

export const requireIfrsValues = (contract: ContractDto): IfrsValuesDto => {
  if (contract.dataFields?.ifrsValues) {
    return contract.dataFields?.ifrsValues;
  }
  throw new Error("Contract doesn't have ifrs values");
};
