import { FormControl, FormHelperText, FormLabel, Spinner, Textarea } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useEffect, useMemo } from "react";

interface Props {
  label: string;
  name: string;
  helperText: string;
  errorMessage: string | undefined;
  onChange: (value: string) => void;
  debouncedOnChangeHandler?: (value: string) => Promise<void>;
  value: string;
  isLoading: boolean;
  required?: boolean;
}

export const TextAreaInput = ({
  name,
  label,
  onChange,
  helperText,
  errorMessage,
  value,
  isLoading,
  debouncedOnChangeHandler,
  required,
}: Props) => {
  useEffect(() => {
    return () => {
      debounceHandler.cancel();
    };
  }, []);

  const debounceHandler = useMemo(
    () =>
      debounce(async (value: string) => {
        debouncedOnChangeHandler && (await debouncedOnChangeHandler(value));
      }, 300),
    []
  );

  return (
    <FormControl isRequired={required} isInvalid={errorMessage !== undefined}>
      <FormLabel htmlFor={name}>
        {label} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Textarea
        id={name}
        onChange={(e) => {
          onChange(e.target.value);
          debounceHandler(e.target.value);
        }}
        value={value === undefined ? "" : value}
      />
      <FormHelperText>{helperText}</FormHelperText>
      <FormHelperText color="red.500">{errorMessage}</FormHelperText>
    </FormControl>
  );
};
