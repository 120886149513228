import Pusher from "pusher-js";
import { requireStringEnvVar } from "../config";

let pusher: Pusher;

export const getPusher = () => {
  if (!pusher) {
    pusher = new Pusher(requireStringEnvVar("VITE_PUSHER_KEY"), {
      cluster: requireStringEnvVar("VITE_PUSHER_CLUSTER"),
    });
  }
  return pusher;
};
