import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { OrgPublicProfileDto } from "../../../../autogen/bff-api";
import { requireBooleanEnvVar } from "../../../../config";

interface Props {
  organization: OrgPublicProfileDto;
}

export const Specialties: React.FC<Props> = ({ organization }) => {
  const { t } = useTranslation();

  return (
    <Flex
      border="1px solid"
      borderColor="smBorder"
      flexDirection={"column"}
      alignItems="start"
      justifyContent={"start"}
      borderRadius={"10px"}
      padding="25px"
      backgroundColor="smBackground"
      w="100%"
      h="auto"
    >
      <Box>
        <Text fontSize={"md"} fontWeight="bold">
          {t("Specializations")}
        </Text>
      </Box>
      <Box>
        {requireBooleanEnvVar("VITE_ENABLE_DUMMY_COMPANY_PAGE") ? (
          <Text fontSize={"sm"} color="smMuted" whiteSpace={"pre-line"}>
            {
              "Procurement, Finance, Cloud-based Financial Applications, Purchasing, Sourcing, Invoicing, Cloud Computing, Spend Management Software, Transformation Digitale, Dépenses, achats, notes de frais, travel, and VaaS"
            }
          </Text>
        ) : organization.specializations ? (
          <Text fontSize={"sm"} color="smMuted" whiteSpace={"pre-line"}>
            {organization.specializations}
          </Text>
        ) : (
          <Text fontSize={"sm"} color="smMuted">
            <i>{t("Not provided")}.</i>
          </Text>
        )}
      </Box>
    </Flex>
  );
};
