import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface Props {
  count: number;
  total: number;
  title: string;
  description: string;
}

export const BidItem = ({ count, total, title, description }: Props) => {
  const { t } = useTranslation();
  return (
    <Flex
      flex={3}
      borderRadius="md"
      borderColor="teal.100"
      borderStyle={"solid"}
      borderWidth={"0.125rem"}
      shadow={"sm"}
      alignItems="center"
      flexDirection={"column"}
      padding={"20px"}
    >
      <Text fontSize={"sm"}>{title}</Text>
      <Text fontSize={"2xl"} fontWeight="bold">
        {count} {t("of")} {total}
      </Text>
      <Text fontSize={"sm"} color="#929eaf">
        {description}
      </Text>
    </Flex>
  );
};
