import { Box, Button, FormControl, FormErrorMessage, FormLabel, Icon, Input, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaMicrosoft } from "react-icons/fa";
import { ForgottenPassword } from "../../../common/auth/ForgottenPassword/ForgottenPassword";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { authService } from "../../../config";

interface Props {
  onReauthSuccessful: () => void;
}
interface FormValues {
  email: string;
  password: string;
}

export const Reauthenticate = ({ onReauthSuccessful }: Props) => {
  const [showForgottenPassword, setShowForgottenPassword] = useState(false);
  const [isReauthenticating, setIsReauthenticating] = useState(false);
  const authState = useLoggedInWithOrgContextState();
  const toast = useToast();
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    setError,
  } = useForm<FormValues>({
    defaultValues: {
      email: authState.me.email,
      password: "",
    },
  });

  const login = async (values: FormValues): Promise<boolean> => {
    setIsReauthenticating(true);
    const result = await authService.login({
      email: values.email,
      password: values.password,
      refresh: true,
    });

    setIsReauthenticating(false);
    switch (result) {
      case "Failure":
        setError("email", {
          message: t("Unknown error! Please contact us via chat if the problem persists!") ?? "",
        });
        return false;
      case "NotAuthorized":
        setError("email", {
          message: t("Invalid email or password") ?? "",
        });
        setError("password", {
          message: t("Invalid email or password") ?? "",
        });
        return false;
      case "Success":
        toast({
          title: t("Successfully re-authenticated!"),
          status: "success",
        });
        onReauthSuccessful();
        return true;
      case "UserDoesNotExist":
        setError("email", {
          message: t("A user for this email address does not exist!") ?? "",
        });
        return false;
    }
  };

  return (
    <>
      {!showForgottenPassword && (
        <>
          <Text fontSize="3xl">
            <b>{t("Log in")}</b>
          </Text>
          <form onSubmit={handleSubmit(login)}>
            <Text fontSize="md" mt="10px">
              {t("Please provide your credentials to log in")}
            </Text>
            <FormControl isInvalid={errors.email !== undefined} mt="15px">
              <FormLabel htmlFor={"email"}>{t("Email")}</FormLabel>
              <Input
                id={"email"}
                type="email"
                {...register("email", {
                  required: t("Please provide your email") ?? "",
                  minLength: {
                    value: 2,
                    message: t("Must be at least 2 characters long"),
                  },
                })}
              />
              <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.password !== undefined} mt="15px">
              <FormLabel htmlFor={"password"}>{t("Password")}</FormLabel>
              <Input
                id={"password"}
                type="password"
                {...register("password", {
                  required: t("Please provide your password") ?? "",
                  minLength: {
                    value: 8,
                    message: t("Your password must be at least 8 characters long"),
                  },
                })}
              />
              <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
            </FormControl>
            <Text fontSize="md" mt="15px" ml="4px">
              {t("Have you")}{" "}
              <Button variant={"link"} colorScheme="teal" onClick={() => setShowForgottenPassword(true)}>
                {t("forgotten your password?")}
              </Button>
            </Text>
            <Box h={10} w="100%" mt="15px" mb="10px">
              <Button type="submit" variant={"solid"} colorScheme="teal" w="100%" isLoading={isReauthenticating}>
                {t("Log in")}
              </Button>
            </Box>
          </form>
          <Text fontSize="md" mt="15px" ml="4px">
            {t("or click here to login with Entra ID")}
          </Text>
          <Box h={10} w="100%" mt="25px" mb="10px">
            <Button
              leftIcon={<Icon as={FaMicrosoft} w="15px" h="15px" />}
              variant={"solid"}
              colorScheme="teal"
              w="100%"
              onClick={() => {
                authService.signupWithAzureAD();
              }}
            >
              {t("Log in with Entra ID")}
            </Button>
          </Box>
        </>
      )}
      <>
        {showForgottenPassword && (
          <>
            <Text fontSize="3xl">
              <b>{t("Forgotten password")}</b>
            </Text>
            <ForgottenPassword
              onComplete={(email) => {
                setValue("email", email);
                setShowForgottenPassword(false);
              }}
            />
          </>
        )}
      </>
    </>
  );
};
