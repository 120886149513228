import { Checkbox, Flex, Tag, Td, Text, Tr } from "@chakra-ui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ContractListItemDto, CounterpartyDto, CountryDto } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { displayDate } from "../../../common/formatting/displayDate";
import { Explanation } from "../../../common/support/Explanation/Explanation";
import { urls } from "../../../urls";
import { hasContractViewerAccess } from "../../organizations/current/manage-users/utils";
import { ProfileImageRounded } from "../../sourcing-events/buyer/view-all/ProfileImageRounded";
import { ContractStatusTag } from "../edit/ContractStatusTag";
import { ContractTemplateTag } from "../edit/ContractTemplateTag";

interface Props {
  contract: ContractListItemDto;
  ifrsReportingEnabled: boolean;
  selectedForIfrsReporting: boolean;
  selectedForIfrsReportingChanged: (newValue: boolean) => void;
}

export const ContractRow = ({
  contract,
  ifrsReportingEnabled,
  selectedForIfrsReporting,
  selectedForIfrsReportingChanged,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();

  const counterparty = contract.counterparty;

  const onClick = () => {
    if (!hasContractViewerAccess(authState.selectedOrg.roles)) return;
    if (contract.state === "Published") navigate(urls.contracts.viewSingle.go(contract.id));
    else navigate(urls.contracts.edit.go(contract.id).details);
  };

  return (
    <Tr
      key={contract.id}
      _hover={{ bg: hasContractViewerAccess(authState.selectedOrg.roles) ? "smBackgroundSecondary" : "" }}
      cursor={hasContractViewerAccess(authState.selectedOrg.roles) ? "pointer" : "auto"}
    >
      <Td fontWeight="semibold" onClick={onClick}>
        <Text fontSize={"sm"}>{contract.title}</Text>
      </Td>
      <Td onClick={onClick}>
        {counterparty && (
          <Flex align="center">
            <Flex>
              <ProfileImageRounded
                name={getCounterpartyName(counterparty)}
                backgroundColor="smSecondary"
                width="5"
                fontSize="xx-small"
              />
            </Flex>
            <Flex pl="1.5">
              <Text fontSize={"sm"}>{getCounterpartyName(counterparty)}</Text>
            </Flex>
          </Flex>
        )}
      </Td>
      <Td onClick={onClick}>
        <Text fontSize={"sm"}>
          {contract.internalParties?.map((p, i, parties) => (
            <Fragment key={p.organizationId}>
              {p.organizationName}
              {i >= parties.length - 1 ? "" : ", "}
            </Fragment>
          ))}
        </Text>
      </Td>
      <Td onClick={onClick}>
        {contract.endDate ? (
          <Text fontSize={"sm"}>{displayDate(contract.endDate)}</Text>
        ) : contract.hasNoEndDate ? (
          <Text fontSize={"sm"}>{t("Has no end date")}</Text>
        ) : (
          <Text fontSize={"sm"} fontStyle={"italic"}>
            {t("Not provided")}
          </Text>
        )}
      </Td>
      <Td onClick={onClick}>
        <Flex align="center">
          <Flex>
            <ProfileImageRounded
              name={contract.internalOwner.firstName}
              backgroundColor={"smPrimary"}
              width={"20px"}
              fontSize="xx-small"
            />
          </Flex>
          <Flex pl="1.5">
            <Text>{`${contract.internalOwner.firstName} ${contract.internalOwner.lastName}`}</Text>
          </Flex>
        </Flex>
      </Td>
      <Td onClick={onClick}>
        {contract.state === "Published" && contract.status && <ContractStatusTag status={contract.status} />}
        {contract.state === "Draft" && (
          <Tag variant="outline" colorScheme="blue" textAlign="center" pt="0.5" pb="0.5">
            {contract.state}
          </Tag>
        )}
      </Td>
      <Td onClick={onClick}>
        <Text fontSize={"sm"}>
          {contract.template ? <ContractTemplateTag template={contract.template} /> : <i>{t("Not provided")}</i>}
        </Text>
      </Td>
      <Td onClick={onClick}>
        {contract.iamParticipant && contract.owningOrganization.id !== authState.selectedOrg.id ? (
          <Explanation text={t(`${contract.owningOrganization.name} shared this contract with you`)} enabled={true}>
            <Text>{t("External")}</Text>
          </Explanation>
        ) : (
          <Explanation text={t("Your organization owns this contract")} enabled={true}>
            <Text>{t("Your org")}</Text>
          </Explanation>
        )}
      </Td>
      {ifrsReportingEnabled && (
        <Td>
          <Explanation
            enabled={!contract.containsIfrsValues}
            text={t("IFRS-values not added")}
            shouldWrapChildren={true}
          >
            <Checkbox
              bg="smBackground"
              ml="10px"
              size="lg"
              isChecked={selectedForIfrsReporting}
              isDisabled={!contract.containsIfrsValues}
              onChange={async () => {
                const newValue = !selectedForIfrsReporting;
                selectedForIfrsReportingChanged(newValue);
              }}
            />
          </Explanation>
        </Td>
      )}
    </Tr>
  );
};

export const getCounterpartyName = (counterparty: CounterpartyDto): string => {
  if (counterparty.organization) {
    return counterparty.organization.name;
  } else if (counterparty.organizationEntry) {
    return counterparty.organizationEntry.name;
  } else {
    throw Error("should never happen");
  }
};

export const getCounterpartyCountry = (counterparty: CounterpartyDto): CountryDto => {
  if (counterparty.organization) {
    return counterparty.organization.country;
  } else if (counterparty.organizationEntry) {
    return counterparty.organizationEntry.country;
  } else {
    throw Error("should never happen");
  }
};
