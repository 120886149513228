import { Box, Divider, Flex, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ContractState } from "../../../../common/redux/reducers/contractReducer";
import { urls } from "../../../../urls";
import { requireDataFields } from "../../typeguards";

interface Props {
  data: ContractState["contract"];
}

export const OriginSourcingEvent = ({ data }: Props) => {
  const { t } = useTranslation();
  const dataFields = requireDataFields(data);
  const sourcingEvent = dataFields.originSourcingEvent;
  const addedDataFields = dataFields.addedDataFields.map((e) => e.name);

  if (addedDataFields.includes("OriginSourcingEvent") && !sourcingEvent) {
    return (
      <Box padding="15px" paddingBottom={"20px"} w="100%">
        <Text fontWeight={"bold"} fontSize="sm" color="#808B9B">
          {t("Origin sourcing event")}
        </Text>
        <Divider marginTop={"5px"} marginBottom={"5px"} />
        <Text fontStyle={"italic"}>{t("Not provided")}</Text>
      </Box>
    );
  }

  if (!sourcingEvent) {
    return null;
  }

  return (
    <Box padding="15px" paddingBottom={"20px"} w="100%">
      <Text fontWeight={"bold"} fontSize="sm" color="#808B9B">
        {t("Origin sourcing event")}
      </Text>
      <Divider marginTop={"5px"} marginBottom={"5px"} />
      <Flex mt="7px" key={sourcingEvent.id}>
        <Link href={urls.events.view.go(sourcingEvent.id)} color="blue.500" target="_blank">
          {sourcingEvent.title}
        </Link>
      </Flex>
    </Box>
  );
};
