import { Heading } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaBuilding, FaPaperPlane, FaShoppingBasket } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useSub } from "../../../common/subscription/useSub";
import { urls } from "../../../urls";
import { Invites } from "../../email-invites/manage-supplier-invites/Invites";
import { useCreateBasicSourcingEventModal } from "../../sourcing-events/buyer/useCreateBasicSourcingEventModal";
import { LinkItem } from "./LinkItem";

export const QuickLinks = () => {
  const navigate = useNavigate();
  const authState = useLoggedInWithOrgContextState();
  const { showCreateBasicSourcingEventModal, createBasicSourcingEventModal } = useCreateBasicSourcingEventModal();
  const [showSendInvites, setShowSendInvites] = useState(false);

  const { t } = useTranslation(["translation"]);
  const canManageSupplierInvites = authState.selectedOrg.canManageSupplierInvites;
  const sub = useSub();
  return (
    <>
      {createBasicSourcingEventModal}
      {showSendInvites && <Invites isOpen={true} onClose={() => setShowSendInvites(false)} />}
      <Heading as="h2" fontSize={"sm"} color="smMuted">
        {t("Quick links")}
      </Heading>
      {sub.hasBuyerAccess && sub.canCreateEvents && (
        <LinkItem
          title={t("Create sourcing event")}
          description={t("Start a sourcing event for a product or service you need")}
          iconType={FaShoppingBasket}
          buttonTitle={t("Create")}
          onClick={showCreateBasicSourcingEventModal}
        />
      )}
      <LinkItem
        title={t("Explore companies")}
        description={t("Browse the SourceMagnet network to find new suppliers and partners")}
        iconType={FaBuilding}
        buttonTitle={t("Explore")}
        onClick={() => navigate(urls.organizations.search)}
      />
      {canManageSupplierInvites && (
        <LinkItem
          title={sub.hasBuyerAccess ? t("Invite suppliers") : t("Invite partners")}
          description={
            sub.hasBuyerAccess
              ? t("Send an invite to one of your suppliers to join the SourceMagnet network")
              : t("Invite other companies to join the SourceMagnet network")
          }
          iconType={FaPaperPlane}
          buttonTitle={t("Invite")}
          onClick={() => setShowSendInvites(true)}
        />
      )}
    </>
  );
};
