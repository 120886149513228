import { Box } from "@chakra-ui/react";
import { NotificationDto } from "../../autogen/bff-api";
import { Notification } from "./Notification";

interface Props {
  notifications: NotificationDto[];
}

export const NotificationList = ({ notifications }: Props) => {
  return (
    <Box>
      {notifications.map((e, index) => (
        <Notification key={e.id} notification={e} index={index} />
      ))}
    </Box>
  );
};
