import {
    SignedDocumentDto,
    ViewDocumentAsOwnerDto,
} from '../../../../autogen/bff-api'

export const isSignedDocument = (doc: unknown): doc is SignedDocumentDto => {
    return (doc as SignedDocumentDto).sentForSigningBy !== undefined
}

export const isDocument = (doc: unknown): doc is ViewDocumentAsOwnerDto => {
    return (doc as ViewDocumentAsOwnerDto).uploadedBy !== undefined
}
