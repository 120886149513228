import { Button, Flex, Icon, Td, Text, Tr, useToast } from "@chakra-ui/react";
import { JSXElementConstructor, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { FaCopy, FaEdit, FaEye, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { SeDto, useCreateSourcingEventCopyMutation, useDeleteSourcingEventMutation } from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { displayDate } from "../../../../common/formatting/displayDate";
import { Explanation } from "../../../../common/support/Explanation/Explanation";
import { ConfirmationModalConfig } from "../../../../common/useConfirmationModal";
import { urls } from "../../../../urls";
import { EventStatusTag } from "../../EventStatusTag";
import { eventIsAwarded } from "../../eventIsAwarded";
import { eventIsDraft } from "../../eventIsDraft";

interface Props {
  event: SeDto;
  confirmationModal: {
    show: (config: ConfirmationModalConfig) => void;
    modal: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  };
}

export const EventRow = ({ event, confirmationModal }: Props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const apiErrorDisplayer = useApiError();

  const [createEventCopy, { isLoading: isLoadingEventCopy }] = useCreateSourcingEventCopyMutation();
  const [deleteSourcingEvent] = useDeleteSourcingEventMutation();

  return (
    <>
      <Tr key={event.id}>
        <Td>{event.title}</Td>
        <Td>{event.referenceId ? event.referenceId : <Text fontStyle={"italic"}>{t("Not set")}</Text>}</Td>
        <Td>
          <Text>
            {event.createdBy.firstName} {event.createdBy.lastName}
          </Text>
        </Td>
        <Td>
          {event.deadline ? (
            displayDate(event.deadline)
          ) : (
            <Text>
              <i> {t("Not provided")}</i>
            </Text>
          )}
        </Td>
        <Td>
          <EventStatusTag status={event.status} />
        </Td>
        {eventIsAwarded(event) ? <Td>{event.winners?.join(", ") ?? "None"}</Td> : <Td></Td>}
        <Td>
          <Flex flexDirection={"row"} columnGap={1} align="center">
            <Explanation enabled={!event.canEdit} text={t("Only admins and creators can copy events") ?? ""}>
              <Button
                isDisabled={!event.canEdit}
                leftIcon={<Icon as={FaCopy} />}
                variant="outline"
                colorScheme="teal"
                size="xs"
                isLoading={isLoadingEventCopy}
                onClick={async () => {
                  const res = await createEventCopy({ eventId: event.id });
                  if ("data" in res) navigate(urls.events.edit.go(res.data.id).intro);
                }}
              >
                {t("Copy")}
              </Button>
            </Explanation>
            {eventIsDraft(event) ? (
              <>
                <Explanation text={t("Only admins and creators can edit events")} enabled={!event.canEdit}>
                  <Button
                    leftIcon={<Icon as={FaEdit} w="15px" h="15px" />}
                    variant={"outline"}
                    colorScheme="teal"
                    isDisabled={!event.canEdit}
                    size="xs"
                    onClick={() => navigate(urls.events.edit.go(event.id).intro)}
                  >
                    {t("Edit")}
                  </Button>
                </Explanation>
                <Explanation text={t("Only admins and creators can delete events")} enabled={!event.canEdit}>
                  <Button
                    leftIcon={<Icon as={FaTimes} w="15px" h="15px" />}
                    variant={"outline"}
                    isDisabled={!event.canEdit}
                    colorScheme="red"
                    size="xs"
                    onClick={async () => {
                      confirmationModal.show({
                        title: t("Delete draft?"),
                        description: t("Are you sure you want to delete this draft?"),
                        onConfirmed: async () => {
                          const result = await deleteSourcingEvent({
                            eventId: event.id,
                          });

                          if ("data" in result) {
                            toast({
                              title: t("Successfully deleted!"),
                              description: t("The draft was successfully deleted"),
                              status: "success",
                            });
                          } else {
                            apiErrorDisplayer.trigger(result.error);
                          }
                        },
                      });
                    }}
                  >
                    {t("Delete")}
                  </Button>
                </Explanation>
              </>
            ) : (
              <Explanation
                enabled={!event.canView}
                text={t("Contact your admin to get access to view this event") ?? ""}
              >
                <Button
                  leftIcon={<Icon as={FaEye} w="15px" h="15px" />}
                  isDisabled={!event.canView}
                  variant={"solid"}
                  colorScheme="teal"
                  size={"xs"}
                  onClick={() => navigate(urls.events.view.go(event.id))}
                >
                  {t("View event")}
                </Button>
              </Explanation>
            )}
          </Flex>
        </Td>
      </Tr>
    </>
  );
};
