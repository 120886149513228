import { useLoggedInWithOrgContextState } from '../../../common/auth/useLoggedInWithOrgContextState'

export const usePersonalProfileCompletion = () => {
    const authState = useLoggedInWithOrgContextState()

    let infosCompleted = 0
    const emailVerified = authState.me.emailVerified
    if (emailVerified) {
        infosCompleted += 1
    }
    const jobTitleProvided: boolean = authState.me.corporateTitle ? true : false
    if (jobTitleProvided) {
        infosCompleted += 1
    }
    const phoneNumberProvided: boolean = authState.me.phoneNumber ? true : false
    if (phoneNumberProvided) {
        infosCompleted += 1
    }

    let profilePercentageComplete = 0
    if (infosCompleted === 1) {
        profilePercentageComplete = 33
    } else if (infosCompleted === 2) {
        profilePercentageComplete = 67
    } else if (infosCompleted === 3) {
        profilePercentageComplete = 100
    }

    return {
        completionPercentage: profilePercentageComplete,
        emailVerified: emailVerified,
        jobTitleProvided: jobTitleProvided,
        phoneNumberProvided: phoneNumberProvided,
    }
}
