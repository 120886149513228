import { Icon, Td, Text, Tr } from "@chakra-ui/react";
import { FaBuilding, FaFileSignature, FaUser } from "react-icons/fa";
import { ContractDateReminderDto, ContractNotificationTypeDto } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useDisplayNotificationEvent } from "../../../../common/hooks/useContractNotificationName";
import { useNotificationLeadTimeConfig } from "../../../../common/hooks/useNotificationLeadTimeConfig";

export const DefaultNotificationRow = ({
  reminder,
  type,
}: {
  reminder: ContractDateReminderDto;
  type: ContractNotificationTypeDto;
}) => {
  const authState = useLoggedInWithOrgContextState();

  const displayNotificationEvent = useDisplayNotificationEvent();
  const displayNotificationTypeConfig = useNotificationLeadTimeConfig();

  return (
    <Tr>
      <Td>
        <Text fontSize={"sm"}>{displayNotificationEvent(type)}</Text>
      </Td>
      <Td>
        <Text fontSize={"sm"}>{displayNotificationTypeConfig(reminder.leadTime.unit, reminder.leadTime.amount)}</Text>
      </Td>
      <Td fontSize={"sm"}>
        {reminder.receivers.persons.map((id) => {
          const person = authState.selectedOrg.users.find((u) => u.person.id === id)?.person;
          return (
            <Text key={id}>
              <Icon as={FaUser} mr="2" />
              {person?.firstName} {person?.lastName}
            </Text>
          );
        })}
        {reminder.receivers.organizationRoles.map((role) => (
          <Text key={role}>
            <Icon as={FaBuilding} mr="2" />
            {role}
          </Text>
        ))}
        {reminder.receivers.contractRoles.map((role) => (
          <Text key={role}>
            <Icon as={FaFileSignature} mr="2" />
            {role}
          </Text>
        ))}
      </Td>
    </Tr>
  );
};
