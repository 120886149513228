import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from "@chakra-ui/react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { SingleSelector } from "./SingleSelector";

interface Props<T extends FieldValues> {
  options: { label: string; value: string }[];
  label: string;
  helperText?: string;
  control: Control<T>;
  name: FieldPath<T>;
  placeholderText: string;
  noOptionsProvidedMessage: string;
  noMatchingOptionsMessage: string;
  required?: {
    value: boolean;
    message: string;
  };
  errorMessage: string | undefined;
  size?: "sm" | "md";
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const SingleFormSelector = <T extends FieldValues>({
  options,
  label,
  helperText,
  control,
  name,
  placeholderText,
  noOptionsProvidedMessage,
  noMatchingOptionsMessage,
  required,
  errorMessage,
  size,
  isDisabled,
  isLoading,
}: Props<T>) => {
  return (
    <FormControl
      id={name}
      borderRadius={"10px"}
      isRequired={required?.value === true}
      isInvalid={errorMessage !== undefined}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        rules={{
          required: required,
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <SingleSelector
              isDisabled={isDisabled}
              isLoading={isLoading}
              isClearable
              size={size}
              options={options}
              onChange={(value) => {
                onChange(value);
              }}
              value={value}
              placeholder={{
                text: placeholderText,
                color: "#a0aec0",
              }}
              noOptionsProvidedMessage={noOptionsProvidedMessage}
              noMatchingOptionsMessage={noMatchingOptionsMessage}
            />
          );
        }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
