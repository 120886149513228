import { Box, Button, Flex, Grid, GridItem, Icon, Stack, Text, useToast } from "@chakra-ui/react";
import moment from "moment";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheckSquare, FaTimesCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  ContractDataFieldNameDto,
  ContractEndDateDto,
  CounterpartyContactPersonDto,
  CounterpartyDto,
  LinkedContractDto,
  OriginSourcingEventDto,
  TerminationDateDto,
  TextDocumentDto,
  useListTextDocumentsForContractQuery,
  usePublishContractMutation,
} from "../../../../autogen/bff-api";
import { DocumentsAsOwnerTable } from "../../../../common/documents/DocumentsAsOwnerTable";
import { SignedDocumentsTable } from "../../../../common/documents/SignedDocumentsTable";
import { TextDocumentModal } from "../../../../common/documents/TextDocumentModal";
import { ReviewRow } from "../../../../common/editing/ReviewRow/ReviewRow";
import { useApiError } from "../../../../common/errors/useApiError";
import { displayDate } from "../../../../common/formatting/displayDate";
import { displayPhoneNumber } from "../../../../common/formatting/displayPhoneNumber";
import { displaySum } from "../../../../common/formatting/displaySum";
import { TextDocumentTable } from "../../../../common/input/TipTap/TextDocumentTable";
import { useTranslationTools } from "../../../../common/useTranslationTools";
import { urls } from "../../../../urls";
import { requireDataFields } from "../../typeguards";
import { displayPersonName } from "../../view-single/sharing/AddExternalParticipantModal";
import { getDataFieldTranslationKey } from "../details/optional/DataFieldSelector";
import { useContractState } from "../useContractState";
interface Props {
  previousStep: () => void;
  navigateAfterPublish: () => void;
}

export const Review = ({ previousStep, navigateAfterPublish }: Props) => {
  const contractState = useContractState();
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [publishContract, { isLoading }] = usePublishContractMutation();
  const displayer = useApiError();
  const translationTools = useTranslationTools();

  const dataFields = requireDataFields(contractState.contract);

  const { data, isLoading: isLoadingTextDocuments } = useListTextDocumentsForContractQuery({
    contractId: contractState.id,
  });
  const [textDocumentToView, setTextDocumentToView] = useState<TextDocumentDto>();

  const publish = async () => {
    if (isValid()) {
      const result = await publishContract({
        contractId: contractState.id,
      });
      if ("data" in result) {
        toast({
          title: t("Contract published!"),
          description: t("Your contract is now published!"),
          status: "success",
        });
        navigateAfterPublish();
      } else displayer.trigger(result.error);
    } else {
      toast({
        title: t("One or more values are invalid!"),
        description: t("Please fix missing or invalid values"),
        status: "warning",
      });
    }
  };

  const isValid = (): boolean => {
    return counterpartyErrorMessage === null;
  };

  const getCounterpartyErrorMessage = (value: CounterpartyDto | null): string | null => {
    if (value === null) {
      return t("Please select a counterparty");
    }
    return null;
  };

  const counterpartyErrorMessage = getCounterpartyErrorMessage(contractState.contract.counterparty ?? null);

  const noValueProvided = (
    <Text fontStyle={"italic"} color="gray.400">
      {t("No value provided")}
    </Text>
  );

  const getDataFieldValue = (name: ContractDataFieldNameDto): ReactElement | null => {
    const awardDate: string | null = dataFields.awardDate ?? null;
    const renewalDate: string | null = dataFields.renewalDate ?? null;
    const counterpartyContactPerson: CounterpartyContactPersonDto | null = dataFields.counterpartyContactPerson ?? null;
    const deliveryTerms: string | null = dataFields.deliveryTerms ?? null;
    const estimatedValue: number | null = dataFields.estimatedValue ?? null;
    const expirationDate: ContractEndDateDto | null = dataFields.endDate ?? null;
    const externalLink: string | null = dataFields.externalLink ?? null;
    const internalReferenceNumber: string | null = dataFields.internalReferenceNumber ?? null;
    const linkedContracts: LinkedContractDto[] | null = dataFields.linkedContracts ?? null;
    const originSourcingEvent: OriginSourcingEventDto | null = dataFields.originSourcingEvent ?? null;
    const paymentTermsInDays: number | null = dataFields.paymentTermsInDays ?? null;
    const pricePerMonth: number | null = dataFields.pricePerMonth ?? null;
    const pricePerYear: number | null = dataFields.pricePerYear ?? null;
    const priority: React.ReactElement | null = dataFields.priority
      ? translationTools.getPriorityTranslation(dataFields.priority)
      : null;
    const purchasingPolicy: string | null = dataFields.purchasingPolicy ?? null;
    const risk: React.ReactElement | null = dataFields.risk
      ? translationTools.getRiskTranslation(dataFields.risk)
      : null;
    const startDate: string | null = dataFields.startDate ?? null;
    const totalLiability: number | null = dataFields.totalLiability ?? null;
    const totalPrice: number | null = dataFields.totalPrice ?? null;
    const warrantyExpirationDate: string | null = dataFields.warrantyExpirationDate ?? null;
    const terminationDate: TerminationDateDto | null = dataFields.terminationDate ?? null;
    const ifrsValues = dataFields.ifrsValues ?? null;
    const internalParties = dataFields.internalParties ?? null;
    const supplierInfo = dataFields.supplierInfo ?? null;
    const supplierCategory = dataFields.supplierCategory ?? null;
    const noticePeriod = dataFields.noticePeriod ?? null;

    switch (name) {
      case "AwardDate":
        return awardDate ? <>{displayDate(awardDate)}</> : null;
      case "RenewalDate":
        return renewalDate ? <>{displayDate(renewalDate)}</> : null;
      case "CounterpartyContactPerson":
        return counterpartyContactPerson?.fullName ? (
          <Flex flexDirection={"column"}>
            {counterpartyContactPerson.fullName && <Text>{counterpartyContactPerson.fullName}</Text>}
            {counterpartyContactPerson.email && <Text>{counterpartyContactPerson.email}</Text>}
            {counterpartyContactPerson.phoneNumber && (
              <Text>{displayPhoneNumber(counterpartyContactPerson.phoneNumber)}</Text>
            )}
          </Flex>
        ) : counterpartyContactPerson?.person ? (
          <Flex flexDirection={"column"}>
            <Text>{displayPersonName(counterpartyContactPerson.person)}</Text>
            {counterpartyContactPerson.person.email && <Text>{counterpartyContactPerson.person.email}</Text>}
            {counterpartyContactPerson.person.phoneNumber && (
              <Text>{displayPhoneNumber(counterpartyContactPerson.person.phoneNumber)}</Text>
            )}
          </Flex>
        ) : (
          noValueProvided
        );
      case "DeliveryTerms":
        return deliveryTerms ? <>{deliveryTerms}</> : noValueProvided;
      case "EstimatedValue":
        return estimatedValue ? (
          <>{`${estimatedValue.toLocaleString()} ${contractState.contract.currency}`}</>
        ) : (
          noValueProvided
        );
      case "EndDate":
        if (expirationDate) {
          if (expirationDate.hasNoEndDate) {
            return <>{t("No end date")}</>;
          } else {
            return expirationDate.date ? <>{displayDate(expirationDate.date)}</> : null;
          }
        }
        return noValueProvided;
      case "ExternalLink":
        return externalLink ? <>{externalLink}</> : noValueProvided;
      case "InternalReferenceNumber":
        return internalReferenceNumber ? <>{internalReferenceNumber}</> : noValueProvided;
      case "LinkedContracts":
        return linkedContracts ? (
          <>
            {linkedContracts.length > 0
              ? linkedContracts.map((linkedContract) => <Text key={linkedContract.id}>{linkedContract.title}</Text>)
              : noValueProvided}
          </>
        ) : (
          noValueProvided
        );
      case "OriginSourcingEvent":
        return originSourcingEvent ? <Text>{originSourcingEvent.title}</Text> : noValueProvided;
      case "PaymentTermsInDays":
        return paymentTermsInDays ? <>{paymentTermsInDays}</> : noValueProvided;
      case "PricePerMonth":
        return pricePerMonth ? <>{pricePerMonth}</> : noValueProvided;
      case "PricePerYear":
        return pricePerYear ? <>{pricePerYear}</> : noValueProvided;
      case "Priority":
        return priority ? <>{priority}</> : noValueProvided;
      case "PurchasingPolicy":
        return purchasingPolicy ? <>{purchasingPolicy}</> : noValueProvided;
      case "Risk":
        return risk ? <>{risk}</> : noValueProvided;
      case "StartDate":
        return startDate ? <>{displayDate(startDate)}</> : noValueProvided;
      case "TotalLiability":
        return totalLiability ? <>{totalLiability}</> : noValueProvided;
      case "TotalPrice":
        return totalPrice ? <>{totalPrice}</> : noValueProvided;
      case "WarrantyExpirationDate":
        return warrantyExpirationDate ? <>{displayDate(warrantyExpirationDate)}</> : noValueProvided;
      case "TerminationDate":
        return terminationDate ? (
          <>
            {displayDate(terminationDate.date)} <br />
            {terminationDate.reason}
          </>
        ) : (
          noValueProvided
        );
      case "IfrsValues":
        return ifrsValues ? (
          <Grid
            templateColumns={"repeat(2, 1fr)"}
            columnGap={"4"}
            rowGap={"2"}
            rounded={"lg"}
            border="1px solid"
            borderColor="smBorder"
            p={"4"}
          >
            <GridItem fontWeight={"bold"} fontSize={"sm"}>
              {t("Start date")}
            </GridItem>
            {ifrsValues.startDate ? <GridItem>{displayDate(ifrsValues.startDate)}</GridItem> : noValueProvided}
            <GridItem fontWeight={"bold"} fontSize={"sm"}>
              {t("Monthly payment")}
            </GridItem>
            <GridItem>
              {ifrsValues.monthlyPayment || ifrsValues.monthlyPayment === 0
                ? displaySum({ value: ifrsValues.monthlyPayment, currency: contractState.contract.currency })
                : noValueProvided}
            </GridItem>
            <GridItem fontWeight={"bold"} fontSize={"sm"}>
              {t("Payment interval")}
            </GridItem>
            <GridItem>
              {ifrsValues.paymentIntervalInMonths
                ? `${ifrsValues.paymentIntervalInMonths} month${ifrsValues.paymentIntervalInMonths > 1 ? "s" : null}`
                : noValueProvided}
            </GridItem>
            <GridItem fontWeight={"bold"} fontSize={"sm"}>
              {t("Duration")}
            </GridItem>
            <GridItem>
              {ifrsValues.contractLengthInMonths
                ? `${ifrsValues.contractLengthInMonths} month${ifrsValues.contractLengthInMonths > 1 ? "s" : null}`
                : noValueProvided}
            </GridItem>
            <GridItem fontWeight={"bold"} fontSize={"sm"}>
              {t("Interest rate")}
            </GridItem>
            <GridItem>
              {ifrsValues.interestRate || ifrsValues.interestRate === 0
                ? `${ifrsValues.interestRate * 100} %`
                : noValueProvided}
            </GridItem>
            {ifrsValues.adjustments?.length > 0 && (
              <>
                <GridItem fontWeight={"bold"} fontSize={"sm"}>
                  {t("Adjustments")}
                </GridItem>
                <GridItem>{ifrsValues.adjustments.length}</GridItem>
              </>
            )}
          </Grid>
        ) : (
          noValueProvided
        );
      case "InternalParties":
        return internalParties ? (
          <Stack>
            {internalParties.parties?.map((party) => (
              <Text key={party.organizationId}>{party.organizationName}</Text>
            ))}
          </Stack>
        ) : null;
      case "SupplierInfo":
        return supplierInfo ? (
          <Grid
            templateColumns={"repeat(3, 1fr)"}
            columnGap={"4"}
            rowGap={"2"}
            rounded={"lg"}
            backgroundColor="smBackground"
            shadow="md"
            p={"4"}
          >
            <GridItem colSpan={2} fontWeight={"bold"} fontSize={"sm"}>
              {t("Is climate committed")}?
            </GridItem>
            <GridItem>{supplierInfo.isClimateCommitted ? t("Yes") : t("No")}</GridItem>
            <GridItem colSpan={2} fontWeight={"bold"} fontSize={"sm"}>
              {t("Has signed code of conduct")}?
            </GridItem>
            <GridItem>{supplierInfo.hasSignedCodeOfConduct ? t("Yes") : t("No")}</GridItem>
            <GridItem colSpan={2} fontWeight={"bold"} fontSize={"sm"}>
              {t("Has customer contact")}?
            </GridItem>
            <GridItem>{supplierInfo.hasCustomerContact ? t("Yes") : t("No")}</GridItem>
          </Grid>
        ) : null;
      case "SupplierCategory":
        return supplierCategory ? <>{supplierCategory}</> : noValueProvided;
      case "NoticePeriod":
        return noticePeriod ? (
          <Text whiteSpace={"nowrap"}>
            {dataFields?.endDate?.date
              ? moment(dataFields?.endDate.date)
                  .add(-(dataFields.noticePeriod ?? 0), "M")
                  .format("DD MMM YYYY")
              : `${dataFields.noticePeriod} ${t("month(s)")}`}
          </Text>
        ) : (
          noValueProvided
        );
      case "Options":
        return (
          <Flex flexDirection={"column"}>
            {dataFields.options?.map((option) => (
              <Box key={option.id} border="1px solid" borderColor="smBorder" rounded="lg" p="4" mb="5">
                <Text fontWeight={"bold"} fontSize={"sm"}>
                  {option.name}
                </Text>
                <Box as="pre" fontFamily={"body"} whiteSpace={"pre-wrap"} fontSize={"sm"} pb="2">
                  {option.description}
                </Box>
                {option.startDate && (
                  <Text fontSize={"sm"}>
                    {t("Start date")}: {displayDate(option.startDate)}
                  </Text>
                )}
                {option.endDate && (
                  <Text fontSize={"sm"}>
                    {t("End date")}: {displayDate(option.endDate)}
                  </Text>
                )}
                {option.deadline && (
                  <Text fontSize={"sm"}>
                    {t("Option execution deadline")}: {displayDate(option.deadline)}
                  </Text>
                )}
                <Flex alignItems={"center"} fontSize={"sm"}>
                  {option.isExecuted ? (
                    <>
                      {t("Executed")} <Icon as={FaCheckSquare} boxSize={4} color={"teal"} ml="2" />
                    </>
                  ) : (
                    <>
                      {t("Not executed")} <Icon as={FaTimesCircle} boxSize={4} ml="2" />
                    </>
                  )}
                </Flex>
              </Box>
            ))}
          </Flex>
        );
      case "Projects":
        return (
          <Flex flexDirection={"column"}>
            {dataFields.projects?.map((p) => (
              <Text key={p.id}>
                {p.externalId} {p.name}
              </Text>
            ))}
          </Flex>
        );
    }
  };

  return (
    <>
      {textDocumentToView && (
        <TextDocumentModal
          textDocument={textDocumentToView}
          onClose={() => setTextDocumentToView(undefined)}
          isUpdating={false}
          editable={false}
        />
      )}
      <Box mt="35px">
        <ReviewRow title={t("Title")} errorMessage={null}>
          {contractState.contract.title}
        </ReviewRow>
        <ReviewRow title={t("Counterparty")} errorMessage={counterpartyErrorMessage}>
          {contractState.contract.counterparty
            ? contractState.contract.counterparty.organization?.name ??
              contractState.contract.counterparty.organizationEntry?.name
            : null}
        </ReviewRow>
        {dataFields.addedDataFields.map((e) => (
          <ReviewRow key={e.name} title={t(getDataFieldTranslationKey(e.name))} errorMessage={null}>
            {getDataFieldValue(e.name) ?? noValueProvided}
          </ReviewRow>
        ))}
        <ReviewRow title={t("Uploaded documents")} showBelow={true} errorMessage={null}>
          <Box w="full" pt="5">
            <DocumentsAsOwnerTable documents={contractState.contract.documentFields?.documents ?? []} />
          </Box>
        </ReviewRow>
        {!!contractState.contract.documentFields?.signedDocuments?.length && (
          <ReviewRow title={t("Signed documents")} showBelow={true} errorMessage={null}>
            <Box w="full" pt="5">
              <SignedDocumentsTable documents={contractState.contract.documentFields.signedDocuments} />
            </Box>
          </ReviewRow>
        )}
        <ReviewRow title={t("Text documents")} showBelow={true} errorMessage={null}>
          <TextDocumentTable
            isLoading={isLoadingTextDocuments}
            documents={data?.documents}
            onClick={setTextDocumentToView}
          />
        </ReviewRow>
        <br />
        <Flex flexDirection={"column"} mb="10" px="2.5">
          <Flex>
            <Button
              variant={"outline"}
              mr="10px"
              w="100%"
              colorScheme={"teal"}
              onClick={previousStep}
              isDisabled={isLoading}
            >
              {t("Previous")}
            </Button>
            {contractState.contract.state === "Published" ? (
              <Button
                variant={"solid"}
                w="100%"
                colorScheme={"blue"}
                isLoading={isLoading}
                onClick={() => {
                  navigate(urls.contracts.viewSingle.go(contractState.id));
                }}
              >
                {t("Done")}
              </Button>
            ) : (
              <Button variant={"solid"} w="100%" colorScheme={"blue"} isLoading={isLoading} onClick={publish}>
                {t("Complete")}
              </Button>
            )}
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
