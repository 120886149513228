import { Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Icon, Spinner } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { TipTap } from "../../../../../../common/input/TipTap/TipTap";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { editBseThunk } from "../../../../../../common/redux/thunks/basic-sourcing-event/edit-bse-thunk";
import { eventIsLive } from "../../../../eventIsLive";
import { useBasicSourcingEventState } from "../../../../useBasicSourcingEventState";

export const Description = () => {
  const dispatch = useAppDispatch();
  const eventState = useBasicSourcingEventState();
  const errorMessage = eventState.description.errorMessage;
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setValue(eventState.description.value);
  }, [eventState.description.value]);

  const eventHandler = useCallback(
    async (value: string | null) => {
      setIsLoading(true);
      await dispatch(
        editBseThunk({
          command: {
            type: "EditDescription",
            value,
          },
        })
      );
      setIsLoading(false);
    },
    [dispatch]
  );

  const valueHasChanged = eventState.description.value !== value;
  const eventIsPublished = eventIsLive(eventState.event);
  const showSaveButton = eventIsPublished && valueHasChanged;

  return (
    <FormControl id="description" isInvalid={errorMessage !== null} isRequired>
      <FormLabel>
        {t("Event description")} {isLoading && <Spinner size={"xs"} />}
      </FormLabel>
      <TipTap
        content={eventState.description.value ?? ""}
        onUpdate={(value) => {
          setValue(value);
          if (!eventIsPublished) {
            eventHandler(value);
          }
        }}
        editable={true}
      />
      <FormHelperText>{t("Describe the scope of this sourcing event")}</FormHelperText>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
      {showSaveButton && (
        <Button
          mt="10px"
          leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
          variant={"solid"}
          colorScheme="teal"
          size={"sm"}
          isLoading={isLoading}
          onClick={() => eventHandler(value)}
        >
          {t("Save")}
        </Button>
      )}
    </FormControl>
  );
};
