import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import { ReactElement, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ContractDto, ContractRoleType, useCreateContractParticipantMutation } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../common/errors/useApiError";
import { RadioFormSelector } from "../../../../common/input/Radio/RadioFormSelector";
import { SelectorValue } from "../../../../common/input/Selector/SelectorValue";
import { SingleFormSelector } from "../../../../common/input/Selector/SingleFormSelector";
import { Explanation } from "../../../../common/support/Explanation/Explanation";
import { displayPersonNameWithEmail } from "./AddExternalParticipantModal";

interface Props {
  data: ContractDto;
  onClose: () => void;
}

interface FormValues {
  person?: SelectorValue;
  roleType: ContractRoleType;
  shouldNotifyByEmail: boolean;
}

export const AddInternalParticipantModal = ({ data, onClose }: Props) => {
  const toast = useToast();
  const displayer = useApiError();
  const authState = useLoggedInWithOrgContextState();

  const [createParticipant] = useCreateContractParticipantMutation();

  const methods = useForm<FormValues>({
    defaultValues: { roleType: "Editor", shouldNotifyByEmail: true },
  });
  const [isLoading, setIsLoading] = useState(false);

  const create = async (values: FormValues) => {
    setIsLoading(true);
    const result = await createParticipant({
      contractId: data.id,
      createContractParticipantRequest: {
        organizationId: authState.selectedOrg.id,
        personId: values.person?.value,
        roleType: values.roleType,
        shouldNotifyByEmail: values.shouldNotifyByEmail,
      },
    });
    if ("data" in result) {
      toast({ title: t("Participant added!"), status: "success" });
      onClose();
    } else displayer.trigger(result.error);
  };

  const options: {
    label: string;
    description: ReactElement;
    value: ContractRoleType;
  }[] = [
    {
      label: t("Editor"),
      description: (
        <Text fontSize="sm" color="smMuted">
          {t("Can see and edit everything")}.
        </Text>
      ),
      value: "Editor",
    },
    {
      label: t("Viewer"),
      description: (
        <Text fontSize="sm" color="smMuted">
          {t("Can see everything, but not edit anything")}.
        </Text>
      ),
      value: "Viewer",
    },
  ];

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Share contract internally")}</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(create)}>
            <ModalBody>
              <SingleFormSelector<FormValues>
                options={authState.selectedOrg.users.map((p) => ({
                  label: displayPersonNameWithEmail(p.person),
                  value: p.person.id,
                }))}
                size="sm"
                label={`${t("Share with person in your organization")} (${t("optional")})`}
                control={methods.control}
                name={"person"}
                placeholderText={`${t("Select a person from your organization")}...`}
                noMatchingOptionsMessage={t("No matching persons")}
                noOptionsProvidedMessage={t("No persons available")}
                errorMessage={undefined}
                helperText={`${t("Select a specific person to share the contract with")}. 
                  ${
                    authState.selectedOrg.canOnlySeeCreatedContracts
                      ? ""
                      : t("If no person is selected, the contract will be shared with all users in your organization.")
                  }
                `}
              />
              <Box pt="6">
                <RadioFormSelector options={options} label={t("Role")} control={methods.control} name={"roleType"} />
              </Box>
              <FormControl pt="6">
                <Controller<FormValues>
                  name={"shouldNotifyByEmail"}
                  control={methods.control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox isChecked={!!value} name="shouldNotifyByEmail" onChange={onChange}>
                      {t("Send email notification to participant(s)")}
                    </Checkbox>
                  )}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose} isDisabled={isLoading}>
                {t("Cancel")}
              </Button>
              <Explanation
                enabled={!methods.watch("person") && !authState.selectedOrg.canOnlySeeCreatedContracts}
                text={t("If no person is selected, the contract will be shared with all users in your organization")}
              >
                <Button
                  type="submit"
                  variant="solid"
                  colorScheme={"blue"}
                  isLoading={isLoading}
                  isDisabled={!methods.watch("person") && authState.selectedOrg.canOnlySeeCreatedContracts}
                >
                  {t("Add")}
                </Button>
              </Explanation>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};
