import { useTranslation } from "react-i18next";
import { DateFormControl } from "../../../../../../common/input/DateTimeSelectors/DateFormControl";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { requireDataFields } from "../../../../typeguards";
import { useContractState } from "../../../useContractState";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const AwardDate = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const contractState = useContractState();
  const dataFields = requireDataFields(contractState.contract);
  const errorMessage = containsError(contractState, "EditAwardDate") ? t("Update failed") : null;

  return (
    <DateFormControl
      id={"awardDate"}
      title={t("Award date")}
      helperText={t("Provide an award date")}
      defaultDate={dataFields.awardDate}
      errorMessage={errorMessage}
      onChange={async (newDate) => {
        await dispatch(
          editContractThunk({
            command: {
              type: "EditAwardDate",
              value: newDate,
            },
          })
        );
      }}
      removeDataField={removeDataField}
      isRequiredButNotProvided={isRequiredButNotProvided}
    />
  );
};
