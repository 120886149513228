import { t } from "i18next";
import { useMemo } from "react";

export const useProjectTypes = () => {
  const projectTypes = useMemo(() => {
    return [
      {
        value: "08ddada0-4372-4a05-9959-387c1b31f71b",
        label: t("BidCalculation"),
      },
      {
        value: "92a87303-235b-4c2a-911e-0e252cb7ccf3",
        label: t("Other"),
      },
    ];
  }, []);
  return projectTypes;
};
