import { Box, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, useToast } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ContractDataFieldNameDto } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { setCustomFields } from "../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../common/redux/thunks/contract/edit-contract-thunk";
import { CustomContractFieldSections, CustomFieldType } from "../../../../common/types";
import { useContractState } from "../useContractState";
import { DataFieldFormSelector } from "./optional/DataFieldFormSelector";
import { ContractFieldOption } from "./optional/DataFieldSelector";

interface Props {
  alreadyAddedFields: ContractDataFieldNameDto[];
  alreadyAddedCustomFields: { sectionId: string; fieldId: string }[];
  onClose: () => void;
}

interface FormValues {
  fields: ContractFieldOption[];
}

export const AddDataFieldModal = ({ alreadyAddedFields, alreadyAddedCustomFields, onClose }: Props) => {
  const methods = useForm<FormValues>({ defaultValues: { fields: [] } });
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const {
    contract: {
      dataFields: { customFields: currentCustomFields },
    },
  } = useContractState();
  const {
    selectedOrg: { customContractSections: sectionTemplates },
  } = useLoggedInWithOrgContextState();

  const create = async (values: FormValues) => {
    const defaultFields = values.fields.filter((field) => !field.sectionId);
    const customFields = values.fields.filter((field) => field.sectionId);
    if (defaultFields.length > 0) {
      await dispatch(
        editContractThunk({
          command: { type: "AddDataFields", value: defaultFields.map((e) => e.name as ContractDataFieldNameDto) },
        })
      );
    }
    const sections = customFields.reduce((map: CustomContractFieldSections, field) => {
      const sectionId = field.sectionId as string;
      const contractSection = currentCustomFields?.[sectionId];
      const templateSection = sectionTemplates?.[sectionId];
      if (!contractSection && !templateSection) {
        throw Error("Contract section does not exist on current org or in current contract");
      }
      map[sectionId] = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...(contractSection ?? templateSection)!,
        fields: [
          ...(map[sectionId]?.fields ?? contractSection?.fields ?? []),
          {
            id: field.fieldId as string,
            type: field.type as CustomFieldType,
            name: field.name,
            isInternal: templateSection?.fields.find((f) => f.id === field.fieldId)?.isInternal ?? false,
            ...(field.type === CustomFieldType.CustomEnum || field.type === CustomFieldType.CustomMultiEnum
              ? { options: field.options }
              : {}),
          },
        ],
      };
      return map;
    }, { ...currentCustomFields } ?? {});
    if (customFields.length > 0) {
      dispatch(setCustomFields(sections));
      await dispatch(
        editContractThunk({
          command: {
            type: "EditCustomFields",
            value: sections,
          },
        })
      );
    }
    toast({ title: t("Data fields added!"), status: "success" });
    onClose();
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Add new data fields")}</ModalHeader>
        <Box mt="-20px">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(create)}>
              <ModalBody>
                <DataFieldFormSelector
                  alreadyAddedFields={alreadyAddedFields}
                  alreadyAddedCustomFields={alreadyAddedCustomFields}
                  control={methods.control}
                  name="fields"
                  helperText={""}
                  showAddSelectedButton={true}
                  autoFocus={true}
                  errorMessage={methods.formState.errors.fields?.message}
                />
              </ModalBody>
            </form>
          </FormProvider>
        </Box>
      </ModalContent>
    </Modal>
  );
};
