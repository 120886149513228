import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TipTap } from "../../../../common/input/TipTap/TipTap";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { editBasicBidThunk } from "../../../../common/redux/thunks/basic-bid/edit-basic-bid-thunk";
import { useBasicBidState } from "../../../sourcing-events/useBasicBidEventState";

export const Comment = () => {
  const dispatch = useAppDispatch();
  const bidState = useBasicBidState();
  const value = bidState.comment.value;
  const errorMessage = bidState.comment.errorMessage;
  const { t } = useTranslation();

  return (
    <>
      <FormControl id="description" mt={"10px"} isInvalid={errorMessage !== null} p="1">
        <FormLabel>{t("Bid comment")}</FormLabel>
        <TipTap
          content={value ?? ""}
          onUpdate={(value) => {
            dispatch(
              editBasicBidThunk({
                command: {
                  type: "EditComment",
                  value: value,
                },
              })
            );
          }}
          editable={true}
        />
        <FormHelperText>{t("Please provide a short comment to your bid")}</FormHelperText>
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </FormControl>
    </>
  );
};
