import { Box, Flex, Skeleton, SkeletonCircle, Stack } from "@chakra-ui/react";
import React from "react";

export const useSkeletons = (): {
  stackedLines: (padding: number, rows?: number) => React.ReactElement;
} => {
  return {
    stackedLines: (padding: number, rows = 3) => (
      <Box w="100%" h="100%" padding={`${padding}px`}>
        <Stack>
          {Array(rows)
            .fill(0)
            .map((_, i) => (
              <Flex key={i}>
                <Skeleton
                  height="16"
                  rounded="full"
                  startColor="smBackground"
                  endColor="smBackgroundSecondary"
                  flexGrow={1}
                  mr="2"
                />
                <SkeletonCircle height={"16"} width={"16"} startColor="smBackground" endColor="smBackgroundSecondary" />
              </Flex>
            ))}
        </Stack>
      </Box>
    ),
  };
};
