import { Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Icon, Spinner } from "@chakra-ui/react";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { DateTimeSelector } from "../../../../../../common/input/DateTimeSelectors/DateTimeSelector";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { editBseThunk } from "../../../../../../common/redux/thunks/basic-sourcing-event/edit-bse-thunk";
import { eventIsLive } from "../../../../eventIsLive";
import { useBasicSourcingEventState } from "../../../../useBasicSourcingEventState";

export const DeadlineSelector = () => {
  const [selectedDate, setSelectedDate] = useState<string>();
  const dispatch = useAppDispatch();
  const eventState = useBasicSourcingEventState();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const deadline = eventState.deadline.value;
  const errorMessage = eventState.deadline.errorMessage || eventState.timezone.errorMessage;

  const deadlineEventHandler = useCallback(
    async (value?: string) => {
      setIsLoading(true);
      await dispatch(
        editBseThunk({
          command: {
            type: "EditDeadline",
            value,
          },
        })
      );
      setIsLoading(false);
    },
    [dispatch]
  );

  const debouncedDeadlineEventHandler = useMemo(
    () =>
      debounce(async (value?: string) => {
        await deadlineEventHandler(value);
      }, 300),
    [deadlineEventHandler]
  );

  const timezoneEventHandler = useCallback(
    async (value: string) => {
      setIsLoading(true);
      await dispatch(
        editBseThunk({
          command: {
            type: "EditTimezone",
            value: value,
          },
        })
      );
      setIsLoading(false);
    },
    [dispatch]
  );

  const debouncedTimezoneEventHandler = useMemo(
    () =>
      debounce(async (value: string) => {
        await timezoneEventHandler(value);
      }, 300),
    [timezoneEventHandler]
  );

  useEffect(() => {
    return () => {
      debouncedTimezoneEventHandler.cancel();
    };
  }, [debouncedTimezoneEventHandler]);

  const valuesHaveChanged = (): boolean => {
    const existingDeadline = deadline ? moment(deadline).toISOString() : undefined;
    if (existingDeadline !== selectedDate) return true;
    return false;
  };

  const eventIsPublished = eventIsLive(eventState.event);
  const valuesHaveBeenChanged = valuesHaveChanged();
  const showSaveButton = eventIsPublished && valuesHaveBeenChanged;

  return (
    <>
      <FormControl id="deadline" isInvalid={errorMessage !== null} isRequired>
        <FormLabel>
          {t("Deadline")} {isLoading && <Spinner size="xs" />}
        </FormLabel>
        <DateTimeSelector
          defaultDate={deadline}
          onChange={(date) => {
            setSelectedDate(date);
            if (!eventIsPublished) debouncedDeadlineEventHandler(date);
          }}
        />
        <FormHelperText>
          {t("Please select a deadline for the event")}. {t("Timezone")}:{" "}
          {Intl.DateTimeFormat().resolvedOptions().timeZone}
        </FormHelperText>
        {showSaveButton && (
          <Button
            mt="10px"
            leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
            variant={"solid"}
            colorScheme="teal"
            size={"sm"}
            isLoading={isLoading}
            onClick={async () => {
              if (selectedDate) await deadlineEventHandler(selectedDate);
            }}
          >
            {t("Save")}
          </Button>
        )}
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </FormControl>
    </>
  );
};
