import { Flex, Heading, Icon, IconButton, Spacer, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useListContractTasksForOrganizationQuery } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { useSkeletons } from "../../../common/useSkeletons";
import { urls } from "../../../urls";
import { Task } from "./Task";

export const UpcomingContractTasks = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();
  const skeletons = useSkeletons();
  const { data, error } = useListContractTasksForOrganizationQuery({
    organizationId: authState.selectedOrg.id,
    orgId: authState.selectedOrg.id,
    personIds: authState.me.id,
    limit: 5,
    statuses: "Open",
  });
  const displayer = useApiError();

  if (error) return displayer.show(error);

  if (!data) return skeletons.stackedLines(5);

  return (
    <>
      <Heading as="h2" fontSize={"sm"} color="#8c98aa">
        {t("Your next tasks")}
      </Heading>
      <Flex flexDirection={"column"}>
        {data.tasks.map((e) => (
          <Task key={e.id} task={e} />
        ))}
        {data.tasks.length === 0 && (
          <Flex pt="10px">
            <Text fontSize={"sm"} color="#8c9098" fontStyle={"italic"}>
              {t("You have no upcoming tasks")}
            </Text>
          </Flex>
        )}
        {data && (
          <Flex py="4">
            <Spacer />
            <IconButton
              aria-label="go to contracts"
              icon={<Icon as={FaArrowRight} />}
              variant={"link"}
              colorScheme="cyan"
              float="right"
              onClick={() => {
                navigate(urls.contracts.viewTasks);
              }}
            />
          </Flex>
        )}
      </Flex>
    </>
  );
};
