import { Box, Button, Collapse, Flex, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  EmailInviteDto,
  OrganizationInviteDto,
  TextDocumentDto,
  useListTextDocumentsForSourcingEventQuery,
  usePublishBasicSourcingEventMutation,
} from "../../../../../autogen/bff-api";
import { DocumentsAsOwnerTable } from "../../../../../common/documents/DocumentsAsOwnerTable";
import { TextDocumentModal } from "../../../../../common/documents/TextDocumentModal";
import { ReviewRow } from "../../../../../common/editing/ReviewRow/ReviewRow";
import { useApiError } from "../../../../../common/errors/useApiError";
import { displayDateWithTime } from "../../../../../common/formatting/displayDateWithTime";
import { TextDocumentTable } from "../../../../../common/input/TipTap/TextDocumentTable";
import { TipTap } from "../../../../../common/input/TipTap/TipTap";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { setSourcingEvent } from "../../../../../common/redux/reducers/basicSourcingEventReducer";
import { useSub } from "../../../../../common/subscription/useSub";
import { urls } from "../../../../../urls";
import { useBasicSourcingEventState } from "../../../useBasicSourcingEventState";
interface Props {
  previousStep: () => void;
}

export const Review = ({ previousStep }: Props) => {
  const eventState = useBasicSourcingEventState();
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [publishEvent, { isLoading }] = usePublishBasicSourcingEventMutation();
  const { data, isLoading: isLoadingTextDocuments } = useListTextDocumentsForSourcingEventQuery({
    eventId: eventState.id,
  });
  const [textDocumentToView, setTextDocumentToView] = useState<TextDocumentDto>();
  const displayer = useApiError();
  const dispatch = useAppDispatch();
  const sub = useSub();

  const [showFullDescription, setShowFullDescription] = useState(false);

  const publish = async () => {
    if (isValid()) {
      const result = await publishEvent({
        eventId: eventState.id,
      });
      if ("data" in result) {
        toast({
          title: t("Event published!"),
          description: t("Your event is now live, and the invitations have been sent"),
          status: "success",
        });
        dispatch(setSourcingEvent(result.data));
        navigate(urls.events.viewOngoing);
      } else {
        displayer.trigger(result.error);
      }
    } else {
      toast({
        title: t("One or more values are invalid!"),
        description: t("Please fix missing or invalid values"),
        status: "warning",
      });
    }
  };

  const isValid = (): boolean => {
    return descriptionErrorMessage === null && deadlineErrorMessage === null && invitesErrorMessage === null;
  };

  const getDescriptionErrorMessage = (value: string | null): string | null => {
    if (value === null) {
      return t("Please provide a description");
    } else if (value.length < 2) {
      return t("Please provide a longer description");
    }
    return null;
  };

  const getDeadlineErrorMessage = (value?: string | null): string | null => {
    if (value === null) {
      return t("Please provide a deadline");
    }
    return null;
  };

  const getInvitesErrorMessage = (
    organizationInvites: OrganizationInviteDto[],
    emailInvites: EmailInviteDto[]
  ): string | null => {
    if (organizationInvites.length + emailInvites.length === 0) {
      return t("Please add at least 1 invited organization or email");
    }

    return null;
  };

  const descriptionErrorMessage = getDescriptionErrorMessage(eventState.description.value);
  const deadlineErrorMessage = getDeadlineErrorMessage(eventState.deadline.value);
  const invitesErrorMessage = getInvitesErrorMessage(
    eventState.organizationInvites.value,
    eventState.emailInvites.value
  );

  return (
    <>
      {textDocumentToView && (
        <TextDocumentModal
          textDocument={textDocumentToView}
          onClose={() => setTextDocumentToView(undefined)}
          isUpdating={false}
          editable={false}
        />
      )}
      <Box mt="35px">
        <ReviewRow title={t("Title")} errorMessage={null}>
          {eventState.title.value}
        </ReviewRow>
        <ReviewRow title={t("Description")} errorMessage={descriptionErrorMessage}>
          <Flex flexDirection="column" alignItems="start">
            <Collapse startingHeight="2rem" in={showFullDescription}>
              {eventState.description.value && (
                <TipTap content={eventState.description.value ?? ""} editable={false} hasBorder={false} padding="0" />
              )}
            </Collapse>
            <Button size="sm" variant={"link"} mt="5px" onClick={() => setShowFullDescription(!showFullDescription)}>
              {!showFullDescription ? t("Show more") : t("Show less")}
            </Button>
          </Flex>
        </ReviewRow>
        <ReviewRow title={t("Deadline")} errorMessage={deadlineErrorMessage}>
          {eventState.deadline.value && displayDateWithTime(eventState.deadline.value, t("at"))}
        </ReviewRow>
        <ReviewRow title={t("Documents")} showBelow={true} errorMessage={null}>
          <Box w="100%" mt="20px">
            <DocumentsAsOwnerTable documents={eventState.documents.value} />
          </Box>
        </ReviewRow>
        <ReviewRow title={t("Text documents")} showBelow={true} errorMessage={null}>
          <TextDocumentTable
            isLoading={isLoadingTextDocuments}
            documents={data?.documents}
            onClick={setTextDocumentToView}
            isDownloadable
          />
        </ReviewRow>
        <ReviewRow title={t("Invited companies")} showBelow={false} errorMessage={invitesErrorMessage}>
          <Flex flexDirection={"column"}>
            {eventState.organizationInvites.value.length > 0 ? (
              eventState.organizationInvites.value.map((e) => <div key={e.id}>{e.organizationName}</div>)
            ) : (
              <i>{t("None")}</i>
            )}
          </Flex>
        </ReviewRow>
        <ReviewRow title={t("Email invitations")} showBelow={false} errorMessage={invitesErrorMessage}>
          <Flex flexDirection={"column"}>
            {eventState.emailInvites.value.length > 0 ? (
              eventState.emailInvites.value.map((e) => <div key={e.id}>{e.email}</div>)
            ) : (
              <i>{t("None")}</i>
            )}
          </Flex>
        </ReviewRow>
        {sub.hasProjectAccess && (
          <ReviewRow title={t("Projects")} showBelow={false} errorMessage={eventState.projects.errorMessage}>
            <Flex flexDirection={"column"}>
              {eventState.projects.values?.length ? (
                eventState.projects.values.map((p) => (
                  <div key={p.id}>{`${p.externalId ? `${p.externalId} - ` : ""}${p.name}`}</div>
                ))
              ) : (
                <i>{t("None")}</i>
              )}
            </Flex>
          </ReviewRow>
        )}
        <br />
        <Flex pb="10" flexDirection={"column"} px="2.5">
          <Flex>
            <Button
              variant={"outline"}
              mr="10px"
              w="100%"
              colorScheme={"teal"}
              onClick={previousStep}
              isDisabled={isLoading}
            >
              {t("Previous")}
            </Button>
            {eventState.event.stateName === "Draft" && (
              <Button variant={"solid"} w="100%" colorScheme={"blue"} isLoading={isLoading} onClick={publish}>
                {t("Go live!")}
              </Button>
            )}
            {eventState.event.stateName !== "Draft" && (
              <Button
                variant={"solid"}
                w="100%"
                colorScheme={"blue"}
                isLoading={isLoading}
                onClick={() => navigate(urls.events.view.go(eventState.id))}
              >
                {t("Back to event")}
              </Button>
            )}
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
