import { Alert, AlertDescription, AlertTitle, Flex, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { ContractDataFieldNameDto, CustomFieldIdentifierDto } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useTranslationTools } from "../../../../common/useTranslationTools";

interface Props {
  missingDataFields: ContractDataFieldNameDto[];
  missingCustomFields?: CustomFieldIdentifierDto[];
}

export const MissingDataFieldsBanner = ({ missingDataFields, missingCustomFields }: Props) => {
  const { t } = useTranslation();
  const translationTools = useTranslationTools();

  const authState = useLoggedInWithOrgContextState();

  return (
    <Alert
      mt="10px"
      status="warning"
      variant="subtle"
      flexDirection="column"
      alignItems="start"
      justifyContent="start"
      textAlign="center"
      borderRadius={"10px"}
      width={"full"}
      maxWidth={"full"}
      overflowWrap={"break-word"}
    >
      <Flex flexDirection={"row"}>
        <AlertTitle>{t("Missing values")}</AlertTitle>
      </Flex>
      <AlertDescription textAlign={"left"} width={"full"} maxWidth={"full"} fontSize={"sm"}>
        {missingDataFields.map((e) => translationTools.getDataFieldTranslation(e)).join(", ")}
        {Object.values(authState.selectedOrg.customContractSections ?? {})
          .filter((s) => missingCustomFields?.find((m) => m.sectionId === s.id))
          .map((section) => (
            <Fragment key={section.id}>
              <Text fontWeight={"bold"} fontSize={"xs"}>
                {section.name}
              </Text>
              {section.fields
                .filter((f) => !!missingCustomFields?.find((m) => m.fieldId === f.id && m.sectionId === section.id))
                .map((f) => (
                  <Text key={f.id}>{f.name}</Text>
                ))}
            </Fragment>
          ))}
      </AlertDescription>
    </Alert>
  );
};
