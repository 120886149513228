import { Box, Flex } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BseQuestionDto } from "../../../../../autogen/bff-api";
import { AddSupplierQuestionComment } from "./AddSupplierQuestionComment";
import { SupplierQuestionComment } from "./SupplierQuestionComment";

interface Props {
  addCommentDisabledReason: string | null;
  question: BseQuestionDto;
  addComment: (values: { comment: string; createAnnouncementDraft: boolean }) => Promise<boolean>;
  showCreateAnnouncementDraft: boolean;
}

export const SupplierQuestionComments = ({
  addCommentDisabledReason,
  question,
  addComment,
  showCreateAnnouncementDraft,
}: Props) => {
  const { t } = useTranslation();
  const comments = useMemo(() => {
    const sortedComments = question.comments.slice().sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));

    sortedComments.unshift({
      id: "original_question",
      createdAt: question.askedAt,
      commenter: question.askedByPerson,
      content: question.content,
    });

    return sortedComments;
  }, [question.askedAt, question.askedByPerson, question.comments, question.content]);

  return (
    <Flex flexDirection={"column"} grow={1} overflow={"auto"}>
      <Box pt="2">
        {comments.map((comment) => (
          <Box key={comment.id} mt="10px">
            <SupplierQuestionComment
              isOriginalQuestion={comment.id === "original_question"}
              sentAt={comment.createdAt}
              sender={comment.commenter}
              content={comment.content}
            />
          </Box>
        ))}
      </Box>
      <Box mt="20px">
        <AddSupplierQuestionComment
          addCommentDisabledReason={
            question.status === "Resolved"
              ? t("This question is resolved and can no longer be commented on")
              : addCommentDisabledReason !== null
              ? addCommentDisabledReason
              : null
          }
          addComment={addComment}
          question={question}
          showCreateAnnouncementDraft={showCreateAnnouncementDraft}
        />
      </Box>
    </Flex>
  );
};
