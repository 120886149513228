import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RadioOption, RadioSelector } from "./RadioSelector";

interface Props<T extends FieldValues> {
  options: RadioOption[];
  label: string;
  helperText?: string;
  control: Control<T>;
  name: FieldPath<T>;
}

export const RadioFormSelector = <T extends FieldValues>({ options, label, helperText, control, name }: Props<T>) => {
  const { t } = useTranslation();
  return (
    <FormControl borderRadius={"10px"}>
      <FormLabel fontSize={"sm"} htmlFor={name}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        rules={{
          required: {
            value: true,
            message: t("Please select a role"),
          },
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <RadioSelector
              value={value}
              options={options}
              onChange={(value) => {
                onChange(value.value);
              }}
            />
          );
        }}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
