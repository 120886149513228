import { Button, Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";

interface Props {
  title: string;
  completed: boolean;
  onClick: () => void;
}

export const PersonalProfileTask = ({ title, completed, onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <Grid ml="10px" templateColumns={"50px 1fr 80px"} templateRows="1fr">
      <GridItem
        backgroundColor={!completed ? "smBackgroundSecondary" : "smPrimarySoft"}
        h="50px"
        w="50px"
        borderRadius={"50%"}
        borderStyle={!completed ? "dashed" : undefined}
        borderColor={!completed ? "smPrimarySoft" : undefined}
        borderWidth={!completed ? "2px" : undefined}
        display="flex"
        alignItems={"center"}
        justifyContent="center"
      >
        {completed && <Icon as={FaCheck} w="15px" h="15px" color="smBackgroundSecondary" />}
      </GridItem>
      <GridItem ml="10px" display={"flex"} alignItems="center">
        <Text fontSize={"sm"} color="smMuted">
          {title}
        </Text>
      </GridItem>
      <GridItem ml="10px" display={"flex"} mt="5px">
        {!completed && (
          <Button onClick={onClick} size="sm" colorScheme="teal">
            {t("Fix now")}
          </Button>
        )}
      </GridItem>
    </Grid>
  );
};
