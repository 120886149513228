import { Box, Button, Flex, Grid, GridItem, Heading, Icon, IconButton, Spacer, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaArrowRight, FaPlay } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useListEventsForBuyerQuery } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { useSub } from "../../../common/subscription/useSub";
import { useSkeletons } from "../../../common/useSkeletons";
import { urls } from "../../../urls";
import { useCreateBasicSourcingEventModal } from "../../sourcing-events/buyer/useCreateBasicSourcingEventModal";
import { LatestEvent } from "./Event";
import { Filter, LatestEventsFilterType } from "./Filter";

export const LatestEvents = () => {
  const navigate = useNavigate();
  const authState = useLoggedInWithOrgContextState();
  const skeletons = useSkeletons();
  const sub = useSub();
  const { data, error } = useListEventsForBuyerQuery({ orgId: authState.selectedOrg.id, status: "Ongoing", limit: 5 });
  const { showCreateBasicSourcingEventModal, createBasicSourcingEventModal } = useCreateBasicSourcingEventModal();
  const [filter, setFilter] = useState<LatestEventsFilterType>("all");
  const displayer = useApiError();

  if (error) {
    return displayer.show(error);
  }

  const filteredEvents = data?.events.filter((e) => {
    switch (filter) {
      case "all":
        return true;
      case "mine":
        return authState.me.id === e.createdBy.id;
    }
  });

  return (
    <Box
      flexDirection={"column"}
      alignItems="start"
      justifyContent={"start"}
      borderRadius={"10px"}
      boxShadow="0 3px 15px rgba(71, 85, 105, 0.16)"
      padding="20px"
      mt="15px"
      backgroundColor="smBackground"
    >
      {createBasicSourcingEventModal}
      <Grid
        gridTemplateColumns="50px 90px"
        gridTemplateAreas={`
                "icon title title"
                "unused1 events events"
                `}
      >
        <GridItem area="icon">
          <Box
            bg="smPrimarySoft"
            h="50px"
            w="50px"
            borderRadius={"50%"}
            display="flex"
            alignItems={"center"}
            justifyContent="center"
          >
            <Icon as={FaPlay} w="18px" h="18px" color="smBackground" />
          </Box>
        </GridItem>
        <GridItem area="title" display={"flex"}>
          <Heading as="h2" fontSize="3xl" fontWeight={"semibold"} ml="10px" mt="7px">
            {t("Ongoing events")}
          </Heading>
          <Spacer />
          <Flex alignItems={"end"}>
            <Box w="120px" mb="5px">
              <Filter
                onChange={(e) => {
                  setFilter(e);
                }}
              />
            </Box>
          </Flex>
        </GridItem>
        <GridItem area="events">
          {!data && skeletons.stackedLines(10, 5)}
          {filteredEvents && (
            <>
              {filteredEvents.map((event) => (
                <LatestEvent key={event.id} event={event} />
              ))}
              {filteredEvents.length === 0 && (
                <Text fontSize={"md"} ml="10px" color="smMuted" fontStyle={"italic"} mt="10px">
                  {t("You have no ongoing events right now!")}
                  {sub.canCreateEvents && (
                    <>
                      {" "}
                      {t("Get started by")}{" "}
                      <Button
                        variant={"link"}
                        colorScheme="teal"
                        fontStyle={"italic"}
                        onClick={showCreateBasicSourcingEventModal}
                      >
                        {t("creating one!")}
                      </Button>
                    </>
                  )}
                </Text>
              )}
            </>
          )}
          {data && (
            <Flex pt="4">
              <Spacer />
              <IconButton
                aria-label="go to sourcing events"
                icon={<Icon as={FaArrowRight} />}
                variant={"link"}
                colorScheme="cyan"
                float="right"
                onClick={() => {
                  navigate(urls.events.viewOngoing);
                }}
              />
            </Flex>
          )}
        </GridItem>
      </Grid>
    </Box>
  );
};
