import { extendTheme } from "@chakra-ui/react";
import { OryAuthService } from "./OryAuthService";

export const localDateFormat = "yyyy-MM-DD";

export const CONTENT_WIDTH = "1280px";
export const DATES_COLOR = "rgba(128, 90, 213, 0.06)";
export const FINANCIAL_COLOR = "rgba(57, 161, 105, 0.06)";
export const COMMERCIAL_COLOR = "rgba(49, 130, 206, 0.06)";
export const TERMS_AND_CONDITIONS_COLOR = "rgba(229, 62, 63, 0.06)";
export const OTHER_COLOR = "rgba(49, 151, 149, 0.06)";
export const OPTIONS_COLOR = "rgba(0, 0, 149, 0.06)";

export const authService = new OryAuthService();

type StringVarName =
  | "VITE_WEBAPP_BASE_URL"
  | "VITE_BFF_BASE_URL"
  | "VITE_ORY_BASE_URL"
  | "VITE_S3_BASE_URL"
  | "VITE_PUSHER_CLUSTER"
  | "VITE_PUSHER_KEY"
  | "VITE_DATADOG_APPLICATION_ID"
  | "VITE_DATADOG_CLIENT_TOKEN"
  | "VITE_DATADOG_ENV"
  | "VITE_INTERCOM_APP_ID"
  | "VITE_INTERCOM_BASE_URL";

export const requireStringEnvVar = (varName: StringVarName): string => {
  switch (varName) {
    case "VITE_BFF_BASE_URL":
      return import.meta.env.VITE_BFF_BASE_URL;
    case "VITE_WEBAPP_BASE_URL":
      return import.meta.env.VITE_WEBAPP_BASE_URL;
    case "VITE_ORY_BASE_URL":
      return import.meta.env.VITE_ORY_BASE_URL;
    case "VITE_S3_BASE_URL":
      return import.meta.env.VITE_S3_BASE_URL;
    case "VITE_PUSHER_CLUSTER":
      return import.meta.env.VITE_PUSHER_CLUSTER;
    case "VITE_PUSHER_KEY":
      return import.meta.env.VITE_PUSHER_KEY;
    case "VITE_DATADOG_APPLICATION_ID":
      return import.meta.env.VITE_DATADOG_APPLICATION_ID;
    case "VITE_DATADOG_CLIENT_TOKEN":
      return import.meta.env.VITE_DATADOG_CLIENT_TOKEN;
    case "VITE_DATADOG_ENV":
      return import.meta.env.VITE_DATADOG_ENV;
    case "VITE_INTERCOM_APP_ID":
      return import.meta.env.VITE_INTERCOM_APP_ID;
    case "VITE_INTERCOM_BASE_URL":
      return import.meta.env.VITE_INTERCOM_BASE_URL;
  }
};

type BooleanVarName = "VITE_ENABLE_DUMMY_COMPANY_PAGE";

export const requireBooleanEnvVar = (varName: BooleanVarName): boolean => {
  switch (varName) {
    case "VITE_ENABLE_DUMMY_COMPANY_PAGE":
      return import.meta.env.VITE_ENABLE_DUMMY_COMPANY_PAGE === "true";
  }
};

export type Locale = "no" | "en";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

export const theme = extendTheme({
  config,
  semanticTokens: {
    colors: {
      smPrimary: {
        default: "teal.500",
        _dark: "teal.200",
      },
      smPrimarySoft: {
        default: "teal.400",
        _dark: "teal.300",
      },
      smPrimarySofter: {
        default: "teal.100",
        _dark: "teal.900",
      },
      smSecondary: {
        default: "blue.400",
        _dark: "blue.100",
      },
      smTertiary: {
        default: "purple.500",
        _dark: "purple.200",
      },
      smTertiarySoft: {
        default: "purple.100",
        _dark: "purple.900",
      },
      smBackground: {
        default: "white",
        _dark: "gray.800",
      },
      smBackgroundSecondary: {
        default: "gray.100",
        _dark: "gray.900",
      },
      smBackgroundTertiary: {
        default: "gray.300",
        _dark: "gray.700",
      },
      smModalBackground: {
        default: "white",
        _dark: "gray.900",
      },
      smBorder: {
        default: "gray.200",
        _dark: "gray.600",
      },
      smMuted: {
        default: "gray.600",
        _dark: "gray.400",
      },
      smTealShadow: {
        default: "teal.100",
        _dark: "teal.800",
      },
      smBlueShadow: {
        default: "blue.100",
        _dark: "blue.800",
      },
    },
    shadows: {
      inner: {
        default: "inset 0 2px 4px 0 rgba(0,0,0,0.06), inset 0 -1px 2px 0 rgba(0,0,0,0.06)",
        _dark: "inset 0px 2px 4px 0 rgba(255,255,255,0.06), inset 0px -1px 2px 0 rgba(255,255,255,0.06)",
      },
    },
  },
});
