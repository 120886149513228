import { Td, Text, Tr } from '@chakra-ui/react'
import React from 'react'
import {
    AcceptedInfoDto,
    SupplierEmailInviteDto,
} from '../../../autogen/bff-api'

interface Props {
    invite: SupplierEmailInviteDto
    acceptedInfo: AcceptedInfoDto
}

export const AcceptedInviteRow: React.FC<Props> = ({
    invite,
    acceptedInfo,
}) => {
    return (
        <Tr key={invite.id}>
            <Td>
                <Text>{invite.email}</Text>
                <Text>
                    {invite.personFirstName} {invite.personLastName}
                </Text>
                <Text>{invite.organizationName}</Text>
            </Td>
            <Td>
                <Text>{acceptedInfo.personEmail}</Text>
                <Text>
                    {acceptedInfo.personFirstName} {acceptedInfo.personLastName}
                </Text>
                <Text>{acceptedInfo.organizationName}</Text>
            </Td>
        </Tr>
    )
}
