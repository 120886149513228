import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SignerDto, TextDocumentDto, ViewDocumentAsOwnerDto } from "../../../../../autogen/bff-api";
import { SelectorValue } from "../../../../../common/input/Selector/SelectorValue";
import { CreateSignedDocument } from "./CreateSignedDocument";

interface Props {
  contractId: string;
  mainDocumentId: string;
  documents: ViewDocumentAsOwnerDto[];
  textDocuments?: TextDocumentDto[];
  sendForSigning: (values: {
    mainDocumentId: string;
    attachments?: SelectorValue[];
    signers?: SignerDto[];
    deadline?: string;
  }) => Promise<void>;
  onClose: () => void;
}

export const SendForSigningModal = ({
  contractId,
  mainDocumentId,
  documents,
  textDocuments,
  sendForSigning,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={true} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Send document for signing")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="6">
          <CreateSignedDocument
            contractId={contractId}
            documentId={mainDocumentId}
            documents={documents}
            textDocuments={textDocuments}
            sendForSigning={sendForSigning}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
