import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSearchOrganizationsAndEntriesQuery } from "../../autogen/bff-api";

export const useOrganizationSearch = ({
  name,
  limit = 20,
  skip = 0,
}: {
  excludedOrgId?: string;
  name?: string;
  skip?: number;
  limit?: number;
}) => {
  const [debouncedName, setDebouncedName] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedName = useCallback(
    debounce((name) => setDebouncedName(name), 300),
    []
  );

  useEffect(() => {
    handleDebouncedName(name);
  }, [handleDebouncedName, name]);

  const { data, isFetching } = useSearchOrganizationsAndEntriesQuery({ name: debouncedName, limit, skip });

  return {
    organizationCombinations: data?.organizations,
    searchName: (name: string) => {
      handleDebouncedName(name);
    },
    isSearching: isFetching,
    count: data?.count,
  };
};
