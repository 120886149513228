import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  MinimalOrgDto,
  useGetOrganizationEntryQuery,
  useLinkOrganizationEntryMutation,
} from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { OrganizationFormSelector } from "../../../../common/input/Selector/OrganizationFormSelector";
import { OrganizationTypeTag } from "../../../../common/organization/OrganizationTypeTag";

interface Props {
  entryId: string;
  onClose: () => void;
}

interface FormValues {
  organization?: MinimalOrgDto;
}

export const LinkWithExistingOrgModal = ({ entryId, onClose }: Props) => {
  const methods = useForm<FormValues>();
  const { t } = useTranslation();
  const [performLink] = useLinkOrganizationEntryMutation();
  const toast = useToast();
  const displayer = useApiError();

  const [isLinking, setIsLinking] = useState(false);

  const { data: entry, isLoading } = useGetOrganizationEntryQuery({ entryId });

  const link = async (values: FormValues) => {
    if (!values.organization || values.organization.type !== "Organization") {
      throw new Error("No organization - could not link organization entry.");
    }
    setIsLinking(true);
    const result = await performLink({
      entryId,
      linkOrganizationEntryRequest: {
        organizationId: values.organization.id,
      },
    });
    setIsLinking(false);

    if ("data" in result) {
      toast({ title: t("Organization linked!"), status: "success" });
      onClose();
    } else displayer.trigger(result.error);
  };
  return (
    <Modal isOpen={true} onClose={onClose} size="md">
      <ModalOverlay />
      <form onSubmit={methods.handleSubmit(link)}>
        <ModalContent pb="10px">
          <ModalHeader>
            {entry?.name}
            {isLoading && `${t("Loading")}...`}
            <br />
            <Box mt="5px">
              <OrganizationTypeTag type="OrganizationEntry" />
            </Box>
          </ModalHeader>
          <ModalBody mt="-15px">
            <OrganizationFormSelector
              label={t("Organization")}
              helperText={t("Please select an organization to link this entry with")}
              control={methods.control}
              name="organization"
              orgsToExclude={[entryId]}
            />
            <Box mt="20px">
              <Text fontWeight={"bold"} color="red.500">
                NB!
              </Text>
              <Text color="red.600">
                {t("This operation is irreversible")}.{" "}
                {t("All existing contracts and information will be moved to the selected organzation")}
                . <br />
                <br />
                {t("In other words, be sure you select the correct organization to link with")}.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" variant="solid" colorScheme={"purple"} isLoading={isLinking}>
              {t("Link!")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};
