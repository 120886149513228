import { Box, Button, Checkbox, Flex, FormControl, FormLabel, Icon, Input, Select, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import {
  UpsertCustomContractDataFieldApiArg,
  useUpsertCustomContractDataFieldMutation,
} from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { addCustomField } from "../../../../common/redux/reducers/authStateReducer";
import { ContractDataFieldSection, CustomContractField, CustomFieldType } from "../../../../common/types";
import { FieldForm } from "./FieldForm";

const initField = () => ({
  id: uuid(),
  name: "",
  type: CustomFieldType.CustomNumber,
  isInternal: false,
});

export const SectionForm = ({ section }: { section: ContractDataFieldSection }) => {
  const dispatch = useAppDispatch();
  const authState = useLoggedInWithOrgContextState();
  const [upsertField, { isLoading, error }] = useUpsertCustomContractDataFieldMutation();

  const [newField, setNewField] = useState<CustomContractField>();

  return (
    <Box key={section.id} border="teal 1px solid" rounded="lg" p="5" mb="5">
      <Text fontWeight={"semibold"} pb="5">
        {section.name}
      </Text>
      {Object.values(section.fields).map((field) => (
        <FieldForm key={field.id} sectionId={section.id} field={field} />
      ))}
      {newField ? (
        <>
          <Flex>
            <FormControl id="custom-field-name" pr="4">
              <FormLabel>{t("Name")}</FormLabel>
              <Input
                type="text"
                size={"sm"}
                rounded="md"
                onChange={(e) => setNewField({ ...newField, name: e.target.value })}
              />
            </FormControl>
            <FormControl id="custom-field-type" pr="4">
              <FormLabel>{t("Field type")}</FormLabel>
              <Select
                placeholder={t("Select field type") ?? ""}
                size={"sm"}
                rounded="md"
                value={newField.type}
                onChange={(e) => {
                  setNewField({
                    ...newField,
                    // Hack to work around typing
                    type: e.target.value as CustomFieldType.CustomAmount,
                    ...(e.target.value === CustomFieldType.CustomEnum ||
                    e.target.value === CustomFieldType.CustomMultiEnum
                      ? { options: [""] }
                      : {}),
                  });
                }}
              >
                {Object.values(CustomFieldType).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl id="custom-field-name" width="96">
              <FormLabel>{t("Internal field")}</FormLabel>
              <Checkbox
                pt="2"
                rounded="md"
                onChange={(e) => setNewField({ ...newField, isInternal: e.target.checked })}
              />
            </FormControl>
            <Flex alignItems={"end"}>
              <Button
                size="sm"
                rightIcon={<Icon as={FaCheck} />}
                variant={"outline"}
                colorScheme="teal"
                isLoading={isLoading}
                isDisabled={!newField.name}
                onClick={async () => {
                  await upsertField({
                    orgId: authState.selectedOrg.id,
                    upsertCustomContractFieldDto: {
                      sectionId: section.id,
                      field: newField,
                    },
                  } as UpsertCustomContractDataFieldApiArg);
                  dispatch(addCustomField({ sectionId: section.id, field: newField }));
                  setNewField(undefined);
                }}
              >
                {t("Save")}
              </Button>
            </Flex>
            {error && <Text color="warn">{t("Sorry, something went wrong")}</Text>}
          </Flex>
          {(newField.type === CustomFieldType.CustomEnum || newField.type === CustomFieldType.CustomMultiEnum) && (
            <FormControl maxWidth={"60"}>
              <FormLabel fontSize={"sm"} pt="2" pb="1" mb="0">
                {t("Options")}
              </FormLabel>
              {newField.options?.map((option, i) => (
                <Input
                  key={i}
                  type="text"
                  size={"xs"}
                  rounded="md"
                  value={option}
                  onChange={(e) => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const options = newField.options!;
                    options[i] = e.target.value;
                    setNewField({ ...newField, options });
                  }}
                />
              ))}
              <Button
                size={"xs"}
                onClick={() => {
                  setNewField({ ...newField, options: [...(newField.options ?? []), ""] });
                }}
              >
                {t("Add option")}
              </Button>
            </FormControl>
          )}
        </>
      ) : (
        <Button
          colorScheme="teal"
          variant={"outline"}
          leftIcon={<Icon as={FaPlus} />}
          size="sm"
          onClick={() => setNewField(initField())}
        >
          {t("Add Field")}
        </Button>
      )}
    </Box>
  );
};
