import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EmailInviteContextDto } from "../../../autogen/bff-api";
import { useAppDispatch } from "../../redux/hooks";
import { loginThunk } from "../../redux/thunks/auth/login-thunk";
import { ForgottenPassword } from "../ForgottenPassword/ForgottenPassword";
import { useLoggedOutState } from "../useLoggedOutState";
import { AzureLoginButton } from "./AzureLoginButton";

interface Props {
  invitationContext: EmailInviteContextDto | null;
}
interface FormValues {
  email: string;
  password: string;
}

export const LoginCredentialsForm = ({ invitationContext }: Props) => {
  const [showForgottenPassword, setShowForgottenPassword] = useState(false);
  const dispatch = useAppDispatch();
  const loggedOutState = useLoggedOutState();
  const { t } = useTranslation();
  const [azureError, setAzureError] = useState<string>();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm<FormValues>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const login = (values: FormValues) => {
    dispatch(
      loginThunk({
        email: values.email,
        password: values.password,
        invitationContext: invitationContext,
      })
    );
  };

  useEffect(() => {
    if (loggedOutState.loginError) {
      switch (loggedOutState.loginError) {
        case "IncorrectEmailOrPassword":
          setError("email", {
            message: t("Incorrect email or password") ?? "",
          });
          setError("password", {
            message: t("Incorrect email or password") ?? "",
          });
          break;
        case "UnknownError":
          setError("email", {
            message: t("Unknown error, please try again") ?? "",
          });
          break;
        case "UserDoesNotExist":
          setError("email", {
            message: t("No user exists for this email") ?? "",
          });
          break;
        case "zz_PersonApiErrorCode_NoOrgContextForAzureUser":
          setAzureError(
            `${t("It seems like you don't have access on behalf of your organization")}.
            ${t("Please contact your IT administrator to get access")}.`
          );
      }
    } else {
      clearErrors();
    }
  }, [clearErrors, loggedOutState.loginError, setError, t]);

  return (
    <>
      {!showForgottenPassword && (
        <>
          <Text fontSize="3xl">
            <b>{t("Log in")}</b>
          </Text>
          <form onSubmit={handleSubmit(login)}>
            <Text fontSize="md" mt="10px">
              {t("Please provide your credentials to log in")}...
            </Text>
            <FormControl isInvalid={errors.email !== undefined} mt="15px">
              <FormLabel htmlFor={"email"}>{t("Email")}</FormLabel>
              <Input
                id={"email"}
                type="email"
                {...register("email", {
                  required: t("Please provide your email") ?? "",
                  minLength: {
                    value: 2,
                    message: t("Must be at least 2 characters long"),
                  },
                })}
              />
              <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.password !== undefined} mt="15px">
              <FormLabel htmlFor={"password"}>{t("Password")}</FormLabel>
              <Input
                id={"password"}
                type="password"
                {...register("password", {
                  required: t("Please provide your password") ?? "",
                  minLength: {
                    value: 8,
                    message: t("Your password must be at least 8 characters long"),
                  },
                })}
              />
              <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
            </FormControl>
            <Text fontSize="sm" mt="1" ml="4px">
              {t("Have you")}{" "}
              <Button size={"sm"} variant={"link"} colorScheme="teal" onClick={() => setShowForgottenPassword(true)}>
                {t("forgotten your password?")}
              </Button>
            </Text>
            <Box h={10} w="100%" mt="15px" mb="10px">
              <Button
                type="submit"
                variant={"solid"}
                colorScheme="teal"
                w="full"
                bgGradient={"linear(to-r, teal, blue.300)"}
                isLoading={loggedOutState.isLoggingIn}
              >
                {t("Log in")}
              </Button>
            </Box>
          </form>
          <Text fontSize="md" mt="15px" ml="4px">
            ...{t("or click here to login with Entra ID")}
          </Text>
          <AzureLoginButton />
          {azureError && (
            <Text fontSize="sm" color="yellow.800">
              {azureError}
            </Text>
          )}
        </>
      )}
      <>
        {showForgottenPassword && (
          <>
            <Text fontSize="3xl">
              <b>{t("Forgotten password")}</b>
            </Text>
            <ForgottenPassword
              onComplete={(email) => {
                setValue("email", email);
                setShowForgottenPassword(false);
              }}
            />
          </>
        )}
      </>
    </>
  );
};
