import { Box, Divider, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const NoCounterpartyContactPersonProvided: React.FC = () => {
    const { t } = useTranslation()

    return (
        <Box padding="15px" paddingBottom={'20px'} w="100%">
            <Text fontWeight={'bold'} fontSize="sm" color="#808B9B">
                {t('Counterparty contact person')}
            </Text>
            <Divider marginTop={'5px'} marginBottom={'5px'} />
            <Text fontStyle={'italic'}>{t('Not provided')}</Text>
        </Box>
    )
}
