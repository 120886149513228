import { PlacementWithLogical, Tooltip } from "@chakra-ui/react";
import { ReactNode } from "react";

export interface Props {
  text: ReactNode;
  enabled?: boolean;
  children: ReactNode;
  placement?: PlacementWithLogical;
  fontSize?: "sm" | "md";
  shouldWrapChildren?: boolean;
}

export const Explanation = ({
  text,
  children,
  enabled = true,
  placement,
  fontSize = "sm",
  shouldWrapChildren,
}: Props) => {
  if (enabled) {
    return (
      <Tooltip
        label={text}
        fontSize={fontSize}
        placement={placement}
        shouldWrapChildren={shouldWrapChildren}
        rounded="md"
      >
        {children}
      </Tooltip>
    );
  }
  return <>{children}</>;
};
