import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  bffApi,
  Category,
  ContractDataFieldNameDto,
  ContractDataFieldSectionDto,
  ContractPriority,
  ContractRisk,
  CounterpartyInputDto,
  Currency,
  OptionDto,
  PhoneNumberDto,
  SignerDto,
  UpdateContractRequest,
} from "../../../../autogen/bff-api";
import { onlyUniqueStringsFilter } from "../../../onlyUniqueStringsFilter";
import { ContractState } from "../../reducers/contractReducer";
import { RootState } from "../../store";

export const editContractThunk = createAsyncThunk(
  "contract/edit",
  async (
    props: {
      command: EditContractCommand;
    },
    { dispatch, getState }
  ): Promise<ContractState | null> => {
    const currentState = getState() as RootState;

    const currentContractState = currentState.contract.state;
    if (!currentContractState) {
      throw Error("Should never happen");
    }

    switch (props.command.type) {
      case "EditAwardDate":
      case "EditRenewalDate":
      case "EditCounterpartyContactPerson":
      case "EditDeliveryTerms":
      case "EditEstimatedValue":
      case "EditExpirationDate":
      case "EditExternalLink":
      case "EditInternalReferenceNumber":
      case "EditLinkedContracts":
      case "EditOriginSourcingEvent":
      case "EditPaymentTermsInDays":
      case "EditPricePerMonth":
      case "EditPricePerYear":
      case "EditPriority":
      case "EditPurchasingPolicy":
      case "EditRisk":
      case "EditStartDate":
      case "EditTotalLiability":
      case "EditTotalPrice":
      case "EditWarrantyExpirationDate":
      case "EditTitle":
      case "EditDescription":
      case "EditInternalOwner":
      case "EditTemplate":
      case "EditProjects":
      case "EditCounterparty":
      case "EditTimezone":
      case "EditCurrency":
      case "EditIfrsValues":
      case "AddDataFields":
      case "EditCustomFields":
      case "AddTerminationDate":
      case "EditInternalParties":
      case "EditSupplierInfo":
      case "AddMonetaryAdjustment":
      case "AddPercentageAdjustment":
      case "RemoveMonetaryAdjustment":
      case "RemovePercentageAdjustment":
      case "EditSupplierCategory":
      case "EditNoticePeriod":
      case "EditOption":
      case "ExecuteOption":
      case "RemoveOption":
      case "RemoveDataField": {
        const response = dispatch(
          bffApi.endpoints.updateContract.initiate({
            contractId: currentContractState.id,
            updateContractRequest: getEditRequest(props.command),
          })
        );
        response.reset();
        const result = await response;

        if ("data" in result && result.data) {
          return getContractState(props.command, result.data as ContractState["contract"], currentContractState);
        } else {
          return getErrors(props.command, currentContractState);
        }
      }
      case "SendDocumentsForSigning": {
        const response = dispatch(
          bffApi.endpoints.createSignedDocument.initiate({
            contractId: currentContractState.id,
            createSignedDocumentRequest: {
              mainDocumentId: props.command.value.mainContractDocumentId,
              signers: props.command.value.signers,
              language: "No",
            },
          })
        );
        response.reset();
        const result = await response;

        if ("data" in result && result.data) {
          return getContractState(props.command, result.data as ContractState["contract"], currentContractState);
        } else {
          return getErrors(props.command, currentContractState);
        }
      }
    }
  }
);

const getContractState = (
  command: EditContractCommand,
  body: ContractState["contract"],
  currentContractState: ContractState
): ContractState => {
  return {
    id: currentContractState.id,
    contract: body,
    lastChanged: new Date().toISOString(),
    errors: currentContractState.errors.filter((e) => e !== command.type),
  };
};

const getErrors = (command: EditContractCommand, currentContractState: ContractState): ContractState => {
  return {
    ...currentContractState,
    errors: [...currentContractState.errors, command.type].filter(onlyUniqueStringsFilter),
  };
};

const getEditRequest = (command: EditContractCommand): UpdateContractRequest => {
  switch (command.type) {
    case "EditTitle":
      return {
        title: command.value,
      };
    case "EditDescription":
      return {
        description: command.value,
      };
    case "EditCounterparty":
      return {
        counterparty: {
          counterparty: command.value ?? undefined,
        },
      };
    case "EditInternalOwner":
      return {
        internalOwner: {
          personId: command.value,
        },
      };
    case "EditTemplate":
      return {
        template: {
          templateId: command.value ?? undefined,
        },
      };
    case "EditProjects":
      return {
        editProjects: command.projects,
      };
    case "EditAwardDate":
      return {
        awardDate: {
          date: command.value,
        },
      };
    case "EditRenewalDate":
      return {
        renewalDate: {
          date: command.value,
        },
      };
    case "EditCounterpartyContactPerson":
      return {
        counterpartyContactPerson: {
          fullName: command.value.fullName ?? undefined,
          email: command.value.email ?? undefined,
          phone: command.value.phoneNumber ?? undefined,
          personId: command.value.personId ?? undefined,
        },
      };
    case "EditDeliveryTerms":
      return {
        deliveryTerms: {
          terms: command.value ?? undefined,
        },
      };
    case "EditEstimatedValue":
      return {
        valueAmount: {
          amount: command.value ?? undefined,
        },
      };
    case "EditExpirationDate":
      return {
        endDate: {
          date: command.value.date ?? undefined,
          hasNoEndDate: command.value.hasNoExpirationDate,
        },
      };
    case "EditExternalLink":
      return {
        externalLink: {
          link: command.value ?? undefined,
        },
      };
    case "EditInternalReferenceNumber":
      return {
        internalReferenceNumber: {
          number: command.value ?? undefined,
        },
      };
    case "EditLinkedContracts":
      return {
        linkedContracts: {
          contractIds: command.value ?? undefined,
        },
      };

    case "EditOriginSourcingEvent":
      return {
        originSourcingEvent: {
          eventId: command.value ?? undefined,
        },
      };
    case "EditPaymentTermsInDays":
      return {
        paymentTermsInDays: {
          days: command.value ?? undefined,
        },
      };

    case "EditPricePerMonth":
      return {
        pricePerMonth: {
          amount: command.value ?? undefined,
        },
      };

    case "EditPricePerYear":
      return {
        pricePerYear: {
          amount: command.value ?? undefined,
        },
      };

    case "EditPriority":
      return {
        priority: {
          priority: command.value ?? undefined,
        },
      };

    case "EditPurchasingPolicy":
      return {
        purchasingPolicy: {
          policy: command.value ?? undefined,
        },
      };

    case "EditRisk":
      return {
        risk: {
          risk: command.value ?? undefined,
        },
      };

    case "EditStartDate":
      return {
        startDate: {
          date: command.value,
        },
      };
    case "EditTotalLiability":
      return {
        totalLiability: {
          amount: command.value ?? undefined,
        },
      };

    case "EditTotalPrice":
      return {
        totalPrice: {
          amount: command.value ?? undefined,
        },
      };
    case "EditWarrantyExpirationDate":
      return {
        warrantyExpirationDate: {
          date: command.value,
        },
      };
    case "EditTimezone":
      return {
        timezone: {
          value: command.value,
        },
      };
    case "EditCurrency":
      return {
        currency: {
          currency: command.value,
        },
      };
    case "SendDocumentsForSigning":
      throw Error("Should never happen");
    case "AddDataFields":
      return {
        addDataFields: command.value,
      };
    case "EditCustomFields":
      return {
        editCustomFields: command.value as {
          [key: string]: {
            id_1?: ContractDataFieldSectionDto | undefined;
            id_2?: ContractDataFieldSectionDto | undefined;
          };
        },
      };
    case "RemoveDataField":
      return {
        removeDataField: command.value,
      };
    case "AddTerminationDate":
      return {
        terminationDate: command.value,
      };
    case "EditIfrsValues":
      return {
        ifrsValues: {
          startDate: command.value.startDate ?? undefined,
          startDateSecondPeriod: command.value.startDateSecondPeriod ?? undefined,
          monthlyPayment: command.value.monthlyPayment ?? undefined,
          paymentIntervalInMonths: command.value.paymentIntervalInMonths ?? undefined,
          contractLengthInMonths: command.value.contractLengthInMonths ?? undefined,
          interestRate: command.value.interestRate ?? undefined,
        },
      };
    case "EditInternalParties":
      return {
        internalParties: command.value,
      };
    case "EditSupplierInfo":
      return {
        supplierInfo: {
          isClimateCommitted: command.value.isClimateCommitted,
          hasSignedCodeOfConduct: command.value.hasSignedCodeOfConduct,
          hasCustomerContact: command.value.hasCustomerContact,
        },
      };
    case "AddMonetaryAdjustment":
      return {
        addMonetaryValueAdjustment: {
          date: command.value.date,
          value: command.value.value,
        },
      };
    case "RemoveMonetaryAdjustment":
      return {
        removeMonetaryValueAdjustment: command.value,
      };
    case "AddPercentageAdjustment":
      return {
        addPercentageValueAdjustment: {
          date: command.value.date,
          value: command.value.value,
        },
      };
    case "RemovePercentageAdjustment":
      return {
        removePercentageValueAdjustment: command.value,
      };
    case "EditSupplierCategory":
      return {
        editSupplierCategory: { category: command.value },
      };
    case "EditNoticePeriod":
      return {
        editNoticePeriod: {
          duration: command.value,
        },
      };
    case "EditOption":
      return {
        editOption: command.option,
      };
    case "ExecuteOption":
      return {
        executeOption: { id: command.id },
      };
    case "RemoveOption":
      return {
        removeOption: {
          id: command.id,
        },
      };
  }
};

type EditTitleCommand = {
  type: "EditTitle";
  value: string;
};

type EditDescriptionCommand = {
  type: "EditDescription";
  value: string;
};

type EditCounterpartyCommand = {
  type: "EditCounterparty";
  value: CounterpartyInputDto;
};

type EditInternalOwner = {
  type: "EditInternalOwner";
  value: string;
};

type EditTemplate = {
  type: "EditTemplate";
  value: string | null;
};

type EditAwardDate = {
  type: "EditAwardDate";
  value?: string;
};

type EditRenewalDate = {
  type: "EditRenewalDate";
  value?: string;
};

type EditCounterpartyContactPerson = {
  type: "EditCounterpartyContactPerson";
  value: {
    fullName: string | null;
    email: string | null;
    phoneNumber: PhoneNumberDto | null;
    personId: string | null;
  };
};

type EditDeliveryTerms = {
  type: "EditDeliveryTerms";
  value: string | null;
};

type EditEstimatedValue = {
  type: "EditEstimatedValue";
  value: number | null;
};

type EditExpirationDate = {
  type: "EditExpirationDate";
  value: {
    date?: string;
    hasNoExpirationDate: boolean;
  };
};

type EditExternalLink = {
  type: "EditExternalLink";
  value: string | null;
};

type EditInternalReferenceNumber = {
  type: "EditInternalReferenceNumber";
  value: string | null;
};

type EditLinkedContracts = {
  type: "EditLinkedContracts";
  value: string[];
};

type EditOriginSourcingEvent = {
  type: "EditOriginSourcingEvent";
  value: string | null;
};

type EditPaymentTermsInDays = {
  type: "EditPaymentTermsInDays";
  value: number | null;
};

type EditPricePerMonth = {
  type: "EditPricePerMonth";
  value: number | null;
};

type EditPricePerYear = {
  type: "EditPricePerYear";
  value: number | null;
};

type EditPriority = {
  type: "EditPriority";
  value: ContractPriority;
};

type EditPurchasingPolicy = {
  type: "EditPurchasingPolicy";
  value: string | null;
};

type EditRisk = {
  type: "EditRisk";
  value: ContractRisk;
};

type EditStartDate = {
  type: "EditStartDate";
  value: string;
};

type EditTotalLiability = {
  type: "EditTotalLiability";
  value: number | null;
};

type EditTotalPrice = {
  type: "EditTotalPrice";
  value: number | null;
};

type EditWarrantyExpirationDate = {
  type: "EditWarrantyExpirationDate";
  value?: string;
};

type EditTimezone = {
  type: "EditTimezone";
  value: string;
};

type EditCurrency = {
  type: "EditCurrency";
  value: Currency;
};

export type EditIfrsValues = {
  type: "EditIfrsValues";
  value: {
    startDate?: string;
    startDateSecondPeriod?: string;
    monthlyPayment?: number;
    paymentIntervalInMonths?: number;
    contractLengthInMonths?: number;
    interestRate?: number;
  };
};

type AddTerminationDateCommand = {
  type: "AddTerminationDate";
  value: {
    date: string;
    reason: string;
  };
};

type AddDataFields = {
  type: "AddDataFields";
  value: ContractDataFieldNameDto[];
};

type EditCustomFields = {
  type: "EditCustomFields";
  value: { [sectionId: string]: ContractDataFieldSectionDto };
};

type RemoveDataField = {
  type: "RemoveDataField";
  value: ContractDataFieldNameDto;
};

type EditInternalParties = {
  type: "EditInternalParties";
  value: {
    parties: string[];
    selfRegisteredParties: string[];
  };
};

type EditSupplierInfo = {
  type: "EditSupplierInfo";
  value: {
    isClimateCommitted: boolean;
    hasSignedCodeOfConduct: boolean;
    hasCustomerContact: boolean;
  };
};

type AddMonetaryAdjustment = {
  type: "AddMonetaryAdjustment";
  value: {
    date: string;
    value: number;
  };
};

type RemoveMonetaryAdjustment = {
  type: "RemoveMonetaryAdjustment";
  value: string;
};

type AddPercentageAdjustment = {
  type: "AddPercentageAdjustment";
  value: {
    date: string;
    value: number;
  };
};

type RemovePercentageAdjustment = {
  type: "RemovePercentageAdjustment";
  value: string;
};

type SendDocumentsForSigningCommand = {
  type: "SendDocumentsForSigning";
  value: {
    mainContractDocumentId: string;
    attachmentContractDocumentIds: string[];
    signers: SignerDto[];
  };
};

type EditSupplierCategory = {
  type: "EditSupplierCategory";
  value?: Category;
};

type EditNoticePeriod = {
  type: "EditNoticePeriod";
  value: number;
};

type EditOption = {
  type: "EditOption";
  option: OptionDto;
};

type ExecuteOption = {
  type: "ExecuteOption";
  id: string;
};

type RemoveOption = {
  type: "RemoveOption";
  id: string;
};

type EditProjects = {
  type: "EditProjects";
  projects: string[];
};

export type EditContractCommandType =
  | "EditTitle"
  | "EditDescription"
  | "EditCounterparty"
  | "EditInternalOwner"
  | "EditTemplate"
  | "EditAwardDate"
  | "EditRenewalDate"
  | "EditCounterpartyContactPerson"
  | "EditDeliveryTerms"
  | "EditEstimatedValue"
  | "EditExpirationDate"
  | "EditExternalLink"
  | "EditInternalReferenceNumber"
  | "EditLinkedContracts"
  | "EditOriginSourcingEvent"
  | "EditPaymentTermsInDays"
  | "EditPricePerMonth"
  | "EditPricePerYear"
  | "EditPriority"
  | "EditPurchasingPolicy"
  | "EditRisk"
  | "EditStartDate"
  | "EditTotalLiability"
  | "EditTotalPrice"
  | "EditWarrantyExpirationDate"
  | "EditTimezone"
  | "EditCurrency"
  | "EditIfrsValues"
  | "AddDataFields"
  | "EditCustomFields"
  | "RemoveDataField"
  | "SendDocumentsForSigning"
  | "AddTerminationDate"
  | "EditInternalParties"
  | "EditSupplierInfo"
  | "AddMonetaryAdjustment"
  | "RemoveMonetaryAdjustment"
  | "AddPercentageAdjustment"
  | "RemovePercentageAdjustment"
  | "EditSupplierCategory"
  | "EditNoticePeriod"
  | "EditOption"
  | "ExecuteOption"
  | "RemoveOption"
  | "EditProjects";

export type EditContractCommand =
  // data fields
  | EditAwardDate
  | EditRenewalDate
  | EditCounterpartyContactPerson
  | EditDeliveryTerms
  | EditEstimatedValue
  | EditExpirationDate
  | EditExternalLink
  | EditInternalReferenceNumber
  | EditLinkedContracts
  | EditOriginSourcingEvent
  | EditPaymentTermsInDays
  | EditPricePerMonth
  | EditPricePerYear
  | EditPriority
  | EditPurchasingPolicy
  | EditRisk
  | EditStartDate
  | EditTotalLiability
  | EditTotalPrice
  | EditWarrantyExpirationDate
  | EditIfrsValues
  // other
  | RemoveDataField
  | EditCurrency
  | AddDataFields
  | EditCustomFields
  | EditTimezone
  | EditCounterpartyCommand
  | SendDocumentsForSigningCommand
  | EditTitleCommand
  | EditDescriptionCommand
  | EditInternalOwner
  | EditTemplate
  | AddTerminationDateCommand
  | EditInternalParties
  | EditSupplierInfo
  | AddMonetaryAdjustment
  | RemoveMonetaryAdjustment
  | AddPercentageAdjustment
  | RemovePercentageAdjustment
  | EditSupplierCategory
  | EditNoticePeriod
  | EditOption
  | ExecuteOption
  | RemoveOption
  | EditProjects;
