import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useListSupplierEmailInvitesQuery } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { useSkeletons } from "../../../common/useSkeletons";
import { SentInviteRow } from "./SentInviteRow";

export const SentInvitesTable: React.FC = () => {
  const authState = useLoggedInWithOrgContextState();
  const { data, refetch, error } = useListSupplierEmailInvitesQuery({
    orgId: authState.selectedOrg.id,
  });
  const { t } = useTranslation();
  const skeletons = useSkeletons();
  const displayer = useApiError();

  if (error) {
    return displayer.show(error);
  }

  return (
    <Box backgroundColor="smBackground" borderRadius={10} mt="20px" boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)">
      {!data && skeletons.stackedLines(20)}
      {data && (
        <Table variant="simple" mb="30px">
          <Thead>
            <Tr>
              <Th>{t("Email")}</Th>
              <Th>{t("Person name")}</Th>
              <Th>{t("Company name")}</Th>
              <Th>{t("Has joined SourceMagnet?")}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.invitations
              .filter((e) => e.acceptedInfo === null || e.acceptedInfo === undefined)
              .map((e) => {
                return <SentInviteRow key={e.id} invite={e} refetch={refetch} />;
              })}
            {data.invitations.length === 0 && (
              <Tr>
                <Td colSpan={4} width={"100%"} textAlign="center">
                  {t("No invitations have been sent!")}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};
