import { useTranslation } from "react-i18next";
import { CommonTemplateType } from "../../../../autogen/bff-api";

export const useTemplateMeta = () => {
  const { t } = useTranslation();
  const getTemplateName = (template: { name?: string; commonType?: CommonTemplateType }): string => {
    if (template.name) {
      return template.name;
    } else if (template.commonType) {
      switch (template.commonType) {
        case "ExpirationDate":
          return t("Contract with end date");
      }
    }

    throw new Error("Should never happen");
  };

  return {
    getTemplateName,
  };
};
