import { Box, Button, Flex, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa";
import { BasicBidDocumentUploader } from "./form-elements/BasicBidDocumentUploader";
import { Comment } from "./form-elements/Comment";

interface Props {
  nextStep: () => void;
}

export const Details = ({ nextStep }: Props) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection={"column"}>
      <Box pb="8">
        <Comment />
      </Box>
      <Box pb="8">
        <BasicBidDocumentUploader />
      </Box>
      <Button
        rightIcon={<Icon as={FaArrowRight} w="4" h="4" />}
        variant="solid"
        width="full"
        colorScheme="teal"
        onClick={nextStep}
      >
        {t("Next")}
      </Button>
    </Flex>
  );
};
