import { OrganizationSize } from '../../autogen/bff-api'

export const displayOrganizationSize = (size: OrganizationSize): string => {
    switch (size) {
        case 'FIFTY_TO_TWO_HUNDRED_AND_FIFTY':
            return '50-250'
        case 'ONE_TO_TEN':
            return '1-10'
        case 'TEN_THOUSAND_PLUS':
            return '10000+'
        case 'TEN_TO_FIFTY':
            return '10-50'
        case 'THOUSAND_TO_TEN_THOUSAND':
            return '1000-10000'
        case 'TWO_HUNDRED_AND_FIFTY_TO_THOUSAND':
            return '250-1000'
    }
}
