import { Tag } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { DocumentSignerStatusDto } from "../../../../../autogen/bff-api";

interface Props {
  status: DocumentSignerStatusDto;
  size?: "sm" | "md" | "lg";
}

export const DocumentSignerStatusTag = ({ status, size }: Props) => {
  const { t } = useTranslation();
  const getEventTagMeta = (
    status: DocumentSignerStatusDto
  ): {
    color: string;
    text: string;
  } => {
    switch (status) {
      case "DocumentEmailNotOpened":
        return {
          color: "red",
          text: t("Email not opened"),
        };
      case "DocumentEmailOpened":
        return {
          color: "orange",
          text: t("Email opened"),
        };
      case "DocumentLinkOpened":
        return {
          color: "orange",
          text: t("Email link opened"),
        };
      case "DocumentRead":
        return {
          color: "blue",
          text: t("Has read document"),
        };
      case "DocumentSigned":
        return {
          color: "green",
          text: t("Signing completed"),
        };
      case "EmailSendingFailed":
        return { color: "red", text: t("Email failed") };
    }
  };

  const tagMeta = getEventTagMeta(status);

  return (
    <Tag
      variant="outline"
      colorScheme={tagMeta.color}
      size={size}
      textAlign="center"
      paddingTop={"2px"}
      paddingBottom={"2px"}
    >
      {tagMeta.text}
    </Tag>
  );
};
