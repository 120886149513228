import { Alert, AlertIcon, Button, Grid, GridItem } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { CONTENT_WIDTH } from "../../config";
import { useLoggedInWithOrgContextState } from "../auth/useLoggedInWithOrgContextState";
import { clearImpersonatedPersonId, getImpersonatedPersonId } from "../local-storage/impersonated-person";
import { Footer } from "./Footer";
import { Navbar } from "./Navbar/Navbar";
import { VerifyEmailBanner } from "./VerifyEmailBanner";

interface Props {
  children: React.ReactNode;
  showFooter?: boolean;
  enableScrolling?: boolean;
}

export const Layout = ({ children, showFooter, enableScrolling }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const impersonatedPersonId = getImpersonatedPersonId();

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      {impersonatedPersonId && (
        <Alert status="info">
          <AlertIcon />
          {t("You are impersonating user")}: <b>{authState.me.email}</b>
          <Button
            size="sm"
            variant={"solid"}
            colorScheme="blue"
            onClick={() => {
              clearImpersonatedPersonId();
              window.location.reload();
            }}
          >
            {t("Stop")}
          </Button>
        </Alert>
      )}
      <Grid
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={`50px minmax(${enableScrolling === true ? "0" : "min-content"}, calc(100vh - 51px)) ${
          showFooter === false ? "" : "300px"
        }`}
        templateAreas={`
                "nav nav nav"
                "content content content"
                "leftFooter footer rightFooter"
                `}
      >
        <GridItem area={"leftNav"} borderBottom="1px solid" borderColor="smBorder"></GridItem>
        <GridItem
          area={"nav"}
          colSpan={3}
          display="flex"
          justifyContent={"center"}
          justifySelf={"stretch"}
          borderBottom="1px solid"
          borderColor="smBorder"
        >
          <Navbar />
        </GridItem>
        <GridItem area={"rightNav"} borderBottom="1px solid" borderColor="smBorder"></GridItem>

        <GridItem area={"content"} height="full" maxWidth="full">
          {!authState.me.emailVerified && <VerifyEmailBanner />}
          {children}
        </GridItem>

        <GridItem area={"leftFooter"} borderTop="1px solid" borderColor="smBorder"></GridItem>
        {showFooter === false ? undefined : (
          <GridItem area={"footer"} borderTop="1px solid" borderColor="smBorder">
            <Footer />
          </GridItem>
        )}
        <GridItem area={"rightFooter"} borderTop="1px solid" borderColor="smBorder"></GridItem>
      </Grid>
    </div>
  );
};
