import { SerializedError, createAsyncThunk } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { CreateProductGroupDto, ProductGroupDto, bffApi } from "../../../../autogen/bff-api";

export type CreateProductGroupResponse =
  | {
      data: ProductGroupDto;
    }
  | {
      error: FetchBaseQueryError | SerializedError | null;
    };

export const createProductGroupThunk = createAsyncThunk(
  "products/group",
  async (createProductGroupDto: CreateProductGroupDto, { dispatch }): Promise<CreateProductGroupResponse> => {
    return dispatch(bffApi.endpoints.createProductGroup.initiate({ createProductGroupDto }));
  }
);
