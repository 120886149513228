import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationDto, bffApi, useMarkNotificationsAsPeekedMutation } from "../../autogen/bff-api";
import { useApiError } from "../../common/errors/useApiError";
import { Layout } from "../../common/layout/Layout";
import { useAppDispatch } from "../../common/redux/hooks";
import { NotificationList } from "./NotificationList";

export const Notifications = () => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<NotificationDto[]>();
  const dispatch = useAppDispatch();
  const [markAsPeeked] = useMarkNotificationsAsPeekedMutation();
  const displayer = useApiError();
  const [isLoading, setIsLoading] = useState(false);
  const [isExhausted, setIsExhausted] = useState(false);
  const limit = 20;

  const loadNotifications = useCallback(async () => {
    if (isExhausted) return;
    setIsLoading(true);
    const response = dispatch(
      bffApi.endpoints.listMyNotifications.initiate({
        offset: notifications?.length ?? 0,
        limit: limit,
      })
    );
    response.unsubscribe();
    const result = await response;
    setIsLoading(false);

    if ("data" in result && result.data) {
      const newNotifications = result.data.notifications;
      if (newNotifications.length < limit) {
        setIsExhausted(true);
      }
      setNotifications([...(notifications ?? []), ...newNotifications]);
      const notPeekedNotifications = newNotifications.filter((e) => e.peeked === false);
      if (notPeekedNotifications.length > 0) {
        markAsPeeked({
          markNotificationsRequest: {
            notificationIds: notPeekedNotifications.map((e) => e.id),
          },
        });
      }
    }
    if (result.isError) {
      displayer.trigger(result.error);
      return null;
    }
  }, [dispatch, displayer, isExhausted, markAsPeeked, notifications]);

  useEffect(() => {
    if (!notifications) loadNotifications();
  }, [loadNotifications, notifications]);

  return (
    <Layout>
      <Flex flexDirection={"column"} alignItems={"center"} height="full" width="full" px="2" pt="5">
        <Flex flexDirection={"column"} width="full" maxWidth={"container.xl"} pb="5">
          <Heading as="h2" size="lg">
            {t("Notifications")}
          </Heading>
          <Flex
            minHeight="80%"
            width="full"
            flexDirection={"column"}
            mt="20px"
            bg="smBackgroundSecondary"
            borderRadius={"10px"}
          >
            {notifications && <NotificationList notifications={notifications} />}
            <Flex justifyContent={"center"} px="5" py="10">
              {!isExhausted ? (
                <Button
                  colorScheme="blue"
                  variant={"link"}
                  isLoading={isLoading}
                  onClick={() => {
                    loadNotifications();
                  }}
                >
                  {t("Load more")}
                </Button>
              ) : (
                <Text color="smMuted" fontSize={"sm"}>
                  {t("You have no more notifications to show")}
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  );
};
