import { Button } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ContractTaskDto } from '../../../autogen/bff-api'
import { displayWrappedWithEllipsis } from '../../../common/formatting/displayWrappedWithEllipsis'
import { urls } from '../../../urls'

interface Props {
    task: ContractTaskDto
    size?: 'sm' | 'md' | 'lg'
}

export const ContractTitle: React.FC<Props> = ({ task }) => {
    const navigate = useNavigate()

    // TODO: link to SharedContractDto if task is for a shared contract
    return (
        <Button
            variant="link"
            mr={3}
            size="sm"
            colorScheme={'blue'}
            onClick={() => {
                navigate(urls.contracts.viewSingle.go(task.contractId))
            }}
        >
            {displayWrappedWithEllipsis(task.contractTitle, 20)}
        </Button>
    )
}
