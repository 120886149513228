import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getImpersonatedPersonId } from '../local-storage/impersonated-person'
import { getPreferredLanguage } from '../local-storage/language'
import { getOrganizationId } from '../local-storage/selected-organization'

// initialize an empty api service that we'll inject endpoints into later as needed
export const myApi = createApi({
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BFF_BASE_URL + '/',
        credentials: 'include',
        prepareHeaders: async (headers) => {
            const persistedOrganizationId = getOrganizationId()
            if (persistedOrganizationId) {
                headers.set('organizationId', persistedOrganizationId)
            }
            const impersonatedPersonId = getImpersonatedPersonId()
            if (impersonatedPersonId) {
                headers.set('personId', impersonatedPersonId)
            }
            headers.set('preferred-language', getPreferredLanguage())

            return headers
        },
    }),
    endpoints: () => ({}),
})
