import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  NumberInput,
  NumberInputField,
  Spinner,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useContractState } from "../../../useContractState";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";

interface Props {
  label: string;
  placeholderText: string;
  amount: number | null;
  errorMessage: string | null;
  editAmount: (amount: number | undefined) => Promise<void> | void;
  helperText: string;
  removeDataField?: () => Promise<void> | void;
  isRequiredButNotProvided: boolean;
}

export const GenericMonetaryValue = ({
  label,
  placeholderText,
  amount,
  errorMessage,
  editAmount,
  helperText,
  removeDataField,
  isRequiredButNotProvided,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const currency = useContractState().contract.currency;

  const [providedAmount, setProvidedAmount] = useState<number>(NaN);
  const format = (val: number): string => {
    const formatted = `${currency} ` + val.toLocaleString();
    return formatted;
  };
  const parse = (val: string): number => {
    const parsed = parseInt(val.replace(`${currency}`, ""));
    return parsed;
  };

  useEffect(() => {
    setProvidedAmount(amount ?? NaN);
  }, [amount]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedAmountEventHandler = useCallback(
    debounce(async (value: number | undefined) => {
      setIsLoading(true);
      await editAmount(value);
      setIsLoading(false);
    }, 300),
    [editAmount]
  );

  useEffect(() => {
    return () => {
      debouncedAmountEventHandler.cancel();
    };
  }, [debouncedAmountEventHandler]);

  return (
    <FormControl isInvalid={errorMessage !== null} isRequired={isRequiredButNotProvided}>
      <FormLabel htmlFor={"value"}>
        {label} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Box borderRadius={"10px"}>
        <Flex w="100%">
          <NumberInput
            bg="smBackground"
            w="100%"
            value={!isNaN(providedAmount) ? format(providedAmount) : `${currency}`}
            placeholder={placeholderText}
            onChange={(e) => {
              const parsedNumber = parse(e);

              if (isNaN(parsedNumber)) {
                setProvidedAmount(NaN);
                debouncedAmountEventHandler(undefined);
              } else if (parsedNumber >= 0) {
                setProvidedAmount(parsedNumber);
                debouncedAmountEventHandler(parsedNumber);
              }
            }}
          >
            <NumberInputField />
          </NumberInput>
          {removeDataField && <RemoveDataFieldButton removeDataField={removeDataField} />}
        </Flex>
      </Box>
      <FormHelperText>{helperText}</FormHelperText>
      <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
