import { Box, Button, Flex, Icon, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BseDto } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { urls } from "../../../../urls";
import { requireDeadlineHasPassed } from "../../requireDeadlineHasPassed";
import { EmployeeInfo } from "./EmployeeInfo";
import { InvitedOrganizationStatusTag } from "./InvitedOrganizationStatusTag";
import { ViewBidAsBuyerModal } from "./ViewBidAsBuyerModal";

interface Props {
  event: BseDto;
  awardToBid: (bidId: string) => Promise<void>;
  isAwarding: boolean;
}

export const InvitedOrganizations = ({ event }: Props) => {
  const [bidIdToView, setBidIdToView] = useState<string>();
  const navigate = useNavigate();
  const deadlineHasPassed = requireDeadlineHasPassed(event);
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();

  return (
    <Box width="full" border="1px solid" borderColor="smBorder" borderRadius="md" overflowX="scroll" py="2.5">
      {bidIdToView && (
        <ViewBidAsBuyerModal
          eventId={event.id}
          bidId={bidIdToView}
          isOpen={bidIdToView !== undefined}
          onClose={() => setBidIdToView(undefined)}
        />
      )}
      <Table variant="simple" size={"sm"}>
        <Thead>
          <Tr>
            <Th>{t("Company name")}</Th>
            <Th>{t("Contact person")}</Th>
            <Th textAlign={"center"}>{t("Status")}</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {event.organizationInvites.length === 0 && (
            <Tr>
              <Td colSpan={4} width={"100%"} textAlign="center">
                {t("No one have accepted their email invites, yet")}
              </Td>
            </Tr>
          )}
          <>
            {event.organizationInvites.map((e) => {
              return (
                <Tr key={e.id}>
                  <Td>
                    <Button
                      variant={"link"}
                      size="sm"
                      colorScheme={"teal"}
                      onClick={() => navigate(urls.organizations.profile.go(e.organizationId))}
                    >
                      {e.organizationName}
                    </Button>
                  </Td>
                  <Td>
                    <EmployeeInfo
                      firstName={e.contactPerson.firstName}
                      lastName={e.contactPerson.lastName}
                      email={e.contactPerson.email}
                      phoneNumber={e.contactPerson.phoneNumber ?? null}
                      corporateTitle={e.contactPerson.corporateTitle ?? null}
                    />
                  </Td>
                  <Td textAlign={"center"}>
                    <Flex justifyContent={"center"}>
                      <InvitedOrganizationStatusTag status={e.status} />
                    </Flex>
                  </Td>
                  <Td>
                    <Flex flexDirection="column" alignItems={"flex-start"} justifyContent="start">
                      {e.bidId && (deadlineHasPassed || authState.selectedOrg.canSeeDeliveredBidsBeforeDeadline) && (
                        <Button
                          leftIcon={<Icon as={FaEye} w="13px" h="13px" />}
                          w="100px"
                          variant={"solid"}
                          colorScheme="teal"
                          size={"sm"}
                          onClick={() => setBidIdToView(e.bidId)}
                        >
                          {t("View bid")}
                        </Button>
                      )}
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </>
        </Tbody>
      </Table>
    </Box>
  );
};
