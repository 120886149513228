import { HamburgerIcon } from "@chakra-ui/icons";
import { Box, Icon, IconButton, Menu, MenuButton, MenuGroup, MenuItem, MenuList } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaBuilding, FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { urls } from "../../../../urls";

export const SmNavbar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box mr="5px" mt="1px">
      <Menu>
        <MenuButton as={IconButton} aria-label="Options" icon={<HamburgerIcon />} variant="outline" />
        <MenuList>
          <MenuGroup title={t("Organizations") ?? ""}>
            <MenuItem
              icon={<Icon as={FaBuilding} w="15px" h="15px" mt={"3px"} mr="-5px" />}
              onClick={() => {
                navigate(urls.organizations.search);
              }}
            >
              {t("Organizations")}
            </MenuItem>
          </MenuGroup>
          <MenuGroup title={t("Profile") ?? ""}>
            <MenuItem
              icon={<Icon as={FaUser} w="15px" h="15px" mt={"3px"} mr="-5px" />}
              onClick={() => {
                navigate(urls.myProfile.personal.view);
              }}
            >
              {t("Personal profile")}
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};
