import { Box, Button, Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { assets } from "../../assets/assets";
import { urls } from "../../urls";

export const AlreadyAccepted: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Grid
        gridTemplateColumns={"1fr minmax(0, 500px) 1fr"}
        height="100%"
        gridTemplateRows={"1fr minmax(200px, auto) 2fr"}
        templateAreas={`
            "left top right"
            "left login right"
            "left bottom right"
            `}
      >
        <GridItem area={"left"} bg="#f8f8f8"></GridItem>
        <GridItem area={"top"} bg="#f8f8f8"></GridItem>
        <GridItem area={"bottom"} bg="#f8f8f8"></GridItem>
        <GridItem area={"right"} bg="#f8f8f8"></GridItem>
        <GridItem area={"login"} borderRadius={10} boxShadow="0 9px 46px 8px rgb(0,0,0,0.2)" padding={10}>
          <Flex w="100%" h={"100%"} flexDirection="column">
            <Flex paddingTop={"20px"} paddingBottom="35px">
              <Image src={assets.logo} alt="Logo" h="30px" mt="10px" />
            </Flex>
            <Text fontSize="2xl" mt="5px">
              <b>{t("Invite already accepted?")}</b>
            </Text>
            <Box mt="10px">
              <Text>
                {t("It seems like you have already accepted this invite")}
                . <br /> <br />
                {t("This means you can")}{" "}
                <Button
                  color="blue.500"
                  fontWeight={"bold"}
                  variant="link"
                  onClick={() => {
                    navigate(urls.login);
                  }}
                >
                  {t("click here to log in")}
                </Button>{" "}
                {t("instead!")}
              </Text>
            </Box>
          </Flex>
        </GridItem>
      </Grid>
    </div>
  );
};
