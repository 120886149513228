import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BseQuestionDto } from "../../../../../autogen/bff-api";
import { displayDateWithTime } from "../../../../../common/formatting/displayDateWithTime";
import { QuestionStatusTag } from "../../../buyer/view-single/questions/QuestionStatusTag";

interface Props {
  question: BseQuestionDto;
}

export const QuestionMeta = ({ question }: Props) => {
  const { t } = useTranslation();
  return (
    <Flex borderRadius={"md"} w="100%">
      <Flex w="100%" alignItems={"center"}>
        <Text pr="2" fontSize={"sm"}>
          {t("Asked by")}{" "}
          <b>
            {question.askedByPerson.firstName} {question.askedByPerson.lastName}
          </b>
          , {displayDateWithTime(question.askedAt, t("at"))}
        </Text>
        <QuestionStatusTag status={question.status} size="sm" />
      </Flex>
    </Flex>
  );
};
