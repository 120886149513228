import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Spinner } from "@chakra-ui/react";
import { debounce } from "lodash";
import React, { useEffect, useMemo } from "react";

interface Props {
  label: string;
  name: string;
  helperText: string;
  errorMessage: string | undefined;
  onChange: (value: string) => void;
  debouncedOnChangeHandler?: (value: string) => Promise<void>;
  value: string;
  isLoading: boolean;
  type?: React.HTMLInputTypeAttribute | undefined;
  isRequiredButNotProvided?: boolean;
}

export const SimpleInput = ({
  name,
  label,
  onChange,
  helperText,
  errorMessage,
  value,
  isLoading,
  type,
  debouncedOnChangeHandler,
  isRequiredButNotProvided,
}: Props) => {
  useEffect(() => {
    return () => {
      debounceHandler.cancel();
    };
  }, []);

  const debounceHandler = useMemo(
    () =>
      debounce(async (value: string) => {
        debouncedOnChangeHandler && (await debouncedOnChangeHandler(value));
      }, 300),
    []
  );

  return (
    <FormControl isRequired={isRequiredButNotProvided} isInvalid={errorMessage !== undefined}>
      <FormLabel htmlFor={name}>
        {label} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Input
        id={name}
        type={type ?? "text"}
        onChange={(e) => {
          onChange(e.target.value);
          debounceHandler(e.target.value);
        }}
        value={value === undefined ? "" : value}
      />
      <FormHelperText>{helperText}</FormHelperText>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
