import { Badge, Flex, Spacer, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { PersonDto } from "../../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../../common/auth/useLoggedInWithOrgContextState";
import { displayDateWithTime } from "../../../../../common/formatting/displayDateWithTime";

interface Props {
  isOriginalQuestion: boolean;
  sender: PersonDto;
  sentAt: string;
  content: string;
}

interface Colors {
  background: string;
  text: string;
}

export const SupplierQuestionComment = ({ isOriginalQuestion, sender, sentAt, content }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const { t } = useTranslation();
  let colors: Colors = {
    background: "#f6f8fb",
    text: "black",
  };

  if (authState.me.id === sender.id) {
    colors = {
      background: "blue.400",
      text: "smBackground",
    };
  }
  return (
    <>
      <Flex flexDirection={"column"} bg={colors.background} padding="10px" borderRadius={"10px"}>
        <Flex w="100%">
          <Flex color={colors.text}>
            <i>
              {sender.firstName} {sender.lastName}{" "}
              {isOriginalQuestion && (
                <Badge colorScheme="purple" ml="6px" mt="-3px">
                  {t("Original question")}
                </Badge>
              )}
            </i>
          </Flex>
          <Spacer />
          <Flex color={colors.text}>
            <i>{displayDateWithTime(sentAt, t("at"))}</i>
          </Flex>
        </Flex>
        <Flex color={colors.text} fontWeight={"semibold"}>
          <Text fontSize={"md"} whiteSpace={"pre-line"}>
            {content}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};
