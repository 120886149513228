import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  Input,
  LinkBox,
  LinkOverlay,
  NumberInput,
  NumberInputField,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { debounce, isFinite, isNil, isUndefined, parseInt } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChartLine, FaDownload } from "react-icons/fa";
import { ContractDto, Currency, IfrsValuesDto } from "../../../../../../autogen/bff-api";
import { DateFormControl } from "../../../../../../common/input/DateTimeSelectors/DateFormControl";
import { DateSelector } from "../../../../../../common/input/DateTimeSelectors/DateSelector";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { Explanation } from "../../../../../../common/support/Explanation/Explanation";
import { requireStringEnvVar } from "../../../../../../config";
import { useContractState } from "../../../useContractState";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";
import { IfrsAdjustmentsModal } from "./ifrs-adjustments/IfrsAdjustmentsModal";

const formatMonetaryAmount = ({ val, currency }: { val?: number; currency?: Currency }): string => {
  const formatted = `${currency} ` + (val?.toLocaleString() ?? "");
  return formatted;
};

const parseMonetaryAmount = (val: string, currency?: Currency): number => {
  if (!currency) throw Error("Failed to parse monetary amount.");
  const parsed = parseInt(val.replace(`${currency}`, ""));
  return parsed;
};

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const IfrsValues = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const contractState = useContractState();

  const [ifrsValues, setIfrsValues] = useState<IfrsValuesDto>();
  const [interestRate, setInterestRate] = useState<string>();
  const [currency, setCurrency] = useState<Currency>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasDeviatingFirstPeriod, setHasDeviatingFirstPeriod] = useState<boolean>(false);
  const [canDownloadReport, setCanDownloadReport] = useState(false);
  const [showAddAdjustment, setShowAddAdjustment] = useState(false);

  useEffect(() => {
    if (!ifrsValues) setIfrsValues(contractState.contract.dataFields?.ifrsValues);
  }, [contractState.contract.dataFields?.ifrsValues, ifrsValues]);

  useEffect(() => {
    if (!currency) setCurrency(contractState.contract.currency);
  }, [contractState.contract.currency, currency]);

  useEffect(() => {
    if (isUndefined(interestRate)) {
      const ir = contractState.contract.dataFields?.ifrsValues?.interestRate;
      setInterestRate((interestRate) =>
        interestRate ? interestRate : `${!isNil(ir) && isFinite(ir) ? ir * 100 : ""}`
      );
    }
  }, [contractState.contract.dataFields?.ifrsValues?.interestRate, interestRate]);

  useEffect(() => {
    if (ifrsValues?.startDateSecondPeriod) setHasDeviatingFirstPeriod(true);
  }, [ifrsValues?.startDateSecondPeriod]);

  useEffect(() => {
    const adjustments = contractState.contract.dataFields?.ifrsValues?.adjustments;
    if (adjustments) {
      setIfrsValues((ifrsValues) => (ifrsValues ? { ...ifrsValues, adjustments } : undefined));
    }
  }, [contractState.contract.dataFields?.ifrsValues?.adjustments]);

  const editIfrsValues = useCallback(
    async (value: IfrsValuesDto) => {
      setIsLoading(true);
      await dispatch(
        editContractThunk({
          command: {
            type: "EditIfrsValues",
            value,
          },
        })
      );
      setIsLoading(false);
    },
    [dispatch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const editIfrsValuesDebounced = useCallback(
    debounce((value: IfrsValuesDto) => editIfrsValues(value), 300),
    [editIfrsValues]
  );

  const handleIfrsValueChange = (value: IfrsValuesDto) => {
    setIfrsValues(value);
    editIfrsValuesDebounced(value);
  };

  useEffect(() => {
    if (
      ifrsValues?.startDate &&
      ifrsValues?.monthlyPayment &&
      ifrsValues?.paymentIntervalInMonths &&
      ifrsValues?.contractLengthInMonths &&
      ifrsValues?.interestRate
    ) {
      setCanDownloadReport(true);
    } else setCanDownloadReport(false);
  }, [ifrsValues]);

  if (!ifrsValues) return <p>{t("Loading")}...</p>;

  return (
    <>
      {showAddAdjustment && (
        <IfrsAdjustmentsModal
          contract={contractState.contract as ContractDto}
          onClose={() => setShowAddAdjustment(false)}
        />
      )}
      <Flex justifyContent={"space-between"}>
        <Heading fontSize="lg">
          {t("IFRS reporting")} {isLoading && <Spinner size="xs" />}
        </Heading>
        <RemoveDataFieldButton removeDataField={removeDataField} />
      </Flex>
      <DateFormControl
        id="ifrs start date"
        title={t("Start date")}
        isRequiredButNotProvided={isRequiredButNotProvided}
        helperText=""
        errorMessage={null}
        defaultDate={ifrsValues.startDate}
        onChange={async (startDate) => {
          handleIfrsValueChange({ ...ifrsValues, startDate: startDate ? startDate : undefined });
        }}
      />
      <FormControl width="full" py="5">
        <FormLabel fontSize="sm">{t("Second period start date")}</FormLabel>
        <Flex width="full">
          <Flex flexDirection="column" justify="space-between" grow="1" width="50%" pt="6" pl="4">
            <Box>
              <Checkbox
                size="lg"
                colorScheme="teal"
                backgroundColor="smBackground"
                isChecked={hasDeviatingFirstPeriod}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setHasDeviatingFirstPeriod(isChecked);
                  handleIfrsValueChange({
                    ...ifrsValues,
                    startDateSecondPeriod: isChecked ? ifrsValues.startDateSecondPeriod : undefined,
                  });
                }}
                aria-label="deviating first period"
              />
            </Box>
            <FormHelperText>{t("Has deviating first period")}</FormHelperText>
          </Flex>
          <Box width="50%">
            <DateSelector
              isDisabled={!hasDeviatingFirstPeriod}
              defaultDate={ifrsValues?.startDateSecondPeriod}
              onChange={async (startDateSecondPeriod) => {
                handleIfrsValueChange({
                  ...ifrsValues,
                  startDateSecondPeriod: startDateSecondPeriod ? startDateSecondPeriod : undefined,
                });
              }}
            />
            <FormHelperText>{t("Select second period start date in case of deviating first period")}</FormHelperText>
          </Box>
        </Flex>
      </FormControl>
      <FormControl pb="5">
        <FormLabel fontSize="sm" pb="1">
          {t("Monthly payment")}
        </FormLabel>
        <NumberInput
          placeholder={t("Monthly payment") ?? ""}
          backgroundColor="smBackground"
          // Needed since NumberInput sometimes converts the custom value to Number.MAX_SAFE_INTEGER
          clampValueOnBlur={false}
          value={formatMonetaryAmount({ val: ifrsValues?.monthlyPayment, currency })}
          onChange={(amount) => {
            const monthlyPayment = parseMonetaryAmount(amount, currency);
            if (monthlyPayment < 0) {
              console.log(monthlyPayment);
              return;
            }
            handleIfrsValueChange({
              ...ifrsValues,
              monthlyPayment: !isNil(monthlyPayment) && !isNaN(monthlyPayment) ? monthlyPayment : undefined,
            });
          }}
        >
          <NumberInputField />
        </NumberInput>
      </FormControl>
      <FormControl pb="5">
        <FormLabel fontSize="sm" pb="1">
          {t("Payment interval")}
        </FormLabel>
        <Input
          mt="5px"
          type="number"
          placeholder={`${t("Provide payment interval in number of months")}...`}
          backgroundColor="smBackground"
          value={ifrsValues?.paymentIntervalInMonths ?? ""}
          min={0}
          onChange={(e) => {
            const val = e.target.value.trim();
            const paymentIntervalInMonths = val ? parseInt(e.target.value.trim()) : undefined;
            handleIfrsValueChange({ ...ifrsValues, paymentIntervalInMonths });
          }}
        />
      </FormControl>
      <FormControl pb="5">
        <FormLabel fontSize="sm" pb="1">
          {t("Contract length")}
        </FormLabel>
        <Input
          type="number"
          placeholder={`${t("Provide contract length in number of months")}...`}
          backgroundColor="smBackground"
          value={ifrsValues?.contractLengthInMonths ?? ""}
          min={0}
          onChange={(e) => {
            const val = e.target.value.trim();
            const contractLengthInMonths = val ? parseInt(val) : undefined;
            handleIfrsValueChange({ ...ifrsValues, contractLengthInMonths });
          }}
        />
      </FormControl>
      <FormControl pb="5">
        <FormLabel fontSize="sm" pb="1">
          {t("Interest rate (%)")}
        </FormLabel>
        <NumberInput
          placeholder={`${t("Interest rate (%)")}...`}
          backgroundColor="smBackground"
          // Needed since NumberInput sometimes converts the custom value to Number.MAX_SAFE_INTEGER
          clampValueOnBlur={false}
          value={interestRate ?? ""}
          onChange={(ir) => {
            setInterestRate(ir);
            const parsedInterestRate = ir === "" ? undefined : parseFloat(ir) / 100;
            handleIfrsValueChange({ ...ifrsValues, interestRate: parsedInterestRate });
          }}
        >
          <NumberInputField />
        </NumberInput>
      </FormControl>
      <Text>{t("The values are used for IFRS reporting")}</Text>
      <Explanation
        fontSize="sm"
        enabled={!(ifrsValues?.filled === true)}
        text={t("Please fill out all other fields before adding adjustments")}
      >
        <Button
          mt="10px"
          size="sm"
          leftIcon={<Icon as={FaChartLine} w="15px" h="15px" />}
          variant={"solid"}
          colorScheme="green"
          onClick={() => setShowAddAdjustment(true)}
          isDisabled={!(ifrsValues?.filled === true)}
        >
          {t("Adjustments")}{" "}
          {ifrsValues?.adjustments && ifrsValues?.adjustments.length > 0 && `(${ifrsValues?.adjustments.length})`}
        </Button>
      </Explanation>
      <LinkBox mt="7px" width={0}>
        <LinkOverlay
          href={`${requireStringEnvVar("VITE_BFF_BASE_URL")}/api/contracts/${contractState.contract.id}/ifrs-report`}
        >
          <Explanation
            fontSize="sm"
            text={t("Please fill out all values before downloading the report")}
            enabled={!canDownloadReport}
          >
            <Button
              size="sm"
              leftIcon={<Icon as={FaDownload} w="15px" h="15px" />}
              isDisabled={!canDownloadReport}
              variant={"solid"}
              colorScheme="blue"
            >
              {t("Download IFRS report")}
            </Button>
          </Explanation>
        </LinkOverlay>
      </LinkBox>
    </>
  );
};
