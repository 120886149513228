import { ReactElement, useState } from "react";
import { CreateSourcingEventModal } from "./CreateSourcingEventModal";

export const useCreateBasicSourcingEventModal = (): {
  showCreateBasicSourcingEventModal: () => void;
  createBasicSourcingEventModal: ReactElement;
} => {
  const [showModal, setShowModal] = useState(false);

  return {
    showCreateBasicSourcingEventModal: () => setShowModal(() => true),
    createBasicSourcingEventModal: <CreateSourcingEventModal showModal={showModal} setShowModal={setShowModal} />,
  };
};
