import { Box, Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { assets } from "../../../assets/assets";
import { useGetSupplierEmailInviteQuery } from "../../../autogen/bff-api";
import { AlreadyAccepted } from "../AlreadyAccepted";
import { LoginDrawer } from "./LoginDrawer";
import { SignupDrawer } from "./SignupDrawer/SignupDrawer";
import { SignupOrLogin } from "./SignupOrLogin";

interface Props {
  inviteId: string;
  secret: string;
}

export const SupplierEmailInvite = ({ inviteId, secret }: Props) => {
  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const { t } = useTranslation();
  const { data, error } = useGetSupplierEmailInviteQuery({
    secret: secret,
    inviteId: inviteId,
  });

  if (error) return <AlreadyAccepted />;
  if (!data) return <div>{t("Loading")}...</div>;

  return (
    <Box
      width="full"
      height="100vh"
      bgGradient="linear(to-br, smTealShadow, smBackground, smBackground, smBackground, smBlueShadow)"
    >
      {showSignup && (
        <SignupDrawer
          asOrg={true}
          isOpen={showSignup}
          onClose={() => setShowSignup(false)}
          invitationContext={{
            emailInviteId: inviteId,
            emailInviteType: "Supplier",
            secret: secret,
          }}
        />
      )}
      {showLogin && (
        <LoginDrawer
          isOpen={showLogin}
          onClose={() => setShowLogin(false)}
          invitationContext={{
            emailInviteId: inviteId,
            emailInviteType: "Supplier",
            secret: secret,
          }}
        />
      )}
      <Grid
        gridTemplateColumns={"1fr minmax(0, 500px) 1fr"}
        height="100%"
        gridTemplateRows={"1fr minmax(480px, auto) 2fr"}
        templateAreas={`
                "left top right"
                "left login right"
                "left bottom right"
                `}
      >
        <GridItem
          area={"login"}
          rounded="md"
          backgroundColor="smBackground"
          boxShadow="0 9px 46px 8px rgb(0,0,0,0.2)"
          padding={10}
        >
          <Flex w="100%" h={"100%"} flexDirection="column">
            <Flex paddingTop={"20px"} paddingBottom="35px">
              <Image src={assets.logo} alt="Logo" h="30px" mt="10px" />
            </Flex>
            <Text fontSize="2xl" mt="5px">
              <b>{t("Invitation to join the SourceMagnet network!")}</b>
            </Text>
            <Text fontSize="md" mt="20px">
              {t("You have been invited to join SourceMagnet by")} {data.sentBy.firstName} {data.sentBy.lastName}.
            </Text>
            <Text fontSize="md" mt="20px">
              {t("Use this invite to")} <b>{t("sign up")}</b> {t("or")} <b>{t("log in singular")}</b>{" "}
              {t("if you already have an account")}.
            </Text>
            <SignupOrLogin onSignup={() => setShowSignup(true)} onLogin={() => setShowLogin(true)} />
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};
