import { Flex } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useListCommentsForContractTaskQuery } from '../../../../../../autogen/bff-api'
import { useLoggedInWithOrgContextState } from '../../../../../../common/auth/useLoggedInWithOrgContextState'
import { AddComment } from './AddComment'
import { Comment } from './Comment'

interface Props {
    taskId: string
}

export const Comments: React.FC<Props> = ({ taskId }) => {
    const authState = useLoggedInWithOrgContextState()
    const { data, refetch } = useListCommentsForContractTaskQuery({
        organizationId: authState.selectedOrg.id,
        taskId: taskId,
    })
    const { t } = useTranslation()
    if (!data) {
        return <div>{t('Loading...')}</div>
    }
    return (
        <Flex flexDirection={'column'}>
            <AddComment taskId={taskId} onComplete={refetch} />
            {data.comments.map((comment) => (
                <Comment
                    key={comment.id}
                    comment={comment}
                    onChangeComplete={refetch}
                />
            ))}
        </Flex>
    )
}
