import { Box, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Spinner } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoggedInWithOrgContextState } from "../../../../../common/auth/useLoggedInWithOrgContextState";
import { SingleSelector } from "../../../../../common/input/Selector/SingleSelector";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { containsError } from "../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { displayPersonNameWithEmail } from "../../../view-single/sharing/AddExternalParticipantModal";
import { useContractState } from "../../useContractState";

export const InternalOwner = () => {
  const { t } = useTranslation();
  const contractState = useContractState();
  const [isLoading, setIsLoading] = useState(false);
  const authState = useLoggedInWithOrgContextState();
  const dispatch = useAppDispatch();
  const errorMessage = containsError(contractState, "EditInternalOwner") ? t("Update failed") : null;

  const eventHandler = async (personId: string) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "EditInternalOwner",
          value: personId,
        },
      })
    );
    setIsLoading(false);
  };

  const persons = authState.selectedOrg.users.map((u) => u.person);

  return (
    <FormControl id="responsiblePerson" isInvalid={errorMessage !== null}>
      <FormLabel>
        {t("Responsible person")} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Box borderRadius={"10px"}>
        <Flex>
          <SingleSelector
            value={{
              value: contractState.contract.internalOwner.id,
              label: displayPersonNameWithEmail(contractState.contract.internalOwner),
            }}
            options={persons.map((e) => ({ value: e.id, label: displayPersonNameWithEmail(e) }))}
            onChange={(option) => {
              if (option) eventHandler(option.value);
            }}
            noMatchingOptionsMessage={t("No matching persons found") ?? ""}
            noOptionsProvidedMessage={t("No persons available") ?? ""}
          />
        </Flex>
      </Box>
      <FormHelperText>{t("Provide a responsible person in your company")}</FormHelperText>
      <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
