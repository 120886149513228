import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaEye, FaStar } from "react-icons/fa";

export const Rating: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Flex
      flexDirection={"column"}
      alignItems="start"
      justifyContent={"start"}
      borderRadius={"10px"}
      boxShadow="0 3px 15px rgba(71, 85, 105, 0.16)"
      padding="25px"
      bg="smBackground"
      w="100%"
    >
      <Box w="100%">
        <Text fontSize={"md"} fontWeight="bold" color="#a4a7a6">
          {t("Internal rating")}
        </Text>
        <Flex w="100%" mt="12px">
          <Flex>
            <Icon as={FaStar} w="20px" h="20px" color="yellow.400" />
          </Flex>
          <Flex ml="10px">
            <Icon as={FaStar} w="20px" h="20px" color="yellow.400" />
          </Flex>
          <Flex ml="10px">
            <Icon as={FaStar} w="20px" h="20px" color="yellow.400" />
          </Flex>
          <Flex ml="10px">
            <Icon as={FaStar} w="20px" h="20px" color="yellow.400" />
          </Flex>
          <Flex ml="10px">
            <Icon as={FaStar} w="20px" h="20px" color="gray.200" />
          </Flex>
        </Flex>
        <Flex mt="13">
          <Text color="#717171" fontSize={"sm"}>
            {t("Based on 7 reviews")}
          </Text>
        </Flex>
      </Box>
      <Flex mt="15">
        <Button colorScheme="yellow" size="sm" variant="solid" leftIcon={<Icon as={FaEye} w="12px" h="12px" />}>
          {t("Read reviews")}
        </Button>
      </Flex>
    </Flex>
  );
};
