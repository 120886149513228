import { Icon } from "@chakra-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaCheckCircle, FaMinusCircle, FaTimesCircle } from "react-icons/fa";
import { Explanation } from "../../../../common/support/Explanation/Explanation";

interface Props {
  status: QuestionnaireStatus;
}

export const QuestionnaireStatusTag: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  switch (status) {
    case "Completed":
      return (
        <Explanation enabled={true} text={t("This questionnaire has been completed")} shouldWrapChildren={true}>
          <Icon as={FaCheckCircle} w="20px" h="20px" color="green.500" />
        </Explanation>
      );
    case "NotCompleted":
      return (
        <Explanation enabled={true} text={t("This questionnaire is not yet completed")} shouldWrapChildren={true}>
          <Icon as={FaMinusCircle} w="20px" h="20px" color="gray.500" />
        </Explanation>
      );
    case "Declined":
      return (
        <Explanation
          enabled={true}
          text={t("The company has declined to complete this questionnaire")}
          shouldWrapChildren={true}
        >
          <Icon as={FaTimesCircle} w="20px" h="20px" color="red.500" />
        </Explanation>
      );
  }
};

export type QuestionnaireStatus = "Completed" | "NotCompleted" | "Declined";
