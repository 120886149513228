import {
  ContractDataFieldSectionDto,
  ContractTemplateDto,
  CustomAmountFieldDto,
  CustomBooleanFieldDto,
  CustomDateFieldDto,
  CustomEmailFieldDto,
  CustomEnumFieldDto,
  CustomMultiEnumFieldDto,
  CustomNumberFieldDto,
  CustomPercentageFieldDto,
  CustomPersonFieldDto,
  CustomPhoneFieldDto,
  CustomRichTextFieldDto,
  CustomTagFieldDto,
  CustomTextFieldDto,
  CustomUrlFieldDto,
  NumberFieldDto,
  ProductCommentDto,
} from "../autogen/bff-api";

export interface ProductGroup {
  id: string;
  sourcingEventId?: string;
  projectId?: string;
  name: string;
  products?: Product[];
  productFields: ProductTemplateField[];
  originProductGroupId?: string;
}

export type ProductGroupDraft = Omit<ProductGroup, "id">;

export interface Product {
  id: string;
  quantity: NumberFieldDto;
  unitPrice?: NumberFieldDto;
  productFields: ProductField[];
  conversation?: ProductCommentDto[];
}

export type ProductDraft = Omit<Product, "id"> & { id?: string };

export type ProductFieldType = "Text" | "Number" | "Boolean" | "Quantity";

export const productFieldTypes: ProductFieldType[] = ["Text", "Boolean", "Number"];

export interface ProductTemplateField {
  id: string;
  name: string;
  type: ProductFieldType;
  populatedBy: "Buyer" | "Supplier";
  isRequired: boolean;
}

export type ProductField = ProductTemplateField & { value?: number | string | boolean | null };

export const isProductDraft = (product: Product | ProductDraft) => {
  return !(product as Product).id;
};

export enum FetchState {
  Idle = "Idle",
  Loading = "Loading",
  Error = "Error",
  Success = "Success",
}

export interface CustomContractFieldSections {
  [id: string]: ContractDataFieldSection;
}

export type ContractTemplate = Omit<ContractTemplateDto, "requiredCustomDataFields"> & {
  requiredCustomDataFields: CustomContractFieldSections;
};

export enum CustomFieldType {
  CustomNumber = "CustomNumber",
  CustomAmount = "CustomAmount",
  CustomDate = "CustomDate",
  CustomEmail = "CustomEmail",
  CustomBoolean = "CustomBoolean",
  CustomEnum = "CustomEnum",
  CustomMultiEnum = "CustomMultiEnum",
  CustomPercentage = "CustomPercentage",
  CustomPerson = "CustomPerson",
  CustomPhone = "CustomPhone",
  CustomRichText = "CustomRichText",
  CustomText = "CustomText",
  CustomTag = "CustomTag",
  CustomUrl = "CustomUrl",
}

export type CustomContractField =
  | CustomAmountField
  | CustomNumberField
  | CustomBooleanField
  | CustomDateField
  | CustomEmailField
  | CustomEnumField
  | CustomMultiEnumField
  | CustomPercentageField
  | CustomPersonField
  | CustomPhoneField
  | CustomRichTextField
  | CustomTextField
  | CustomUrlField
  | CustomTagField;

export interface ContractDataFieldSection extends ContractDataFieldSectionDto {
  fields: CustomContractField[];
}

export interface CustomNumberField extends CustomNumberFieldDto {
  type: CustomFieldType.CustomNumber;
}

export interface CustomAmountField extends CustomAmountFieldDto {
  type: CustomFieldType.CustomAmount;
}

export interface CustomDateField extends CustomDateFieldDto {
  type: CustomFieldType.CustomDate;
}

export interface CustomEmailField extends CustomEmailFieldDto {
  type: CustomFieldType.CustomEmail;
}

export interface CustomBooleanField extends CustomBooleanFieldDto {
  type: CustomFieldType.CustomBoolean;
}

export interface CustomEnumField extends CustomEnumFieldDto {
  type: CustomFieldType.CustomEnum;
}

export interface CustomMultiEnumField extends CustomMultiEnumFieldDto {
  type: CustomFieldType.CustomMultiEnum;
}

export interface CustomPercentageField extends CustomPercentageFieldDto {
  type: CustomFieldType.CustomPercentage;
}

export interface CustomPersonField extends CustomPersonFieldDto {
  type: CustomFieldType.CustomPerson;
}

export interface CustomPhoneField extends CustomPhoneFieldDto {
  type: CustomFieldType.CustomPhone;
}

export interface CustomRichTextField extends CustomRichTextFieldDto {
  type: CustomFieldType.CustomRichText;
}

export interface CustomTagField extends CustomTagFieldDto {
  type: CustomFieldType.CustomTag;
}

export interface CustomUrlField extends CustomUrlFieldDto {
  type: CustomFieldType.CustomUrl;
}

export interface CustomTextField extends CustomTextFieldDto {
  type: CustomFieldType.CustomText;
}
