import { Box, Button, Icon, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ListSourcingEventsFilterStatus, useListSeSupplierViewsQuery } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../common/errors/useApiError";
import { displayDateWithTimeAndTz } from "../../../../common/formatting/displayDateWithTimeAndTz";
import { useSkeletons } from "../../../../common/useSkeletons";
import { urls } from "../../../../urls";
import { BidInvitationStatus } from "../../../bids/view-all/BidInvitationStatus";

interface Props {
  subtitle: string;
  status: ListSourcingEventsFilterStatus;
}

export const SourcingEventsTableForSupplier = ({ subtitle, status }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();
  const { data, error } = useListSeSupplierViewsQuery({
    orgId: authState.selectedOrg.id,
    status: status,
  });
  const skeletons = useSkeletons();
  const displayer = useApiError();

  if (error) return displayer.show(error);

  return (
    <>
      <Box py="5">
        <Text fontSize="medium" color="smMuted">
          {subtitle}
        </Text>
      </Box>
      <Box border="1px solid" borderColor="smBorder" rounded="lg" pb="2.5" mb="8">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>{t("Title")}</Th>
              <Th>{t("Buyer")}</Th>
              <Th>{t("Contact person")}</Th>
              <Th width={"150px"}>{t("Deadline")}</Th>
              <Th>{t("Status")}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {!data?.events && (
              <Tr>
                <Td colSpan={6}>{skeletons.stackedLines(20)}</Td>
              </Tr>
            )}
            {data?.events.map((e) => (
              <Tr key={e.id}>
                <Td>
                  <Text fontSize={"sm"}>{e.title}</Text>
                </Td>
                <Td>
                  <Text fontSize={"sm"}>{e.owningOrganizationName}</Text>
                </Td>
                <Td>
                  <Text fontSize={"sm"}>
                    {e.contactPerson.firstName} {e.contactPerson.lastName}
                  </Text>
                </Td>
                <Td>
                  <Text fontSize={"sm"}>
                    {displayDateWithTimeAndTz({
                      timestamp: e.deadline,
                      timezone: e.timezone,
                      at: t("at"),
                    })}
                  </Text>
                </Td>
                <Td>
                  <BidInvitationStatus status={e.status} />
                </Td>
                <Td>
                  <Button
                    leftIcon={<Icon as={FaEye} w="15px" h="15px" />}
                    variant={"solid"}
                    colorScheme="teal"
                    size={"sm"}
                    onClick={() => navigate(urls.events.supplierView.go(e.id))}
                  >
                    {t("View event")}
                  </Button>
                </Td>
              </Tr>
            ))}
            {data?.events.length === 0 && (
              <Tr>
                <Td colSpan={5} width={"100%"} textAlign="center">
                  {t("You have no invites, yet!")}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
