import { Box, Button, Flex, Icon, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaBuilding, FaShareAlt } from "react-icons/fa";
import { ContractDto, useListParticipantsForContractsQuery } from "../../../../autogen/bff-api";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { loadContractThunk } from "../../../../common/redux/thunks/contract/load-contract-thunk";
import { isContractOrgParticipant } from "../../typeguards";
import { AddExternalParticipantModal } from "./AddExternalParticipantModal";
import { AddInternalParticipantModal } from "./AddInternalParticipantModal";
import { OrganizationParticipantRow } from "./OrganizationParticipantRow";
import { PersonParticipantRow } from "./PersonParticipantRow";

interface Props {
  contract: ContractDto;
}

export const Sharing = ({ contract }: Props) => {
  const { data, isLoading } = useListParticipantsForContractsQuery({ contractId: contract.id });
  const dispatch = useAppDispatch();
  const [showShareExternally, setShowShareExternally] = useState(false);
  const [showShareInternally, setShowShareInternally] = useState(false);

  const participants = data ? [...data.organizationParticipants, ...data.personParticipants] : undefined;

  return (
    <>
      {showShareExternally && (
        <AddExternalParticipantModal data={contract} onClose={() => setShowShareExternally(false)} />
      )}
      {showShareInternally && (
        <AddInternalParticipantModal data={contract} onClose={() => setShowShareInternally(false)} />
      )}
      <Flex w="100%" flexDirection={"column"}>
        {contract.canUpdateParticipants && (
          <Flex w="100%" justifyContent={"end"} mb="25px">
            <Button
              rightIcon={<Icon as={FaBuilding} />}
              variant="outline"
              size="sm"
              colorScheme="teal"
              onClick={() => setShowShareInternally(true)}
              mr="2"
            >
              {t("Add internal participant")}
            </Button>
            <Button
              rightIcon={<Icon as={FaShareAlt} />}
              variant="outline"
              size="sm"
              colorScheme="blue"
              onClick={() => setShowShareExternally(true)}
            >
              {t("Add external participant")}
            </Button>
          </Flex>
        )}
        <Box borderRadius={10} width="full" border="1px solid" borderColor="smBorder" pb="2">
          {isLoading && (
            <Flex height={"40"} justifyContent={"center"} alignItems={"center"}>
              <Spinner />
            </Flex>
          )}
          {participants && (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>{t("Name")}</Th>
                  <Th>{t("Role")}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                <>
                  {participants.map((p) => {
                    if (isContractOrgParticipant(p)) {
                      return (
                        <OrganizationParticipantRow
                          key={p.id}
                          participant={p}
                          contractId={contract.id}
                          onRemoved={async () => {
                            await dispatch(loadContractThunk({ contractId: contract.id }));
                          }}
                          canDelete={contract.canUpdateParticipants}
                        />
                      );
                    } else {
                      return (
                        <PersonParticipantRow
                          key={p.id}
                          participant={p}
                          contractId={contract.id}
                          onRemoved={async () => {
                            await dispatch(
                              loadContractThunk({
                                contractId: contract.id,
                              })
                            );
                          }}
                          canDelete={contract.canUpdateParticipants}
                        />
                      );
                    }
                  })}
                  {participants.length === 0 && (
                    <Tr>
                      <Td colSpan={4} width={"100%"} textAlign="center">
                        {t("No participants added")}
                      </Td>
                    </Tr>
                  )}
                </>
              </Tbody>
            </Table>
          )}
        </Box>
      </Flex>
    </>
  );
};
