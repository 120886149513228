import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaCopy, FaEye } from "react-icons/fa";
import { useGetTokenQuery, useUpdateOrganizationMutation } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { updateSelectedOrg } from "../../../../common/redux/reducers/authStateReducer";
import { ChangeAzureTenant } from "./ChangeAzureTenant";
import { ChangeAzureUsersDefaultRole } from "./ChangeAzureUsersDefaultRole";

export const AzureConfiguration = () => {
  const authState = useLoggedInWithOrgContextState();
  const [skip, setSkip] = useState(true);
  const {
    data: secret,
    isLoading: isLoadingSecret,
    refetch,
  } = useGetTokenQuery({ organizationId: authState.selectedOrg.id }, { skip });
  const [editOrg, { isLoading: isUpdatingOrg }] = useUpdateOrganizationMutation();
  const toast = useToast();
  const dispatch = useAppDispatch();

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>();

  const fetchToken = () => {
    setSkip(false);
    refetch();
  };

  return (
    <>
      <ChangeAzureTenant currentValue={authState.selectedOrg.azureTenantId ?? ""} />
      <ChangeAzureUsersDefaultRole currentValue={authState.selectedOrg.azureUsersDefaultRole} />
      <Flex
        maxWidth="full"
        width="full"
        flexDir={"column"}
        backgroundColor="smBackgroundSecondary"
        p="5"
        mt="5"
        rounded={"lg"}
      >
        <FormControl maxWidth="full" width="full">
          <FormLabel>{t("Provisioning Settings")}</FormLabel>
          <Flex maxWidth="full" width="full">
            {!secret && (
              <Button
                width={"40"}
                minWidth={"40"}
                colorScheme={"teal"}
                onClick={fetchToken}
                isLoading={isLoadingSecret}
              >
                {t("Get Secret")}
              </Button>
            )}
            {secret && isPasswordVisible && (
              <Box maxWidth="full" width="full">
                <Text
                  display={"inline-block"}
                  maxWidth="full"
                  width="full"
                  overflow={"hidden"}
                  wordBreak={"break-all"}
                  bg="transparent"
                >
                  {secret?.token}
                </Text>
                <IconButton
                  aria-label="hide password"
                  icon={<FaEye />}
                  colorScheme="teal"
                  onClick={() => setIsPasswordVisible(false)}
                />
              </Box>
            )}
            {secret && !isPasswordVisible && (
              <>
                <Input width="80" bg="transparent" type="password" value={secret?.token} disabled />
                <IconButton
                  aria-label="copy password"
                  icon={<FaCopy />}
                  colorScheme="teal"
                  mx="2"
                  onClick={() => {
                    navigator.clipboard.writeText(secret.token);
                    toast({
                      colorScheme: "teal",
                      status: "success",
                      description: t("Secret copied to the clip board."),
                    });
                  }}
                />
                <IconButton
                  aria-label="show password"
                  icon={<FaEye />}
                  colorScheme="teal"
                  onClick={() => setIsPasswordVisible(true)}
                />
              </>
            )}
          </Flex>
          <FormHelperText>{t("The secret must be provided when enabling provisioning in Azure")}.</FormHelperText>
          <Flex alignItems={"center"} pt="5">
            <Checkbox
              isChecked={authState.selectedOrg.allowScimUsersOnly}
              onChange={(e) => {
                editOrg({
                  orgId: authState.selectedOrg.id,
                  editOrganizationRequest: {
                    allowScimUsersOnly: e.target.checked,
                  },
                });
                dispatch(updateSelectedOrg({ ...authState.selectedOrg, allowScimUsersOnly: e.target.checked }));
              }}
            >
              {t("Only allow users provisioned through Azure SCIM provisioning to log in")}
            </Checkbox>
            {isUpdatingOrg && <Spinner color="teal" thickness="0.2rem" emptyColor="gray.200" ml="2" />}
          </Flex>
        </FormControl>
      </Flex>
    </>
  );
};
