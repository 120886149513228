import { Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import {
  CreateProductDto,
  ProductDto,
  ProductTemplateFieldDto,
  SeDto,
  useCreateProductGroupMutation,
  useEditProductGroupMutation,
  useGetProductGroupsForSourcingEventQuery,
} from "../../../../autogen/bff-api";
import { useRumError } from "../../../../common/errors/RumComponentContext/useRumError";
import { findOriginProduct, findOriginProductGroup, getProductCount } from "../../../../common/products/productUtils";
import { mapDtoToProductGroup } from "../../../../common/redux/reducers/productReducer";
import { Product, ProductGroup } from "../../../../common/types";

interface MatchedProducts {
  groupId: string;
  matchedProducts: Product[];
  newProducts: Product[];
}

const countNewProducts = ({
  newGroups,
  matchedGroups,
}: {
  newGroups: ProductGroup[];
  matchedGroups: MatchedProducts[];
}) =>
  newGroups.reduce((count, g) => count + (g.products?.length ?? 0), 0) +
  matchedGroups.reduce((count, g) => count + (g.newProducts.length ?? 0), 0);

const countUpdatedProducts = (matchedGroups: MatchedProducts[]) =>
  matchedGroups.reduce((count, g) => count + (g.matchedProducts.length ?? 0), 0);

const getBorderColor = ({ isDropping, isSelected }: { isDropping: boolean; isSelected: boolean }) => {
  if (isDropping) return "smSecondary";
  if (isSelected) return "smTertiary";
  return "smBorder";
};

export const SourcingEventTarget = ({
  event,
  selectedProducts,
  isSelected,
  setHighlightedProducts,
}: {
  event: SeDto;
  selectedProducts?: ProductGroup[];
  isSelected: boolean;
  setHighlightedProducts: (groups?: ProductGroup[]) => void | Promise<void>;
}) => {
  const toast = useToast();
  const rumError = useRumError();

  const [isDropping, setIsDropping] = useState(false);

  const [createProductGroup, { isLoading: isCreatingProductGroup }] = useCreateProductGroupMutation();
  const [updateProductGroup, { isLoading: isUpdatingProductGroup }] = useEditProductGroupMutation();

  const {
    data,
    isLoading,
    isFetching: isFetchingProducts,
    error,
    refetch,
  } = useGetProductGroupsForSourcingEventQuery({ sourcingEventId: event.id });

  const productGroups = useMemo(() => {
    return data?.productGroups.map((g) => mapDtoToProductGroup(g));
  }, [data?.productGroups]);

  const productCount = useMemo(() => {
    if (data) return getProductCount(data.productGroups as ProductGroup[]);
  }, [data]);

  const groupCount = useMemo(() => {
    return data?.productGroups.length;
  }, [data?.productGroups.length]);

  const matchedProducts = useMemo(() => {
    if (selectedProducts) {
      const matchedGroups: MatchedProducts[] = [];
      const newGroups: ProductGroup[] = [];
      selectedProducts.forEach((g) => {
        const matchedGroup = findOriginProductGroup({
          originProductGroupId: g.id,
          groups: (data?.productGroups ?? []) as ProductGroup[],
        });
        if (matchedGroup) {
          const matchedProducts: Product[] = [];
          const newProducts: Product[] = [];
          g.products?.forEach((p) => {
            const matchedProduct = findOriginProduct({
              productId: p.id,
              originProductGroupId: g.id,
              groups: (data?.productGroups ?? []) as ProductGroup[],
            });
            if (matchedProduct) matchedProducts.push(p);
            else newProducts.push(p);
          });
          matchedGroups.push({ groupId: matchedGroup.id, matchedProducts, newProducts });
        } else newGroups.push({ ...g, originProductGroupId: g.id });
      });
      return { matchedGroups, newGroups };
    }
  }, [data?.productGroups, selectedProducts]);

  useEffect(() => {
    if (!selectedProducts) setIsDropping(false);
    if (selectedProducts) setHighlightedProducts(undefined);
  }, [selectedProducts, setHighlightedProducts]);

  return (
    <Flex
      key={event.id}
      flexDirection="column"
      rounded="md"
      border="2px dashed"
      borderColor={getBorderColor({ isDropping: isDropping && event.canEdit, isSelected })}
      cursor="pointer"
      p="5"
      onDragOver={(e) => {
        e.preventDefault();
        setIsDropping(true);
      }}
      onDragLeave={() => {
        setIsDropping(false);
      }}
      onDrop={async () => {
        if (!event.canEdit) {
          toast({
            title: `${t("Not allowed")}.`,
            description: `${t("Only admins and creators can edit products")}...`,
            status: "warning",
            variant: "subtle",
          });
          return;
        }
        if (!selectedProducts) {
          toast({ description: t("Something went wrong - could not add product"), status: "error" });
          rumError(new Error("Failed to add project product to sourcing event"), undefined);
          return;
        }
        if (matchedProducts) {
          for (const g of matchedProducts.newGroups) {
            await createProductGroup({
              createProductGroupDto: {
                name: g.name,
                originProductGroupId: g.originProductGroupId,
                sourcingEventId: event.id,
                productFields: g.productFields as ProductTemplateFieldDto[],
                products: g.products as CreateProductDto[],
              },
            });
          }
          for (const g of matchedProducts.matchedGroups) {
            await updateProductGroup({
              editProductGroupDto: {
                id: g.groupId,
                upsertProducts: [...g.matchedProducts, ...g.newProducts] as ProductDto[],
              },
            });
          }
          toast({
            title: `${t("Updated products")}!`,
            description: `
              ${
                countNewProducts(matchedProducts)
                  ? `${t("Added")} ${countNewProducts(matchedProducts)} ${t("new products")}.`
                  : ""
              }
              ${
                countUpdatedProducts(matchedProducts.matchedGroups)
                  ? `${t("Updated")} ${countUpdatedProducts(matchedProducts.matchedGroups)} ${t("products")}.`
                  : ""
              }
            `,
            variant: "subtle",
            status: "success",
          });
        }
        setIsDropping(false);
        refetch();
      }}
      onClick={() => {
        if (isSelected) setHighlightedProducts(undefined);
        else setHighlightedProducts(productGroups);
      }}
    >
      <Text fontWeight="bold" fontSize="sm" pb="4">
        {event.title}
      </Text>
      {data && !selectedProducts?.length && (
        <Text fontSize="sm" pb="2">
          {groupCount} {groupCount === 1 ? t("product group") : t("product groups")} {t("with")} {productCount}{" "}
          {productCount === 1 ? t("product") : t("products")} {t("in total")}.
        </Text>
      )}
      {data && selectedProducts && matchedProducts && (
        <Text fontSize="sm" color={matchedProducts?.matchedGroups ? "smPrimary" : "smSecondary"} pb="2">
          {`
              ${
                countNewProducts(matchedProducts)
                  ? `${countNewProducts(matchedProducts)} ${t("products not added yet")}.`
                  : ""
              }
              ${
                countUpdatedProducts(matchedProducts.matchedGroups)
                  ? `${countUpdatedProducts(matchedProducts.matchedGroups)} ${t("products already added")}.
                    ${event.canEdit ? `${t("Drag and drop to overwrite")}.` : ""}
                  `
                  : ""
              }
            `}
        </Text>
      )}
      {(isCreatingProductGroup || isUpdatingProductGroup) && (
        <Flex fontSize="sm" align="center" pb="2">
          <Text pr="1">{t("Updating sourcing event products")}.</Text>
          <Spinner color="smPrimary" size="sm" />
        </Flex>
      )}
      {isFetchingProducts && (
        <Flex fontSize="sm" align="center" pb="2">
          <Text pr="1">{t("Loading products")}.</Text>
          <Spinner color="smPrimary" size="sm" />
        </Flex>
      )}
      {!isLoading && !data && error && <Text color="red">{t("Failed to load products")}.</Text>}
    </Flex>
  );
};
