import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AdminOrgDto } from '../../../autogen/bff-api'
import { setImpersonatedPersonId } from '../../../common/local-storage/impersonated-person'
import { clearOrganizationId } from '../../../common/local-storage/selected-organization'

interface Props {
    organization: AdminOrgDto
    onClose: () => void
}

export const AdminImpersonateUserModal: React.FC<Props> = ({
    organization,
    onClose,
}) => {
    const { t } = useTranslation()

    const persons = [
        organization.owner,
        ...organization.admins,
        ...organization.creators,
        ...organization.viewers,
    ]

    return (
        <Modal isOpen={true} onClose={onClose}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>{t('Edit organization')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    {persons.map((e) => (
                        <Button
                            key={e.id}
                            colorScheme="blue"
                            variant={'solid'}
                            onClick={() => {
                                setImpersonatedPersonId(e.id)
                                clearOrganizationId()
                                window.location.reload()
                            }}
                        >
                            {e.email}
                        </Button>
                    ))}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
