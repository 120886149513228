import { Box, Flex, Icon, IconButton, Table, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaCompressAlt, FaExpandAlt } from "react-icons/fa";
import { Product, ProductGroup } from "../../../../common/types";
import { ProductRowForm } from "./ProductRowForm";

export const ProductGroupForm = ({ group }: { group: ProductGroup }) => {
  const [showFullScreen, setShowFullScreen] = useState(false);

  return (
    <Box
      position={showFullScreen ? "absolute" : "relative"}
      zIndex={showFullScreen ? "docked" : "auto"}
      width="full"
      left="0"
      px={showFullScreen ? "2" : "0"}
      pb={showFullScreen ? "8" : "0"}
    >
      <Box
        width="full"
        backgroundColor="smBackground"
        border="1px solid"
        borderColor="smBorder"
        rounded="lg"
        shadow={showFullScreen ? "lg" : ""}
        pb="2.5"
      >
        <Flex alignItems={"center"} pb="4" pl="4" justifyContent={"space-between"}>
          <Text size="lg" fontWeight={"bold"} pt="4">
            {group.name}
          </Text>
          <IconButton
            aria-label="Toggle product group size"
            icon={<Icon as={showFullScreen ? FaCompressAlt : FaExpandAlt} />}
            size="sm"
            variant={"ghost"}
            onClick={() => setShowFullScreen(!showFullScreen)}
          />
        </Flex>
        <Box width={"full"} maxWidth={"full"} overflowX={"auto"}>
          <Table variant={"simple"} size="sm">
            <Thead>
              <Tr>
                {group.productFields
                  .filter((field) => field.populatedBy === "Buyer")
                  .map((field) => (
                    <Th key={field.id}>{field.name}</Th>
                  ))}
                <Th>{t("Quantity")}</Th>
                <Th>{t("Unit Price")}</Th>
                {group.productFields
                  .filter((field) => field.populatedBy === "Supplier")
                  .map((field) => (
                    <Th key={field.id}>{field.name}</Th>
                  ))}
              </Tr>
            </Thead>
            <Tbody>
              {group.products?.map((product) => (
                <ProductRowForm
                  key={product.id}
                  groupId={group.id}
                  product={product as Product}
                  productFields={group.productFields}
                />
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
};
