import { Button, Flex, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { ContractTaskDto, useUpdateContractTaskMutation } from "../../../../../../autogen/bff-api";
import { DateSelector } from "../../../../../../common/input/DateTimeSelectors/DateSelector";

interface Props {
  task: ContractTaskDto;
  onEditComleted: (task: ContractTaskDto) => void;
  onCancel: () => void;
}

export const EditDueDate = ({ task, onEditComleted, onCancel }: Props) => {
  const [date, setDate] = useState<string | undefined>(task.dueDate);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [update] = useUpdateContractTaskMutation();
  return (
    <Flex flexDirection={"column"} w="100%" mt="10px">
      <DateSelector defaultDate={date} onChange={async (e) => setDate(e)} />
      <Flex mt="10px">
        <Button
          id="save-due-date-button"
          variant={"solid"}
          colorScheme="teal"
          size="xs"
          isLoading={isLoading}
          onClick={async () => {
            setIsLoading(true);
            const result = await update({
              taskId: task.id,
              updateTaskRequest: {
                dueDate: date,
              },
            });
            setIsLoading(false);

            if ("data" in result) {
              onEditComleted(result.data);
            } else {
              toast({
                title: t("Could not edit due date"),
                status: "error",
              });
            }
          }}
        >
          {t("Save")}
        </Button>
        <Button variant={"solid"} colorScheme="gray" size="xs" ml="5px" onClick={onCancel}>
          {t("Cancel")}
        </Button>
      </Flex>
    </Flex>
  );
};
