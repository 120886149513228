import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isNil } from "lodash";
import { BseSupplierViewDto, ProductGroupBidDto, UploadDto, ViewDocumentAsOwnerDto } from "../../../autogen/bff-api";
import { FetchState, Product, ProductGroup } from "../../types";
import { editBasicBidThunk } from "../thunks/basic-bid/edit-basic-bid-thunk";
import { loadBasicBidThunk } from "../thunks/basic-bid/load-basic-bid-thunk";

export type BasicBidState = {
  id: string;
  lastChanged: string;
  forEvent: BseSupplierViewDto;
  comment: {
    value: string | null;
    errorMessage: string | null;
  };
  productGroupBids: {
    value: ProductGroupBidDto[] | null;
    errorMessage: string | null;
  };
  documents: {
    value: ViewDocumentAsOwnerDto[];
    errorMessage: string | null;
  };
  uploads: {
    value: UploadDto[];
    errorMessage: string | null;
  };
};

interface State {
  bid: BasicBidState | null;
  fetchState: FetchState;
  productGroups?: ProductGroup[];
}

const initialState: State = {
  bid: null,
  fetchState: FetchState.Idle,
};

export const slice = createSlice<
  State,
  {
    setProductGroups: (state: State, action: PayloadAction<ProductGroup[]>) => void;
    resetState: (state: State) => void;
    setProduct: (state: State, action: PayloadAction<{ groupId: string; product: Product }>) => void;
  }
>({
  name: "basicBid",
  initialState,
  reducers: {
    setProductGroups: (state, action) => {
      if (!state.bid) return;
      state.productGroups = action.payload.map((group) => {
        const groupBid = (state.bid as BasicBidState).productGroupBids?.value?.find(
          (g) => g.productGroupId === group.id
        );
        return {
          ...group,
          products: group.products?.map((product: Product) => {
            const productBid = groupBid?.productBids.find((p) => p.productId === product.id);
            return {
              ...product,
              unitPrice: productBid?.unitPrice,
              productFields: product.productFields.map((field) => {
                const value = productBid?.productFields.find((f) => f.name === field.name)?.value;
                return {
                  ...field,
                  ...(field.populatedBy === "Supplier" ? { value } : null),
                };
              }),
            };
          }),
        };
      });
    },
    resetState: (state) => {
      state.bid = null;
      state.productGroups = undefined;
    },
    setProduct: (state, action) => {
      const updatedProduct = action.payload.product;
      const group = state.productGroups?.find((g) => g.id === action.payload.groupId);
      if (!group) throw Error("Product group not found.");
      const productIndex = group.products?.findIndex((p) => p.id === updatedProduct.id);
      if (!isNil(productIndex) && isFinite(productIndex)) {
        if (group.products) group.products[productIndex] = updatedProduct;
        else group.products = [updatedProduct];
      } else {
        if (group.products) group.products.push(updatedProduct);
        else group.products = [updatedProduct];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadBasicBidThunk.pending, (state) => {
      state.fetchState = FetchState.Loading;
    });
    builder.addCase(loadBasicBidThunk.fulfilled, (state, action) => {
      const payload = action.payload;
      state.bid = payload;
      state.fetchState = FetchState.Success;
    });
    builder.addCase(loadBasicBidThunk.rejected, (state) => {
      state.fetchState = FetchState.Error;
    });
    builder.addCase(editBasicBidThunk.pending, (state) => {
      state.fetchState = FetchState.Loading;
    });
    builder.addCase(editBasicBidThunk.fulfilled, (state, action) => {
      const payload = action.payload;
      state.bid = payload;
    });
    builder.addCase(editBasicBidThunk.rejected, (state) => {
      state.fetchState = FetchState.Error;
    });
  },
});

export const { resetState, setProduct, setProductGroups } = slice.actions;
export default slice.reducer;
