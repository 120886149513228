import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Reauthenticate } from "./Reauthenticate";

interface Props {
  onClose: () => void;
  onReauthSuccessful: () => void;
}

export const ReauthenticateModal = ({ onClose, onReauthSuccessful }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={true} onClose={onClose} closeOnOverlayClick={false} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Confirm email")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Reauthenticate onReauthSuccessful={onReauthSuccessful} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
