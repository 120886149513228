import { Button, Icon, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCog, FaEdit, FaFileContract, FaFileSignature, FaPlus, FaTasks } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CreateContractModal } from "../../../../pages/contracts/create/CreateContractModal";
import { urls } from "../../../../urls";
import { useLoggedInWithOrgContextState } from "../../../auth/useLoggedInWithOrgContextState";
import { useSub } from "../../../subscription/useSub";
import { Explanation } from "../../../support/Explanation/Explanation";

export const Contracts = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();
  const [showCreateContractModal, setShowCreateContractModal] = useState(false);
  const sub = useSub();

  const canCreateContract = authState.selectedOrg.canCreateContract;

  const contractsMessage = t("Get in touch via the chat in the bottom right corner to get access to contracts");

  const getNewContractDisabledMessage = (): string | null => {
    if (!canCreateContract) {
      return t("Only admins and creators can create new contracts");
    } else if (!sub.hasContractAccess) {
      return contractsMessage;
    }

    return null;
  };
  const newContractDisabledMessage = getNewContractDisabledMessage();

  const getContractTypesDisabledMessage = (): string | null => {
    if (!authState.selectedOrg.canEdit) {
      return t("Only admins can manage contract settings");
    } else if (!sub.hasContractAccess) {
      return contractsMessage;
    }

    return null;
  };
  const contractTypesDisabledMessage = getContractTypesDisabledMessage();

  return (
    <>
      {showCreateContractModal && <CreateContractModal onClose={() => setShowCreateContractModal(false)} />}
      <Menu>
        <MenuButton
          as={Button}
          leftIcon={<Icon as={FaFileSignature} w="15px" h="15px" />}
          colorScheme="teal"
          variant="ghost"
          size={"sm"}
        >
          {t("Contracts")}
        </MenuButton>
        <MenuList>
          <Explanation
            enabled={newContractDisabledMessage !== null}
            placement={"left-start"}
            text={newContractDisabledMessage}
          >
            <MenuItem
              isDisabled={newContractDisabledMessage !== null}
              icon={<Icon as={FaPlus} w="15px" h="15px" marginBottom={-1} />}
              onClick={() => setShowCreateContractModal(true)}
            >
              {t("New contract")}
            </MenuItem>
          </Explanation>
          <Explanation enabled={!sub.hasContractAccess} text={contractsMessage} placement="left">
            <MenuItem
              isDisabled={!sub.hasContractAccess}
              icon={<Icon as={FaEdit} w="15px" h="15px" marginBottom={-1} />}
              onClick={() => {
                navigate(urls.contracts.viewDrafts);
              }}
            >
              {t("Contract drafts")}
            </MenuItem>
          </Explanation>
          <MenuItem
            icon={<Icon as={FaFileContract} w="15px" h="15px" marginBottom={-1} />}
            onClick={() => {
              navigate(`${urls.contracts.viewPublished}?status=Active`);
            }}
          >
            {t("All contracts")}
          </MenuItem>
          <Explanation
            enabled={contractTypesDisabledMessage !== null}
            text={contractTypesDisabledMessage}
            placement="left"
          >
            <MenuItem
              isDisabled={contractTypesDisabledMessage !== null}
              icon={<Icon as={FaCog} w="15px" h="15px" marginBottom={-1} />}
              onClick={() => {
                navigate(urls.contracts.settings);
              }}
            >
              {t("Settings")}
            </MenuItem>
          </Explanation>

          <MenuItem
            icon={<Icon as={FaTasks} w="15px" h="15px" marginBottom={-1} />}
            onClick={() => {
              navigate(urls.contracts.viewTasks);
            }}
          >
            {t("Tasks")}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
