import { Box, Button, Icon, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { OrganizationInviteDto } from "../../../../../autogen/bff-api";
import { useConfirmationModal } from "../../../../../common/useConfirmationModal";
import { useBasicSourcingEventState } from "../../../useBasicSourcingEventState";

interface Props {
  invites: OrganizationInviteDto[];
  removeInvite?: (inviteId: string) => Promise<void>;
}

export const OrganizationInvitesTable = ({ invites, removeInvite }: Props) => {
  const { t } = useTranslation();
  const eventState = useBasicSourcingEventState();
  const confirmationModal = useConfirmationModal();

  const mangualRemovalConfirmationRequired = (): boolean => {
    switch (eventState.event.status) {
      case "Awarded":
      case "Draft":
        return false;
      case "Completed":
      case "DeadlineHasPassed":
      case "Published":
        return true;
    }
  };

  return (
    <Box height="full" width="full" backgroundColor="smBackgroundSecondary" rounded="md">
      {confirmationModal.modal}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>
              <Text fontSize={"x-small"}>{t("Organization name")}</Text>
            </Th>
            {removeInvite && <Th></Th>}
          </Tr>
        </Thead>
        <Tbody>
          <>
            {invites.map((e) => {
              return (
                <Tr key={e.id}>
                  <Td>
                    <Text fontSize={"sm"}>{e.organizationName}</Text>
                  </Td>
                  {removeInvite !== undefined && (
                    <Td display={"flex"} flexDirection="row" justifyContent={"end"}>
                      <Button
                        leftIcon={<Icon as={FaTimes} w="15px" h="15px" />}
                        size="xs"
                        colorScheme="red"
                        variant={"solid"}
                        onClick={() => {
                          if (mangualRemovalConfirmationRequired()) {
                            confirmationModal.show({
                              title: t("Confirm removal"),
                              description: t(
                                "You are live, and the selected organizations wil immediately be removed from the event"
                              ),
                              onConfirmed: async () => {
                                await removeInvite(e.id);
                              },
                              confirmButtonColor: "blue",
                              confirmButtonText: t("Send") ?? "",
                            });
                          } else {
                            removeInvite(e.id);
                          }
                        }}
                      >
                        {t("Remove")}
                      </Button>
                    </Td>
                  )}
                </Tr>
              );
            })}
          </>
          {invites.length === 0 && (
            <Tr>
              <Td colSpan={4} width={"100%"} textAlign="center">
                {t("No organization invites have been added")}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};
