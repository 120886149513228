import { Box, Flex, FormControl, Icon, IconButton, Input, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaCheck, FaLock, FaLockOpen, FaPlus, FaTrash } from "react-icons/fa";
import {
  UpsertCustomContractDataFieldApiArg,
  useDeleteCustomContractDataFieldMutation,
  useUpsertCustomContractDataFieldMutation,
} from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { editCustomField, removeCustomField } from "../../../../common/redux/reducers/authStateReducer";
import { CustomContractField, CustomFieldType } from "../../../../common/types";
import { ToggleInternalField } from "./ToggleInternalField";

export const FieldForm = ({ sectionId, field }: { sectionId: string; field: CustomContractField }) => {
  const dispatch = useAppDispatch();
  const authState = useLoggedInWithOrgContextState();

  const [upsertField] = useUpsertCustomContractDataFieldMutation();
  const [deleteField, { isLoading: isDeleting }] = useDeleteCustomContractDataFieldMutation();

  const [newOption, setNewOption] = useState<string>();

  return (
    <Box key={field.id} pb="5">
      <Flex alignItems={"center"} justifyContent={"space-between"} width={"sm"}>
        <Flex alignItems={"center"}>
          <Text fontSize={"sm"} fontWeight={"semibold"} pr="2">
            {field.name}
          </Text>
          <Text fontSize={"sm"}>({field.type})</Text>
        </Flex>
        <IconButton
          aria-label="delete field"
          size="xs"
          variant={"ghost"}
          colorScheme="gray"
          icon={<Icon as={FaTrash} />}
          isLoading={isDeleting}
          onClick={async () => {
            await deleteField({
              orgId: authState.selectedOrg.id,
              deleteCustomFieldDto: { sectionId, fieldId: field.id },
            });
            dispatch(removeCustomField({ sectionId, fieldId: field.id }));
          }}
        />
      </Flex>
      <FormControl width="96" alignItems={"center"} py="2">
        <Flex>
          <ToggleInternalField
            label={t("Changing visibility will not affect existing contracts")}
            isChecked={field.isInternal}
            onChange={async (checked: boolean) => {
              const updatedField: CustomContractField = { ...field, isInternal: checked };
              dispatch(
                editCustomField({
                  sectionId,
                  field: updatedField,
                })
              );
              await upsertField({
                orgId: authState.selectedOrg.id,
                upsertCustomContractFieldDto: {
                  sectionId,
                  field: updatedField,
                },
              } as UpsertCustomContractDataFieldApiArg);
            }}
          />
          {field.isInternal ? (
            <Text fontSize={"xs"} color="smMuted">
              {t("Internal field - will not be visible to contract counterparty")}
              <Icon as={FaLock} boxSize={"3"} ml="1" />
            </Text>
          ) : (
            <Text fontSize={"xs"} color="smMuted">
              {t("Shared field - visible to contract counterparty")}
              <Icon as={FaLockOpen} boxSize={"3"} ml="1" />
            </Text>
          )}
        </Flex>
      </FormControl>
      {(field.type === CustomFieldType.CustomEnum || field.type === CustomFieldType.CustomMultiEnum) && (
        <Flex flexDirection={"column"}>
          {field.options?.length ? (
            field.options?.map((option) => (
              <Flex key={option} alignItems={"center"} justifyContent={"space-between"} maxWidth={"xs"} py="1">
                <Text
                  fontSize={"sm"}
                  fontStyle={"italic"}
                  backgroundColor="smBackgroundSecondary"
                  rounded="lg"
                  py="1"
                  px="4"
                >
                  {option}
                </Text>
                <IconButton
                  variant={"ghost"}
                  size={"xs"}
                  aria-label="delete option"
                  colorScheme="gray"
                  icon={<Icon as={FaTrash} />}
                  onClick={async () => {
                    const updatedField = { ...field, options: field.options?.filter((o) => o !== option) };
                    dispatch(
                      editCustomField({
                        sectionId,
                        field: updatedField,
                      })
                    );
                    await upsertField({
                      orgId: authState.selectedOrg.id,
                      upsertCustomContractFieldDto: {
                        sectionId,
                        field: updatedField,
                      },
                    } as UpsertCustomContractDataFieldApiArg);
                  }}
                />
              </Flex>
            ))
          ) : (
            <Text fontSize={"xs"} fontStyle={"italic"} py="2">
              {t("No options yet")}
            </Text>
          )}
          {newOption !== undefined && (
            <Flex maxWidth={"xs"}>
              <Input
                size={"xs"}
                rounded="md"
                onChange={(e) => {
                  setNewOption(e.target.value);
                }}
              />
              <IconButton
                size={"xs"}
                aria-label="save"
                variant={"outline"}
                colorScheme="teal"
                icon={<Icon as={FaCheck} />}
                onClick={async () => {
                  const updatedField = { ...field, options: [...(field.options ?? []), newOption] };
                  setNewOption(undefined);
                  dispatch(
                    editCustomField({
                      sectionId,
                      field: updatedField,
                    })
                  );
                  await upsertField({
                    orgId: authState.selectedOrg.id,
                    upsertCustomContractFieldDto: {
                      sectionId,
                      field: updatedField,
                    },
                  } as UpsertCustomContractDataFieldApiArg);
                }}
              />
            </Flex>
          )}
          <Flex justifyContent={"end"} maxWidth={"xs"}>
            <IconButton
              variant={"ghost"}
              size={"xs"}
              aria-label="add option"
              color="teal"
              icon={<Icon as={FaPlus} />}
              onClick={() => setNewOption("")}
            />
          </Flex>
        </Flex>
      )}
    </Box>
  );
};
