import { useParams } from "react-router-dom";
import { EmailInviteTypeDto } from "../../../autogen/bff-api";
import { useQueryParams } from "../../../common/useQueryParams";
import { OrgUserEmailInvite } from "./OrgUserEmailInvite";
import { SourcingEventEmailInvite } from "./SourcingEventEmailInvite";
import { SupplierEmailInvite } from "./SupplierEmailInvite";

export const EmailInvite = () => {
  const { emailInviteId } = useParams();
  const params = useQueryParams();
  const secret = params.get("secret");
  const rawType = params.get("type");
  const type = parseType(rawType);
  if (!emailInviteId || !secret || !type) {
    return <div>Invalid invite params!</div>;
  }

  switch (type) {
    case "SourcingEvent":
      return <SourcingEventEmailInvite inviteId={emailInviteId} secret={secret} />;
    case "Supplier":
      return <SupplierEmailInvite inviteId={emailInviteId} secret={secret} />;
    case "OrgUser":
      return <OrgUserEmailInvite inviteId={emailInviteId} secret={secret} />;
  }
};

const parseType = (type: string | null): EmailInviteTypeDto | null => {
  if (type === "SourcingEvent" || type === "Supplier" || type === "OrgUser") {
    return type;
  } else {
    return null;
  }
};
