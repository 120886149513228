import { Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";
import { BseDto } from "../../../../autogen/bff-api";
import { displaySum } from "../../../../common/formatting/displaySum";
import { EditEventValueModal } from "./EditEventValueModal";

interface Props {
  data: BseDto;
}

export const EventValue = ({ data }: Props) => {
  const { t } = useTranslation();
  const [showEditValueModal, setShowEditValueModal] = useState(false);

  if (data.awardedFields) {
    const eventValue = data.awardedFields.eventValue;
    return (
      <>
        {showEditValueModal && (
          <EditEventValueModal
            event={data}
            showModal={showEditValueModal}
            onClose={() => setShowEditValueModal(false)}
          />
        )}
        <Flex flex={3} mt="20px" borderRadius="6px" alignItems="center" flexDirection={"row"}>
          <Flex flexDirection={"column"}>
            <Flex alignItems={"center"}>
              <Text fontSize={"md"} fontWeight="semibold">
                {t("Event value")}
              </Text>

              <IconButton
                aria-label="edit event value"
                ml="10px"
                mt="3px"
                icon={<Icon as={FaEdit} w="13px" h="13px" />}
                variant={"ghost"}
                size="xs"
                colorScheme={"teal"}
                onClick={() => setShowEditValueModal(true)}
              />
            </Flex>
            <Text fontSize={"sm"}>
              {eventValue &&
                displaySum({
                  value: eventValue.value,
                  currency: eventValue.currency,
                })}
              {!eventValue && <i>{t("Not provided")}</i>}
            </Text>
          </Flex>
        </Flex>
      </>
    );
  }
  return null;
};
