import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import { MinimalOrgDto } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { OrganizationTypeTag } from "../../../common/organization/OrganizationTypeTag";
import { SearchResultContent } from "./SearchResultContent";

interface Props {
  organization: MinimalOrgDto;
}

export const SearchResult = ({ organization }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const isSelectedOrg = organization.id === authState.selectedOrg.id;

  return (
    <Grid gridTemplateColumns={"50px 1fr minmax(30px, auto)"} gridTemplateRows="1fr" marginLeft={4} w="100%">
      <GridItem>
        <Box
          overflow="hidden"
          bg={isSelectedOrg ? "blue.500" : organization.type === "Organization" ? "blue.500" : "gray.400"}
          h="50px"
          borderRadius={"10px"}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Text fontWeight={"bold"} color="smBackground">
            {organization.name.charAt(0)}
          </Text>
        </Box>
      </GridItem>
      <GridItem>
        <SearchResultContent organization={organization} />
      </GridItem>
      <GridItem display={"flex"} alignItems={"center"}>
        <Box mr="15px">
          <OrganizationTypeTag type={organization.type} isCurrentlyLoggedIn={isSelectedOrg} />
        </Box>
      </GridItem>
    </Grid>
  );
};
