import { Box, Button, useToast } from '@chakra-ui/react'
import { VerificationFlow } from '@ory/client'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { authService } from '../../../config'
import { useLoggedInWithOrgContextState } from '../useLoggedInWithOrgContextState'

interface Props {
    codeSent: (flow: VerificationFlow) => void
}

export const SendConfirmationCode: React.FC<Props> = ({ codeSent }) => {
    const authState = useLoggedInWithOrgContextState()
    const email = authState.me.email
    const [isSendingCode, setIsSendingCode] = useState(false)
    const { t } = useTranslation()
    const toast = useToast()
    const sendEmail = async () => {
        setIsSendingCode(true)
        const flow = await authService.sendVerificationLink(email)
        if (flow) {
            codeSent(flow)
        } else {
            toast({
                title: t('Could not send email code!'),
                status: 'error',
            })
        }
        setIsSendingCode(false)
    }

    return (
        <Box paddingBottom={'10px'}>
            {t('A verification email has already been sent to')} <b>{email}</b>.{' '}
            {t('Click the link in the email, or')}{' '}
            <Button
                colorScheme="teal"
                variant={'link'}
                isLoading={isSendingCode}
                onClick={sendEmail}
            >
                {t('request a new email')}
            </Button>{' '}
            {t("if you can't find the original email")}.
        </Box>
    )
}
