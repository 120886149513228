import { Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Spinner } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MinimalOrgDto } from "../../../../../autogen/bff-api";
import { OrganizationSelector } from "../../../../../common/organization/OrganizationSelector";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { containsError } from "../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../useContractState";
import { CreateOrganizationEntryModal } from "../create-entry/CreateOrganizationEntryModal";

export const Counterparty = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const contractState = useContractState();
  const [value, setValue] = useState<MinimalOrgDto | undefined>(() => {
    const counterParty = contractState.contract.counterparty;
    if (counterParty?.organization) return { ...counterParty.organization, type: "Organization" };
    if (counterParty?.organizationEntry) return { ...counterParty.organizationEntry, type: "OrganizationEntry" };
  });
  const [showCreateEntryModal, setShowCreateEntryModal] = useState(false);
  const errorMessage = containsError(contractState, "EditCounterparty") ? t("Update failed") : null;

  const [isLoading, setIsLoading] = useState(false);
  const isRequiredButNotProvided = !contractState.contract.counterparty;

  const update = async ({ organization }: { organization?: MinimalOrgDto }) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "EditCounterparty",
          value: {
            organizationId: organization?.type === "Organization" ? organization?.id : undefined,
            organizationEntryId: organization?.type === "OrganizationEntry" ? organization?.id : undefined,
          },
        },
      })
    );
    setIsLoading(false);
  };

  return (
    <>
      {showCreateEntryModal && (
        <CreateOrganizationEntryModal
          onConfirm={async (organization) => {
            update({ organization: { ...organization, type: "OrganizationEntry" } });
            setValue({ type: "OrganizationEntry", ...organization });
          }}
          onClose={() => setShowCreateEntryModal(false)}
        />
      )}
      <FormControl id="counterparty" isRequired={isRequiredButNotProvided} isInvalid={errorMessage !== null}>
        <FormLabel>
          {t("Counterparty")} {isLoading && <Spinner size="xs" />}
        </FormLabel>
        <OrganizationSelector
          size="md"
          placeholder={t("Select counterparty") ?? ""}
          organization={value}
          onChange={(value) => {
            setValue(value.organization);
            update(value);
          }}
        />
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
        <FormHelperText>
          {t("Please select an existing company, or")}{" "}
          <Button
            variant={"link"}
            colorScheme="blue"
            fontWeight={"bold"}
            fontSize="sm"
            onClick={() => {
              setShowCreateEntryModal(true);
            }}
          >
            {t("create a new company entry")}
          </Button>
        </FormHelperText>
      </FormControl>
    </>
  );
};
