import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useUpdateMeMutation } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { useAppDispatch } from "../../../common/redux/hooks";
import { updateMe } from "../../../common/redux/reducers/authStateReducer";

interface FormValues {
  firstName: string;
}

export const ChangeFirstName: React.FC = () => {
  const { me } = useLoggedInWithOrgContextState();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [editMe, { isLoading }] = useUpdateMeMutation();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { t } = useTranslation();
  const displayer = useApiError();

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      firstName: me.firstName,
    },
  });

  const value = watch("firstName");

  const onSubmit = async (values: FormValues) => {
    setIsRefreshing(true);

    const response = await editMe({
      editMeRequest: {
        firstName: values.firstName,
      },
    });
    if ("data" in response) {
      dispatch(updateMe(response.data));
    } else {
      displayer.trigger(response.error);
    }
    setIsRefreshing(false);

    toast({ title: t("First name has been updated!"), status: "success" });
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl mt="15px" isInvalid={errors.firstName !== undefined}>
          <FormLabel htmlFor={"firstName"}>{t("First name")}</FormLabel>
          <Input
            id={"firstName"}
            type="text"
            {...register("firstName", {
              required: t("Please provide your first name") ?? "",
              minLength: {
                value: 2,
                message: t("Must be at least 2 characters long"),
              },
            })}
          />
          <FormErrorMessage>{errors.firstName && errors.firstName.message}</FormErrorMessage>
          <FormHelperText>{t("Please provide your first name")}</FormHelperText>
        </FormControl>
        {me.firstName !== value && (
          <Button
            mt="10px"
            leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
            variant={"solid"}
            colorScheme="teal"
            size={"sm"}
            isLoading={isLoading || isRefreshing}
            type="submit"
          >
            {t("Save change")}
          </Button>
        )}
      </form>
    </>
  );
};
