import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect } from "react";
import { useGetProductGroupsAsSupplierQuery } from "../../autogen/bff-api";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setProductGroups } from "../redux/reducers/basicBidReducer";
import { mapDtoToProductGroup } from "../redux/reducers/productReducer";

export const useProductGroupsAsSupplier = () => {
  const dispatch = useAppDispatch();

  const bid = useAppSelector((state) => state.basicBid.bid);

  const {
    data,
    isFetching: isLoadingProductGroups,
    error: productGroupsError,
  } = useGetProductGroupsAsSupplierQuery(bid?.forEvent.id ? { sourcingEventId: bid.forEvent.id } : skipToken);

  const productGroups = useAppSelector((state) => state.basicBid.productGroups);

  useEffect(() => {
    if (
      data?.productGroups.length &&
      (!productGroups?.length || data.productGroups[0].sourcingEventId !== productGroups[0].sourcingEventId)
    ) {
      dispatch(setProductGroups(data.productGroups.map((g) => mapDtoToProductGroup(g))));
    }
  }, [data?.productGroups, dispatch, productGroups]);

  return { productGroups, isLoading: isLoadingProductGroups, error: productGroupsError };
};
