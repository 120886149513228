import { Box, Button, Flex, Icon } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { ContractDataFieldSection, CustomContractFieldSections } from "../../../../common/types";
import { ContractSectionModal } from "./ContractSectionModal";
import { SectionForm } from "./SectionForm";

export const CustomContractFields = () => {
  const authState = useLoggedInWithOrgContextState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Box>
      <ContractSectionModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <Flex justifyContent={"end"} pb="5">
        <Button
          colorScheme="teal"
          variant={"outline"}
          leftIcon={<Icon as={FaPlus} />}
          size="sm"
          onClick={() => setIsModalOpen(true)}
        >
          {t("Add Section")}
        </Button>
      </Flex>
      {Object.values(authState.selectedOrg.customContractSections ?? ({} as CustomContractFieldSections)).map(
        (section: ContractDataFieldSection) => (
          <SectionForm key={section.id} section={section} />
        )
      )}
    </Box>
  );
};
