import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SimpleInput } from "../../../../../common/input/SimpleInput/SimpleInput";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { containsError } from "../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../useContractState";

export const Title = () => {
  const dispatch = useAppDispatch();
  const contractState = useContractState();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<string>("");
  const { t } = useTranslation();
  const errorMessage = containsError(contractState, "EditTitle") ? t("Update failed") : undefined;

  useEffect(() => {
    setValue(contractState.contract.title);
  }, []);

  const update = async (value: string) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "EditTitle",
          value: value,
        },
      })
    );
    setIsLoading(false);
  };

  return (
    <SimpleInput
      name="title"
      label={t("Contract title")}
      helperText={t("Provide a title for your contract, this will be visible to all participants")}
      errorMessage={errorMessage}
      onChange={(value) => {
        setValue(value);
      }}
      isLoading={isLoading}
      debouncedOnChangeHandler={update}
      value={value}
    />
  );
};
