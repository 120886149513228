import { Box, Button, Flex, Icon, Table, TableCaption, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { ContractDto } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { loadContractThunk } from "../../../../common/redux/thunks/contract/load-contract-thunk";
import { requireNotificationFields } from "../../typeguards";
import { ContractNotificationModal } from "./ContractNotificationModal";
import { ContractNotificationRow } from "./ContractNotificationRow";
import { DefaultNotificationRow } from "./DefaultNotificationRow";

interface Props {
  data: ContractDto;
}

export const Notifications = ({ data }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const dispatch = useAppDispatch();

  const notifications = requireNotificationFields(data).notifications;

  const [showCreate, setShowCreate] = useState(false);
  return (
    <>
      {showCreate && <ContractNotificationModal data={data} onClose={() => setShowCreate(false)} />}
      <Flex w="100%" flexDirection={"column"}>
        <Flex w="100%" justifyContent={"end"} mb="25px">
          <Button
            leftIcon={<Icon as={FaPlus} w="15px" h="15px" />}
            variant="solid"
            size="sm"
            colorScheme={"blue"}
            onClick={() => setShowCreate(true)}
          >
            {t("New reminder")}
          </Button>
        </Flex>
        <Box border="1px solid" borderColor="gray.200" borderRadius={"lg"} width="full" pb="2" mb="8">
          <Table variant="simple">
            <TableCaption color="teal" placement="top" fontSize={"lg"}>
              {t("Reminders for this contract")}
            </TableCaption>
            <Thead>
              <Tr>
                <Th>{t("Type")}</Th>
                <Th>{t("Reminder time")}</Th>
                <Th>{t("Receivers")}</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {notifications.map((e) => (
                <ContractNotificationRow
                  key={e.id}
                  notification={e}
                  contractId={data.id}
                  onRemoved={async () => {
                    await dispatch(
                      loadContractThunk({
                        contractId: data.id,
                      })
                    );
                  }}
                />
              ))}
              {notifications.length === 0 && (
                <Tr>
                  <Td colSpan={4} width={"100%"} textAlign="center">
                    {t("No reminders added")}
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
        <Box border="1px solid" borderColor="gray.200" borderRadius={"lg"} width="full" pb="2">
          <Table>
            <TableCaption color="teal" placement="top" fontSize={"lg"}>
              {t("Reminders for all contracts")}
            </TableCaption>
            <Thead>
              <Tr>
                <Th>{t("Type")}</Th>
                <Th>{t("Reminder time")}</Th>
                <Th>{t("Receivers")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.startDateNotifications.map(
                (reminder, i) => (
                  <DefaultNotificationRow key={i} reminder={reminder} type="StartDateReminder" />
                )
              )}
              {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.endDateNotifications.map(
                (reminder, i) => (
                  <DefaultNotificationRow key={i} reminder={reminder} type="EndDateReminder" />
                )
              )}
              {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.optionExecutionDeadlineNotifications.map(
                (reminder, i) => (
                  <DefaultNotificationRow key={i} reminder={reminder} type="OptionExecutionDeadlineReminder" />
                )
              )}
              {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.optionStartDateNotifications.map(
                (reminder, i) => (
                  <DefaultNotificationRow key={i} reminder={reminder} type="OptionStartDateReminder" />
                )
              )}
              {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.renewalDateNotifications.map(
                (reminder, i) => (
                  <DefaultNotificationRow key={i} reminder={reminder} type="RenewalDateReminder" />
                )
              )}
              {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.terminationDateNotifications.map(
                (reminder, i) => (
                  <DefaultNotificationRow key={i} reminder={reminder} type="TerminationDateReminder" />
                )
              )}
              {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.warrantyExpirationNotifications.map(
                (reminder, i) => (
                  <DefaultNotificationRow key={i} reminder={reminder} type="WarrantyExpirationDateReminder" />
                )
              )}
            </Tbody>
          </Table>
        </Box>
      </Flex>
    </>
  );
};
