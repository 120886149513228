import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { DefaultNotificationSettingsDto } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { BidDeliveryDeadlineSetting } from "./BidDeliveryDeadlineSetting";
import { BidDeliverySettingForm } from "./BidDeliverySettingForm";
import { ContractDateReminderSetting } from "./ContractDateReminderSetting";
import { ContractNotificationSettingForm } from "./ContractDateReminderSettingForm";
import { ContractTaskDueDateReminderSetting } from "./ContractTaskDueDateReminderSetting";
import { ContractTaskNotificationSettingForm } from "./ContractTaskNotificationSettingForm";
import { RecurringContractReminderSetting } from "./RecurringContractReminderSetting";
import { RecurringContractReminderSettingForm } from "./RecurringContractReminderSettingForm";

export const initSettings = (): DefaultNotificationSettingsDto => ({
  contractNotificationSettings: {
    startDateNotifications: [],
    endDateNotifications: [],
    renewalDateNotifications: [],
    warrantyExpirationNotifications: [],
    terminationDateNotifications: [],
    noticePeriodNotifications: [],
    optionExecutionDeadlineNotifications: [],
    optionStartDateNotifications: [],
    noEndDateNotifications: [],
  },
  contractTaskNotificationSettings: {
    dueDateNotifications: [],
  },
  sourcingEventNotificationSettings: {
    bidDeliveryDeadlineNotifications: [],
  },
});

export const NotificationSettings = () => {
  const authState = useLoggedInWithOrgContextState();

  return (
    <Accordion allowMultiple>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight="semibold" fontSize={"lg"}>
              {t("Notifications for contracts")}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb="4">
          <Flex flexDirection={"column"} pb="4">
            <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
              {t("Start date")}
            </Text>
            {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.startDateNotifications.map(
              (setting, i) => (
                <ContractDateReminderSetting key={i} setting={setting} type="StartDateReminder" />
              )
            )}
            <ContractNotificationSettingForm type="StartDateReminder" />
          </Flex>
          <Flex flexDirection={"column"} pb="4">
            <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
              {t("End date")}
            </Text>
            {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.endDateNotifications.map(
              (setting, i) => (
                <ContractDateReminderSetting key={i} setting={setting} type="EndDateReminder" />
              )
            )}
            <ContractNotificationSettingForm type="EndDateReminder" />
          </Flex>
          <Flex flexDirection={"column"} pb="4">
            <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
              {t("Warranty expiration date")}
            </Text>
            {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.warrantyExpirationNotifications.map(
              (setting, i) => (
                <ContractDateReminderSetting key={i} setting={setting} type="WarrantyExpirationDateReminder" />
              )
            )}
            <ContractNotificationSettingForm type="WarrantyExpirationDateReminder" />
          </Flex>
          <Flex flexDirection={"column"} pb="4">
            <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
              {t("Renewal date")}
            </Text>
            {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.renewalDateNotifications.map(
              (setting, i) => (
                <ContractDateReminderSetting key={i} setting={setting} type="RenewalDateReminder" />
              )
            )}
            <ContractNotificationSettingForm type="RenewalDateReminder" />
          </Flex>
          <Flex flexDirection={"column"} pb="4">
            <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
              {t("Termination date")}
            </Text>
            {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.terminationDateNotifications.map(
              (setting, i) => (
                <ContractDateReminderSetting key={i} setting={setting} type="TerminationDateReminder" />
              )
            )}
            <ContractNotificationSettingForm type="TerminationDateReminder" />
          </Flex>
          <Flex flexDirection={"column"} pb="4">
            <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
              {t("Notice period start date")}
            </Text>
            {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.noticePeriodNotifications.map(
              (setting, i) => (
                <ContractDateReminderSetting key={i} setting={setting} type="NoticePeriodReminder" />
              )
            )}
            <ContractNotificationSettingForm type="NoticePeriodReminder" />
          </Flex>
          <Flex flexDirection={"column"} pb="4">
            <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
              {t("Option execution deadline")}
            </Text>
            {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.optionExecutionDeadlineNotifications.map(
              (setting, i) => (
                <ContractDateReminderSetting key={i} setting={setting} type="OptionExecutionDeadlineReminder" />
              )
            )}
            <ContractNotificationSettingForm type="OptionExecutionDeadlineReminder" />
          </Flex>
          <Flex flexDirection={"column"} pb="4">
            <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
              {t("Option start date")}
            </Text>
            {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.optionStartDateNotifications.map(
              (setting, i) => (
                <ContractDateReminderSetting key={i} setting={setting} type="OptionStartDateReminder" />
              )
            )}
            <ContractNotificationSettingForm type="OptionStartDateReminder" />
          </Flex>
          <Flex flexDirection={"column"} pb="4">
            <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
              {t("Contracts with no end date")}
            </Text>
            {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.noEndDateNotifications.map(
              (setting, i) => (
                <RecurringContractReminderSetting key={i} setting={setting} />
              )
            )}
            <RecurringContractReminderSettingForm />
          </Flex>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight="semibold" fontSize={"lg"}>
              {t("Notifications for contract tasks")}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb="4">
          <Flex flexDirection={"column"} pb="4">
            <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
              {t("Due date")}
            </Text>
            {authState.selectedOrg.defaultNotificationSettings?.contractTaskNotificationSettings.dueDateNotifications.map(
              (setting, i) => (
                <ContractTaskDueDateReminderSetting key={i} setting={setting} />
              )
            )}
            <ContractTaskNotificationSettingForm />
          </Flex>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight="semibold" fontSize={"lg"}>
              {t("Notifications for sourcing events")}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb="4">
          <Flex flexDirection={"column"} pb="4">
            <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
              {t("Bid delivery deadline")}
            </Text>
            {authState.selectedOrg.defaultNotificationSettings?.sourcingEventNotificationSettings.bidDeliveryDeadlineNotifications.map(
              (setting, i) => (
                <BidDeliveryDeadlineSetting key={i} setting={setting} />
              )
            )}
            <BidDeliverySettingForm />
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
