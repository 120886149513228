import { Box } from "@chakra-ui/react";
import { t } from "i18next";
import { useCallback } from "react";
import { UppyUploader } from "../../../../common/documents/UppyUploader/UppyUploader";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { loadBasicBidThunk } from "../../../../common/redux/thunks/basic-bid/load-basic-bid-thunk";
import { useBasicBidState } from "../../../sourcing-events/useBasicBidEventState";

export const BasicBidDocumentUploader = () => {
  const dispatch = useAppDispatch();
  const bidState = useBasicBidState();

  const loadBid = useCallback(() => {
    dispatch(loadBasicBidThunk({ bidId: bidState.id }));
  }, [bidState.id, dispatch]);

  return (
    <Box pb="5">
      <UppyUploader
        uploadEntityType={"Bid"}
        entityId={bidState.id}
        documents={bidState.documents.value}
        uploads={bidState.uploads.value}
        reloadEntity={loadBid}
        target={"bidFileTarget"}
        helperText={t("Upload documents relevant for your bid")}
      />
    </Box>
  );
};
