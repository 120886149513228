import { Grid, GridItem } from "@chakra-ui/react";
import { OrgPublicProfileDto } from "../../../autogen/bff-api";
import { requireBooleanEnvVar } from "../../../config";
import { AboutUs } from "./content-items/AboutUs";
import { Certificates } from "./content-items/Certificates";
import { Details } from "./content-items/Details";
import { EsgScoring } from "./content-items/EsgScorecard";
import { LinkedEntries } from "./content-items/LinkedEntries";
import { Location } from "./content-items/Location";
import { Questionnaires } from "./content-items/Questionnaires";
import { Rating } from "./content-items/Rating";
import { Specialties } from "./content-items/Specialties";

interface Props {
  organization: OrgPublicProfileDto;
}

export const Content = ({ organization }: Props) => {
  const showDummyData = requireBooleanEnvVar("VITE_ENABLE_DUMMY_COMPANY_PAGE");
  return (
    <Grid
      gridTemplateColumns={"3fr 5fr"}
      columnGap={6}
      rowGap={2}
      templateAreas={`
            "sidebar products"
            `}
    >
      <GridItem area="sidebar">
        <Details organization={organization} />
        <br />
        {organization.linkedEntries.length > 0 && (
          <>
            <LinkedEntries entries={organization.linkedEntries} />
            <br />
          </>
        )}
        {showDummyData && <Certificates />}
        <br />
        {showDummyData && <Questionnaires />}
        <br />
        {showDummyData && <Rating />}
      </GridItem>
      <GridItem area="products" pb="25px">
        <AboutUs organization={organization} />
        <br />
        <Specialties organization={organization} />
        <br />
        {showDummyData && <EsgScoring />}
        <br />
        {showDummyData && <Location />}
      </GridItem>
    </Grid>
  );
};
