import {
    Button,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Icon,
    useToast,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaSave } from 'react-icons/fa'
import { useApiError } from '../../../common/errors/useApiError'
import { useLoggedInWithOrgContextState } from '../../../common/auth/useLoggedInWithOrgContextState'
import { useUpdateMeMutation } from '../../../autogen/bff-api'
import { useAppDispatch } from '../../../common/redux/hooks'
import { updateMe } from '../../../common/redux/reducers/authStateReducer'
import { PhoneNumberInput } from './PhoneNumberInput'

interface FormValues {
    phoneNumber: PhoneNumberInput | null
}

export const ChangePhoneNumber: React.FC = () => {
    const { me } = useLoggedInWithOrgContextState()
    const [editMe, { isLoading }] = useUpdateMeMutation()
    const dispatch = useAppDispatch()
    const [isRefreshing, setIsRefreshing] = useState(false)
    const { t } = useTranslation()
    const displayer = useApiError()

    const toast = useToast()
    const {
        formState: { errors },
        watch,
        handleSubmit,
        control,
    } = useForm<FormValues>({
        defaultValues: {
            phoneNumber: me.phoneNumber
                ? {
                      country: {
                          name: me.phoneNumber.callingCode.countryName,
                          alpha3Code:
                              me.phoneNumber.callingCode.countryAlpha3Code,
                          callingCode: me.phoneNumber.callingCode.value,
                      },
                      number: me.phoneNumber.number,
                  }
                : null,
        },
    })

    const newValue = watch('phoneNumber')

    const onSubmit = async (values: FormValues) => {
        setIsRefreshing(true)

        if (values.phoneNumber && values.phoneNumber.country) {
            const response = await editMe({
                editMeRequest: {
                    phoneNumber: {
                        value: {
                            callingCode: {
                                countryName: values.phoneNumber.country.name,
                                countryAlpha3Code:
                                    values.phoneNumber.country.alpha3Code,
                                value: values.phoneNumber.country.callingCode,
                            },
                            number: values.phoneNumber.number,
                        },
                    },
                },
            })
            if ('data' in response) {
                dispatch(updateMe(response.data))
            } else {
                displayer.trigger(response.error)
            }
        } else {
            const response = await editMe({
                editMeRequest: {
                    phoneNumber: {
                        value: undefined,
                    },
                },
            })
            if ('data' in response) {
                dispatch(updateMe(response.data))
            } else {
                displayer.trigger(response.error)
            }
        }
        setIsRefreshing(false)

        toast({ title: t('Phone number has been updated!'), status: 'success' })
    }

    const valuesAreEqual = (): boolean => {
        if (me.phoneNumber === null) {
            return newValue === null
        } else {
            if (newValue === null) {
                return false
            }
            return (
                me.phoneNumber?.callingCode.countryAlpha3Code ===
                    newValue.country?.alpha3Code &&
                me.phoneNumber?.callingCode.value ===
                    newValue.country?.callingCode &&
                me.phoneNumber?.number === newValue.number
            )
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                    mt="15px"
                    isInvalid={errors.phoneNumber !== undefined}
                >
                    <FormLabel htmlFor={'phoneNumber'}>
                        {t('Phone number')}
                    </FormLabel>
                    <Controller
                        name="phoneNumber"
                        control={control}
                        rules={{
                            validate: (e) => {
                                if (
                                    e?.country?.alpha3Code &&
                                    e.country.callingCode &&
                                    e.number.length > 2
                                ) {
                                    return true
                                } else if (e === null) {
                                    return true
                                }
                                return false
                            },
                        }}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <PhoneNumberInput
                                    onChange={onChange}
                                    onClear={() => {
                                        onSubmit({ phoneNumber: null })
                                    }}
                                    value={value}
                                />
                            )
                        }}
                    />
                    <FormErrorMessage>
                        {errors.phoneNumber &&
                            t(
                                'Please provide a valid country code and phone number'
                            )}
                    </FormErrorMessage>
                    <FormHelperText>
                        {t('Please provide your phone number')}
                    </FormHelperText>
                </FormControl>
                {!valuesAreEqual() && (
                    <Button
                        mt="10px"
                        leftIcon={
                            <Icon as={FaSave} w="15px" h="15px" mt="-2px" />
                        }
                        variant={'solid'}
                        colorScheme="teal"
                        size={'sm'}
                        isLoading={isLoading || isRefreshing}
                        type="submit"
                    >
                        {t('Save change')}
                    </Button>
                )}
            </form>
        </>
    )
}
