import { AwardedFieldsDto, BseDto, PublishedFieldsDto } from "../../autogen/bff-api";

export const requirePublishedOrAwardedState = (bse: BseDto): PublishedFieldsDto | AwardedFieldsDto => {
  switch (bse.status) {
    case "Awarded": {
      const fields = bse.awardedFields;
      if (!fields) {
        throw Error("Status is awarded, but no awarded fields found.");
      }
      return fields;
    }
    case "Draft":
      throw Error("cannot require deadline for drafts");
    case "Completed":
    case "DeadlineHasPassed":
    case "Published": {
      const fields = bse.publishedFields;
      if (!fields) {
        throw Error("No published fields");
      }
      return fields;
    }
  }
};
