import { Button, Flex, Icon, Input, Spacer, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";
import { DataFieldTypeTag } from "./DataFieldTypeTag";
import { SelectedDataField } from "./SelectedDataField";

const order = ["Date", "Financial", "Commercial", "TermsAndConditions", "Other"];

export interface ContractFieldOption {
  name: string;
  type: string;
  sectionId?: string;
  sectionName?: string;
  fieldId?: string;
  options?: string[];
}

interface Props {
  options: ContractFieldOption[];
  values: ContractFieldOption[];
  onChange: (value: ContractFieldOption[]) => void;
  showAddSelectedButton?: boolean;
  autoFocus?: boolean;
}

export const DataFieldSelector = ({ options, values, onChange, showAddSelectedButton, autoFocus }: Props) => {
  const [showOptions, setShowOptions] = useState(false);
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>("");
  const {
    formState: { isSubmitting },
  } = useFormContext();

  const modifiedOptions = options
    .sort((a, b) => (order.indexOf(a.type) < order.indexOf(b.type) ? -1 : 1))
    .map((field) => ({
      fieldId: field.fieldId,
      name: field.name,
      translatedName: t(getDataFieldTranslationKey(field.name)),
      type: field.type,
      sectionName: field.sectionName,
      sectionId: field.sectionId,
      options: field.options,
    }));
  const modifiedOptionNames = modifiedOptions.map((e) => e.name);

  const filteredOptions = modifiedOptions.filter((e) =>
    e.translatedName.toLowerCase().includes(searchText?.toLowerCase())
  );

  const addOrRemove = (newValue: ContractFieldOption) => {
    const predicate = (v: ContractFieldOption) =>
      v.name === newValue.name && v.sectionId === newValue.sectionId && v.fieldId === newValue.fieldId;
    if (values.find((v) => predicate(v))) {
      onChange(values.filter((v) => !predicate(v)));
    } else onChange([...values, newValue]);
  };

  return (
    <>
      <Flex flexWrap={"wrap"}>
        {values
          .sort((a, b) => (modifiedOptionNames.indexOf(a.name) < modifiedOptionNames.indexOf(b.name) ? -1 : 1))
          .map((e) => (
            <SelectedDataField
              key={e.name}
              field={e}
              onRemove={() => {
                onChange(values.filter((f) => f.name !== e.name));
              }}
            />
          ))}
      </Flex>
      <Flex flexDirection={"column"} w="100%" mt="10px">
        <Flex width="full">
          <Input
            autoFocus={autoFocus ?? false}
            size="md"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onFocus={() => {
              setShowOptions(true);
            }}
            onBlur={() => {
              if (!autoFocus) setShowOptions(false);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter" && filteredOptions.length === 1) {
                addOrRemove(filteredOptions[0]);
                setShowOptions(false);
              }
            }}
          />
          {showAddSelectedButton && (
            <Button
              ml="5px"
              variant={"solid"}
              colorScheme="blue"
              pl="30px"
              pr="30px"
              isLoading={isSubmitting}
              type="submit"
            >
              {t("Add selected")}
            </Button>
          )}
        </Flex>
        {showOptions && (
          <Flex
            width={"full"}
            mt="45px"
            zIndex={1}
            bg="smBackground"
            position={"absolute"}
            flexDirection={"column"}
            borderRadius="10px"
            border="1px solid"
            borderColor="smBorder"
            boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
            maxHeight={"60vh"}
            overflow={"auto"}
          >
            {filteredOptions.map((option) => {
              const isChecked = values?.map((e) => e.name).indexOf(option.name) !== -1;
              return (
                <Flex
                  key={`${option.name}${option.fieldId ?? ""}${option.sectionId ?? ""}`}
                  role="button"
                  borderTop={"1px solid"}
                  borderColor="smBorder"
                  padding={"10px"}
                  _hover={{ cursor: "hover", backgroundColor: "smBackgroundSecondary" }}
                  w="100%"
                  onMouseDown={() => {
                    addOrRemove(option);
                  }}
                >
                  <Flex>
                    <Text fontSize="md" fontWeight={isChecked ? "bold" : "normal"}>
                      {t(getDataFieldTranslationKey(option.name))}
                    </Text>
                  </Flex>
                  <Flex justifyContent="center" alignItems="center" ml="7px">
                    {isChecked && <Icon as={FaCheck} w="15px" h="15px" mt="-3px" />}
                  </Flex>
                  <Spacer />
                  <Flex>
                    <DataFieldTypeTag size="sm" type={option.type} sectionName={option.sectionName} />
                  </Flex>
                </Flex>
              );
            })}
            {filteredOptions.length === 0 && (
              <Flex padding={"20px"}>
                <Text fontStyle={"italic"}>{t("No matching data fields!")}</Text>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </>
  );
};

export const getDataFieldTranslationKey = (name: string): string => {
  switch (name) {
    case "AwardDate":
      return "Award date";
    case "RenewalDate":
      return "Renewal date";
    case "CounterpartyContactPerson":
      return "Counterparty contact person";
    case "DeliveryTerms":
      return "Delivery terms";
    case "EstimatedValue":
      return "Estimated total value";
    case "EndDate":
      return "End date";
    case "ExternalLink":
      return "External system link";
    case "InternalReferenceNumber":
      return "Internal reference number";
    case "LinkedContracts":
      return "Linked contracts";
    case "OriginSourcingEvent":
      return "Origin sourcing event";
    case "PaymentTermsInDays":
      return "Payment terms in days";
    case "PricePerMonth":
      return "Price per month";
    case "PricePerYear":
      return "Price per year";
    case "Priority":
      return "Priority";
    case "PurchasingPolicy":
      return "Purchase policy";
    case "Risk":
      return "Risk";
    case "StartDate":
      return "Start date";
    case "TotalLiability":
      return "Total liability";
    case "TotalPrice":
      return "Total price";
    case "WarrantyExpirationDate":
      return "Warranty expiration date";
    case "TerminationDate":
      return "Termination date";
    case "IfrsValues":
      return "IFRS values";
    case "InternalParties":
      return "Internal parties";
    case "SupplierInfo":
      return "Supplier info";
    case "SupplierCategory":
      return "Supplier category";
    case "NoticePeriod":
      return "Notice period";
    case "Options":
      return "Contract options";
    default:
      return name;
  }
};
