import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useListChangesForContractTaskQuery } from '../../../../../../autogen/bff-api'
import { useLoggedInWithOrgContextState } from '../../../../../../common/auth/useLoggedInWithOrgContextState'
import { Change } from './Change'

interface Props {
    taskId: string
}

export const ChangeList: React.FC<Props> = ({ taskId }) => {
    const authState = useLoggedInWithOrgContextState()
    const { data } = useListChangesForContractTaskQuery({
        organizationId: authState.selectedOrg.id,
        taskId: taskId,
    })
    const { t } = useTranslation()
    if (!data) {
        return <div>{t('Loading...')}</div>
    }
    return (
        <Flex flexDirection={'column'}>
            {data.changes.length === 0 && (
                <Text fontStyle={'italic'}>{t('No changes made')}</Text>
            )}
            {data.changes.length > 0 &&
                data.changes.map((e) => <Change key={e.id} change={e} />)}
        </Flex>
    )
}
