import { Button, Flex, Icon, Spacer, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { BseAnnouncementDto } from "../../../../../autogen/bff-api";
import { displayDateWithTime } from "../../../../../common/formatting/displayDateWithTime";
import { displayWrappedWithEllipsis } from "../../../../../common/formatting/displayWrappedWithEllipsis";
import { Explanation } from "../../../../../common/support/Explanation/Explanation";
import { useQueryParams } from "../../../../../common/useQueryParams";
import { AnnouncementModal } from "./AnnouncementModal";
import { AnnouncementData, NewAnnouncementModal } from "./NewAnnouncementModal";
interface Props {
  canCreateAnnouncement: boolean;
  createAnnouncement?: (values: { title: string; content: string }) => Promise<boolean>;
  initialAnnouncementValues: AnnouncementData | null;
  announcements: BseAnnouncementDto[];
}

export const Announcements = ({
  canCreateAnnouncement,
  announcements,
  initialAnnouncementValues,
  createAnnouncement,
}: Props) => {
  const [announcementId, setAnnouncementId] = useState<string>();
  const [newAnnouncement, setNewAnnouncement] = useState(false);
  const params = useQueryParams();
  const announcementIdParam = params.get("announcement");
  const createAnnouncementParam = params.get("createannouncement");
  const matchingAnnouncements = announcements.filter((e) => e.id === announcementId);
  const { t } = useTranslation();

  useEffect(() => {
    if (announcementIdParam) setAnnouncementId(announcementIdParam);
  }, [announcementIdParam]);

  useEffect(() => {
    if (createAnnouncementParam) {
      setNewAnnouncement(true);
      params.clearSelectedAnnouncement();
    }
  }, [createAnnouncementParam, params]);

  return (
    <>
      {matchingAnnouncements.length > 0 && (
        <AnnouncementModal
          announcement={matchingAnnouncements[0]}
          isOpen={true}
          close={() => {
            setAnnouncementId(undefined);
            params.clearSelectedAnnouncement();
          }}
        />
      )}
      {createAnnouncement && newAnnouncement && (
        <NewAnnouncementModal
          isOpen={newAnnouncement}
          close={() => setNewAnnouncement(false)}
          create={createAnnouncement}
          initialValues={initialAnnouncementValues}
        />
      )}
      <Flex flexDirection={"column"}>
        {createAnnouncement && (
          <Flex mb="15px">
            <Spacer />
            <Explanation
              enabled={!canCreateAnnouncement}
              text={t("Only admins and creators can create new announcements")}
            >
              <Button
                size={"sm"}
                leftIcon={<Icon as={FaPlus} w="15px" h="15px" />}
                colorScheme={"blue"}
                isDisabled={!canCreateAnnouncement}
                variant="outline"
                onClick={() => setNewAnnouncement(true)}
              >
                {t("New announcement")}
              </Button>
            </Explanation>
          </Flex>
        )}
        <Flex
          flexDirection={"column"}
          backgroundColor="smBackground"
          border="1px solid"
          borderColor="smBorder"
          rounded="md"
        >
          <Table variant="simple" mb="2.5">
            <Thead>
              <Tr>
                <Th>{t("Title")}</Th>
                <Th>{t("Published by")}</Th>
                <Th>{t("Published at")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {announcements.map((e) => {
                return (
                  <Tr
                    key={e.id}
                    _hover={{ backgroundColor: "smBackgroundSecondary" }}
                    cursor={"pointer"}
                    onClick={() => {
                      setAnnouncementId(e.id);
                      params.setSelectedAnnouncement(e.id);
                    }}
                  >
                    <Td>
                      <Text fontSize={"md"}>{displayWrappedWithEllipsis(e.title, 20)}</Text>
                    </Td>
                    <Td>
                      <Text fontSize={"md"}>
                        {e.createdByPerson.firstName} {e.createdByPerson.lastName}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize={"md"}>{displayDateWithTime(e.createdAt, t("at"))}</Text>
                    </Td>
                  </Tr>
                );
              })}
              {announcements.length === 0 && (
                <Tr>
                  <Td colSpan={5} width={"100%"} textAlign="center">
                    {t("No announcements have been published")}
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Flex>
      </Flex>
    </>
  );
};
