import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ContractDto, IfrsAdjustmentType } from "../../../../../../../autogen/bff-api";
import { AddCurrencyAmountAdjustment } from "./AddCurrencyAmountAdjustment";
import { AddPercentageAdjustment } from "./AddPercentageAdjustment";
import { AdjustmentsTable } from "./AdjustmentsTable";

interface Props {
  contract: ContractDto;
  onClose: () => void;
}

export const IfrsAdjustmentsModal = ({ contract, onClose }: Props) => {
  const { t } = useTranslation();
  const [selectedAdjustmentType, setSelectedAdjustmentType] = useState<IfrsAdjustmentType>();

  return (
    <>
      <Modal size={"4xl"} isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent overflow={"auto"} height={"80%"}>
          <ModalHeader>{t("Adjustments")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AdjustmentsTable contract={contract} />
          </ModalBody>
          <ModalHeader>{t("New adjustment")}</ModalHeader>
          <ModalBody pb="25px">
            <Button
              variant={selectedAdjustmentType === "Monetary" ? "solid" : "outline"}
              colorScheme="blue"
              onClick={() => setSelectedAdjustmentType("Monetary")}
            >
              {t("Monetary value")}
            </Button>
            <Button
              variant={selectedAdjustmentType === "Percentage" ? "solid" : "outline"}
              colorScheme="blue"
              ml="5px"
              onClick={() => setSelectedAdjustmentType("Percentage")}
            >
              {t("Percentage")}
            </Button>
            {selectedAdjustmentType === "Percentage" && (
              <AddPercentageAdjustment contract={contract} onCancel={() => setSelectedAdjustmentType(undefined)} />
            )}
            {selectedAdjustmentType === "Monetary" && (
              <AddCurrencyAmountAdjustment contract={contract} onCancel={() => setSelectedAdjustmentType(undefined)} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
