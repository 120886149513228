import { createAsyncThunk } from "@reduxjs/toolkit";
import { EmailInviteContextDto } from "../../../../autogen/bff-api";
import { authService } from "../../../../config";
import { clearOrganizationId } from "../../../local-storage/selected-organization";
import { AuthState } from "../../reducers/authStateReducer";
import { getLoggedInState } from "./get-initial-auth-state-thunk";

export type LoginError = "UserDoesNotExist" | "IncorrectEmailOrPassword" | "UnknownError" | "NoOrgContextForAzureUser";

export const loginThunk = createAsyncThunk(
  "authState/login",
  async (
    props: {
      email: string;
      password: string;
      invitationContext: EmailInviteContextDto | null;
    },
    { dispatch }
  ): Promise<AuthState> => {
    clearOrganizationId();
    const result = await authService.login({
      email: props.email,
      password: props.password,
      refresh: true,
    });

    switch (result) {
      case "Success": {
        return await getLoggedInState({
          invitationContext: props.invitationContext,
          dispatch: dispatch,
          showUnknownError: true,
        });
      }
      case "UserDoesNotExist": {
        return {
          type: "LoggedOut",
          isLoggingIn: false,
          loginError: "UserDoesNotExist",
          isSigningUp: false,
          signupError: null,
        };
      }
      case "NotAuthorized": {
        return {
          type: "LoggedOut",
          isLoggingIn: false,
          loginError: "IncorrectEmailOrPassword",
          isSigningUp: false,
          signupError: null,
        };
      }
      case "Failure": {
        return {
          type: "LoggedOut",
          isLoggingIn: false,
          loginError: "UnknownError",
          isSigningUp: false,
          signupError: null,
        };
      }
    }
  }
);
