import { Flex, Skeleton, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  ContractStatusDto,
  ContractTemplateDto,
  FilterOptionDto,
  ProjectDto,
  useListContractFilterOptionsQuery,
} from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useSub } from "../../../../common/subscription/useSub";
import { useTemplateMeta } from "../../settings/ContractTemplates/useTemplateMeta";
import { MultiSelectFilter, MultiSelectOption } from "./MultiSelectFilter";

interface Props {
  selectedCounterparties: string[];
  onCounterpartsChange: (values: MultiSelectOption[]) => void;
  selectedOwners: string[];
  onOwnersChange: (values: MultiSelectOption[]) => void;
  selectedStatuses?: string[];
  onStatusesChange?: (values: MultiSelectOption[]) => void;
  selectedTypes: string[];
  onTypesChange: (values: MultiSelectOption[]) => void;
  templates: ContractTemplateDto[];
  selectedInternalParties: string[];
  onInternalPartiesChange: (value: MultiSelectOption[]) => void;
  selectedProjects: string[];
  onProjectsChange: (value: MultiSelectOption[]) => void;
}

const statuses: ContractStatusDto[] = ["Awarded", "Active", "Expired", "Terminated"];

export const ContractFilters = ({
  selectedCounterparties,
  onCounterpartsChange,
  selectedOwners,
  onOwnersChange,
  selectedStatuses,
  onStatusesChange,
  selectedTypes,
  onTypesChange,
  templates,
  selectedInternalParties,
  onInternalPartiesChange,
  selectedProjects,
  onProjectsChange,
}: Props) => {
  const { t } = useTranslation();

  const authState = useLoggedInWithOrgContextState();
  const sub = useSub();
  const { data: options, isLoading } = useListContractFilterOptionsQuery({ orgId: authState.selectedOrg.id });

  const templateMeta = useTemplateMeta();

  if (isLoading)
    return (
      <Stack width="full">
        <Skeleton height="5" width="full"></Skeleton>
        <Skeleton height="5" width="full"></Skeleton>
      </Stack>
    );

  return (
    <Flex width="full" flexWrap="wrap" columnGap="2" rowGap="1">
      {sub.hasProjectAccess ? (
        <Flex flex={1} minWidth="40">
          <MultiSelectFilter
            placeholder={t("Project")}
            options={
              options?.projects.map((o) => ({
                label: `${o.externalId ? `${o.externalId} - ` : ""}${o.name}`,
                value: o.id,
              })) ?? []
            }
            value={selectedProjects
              .map((id) => options?.projects.find((p) => p.id === id))
              .filter((p): p is ProjectDto => !!p)
              .map((p: ProjectDto) => ({
                value: p.id,
                label: `${p.externalId ? `${p.externalId} - ` : ""}${p.name}`,
              }))}
            onChange={(values) => onProjectsChange(values)}
          />
        </Flex>
      ) : null}
      <Flex flex={1} minWidth="40">
        <MultiSelectFilter
          placeholder={t("Counterparty")}
          options={options?.counterparties.map((o) => ({ label: o.name, value: o.id })) ?? []}
          value={selectedCounterparties
            .map((id) => options?.counterparties.find((c) => c.id === id))
            .filter((o): o is FilterOptionDto => !!o)
            .map((o: FilterOptionDto) => ({
              value: o.id,
              label: o.name,
            }))}
          onChange={(values) => onCounterpartsChange(values)}
        />
      </Flex>
      <Flex flex={1} minWidth="40">
        <MultiSelectFilter
          placeholder={t("Internal party")}
          options={options?.internalParties.map((o) => ({ label: o.name, value: o.id })) ?? []}
          value={selectedInternalParties
            .map((id) => options?.internalParties.find((c) => c.id === id))
            .filter((o): o is FilterOptionDto => !!o)
            .map((o: FilterOptionDto) => ({
              value: o.id,
              label: o.name,
            }))}
          onChange={onInternalPartiesChange}
        />
      </Flex>
      <Flex flex={1} minWidth="40">
        <MultiSelectFilter
          placeholder={t("Responsible")}
          options={options?.persons.map((o) => ({ label: o.name, value: o.id })) ?? []}
          value={selectedOwners
            .map((id) => options?.persons.find((p) => p.id === id))
            .filter((p): p is FilterOptionDto => !!p)
            .map((p) => ({
              value: p.id,
              label: p.name,
            }))}
          onChange={(values) => onOwnersChange(values)}
        />
      </Flex>
      {selectedStatuses && onStatusesChange && (
        <Flex flex={1} minWidth="40">
          <MultiSelectFilter
            placeholder={t("Status")}
            options={statuses.map((status) => ({
              label: t(status),
              value: status,
            }))}
            value={selectedStatuses.map((status) => ({
              label: t(status),
              value: status,
            }))}
            onChange={(values) => onStatusesChange(values)}
          />
        </Flex>
      )}
      <Flex flex={1} minWidth="40">
        <MultiSelectFilter
          placeholder={t("Type")}
          options={templates.map((template) => ({
            label: templateMeta.getTemplateName(template),
            value: template.id,
          }))}
          value={selectedTypes
            .map((id) => templates.find((t) => t.id === id))
            .filter((t): t is ContractTemplateDto => !!t)
            .map((p) => ({
              value: p.id,
              label: p.name ?? `${t("No template name")}...`,
            }))}
          onChange={(values) => onTypesChange(values)}
        />
      </Flex>
    </Flex>
  );
};
