import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useProductGroupsAsSupplier } from "../../../../common/hooks/useProductGroupsAsSupplier";
import { ProductGroupForm } from "./ProductGroupForm";

export const Products = ({ nextStep, previousStep }: { nextStep: () => void; previousStep: () => void }) => {
  const { productGroups, isLoading, error } = useProductGroupsAsSupplier();

  if (error) return <div>{t("Sorry, something went wrong")}...</div>;

  return (
    <Box height={"full"} maxWidth="full" py="4">
      {isLoading && !productGroups?.length ? (
        <Flex
          justify={"center"}
          align={"center"}
          height={"64"}
          backgroundColor="smBackgroundSecondary"
          rounded="lg"
          mb="8"
          mt="8"
        >
          <Spinner />
        </Flex>
      ) : null}
      {!isLoading && productGroups?.length === 0 ? (
        <Flex
          justify={"center"}
          align={"center"}
          height={"64"}
          backgroundColor="smBackgroundSecondary"
          rounded="lg"
          mb="8"
          mt="8"
        >
          <Text fontStyle={"italic"} color={"gray.500"}>
            {t("No products")}.
          </Text>
        </Flex>
      ) : null}
      {productGroups?.map((group) => (
        <Box key={group.id} maxWidth="full" pb="5">
          <ProductGroupForm group={group} />
        </Box>
      ))}
      <Flex pt="8">
        <Button variant={"outline"} mr="5px" w="50%" colorScheme={"teal"} onClick={previousStep}>
          {t("Previous")}
        </Button>
        <Button
          variant={"solid"}
          w="50%"
          colorScheme={"teal"}
          onClick={() => {
            nextStep();
          }}
        >
          {t("Next")}
        </Button>
      </Flex>
    </Box>
  );
};
