import { Tag } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BseStatusDto } from "../../autogen/bff-api";

interface Props {
  status: BseStatusDto;
  size?: "sm" | "md" | "lg";
}

export const EventStatusTag = ({ status, size }: Props) => {
  const { t } = useTranslation();
  const getEventTagMeta = (
    status: BseStatusDto
  ): {
    color: string;
    text: string;
  } => {
    switch (status) {
      case "Awarded":
        return {
          color: "purple",
          text: t("Awarded!"),
        };
      case "Completed":
        return {
          color: "purple",
          text: t("Evaluation Complete"),
        };
      case "Draft":
        return {
          color: "orange",
          text: t("Draft"),
        };
      case "Published":
        return {
          color: "green",
          text: t("Live"),
        };
      case "DeadlineHasPassed":
        return {
          color: "orange",
          text: t("Deadline passed"),
        };
    }
  };

  const tagMeta = getEventTagMeta(status);

  return (
    <Tag
      variant="outline"
      colorScheme={tagMeta.color}
      size={size}
      textAlign="center"
      paddingTop={"2px"}
      paddingBottom={"2px"}
    >
      {tagMeta.text}
    </Tag>
  );
};
