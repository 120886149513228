import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { OrganizationSize, useUpdateOrganizationMutation } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../common/errors/useApiError";
import { displayOrganizationSize } from "../../../../common/formatting/displayOrganizationSize";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { OrgDto, updateSelectedOrg } from "../../../../common/redux/reducers/authStateReducer";

interface FormValues {
  size: OrganizationSize | null;
}

interface Props {
  currentValue: OrganizationSize | null;
}

export const ChangeSize = ({ currentValue }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const [editOrg, { isLoading }] = useUpdateOrganizationMutation();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { t } = useTranslation();
  const displayer = useApiError();
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      size: currentValue,
    },
  });

  let newValue: string | OrganizationSize | null = watch("size");
  if (newValue === "") {
    newValue = null;
  }

  const onSubmit = async (values: FormValues) => {
    setIsRefreshing(true);

    const isNull: boolean = values.size !== null && values.size.toString() === "";

    const result = await editOrg({
      orgId: authState.selectedOrg.id,
      editOrganizationRequest: {
        size: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          size: isNull ? undefined : values.size!,
        },
      },
    });
    if ("data" in result) {
      dispatch(updateSelectedOrg(result.data as OrgDto));
    } else {
      displayer.trigger(result.error);
    }
    setIsRefreshing(false);
    toast({ title: t("Company size has been updated!"), status: "success" });
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="size" mt="20px" isInvalid={errors.size != null}>
          <FormLabel>{t("Company size")}</FormLabel>
          <Select placeholder={t("Select a size for your company") ?? ""} {...register("size")}>
            {organizationSizeToList().map((e) => (
              <option key={e} value={e}>
                {displayOrganizationSize(e)}
              </option>
            ))}
          </Select>
          <FormHelperText>{t("Please provide a size for your company")}.</FormHelperText>
          <FormErrorMessage>{errors.size && errors.size.message}</FormErrorMessage>
        </FormControl>
        {currentValue !== newValue && (
          <Button
            mt="10px"
            leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
            variant={"solid"}
            colorScheme="teal"
            size={"sm"}
            type="submit"
            isLoading={isLoading || isRefreshing}
          >
            {t("Save change")}
          </Button>
        )}
      </form>
    </>
  );
};

const organizationSizeToList = (): OrganizationSize[] => {
  return [
    "ONE_TO_TEN",
    "TEN_TO_FIFTY",
    "FIFTY_TO_TWO_HUNDRED_AND_FIFTY",
    "TWO_HUNDRED_AND_FIFTY_TO_THOUSAND",
    "THOUSAND_TO_TEN_THOUSAND",
    "TEN_THOUSAND_PLUS",
  ];
};
