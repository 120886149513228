export const getImpersonatedPersonId = (): string | null => {
    return localStorage.getItem('personId')
}

export const setImpersonatedPersonId = (id: string) => {
    localStorage.setItem('personId', id)
}

export const clearImpersonatedPersonId = () => {
    localStorage.removeItem('personId')
}
