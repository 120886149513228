import { Button, Flex, Select, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { ContractTaskDto, ContractTaskStatus, useUpdateContractTaskMutation } from "../../../../../../autogen/bff-api";

interface Props {
  task: ContractTaskDto;
  onEditComleted: (task: ContractTaskDto) => void;
  onCancel: () => void;
}

export const EditStatus = ({ task, onEditComleted, onCancel }: Props) => {
  const [status, setStatus] = useState(task.status);
  const options: ContractTaskStatus[] = ["Open", "Completed"];
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [update] = useUpdateContractTaskMutation();
  return (
    <Flex flexDirection={"column"}>
      <Select
        borderRadius={"7px"}
        size="sm"
        value={status}
        onChange={(e) => {
          setStatus(e.target.value as ContractTaskStatus);
        }}
      >
        {options.map((e) => (
          <option key={e} value={e}>
            {e}
          </option>
        ))}
      </Select>
      <Flex mt="10px">
        <Button
          id="save-status-button"
          variant={"solid"}
          colorScheme="teal"
          size="xs"
          isLoading={isLoading}
          onClick={async () => {
            setIsLoading(true);
            const result = await update({
              taskId: task.id,
              updateTaskRequest: {
                status: status,
              },
            });
            setIsLoading(false);

            if ("data" in result) {
              onEditComleted(result.data);
            } else {
              toast({
                title: t("Could not edit status"),
                status: "error",
              });
            }
          }}
        >
          {t("Save")}
        </Button>
        <Button variant={"solid"} colorScheme="gray" size="xs" ml="5px" onClick={onCancel}>
          {t("Cancel")}
        </Button>
      </Flex>
    </Flex>
  );
};
