import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EmailInviteContextDto, OrgPitchDto, bffApi } from "../../../../autogen/bff-api";
import { useLoggedOutState } from "../../../../common/auth/useLoggedOutState";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { signupAsOrgThunk } from "../../../../common/redux/thunks/auth/signup-as-org-thunk";
import { useBaseData } from "../../../../common/useBaseData";

interface FormValues {
  firstName: string;
  lastName: string;
  companyName: string;
  countryId: string;
  organizationNumber: string;
  email: string;
  password: string;
  tos: boolean;
  pp: boolean;
}

interface Props {
  invitationContext: EmailInviteContextDto | null;
}

export const SignupAsOrgForm = ({ invitationContext }: Props) => {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const authState = useLoggedOutState();

  const baseData = useBaseData();

  const methods = useForm<FormValues>();
  const errors = methods.formState.errors;
  const register = methods.register;
  const handleSubmit = methods.handleSubmit;

  const [duplicateOrg, setDuplicateOrg] = useState<OrgPitchDto>();

  const signup = async (values: FormValues) => {
    const duplicateOrg = await dispatch(
      bffApi.endpoints.searchOpenOrgContactInfo.initiate({
        name: values.companyName,
        number: values.organizationNumber?.replace(/\s+/g, ""),
      })
    );
    if (duplicateOrg.data) {
      setDuplicateOrg(duplicateOrg.data.organization);
      return;
    }
    dispatch(
      signupAsOrgThunk({
        loginCredentials: {
          email: values.email,
          password: values.password,
        },
        personCredentials: {
          firstName: values.firstName,
          lastName: values.lastName,
        },
        orgCredentials: {
          companyName: values.companyName,
          countryId: values.countryId,
          organizationNumber: values.organizationNumber?.replace(/\s+/g, ""),
        },
        invitationContext,
      })
    );
  };

  useEffect(() => {
    if (authState.signupError) {
      switch (authState.signupError) {
        case "EmailAlreadyTaken":
          methods.setError("email", {
            message: t("An account already belongs to this email!") ?? "",
          });
          break;
        case "WeakPassword":
          methods.setError("password", {
            message: t("This password has been found in password breaches and you need to use another password") ?? "",
          });
          break;
        case "PasswordBreached":
          methods.setError("password", {
            message: t("This password has been found in password breaches and cannot be used") ?? "",
          });
          break;
        case "SimilarToIdentifier":
          methods.setError("password", {
            message: t("This password is too similar to your email") ?? "",
          });
          break;
        case "UnknownError":
          toast({
            title: t("Uknown error occurred, please try again!"),
            status: "error",
          });
          break;
        default:
          toast.closeAll();
          toast({
            title: t(authState.signupError),
            status: "error",
          });
      }
    } else {
      methods.clearErrors();
    }
  }, [authState.signupError, methods, t, toast]);

  return (
    <>
      <Modal isOpen={!!duplicateOrg} onClose={() => setDuplicateOrg(undefined)} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Organization already registered")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{t("It seems like you're trying to register a company which already exists")}.</Text>
            {duplicateOrg && (
              <Flex py="5">
                <Text>
                  {t("Please get in touch with")} {duplicateOrg.owner.firstName} {duplicateOrg.owner.lastName} (
                  {duplicateOrg.owner.email}) {t("if you should have access to this organization")}.
                </Text>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(signup)}>
          <FormControl isInvalid={errors.firstName !== undefined} mt="5px">
            <FormLabel htmlFor={"firstName"}>{t("First name")}</FormLabel>
            <Input
              id={"firstName"}
              type="text"
              {...register("firstName", {
                required: t("Please provide your first name") ?? "",
                minLength: {
                  value: 2,
                  message: t("Must be at least 2 characters long"),
                },
              })}
            />
            <FormErrorMessage>{errors.firstName && errors.firstName.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.lastName !== undefined} mt="5px">
            <FormLabel htmlFor={"lastName"}>{t("Last name")}</FormLabel>
            <Input
              id={"lastName"}
              type="text"
              {...register("lastName", {
                required: t("Please provide your last name") ?? "",
                minLength: {
                  value: 2,
                  message: t("Must be at least 2 characters long"),
                },
              })}
            />
            <FormErrorMessage>{errors.lastName && errors.lastName.message}</FormErrorMessage>
          </FormControl>
          <FormControl mt="5px" isInvalid={errors.companyName !== undefined}>
            <FormLabel htmlFor={"companyName"}>{t("Company name")}</FormLabel>
            <Input
              id={"companyName"}
              type="text"
              {...register("companyName", {
                required: t("Please provide the name of your company") ?? "",
                minLength: {
                  value: 2,
                  message: t("Must be at least 2 characters long"),
                },
              })}
            />
            <FormErrorMessage>{errors.companyName && errors.companyName.message}</FormErrorMessage>
          </FormControl>
          <FormControl id="country" mt="5px" isInvalid={errors.countryId != null}>
            <FormLabel>{t("Company location")}</FormLabel>
            <Select
              placeholder={t("Select a country for your company") ?? ""}
              {...register("countryId", {
                required: {
                  value: true,
                  message: t("Select a country for your company"),
                },
              })}
            >
              {baseData.countries.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.countryId && errors.countryId.message}</FormErrorMessage>
          </FormControl>
          <FormControl mt="5px" isInvalid={errors.organizationNumber !== undefined}>
            <FormLabel htmlFor={"organizationNumber"}>{t("Organization number")}</FormLabel>
            <Input
              id={"organizationNumber"}
              type="text"
              {...register("organizationNumber", {
                required: t("Please provide an organization number") ?? "",
                minLength:
                  // ID for Norway
                  methods.watch("countryId") === "66fae8b8-7b1f-4ee5-81cb-c10f8b2617ea"
                    ? {
                        value: 9,
                        message: t("Must be at least 9 digits"),
                      }
                    : {
                        value: 2,
                        message: t("Must be at least 2 digits"),
                      },
                maxLength:
                  // ID for Norway
                  methods.watch("countryId") === "66fae8b8-7b1f-4ee5-81cb-c10f8b2617ea"
                    ? {
                        value: 9,
                        message: t("Must be at most 9 digits"),
                      }
                    : undefined,
              })}
            />
            <FormErrorMessage>{errors.organizationNumber && errors.organizationNumber.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.email !== undefined} mt="5px">
            <FormLabel htmlFor={"email"}>Email</FormLabel>
            <Input
              id={"email"}
              type="email"
              {...register("email", {
                required: t("Please provide your email") ?? "",
                minLength: {
                  value: 2,
                  message: t("Must be at least 2 characters long"),
                },
              })}
            />
            <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.password !== undefined} mt="5px">
            <FormLabel htmlFor={"password"}>{t("Password")}</FormLabel>
            <Input
              id={"password"}
              type="password"
              {...register("password", {
                required: t("Please provide your desired passord") ?? "",
                minLength: {
                  value: 8,
                  message: t("Your password must be at least 8 characters long"),
                },
              })}
            />
            <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.pp !== undefined} mt="10px">
            <Controller
              name="pp"
              control={methods.control}
              rules={{
                required: {
                  value: true,
                  message: t("Please accept our Privacy Policy before logging in"),
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Checkbox isChecked={value} onChange={onChange}>
                    {t("I accept the")}{" "}
                    <Link href="https://sourcemagnet.com/privacy-policy" color="blue.500" target={"_blank"}>
                      <u>{t("Privacy Policy")}</u>
                    </Link>
                  </Checkbox>
                );
              }}
            />
            <FormErrorMessage>{errors.pp && errors.pp.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.tos !== undefined} mt="10px">
            <Controller
              name="tos"
              control={methods.control}
              rules={{
                required: {
                  value: true,
                  message: t("Please accept our Terms of Service before logging in"),
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Checkbox isChecked={value} onChange={onChange}>
                    {t("I accept the")}{" "}
                    <Link href="https://sourcemagnet.com/avtalevilkar" color="blue.500" target={"_blank"}>
                      <u>{t("Terms of Service")}</u>
                    </Link>
                  </Checkbox>
                );
              }}
            />
            <FormErrorMessage>{errors.tos && errors.tos.message}</FormErrorMessage>
          </FormControl>
          <Flex mt="10px" justifyContent={"end"}>
            <Button type="submit" colorScheme="teal" isLoading={authState.isSigningUp}>
              {t("Proceed")}
            </Button>
          </Flex>
        </form>
      </FormProvider>
    </>
  );
};
