import { Button, Flex, Textarea, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { ContractTaskDto, useUpdateContractTaskMutation } from "../../../../../../autogen/bff-api";

interface Props {
  task: ContractTaskDto;
  onEditComleted: (task: ContractTaskDto) => void;
  onCancel: () => void;
}

export const EditDescription = ({ task, onEditComleted, onCancel }: Props) => {
  const [text, setText] = useState(task.description);
  const [isLoading, setIsLoading] = useState(false);
  const [update] = useUpdateContractTaskMutation();
  const toast = useToast();
  return (
    <Flex flexDirection={"column"} w="100%" mt="4px">
      <Textarea id="edit-description-textarea" w="100%" value={text} onChange={(e) => setText(e.target.value)} />
      <Flex mt="10px">
        <Button
          id="save-description-button"
          variant={"solid"}
          colorScheme="teal"
          size="xs"
          isLoading={isLoading}
          onClick={async () => {
            setIsLoading(true);
            const result = await update({
              taskId: task.id,
              updateTaskRequest: {
                description: text,
              },
            });
            setIsLoading(false);

            if ("data" in result) {
              onEditComleted(result.data);
            } else {
              toast({
                title: t("Could not edit description"),
                status: "error",
              });
            }
          }}
        >
          {t("Save")}
        </Button>
        <Button variant={"solid"} colorScheme="gray" size="xs" ml="5px" onClick={onCancel}>
          {t("Cancel")}
        </Button>
      </Flex>
    </Flex>
  );
};
