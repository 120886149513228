import { useCallback, useContext } from "react";
import { RumComponentContext } from ".";
import { getGlobalObject } from "../../getGlobalObject";

export const useRumError = () => {
  const componentContext = useContext(RumComponentContext);
  const RumGlobal = getGlobalObject<Window>().DD_RUM;

  if (!RumGlobal) {
    console.warn("@datadog/rum-react-integration: Datadog RUM SDK is not initialized.");
    throw Error("Datadog RUM has not been initialized");
  }

  const errorHandler = useCallback(
    (error: unknown, customAttributes: object | undefined) => {
      RumGlobal.addError(error, {
        ...componentContext.customAttributes,
        ...customAttributes,
        react: {
          breadcrumbs: componentContext.componentBreadCrumbs,
          component: componentContext.component,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(customAttributes as any)?.react,
        },
      });
    },
    [RumGlobal, componentContext.component, componentContext.componentBreadCrumbs, componentContext.customAttributes]
  );

  return errorHandler;
};
