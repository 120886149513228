import { Box, Flex, Grid, GridItem, Heading, Icon, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaInbox } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useListSeSupplierViewsQuery } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { useSkeletons } from "../../../common/useSkeletons";
import { urls } from "../../../urls";
import { BidInvitationStatus } from "../../bids/view-all/BidInvitationStatus";
import styles from "./LatestBidInvitations.module.css";

export const LatestBidInvitations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authState = useLoggedInWithOrgContextState();
  const skeletons = useSkeletons();
  const displayer = useApiError();
  const { data, error } = useListSeSupplierViewsQuery({
    orgId: authState.selectedOrg.id,
    status: "Ongoing",
  });

  if (error) {
    return displayer.show(error);
  }

  return (
    <Box
      flexDirection={"column"}
      alignItems="start"
      justifyContent="start"
      borderRadius="lg"
      boxShadow="0 3px 15px rgba(71, 85, 105, 0.16)"
      padding="20px"
      mt="15px"
      bg="smBackground"
    >
      <Grid
        gridTemplateColumns="50px 90px"
        gridTemplateRows="50px"
        gridTemplateAreas={`
                "icon title title"
                "unused1 events events"
                `}
      >
        <GridItem area="icon">
          <Box
            bg="smPrimarySoft"
            h="100%"
            borderRadius={"50%"}
            display="flex"
            alignItems={"center"}
            justifyContent="center"
          >
            <Icon as={FaInbox} w="18px" h="18px" color="smBackground" />
          </Box>
        </GridItem>
        <GridItem area="title" display={"flex"} alignItems="center">
          <Heading as="h2" fontSize="3xl" fontWeight={"semibold"} ml="10px">
            {t("Bid invitations")}
          </Heading>
        </GridItem>
        <GridItem area="events">
          {!data && skeletons.stackedLines(10)}
          {data && data.events && (
            <>
              {data.events.map((event) => {
                return (
                  <Flex
                    key={event.id}
                    w="100%"
                    h="50px"
                    borderRadius={"6px"}
                    mt="15px"
                    padding={"10px"}
                    backgroundColor="smBackgroundSecondary"
                    className={styles.event}
                    onClick={() => {
                      navigate(urls.events.supplierView.go(event.id));
                    }}
                  >
                    <Flex flex={3} alignItems={"center"} paddingLeft="10px">
                      <Text fontSize={"md"}>{event.title}</Text>
                    </Flex>
                    <Flex w="100px" alignItems={"center"} justifyContent="end">
                      <BidInvitationStatus status={event.status} />
                    </Flex>
                  </Flex>
                );
              })}
              {data.events.length === 0 && (
                <Text fontSize={"md"} ml="10px" color="#5f6368" fontStyle={"italic"}>
                  {t("You have not received any invitations to bid, yet!")}
                </Text>
              )}
            </>
          )}
        </GridItem>
      </Grid>
    </Box>
  );
};
