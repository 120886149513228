import { Flex } from "@chakra-ui/react";
import { useState } from "react";
import { ContractTaskDto } from "../../../../../../autogen/bff-api";
import { ContractTaskStatusTag } from "../../ContractTaskStatusTag";
import { EditButton } from "../EditButton";
import { EditStatus } from "./EditStatus";

interface Props {
  task: ContractTaskDto;
  onEditComleted: (task: ContractTaskDto) => void;
}

export const Status = ({ task, onEditComleted }: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Flex mt="10px" w="100%">
      {!isEditing && (
        <Flex>
          <ContractTaskStatusTag status={task.status} size={"md"} />
          {task.canEdit && <EditButton name="edit-status-button" onClick={() => setIsEditing(true)} />}
        </Flex>
      )}
      {isEditing && (
        <EditStatus
          task={task}
          onEditComleted={(e) => {
            setIsEditing(false);
            onEditComleted(e);
          }}
          onCancel={() => setIsEditing(false)}
        />
      )}
    </Flex>
  );
};
