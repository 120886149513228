import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaPaperPlane } from "react-icons/fa";
import { Certificate } from "./Certificate";

export const Certificates: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Flex
      flexDirection={"column"}
      alignItems="start"
      justifyContent={"start"}
      borderRadius={"10px"}
      boxShadow="0 3px 15px rgba(71, 85, 105, 0.16)"
      padding="25px"
      bg="smBackground"
      w="100%"
    >
      <Box w="100%">
        <Text fontSize={"md"} fontWeight="bold" color="#a4a7a6">
          {t("Certificates")}
        </Text>
        <Flex flexDirection={"column"} w="100%">
          <Certificate name="Miljøfyrtårn" status="Valid" />
          <Certificate name="ISO 9001:2015" status="Valid" />
          <Certificate name="ISO 9014" status="Valid" />
          <Certificate name="SOC 2" status="Expired" />
        </Flex>
      </Box>
      <Flex mt="20px">
        <Button colorScheme="purple" size="sm" variant="solid" leftIcon={<Icon as={FaPaperPlane} w="10px" h="10px" />}>
          {t("Request new certificate")}
        </Button>
      </Flex>
    </Flex>
  );
};
