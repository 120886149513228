import { BseDto, ProductGroupBidDto, ReceivedBseBidDto } from "../../../../../autogen/bff-api";
import { Product, ProductDraft, ProductGroup } from "../../../../../common/types";
import { eventIsAwarded } from "../../../eventIsAwarded";

export const getSumOfBestPrices = ({ groups, bids }: { groups: ProductGroup[]; bids: ReceivedBseBidDto[] }) => {
  return groups.reduce((sum: number, group: ProductGroup) => sum + getSumOfBestGroupPrices({ group, bids }), 0);
};

export const getSumOfBestGroupPrices = ({
  group,
  bids,
}: {
  group: ProductGroup;
  bids: ReceivedBseBidDto[];
}): number => {
  return (
    group.products?.reduce(
      (sum: number, product: Product | ProductDraft) =>
        sum + (getBestProductPrice({ productId: product.id, group, bids }) ?? 0),
      0
    ) ?? 0
  );
};

export const isBestTotalPrice = ({
  bid,
  bids,
  groups,
}: {
  bid: ReceivedBseBidDto;
  bids: ReceivedBseBidDto[];
  groups: ProductGroup[];
}) => {
  const lowestPrice = getLowestTotalPrice({ groups, bids });
  const price = getTotalPrice({ bid, groups });
  return price <= lowestPrice;
};

const getLowestTotalPrice = ({ bids, groups }: { bids: ReceivedBseBidDto[]; groups: ProductGroup[] }): number => {
  return Math.min(...bids.map((bid) => getTotalPrice({ bid, groups })));
};

export const getTotalPrice = ({ bid, groups }: { bid: ReceivedBseBidDto; groups: ProductGroup[] }): number => {
  return (
    bid.products?.reduce(
      (sum, groupBid) =>
        sum +
        (getProductGroupPrice({
          groupBid,
          productGroup: findProductGroup({ groupId: groupBid.productGroupId, groups }),
        }) ?? 0),
      0
    ) ?? 0
  );
};

const findProductGroup = ({ groupId, groups }: { groupId: string; groups: ProductGroup[] }) => {
  const group = groups.find((g) => g.id === groupId);
  return group;
};

export const getProductGroupPrice = ({
  groupBid,
  productGroup,
}: {
  groupBid?: ProductGroupBidDto;
  productGroup?: ProductGroup;
}): number | undefined => {
  if (!productGroup) return 0;
  return groupBid?.productBids.reduce(
    (sum, productBid) =>
      sum + (getTotalProductPrice({ productId: productBid.productId, group: productGroup, bid: groupBid }) ?? 0),
    0
  );
};

export const isBestProductPrice = ({
  bidId,
  productId,
  group,
  bids,
}: {
  bidId: string;
  productId?: string;
  group: ProductGroup;
  bids: ReceivedBseBidDto[];
}) => {
  if (!productId) throw Error("No product id.");
  const lowestPrice = getBestProductPrice({ productId, group, bids });
  const productPrice = getProductPrice({ bidId, productId, group, bids });
  return (productPrice || productPrice === 0) && productPrice <= (lowestPrice ?? 0);
};

export const getBestProductPrice = ({
  productId,
  group,
  bids,
}: {
  productId?: string;
  group: ProductGroup;
  bids: ReceivedBseBidDto[];
}) => {
  if (!productId) return undefined;
  const prices = bids
    .map((bid) =>
      getTotalProductPrice({
        group,
        bid: findProductGroupBid({ groupId: group.id, bid }),
        productId,
      })
    )
    .filter((price) => !!price || price === 0) as number[];
  return prices.length ? Math.min(...prices) : undefined;
};

const getProductPrice = ({
  bidId,
  productId,
  group,
  bids,
}: {
  bidId: string;
  productId: string;
  group: ProductGroup;
  bids: ReceivedBseBidDto[];
}) => {
  const bid = bids.find((b) => b.id === bidId);
  if (!bid) return undefined;
  const groupBid = findProductGroupBid({ groupId: group.id, bid });
  return getTotalProductPrice({ productId, group, bid: groupBid });
};

export const findProductBid = ({
  productId,
  groupId,
  bid,
}: {
  productId?: string;
  groupId: string;
  bid?: ReceivedBseBidDto;
}) => {
  if (!productId) return undefined;
  return findProductGroupBid({ groupId, bid })?.productBids.find((productBid) => productBid.productId === productId);
};

export const findProductGroupBid = ({ groupId, bid }: { groupId: string; bid?: ReceivedBseBidDto }) =>
  bid?.products?.find((groupBid) => groupBid.productGroupId === groupId);

export const getQuotesRequested = (groups: ProductGroup[]): number => {
  return groups.map((group) => group.products?.length).reduce((count, sum) => (count ?? 0) + (sum ?? 0), 0) ?? 0;
};

export const getQuotesDelivered = ({ groups, bids }: { groups: ProductGroup[]; bids: ReceivedBseBidDto[] }): number => {
  return groups.reduce((sum: number, group) => sum + getQuotesDeliveredInGroup({ group, bids }), 0);
};

const getQuotesDeliveredInGroup = ({ group, bids }: { group: ProductGroup; bids: ReceivedBseBidDto[] }): number => {
  return (
    group.products?.reduce(
      (sum: number, product: Product | ProductDraft) =>
        sum + (isQuoteDeliveredForProduct({ groupId: group.id, productId: product.id, bids }) ? 1 : 0),
      0
    ) ?? 0
  );
};

const isQuoteDeliveredForProduct = ({
  groupId,
  productId,
  bids,
}: {
  groupId: string;
  productId?: string;
  bids: ReceivedBseBidDto[];
}): boolean => {
  if (!productId) throw Error("No productId");
  for (const bid of bids) {
    const productBid = findProductBid({ productId, groupId, bid });
    if (productBid) return true;
  }
  return false;
};

export const getQuotesDeliveredInBid = ({ bid }: { bid: ReceivedBseBidDto }) => {
  const quotesByGroup = bid.products?.map((groupBid) => groupBid.productBids.length);
  return quotesByGroup?.reduce((count, sum) => count + sum, 0) ?? 0;
};

export const getSumOfSelectedPrices = ({
  groups,
  bids,
  sourcingEvent,
}: {
  groups: ProductGroup[];
  bids: ReceivedBseBidDto[];
  sourcingEvent: BseDto;
}) => {
  return groups.reduce((sum: number, group) => sum + getSelectedProductGroupPrice({ group, bids, sourcingEvent }), 0);
};

export const getSelectedProductGroupPrice = ({
  group,
  bids,
  sourcingEvent,
}: {
  group: ProductGroup;
  bids: ReceivedBseBidDto[];
  sourcingEvent: BseDto;
}): number => {
  return (
    group.products?.reduce(
      (sum, product) => sum + (getAwardedProductPrice({ group, bids, sourcingEvent, productId: product.id }) ?? 0),
      0
    ) ?? 0
  );
};

export const getAwardedProductPrice = ({
  group,
  productId,
  bids,
  sourcingEvent,
}: {
  group: ProductGroup;
  productId?: string;
  bids: ReceivedBseBidDto[];
  sourcingEvent: BseDto;
}) => {
  if (!productId) throw Error("No product id");
  const selectedBids = getSelectedOrWinningBids(sourcingEvent);
  const selectedProductBids = selectedBids
    ?.find((groupBid) => groupBid.productGroupId === group.id)
    ?.bids.find((productBid) => productBid.productId === productId);
  const selectedBidsForProduct = bids.filter((b) => selectedProductBids?.bids.includes(b.id));
  return selectedBidsForProduct.reduce((sum, bid) => {
    const groupBid = findProductGroupBid({ groupId: group.id, bid });
    return (
      sum +
      (getTotalProductPrice({
        group,
        bid: groupBid,
        productId,
      }) ?? 0)
    );
  }, 0);
};

export const getSelectedOrWinningBids = (sourcingEvent: BseDto) =>
  eventIsAwarded(sourcingEvent)
    ? sourcingEvent.awardedFields?.winningProductBids
    : sourcingEvent.publishedFields?.selectedProductGroupBids;

const findWinningBids = ({
  group,
  productId,
  bids,
  sourcingEvent,
}: {
  group: ProductGroup;
  productId: string;
  bids: ReceivedBseBidDto[];
  sourcingEvent: BseDto;
}) => {
  const winningBids = getSelectedOrWinningBids(sourcingEvent)
    ?.find((groupBid) => groupBid.productGroupId === group.id)
    ?.bids.find((productBid) => productBid.productId === productId);
  return bids.filter((b) => winningBids?.bids.includes(b.id));
};

export const isAwardedProduct = ({
  group,
  productId,
  bidId,
  bids,
  sourcingEvent,
}: {
  group: ProductGroup;
  productId: string;
  bidId: string;
  bids: ReceivedBseBidDto[];
  sourcingEvent: BseDto;
}) => {
  return findWinningBids({ group, productId, bids, sourcingEvent })
    .map((b) => b.id)
    .includes(bidId);
};

export const getAwardedProductSuppliers = ({
  group,
  productId,
  bids,
  sourcingEvent,
}: {
  group: ProductGroup;
  productId?: string;
  bids: ReceivedBseBidDto[];
  sourcingEvent: BseDto;
}) => {
  if (!productId) throw Error("Product is missing id");
  const winningBids = findWinningBids({ group, productId, bids, sourcingEvent });
  return winningBids?.map((bid) => bid.owningOrganization.name).join(", ");
};

export const getTotalProductPrice = ({
  productId,
  group,
  bid,
}: {
  productId: string;
  group: ProductGroup;
  bid?: ProductGroupBidDto;
}): number | undefined => {
  const productBid = bid?.productBids.find((b) => b.productId === productId);
  const unitPrice = productBid?.unitPrice.value;
  const quantity = group.products?.find((product) => product.id === productId)?.quantity.value;
  if (unitPrice && quantity) return unitPrice * quantity;
  return unitPrice;
};
