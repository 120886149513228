import { Button, Grid, GridItem, Link, Text } from "@chakra-ui/react";
import { useIntercom } from "react-use-intercom";
import { EmailInviteLanguage } from "../../autogen/bff-api";
import { setPreferredLanguage } from "../local-storage/language";

export const FooterLinks = () => {
  const { show } = useIntercom();

  const setLanguage = (language: EmailInviteLanguage) => {
    setPreferredLanguage(language);
    window.location.reload();
  };
  return (
    <Grid h="100%" gridTemplateColumns={`1fr 1fr 1fr 1fr`} gridTemplateRows={`1fr`}>
      <GridItem paddingTop={1}>
        <Text fontSize="md" color="smMuted">
          <b>About</b>
        </Text>
        <Link fontSize="sm" color="smMuted" target="_blank" href="https://www.sourcemagnet.com">
          Our vision
        </Link>
        <br />
        <Link fontSize="sm" color="smMuted" target="_blank" href="https://www.sourcemagnet.com/about-us">
          The team
        </Link>
      </GridItem>
      <GridItem paddingTop={1}>
        <Text fontSize="md" color="smMuted">
          <b>Terms</b>
        </Text>
        <Link fontSize="sm" color="smMuted" target="_blank" href="https://www.sourcemagnet.com/privacy-policy">
          Privacy Policy
        </Link>
        <br />
        <Link fontSize="sm" color="smMuted" target="_blank" href="https://www.sourcemagnet.com/avtalevilkar">
          Terms of Use
        </Link>
      </GridItem>
      <GridItem paddingTop={1}>
        <Text fontSize="md" color="smMuted">
          <b>Support</b>
        </Text>
        <Button fontSize="sm" color="smMuted" variant="link" onClick={show} fontWeight="normal">
          Contact us
        </Button>
      </GridItem>
      <GridItem paddingTop={1}>
        <Text fontSize="md" color="smMuted">
          <b>Language</b>
        </Text>
        <Button fontSize="sm" color="smMuted" variant="link" onClick={() => setLanguage("No")} fontWeight="normal">
          Norwegian
        </Button>
        <br />
        <Button fontSize="sm" color="smMuted" variant="link" onClick={() => setLanguage("En")} fontWeight="normal">
          English
        </Button>
      </GridItem>
    </Grid>
  );
};
