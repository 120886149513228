import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  ContractDataFieldNameDto,
  ContractTemplateDto,
  CustomFieldIdentifierDto,
  useUpdateContractTemplateMutation,
} from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { SimpleFormInput } from "../../../../common/input/SimpleInput/SimpleFormInput";
import { TextAreaFormInput } from "../../../../common/input/TextAreaInput/TextAreaFormInput";
import { ContractDataFieldSection, CustomFieldType } from "../../../../common/types";
import { DataFieldFormSelector } from "../../edit/details/optional/DataFieldFormSelector";
import { ContractFieldOption } from "../../edit/details/optional/DataFieldSelector";

interface Props {
  data: ContractTemplateDto;
  onClose: () => void;
}

interface FormValues {
  name: string;
  description: string;
  requiredDataFields: ContractFieldOption[];
}

export const EditTemplateModal = ({ data, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editTemplate] = useUpdateContractTemplateMutation();
  const displayer = useApiError();
  const toast = useToast();
  const methods = useForm<FormValues>({
    defaultValues: {
      name: data.name,
      description: data.description,
      requiredDataFields: [
        ...data.requiredFields,
        ...Object.values(
          (data.requiredCustomDataFields as unknown as { [key: string]: ContractDataFieldSection }) ?? {}
        )
          .map((section) =>
            section.fields.map((field) => ({
              fieldId: field.id,
              name: field.name,
              type: field.type,
              sectionId: section.id,
              sectionName: section.name,
              ...(field.type === CustomFieldType.CustomEnum || field.type === CustomFieldType.CustomMultiEnum
                ? { options: field.options }
                : {}),
            }))
          )
          .flat(),
      ],
    },
  });

  const alreadyAddedFields = methods.watch("requiredDataFields");

  const edit = async (values: FormValues) => {
    setIsLoading(true);
    const result = await editTemplate({
      templateId: data.id,
      upsertContractTemplateRequest: {
        name: values.name,
        description: values.description,
        requiredDataFields: values.requiredDataFields
          .filter((o) => !o.sectionId)
          .map((o) => o.name) as ContractDataFieldNameDto[],
        requiredCustomDataFields: values.requiredDataFields
          ?.filter((o) => o.sectionId)
          .map((o) => ({ sectionId: o.sectionId, fieldId: o.fieldId })) as CustomFieldIdentifierDto[],
      },
    });
    if ("data" in result) {
      toast({
        title: t("Type edited!"),
        status: "success",
      });
      onClose();
    } else {
      displayer.trigger(result.error);
    }
    setIsLoading(false);
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Edit contract type")}</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(edit)}>
            <ModalBody>
              <SimpleFormInput
                label={"Name"}
                helperText={t("Please provide a type name")}
                control={methods.control}
                name="name"
                required={{
                  value: true,
                  message: t("Name is required"),
                }}
                errorMessage={methods.formState.errors.name?.message}
              />
              <Box mt="20px">
                <TextAreaFormInput
                  label={"Description"}
                  helperText={t("Please provide a contract type description")}
                  control={methods.control}
                  name="description"
                  required={{
                    value: true,
                    message: t("Description is required"),
                  }}
                  errorMessage={methods.formState.errors.description?.message}
                />
              </Box>
              <Box mt="20px">
                <DataFieldFormSelector
                  alreadyAddedFields={alreadyAddedFields.map((e) => e.name)}
                  control={methods.control}
                  name="requiredDataFields"
                  helperText={t("Please select data fields")}
                  label={t("Required data fields") ?? ""}
                  errorMessage={methods.formState.errors.requiredDataFields?.message}
                />
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose} isDisabled={isLoading}>
                {t("Cancel")}
              </Button>
              <Button type="submit" variant="solid" colorScheme={"blue"} isLoading={isLoading}>
                {t("Save")}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};
