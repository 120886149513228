import { Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ContractTaskDto } from "../../../../../../autogen/bff-api";
import { displayPersonNameWithEmail } from "../../../sharing/AddExternalParticipantModal";
import { EditButton } from "../EditButton";
import { EditResponsiblePerson } from "./EditResponsiblePerson";

interface Props {
  task: ContractTaskDto;
  onEditComleted: (task: ContractTaskDto) => void;
}

export const ResponsiblePerson = ({ task, onEditComleted }: Props) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  return (
    <Flex w="100%" flexDirection={"column"} padding="10px">
      <Flex>
        <Text fontSize={"md"} fontWeight="bold">
          {t("Responsible person")}
        </Text>
        {task.canEdit && !isEditing && (
          <EditButton name="edit-responsible-person-button" onClick={() => setIsEditing(true)} />
        )}
      </Flex>
      <Flex mt="5px">
        {isEditing ? (
          <EditResponsiblePerson
            task={task}
            onEditComleted={(e) => {
              setIsEditing(false);
              onEditComleted(e);
            }}
            onCancel={() => setIsEditing(false)}
          />
        ) : (
          <Text fontWeight={"light"} whiteSpace="pre-line">
            {displayPersonNameWithEmail(task.responsiblePerson)}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
