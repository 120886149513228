import { createAsyncThunk } from "@reduxjs/toolkit";
import { bffApi } from "../../../../autogen/bff-api";
import { ContractState } from "../../reducers/contractReducer";

export const loadContractThunk = createAsyncThunk(
  "contract/load",
  async (
    props: {
      contractId: string;
    },
    { dispatch }
  ): Promise<ContractState | null> => {
    try {
      const contract = await dispatch(
        bffApi.endpoints.getContract.initiate({ contractId: props.contractId }, { forceRefetch: true })
      ).unwrap();
      return {
        id: contract.id,
        contract: contract as ContractState["contract"],
        lastChanged: contract.modifiedAt,
        errors: [],
      };
    } catch (e) {
      return null;
    }
  }
);
