import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { RecoveryFlow } from "@ory/client";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { authService } from "../../../config";
interface Props {
  onComplete: (email: string, flow: RecoveryFlow) => void;
}

interface FormValues {
  email: string;
}

export const InitForgottenPasswordFlowForm = ({ onComplete }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<FormValues>();

  const initForgottenPasswordFlow = async (values: FormValues): Promise<boolean> => {
    setIsLoading(true);

    const flow = await authService.createBrowserRecoveryFlow();

    const result = await authService.initForgottenPasswordFlow(values.email, flow);
    switch (result) {
      case "Failure":
        toast({
          title: t("Woops") + "...",
          description: t(
            "Something went wrong and our team has bee notified, please make sure you entered the correct email address for your account"
          ),
          status: "error",
        });
        setIsLoading(false);
        return true;
      case "UserNotFound":
        setError("email", {
          message: t("An account for this email address does not exist") ?? "",
        });
        setIsLoading(false);
        return true;
      case "Success":
        onComplete(values.email, flow);
        toast({
          title: t("Email sent!"),
          status: "success",
        });
        setIsLoading(false);
        return true;
    }
  };

  return (
    <form onSubmit={handleSubmit(initForgottenPasswordFlow)}>
      <FormControl isInvalid={errors.email !== undefined} mt="10px">
        <FormLabel htmlFor={"email"}>Email</FormLabel>
        <Input
          id={"email"}
          type="email"
          {...register("email", {
            required: t("Please provide the email address of your account") ?? "",
            minLength: {
              value: 2,
              message: t("Must be at least 2 characters long"),
            },
          })}
        />
        <FormHelperText>{t("We will send the reset instructions to your email")}</FormHelperText>
        <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
      </FormControl>
      <Flex mt="20px" justifyContent={"end"}>
        <Button type="submit" colorScheme="teal" isLoading={isLoading}>
          {t("Receive email code")}
        </Button>
      </Flex>
    </form>
  );
};
