import { Button, Icon } from '@chakra-ui/react'
import React from 'react'
import { FaEdit } from 'react-icons/fa'

interface Props {
    name: string
    onClick: () => void
}

export const EditButton: React.FC<Props> = ({ name, onClick }) => {
    return (
        <Button
            id={name}
            ml="10px"
            mt="-2px"
            variant={'ghost'}
            size="xs"
            color="#717171"
            leftIcon={<Icon as={FaEdit} w="15px" h="15px" />}
            onClick={onClick}
            pl="17px"
        ></Button>
    )
}
