import { Flex, Spacer, Text } from '@chakra-ui/react'
import React from 'react'
import {
    QuestionnaireStatus,
    QuestionnaireStatusTag,
} from './QuestionnaireStatusTag'

interface Props {
    name: string
    status: QuestionnaireStatus
}

export const Questionnaire: React.FC<Props> = ({ name, status }) => {
    return (
        <Flex w="100%" mt="15px">
            <Flex>
                <Text>{name}</Text>
            </Flex>
            <Spacer />
            <Flex alignItems={'center'}>
                <QuestionnaireStatusTag status={status} />
            </Flex>
        </Flex>
    )
}
