import {
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@chakra-ui/react'
import { VerificationFlow } from '@ory/client'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoggedInWithOrgContextState } from '../useLoggedInWithOrgContextState'
import { SendConfirmationCode } from './SendConfirmationCode'

interface Props {
    onClose: () => void
}

export const ConfirmEmailModal: React.FC<Props> = ({ onClose }) => {
    const [flow, setFlow] = useState<VerificationFlow>()
    const authState = useLoggedInWithOrgContextState()
    const { t } = useTranslation()

    return (
        <Modal
            isOpen={true}
            onClose={onClose}
            closeOnOverlayClick={true}
            size="md"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('Confirm email')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {!flow ? (
                        <SendConfirmationCode
                            codeSent={(flow) => {
                                setFlow(flow)
                            }}
                        />
                    ) : (
                        <Box paddingBottom={'10px'}>
                            <Text>
                                {t('A new verification email has been sent to')}{' '}
                                <b>{authState.me.email}</b>
                            </Text>
                        </Box>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
