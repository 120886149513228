import { Button, Flex, FormControl, FormLabel, Icon, Spinner, Text } from "@chakra-ui/react";
import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import ProgressBar from "@uppy/progress-bar";
import "@uppy/progress-bar/dist/style.css";
import { DragDrop } from "@uppy/react";
import { t } from "i18next";
import { isFinite } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  ContractDto,
  ContractTemplateDto,
  ProjectDto,
  bffApi,
  useCreateContractMutation,
  useStartDocumentChatMutation,
} from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useOrganizationSearch } from "../../../common/organization/useOrganizationSearch";
import { getPusher } from "../../../common/pusher";
import { useAppDispatch } from "../../../common/redux/hooks";
import { urls } from "../../../urls";

const createPrompt = (templates: ContractTemplateDto[], loggedInOrg: string) => `
  Based on the given PDF Document text, populate the values in the following json:

  {
    "title": null, // Should be short but descriptive
    "description": null, // Should be short but descriptive. 1-2 paragraphs.
    "templateId": null, // Should be one of the UUIDs in the list below. Select the most appropriate template. Should be null if no template fits. Must be a valid UUID or null!!!
    "startDate": null, // Should be formatted as yyyy-MM-dd. Should be null if there is no start date.
    "endDate": null, // Should be formatted as yyyy-MM-dd. Should be null if there is no end date.
    "paymentTerms": number; // Should be the payment terms in number of days. Should be null or an integer.
    "noticePeriod": number; // Should be the notice period in number of months. Should be null or an integer.
    "counterparty": {
      companyName: string;
      fullName?: string;
      email?: string;
    },
    "documentPages": number; // Number of pages in the text
  }

  The current user is logged in on behalf of ${loggedInOrg}

  The templateId should be one of the UUIDs in the following list:
  ${templates.map((t) => `${JSON.stringify({ uuid: t.id, name: t.name, description: t.description })}, `)}

  It is very important that you return a plain, valid json of the provided format only.
`;

export const CreateContractWithAIForm = ({
  templates,
  project,
}: {
  templates: ContractTemplateDto[];
  project?: ProjectDto;
}) => {
  const authState = useLoggedInWithOrgContextState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [create, { isLoading: isCreatingContract }] = useCreateContractMutation();
  const [analyzeDocument, { isLoading: isAnalyzingDocument }] = useStartDocumentChatMutation();

  // TODO: Avoid listing all orgs
  const { organizationCombinations } = useOrganizationSearch({ limit: 10_000 });

  const [target, setTarget] = useState<string>();
  const [isDocumentAnalyzed, setIsDocumentAnalyzed] = useState<boolean>();
  const [isDocumentScanned, setIsDocumentScanned] = useState<boolean>();
  const [pagesAnalyzed, setPagesAnalyzed] = useState<number>();
  const [contract, setContract] = useState<ContractDto>();

  useEffect(() => {
    setTarget("#dnd");
  }, []);

  const documentAddedHandler = useCallback(async () => {
    if (!contract?.id) return;
    setIsDocumentScanned(true);
    const response = dispatch(
      bffApi.endpoints.getContract.initiate({
        contractId: contract.id,
      })
    );
    response.unsubscribe();
    const result = await response;
    setContract(result.data);
  }, [contract, dispatch]);

  useEffect(() => {
    const channel = contract?.id ? getPusher().subscribe(contract.id) : undefined;
    channel?.bind("document-added", documentAddedHandler);

    return () => {
      channel?.unbind("document-added", documentAddedHandler);
    };
  }, [documentAddedHandler, contract?.id]);

  useEffect(() => {
    if (!isDocumentAnalyzed && contract?.documentFields?.documents.length) {
      const document = contract.documentFields.documents[0];
      analyzeDocument({
        docId: document.id,
        chatCompletionRequest: {
          model: "gpt-4o",
          messages: [
            {
              role: "system",
              content: createPrompt(templates, authState.selectedOrg.name),
            },
          ],
          temperature: 0.7,
        },
      }).then(async (res) => {
        if (!("data" in res)) throw Error("Could not analyze document");
        const analysis = res.data.messages[res.data.messages.length - 1].content;
        if (!analysis) throw Error("Could not analyze document");
        const data = JSON.parse(analysis.replace(/```json\s*|\s*```/g, ""));
        setIsDocumentAnalyzed(true);
        setPagesAnalyzed(data.documentPages);
        const counterparty = organizationCombinations?.find(
          (o) => o.name.toLowerCase() === data.counterparty?.companyName?.toLowerCase()
        );
        setContract((c) =>
          c
            ? {
                ...c,
                title: data.title,
                description: data.description,
                template: templates.find((t) => t.id === data.templateId),
                counterparty: {
                  organizationEntry: {
                    id: "",
                    name: data.counterparty.companyName,
                    country: {
                      id: "",
                      alpha3Code: "",
                      name: "",
                    },
                    industries: [],
                    organizationNumber: "",
                  },
                },
                dataFields: {
                  startDate: data.startDate,
                  endDate: {
                    date: data.endDate,
                    hasNoEndDate: false,
                  },
                  counterpartyContactPerson: {
                    fullName: data.counterparty.fullName,
                    email: data.counterparty.email,
                  },
                  paymentTermsInDays: data.paymentTerms,
                  noticePeriod: data.noticePeriod,
                  addedDataFields: [],
                  requiredDataFields: [],
                  missingDataFields: [],
                },
              }
            : undefined
        );
        const response = dispatch(
          bffApi.endpoints.updateContract.initiate({
            contractId: contract.id,
            updateContractRequest: {
              title: data.title,
              description: data.description,
              template: {
                templateId: data.templateId,
              },
              startDate: data.startDate
                ? {
                    date: data.startDate,
                  }
                : undefined,
              endDate: data.endDate
                ? {
                    date: data.endDate,
                    hasNoEndDate: false,
                  }
                : undefined,
              counterpartyContactPerson: data.counterparty
                ? {
                    fullName: data.counterparty.fullName,
                    email: data.counterparty.email,
                  }
                : undefined,
              paymentTermsInDays: isFinite(data.paymentTerms)
                ? {
                    days: data.paymentTerms,
                  }
                : undefined,
              editNoticePeriod: data.noticePeriod
                ? {
                    duration: data.noticePeriod,
                  }
                : undefined,
              counterparty: counterparty
                ? {
                    counterparty: {
                      organizationEntryId: counterparty?.type === "OrganizationEntry" ? counterparty.id : undefined,
                      organizationId: counterparty?.type === "Organization" ? counterparty.id : undefined,
                    },
                  }
                : undefined,
            },
          })
        );
        response.reset();
        await response;
      });
    }
  }, [
    analyzeDocument,
    authState.selectedOrg.name,
    contract,
    dispatch,
    isDocumentAnalyzed,
    organizationCombinations,
    templates,
  ]);

  const { uppy } = useMemo(() => {
    const uppy = new Uppy({
      id: "AwsS3",
      autoProceed: true,
      onBeforeFileAdded(currentFile) {
        currentFile["id"] = uuid();
        return currentFile;
      },
    })
      .use(ProgressBar, { target })
      .use(AwsS3, {
        id: "AwsS3",
        limit: 1,
        async getUploadParameters(file) {
          if (!isFile(file.data)) throw Error("Only files, not blobs, are supported.");
          const contractRes = await create({
            depId: authState.selectedOrg.departments[0].id,
            createContractRequest: {
              title: "",
              timezone: moment.tz.guess(),
              projects: project ? [project.id] : undefined,
            },
          });
          if (!("data" in contractRes)) throw Error("Could not create contract");
          setContract(contractRes.data);
          const uploadMutation = dispatch(
            bffApi.endpoints.createUploadForContract.initiate({
              contractId: contractRes.data.id,
              uploadId: file.id,
              createUploadRequestDto: {
                fileName: file.name,
                fileSize: file.size,
                fileExtension: file.extension,
                mimeType: file.data.type,
              },
            })
          );
          uploadMutation.reset();
          const uploadRes = await uploadMutation;
          if (!("data" in uploadRes)) throw Error("Could not create upload");
          setContract({
            ...contractRes.data,
            editingMetaFields: {
              uploads: [uploadRes.data],
            },
          });
          return {
            url: uploadRes.data.url,
            method: "POST",
            fields: {
              "x-amz-date": uploadRes.data.renamedXAmzDate,
              "x-amz-signature": uploadRes.data.renamedXAmzSignature,
              "x-amz-algorithm": uploadRes.data.renamedXAmzAlgorithm,
              "x-amz-credential": uploadRes.data.renamedXAmzCredential,
              policy: uploadRes.data.policy,
              key: uploadRes.data.key,
            },
          };
        },
      });

    return { uppy };
  }, [authState.selectedOrg.departments, create, dispatch, project, target]);

  return (
    <Flex flexDirection={"column"}>
      <FormControl pt="4" display={contract || isCreatingContract ? "none" : "block"}>
        <FormLabel>{t("Add contract document")}</FormLabel>
        <div id="dnd">
          <DragDrop uppy={uppy} />
        </div>
      </FormControl>
      {isCreatingContract && !contract && (
        <Flex alignItems={"center"} pt="4">
          <Spinner size="xs" thickness="2px" color="blue.200" mr="2" />
          <Text fontSize={"sm"} color="smMuted">
            {t("Creating contract")}...
          </Text>
        </Flex>
      )}
      {contract && (
        <Flex alignItems={"center"} pt="4">
          <Icon as={FaCheckCircle} color={"green.400"} mr="2" />
          <Text fontSize={"sm"} color="smMuted">
            {t("Contract created")}
          </Text>
        </Flex>
      )}
      {contract && !isDocumentScanned && !isAnalyzingDocument && !isDocumentAnalyzed && (
        <Flex alignItems={"center"}>
          <Spinner size="xs" thickness="2px" color="blue.200" mr="2" />
          <Text fontSize={"sm"} color="smMuted">
            {t("Scanning document")}...
          </Text>
        </Flex>
      )}
      {isDocumentScanned && (
        <Flex alignItems={"center"}>
          <Icon as={FaCheckCircle} color={"green.400"} mr="2" />
          <Text fontSize={"sm"} color="smMuted">
            {t("Document virus scan complete")}
          </Text>
        </Flex>
      )}
      {isAnalyzingDocument && !isDocumentAnalyzed && (
        <Flex alignItems={"center"}>
          <Spinner size="xs" thickness="2px" color="blue.200" mr="2" />
          <Text fontSize={"sm"} color="smMuted">
            {t("Running deep neural net analysis")}...
          </Text>
        </Flex>
      )}
      {isDocumentAnalyzed && pagesAnalyzed && (
        <Flex alignItems={"center"} pb="4">
          <Icon as={FaCheckCircle} color={"green.400"} mr="2" />
          <Text fontSize={"sm"} color="smMuted">
            {t("Deep neural net analysis complete")}. {t("Analyzed")} {pagesAnalyzed} {t("pages")}.
          </Text>
        </Flex>
      )}
      {isDocumentAnalyzed && contract && (
        <Flex flexDirection={"column"} overflow="auto" backgroundColor={"smBackgroundSecondary"} p="4">
          {contract?.title && (
            <Text fontWeight={"bold"} pt="4">
              {contract.title}
            </Text>
          )}
          {contract?.description && (
            <Text fontSize={"sm"} py="2">
              {contract.description}
            </Text>
          )}
          {contract?.template && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("Contract type")}</Text>
              <Text fontWeight={"bold"}>{contract.template.name}</Text>
            </Flex>
          )}
          {contract?.dataFields?.startDate && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("Start date")}</Text>
              <Text fontWeight={"bold"}>{contract.dataFields.startDate}</Text>
            </Flex>
          )}
          {contract?.dataFields?.endDate?.date && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("End date")}</Text>
              <Text fontWeight={"bold"}>{contract.dataFields.endDate.date}</Text>
            </Flex>
          )}
          {contract?.dataFields?.paymentTermsInDays && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("Payment terms")}</Text>
              <Text fontWeight={"bold"}>
                {contract.dataFields.paymentTermsInDays} {t("days")}
              </Text>
            </Flex>
          )}
          {contract?.dataFields?.noticePeriod && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("Notice period")}</Text>
              <Text fontWeight={"bold"}>
                {contract.dataFields.noticePeriod} {t("months")}
              </Text>
            </Flex>
          )}
          {contract?.dataFields?.counterpartyContactPerson && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("Counterparty")}</Text>
              <Text fontWeight={"bold"} pb="2">
                {contract.counterparty?.organizationEntry?.name}
                {contract.counterparty?.organizationEntry?.organizationNumber}
              </Text>
              <Text>{contract.dataFields.counterpartyContactPerson.fullName}</Text>
              <Text>{contract.dataFields.counterpartyContactPerson.email}</Text>
            </Flex>
          )}
        </Flex>
      )}
      <Flex justifyContent={"end"} alignItems={"end"} pt="4">
        <Button
          colorScheme="teal"
          isDisabled={!contract || !isDocumentAnalyzed}
          onClick={() => {
            if (!contract) return;
            if (urls.contracts.edit.isCurrentPage()) {
              window.location.replace(urls.contracts.edit.go(contract.id).details.fullPathName("details"));
            } else navigate(urls.contracts.edit.go(contract.id).details, { replace: true });
          }}
        >
          {t("Next")}
        </Button>
      </Flex>
    </Flex>
  );
};

const isFile = (file: File | Blob): file is File => {
  return (file as File).lastModified !== undefined;
};
