import { Tag } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ContractTaskStatusDto } from "../../../../autogen/bff-api";

interface Props {
  status: ContractTaskStatusDto;
  size?: "sm" | "md" | "lg";
}

export const ContractTaskStatusTag = ({ status, size }: Props) => {
  const { t } = useTranslation();
  const getEventTagMeta = (
    status: ContractTaskStatusDto
  ): {
    color: string;
    text: string;
  } => {
    switch (status) {
      case "Open":
        return {
          color: "cyan",
          text: t("Open"),
        };
      case "Completed":
        return {
          color: "green",
          text: t("Completed"),
        };
      case "Overdue":
        return {
          color: "red",
          text: t("Overdue"),
        };
    }
  };

  const tagMeta = getEventTagMeta(status);

  return (
    <Tag
      variant="outline"
      colorScheme={tagMeta.color}
      size={size}
      textAlign="center"
      paddingTop={"2px"}
      paddingBottom={"2px"}
    >
      {tagMeta.text}
    </Tag>
  );
};
