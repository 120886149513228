import { Box } from "@chakra-ui/react";
import { useCallback } from "react";
import { MinimalOrgDto, useUpdateOrganizationMutation } from "../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../common/auth/useLoggedInWithOrgContextState";
import { OrganizationsSelector } from "../../common/organization/OrganizationsSelector";
import { useAppDispatch } from "../../common/redux/hooks";
import { updateSelectedOrg } from "../../common/redux/reducers/authStateReducer";

export const InternalParties = () => {
  const authState = useLoggedInWithOrgContextState();
  const dispatch = useAppDispatch();

  const [updateOrganization] = useUpdateOrganizationMutation();

  const handleUpdatedOrganization = useCallback(
    ({ organizations }: { organizations?: MinimalOrgDto[] }) => {
      dispatch(updateSelectedOrg({ ...authState.selectedOrg, internalParties: organizations }));
      const parties = organizations?.filter((o) => o.type === "Organization");
      const selfRegisteredParties = organizations?.filter((o) => o.type === "OrganizationEntry");
      updateOrganization({
        orgId: authState.selectedOrg.id,
        editOrganizationRequest: {
          internalParties: {
            parties: parties?.map((o) => o.id),
            selfRegisteredParties: selfRegisteredParties?.map((o) => o.id),
          },
        },
      });
    },
    [authState.selectedOrg, dispatch, updateOrganization]
  );

  return (
    <Box>
      <OrganizationsSelector
        size="md"
        selectedOrganizations={authState.selectedOrg.internalParties ?? []}
        onChange={({ organizations }) => handleUpdatedOrganization({ organizations })}
      />
    </Box>
  );
};
