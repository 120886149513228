import { Checkbox, Flex, Icon, IconButton, Input, Td, Textarea, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { FaGripLinesVertical, FaTrash } from "react-icons/fa";
import { Populater } from "../../autogen/bff-api";
import { useAppDispatch } from "../redux/hooks";
import { editProduct as editProductState, removeProduct } from "../redux/reducers/productReducer";
import { editProductGroupThunk } from "../redux/thunks/product/editProductGroupThunk";
import { Explanation } from "../support/Explanation/Explanation";
import { Product, ProductField, ProductFieldType } from "../types";

export const ProductRowForm = ({
  groupId,
  product,
  isSelected = false,
  isHighlighted = false,
  onSelect,
  onUnSelect,
  onProductDrag,
  onProductDragEnd,
}: {
  groupId: string;
  product: Product;
  isSelected?: boolean;
  isHighlighted?: boolean;
  onSelect?: (product: Product) => void | Promise<void>;
  onUnSelect?: (product: Product) => void | Promise<void>;
  onProductDrag?: (product: Product) => void | Promise<void>;
  onProductDragEnd?: (product: Product) => void | Promise<void>;
}) => {
  const dispatch = useAppDispatch();

  const [isDragging, setIsDragging] = useState(false);

  const handleFieldChange = ({
    id,
    name,
    type,
    isRequired,
    populatedBy,
    value,
  }: {
    id: string;
    name: string;
    type: ProductFieldType;
    value: string | boolean;
    isRequired: boolean;
    populatedBy: Populater;
  }) => {
    const productFields = [...product.productFields];
    const fieldIndex = product.productFields.findIndex((f) => f.name === name);
    productFields.splice(fieldIndex, 1, {
      id,
      name,
      type,
      isRequired,
      populatedBy,
      value: parseInputValue(value, type),
    });
    handleChange({ groupId, product: { ...product, productFields } });
  };

  const handleQuantityChange = (value: string) => {
    handleChange({
      groupId,
      product: { ...product, quantity: { ...product.quantity, value: value === "" ? undefined : Number(value) } },
    });
  };

  const handleChange = ({ groupId, product }: { groupId: string; product: Product }) => {
    dispatch(editProductState({ groupId, product }));
    debouncedEditProduct({ groupId, product });
  };

  const editProduct = useCallback(
    async ({ groupId, product }: { groupId: string; product: Product }) => {
      await dispatch(
        editProductGroupThunk({
          id: groupId,
          editProduct: {
            id: product.id,
            quantity: product.quantity,
            productFields: product.productFields.map((field) => ({
              id: field.id,
              name: field.name,
              value: field.value ? field.value : undefined,
              populatedBy: field.populatedBy,
              isRequired: field.isRequired,
              type: field.type,
            })),
          },
        })
      );
    },
    [dispatch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedEditProduct = useCallback(
    debounce(({ groupId, product }: { groupId: string; product: Product }) => editProduct({ groupId, product }), 500),
    [editProduct]
  );

  const parseInputValue = (value: string | boolean, type: ProductField["type"]) => {
    if (type === "Number") return value === "" ? "" : Number(value);
    if (type === "Boolean") return Boolean(value);
    else return value;
  };

  return (
    <Tr backgroundColor={isHighlighted ? "smTertiarySoft" : ""}>
      {(onProductDrag || onSelect) && (
        <Td verticalAlign="top" p="1">
          <Flex columnGap={2} align="center" pl="2">
            {onSelect && (
              <Explanation text={t("Select to see connections to sourcing events")} shouldWrapChildren>
                <Checkbox
                  variant="ghost"
                  colorScheme="purple"
                  isChecked={isSelected}
                  onChange={(e) => {
                    if (e.target.checked) onSelect(product);
                    else onUnSelect?.(product);
                  }}
                />
              </Explanation>
            )}
            {onProductDrag && (
              <Explanation text={t("Drag to sourcing event")}>
                <IconButton
                  draggable
                  variant="ghost"
                  cursor={isDragging ? "grabbing" : "grab"}
                  aria-label="drag to event"
                  colorScheme="cyan"
                  size="xs"
                  icon={<Icon as={FaGripLinesVertical} />}
                  onDrag={() => {
                    onProductDrag(product);
                    setIsDragging(true);
                  }}
                  onDragEnd={(e) => {
                    e.preventDefault();
                    setIsDragging(false);
                    onProductDragEnd?.(product);
                  }}
                />
              </Explanation>
            )}
          </Flex>
        </Td>
      )}
      {product.productFields
        .filter((field) => field.populatedBy === "Buyer")
        .map((field) => (
          <Td key={field.name} verticalAlign={"top"} p="1">
            {field.type === "Boolean" ? (
              <Checkbox
                colorScheme="teal"
                isChecked={field.value as boolean}
                onChange={(e) =>
                  handleFieldChange({
                    id: field.id,
                    name: field.name,
                    type: field.type,
                    value: e.target.checked,
                    isRequired: field.isRequired,
                    populatedBy: field.populatedBy,
                  })
                }
              />
            ) : field.type === "Text" ? (
              <Textarea
                backgroundColor="smBackground"
                size="xs"
                rounded="md"
                width="full"
                minHeight={"6"}
                rows={1}
                pt="1"
                pb="0"
                isRequired={field.isRequired}
                value={(field.value as string | number) ?? ""}
                onChange={(e) =>
                  handleFieldChange({
                    id: field.id,
                    name: field.name,
                    type: field.type,
                    value: e.target.value,
                    isRequired: field.isRequired,
                    populatedBy: field.populatedBy,
                  })
                }
              />
            ) : (
              <Input
                backgroundColor="smBackground"
                size="xs"
                rounded="md"
                type={"number"}
                isRequired={field.isRequired}
                value={(field.value as string | number) ?? ""}
                onChange={(e) =>
                  handleFieldChange({
                    id: field.id,
                    name: field.name,
                    type: field.type,
                    value: e.target.value,
                    isRequired: field.isRequired,
                    populatedBy: field.populatedBy,
                  })
                }
              />
            )}
          </Td>
        ))}
      <Td verticalAlign={"top"} p="1">
        <Input
          backgroundColor="smBackground"
          size="xs"
          type={"number"}
          isRequired={product.quantity.isRequired}
          value={product.quantity.value ?? ""}
          rounded="md"
          onChange={(e) => handleQuantityChange(e.target.value)}
        />
      </Td>
      <Td textAlign={"center"} verticalAlign="top" p="1">
        <Input
          size="xs"
          minWidth={"24"}
          rounded="md"
          backgroundColor="smBackgroundSecondary"
          type="number"
          isDisabled
          value={product.unitPrice?.value ?? ""}
        />
      </Td>
      {product.productFields
        .filter((field) => field.populatedBy === "Supplier")
        .map((field) => (
          <Td key={field.name} verticalAlign="top" textAlign="center" p="1">
            {field.type === "Boolean" ? (
              <Checkbox
                colorScheme="teal"
                isDisabled
                backgroundColor="smBackgroundSecondary"
                variant=""
                isChecked={field.value as boolean}
                onChange={(e) =>
                  handleFieldChange({
                    id: field.id,
                    name: field.name,
                    type: field.type,
                    value: e.target.checked,
                    isRequired: field.isRequired,
                    populatedBy: field.populatedBy,
                  })
                }
              />
            ) : field.type === "Text" ? (
              <Textarea
                backgroundColor="smBackgroundSecondary"
                size="xs"
                rounded="md"
                width="full"
                minHeight={"6"}
                rows={1}
                pt="1"
                pb="0"
                isDisabled
                isRequired={field.isRequired}
                value={(field.value as string | number) ?? ""}
                onChange={(e) =>
                  handleFieldChange({
                    id: field.id,
                    name: field.name,
                    type: field.type,
                    value: e.target.value,
                    isRequired: field.isRequired,
                    populatedBy: field.populatedBy,
                  })
                }
              />
            ) : (
              <Input
                backgroundColor="smBackgroundSecondary"
                size="xs"
                rounded="md"
                type={"number"}
                variant="filled"
                isDisabled
                isRequired={field.isRequired}
                value={(field.value as string | number) ?? ""}
                onChange={(e) =>
                  handleFieldChange({
                    id: field.id,
                    name: field.name,
                    type: field.type,
                    value: e.target.value,
                    isRequired: field.isRequired,
                    populatedBy: field.populatedBy,
                  })
                }
              />
            )}
          </Td>
        ))}
      <Td width="20" textAlign={"right"} verticalAlign={"top"} pt="1" pr="2">
        <IconButton
          aria-label="Delete product"
          size="xs"
          width="6"
          variant="ghost"
          color="gray.400"
          icon={<Icon as={FaTrash} />}
          onClick={() => {
            if (product.id) {
              dispatch(
                editProductGroupThunk({
                  id: groupId,
                  deleteProduct: { productId: product.id },
                })
              );
            }
            dispatch(removeProduct({ groupId, productId: product.id }));
          }}
        />
      </Td>
    </Tr>
  );
};
