import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OrganizationType } from "../../../autogen/bff-api";
import { OrganizationSelector } from "../../organization/OrganizationSelector";

interface Props<T extends FieldValues> {
  label: string;
  helperText: string;
  control: Control<T>;
  name: FieldPath<T>;
  orgsToExclude?: string[];
  type?: OrganizationType;
}

export const OrganizationFormSelector = <T extends FieldValues>({
  control,
  helperText,
  name,
  label,
  type,
  orgsToExclude,
}: Props<T>) => {
  const { t } = useTranslation();
  return (
    <FormControl>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <OrganizationSelector
            type={type}
            placeholder={t("Select an organization") ?? ""}
            organization={value?.organization}
            orgsToExclude={orgsToExclude}
            onChange={(value) => {
              onChange(value.organization);
            }}
          />
        )}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
