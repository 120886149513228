import { Button, Icon, useToast } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useApiError } from '../../../common/errors/useApiError'
import { useConfirmationModal } from '../../../common/useConfirmationModal'
import {
    BidDto,
    useDeleteBidMutation,
} from '../../../autogen/bff-api'

interface Props {
    bid: BidDto
    refetch: () => void
}

export const DeleteDraftButton: React.FC<Props> = ({ bid, refetch }) => {
    const toast = useToast()
    const confirmationModal = useConfirmationModal()
    const [deleteBid] = useDeleteBidMutation()
    const { t } = useTranslation()
    const displayer = useApiError()

    return (
        <>
            {confirmationModal.modal}
            <Button
                leftIcon={<Icon as={FaTimes} w="15px" h="15px" />}
                variant={'solid'}
                colorScheme="red"
                mr="5px"
                size={'sm'}
                onClick={() => {
                    confirmationModal.show({
                        title: t('Confirm deletion'),
                        description: t(
                            'Are you sure you want to delete this draft?'
                        ),
                        onConfirmed: async () => {
                            const result = await deleteBid({
                                bidId: bid.id,
                            })

                            if ('data' in result) {
                                toast({
                                    title: t('Bid deleted'),
                                    description: t(
                                        'The Bid was successfully deleted'
                                    ),
                                    status: 'success',
                                })
                                refetch()
                            } else {
                                displayer.trigger(result.error)
                            }
                        },
                    })
                }}
            >
                {t('Delete')}
            </Button>
        </>
    )
}
