import { Box, Button, Flex, Icon, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaBuilding, FaLink, FaMapMarkerAlt, FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { OrgPublicProfileDto } from "../../../../autogen/bff-api";
import { displayOrganizationSize } from "../../../../common/formatting/displayOrganizationSize";
import { urls } from "../../../../urls";

interface Props {
  organization: OrgPublicProfileDto;
}

export const CompanyPitch = ({ organization }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Flex
      flexDirection={"column"}
      alignItems="start"
      justifyContent={"start"}
      borderRadius={"10px"}
      shadow="md"
      padding="6"
      width="full"
    >
      <Box w="100%">
        <Flex w="100%">
          <Flex w="60px" h="60px" rounded="md" border="1px solid" borderColor="smBorder" padding="0.5">
            <Box
              bg="smPrimary"
              rounded="md"
              width="full"
              height="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text fontWeight={"bold"} fontSize="xl" color="smBackground">
                {organization.name.charAt(0)}
              </Text>
            </Box>
          </Flex>
          <Flex flex={1} alignItems={"center"} justifyContent="end">
            <Button
              leftIcon={<Icon as={FaBuilding} w="15px" h="15px" />}
              variant={"outline"}
              colorScheme="teal"
              size={"sm"}
              onClick={() => navigate(urls.organizations.profile.go(organization.id))}
            >
              {t("View profile")}
            </Button>
          </Flex>
        </Flex>
        <Flex mt="7px">
          <Icon as={FaBuilding} w="15px" h="15px" mt="4px" color="smMuted" />
          <Text fontSize={"sm"} color="smMuted" ml="10px">
            {organization.name}
          </Text>
        </Flex>
        <Flex mt="7px">
          <Icon as={FaMapMarkerAlt} w="15px" h="15px" mt="4px" color="smMuted" />
          <Text fontSize={"sm"} color="smMuted" ml="10px">
            {organization.country.name}
          </Text>
        </Flex>
        {organization.size && (
          <Flex mt="6px">
            <Icon as={FaUsers} w="15px" h="15px" mt="3px" color="smMuted" />
            <Text fontSize={"sm"} color="smMuted" ml="10px">
              {displayOrganizationSize(organization.size)}
            </Text>
          </Flex>
        )}
        {organization.homePageUrl && (
          <Flex mt="5px">
            <Icon as={FaLink} w="13px" h="15px" mt="4px" ml="1px" color="smMuted" />
            <Text ml="11px" fontSize={"sm"}>
              <Link color="smPrimary" href={organization.homePageUrl} target={"_blank"}>
                {organization.homePageUrl.replace("https://", "")}
              </Link>
            </Text>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};
