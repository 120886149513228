import { Box, Flex, Input, Select } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SupplierInvitesForm } from "./Invites";

export const InvitedSupplierRow = () => {
  const { register } = useFormContext<SupplierInvitesForm>();
  const { t } = useTranslation();
  return (
    <Flex backgroundColor="smBackgroundSecondary" minH={"40px"} borderRadius={"5px"} alignItems="center" padding="10px">
      <Box flex={1}>
        <Input
          placeholder="Email"
          type={"email"}
          {...register(`email`, {
            required: {
              value: true,
              message: t("Please provide an email address"),
            },
            minLength: {
              value: 2,
              message: t("Please provide an email address"),
            },
          })}
        />
      </Box>
      <Box width="20px"></Box>
      <Box flex={1}>
        <Input
          placeholder={t("First name") ?? ""}
          {...register(`firstName`, {
            required: {
              value: true,
              message: t("Please provide a name for the company of the receiver"),
            },
            minLength: {
              value: 2,
              message: t("Please provide a name for the company of the receiver"),
            },
          })}
        />
      </Box>
      <Box width="20px"></Box>
      <Box flex={1}>
        <Input
          placeholder={t("Last name") ?? ""}
          {...register(`lastName`, {
            required: {
              value: true,
              message: t("Please provide a name for the company of the receiver"),
            },
            minLength: {
              value: 2,
              message: t("Please provide a name for the company of the receiver"),
            },
          })}
        />
      </Box>
      <Box width="20px"></Box>
      <Box flex={1}>
        <Input
          placeholder={t("Company name") ?? ""}
          {...register(`companyName`, {
            required: {
              value: true,
              message: t("Please provide a name for the company of the receiver"),
            },
            minLength: {
              value: 2,
              message: t("Please provide a name for the company of the receiver"),
            },
          })}
        />
      </Box>
      <Box width="20px"></Box>
      <Box flex={1}>
        <Select
          placeholder={t("Email language") ?? ""}
          ml="3px"
          {...register(`language`, {
            required: {
              value: true,
              message: t("Please select a language"),
            },
          })}
        >
          <option key={"En"} value={"En"}>
            {t("English")}
          </option>
          <option key={"No"} value={"No"}>
            {t("Norwegian")}
          </option>
        </Select>
      </Box>
      <Box width="20px"></Box>
    </Flex>
  );
};
