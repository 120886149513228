
import {
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface FormValues {
    name: string
}

export const Name: React.FC = () => {
    const { t } = useTranslation()
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useFormContext<FormValues>()

    return (
        <FormControl isInvalid={errors.name !== undefined} mt="10px">
            <FormLabel htmlFor={'name'}>{t('Company name')}</FormLabel>
            <Input
                id={'name'}
                type="name"
                {...register('name', {
                    required: t('Please provide a company name') ?? '',
                    minLength: {
                        value: 2,
                        message: t('Must be at least 2 characters long'),
                    },
                })}
            />
            <FormErrorMessage>
                {errors.name && errors.name.message}
            </FormErrorMessage>
            <FormHelperText>
                {t('Please provide the name of the company')}
            </FormHelperText>
        </FormControl>
    )
}