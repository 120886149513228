import {
    Box,
    CircularProgress,
    CircularProgressLabel,
    Flex,
    Text,
} from '@chakra-ui/react'
import React from 'react'

interface Props {
    color: string
    name: string
    score: number
    size: string
}

export const EsgValue: React.FC<Props> = ({ color, name, score, size }) => {
    return (
        <Flex flexDirection={'column'}>
            <Flex>
                <Flex alignItems={'center'}>
                    <Box
                        w="12px"
                        h="12px"
                        borderRadius={'50%'}
                        bg={color}
                    ></Box>
                </Flex>
                <Flex ml="5px">
                    <Text fontSize={'sm'} fontWeight="light" color="#a4a7a6">
                        {name}
                    </Text>
                </Flex>
            </Flex>
            <Flex mt="15px">
                <CircularProgress
                    value={score}
                    size={size}
                    color={color}
                    pl="-100px"
                >
                    <CircularProgressLabel fontSize={'lg'}>
                        {score}%
                    </CircularProgressLabel>
                </CircularProgress>
            </Flex>
        </Flex>
    )
}
