import { Grid, GridItem, Spinner, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MinimalOrgDto } from "../../../autogen/bff-api";
import { urls } from "../../../urls";
import { LinkWithExistingOrgModal } from "../entry/view/LinkWithExistingOrgModal";
import { OrganizationEntryModal } from "../entry/view/OrganizationEntryModal";
import { SearchResult } from "./SearchResult";
import styles from "./SearchResults.module.css";

interface Props {
  organizations?: MinimalOrgDto[];
  isSearching: boolean;
  count?: number;
}

export const SearchResults = ({ organizations, isSearching, count }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [organizationEntryToShow, setOrganizationEntryToShow] = useState<string>();
  const [organizationEntryToLink, setOrganizationEntryToLink] = useState<string>();

  return (
    <>
      {organizationEntryToShow && (
        <OrganizationEntryModal
          entryId={organizationEntryToShow}
          onClose={() => setOrganizationEntryToShow(undefined)}
          linkWithExistingOrg={() => {
            setOrganizationEntryToShow(undefined);
            setOrganizationEntryToLink(organizationEntryToShow);
          }}
        />
      )}
      {organizationEntryToLink && (
        <LinkWithExistingOrgModal
          entryId={organizationEntryToLink}
          onClose={() => {
            setOrganizationEntryToLink(undefined);
          }}
        />
      )}
      <Grid gridTemplateColumns={"1fr"} gridTemplateRows="minmax(50px, 1fr)" gridAutoRows={"minmax(100px, auto)"}>
        <GridItem display="flex" alignItems={"center"}>
          <Text fontSize="md" marginLeft={4}>
            {organizations && (
              <>
                <b>{count}</b> {t("companies found")}{" "}
              </>
            )}
          </Text>
          {isSearching && <Spinner ml="8px" size={"xs"} />}
        </GridItem>

        {organizations ? (
          organizations.map((o) => (
            <GridItem
              key={o.id}
              className={styles.result}
              py="5"
              onClick={() => {
                switch (o.type) {
                  case "Organization":
                    navigate(urls.organizations.profile.go(o.id));
                    break;
                  case "OrganizationEntry":
                    setOrganizationEntryToShow(o.id);
                    break;
                }
              }}
            >
              <SearchResult organization={o} />
            </GridItem>
          ))
        ) : (
          <div>{t("Loading...")}</div>
        )}
      </Grid>
    </>
  );
};
