import { useAppSelector } from "../redux/hooks";

export const useLoggedInWithOrgContextState = () => {
  const authState = useAppSelector((state) => state.authState.state);

  if (!authState || authState.type !== "LoggedInWithOrgContext") {
    throw Error('"useLoggedInWithOrgContextState" can only be used in LoggedInWithOrgContext state');
  }

  return authState;
};
