import { Box, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { CountryDto } from "../../../autogen/bff-api";

interface Props {
  name: string;
  country: CountryDto;
}

export const Banner = ({ name, country }: Props) => {
  return (
    <Flex w="full">
      <Box w="120px" h="120px" borderRadius={"5px"} border="1px solid" borderColor="smBorder" padding={"5px"}>
        <Box
          bg="smPrimary"
          borderRadius={"5px"}
          w="100%"
          h="100%"
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Text fontWeight={"bold"} fontSize="4xl" color="smBackground">
            {name.charAt(0)}
          </Text>
        </Box>
      </Box>
      <Flex flex={1} alignItems={"center"}>
        <Flex ml="20px" w="100%">
          <Flex flexDirection={"column"} flex={1}>
            <Heading fontSize={"2xl"}>{name}</Heading>
            <Flex>
              <Icon as={FaMapMarkerAlt} w="10px" h="15px" mt="4px" color="smMuted" />
              <Text ml="9px" fontSize={"md"} color="smMuted">
                {country.name}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
