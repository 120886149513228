import { Box, Flex, Grid, GridItem, Heading, Icon, IconButton, Spacer, Tag, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { FaArrowRight, FaNetworkWired } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useGetProjectsQuery } from "../../../autogen/bff-api";
import { useSkeletons } from "../../../common/useSkeletons";
import { urls } from "../../../urls";
import { useProjectStatuses } from "../../projects/useProjectStatuses";

export const LatestProjects = () => {
  const navigate = useNavigate();
  const skeletons = useSkeletons();

  const projectStatuses = useProjectStatuses();

  const { data, isLoading } = useGetProjectsQuery({ limit: 5 });

  return (
    <Box
      flexDirection="column"
      alignItems="start"
      justifyContent="start"
      borderRadius="10"
      boxShadow="0 3px 15px rgba(71, 85, 105, 0.16)"
      p="5"
      mt="15px"
      backgroundColor="smBackground"
    >
      <Grid
        gridTemplateColumns="50px 90px"
        gridTemplateAreas={`
                "icon title title"
                "unused1 projects projects"
                `}
      >
        <GridItem area="icon">
          <Box
            bg="smPrimarySoft"
            h="50px"
            w="50px"
            borderRadius={"50%"}
            display="flex"
            alignItems={"center"}
            justifyContent="center"
          >
            <Icon as={FaNetworkWired} width="18px" height="18px" color="smBackground" />
          </Box>
        </GridItem>
        <GridItem area="title" display="flex">
          <Heading as="h2" fontSize="3xl" fontWeight={"semibold"} ml="10px" mt="7px">
            {t("Projects")}
          </Heading>
          <Spacer />
        </GridItem>
        <GridItem area="projects">
          {!data && isLoading && skeletons.stackedLines(10, 5)}
          {data?.projects.length === 0 && (
            <Text fontSize={"md"} ml="10px" color="smMuted" fontStyle={"italic"} pt="2.5">
              {t("You have no projects")}...
            </Text>
          )}
          {data?.projects.map((project) => (
            <Flex
              key={project.id}
              w="full"
              borderRadius="6"
              mt="15px"
              p="2.5"
              backgroundColor="smBackgroundSecondary"
              cursor="pointer"
              _hover={{ backgroundColor: "rgba(49, 151, 149, 0.08)" }}
              onClick={() => {
                navigate(urls.projects.view.go(project.id));
              }}
            >
              <Grid
                width="full"
                key={project.id}
                gridTemplateAreas={`
                    "title title status"
                    "sub unresolved status"
            `}
                gridTemplateColumns={"1fr minmax(100px, max-content) 10rem"}
              >
                <GridItem area="title">
                  <Flex flex={3} alignItems={"center"} pl="2.5">
                    <Text fontSize="md" color="smMuted">
                      {project.name}
                    </Text>
                  </Flex>
                </GridItem>
                <GridItem area={"sub"}>
                  {project.projectResponsible && (
                    <Flex flex={3} alignItems={"center"} pl="2.5">
                      <Text fontSize="sm" fontWeight="light" color="smMuted">{`${
                        project.projectResponsible.firstName ?? ""
                      } ${project.projectResponsible.lastName ?? ""}`}</Text>
                    </Flex>
                  )}
                </GridItem>
                <GridItem
                  area="status"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="end"
                  rowGap={1}
                >
                  <Tag variant="outline" colorScheme={projectStatuses[project.status].colorScheme}>
                    {projectStatuses[project.status].name}
                  </Tag>
                  {project.projectType && <Tag variant="subtle">{t(project.projectType.name)}</Tag>}
                </GridItem>
              </Grid>
            </Flex>
          ))}
          {data && (
            <Flex pt="4">
              <Spacer />
              <IconButton
                aria-label="go to projects"
                icon={<Icon as={FaArrowRight} />}
                variant={"link"}
                colorScheme="cyan"
                float="right"
                onClick={() => {
                  navigate(urls.projects.viewAll);
                }}
              />
            </Flex>
          )}
        </GridItem>
      </Grid>
    </Box>
  );
};
