import { t } from "i18next";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { MinimalOrgDto, OrganizationType, useSearchOrganizationsAndEntriesQuery } from "../../autogen/bff-api";
import { SelectorValue } from "../input/Selector/SelectorValue";
import { SingleSelector } from "../input/Selector/SingleSelector";

export const OrganizationSelector = ({
  organization,
  onChange,
  size = "sm",
  placeholder,
  type,
  orgsToExclude,
}: {
  organization?: MinimalOrgDto;
  onChange: ({ organization }: { organization?: MinimalOrgDto }) => void | Promise<void>;
  size?: "sm" | "md";
  placeholder?: string;
  type?: OrganizationType;
  orgsToExclude?: string[];
}) => {
  const [searchName, setSearchName] = useState("");
  const [debouncedSearchName, setDebouncedSearchName] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchName = useCallback(
    debounce((name: string) => setDebouncedSearchName(name), 300),
    []
  );

  const { data, isFetching } = useSearchOrganizationsAndEntriesQuery({ name: debouncedSearchName, type, limit: 10 });

  return (
    <SingleSelector
      size={size}
      placeholder={{ text: placeholder ?? `${t("Select organization")}...`, color: "" }}
      value={
        organization
          ? {
              label: `${organization.name}${
                organization.organizationNumber ? ` (${organization.organizationNumber})` : ""
              }`,
              value: organization.id,
            }
          : null
      }
      inputValue={searchName}
      isLoading={isFetching}
      onInputChange={(name: string) => {
        setSearchName(name);
        handleDebouncedSearchName(name);
      }}
      options={
        data?.organizations
          .filter((o) => !orgsToExclude?.includes(o.id))
          .map((o) => ({
            label: `${o.name} ${o.organizationNumber ? `(${o.organizationNumber})` : ""}`,
            value: o.id,
          })) ?? []
      }
      onChange={(value: SelectorValue | null) => {
        if (!value) {
          onChange({});
          return;
        }
        const organization = data?.organizations?.find((o) => value.value === o.id);
        onChange({ organization });
      }}
      searchValues={false}
    />
  );
};
