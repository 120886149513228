import { Badge, Flex, Grid, GridItem, Icon, Skeleton, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAward, FaBullhorn, FaDollarSign, FaHandshake, FaInfo, FaQuestion } from "react-icons/fa";
import { useParams } from "react-router-dom";
import {
  BseQuestionDto,
  BseQuestionStatus,
  BseStatusDto,
  bffApi,
  useGetBasicSourcingEventQuery,
} from "../../../../autogen/bff-api";
import { ProcessBar } from "../../../../common/ProcessBar";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { isFetchBaseQueryError } from "../../../../common/errors/isFetchBaseQueryError";
import { useApiError } from "../../../../common/errors/useApiError";
import { Layout } from "../../../../common/layout/Layout";
import { useAppDispatch, useAppSelector } from "../../../../common/redux/hooks";
import { BasicSourcingEventState, setSourcingEvent } from "../../../../common/redux/reducers/basicSourcingEventReducer";
import { useSub } from "../../../../common/subscription/useSub";
import { BseTab, useQueryParams } from "../../../../common/useQueryParams";
import { CONTENT_WIDTH } from "../../../../config";
import { Award } from "./Award";
import { CreateContracts } from "./CreateContracts";
import { BseEventInfo } from "./EventInfo";
import { EventMeta } from "./EventMeta";
import { Announcements } from "./announcements/Announcements";
import { AnnouncementData } from "./announcements/NewAnnouncementModal";
import { BidEvalutation } from "./bid-evaluation/BidEvaluation";
import { SupplierQuestions } from "./questions/SupplierQuestions";

const tabs = ["info", "qa", "announcements", "evaluation", "reports"].filter((tab) => !!tab) as BseTab[];

const getCurrentStep = (state: BasicSourcingEventState, stateToStepMap: { [state in BseStatusDto]: string }) =>
  stateToStepMap[state.event.status];

export const BseView = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const authState = useLoggedInWithOrgContextState();
  const params = useQueryParams();
  const tab = params.get("tab");
  const subscriptions = useSub();
  const displayer = useApiError();
  const eventState = useAppSelector((state) => state.basicSourcingEvent.state);
  const { eventId } = useParams();

  const [initialAnnouncementValues, setInitialAnnouncementValues] = useState<AnnouncementData | null>(null);
  const {
    data: eventDto,
    error,
    refetch,
    isLoading,
  } = useGetBasicSourcingEventQuery(eventId ? { eventId } : skipToken);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (eventState?.id !== eventId) {
      if (eventDto) dispatch(setSourcingEvent(eventDto));
      else dispatch(setSourcingEvent(null));
    }
  }, [eventDto, dispatch, eventState, eventId]);

  const stateToStepMap = useMemo(() => {
    const stateToStepMap: { [state in BseStatusDto]: string } = {
      Draft: t("Create event"),
      Published: t("Receive bids"),
      DeadlineHasPassed: subscriptions.hasProductAccess ? t("Evaluate bids") : t("Award winners"),
      Completed: t("Award winners"),
      Awarded: t("Event completed"),
    };
    return stateToStepMap;
  }, [subscriptions.hasProductAccess, t]);

  useEffect(() => {
    const index = tabs.indexOf(tab as BseTab);
    if (tab && index > -1) setTabIndex(index);
  }, [tab]);

  useEffect(() => {
    return () => {
      dispatch(setSourcingEvent(null));
    };
  }, [dispatch]);

  if (error) {
    if (isFetchBaseQueryError(error) && error.status === 403) return displayer.showNoAccess();
    return displayer.show(error);
  }

  const unresolvedQuestionsCount = eventState?.event.questions?.filter((e) => e.status === "Unresolved").length ?? 0;

  return (
    <Layout>
      <Grid
        height="100%"
        gap={1}
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"1fr"}
        templateAreas={`"leftContent content rightContent"`}
      >
        <GridItem area={"content"}>
          <Grid
            w="100%"
            gridTemplateColumns={"3fr 4fr 1fr"}
            gridTemplateRows="minmax(50px, 1fr) auto auto auto"
            templateAreas={`
                        "processBar processBar processBar"
                        "title title edit"
                        "createdMeta createdMeta edit"
                        "content content content"
                        `}
          >
            {eventState && eventId === eventState?.id && (
              <>
                <GridItem area="processBar">
                  <ProcessBar
                    steps={Array.from(new Set(Object.values(stateToStepMap)))}
                    currentStep={getCurrentStep(eventState, stateToStepMap)}
                  />
                </GridItem>
                <EventMeta data={eventState.event} />
                <GridItem area={"content"} display={"flex"} flexDirection={"column"}>
                  <Tabs
                    colorScheme="teal"
                    mt="25px"
                    width={"100%"}
                    index={tabIndex}
                    onChange={(index) => {
                      setTabIndex(index);
                      params.setSelectedBseTab(indexToQueryValue(index));
                    }}
                    variant="line"
                  >
                    <TabList justifyContent={"start"} mx="2">
                      <Tab>
                        <Icon as={FaInfo} mr="1" />
                        {t("Bids")}
                      </Tab>
                      <Tab>
                        <Icon as={FaQuestion} mr="1" />
                        {t("Supplier Questions")}
                        {unresolvedQuestionsCount > 0 && (
                          <Badge colorScheme="red" ml="6px">
                            {unresolvedQuestionsCount} {t("unresolved!")}
                          </Badge>
                        )}
                      </Tab>
                      <Tab>
                        <Icon as={FaBullhorn} mr="1" />
                        {t("Announcements")}
                      </Tab>
                      {subscriptions.hasProductAccess && (
                        <Tab
                          isDisabled={
                            !authState.selectedOrg.canSeeDeliveredBidsBeforeDeadline &&
                            (eventState.event.status === "Draft" || eventState.event.status === "Published")
                          }
                        >
                          <Icon as={FaDollarSign} mr="1" />
                          {t("Evaluation")}
                        </Tab>
                      )}
                      <Tab isDisabled={eventState.event.status === "Draft" || eventState.event.status === "Published"}>
                        <Icon as={FaAward} mr="1" />
                        {t("Award")}
                      </Tab>
                      <Tab isDisabled={eventState.event.status !== "Awarded"}>
                        <Icon as={FaHandshake} mr="1" />
                        {t("Contract")}
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <BseEventInfo data={eventState.event} reloadEvent={refetch} />
                      </TabPanel>
                      <TabPanel>
                        <SupplierQuestions
                          canManageQuestions={eventState.event.canEdit}
                          addComment={async (values: {
                            comment: string;
                            question: BseQuestionDto;
                            createAnnouncementDraft: boolean;
                          }) => {
                            const response = dispatch(
                              bffApi.endpoints.createBseQuestionCommentAsBuyer.initiate({
                                eventId: eventState.event.id,
                                questionId: values.question.id,
                                createBseQuestionCommentRequest: {
                                  content: values.comment,
                                },
                              })
                            );
                            response.reset();
                            const result = await response;
                            if ("data" in result) {
                              if (values.createAnnouncementDraft) {
                                setInitialAnnouncementValues({
                                  title: values.question.subject,
                                  content: values.comment,
                                });
                                params.setCreateAnnouncement();
                              }
                              dispatch(setSourcingEvent(result.data));
                              return true;
                            } else return false;
                          }}
                          changeStatus={async (status: BseQuestionStatus, questionId: string) => {
                            const response = dispatch(
                              bffApi.endpoints.updateBseQuestion.initiate({
                                eventId: eventState.event.id,
                                questionId: questionId,
                                updateBseQuestionRequest: {
                                  status: status,
                                },
                              })
                            );
                            response.reset();
                            const result = await response;
                            if ("data" in result) {
                              return true;
                            } else {
                              return false;
                            }
                          }}
                          questions={eventState.event.questions ?? []}
                          announcements={eventState.event.announcements}
                        />
                      </TabPanel>
                      <TabPanel>
                        <Announcements
                          canCreateAnnouncement={eventState.event.canEdit}
                          announcements={eventState.event.announcements}
                          initialAnnouncementValues={initialAnnouncementValues}
                          createAnnouncement={async (values: { title: string; content: string }) => {
                            const response = dispatch(
                              bffApi.endpoints.createBseAnnouncement.initiate({
                                eventId: eventState.event.id,
                                createBseAnnouncementRequest: {
                                  title: values.title,
                                  content: values.content,
                                },
                              })
                            );
                            response.reset();
                            const result = await response;
                            if ("data" in result) {
                              setInitialAnnouncementValues(null);
                              dispatch(setSourcingEvent(result.data));
                              return true;
                            } else {
                              return false;
                            }
                          }}
                        />
                      </TabPanel>
                      {subscriptions.hasProductAccess && (
                        <TabPanel>
                          <BidEvalutation sourcingEvent={eventState.event} />
                        </TabPanel>
                      )}
                      <TabPanel>
                        <Award sourcingEvent={eventState.event} />
                      </TabPanel>
                      <TabPanel>
                        <CreateContracts sourcingEvent={eventState.event} />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </GridItem>
              </>
            )}
            {isLoading && (
              <>
                <GridItem area="processBar">
                  <ProcessBar steps={Array.from(new Set(Object.values(stateToStepMap)))} currentStep={""} />
                </GridItem>
                <GridItem area={"title"} display={"flex"} flexDirection={"column"} px="2" pt="2">
                  <Flex flexDirection={"column"} rowGap={"2"} justifyContent={"space-between"}>
                    <Skeleton
                      width={"60"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"60"}
                      height="6"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                  </Flex>
                </GridItem>
                <GridItem
                  area="edit"
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems="end"
                  justifyContent={"space-between"}
                  height="full"
                  pt="2.5"
                  pl="2"
                  pr="6"
                >
                  <Flex flexDirection={"column"} width={"full"} rowGap={2}>
                    <Skeleton
                      width={"full"}
                      height={"8"}
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"full"}
                      height={"8"}
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                  </Flex>
                  <Skeleton
                    width={"full"}
                    height={"8"}
                    rounded="md"
                    startColor="smBackgroundSecondary"
                    endColor="smBackgroundTertiary"
                  />
                </GridItem>
                <GridItem area={"createdMeta"} display={"flex"} flexDirection={"column"} px="2" pt="2">
                  <Flex flexDirection={"column"} rowGap={"2"} justifyContent={"space-between"}>
                    <Skeleton
                      width={"60"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"60"}
                      height="6"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                  </Flex>
                </GridItem>
                <GridItem area={"content"} display={"flex"} flexDirection={"column"} px="2" pt="8">
                  <Flex columnGap={"2"} pt="2">
                    <Skeleton
                      width={"32"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"32"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"32"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"32"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"32"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                  </Flex>
                  <Flex flexDirection={"column"} rowGap={"4"} pt="2">
                    <Skeleton
                      width={"full"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"80%"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"20%"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"20%"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"60%"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                  </Flex>
                </GridItem>
              </>
            )}
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};

const indexToQueryValue = (index: number): BseTab => {
  return tabs[index];
};
