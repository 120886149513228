import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SignedDocumentDto } from "../../autogen/bff-api";
import { SignedDocumentStatusTag } from "../../pages/contracts/edit/docs/signatures/SignedDocumentStatusTag";
import { displayDateWithTime } from "../formatting/displayDateWithTime";
import { DownloadDocumentIconButton } from "./DownloadDocumentIconButton/DownloadDocumentIconButton";

interface Props {
  documents: SignedDocumentDto[];
}

export const SignedDocumentsTable = ({ documents }: Props) => {
  const { t } = useTranslation();
  return (
    <Box borderRadius={"md"} border="1px solid" borderColor="smBorder" pb="2.5">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>{t("Name")}</Th>
            <Th>{t("Upload date")}</Th>
            <Th>{t("Status")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {documents.map((e) => {
            return (
              <Tr key={e.id}>
                <Td>{e.name}</Td>
                <Td>{displayDateWithTime(e.uploadedAt, t("at"))}</Td>
                <Td>
                  <SignedDocumentStatusTag status={e.status} />
                </Td>
                <Td>
                  {e.status === "Signed" && (
                    <Flex justifyContent="end">
                      <DownloadDocumentIconButton documentId={e.id} isSignedDocument={true} />
                    </Flex>
                  )}
                </Td>
              </Tr>
            );
          })}
          {documents.length === 0 && (
            <Tr>
              <Td colSpan={3} width={"100%"} textAlign="center" fontSize={"sm"}>
                {t("No documents have been signed")}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};
