import { Box, Checkbox, Flex, FormControl, FormErrorMessage, FormHelperText } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateSelector } from "../../../../../../common/input/DateTimeSelectors/DateSelector";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../../useContractState";
import { DatesContext } from "../../Details";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const ExpirationDate = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const contractState = useContractState();
  const errorMessage = containsError(contractState, "EditExpirationDate") ? t("Update failed") : null;
  const [hasNoExpirationDate, setHasNoExpirationDate] = useState<boolean>(
    () => contractState.contract.dataFields.endDate?.hasNoEndDate ?? false
  );

  const { expirationDate, setExpirationDate } = useContext(DatesContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDateChange = useCallback(
    debounce(async (date) => {
      await dispatch(
        editContractThunk({ command: { type: "EditExpirationDate", value: { date, hasNoExpirationDate } } })
      );
    }, 300),
    []
  );

  return (
    <FormControl id={"expirationDate"} isInvalid={errorMessage !== null} isRequired={isRequiredButNotProvided}>
      <Box fontWeight="medium" fontSize="sm" pb="2">
        {t("End date")}
      </Box>

      <Flex flexDirection={"row"} justifyContent={"space-between"}>
        <Flex>
          <Flex width="300px">
            <DateSelector
              isDisabled={hasNoExpirationDate}
              date={hasNoExpirationDate ? null : expirationDate}
              onChange={(date) => {
                setExpirationDate(date ?? null);
                handleDateChange(date);
              }}
            />
          </Flex>
          <Checkbox
            ml="10px"
            isChecked={hasNoExpirationDate}
            onChange={async (e) => {
              const isChecked = e.target.checked;
              setHasNoExpirationDate(isChecked);
              if (isChecked) setExpirationDate(null);
              await dispatch(
                editContractThunk({
                  command: {
                    type: "EditExpirationDate",
                    value: {
                      date: isChecked ? undefined : expirationDate ?? undefined,
                      hasNoExpirationDate: isChecked,
                    },
                  },
                })
              );
            }}
          >
            {t("Contract has no end date")}
          </Checkbox>
        </Flex>
        <RemoveDataFieldButton removeDataField={removeDataField} />
      </Flex>
      <FormHelperText>{t("Provide an end date")}</FormHelperText>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
