import { useMemo } from "react";
import { ReceivedBseBidDto } from "../../../../../autogen/bff-api";
import { findProductGroupBid } from "./BidEvaluationUtils";

export const useProductBid = ({
  productId,
  groupId,
  bid,
}: {
  productId?: string;
  groupId: string;
  bid?: ReceivedBseBidDto;
}) => {
  return useMemo(() => {
    if (!productId) return undefined;
    return findProductGroupBid({ groupId, bid })?.productBids.find((productBid) => productBid.productId === productId);
  }, [bid, groupId, productId]);
};
