import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  Spinner,
} from "@chakra-ui/react";
import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { editBseThunk } from "../../../../../../common/redux/thunks/basic-sourcing-event/edit-bse-thunk";
import { eventIsLive } from "../../../../eventIsLive";
import { useBasicSourcingEventState } from "../../../../useBasicSourcingEventState";

export const ReferenceId: React.FC = () => {
  const dispatch = useAppDispatch();
  const eventState = useBasicSourcingEventState();
  const errorMessage = eventState.referenceId.errorMessage;
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setValue(eventState.referenceId.value ?? null);
  }, []);

  useEffect(() => {
    return () => {
      debouncedEventHandler.cancel();
    };
  }, []);

  const eventHandler = async (value: string) => {
    setIsLoading(true);
    await dispatch(
      editBseThunk({
        command: {
          type: "EditReferenceId",
          value: value.trim().length === 0 ? null : value.trim(),
        },
      })
    );
    setIsLoading(false);
  };

  const debouncedEventHandler = useMemo(
    () =>
      debounce(async (value: string) => {
        await eventHandler(value);
      }, 300),
    []
  );

  const eventIsPublished = eventIsLive(eventState.event);
  const valueHasBeenChanged = value !== eventState.event.referenceId;
  const showSaveButton = eventIsPublished && valueHasBeenChanged;

  return (
    <FormControl isInvalid={errorMessage !== null}>
      <FormLabel htmlFor={"referenceId"}>
        {t("Internal reference")} ({t("optional")}) {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Input
        id={"referenceId"}
        type="text"
        value={value ?? ""}
        onChange={(e) => {
          if (e.target.value.trim().length === 0) {
            setValue(null);
          } else {
            setValue(e.target.value);
          }
          if (!eventIsPublished) {
            debouncedEventHandler(e.target.value);
          }
        }}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
      <FormHelperText>{t("The internal reference number used by your company")}</FormHelperText>
      {showSaveButton && (
        <Button
          mt="10px"
          leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
          variant={"solid"}
          colorScheme="teal"
          size={"sm"}
          isLoading={isLoading}
          onClick={() => eventHandler(value ?? "")}
        >
          {t("Save")}
        </Button>
      )}
    </FormControl>
  );
};
