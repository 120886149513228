import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { ContractTaskDto, bffApi } from "../../../../../autogen/bff-api";
import { useApiError } from "../../../../../common/errors/useApiError";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { ChangeList } from "./changes/ChangeList";
import { Comments } from "./comments/Comments";
import { Description } from "./description/Description";
import { DueDate } from "./due-date/DueDate";
import { ResponsiblePerson } from "./responsible-person/ResponsiblePerson";
import { Status } from "./status/Status";
import { Title } from "./title/Title";

interface Props {
  taskId: string;
  onClose: () => void;
}

export const ShowTaskModal = ({ taskId, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const displayer = useApiError();
  const [task, setTask] = useState<ContractTaskDto | undefined>();

  const fetch = useCallback(async (): Promise<ContractTaskDto | undefined> => {
    return dispatch(bffApi.endpoints.getTask.initiate({ taskId }))
      .unwrap()
      .catch((e) => {
        displayer.trigger(e);
        return undefined;
      });
  }, [dispatch, displayer, taskId]);

  useEffect(() => {
    const load = async () => {
      const value = await fetch();
      setTask(value);
    };
    load();
  }, [fetch]);

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        {task ? (
          <>
            <ModalHeader>
              <Status task={task} onEditComleted={(e) => setTask(e)} />
              <Title task={task} onEditComleted={(e) => setTask(e)} />
              <DueDate task={task} onEditComleted={(e) => setTask(e)} />
            </ModalHeader>
            <ModalBody>
              <Flex
                width="full"
                backgroundColor="smBackgroundSecondary"
                borderRadius={"7px"}
                boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
                flexDirection={"column"}
                padding="10px"
              >
                <Description task={task} onEditComleted={(e) => setTask(e)} />
                <ResponsiblePerson task={task} onEditComleted={(e) => setTask(e)} />
              </Flex>
              <Flex mt="20px" ml="5px" w="100%">
                <Tabs size="sm" variant="enclosed" w="100%">
                  <TabList>
                    <Tab>{t("Comments")}</Tab>
                    <Tab>{t("Change log")}</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel padding="0px" paddingBottom={"20px"}>
                      <Comments taskId={task.id} />
                    </TabPanel>
                    <TabPanel>
                      <ChangeList taskId={task.id} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
            </ModalBody>
          </>
        ) : (
          <Flex padding={"20px"}>{t("Loading...")}</Flex>
        )}
      </ModalContent>
    </Modal>
  );
};
