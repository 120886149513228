import { Grid, GridItem, Icon, Spacer, Text } from "@chakra-ui/react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MinimalOrgDto } from "../../../autogen/bff-api";

interface Props {
  organization: MinimalOrgDto;
}

export const SearchResultContent = ({ organization }: Props) => {
  return (
    <Grid gridTemplateColumns={"1fr"} gridTemplateRows="20px minmax(30px, auto)" ml="10px">
      <GridItem display="flex" mt="1px">
        <Icon as={FaMapMarkerAlt} w="10px" h="15px" color="smMuted" ml="4px" />
        <Text color="smMuted" fontSize="sm" ml={1} mt="-3px">
          {organization.country.name}
        </Text>
      </GridItem>
      <GridItem display={"flex"}>
        <Text fontSize="lg" ml={1} fontWeight="medium">
          {organization.name}
        </Text>
        <Spacer />
      </GridItem>
    </Grid>
  );
};
