import { Box } from "@chakra-ui/react";
import { InputActionMeta, Select, useChakraSelectProps } from "chakra-react-select";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectorOptions, SelectorValue } from "./SelectorValue";

interface Props {
  value: SelectorValue[];
  options: SelectorOptions;
  noOptionsAvailableMessage: string;
  onChange: (values: SelectorValue[]) => void;
  inputValue?: string;
  onInputChange?: ((newValue: string, actionMeta: InputActionMeta) => void) | undefined;
  isLoading?: boolean;
  placeholder?: {
    text: string;
    color: string;
  };
  searchValues?: boolean;
  size?: "sm" | "md";
}

export const MultiSelector = ({
  value,
  options,
  noOptionsAvailableMessage,
  onChange,
  inputValue,
  onInputChange,
  isLoading,
  placeholder,
  searchValues = true,
  size = "md",
}: Props) => {
  const [selectedValues, setSelectedValues] = useState<SelectorValue[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  const getNoOptionsMessage = () => {
    if (options.length === 0) return <Box>{noOptionsAvailableMessage}</Box>;
    else return <Box>{t("No values found")}</Box>;
  };

  return (
    <Select
      isLoading={isLoading}
      {...useChakraSelectProps({
        isMulti: true,
        size,
        value: selectedValues,
        onChange: (e) => {
          const values = e.map((e) => ({
            value: e.value,
            label: e.label,
          }));
          setSelectedValues(values);
          onChange(values);
        },
        isClearable: false,
        selectedOptionStyle: "check",
        selectedOptionColor: "blue",
        placeholder: placeholder ? <Box color={placeholder.color}>{placeholder.text}</Box> : <Box></Box>,
        noOptionsMessage: getNoOptionsMessage,
      })}
      inputValue={inputValue}
      onInputChange={onInputChange}
      filterOption={customFilterOption({ disableFrontendFiltering: !!onInputChange, searchLabelsOnly: !searchValues })}
      options={options}
      chakraStyles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: "smBackground",
          rounded: "md",
        }),
        container: (provided) => ({
          ...provided,
          width: "full",
        }),
        menuList: (provided) => ({
          ...provided,
          rounded: "md",
        }),
      }}
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  );
};

const customFilterOption = ({
  disableFrontendFiltering,
  searchLabelsOnly,
}: {
  disableFrontendFiltering: boolean;
  searchLabelsOnly: boolean;
}) => {
  if (disableFrontendFiltering) return () => true;
  if (searchLabelsOnly) return filterLabels;
  return undefined;
};

const filterLabels = (option: SelectorValue, inputValue: string) => {
  return option.label.toLowerCase().includes(inputValue.toLowerCase());
};
