import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { Select, useChakraSelectProps } from "chakra-react-select";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchOrganizationsQuery } from "../../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../../common/auth/useLoggedInWithOrgContextState";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { editBseThunk } from "../../../../../common/redux/thunks/basic-sourcing-event/edit-bse-thunk";
import { useConfirmationModal } from "../../../../../common/useConfirmationModal";
import { eventIsLive } from "../../../eventIsLive";
import { useBasicSourcingEventState } from "../../../useBasicSourcingEventState";
import styles from "./AddOrganizationInvites.module.css";
import { OrganizationInvitesTable } from "./OrganizationInvitesTable";

export const AddOrganizationInvites = () => {
  const dispatch = useAppDispatch();
  const authState = useLoggedInWithOrgContextState();
  const eventState = useBasicSourcingEventState();
  const value = eventState.organizationInvites.value;
  const errorMessage = eventState.organizationInvites.errorMessage;
  const [isLoading, setIsLoading] = useState(false);
  const confirmationModal = useConfirmationModal();
  const { t } = useTranslation();
  const [selectedOrganizations, setSelectedOrganizations] = useState<
    {
      value: string;
      label: string;
      email: string;
    }[]
  >([]);
  const { data } = useSearchOrganizationsQuery({
    industries: "",
  });
  const selectProps = useChakraSelectProps({
    isMulti: true,
    value: selectedOrganizations,
    onChange: (newValues) => {
      setSelectedOrganizations(
        newValues.map((e) => ({
          value: e.value,
          label: e.label,
          email: e.email,
        }))
      );
    },
    selectedOptionStyle: "check",
    selectedOptionColor: "blue",
  });

  const add = async (organizationIds: string[]) => {
    setIsLoading(true);
    await dispatch(
      editBseThunk({
        command: {
          type: "AddInvitedOrganizationId",
          value: organizationIds,
        },
      })
    );
    setIsLoading(false);
  };

  const remove = async (inviteId: string) => {
    setIsLoading(true);
    await dispatch(
      editBseThunk({
        command: {
          type: "RemoveOrganizationInvite",
          value: inviteId,
        },
      })
    );
    setIsLoading(false);
  };

  const triggerUpdate = async () => {
    setSelectedOrganizations([]);
    await add(selectedOrganizations.map((e) => e.value));
  };

  if (!data) return <div>{t("Loading")}...</div>;

  return (
    <>
      {confirmationModal.modal}
      <FormControl id="registeredCompanies" mt={8} h="100%" isInvalid={errorMessage !== null}>
        <FormLabel>{t("Add an already registered company")}</FormLabel>
        <Flex>
          <Flex data-testid="search-for-company-name" w="100%">
            <Select
              size="md"
              placeholder={t("Search for company name") ?? ""}
              {...selectProps}
              options={data.organizations
                .filter((e) => e.id !== authState.selectedOrg.id)
                .filter((e) => value.map((e) => e.organizationId).indexOf(e.id) === -1)
                .map((e) => ({
                  value: e.id,
                  label: e.name,
                  name: e.name,
                  email: e.owner.email,
                }))}
              className={styles.multiSelect}
            />
          </Flex>
          <Button
            id="add-org"
            variant={"solid"}
            colorScheme="teal"
            isLoading={isLoading}
            isDisabled={!selectedOrganizations?.length}
            onClick={async () => {
              if (eventIsLive(eventState.event)) {
                confirmationModal.show({
                  title: t("Confirm invitations"),
                  description: t(
                    "You are live, and the selected organizations wil immediately get access to the event"
                  ),
                  onConfirmed: async () => {
                    await triggerUpdate();
                  },
                  confirmButtonColor: "blue",
                  confirmButtonText: t("Send") ?? "",
                });
              } else {
                await triggerUpdate();
              }
            }}
          >
            {t("Add")}
          </Button>
        </Flex>
        <FormErrorMessage>{errorMessage}</FormErrorMessage>

        <Box mt="20px">
          <OrganizationInvitesTable
            invites={value}
            removeInvite={async (inviteId: string) => {
              await remove(inviteId);
            }}
          />
        </Box>
      </FormControl>
    </>
  );
};
