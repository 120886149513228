import { Box, Button, Checkbox, Flex, Heading, Icon, IconButton, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { FaGripLinesVertical, FaPlus } from "react-icons/fa";
import {
  ProjectDto,
  useGetProductGroupsForProjectQuery,
  useListEventsForBuyerQuery,
} from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { CriticalError } from "../../../../common/errors/CriticalError/CriticalError";
import { ProductFileUploader } from "../../../../common/products/ProductFileUploader";
import { ProductGroupForm } from "../../../../common/products/ProductGroupForm";
import { ProductTemplateModal } from "../../../../common/products/ProductTemplateModal";
import { useAppDispatch, useAppSelector } from "../../../../common/redux/hooks";
import { mapDtoToProductGroup, setProductGroups } from "../../../../common/redux/reducers/productReducer";
import { Explanation } from "../../../../common/support/Explanation/Explanation";
import { Product, ProductGroup } from "../../../../common/types";
import { useSkeletons } from "../../../../common/useSkeletons";
import { SourcingEventTarget } from "./SourcingEventTarget";

const areAllProductsSelected = ({
  selectedGroups,
  availableGroups,
}: {
  selectedGroups?: ProductGroup[];
  availableGroups?: ProductGroup[];
}) => {
  if (!selectedGroups || !availableGroups || selectedGroups.length !== availableGroups.length) return false;
  for (const ag of availableGroups) {
    const selectedGroup = selectedGroups.find((sg) => sg.id === ag.id);
    if (!selectedGroup) return false;
    for (const p of ag.products ?? []) {
      if (!selectedGroup.products?.find((sp) => sp.id === p.id)) return false;
    }
  }
  return true;
};

export const Products = ({ project }: { project: ProjectDto }) => {
  const dispatch = useAppDispatch();
  const authState = useLoggedInWithOrgContextState();
  const skeletons = useSkeletons();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productGroupDraft, setProductGroupDraft] = useState<ProductGroup>();
  const [selectedProducts, setSelectedProducts] = useState<ProductGroup[]>();
  const [isDragging, setIsDragging] = useState(false);
  const [highlightedProducts, setHighlightedProducts] = useState<ProductGroup[]>();

  const {
    data: eventsData,
    error: eventsError,
    isLoading: isLoadingEvents,
  } = useListEventsForBuyerQuery({
    orgId: authState.selectedOrg.id,
    status: "Drafts",
    projectId: project.id,
  });

  const {
    data: productData,
    isLoading: isLoadingProducts,
    error: productError,
  } = useGetProductGroupsForProjectQuery({ projectId: project.id });

  const productGroups = useAppSelector((state) => state.product.data.productGroups);

  useEffect(() => {
    if (
      productGroups[0]?.projectId !== project.id &&
      productData?.productGroups.length &&
      productData.productGroups[0]?.projectId === project.id
    ) {
      dispatch(setProductGroups(productData?.productGroups.map((group) => mapDtoToProductGroup(group))));
    } else if (productGroups.length && productGroups[0].projectId !== project.id) {
      dispatch(setProductGroups([]));
    }
  }, [dispatch, productData?.productGroups, productGroups, project.id]);

  const handleSelectedProduct = useCallback(({ group, product }: { group: ProductGroup; product: Product }) => {
    setSelectedProducts((groups) => {
      const filteredGroups = groups?.filter((g) => g.id !== group.id) ?? [];
      const filteredProducts =
        groups?.find((g) => g.id === group.id)?.products?.filter((p) => p.id !== product.id) ?? [];
      return [...filteredGroups, { ...group, products: [...filteredProducts, product] }];
    });
  }, []);

  const handleUnselectedProduct = useCallback(
    ({ group, product }: { group: ProductGroup; product: Product }) =>
      setSelectedProducts((groups) =>
        groups
          ?.map((g) => (g.id === group.id ? { ...g, products: g.products?.filter((p) => p.id !== product.id) } : g))
          .filter((g) => !!g.products?.length)
      ),
    []
  );

  const handleSelectedProductGroup = useCallback(({ group }: { group: ProductGroup }) => {
    setSelectedProducts((groups) => {
      const filteredGroups = groups?.filter((g) => g.id !== group.id) ?? [];
      return [...filteredGroups, group];
    });
  }, []);

  const handleUnselectedProductGroup = useCallback(({ group }: { group: ProductGroup }) => {
    setSelectedProducts((groups) => groups?.filter((g) => g.id !== group.id));
  }, []);

  return (
    <Box width="full" maxWidth="full" maxHeight="full">
      {project.canEdit && (
        <>
          <ProductTemplateModal
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            productGroupDraft={productGroupDraft}
            projectId={project.id}
          />
          <ProductFileUploader
            showExplanation={productData?.productGroups.length === 0}
            setProductGroupDraft={setProductGroupDraft}
            setIsModalOpen={setIsModalOpen}
            projectId={project.id}
          />
          <Button variant={"outline"} mr="5px" colorScheme={"teal"} size={"sm"} onClick={() => setIsModalOpen(true)}>
            {t("New product group")} <Icon as={FaPlus} ml="2" />
          </Button>
        </>
      )}
      <Flex maxWidth="full" overflow="hidden" columnGap={5} maxHeight="80%">
        <Flex flexDirection="column" width="75%">
          <Heading fontSize="lg" pt="8" pb="4">
            {t("Project products")}
          </Heading>
          <Box maxHeight="80vh" overflowY="auto" shadow="inner" rounded="lg" pt="4" px="4">
            <Flex pl="8" pb="4">
              <Explanation text={t("Select to see connections to sourcing events")}>
                <Checkbox
                  variant="ghost"
                  colorScheme="purple"
                  isChecked={areAllProductsSelected({
                    selectedGroups: selectedProducts,
                    availableGroups: productGroups,
                  })}
                  onChange={() => {
                    if (selectedProducts?.length) setSelectedProducts(undefined);
                    else setSelectedProducts(productGroups);
                  }}
                  isIndeterminate={
                    !areAllProductsSelected({ selectedGroups: selectedProducts, availableGroups: productGroups }) &&
                    !!selectedProducts?.length
                  }
                />
              </Explanation>
              <Explanation text={t("Drag to sourcing event")}>
                <IconButton
                  draggable
                  variant="ghost"
                  cursor={isDragging ? "grabbing" : "grab"}
                  aria-label="drag to event"
                  colorScheme="cyan"
                  size="xs"
                  icon={<Icon as={FaGripLinesVertical} />}
                  onDrag={() => {
                    setSelectedProducts(productGroups);
                    setIsDragging(true);
                  }}
                  onDragEnd={(e) => {
                    e.preventDefault();
                    setIsDragging(false);
                  }}
                />
              </Explanation>
              <Text>{t("Select all products in all product groups")}</Text>
            </Flex>
            {productGroups.map((group) => (
              <Box key={group.id} pb="5">
                <ProductGroupForm
                  group={group}
                  selectedProducts={selectedProducts}
                  highlightedProducts={highlightedProducts}
                  onSelect={(product: Product) => handleSelectedProduct({ group, product })}
                  onUnSelect={(product: Product) => handleUnselectedProduct({ group, product })}
                  onSelectGroup={(group: ProductGroup) => handleSelectedProductGroup({ group })}
                  onUnselectGroup={(group: ProductGroup) => handleUnselectedProductGroup({ group })}
                  onProductDrag={(product: Product) => handleSelectedProduct({ group, product })}
                  onProductGroupDrag={(group: ProductGroup) => handleSelectedProductGroup({ group })}
                />
              </Box>
            ))}
            {productData && !productData.productGroups.length && (
              <Text color="smMuted" pb="4">
                {t("No products yet")}...
              </Text>
            )}
            {isLoadingProducts && skeletons.stackedLines(0, 3)}
            {!productData && !isLoadingProducts && productError && <CriticalError />}
          </Box>
        </Flex>
        <Flex flexDirection="column" width="25%" pt="8">
          <Heading fontSize="lg" pb="4">
            {t("Sourcing Events")}
          </Heading>
          {eventsData?.events.map((event) => (
            <Box key={event.id} pb="2">
              <SourcingEventTarget
                event={event}
                selectedProducts={selectedProducts}
                isSelected={highlightedProducts?.[0]?.sourcingEventId === event.id}
                setHighlightedProducts={setHighlightedProducts}
              />
            </Box>
          ))}
          {isLoadingEvents && skeletons.stackedLines(0, 3)}
          {!eventsData && !isLoadingEvents && eventsError && <CriticalError />}
        </Flex>
      </Flex>
    </Box>
  );
};
