import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa";
import { useLoggedInWithOrgContextState } from "../../auth/useLoggedInWithOrgContextState";
import { setOrganizationId } from "../../local-storage/selected-organization";

export const useChangeOrganizationModal = (): {
  show: () => void;
  modal: React.ReactElement;
} => {
  const [showModal, setShowModal] = useState(false);
  const authState = useLoggedInWithOrgContextState();
  const organizations = authState.organizations;
  const currentOrganization = authState.selectedOrg;
  const { t } = useTranslation();

  return {
    show: () => setShowModal(true),
    modal: (
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Change organization")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box backgroundColor="smBackground" borderRadius={10} pt="5">
              <Table variant="simple" mb="30px">
                <Thead>
                  <Tr>
                    <Th>{t("Name")}</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {organizations.map((e) => {
                    const isCurrentlySelected = e.id === currentOrganization.id;

                    return (
                      <Tr key={e.id}>
                        <Td>{e.name}</Td>
                        <Td>
                          <Flex justifyContent="end">
                            <Button
                              variant="solid"
                              size="sm"
                              colorScheme={"blue"}
                              isDisabled={isCurrentlySelected}
                              rightIcon={<Icon as={FaArrowRight} w="15px" h="15px" />}
                              onClick={() => {
                                setOrganizationId(e.id);
                                window.location.replace(import.meta.env.VITE_WEBAPP_BASE_URL);
                              }}
                            >
                              {isCurrentlySelected ? t("Already selected") : t("Change")}
                            </Button>
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })}
                  {organizations.length === 0 && (
                    <Tr>
                      <Td colSpan={3} width={"100%"} textAlign="center">
                        {t("You have no organizations")}
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    ),
  };
};
