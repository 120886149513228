import { Button, Icon, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaBuilding, FaCog, FaExchangeAlt, FaSignOutAlt, FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { urls } from "../../../../urls";
import { useLoggedInWithOrgContextState } from "../../../auth/useLoggedInWithOrgContextState";
import { displayWrappedWithEllipsis } from "../../../formatting/displayWrappedWithEllipsis";
import { useAppDispatch } from "../../../redux/hooks";
import { logoutThunk } from "../../../redux/thunks/auth/logout-thunk";
import { Explanation } from "../../../support/Explanation/Explanation";
import { useChangeOrganizationModal } from "../useChangeOrganizationModal";
import { PersonalProfileMenuItem } from "./PersonalProfileMenuItem";

export const MyAccount = () => {
  const navigate = useNavigate();
  const authState = useLoggedInWithOrgContextState();
  const dispatch = useAppDispatch();
  const changeOrganizationModal = useChangeOrganizationModal();
  const canEditOrg = authState.selectedOrg.canEdit;
  const { t } = useTranslation();
  return (
    <>
      {changeOrganizationModal.modal}
      <Menu>
        <MenuButton
          data-testid={"my-account-menu-button"}
          as={Button}
          leftIcon={<Icon as={FaBuilding} w="15px" h="15px" />}
          colorScheme="teal"
          variant="solid"
          size={"sm"}
        >
          {displayWrappedWithEllipsis(authState.selectedOrg.name, 15)}
        </MenuButton>
        <MenuList>
          <PersonalProfileMenuItem />
          <MenuItem
            icon={<Icon as={FaBuilding} w="15px" h="15px" marginBottom={-0.5} />}
            onClick={() => {
              navigate(urls.organizations.profile.go(authState.selectedOrg.id));
            }}
          >
            {authState.selectedOrg.name}
          </MenuItem>
          <Explanation text={t("Only admins can manage users")} enabled={!canEditOrg} placement="right-end">
            <MenuItem
              icon={<Icon as={FaUsers} w="15px" h="15px" marginBottom={-0.5} />}
              isDisabled={!canEditOrg}
              onClick={() => {
                navigate(`${urls.myProfile.organization.manageUsers}?tab=users`);
              }}
            >
              {t("Access management")}
            </MenuItem>
          </Explanation>
          <Explanation
            text={t("Only admins can manage organization settings")}
            enabled={!canEditOrg}
            placement="right-end"
          >
            <MenuItem
              icon={<Icon as={FaCog} w="15px" h="15px" marginBottom={-0.5} />}
              isDisabled={!canEditOrg}
              onClick={() => {
                navigate(`${urls.myProfile.organization.settings}?tab=notifications`);
              }}
            >
              {t("Settings")}
            </MenuItem>
          </Explanation>
          <MenuItem
            icon={<Icon as={FaExchangeAlt} w="15px" h="15px" marginBottom={-0.5} />}
            onClick={() => {
              changeOrganizationModal.show();
            }}
          >
            {t("Switch organization")}
          </MenuItem>
          <MenuItem
            icon={<Icon as={FaSignOutAlt} w="15px" h="15px" marginBottom={-0.5} />}
            onClick={() => {
              dispatch(logoutThunk());
            }}
          >
            {t("Log out")}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
