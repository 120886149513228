import { Button, Flex, Icon, Spacer, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { BseSupplierViewDto } from "../../../../../autogen/bff-api";
import { displayDateWithTime } from "../../../../../common/formatting/displayDateWithTime";
import { displayWrappedWithEllipsis } from "../../../../../common/formatting/displayWrappedWithEllipsis";
import { useQueryParams } from "../../../../../common/useQueryParams";
import { QuestionStatusTag } from "../../../buyer/view-single/questions/QuestionStatusTag";
import { sortByQuestionStatus } from "../../../buyer/view-single/questions/SupplierQuestions";
import { NewQuestionModal } from "./NewQuestionModal";
import { QuestionModal } from "./QuestionModal";
interface Props {
  data: BseSupplierViewDto;
  addComment: (comment: string, questionId: string) => Promise<boolean>;
  createNewQuestion: (values: { subject: string; content: string; announcementId: string | null }) => Promise<boolean>;
}

export const Questions = ({ data, addComment, createNewQuestion }: Props) => {
  const [questionId, setQuestionId] = useState<string>();
  const [newQuestion, setNewQuestion] = useState(false);
  const params = useQueryParams();
  const questionParam = params.get("question");
  const questions = data.questions;
  const matchingQuestions = questions?.filter((e) => e.id === questionId);
  const sortedQuestions = questions.slice().sort(sortByQuestionStatus);
  const { t } = useTranslation();

  useEffect(() => {
    if (questionParam) {
      setQuestionId(questionParam);
    }
  }, [questionParam]);

  return (
    <>
      {matchingQuestions.length > 0 && (
        <QuestionModal
          canAddComments={true}
          addComment={async (values: { comment: string }) => {
            return addComment(values.comment, matchingQuestions[0].id);
          }}
          isOpen={true}
          close={() => {
            setQuestionId(undefined);
            params.clearSelectedQuestion();
          }}
          question={matchingQuestions[0]}
          addCommentDisabledReason={null}
        />
      )}
      {newQuestion && (
        <NewQuestionModal
          isOpen={newQuestion}
          close={() => setNewQuestion(false)}
          createNewQuestion={createNewQuestion}
        />
      )}
      <Flex flexDirection={"column"}>
        {data.canAskQuestions && (
          <Flex mb="15px">
            <Spacer />
            <Button
              leftIcon={<Icon as={FaPlus} w="15px" h="15px" />}
              colorScheme={"blue"}
              variant="outline"
              size={"sm"}
              onClick={() => setNewQuestion(true)}
            >
              {t("New question")}
            </Button>
          </Flex>
        )}
        <Flex flexDirection={"column"} bg="gray.50" borderRadius={"10px"}>
          <Table variant="simple" mb="30px">
            <Thead>
              <Tr>
                <Th>{t("Subject")}</Th>
                <Th>{t("Asked by")}</Th>
                <Th>{t("Asked at")}</Th>
                <Th>{t("Status")}</Th>
                <Th>{t("Comments")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedQuestions.map((e) => {
                return (
                  <Tr
                    cursor={"pointer"}
                    _hover={{ bg: "gray.100" }}
                    key={e.id}
                    onClick={() => {
                      setQuestionId(e.id);
                      params.setSelectedQuestion(e.id);
                    }}
                  >
                    <Td>
                      <Text fontSize={"md"}>{displayWrappedWithEllipsis(e.subject, 20)}</Text>
                    </Td>
                    <Td>
                      <Text fontSize={"md"}>
                        {e.askedByPerson.firstName} {e.askedByPerson.lastName}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize={"md"}>{displayDateWithTime(e.askedAt, t("at"))}</Text>
                    </Td>
                    <Td>
                      <QuestionStatusTag status={e.status} />
                    </Td>
                    <Td>{e.comments.length}</Td>
                  </Tr>
                );
              })}
              {questions.length === 0 && (
                <Tr>
                  <Td colSpan={5} width={"100%"} textAlign="center">
                    {t("You have not asked any questions")}
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Flex>
      </Flex>
    </>
  );
};
