import { Box, Button, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAdminListSourcingEventsQuery } from '../../../autogen/bff-api'
import { useLoggedInWithOrgContextState } from '../../../common/auth/useLoggedInWithOrgContextState'
import { EventStatusTag } from '../../sourcing-events/EventStatusTag'
import { DebugPrint } from '../DebugPrint'

interface Props {
    organizationId: string
}

export const AdminSourcingEventsTable: React.FC<Props> = ({
    organizationId,
}) => {
    const { t } = useTranslation()
    const authState = useLoggedInWithOrgContextState()
    const { data } = useAdminListSourcingEventsQuery({
        orgId: organizationId,
        organizationId: authState.selectedOrg.id,
    })
    const [json, setJson] = useState<any>()

    if (!data) {
        return <div>{t('Loading...')}</div>
    }
    return (
        <Box
            bg="#f6f8fb"
            borderRadius={10}
            mt="20px"
            boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
        >
            <Table variant="simple" mb="30px">
                <Thead>
                    <Tr>
                        <Th>{t('Title')}</Th>
                        <Th>{t('Status')}</Th>
                        <Th>{t('Created by')}</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {data.events.map((e) => {
                        return (
                            <Tr key={e.id}>
                                <Td>{e.title}</Td>
                                <Td>
                                    <EventStatusTag status={e.status} />
                                </Td>
                                <Td>
                                    {e.createdBy.firstName}{' '}
                                    {e.createdBy.lastName}
                                </Td>
                                <Td>
                                    <Button
                                        variant={'solid'}
                                        colorScheme="green"
                                        onClick={() => setJson(e)}
                                    >
                                        Show json
                                    </Button>
                                </Td>
                            </Tr>
                        )
                    })}
                    {data.events.length === 0 && (
                        <Tr>
                            <Td colSpan={3} width={'100%'} textAlign="center">
                                {t('No events have been created')}
                            </Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>
            <Box>{json && <DebugPrint data={json} />}</Box>
        </Box>
    )
}
