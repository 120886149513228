import { Tag } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface Props {
  type: string;
  size?: "sm" | "md" | "lg";
  sectionName?: string;
}

export const DataFieldTypeTag = ({ type, sectionName, size }: Props) => {
  const { t } = useTranslation();
  const getEventTagMeta = (
    type: string
  ): {
    color: string;
    text: string;
  } => {
    switch (type) {
      case "Commercial":
        return {
          color: "blue",
          text: t("Commercial"),
        };
      case "Date":
        return {
          color: "purple",
          text: t("Date"),
        };
      case "Financial":
        return {
          color: "green",
          text: t("Financial"),
        };
      case "Other":
        return {
          color: "gray",
          text: t("Other"),
        };
      case "TermsAndConditions":
        return {
          color: "red",
          text: t("Terms & Conditions"),
        };
      default:
        return {
          color: "teal",
          text: type,
        };
    }
  };

  const tagMeta = getEventTagMeta(type);

  return (
    <>
      {sectionName && (
        <Tag variant={"outline"} colorScheme="teal" size={size} mr="2">
          {sectionName}
        </Tag>
      )}
      <Tag colorScheme={tagMeta.color} size={size} textAlign="center" paddingTop={"2px"} paddingBottom={"2px"}>
        {tagMeta.text}
      </Tag>
    </>
  );
};
