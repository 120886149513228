import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { OrgUserInviteRole, useUpdateOrganizationMutation } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../common/errors/useApiError";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { OrgDto, updateSelectedOrg } from "../../../../common/redux/reducers/authStateReducer";
import { selectableRoles } from "./config";

interface Props {
  currentValue: OrgUserInviteRole;
}

interface FormValues {
  role: OrgUserInviteRole;
}

export const ChangeAzureUsersDefaultRole = ({ currentValue }: Props) => {
  const toast = useToast();
  const authState = useLoggedInWithOrgContextState();
  const [editOrg, { isLoading }] = useUpdateOrganizationMutation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const displayer = useApiError();

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      role: currentValue,
    },
  });

  const value = watch("role");

  const onSubmit = async (values: FormValues) => {
    setIsRefreshing(true);
    const result = await editOrg({
      orgId: authState.selectedOrg.id,
      editOrganizationRequest: {
        azureUsersDefaultRole: {
          value: values.role,
        },
      },
    });
    if ("data" in result) {
      dispatch(updateSelectedOrg(result.data as OrgDto));
    } else {
      displayer.trigger(result.error);
    }
    setIsRefreshing(false);
    toast({
      title: t("Default Azure role has been updated!"),
      status: "success",
    });
  };
  return (
    <Box backgroundColor="smBackgroundSecondary" borderRadius={"10px"} p="20px" mt="20px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl mt="15px" isInvalid={errors.role !== undefined}>
          <FormLabel htmlFor={"role"}>{t("Default role for Entra ID users")}</FormLabel>
          <Select placeholder={t("Select a default role") ?? ""} {...register("role")}>
            {selectableRoles.map((role) => (
              <option key={role.value} value={role.value}>
                {t(role.label)}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.role && errors.role.message}</FormErrorMessage>
          <FormHelperText>{t("Please select a default role")}.</FormHelperText>
        </FormControl>
        {currentValue.trim() !== value.trim() && (
          <Button
            mt="10px"
            leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
            variant={"solid"}
            colorScheme="teal"
            size={"sm"}
            isLoading={isLoading || isRefreshing}
            type="submit"
          >
            {t("Update")}
          </Button>
        )}
      </form>
    </Box>
  );
};
