import { Flex, Skeleton } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContractTaskStatus, useListContractsQuery } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { MultiSelector } from "../../../../common/input/Selector/MultiSelector";
import { MultiSelectFilter, MultiSelectOption } from "../../view-all/filtering/MultiSelectFilter";
import { displayPersonName } from "../../view-single/sharing/AddExternalParticipantModal";

interface Props {
  selectedResponsiblePersons: MultiSelectOption[];
  onSelectedResponsiblePersonsChange: (values: MultiSelectOption[]) => void;
  selectedContracts: MultiSelectOption[];
  onSelectedContractsChange: (values: MultiSelectOption[]) => void;
  selectedStatuses: MultiSelectOption[];
  onSelectedStatusesChange: (values: MultiSelectOption[]) => void;
}

const statuses: ContractTaskStatus[] = ["Open", "Completed"];

export const TaskFilters = ({
  selectedResponsiblePersons,
  onSelectedResponsiblePersonsChange,
  selectedContracts,
  onSelectedContractsChange,
  selectedStatuses,
  onSelectedStatusesChange,
}: Props) => {
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();

  const [searchName, setSearchName] = useState("");
  const [debouncedSearchName, setDebouncedSearchName] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchName = useCallback(
    debounce((name: string) => setDebouncedSearchName(name), 300),
    []
  );

  const { data, isLoading } = useListContractsQuery({
    orgId: authState.selectedOrg.id,
    state: "Published",
    title: debouncedSearchName ? debouncedSearchName : undefined,
    limit: 10,
  });

  return (
    <Flex w="full">
      {data && (
        <Flex width="full" columnGap={2}>
          <Flex flex={1}>
            <MultiSelectFilter
              placeholder={t("Responsible")}
              options={authState.selectedOrg.users.map((e) => ({
                label: displayPersonName(e.person),
                value: e.person.id,
              }))}
              value={selectedResponsiblePersons}
              onChange={(values) => onSelectedResponsiblePersonsChange(values)}
            />
          </Flex>
          <Flex flex={1}>
            <MultiSelector
              size="sm"
              placeholder={{ text: t("Contract"), color: "" }}
              inputValue={searchName}
              value={selectedContracts}
              options={
                data?.contracts.map((e) => ({
                  label: e.title,
                  value: e.id,
                })) ?? []
              }
              noOptionsAvailableMessage={t("No contracts available")}
              onInputChange={(name: string) => {
                setSearchName(name);
                handleDebouncedSearchName(name);
              }}
              onChange={(values) => onSelectedContractsChange(values)}
            />
          </Flex>
          <Flex flex={1}>
            <MultiSelectFilter
              placeholder={t("Status")}
              options={statuses.map((status) => ({
                label: t(status),
                value: status,
              }))}
              value={selectedStatuses}
              onChange={(values) => onSelectedStatusesChange(values)}
            />
          </Flex>
        </Flex>
      )}
      {isLoading && !data && (
        <Skeleton
          height="8"
          width="full"
          rounded="md"
          startColor="smBackgroundSecondary"
          endColor="smBackgroundTertiary"
        />
      )}
    </Flex>
  );
};
