import { Button, Flex, FormControl, FormHelperText, FormLabel, Icon } from "@chakra-ui/react";
import { t } from "i18next";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { FaFileAlt } from "react-icons/fa";
import {
  TextDocumentDto,
  useCreateDocumentTemplateMutation,
  useDeleteTextDocumentMutation,
  useListDocumentTemplatesQuery,
  useUpdateTextDocumentMutation,
} from "../../autogen/bff-api";
import { TextDocumentModal } from "../../common/documents/TextDocumentModal";
import { useApiError } from "../../common/errors/useApiError";
import { TextDocumentTable } from "../../common/input/TipTap/TextDocumentTable";

export const DocumentTemplates = () => {
  const displayer = useApiError();

  const { data, isLoading } = useListDocumentTemplatesQuery();
  const [createDocumentTemplate, { isLoading: isCreatingDocumentTemplate }] = useCreateDocumentTemplateMutation();
  const [updateDocumentTemplate, { isLoading: isUpdatingDocumentTemplate }] = useUpdateTextDocumentMutation();
  const [deleteDocumentTemplate] = useDeleteTextDocumentMutation();

  const [textDocumentToEdit, setTextDocumentToEdit] = useState<TextDocumentDto>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateTextDocument = useCallback(
    debounce(
      ({
        textDocumentId,
        name,
        text,
        tags,
      }: {
        textDocumentId: string;
        name?: string;
        text?: string;
        tags?: string[];
      }) => {
        updateDocumentTemplate({
          updateTextDocumentRequest: {
            id: textDocumentId,
            name,
            text,
            tags,
          },
        });
      },
      300
    ),
    []
  );

  return (
    <>
      {textDocumentToEdit && (
        <TextDocumentModal
          textDocument={textDocumentToEdit}
          onClose={() => setTextDocumentToEdit(undefined)}
          onUpdate={({ title, content, tags }: { title?: string; content?: string; tags?: string[] }) => {
            if (!textDocumentToEdit) throw Error("No text document id - could not edit");
            debouncedUpdateTextDocument({
              textDocumentId: textDocumentToEdit.id,
              name: title,
              text: content,
              tags,
            });
          }}
          isUpdating={isUpdatingDocumentTemplate}
        />
      )}
      <FormControl>
        <FormLabel>{t("Create document template")}</FormLabel>
        <Flex flexDirection="column" alignItems="start">
          <Button
            colorScheme="teal"
            rightIcon={<Icon as={FaFileAlt} />}
            isLoading={isCreatingDocumentTemplate}
            onClick={async () => {
              const res = await createDocumentTemplate({
                createDocumentTemplateRequest: {
                  name: "",
                  text: "",
                  tags: [],
                },
              });
              if ("error" in res) displayer.show(res.error);
              else setTextDocumentToEdit(res.data);
            }}
          >
            {t("Create")}
          </Button>
          <FormHelperText>
            {t("Create a new document template from scratch or based on other templates")}
          </FormHelperText>
        </Flex>
      </FormControl>
      <FormControl pt={8}>
        <FormLabel>{t("Document templates")}</FormLabel>
        <TextDocumentTable
          isLoading={isLoading}
          noDocumentsText={t("No document templates yet") ?? ""}
          documents={data?.documents}
          onClick={setTextDocumentToEdit}
          onDelete={async (id: string) => {
            await deleteDocumentTemplate({ id });
          }}
          maxHeight="full"
        />
      </FormControl>
    </>
  );
};
