import { Button, Icon, Input, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import { useUpsertContractDataFieldsSectionMutation } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { addContractSection } from "../../../../common/redux/reducers/authStateReducer";
import { CustomContractField } from "../../../../common/types";

export const ContractSectionModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const authState = useLoggedInWithOrgContextState();

  const [createSection, { isLoading }] = useUpsertContractDataFieldsSectionMutation();

  const [sectionName, setSectionName] = useState("");

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setSectionName("");
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <ModalHeader>{t("Create Section")}</ModalHeader>
          <Input
            mb="4"
            placeholder={t("Enter name") ?? ""}
            value={sectionName}
            onChange={(e) => setSectionName(e.target.value)}
          />
          <Button
            float={"right"}
            variant={"outline"}
            colorScheme="teal"
            rightIcon={<Icon as={FaCheck} />}
            isLoading={isLoading}
            onClick={async () => {
              const section = {
                id: uuid(),
                name: sectionName,
                fields: [] as CustomContractField[],
              };
              await createSection({
                orgId: authState.selectedOrg.id,
                contractDataFieldSectionDto: section,
              });
              dispatch(addContractSection(section));
              onClose();
              setSectionName("");
            }}
          >
            {t("Save")}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
