import { Box, Divider, Flex, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface DataRow {
  type: "Data";
  key?: string;
  name: string;
  value: React.ReactElement;
  displayBelow?: boolean;
}

export type GenericDetailRow =
  | {
      type: "Divider";
    }
  | DataRow;

interface Props {
  title: string;
  rows: GenericDetailRow[];
}

export const GenericDetails = ({ title, rows }: Props) => {
  const { t } = useTranslation();
  return (
    <Box padding="15px">
      <Text fontWeight={"bold"}>{title}</Text>
      {rows.length === 0 && (
        <Text fontWeight={"light"} fontStyle={"italic"}>
          {t("No values provided")}
        </Text>
      )}
      {rows.map((e, index) => {
        if (e.type === "Data") {
          return getDetailRow(e, e.displayBelow ?? false);
        } else {
          return <Divider key={index} mt="8px" />;
        }
      })}
    </Box>
  );
};

const getDetailRow = (props: DataRow, displayBelow: boolean) => {
  return (
    <Flex mt="5px" key={props.key ?? props.name} flexDirection={displayBelow ? "column" : undefined}>
      <Flex>
        <Text color="smMuted">{props.name}</Text>
      </Flex>
      <Spacer />
      <Flex justifyContent={!displayBelow ? "end" : undefined}>{props.value}</Flex>
    </Flex>
  );
};
