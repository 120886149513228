import { Box, Button, Icon, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { EmailInviteDto, EmailInviteLanguage } from "../../../../../autogen/bff-api";

interface Props {
  invites: EmailInviteDto[];
  removeInvite?: (id: string) => void;
}

export const EmailInvitesTable = ({ invites, removeInvite }: Props) => {
  const { t } = useTranslation();

  const languageToString = (language: EmailInviteLanguage): string => {
    switch (language) {
      case "En":
        return t("English");
      case "No":
        return t("Norwegian");
    }
  };
  return (
    <Box h="100%" w="100%" backgroundColor="smBackgroundSecondary" rounded="md">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>
              <Text fontSize={"x-small"}>{t("Email")}</Text>
            </Th>
            <Th>
              <Text fontSize={"x-small"}>{t("Email language")}</Text>
            </Th>
            {removeInvite && <Th></Th>}
          </Tr>
        </Thead>
        <Tbody>
          <>
            {invites.map((e) => {
              return (
                <Tr key={e.id}>
                  <Td>
                    <Text fontSize={"sm"}>{e.email}</Text>
                  </Td>
                  <Td>
                    <Text fontSize={"sm"}>{languageToString(e.language)}</Text>
                  </Td>
                  {removeInvite !== undefined && (
                    <Td display={"flex"} flexDirection="row" justifyContent={"end"}>
                      <Button
                        leftIcon={<Icon as={FaTimes} w="15px" h="15px" />}
                        size="xs"
                        colorScheme="red"
                        variant={"solid"}
                        onClick={() => removeInvite(e.id)}
                      >
                        {t("Remove")}
                      </Button>
                    </Td>
                  )}
                </Tr>
              );
            })}
          </>
          {invites.length === 0 && (
            <Tr>
              <Td colSpan={4} width={"100%"} textAlign="center">
                {t("No email invites have been added")}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};
