import { Box, Button, Flex, Icon, Input, Text, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { ClipboardEvent, Dispatch, DragEvent, SetStateAction, useCallback, useRef, useState } from "react";
import { FaFileUpload } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import { read as readXlsx, utils as xlsxUtils } from "xlsx";
import { useRumError } from "../errors/RumComponentContext/useRumError";
import { getParsedType, parseToType } from "../parsers/parseString";
import { ProductField, ProductFieldType, ProductGroup, ProductTemplateField } from "../types";

const mapTableToProductGroup = ({
  table,
  name = "",
  sourcingEventId,
  projectId,
}: {
  table: string[][];
  name?: string;
  sourcingEventId?: string;
  projectId?: string;
}): ProductGroup => {
  const tableData = table.slice(1);
  const cleanedTableData = tableData.map((row) => row.map((d) => `${d ?? ""}`.trim()));
  const productFields = getFieldsFromTable(table[0], cleanedTableData);
  return {
    id: uuid(),
    sourcingEventId,
    projectId,
    name,
    products: cleanedTableData.map((row) => ({
      // Temporary id. ProductId will be generated backend.
      id: uuid(),
      quantity: {
        id: uuid(),
        name: "Quantity",
        populatedBy: "Buyer",
        isRequired: false,
      },
      productFields: productFields.map((_, i) => ({
        id: productFields[i].id,
        name: productFields[i].name,
        type: productFields[i].type,
        populatedBy: productFields[i].populatedBy,
        isRequired: productFields[i].isRequired,
        value:
          productFields[i].populatedBy === "Buyer"
            ? parseToType({ val: row[i], type: productFields[i].type })
            : undefined,
      })),
      conversation: [],
    })),
    productFields,
  };
};

const getParsedColumnType = (column: string[]): ProductFieldType => {
  if (column.some((datum) => !!datum && getParsedType(datum) === "Text")) return "Text";
  if (column.every((datum) => !datum || getParsedType(datum) === "Number")) return "Number";
  if (column.every((datum) => !datum || getParsedType(datum) === "Boolean")) return "Boolean";
  return "Text";
};

const getColumnPopulater = (column: string[]): ProductTemplateField["populatedBy"] => {
  if (column.some((datum) => !!datum)) return "Buyer";
  return "Supplier";
};

const getFieldsFromTable = (titles?: string[], tableData?: string[][]): ProductField[] => {
  if (!titles || !tableData) {
    throw Error("No titles or table data");
  }
  return titles.map<ProductField>((title, i) => ({
    id: uuid(),
    name: title,
    type: getParsedColumnType(tableData.map((row) => row[i])),
    populatedBy: getColumnPopulater(tableData.map((row) => row[i])),
    isRequired: false,
  }));
};

const findValidfile = (fileList: FileList) =>
  Array.from(fileList).find((file) => file.name.split(".").pop() === "xlsx");

export const ProductFileUploader = ({
  showExplanation,
  setProductGroupDraft,
  setIsModalOpen,
  sourcingEventId,
  projectId,
}: {
  showExplanation: boolean;
  setProductGroupDraft: Dispatch<SetStateAction<ProductGroup | undefined>>;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  sourcingEventId?: string;
  projectId?: string;
}) => {
  const toast = useToast();
  const rumError = useRumError();

  const [isHighlighted, setIsHighlighted] = useState(false);

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = findValidfile(e.dataTransfer.files);
    if (!file) {
      toast({ description: `${t("The file type is not supported")}...`, status: "warning" });
      return;
    }
    const result = await parseFile(file);
    handleProductData(result?.table, result?.name);
  };

  const handlePaste = async (e: ClipboardEvent<HTMLElement>) => {
    const fileList = e.clipboardData.files;
    const file = findValidfile(fileList);
    const result = file ? await parseFile(file) : parsePastedData(e);
    if (!result) return;
    handleProductData(result?.table, result?.name);
  };

  const parsePastedData = useCallback(
    (e: ClipboardEvent<HTMLElement>): { table: string[][]; name?: string } | undefined => {
      try {
        const mime = "text/html"; // ["text/plain", "text/html", "text/rtf"];
        const data = e.clipboardData?.getData(mime);
        const workbook = readXlsx(data, { type: "string", FS: "," });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const table = xlsxUtils.sheet_to_json<string[]>(worksheet, { header: 1 });
        return { table };
      } catch (_) {
        toast({ description: `${t("We're not able to interpret that data")}...`, status: "warning" });
      }
    },
    [toast]
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = async (fileList: FileList) => {
    const file = findValidfile(fileList);
    if (!file) {
      toast({ description: `${t("The file type is not supported")}...`, status: "warning" });
      return;
    }
    const result = await parseFile(file);
    if (!result) return;
    handleProductData(result?.table, result?.name);
  };

  const handleProductData = (table?: string[][], name?: string) => {
    if (!table?.[0]?.[0]) {
      toast({ description: `${t("We're not able to interpret that data")}...`, status: "warning" });
      return;
    }
    try {
      const trimmedTable = trimEmptyRows(table);
      const groupDraft = mapTableToProductGroup({ table: trimmedTable, name, sourcingEventId, projectId });
      setProductGroupDraft(groupDraft);
      setIsModalOpen(true);
    } catch (e) {
      toast({ description: `${t("We're not able to interpret that data")}...`, status: "warning" });
    }
  };

  const trimEmptyRows = useCallback(
    (table: string[][]) => {
      try {
        return table?.filter((row) => row.some((value) => !!`${value ?? ""}`?.trim()));
      } catch (e) {
        rumError({ message: "Trimming empty rows failed." }, { error: e });
        throw e;
      }
    },
    [rumError]
  );

  const parseFile = useCallback(async (file: File) => {
    const wb = readXlsx(await file.arrayBuffer());
    const name = wb.SheetNames[0];
    const workSheet = wb.Sheets[name];
    const table = xlsxUtils.sheet_to_json<string[]>(workSheet, { header: 1 });
    return { table, name };
  }, []);

  return (
    <>
      {showExplanation ? (
        <Flex
          justify="center"
          align="center"
          height="64"
          backgroundColor="smBackground"
          rounded="lg"
          border="2px dashed"
          borderColor={isHighlighted ? "smSecondary" : "smBorder"}
          mb="8"
          mt="8"
          onPaste={(e) => handlePaste(e)}
          onDrop={(e) => {
            handleDrop(e);
            setIsHighlighted(false);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            setIsHighlighted(true);
          }}
          onDragLeave={() => setIsHighlighted(false)}
        >
          <Box fontStyle="italic">
            <Text pb="4">{t("Enter your product data by")}...</Text>
            <Text pb="2">1. {t("clicking here and pasting a table or file")},</Text>
            <Text pb="2">2. {t("dragging and dropping a file")},</Text>
            <Flex pb="2" alignItems={"center"}>
              <Text>3.</Text>{" "}
              <Button size="sm" leftIcon={<Icon as={FaFileUpload} />} onClick={() => fileInputRef.current?.click()}>
                {t("browsing and selecting a file")}
              </Button>
              <Text>, {t("or")}</Text>
            </Flex>
            <Text pb="2">4. {t("adding a new product group manually")}.</Text>
          </Box>
        </Flex>
      ) : (
        <Flex
          justify="center"
          align="center"
          backgroundColor="smBackground"
          rounded="lg"
          border="2px dashed"
          borderColor={isHighlighted ? "smSecondary" : "smBorder"}
          pt="5"
          pb="3"
          my="8"
          onPaste={(e) => handlePaste(e)}
          onDrop={(e) => {
            handleDrop(e);
            setIsHighlighted(false);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            setIsHighlighted(true);
          }}
          onDragLeave={() => setIsHighlighted(false)}
        >
          <Box fontStyle="italic">
            <Flex alignItems={"center"}>
              <Button size="sm" leftIcon={<Icon as={FaFileUpload} />} onClick={() => fileInputRef.current?.click()}>
                {t("Drop or browse files here")}
              </Button>
            </Flex>
            <Text pb="2"></Text>
          </Box>
        </Flex>
      )}
      <Input
        ref={fileInputRef}
        style={{ display: "none" }}
        type="file"
        onChange={(e) => {
          const files = e.target.files;
          if (files) handleFileUpload(files);
        }}
      />
    </>
  );
};
