import { Box } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import {
    AdminEntityType,
    useAdminListAllEntitiesQuery,
} from '../../../autogen/bff-api'
import { DebugPrint } from '../DebugPrint'
interface Props {
    entityType: AdminEntityType
}

export const AllEntities: React.FC<Props> = ({ entityType }) => {
    const { data, refetch } = useAdminListAllEntitiesQuery({
        entityType: entityType,
    })

    useEffect(() => {
        refetch()
    }, [entityType])

    if (!data) {
        return <div>Loading...</div>
    }

    return (
        <Box
            bg="#f6f8fb"
            borderRadius={10}
            mt="20px"
            boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
        >
            <Box>{data && <DebugPrint data={data} />}</Box>
        </Box>
    )
}
