import { Box, Button, Flex, Grid, GridItem, Heading, Icon, IconButton, Spacer, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaArrowRight, FaFileContract } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useListContractsQuery } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { useSub } from "../../../common/subscription/useSub";
import { useSkeletons } from "../../../common/useSkeletons";
import { urls } from "../../../urls";
import { CreateContractModal } from "../../contracts/create/CreateContractModal";
import { Contract } from "./Contract";
import { Filter, LatestContractsFilterType } from "./Filter";

export const LatestContracts = () => {
  const navigate = useNavigate();
  const authState = useLoggedInWithOrgContextState();
  const skeletons = useSkeletons();
  const sub = useSub();
  const { data, error } = useListContractsQuery({
    orgId: authState.selectedOrg.id,
    state: "Published",
    limit: 5,
  });
  const [showCreateContractModal, setShowCreateContractModal] = useState(false);
  const [filter, setFilter] = useState<LatestContractsFilterType>("all");
  const displayer = useApiError();

  if (error) {
    return displayer.show(error);
  }

  const filteredContracts = data?.contracts.filter((e) => {
    switch (filter) {
      case "all":
        return true;
      case "imResponsible":
        return authState.me.id === e.internalOwner.id;
    }
  });

  return (
    <Box
      flexDirection={"column"}
      alignItems="start"
      justifyContent={"start"}
      borderRadius={"10px"}
      boxShadow="0 3px 15px rgba(71, 85, 105, 0.16)"
      padding="20px"
      my="4"
      backgroundColor="smBackground"
    >
      {showCreateContractModal && <CreateContractModal onClose={() => setShowCreateContractModal(false)} />}
      <Grid
        gridTemplateColumns="50px 90px"
        gridTemplateAreas={`
                "icon title title"
                "unused1 events events"
                `}
      >
        <GridItem area="icon">
          <Box
            bg="smPrimarySoft"
            h="50px"
            w="50px"
            borderRadius={"50%"}
            display="flex"
            alignItems={"center"}
            justifyContent="center"
          >
            <Icon as={FaFileContract} w="18px" h="18px" color="smBackground" />
          </Box>
        </GridItem>
        <GridItem area="title" display={"flex"}>
          <Heading as="h2" fontSize="3xl" fontWeight={"semibold"} ml="10px" mt="7px">
            {t("Latest contracts")}
          </Heading>
          <Spacer />
          <Flex alignItems={"end"}>
            <Box w="150px" mb="5px">
              <Filter
                onChange={(e) => {
                  setFilter(e);
                }}
              />
            </Box>
          </Flex>
        </GridItem>
        <GridItem area="events">
          {!data && skeletons.stackedLines(10)}
          {filteredContracts && (
            <>
              {filteredContracts.map((contract) => {
                return <Contract key={contract.id} contract={contract} />;
              })}
              {filteredContracts.length === 0 && (
                <Text fontSize={"md"} ml="10px" color="#5f6368" fontStyle={"italic"} mt="10px">
                  {t("You have no contracts right now!")}{" "}
                  {sub.canCreateContracts && (
                    <>
                      {t("Get started by")}
                      <Button
                        variant={"link"}
                        colorScheme="teal"
                        fontStyle={"italic"}
                        onClick={() => setShowCreateContractModal(true)}
                      >
                        {t("creating your first contract!")}
                      </Button>
                    </>
                  )}
                </Text>
              )}
            </>
          )}
          {data && (
            <Flex pt="4">
              <Spacer />
              <IconButton
                aria-label="go to contracts"
                icon={<Icon as={FaArrowRight} />}
                variant={"link"}
                colorScheme="cyan"
                float="right"
                onClick={() => {
                  navigate(`${urls.contracts.viewPublished}?status=Active`);
                }}
              />
            </Flex>
          )}
        </GridItem>
      </Grid>
    </Box>
  );
};
