import {
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BseAnnouncementDto } from "../../../../../autogen/bff-api";
import { displayDateWithTime } from "../../../../../common/formatting/displayDateWithTime";
import { displayWrappedWithEllipsis } from "../../../../../common/formatting/displayWrappedWithEllipsis";

interface Props {
  isOpen: boolean;
  close: () => void;
  announcement: BseAnnouncementDto;
}

export const AnnouncementModal = ({ isOpen, close, announcement }: Props) => {
  const marginTop = "10px";
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={close} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{displayWrappedWithEllipsis(announcement.title, 60)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex backgroundColor="smBackgroundSecondary" borderRadius={"10px"} padding="20px" w="100%">
            <Grid gridTemplateColumns={"1fr 1fr"} height="100%" gridTemplateRows={"auto"} w="100%">
              <GridItem mt={marginTop}>
                <Text fontWeight={"bold"}>{t("Published at")}</Text>
              </GridItem>
              <GridItem mt={marginTop}>{displayDateWithTime(announcement.createdAt, t("at"))}</GridItem>
              <GridItem mt={marginTop}>
                <Text fontWeight={"bold"}>{t("Published by")}</Text>
              </GridItem>
              <GridItem mt={marginTop}>
                {announcement.createdByPerson.firstName} {announcement.createdByPerson.lastName}
              </GridItem>
              <GridItem mt={marginTop}>
                <Text fontWeight={"bold"}>{t("Title")}</Text>
              </GridItem>
              <GridItem mt={marginTop}>{announcement.title}</GridItem>
              <GridItem mt={marginTop}>
                <Text fontWeight={"bold"}>{t("Announcement")}</Text>
              </GridItem>
              <GridItem mt={marginTop}>
                <Text fontSize={"md"} whiteSpace={"pre-line"}>
                  {announcement.content}
                </Text>
              </GridItem>
            </Grid>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
