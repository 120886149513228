import { Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";

interface Props {
  icon?: IconType;
  text: string;
  backgroundColor?: string;
  color?: string;
  border?: string;
  borderColor?: string;
  children?: React.ReactNode;
}

export const StatusBanner = ({ icon, text, backgroundColor, color, border, borderColor, children }: Props) => {
  return (
    <>
      <Flex
        display={"flex"}
        bg={backgroundColor}
        borderRadius={"10px"}
        padding="20px"
        flexDirection={"row"}
        alignItems="center"
        color={color ?? "smBackground"}
        border={border}
        borderColor={borderColor}
      >
        <Flex flex={2}>
          {icon && <Icon as={icon} mr="5px" mt="3px" w="20px" h="20px" />}
          <Text fontSize={"lg"} fontWeight="bold">
            {text}
          </Text>
        </Flex>
        <Flex flex={1} justifyContent="end">
          {children}
        </Flex>
      </Flex>
    </>
  );
};
