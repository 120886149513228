import { Modal, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useState } from "react";
import { FileUpload } from "./FileRow";
import { SustainabilityAnalysisContentDocumentsStep } from "./SustainabilityAnalysisContentDocumentsStep";
import { SustainabilityAnalysisContentResultStep } from "./SustainabilityAnalysisContentResultStep";

interface Props {
  fileRows: FileUpload[];
  onClose: () => void;
}

export const SustainabilityAnalysisModal = ({ onClose, fileRows }: Props) => {
  const [step, setStep] = useState(1);

  return (
    <Modal isOpen={true} onClose={onClose} closeOnOverlayClick={true} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{step === 1 ? "Bærekraftsanalyse" : "Resultater"}</ModalHeader>
        {step === 1 && (
          <SustainabilityAnalysisContentDocumentsStep fileRows={fileRows} showResults={() => setStep(2)} />
        )}
        {step === 2 && <SustainabilityAnalysisContentResultStep />}
      </ModalContent>
    </Modal>
  );
};
