import { t } from "i18next";
import { useMemo } from "react";
import { ProjectStatus } from "../../autogen/bff-api";

export const useProjectStatuses = () => {
  const projectStatuses: {
    [status in ProjectStatus]: {
      name: string;
      colorScheme: string;
    };
  } = useMemo(
    () => ({
      NotStarted: { name: t("Not started") ?? "", colorScheme: "cyan" },
      Ongoing: { name: t("Ongoing") ?? "", colorScheme: "teal" },
      Ended: { name: t("Ended") ?? "", colorScheme: "orange" },
    }),
    []
  );
  return projectStatuses;
};
