import { Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { ContractTaskChangeDto } from "../../../../../../autogen/bff-api";
import { displayDateWithTime } from "../../../../../../common/formatting/displayDateWithTime";
import { Explanation } from "../../../../../../common/support/Explanation/Explanation";

interface Props {
  change: ContractTaskChangeDto;
}

export const Change: React.FC<Props> = ({ change }) => {
  const { t } = useTranslation();

  const getChangeName = (): string => {
    switch (change.type) {
      case "Description":
        return t("description");
      case "DueDate":
        return t("due date");
      case "ResponsibleParty":
        return t("responsible party");
      case "Status":
        return t("status");
      case "Title":
        return t("title");
    }
  };

  const getChangeText = () => {
    return `${t("Changed")} ${getChangeName()} ${t("from")} "${change.oldValue}" ${t("to")} "${change.newValue}"`;
  };
  return (
    <Flex flexDirection={"column"} mt="15px">
      <Flex>
        <Text fontWeight={"semibold"} fontSize="md" color="#42526e">
          {change.changedByFullName}
        </Text>
        <Explanation enabled={true} text={displayDateWithTime(change.createdAt, t("at"))}>
          <Text fontSize="sm" color="#42526e" ml="15px" mt="1px">
            {moment(change.createdAt).fromNow()}
          </Text>
        </Explanation>
      </Flex>
      <Flex mt="5px">
        <Text fontStyle={"italic"}>{getChangeText()}</Text>
      </Flex>
    </Flex>
  );
};
