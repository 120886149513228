import { Select } from '@chakra-ui/react'
import { t } from 'i18next'
import React from 'react'

interface Props {
    onChange: (type: LatestEventsFilterType) => void
}

export type LatestEventsFilterType = 'all' | 'mine'

export const Filter: React.FC<Props> = ({ onChange }) => {
    return (
        <Select
            size={'sm'}
            onChange={(e) => {
                const selected = toType(e.target.value)

                onChange(selected)
            }}
        >
            <option key={'all'} value={'all'}>
                {t('All')}
            </option>
            <option key={'mine'} value={'mine'}>
                {t('Only mine')}
            </option>
        </Select>
    )
}

const toType = (raw: string): LatestEventsFilterType => {
    if (raw === 'all') {
        return 'all'
    } else if (raw === 'mine') {
        return 'mine'
    } else {
        throw Error('should never happen')
    }
}
