import { Text } from "@chakra-ui/react";
import { isNumber } from "lodash";
import { useTranslation } from "react-i18next";
import { ContractState } from "../../../../common/redux/reducers/contractReducer";
import { requireDataFields } from "../../typeguards";
import { GenericDetailRow, GenericDetails } from "./GenericDetails";
interface Props {
  data: ContractState["contract"];
}

export const Financial = ({ data }: Props) => {
  const { t } = useTranslation();
  const dataFields = requireDataFields(data);
  const rows: GenericDetailRow[] = [];
  const addedFields = dataFields.addedDataFields.map((e) => e.name);
  const noValueProvided = <Text fontStyle={"italic"}>{t("Not provided")}</Text>;
  if (addedFields.includes("EstimatedValue")) {
    rows.push({
      type: "Data",
      name: t("Estimated value"),
      value: isNumber(dataFields.estimatedValue) ? (
        <Text>{`${dataFields.estimatedValue.toLocaleString()} ${data.currency}`}</Text>
      ) : (
        noValueProvided
      ),
    });
  }
  if (addedFields.includes("PricePerMonth")) {
    rows.push({
      type: "Data",
      name: t("Price per month"),
      value: isNumber(dataFields.pricePerMonth) ? (
        <Text>{`${dataFields.pricePerMonth.toLocaleString()} ${data.currency}`}</Text>
      ) : (
        noValueProvided
      ),
    });
  }
  if (addedFields.includes("PricePerYear")) {
    rows.push({
      type: "Data",
      name: t("Price per year"),
      value: isNumber(dataFields.pricePerYear) ? (
        <Text>{`${dataFields.pricePerYear.toLocaleString()} ${data.currency}`}</Text>
      ) : (
        noValueProvided
      ),
    });
  }
  if (addedFields.includes("TotalPrice")) {
    rows.push({
      type: "Data",
      name: t("Total price"),
      value: isNumber(dataFields.totalPrice) ? (
        <Text>{`${dataFields.totalPrice.toLocaleString()} ${data.currency}`}</Text>
      ) : (
        noValueProvided
      ),
    });
  }
  if (addedFields.includes("TotalLiability")) {
    rows.push({
      type: "Data",
      name: t("Total liability"),
      value: isNumber(dataFields.totalLiability) ? (
        <Text>{`${dataFields.totalLiability.toLocaleString()} ${data.currency}`}</Text>
      ) : (
        noValueProvided
      ),
    });
  }

  return <GenericDetails title={t("Financial")} rows={rows} />;
};
