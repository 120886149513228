import { Tag } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    status: CertificateStatus
    size?: 'sm' | 'md' | 'lg'
}

export const CertificateStatusTag: React.FC<Props> = ({ status, size }) => {
    const { t } = useTranslation()
    const getEventTagMeta = (
        status: CertificateStatus
    ): {
        color: string
        text: string
    } => {
        switch (status) {
            case 'Valid':
                return {
                    color: 'green',
                    text: t('Valid'),
                }
            case 'Expired':
                return {
                    color: 'orange',
                    text: t('Expired'),
                }
        }
    }

    const tagMeta = getEventTagMeta(status)

    return (
        <Tag
            colorScheme={tagMeta.color}
            size={size}
            textAlign="center"
            paddingTop={'2px'}
            paddingBottom={'2px'}
        >
            {tagMeta.text}
        </Tag>
    )
}

export type CertificateStatus = 'Valid' | 'Expired'
