import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaPaperPlane } from "react-icons/fa";

interface Props {
  isOpen: boolean;
  close: () => void;
  create: (values: { title: string; content: string }) => Promise<boolean>;
  initialValues: AnnouncementData | null;
}
export interface AnnouncementData {
  title: string;
  content: string;
}

export const NewAnnouncementModal = ({ isOpen, close, create, initialValues }: Props) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<AnnouncementData>({
    defaultValues: {
      title: initialValues?.title ?? "",
      content: initialValues?.content ?? "",
    },
  });
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={close} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("New announcement")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <form
            onSubmit={handleSubmit(async (values: AnnouncementData) => {
              setIsLoading(true);
              const result = await create({
                title: values.title,
                content: values.content,
              });
              setIsLoading(false);

              if (result) {
                toast({
                  title: t("Announcement added!"),
                  status: "success",
                });
                close();
              } else {
                toast({
                  title: t("Could not add announcement!"),
                  status: "error",
                });
              }
            })}
          >
            <FormControl mt="15px" isInvalid={errors.title !== undefined}>
              <Input
                id={"title"}
                type="text"
                placeholder={t("Title") ?? ""}
                {...register("title", {
                  required: t("Announcement title") ?? "",
                  minLength: {
                    message: t("Minimum 2 characters needed!"),
                    value: 2,
                  },
                })}
              />
              <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt="15px" isInvalid={errors.content !== undefined}>
              <Textarea
                placeholder={t("Your announcement") ?? ""}
                {...register("content", {
                  minLength: {
                    message: t("Minimum 2 characters needed!"),
                    value: 2,
                  },
                })}
              />
              <FormErrorMessage>{errors.content && errors.content.message}</FormErrorMessage>
            </FormControl>
            <Flex mt="15px">
              <Spacer />
              <Button
                leftIcon={<Icon as={FaPaperPlane} w="15px" h="15px" mt="-2px" />}
                variant={"solid"}
                colorScheme="teal"
                size={"sm"}
                isLoading={isLoading}
                type="submit"
              >
                {t("Publish")}
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
