import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useUpdateOrganizationMutation } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../common/errors/useApiError";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { OrgDto, updateSelectedOrg } from "../../../../common/redux/reducers/authStateReducer";
import { useBaseData } from "../../../../common/useBaseData";

interface FormValues {
  organizationNumber?: string;
}

interface Props {
  currentValue?: string;
}

export const ChangeOrgNumber = ({ currentValue }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const [editOrg, { isLoading }] = useUpdateOrganizationMutation();
  const baseData = useBaseData();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { t } = useTranslation();
  const displayer = useApiError();

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      organizationNumber: currentValue,
    },
  });

  if (!baseData) return <div>{t("Loading")}...</div>;

  const newValue = watch("organizationNumber");

  const onSubmit = async (values: FormValues) => {
    setIsRefreshing(true);
    const response = await editOrg({
      orgId: authState.selectedOrg.id,
      editOrganizationRequest: {
        organizationNumber: values.organizationNumber,
      },
    });
    if ("data" in response) {
      dispatch(updateSelectedOrg(response.data as OrgDto));
      toast({ title: `${t("Organization number has been updated")}!`, status: "success" });
    } else {
      displayer.trigger(response.error);
    }
    setIsRefreshing(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="organizationNumber" mt="20px" isInvalid={errors.organizationNumber != null}>
        <FormLabel>{t("Organization number")}</FormLabel>
        <Input id={"organizationNumber"} type="text" {...register("organizationNumber", {})} />
        <FormHelperText>{t("Please enter the unique organization number for your company")}.</FormHelperText>
        <FormErrorMessage>{errors.organizationNumber && errors.organizationNumber.message}</FormErrorMessage>
      </FormControl>
      {currentValue?.trim() !== newValue?.trim() && (
        <Button
          mt="10px"
          leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
          variant={"solid"}
          colorScheme="teal"
          size={"sm"}
          isLoading={isLoading || isRefreshing}
          type="submit"
        >
          {t("Save change")}
        </Button>
      )}
    </form>
  );
};
