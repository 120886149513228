import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Spinner,
  Textarea,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";

interface Props {
  label: string;
  placeholderText: string;
  text: string | null;
  errorMessage: string | null;
  editText: (text: string) => Promise<void>;
  helperText: string;
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const GenericTextArea = ({
  label,
  placeholderText,
  text,
  errorMessage,
  editText,
  helperText,
  removeDataField,
  isRequiredButNotProvided,
}: Props) => {
  const [providedText, setProvidedText] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setProvidedText(text ?? undefined);
  }, []);

  const amountEventHandler = async (value: string) => {
    setIsLoading(true);
    await editText(value);
    setIsLoading(false);
  };

  const debouncedEventHandler = useMemo(
    () =>
      debounce(async (value: string) => {
        await amountEventHandler(value);
      }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedEventHandler.cancel();
    };
  }, []);

  return (
    <FormControl isInvalid={errorMessage !== null} isRequired={isRequiredButNotProvided}>
      <FormLabel htmlFor={"value"}>
        {label} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Box borderRadius={"10px"}>
        <Flex>
          <Textarea
            backgroundColor="smBackground"
            value={providedText ?? ""}
            placeholder={placeholderText}
            onChange={(e) => {
              setProvidedText(e.target.value);
              debouncedEventHandler(e.target.value);
            }}
          />
          <RemoveDataFieldButton removeDataField={removeDataField} />
        </Flex>
      </Box>
      <FormHelperText>{helperText}</FormHelperText>
      <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
