import { Tag } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BseQuestionStatus } from "../../../../../autogen/bff-api";

interface Props {
  status: BseQuestionStatus;
  size?: "sm" | "md" | "lg";
}

export const QuestionStatusTag = ({ status, size }: Props) => {
  const { t } = useTranslation();
  const getEventTagMeta = (
    status: BseQuestionStatus
  ): {
    color: string;
    text: string;
  } => {
    switch (status) {
      case "Resolved":
        return {
          color: "green",
          text: t("Resolved"),
        };
      case "Unresolved":
        return {
          color: "red",
          text: t("Unresolved"),
        };
    }
  };

  const tagMeta = getEventTagMeta(status);

  return (
    <Tag colorScheme={tagMeta.color} size={size}>
      {tagMeta.text}
    </Tag>
  );
};
