import { CountryDto } from "../autogen/bff-api";
import { useAppSelector } from "./redux/hooks";

export const useBaseData = () => {
  const baseData = useAppSelector((state) => state.baseData.baseData);

  if (!baseData) throw Error("BaseData not available");

  const requireCountry = (id: string): CountryDto => {
    const matches = baseData.countries.filter((e) => e.id === id);
    if (matches.length !== 1) throw Error("Could not find country when it was required");

    return matches[0];
  };

  return {
    countries: baseData.countries,
    callingCodes: baseData.callingCodes,
    contractDataFields: baseData.contractDataFields,
    requireCountry,
  };
};
