import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { bffApi } from "../../autogen/bff-api";
import authStateReducer from "./reducers/authStateReducer";
import baseDataReducer from "./reducers/baseDataReducer";
import basicBidReducer from "./reducers/basicBidReducer";
import basicSourcingEventReducer from "./reducers/basicSourcingEventReducer";
import contractReducer from "./reducers/contractReducer";
import { productReducer } from "./reducers/productReducer";

export const store = configureStore({
  reducer: {
    [bffApi.reducerPath]: bffApi.reducer,
    authState: authStateReducer,
    baseData: baseDataReducer,
    basicSourcingEvent: basicSourcingEventReducer,
    basicBid: basicBidReducer,
    contract: contractReducer,
    product: productReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["meta.arg.user", "meta.baseQueryMeta.request", "meta.baseQueryMeta.response"],
      },
    }).concat(bffApi.middleware),
  devTools: true,
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
