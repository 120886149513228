import { useTranslation } from "react-i18next";
import { ContractDataFieldNameDto, ContractPriority, ContractRisk } from "../autogen/bff-api";

export const useTranslationTools = () => {
  const { t } = useTranslation();
  const getRiskTranslation = (risk: ContractRisk): React.ReactElement => {
    switch (risk) {
      case "AboveAverage":
        return <>{t("Above average")}</>;
      case "Average":
        return <>{t("Average")}</>;
      case "BelowAverage":
        return <>{t("Below average")}</>;
      case "VeryHigh":
        return <>{t("Very high")}</>;
      case "VeryLow":
        return <>{t("Very low")}</>;
    }
  };

  const getPriorityTranslation = (priority: ContractPriority): React.ReactElement => {
    switch (priority) {
      case "AboveAverage":
        return <>{t("Above average")}</>;
      case "Average":
        return <>{t("Average")}</>;
      case "BelowAverage":
        return <>{t("Below average")}</>;
      case "VeryHigh":
        return <>{t("Very high")}</>;
      case "VeryLow":
        return <>{t("Very low")}</>;
    }
  };

  const getDataFieldTranslation = (name: ContractDataFieldNameDto): string => {
    switch (name) {
      case "AwardDate":
        return t("Award date");
      case "RenewalDate":
        return t("Renewal date");
      case "CounterpartyContactPerson":
        return t("Counterparty contact person");
      case "DeliveryTerms":
        return t("Delivery terms");
      case "EstimatedValue":
        return t("Estimated total value");
      case "EndDate":
        return t("End date");
      case "ExternalLink":
        return t("External system link");
      case "InternalReferenceNumber":
        return t("Internal reference number");
      case "LinkedContracts":
        return t("Linked contracts");
      case "OriginSourcingEvent":
        return t("Origin sourcing event");
      case "PaymentTermsInDays":
        return t("Payment terms in days");
      case "PricePerMonth":
        return t("Price per month");
      case "PricePerYear":
        return t("Price per year");
      case "Priority":
        return t("Priority");
      case "PurchasingPolicy":
        return t("Purchase policy");
      case "Risk":
        return t("Risk");
      case "StartDate":
        return t("Start date");
      case "TotalLiability":
        return t("Total liability");
      case "TotalPrice":
        return t("Total price");
      case "WarrantyExpirationDate":
        return t("Warranty expiration date");
      case "TerminationDate":
        return t("Termination date");
      case "IfrsValues":
        return t("IFRS values");
      case "InternalParties":
        return t("Internal parties");
      case "SupplierInfo":
        return t("Supplier info");
      case "SupplierCategory":
        return t("Supplier category");
      case "NoticePeriod":
        return t("Notice period");
      case "Options":
        return t("Contract options");
      case "Projects":
        return t("Projects");
    }
  };

  return {
    getRiskTranslation,
    getPriorityTranslation,
    getDataFieldTranslation,
  };
};
