import { BseDto } from "../../../../autogen/bff-api";
import { BseReports } from "./BseReports";
import { EventValue } from "./EventValue";
import { MessagesToWinnersAndLosers } from "./MessagesToWinnersAndLosers";

export const Awarded = ({ sourcingEvent }: { sourcingEvent: BseDto }) => {
  return (
    <>
      <MessagesToWinnersAndLosers event={sourcingEvent} />
      <EventValue data={sourcingEvent} />
      <BseReports data={sourcingEvent} />
    </>
  );
};
