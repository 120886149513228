import { Tag } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BseBidStatusDto } from '../../../autogen/bff-api'

interface Props {
    status: BseBidStatusDto
}

export const BidInvitationStatus: React.FC<Props> = ({ status }) => {
    const { t } = useTranslation()

    const getTagMeta = (
        status: BseBidStatusDto
    ): {
        color: string
        text: string
    } => {
        switch (status) {
            case 'DeadlinePassedAndBidDelivered':
            case 'BidDelivered':
                return {
                    color: 'blue',
                    text: t('Delivered'),
                }
            case 'DraftCreated':
                return {
                    color: 'yellow',
                    text: t('Draft'),
                }
            case 'InviteAccepted':
            case 'InviteNotAccepted':
                return {
                    color: 'gray',
                    text: t('Invited'),
                }
            case 'DeadlinePassedWithoutBidDelivered':
                return {
                    color: 'red',
                    text: t('Overdue'),
                }
            case 'IsLoser':
                return {
                    color: 'red',
                    text: t('Loser'),
                }
            case 'IsWinner':
                return {
                    color: 'green',
                    text: t('Winner!'),
                }
        }
    }

    const tagMeta = getTagMeta(status)

    return (
        <>
            <Tag
                size={'sm'}
                variant="solid"
                colorScheme={tagMeta.color}
                textAlign="center"
                pt="4px"
                pb="4px"
            >
                {tagMeta.text}
            </Tag>
        </>
    )
}
