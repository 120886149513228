import { Input } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";

export interface Props {
  defaultDate?: string;
  date?: string | null;
  onChange: (date?: string) => Promise<void> | void;
  isDisabled?: boolean;
  onBlur?: () => void;
  autoFocus?: boolean;
}

const convertISOToDateTime = (date?: string | null) =>
  date ? moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS") : undefined;
const convertDateTimeToISO = (date?: string) => moment(date).toISOString();

export const DateTimeSelector = ({ defaultDate, onChange, isDisabled, onBlur, autoFocus = false, date }: Props) => {
  const [internalDate, setInternalDate] = useState<string>(convertISOToDateTime(defaultDate) || "");

  return (
    <Input
      fontSize="md"
      type="datetime-local"
      isDisabled={isDisabled}
      autoFocus={autoFocus}
      value={date !== undefined ? convertISOToDateTime(date) ?? "" : internalDate}
      onChange={(e) => {
        const date = e.target.value;
        setInternalDate(date);
        onChange(convertDateTimeToISO(e.target.value));
      }}
      onBlur={onBlur}
      maxWidth={{ sm: "full", md: "60" }}
      border="1px solid"
      borderColor="smBorder"
      backgroundColor="smBackground"
      rounded="md"
      p="4"
    />
  );
};
