import { Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { BseQuestionDto } from "../../../../../autogen/bff-api";
import { displayWrappedWithEllipsis } from "../../../../../common/formatting/displayWrappedWithEllipsis";
import { SupplierQuestionComments } from "../../../buyer/view-single/questions/SupplierQuestionComments";
import { QuestionMeta } from "./QuestionMeta";

interface Props {
  isOpen: boolean;
  close: () => void;
  canAddComments: boolean;
  question: BseQuestionDto;
  addComment: (values: { comment: string; createAnnouncementDraft: boolean }) => Promise<boolean>;
  addCommentDisabledReason: string | null;
}

export const QuestionModal = ({ isOpen, close, question, addComment, addCommentDisabledReason }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={close} size="3xl">
      <ModalOverlay />
      <ModalContent height="80%" maxHeight={"80%"}>
        <ModalCloseButton />
        <ModalBody height={"full"} p={5}>
          <Flex flexDirection={"column"} height={"full"}>
            <Heading as="h2" size="md" py="2">
              {displayWrappedWithEllipsis(question.subject, 60)}
            </Heading>
            <QuestionMeta question={question} />
            <SupplierQuestionComments
              addCommentDisabledReason={addCommentDisabledReason}
              addComment={addComment}
              question={question}
              showCreateAnnouncementDraft={false}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
