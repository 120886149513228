import { Box, Divider, Flex, Spacer, Text } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { displayDate } from "../../../../common/formatting/displayDate";
import { ContractState } from "../../../../common/redux/reducers/contractReducer";
import { requireDataFields } from "../../typeguards";

interface Props {
  data: ContractState["contract"];
}

export const KeyDates = ({ data }: Props) => {
  const dataFields = requireDataFields(data);
  const { t } = useTranslation();
  const dates: React.ReactElement[] = [];
  const addedDataFields = dataFields.addedDataFields.map((e) => e.name);

  const getNoValueRow = (title: string): React.ReactElement => {
    return (
      <Flex key={title}>
        <Flex>
          <Text fontSize="md" color="smMuted">
            {title}
          </Text>
        </Flex>
        <Spacer />
        <Flex textAlign={"right"}>
          <Text fontStyle={"italic"}>{t("Not provided")}</Text>
        </Flex>
      </Flex>
    );
  };

  if (addedDataFields.includes("AwardDate")) {
    if (dataFields.awardDate) {
      dates.push(
        getKeyDateRow({
          title: t("Award date"),
          date: dataFields.awardDate,
          isBold: false,
        })
      );
    } else {
      dates.push(getNoValueRow(t("Award date")));
    }
  }
  if (addedDataFields.includes("StartDate")) {
    if (dataFields.startDate) {
      dates.push(
        getKeyDateRow({
          title: t("Start date"),
          date: dataFields.startDate,
          isBold: false,
        })
      );
    } else {
      dates.push(getNoValueRow(t("Start date")));
    }
  }
  if (addedDataFields.includes("WarrantyExpirationDate")) {
    if (dataFields.warrantyExpirationDate) {
      dates.push(
        getKeyDateRow({
          title: t("Warranty expiration date"),
          date: dataFields.warrantyExpirationDate,
          isBold: false,
        })
      );
    } else {
      dates.push(getNoValueRow(t("Warranty expiration date")));
    }
  }
  if (addedDataFields.includes("RenewalDate")) {
    if (dataFields.renewalDate) {
      dates.push(
        getKeyDateRow({
          title: t("Renewal date"),
          date: dataFields.renewalDate,
          isBold: false,
        })
      );
    } else {
      dates.push(getNoValueRow(t("Renewal date")));
    }
  }
  if (addedDataFields.includes("EndDate")) {
    if (dataFields.endDate) {
      if (dataFields.endDate.date) {
        dates.push(
          getKeyDateRow({
            title: t("End date"),
            date: dataFields.endDate.date,
            isBold: true,
          })
        );
      } else if (dataFields.endDate.hasNoEndDate) {
        dates.push(
          <Flex key={"hasNoEndDate"} flexDirection={"column"}>
            {dates.length > 0 && <Divider marginTop={"5px"} marginBottom={"5px"} />}
            <Flex>
              <Text fontSize="md" color="smMuted">
                {t("Contract has no expiration date")}
              </Text>
            </Flex>
          </Flex>
        );
      } else {
        dates.push(getNoValueRow(t("End date")));
      }
    }
  }
  if (addedDataFields.includes("NoticePeriod")) {
    if (dataFields.noticePeriod) {
      dates.push(
        <Flex key={"noticePeriod"}>
          <Flex>
            <Text fontSize="md" color="smMuted">
              {data.dataFields?.endDate?.date ? t("Notice deadline") : t("Notice period")}
            </Text>
          </Flex>
          <Spacer />
          <Flex>
            <Text whiteSpace={"nowrap"}>
              {data.dataFields?.endDate?.date
                ? moment(data.dataFields?.endDate.date)
                    .add(-(dataFields.noticePeriod ?? 0), "M")
                    .format("DD MMM YYYY")
                : `${dataFields.noticePeriod} ${t("month(s)")}`}
            </Text>
          </Flex>
        </Flex>
      );
    }
  }
  return (
    <Box padding="15px" w="100%">
      <Text fontWeight={"bold"} fontSize="sm" color="#808B9B">
        {t("Dates")}
      </Text>
      <Divider marginTop={"5px"} marginBottom={"5px"} />
      {dates.length === 0 && (
        <Text fontWeight={"light"} fontStyle={"italic"}>
          {t("No values provided")}
        </Text>
      )}
      {dates}
    </Box>
  );
};

export const getKeyDateRow = (props: { title: string; date: string; isBold: boolean }) => {
  const fontWeight = props.isBold ? "bold" : undefined;
  return (
    <Flex key={props.title}>
      <Flex>
        <Text fontSize="md" color="smMuted" fontWeight={fontWeight}>
          {props.title}
        </Text>
      </Flex>
      <Spacer />
      <Flex>
        <Text fontSize="md" color="smMuted" fontWeight={fontWeight}>
          {displayDate(props.date)}
        </Text>
      </Flex>
    </Flex>
  );
};
