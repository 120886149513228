import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { bffApi, EmailInviteContextDto, MeDto, OrgDto } from "../../../../autogen/bff-api";
import { authService, requireStringEnvVar } from "../../../../config";
import { clearOrganizationId } from "../../../local-storage/selected-organization";
import { AuthState, LoginCredentials, OrgCredentials, PersonCredentials } from "../../reducers/authStateReducer";

export type SignupError = "EmailAlreadyTaken" | "WeakPassword" | "UnknownError" | "CouldNotCreateUserInBff";

export const signupAsOrgThunk = createAsyncThunk(
  "authState/signupAsOrg",
  async (
    props: {
      loginCredentials: LoginCredentials;
      personCredentials: PersonCredentials;
      orgCredentials: OrgCredentials;
      invitationContext: EmailInviteContextDto | null;
    },
    { dispatch }
  ): Promise<AuthState> => {
    clearOrganizationId();
    const result = await authService.signup({
      email: props.loginCredentials.email,
      password: props.loginCredentials.password,
      firstName: props.personCredentials.firstName,
      lastName: props.personCredentials.lastName,
      roles: [],
    });

    switch (result) {
      case "EmailAlreadyTaken":
        return {
          type: "LoggedOut",
          isLoggingIn: false,
          loginError: null,
          isSigningUp: false,
          signupError: "EmailAlreadyTaken",
        };
      case "WeakPassword":
        return {
          type: "LoggedOut",
          isLoggingIn: false,
          loginError: null,
          isSigningUp: false,
          signupError: "WeakPassword",
        };
      case "PasswordBreached":
        return {
          type: "LoggedOut",
          isLoggingIn: false,
          loginError: null,
          isSigningUp: false,
          signupError: "PasswordBreached",
        };
      case "SimilarToIdentifier":
        return {
          type: "LoggedOut",
          isLoggingIn: false,
          loginError: null,
          isSigningUp: false,
          signupError: "SimilarToIdentifier",
        };
      case "Success": {
        const meAndOrg = await getMeAndCreateOrg({
          companyName: props.orgCredentials.companyName,
          countryId: props.orgCredentials.countryId,
          organizationNumber: props.orgCredentials.organizationNumber,
          invitationContext: props.invitationContext,
          dispatch,
        });

        if ("data" in meAndOrg) {
          window.location.replace(requireStringEnvVar("VITE_WEBAPP_BASE_URL"));
          return {
            type: "LoggedOut",
            isLoggingIn: false,
            loginError: null,
            isSigningUp: false,
            signupError: null,
          };
        } else {
          return {
            type: "LoggedOut",
            isLoggingIn: false,
            loginError: null,
            isSigningUp: false,
            signupError: meAndOrg.error.code ?? "UnknownError",
          };
        }
      }
      case "Failure":
        return {
          type: "LoggedOut",
          isLoggingIn: false,
          loginError: null,
          isSigningUp: false,
          signupError: "UnknownError",
        };
    }
  }
);

const getMeAndCreateOrg = async (props: {
  companyName: string;
  countryId: string;
  organizationNumber: string;
  invitationContext: EmailInviteContextDto | null;
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
}): Promise<
  | {
      data: {
        me: MeDto;
        org: OrgDto;
      };
    }
  | {
      error: {
        code: string;
        uniqueForCreateMeApiErrorDto: true;
      };
    }
> => {
  const meResponse = props.dispatch(bffApi.endpoints.getMe.initiate({}));
  meResponse.unsubscribe();
  const meResult = await meResponse;

  if ("data" in meResult && meResult.data) {
    const orgResult = await props.dispatch(
      bffApi.endpoints.createOrganizationForMe.initiate({
        createOrgRequest: {
          companyName: props.companyName,
          countryId: props.countryId,
          organizationNumber: props.organizationNumber,
          industryIds: [],
          emailInviteContext: props.invitationContext ?? undefined,
        },
      })
    );
    if ("data" in orgResult) {
      return {
        data: {
          me: meResult.data,
          org: orgResult.data,
        },
      };
    }
    const loginError = (orgResult.error as { data: { errorCode: string } })?.data?.errorCode;
    return {
      error: {
        code: loginError ?? "Unknown",
        uniqueForCreateMeApiErrorDto: true,
      },
    };
  }
  const loginError = (meResult.error as { data: { errorCode: string } })?.data?.errorCode;
  return {
    error: {
      code: loginError ?? "Unknown",
      uniqueForCreateMeApiErrorDto: true,
    },
  };
};
