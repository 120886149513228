import {
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useCallback, useRef } from "react";
import { FaFileDownload } from "react-icons/fa";
import { bffApi, SignedDocumentDto } from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { displayDateWithTime } from "../../../../common/formatting/displayDateWithTime";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { SignedDocumentStatusTag } from "../../edit/docs/signatures/SignedDocumentStatusTag";

export const AttachmentsModal = ({
  signedDocument,
  onClose,
}: {
  signedDocument: SignedDocumentDto;
  onClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const displayer = useApiError();
  const ref = useRef<HTMLAnchorElement>(null);

  const downloadAttachment = useCallback(
    async (attachmentId: string) => {
      const postRequestResponse = dispatch(
        bffApi.endpoints.getSignedAttachmentDownloadLink.initiate({
          docId: signedDocument.id,
          attachmentId,
        })
      );
      postRequestResponse.unsubscribe();
      const postRequestResult = await postRequestResponse;

      if ("data" in postRequestResult && postRequestResult.data && ref.current) {
        ref.current.href = postRequestResult.data.url;
        ref.current.click();
      } else {
        displayer.trigger(postRequestResult.error);
        return null;
      }
    },
    [dispatch, displayer, signedDocument.id]
  );

  return (
    <Modal isOpen={true} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex>
            <Text fontWeight="normal">
              {t("Attachments for the signed document")}
              <b>{" " + signedDocument.name}</b>
            </Text>
          </Flex>
          <SignedDocumentStatusTag status={signedDocument.status} />
          <Text fontSize="sm" fontWeight="normal">
            {signedDocument.deadline && `${t("Deadline")}: ${displayDateWithTime(signedDocument.deadline, t("at"))}`}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="6">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>{t("Attachment name")}</Th>
                <Th>{t("Download")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {signedDocument.attachments?.map((a) => {
                return (
                  <Tr key={a.id}>
                    <Td>{a.fileName}</Td>
                    <Td>
                      <IconButton
                        aria-label="download attachment"
                        variant="link"
                        isDisabled={signedDocument.status !== "Signed"}
                        icon={<Icon as={FaFileDownload} />}
                        onClick={() => downloadAttachment(a.id)}
                      />
                      <a ref={ref} style={{ display: "none" }} target="_blank" rel="noreferrer">
                        Hidden Link
                      </a>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
