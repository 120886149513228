import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../common/redux/hooks";
import { logoutThunk } from "../../../common/redux/thunks/auth/logout-thunk";
export const NoOrgsFoundExplanation = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  return (
    <Flex flexDirection={"column"}>
      <Heading as="h2" size="lg">
        {t("No orgs available")}
      </Heading>
      <br />
      <Text>
        {t("Your account may have been a part of an organization at one point, and then been removed")}
        . <br />
        {t(
          "Please get in touch with your workplace administrator if you wish to be a part of the same organization again"
        )}
        . <br /> <br />
        {t("If you want to create your own organization you can contact us at")} <b>hi@sourcemagnet.com</b>
        <br /> <br />
        <Button
          variant={"solid"}
          colorScheme="blue"
          onClick={() => {
            dispatch(logoutThunk());
          }}
        >
          {t("Click here to log out")}
        </Button>
      </Text>
    </Flex>
  );
};
