import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  EmailInviteLanguage,
  OrgUserInviteRole,
  useCreateOrgUserEmailInviteMutation,
} from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../common/errors/useApiError";
import { getEmailSecretOverride } from "../../../../common/local-storage/email-secret-override";
import { getPreferredLanguage } from "../../../../common/local-storage/language";
import { selectableRoles } from "./config";

interface FormValues {
  email: string;
  role: OrgUserInviteRole;
  language: EmailInviteLanguage;
}

export const InviteNewUser = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      language: getPreferredLanguage(),
    },
  });
  const authState = useLoggedInWithOrgContextState();
  const [sendInvite] = useCreateOrgUserEmailInviteMutation();
  const { t } = useTranslation();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const displayer = useApiError();

  const add = async (values: FormValues) => {
    setIsLoading(true);
    const result = await sendInvite({
      orgId: authState.selectedOrg.id,
      createOrgUserEmailInviteRequest: {
        email: values.email,
        role: values.role,
        language: values.language,
        emailInviteSecretOverride: getEmailSecretOverride() ?? undefined,
      },
    });
    setIsLoading(false);

    if ("data" in result) {
      toast({
        title: t("Invite sent!"),
        status: "success",
      });
      setValue("email", "");
    } else {
      displayer.trigger(result.error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(add)}>
        <FormControl isInvalid={errors.email !== undefined || errors.role !== undefined} mt="20px">
          <Flex flexDirection={"row"} w="100%">
            <Flex flexDirection={"column"} flex={2}>
              <FormLabel htmlFor={"email"}>{t("Email")}</FormLabel>
              <Input
                id={"email"}
                type="email"
                {...register("email", {
                  required: t("Please provide an email address") ?? "",
                  minLength: {
                    value: 2,
                    message: t("Must be at least 2 characters long") ?? "",
                  },
                })}
              />
            </Flex>
            <Flex flexDirection={"column"} flex={1} ml="3px">
              <FormLabel htmlFor={"language"}>{t("Email language")}</FormLabel>
              <Select
                placeholder={t("Email language") ?? ""}
                {...register("language", {
                  required: {
                    value: true,
                    message: t("Please select a language"),
                  },
                })}
              >
                <option key={"En"} value={"En"}>
                  {t("English")}
                </option>
                <option key={"No"} value={"No"}>
                  {t("Norwegian")}
                </option>
              </Select>
            </Flex>
            <Flex flexDirection={"column"} flex={1} ml="3px">
              <FormLabel htmlFor={"language"}>{t("Role")}</FormLabel>
              <Select
                placeholder={t("Select role") ?? ""}
                {...register("role", {
                  required: {
                    value: true,
                    message: t("Please select a role"),
                  },
                })}
              >
                {selectableRoles.map((role) => (
                  <option key={role.value} value={role.value}>
                    {t(role.label)}
                  </option>
                ))}
              </Select>
            </Flex>
          </Flex>
          <FormErrorMessage>
            {errors.email && errors.email.message} <br />
            {errors.language && errors.language.message} <br />
            {errors.role && errors.role.message}
          </FormErrorMessage>
        </FormControl>
        <Flex justifyContent={"end"} mt="20px">
          <Button type="submit" colorScheme="teal" isLoading={isLoading}>
            {t("Send invite")}
          </Button>
        </Flex>
      </form>
    </>
  );
};
