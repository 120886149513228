import { Box, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Select, Spinner } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Currency } from "../../../../../autogen/bff-api";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { containsError } from "../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../useContractState";

export const CurrencyAndTimezone = () => {
  const { t } = useTranslation();
  const contractState = useContractState();
  const [providedCurrency, setProvidedCurrency] = useState<Currency>("NOK");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState<string>("");
  const timezones = moment.tz.names();
  const timezoneErrorMessage = containsError(contractState, "EditTimezone") ? t("Update failed") : null;

  const currencyErrorMessage = containsError(contractState, "EditCurrency");
  const errorMessage = currencyErrorMessage || timezoneErrorMessage ? t("Update failed") : null;
  const dispatch = useAppDispatch();

  useEffect(() => {
    setProvidedCurrency(contractState.contract.currency);
    setSelectedTimezone(contractState.contract.timezone);
  }, []);

  const currencyEventHandler = async (value: Currency) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "EditCurrency",
          value: value,
        },
      })
    );
    setIsLoading(false);
  };

  const timezoneEventHandler = async (value: string) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "EditTimezone",
          value: value,
        },
      })
    );
    setIsLoading(false);
  };

  return (
    <FormControl isInvalid={errorMessage !== null}>
      <FormLabel htmlFor={"value"}>
        {t("Currency and time zone")} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Box borderRadius={"10px"}>
        <Flex>
          <Select
            value={providedCurrency}
            onChange={(e) => {
              const currency = e.target.value as Currency;
              if (isCurrency(currency)) {
                setProvidedCurrency(currency);
                currencyEventHandler(currency);
              } else {
                throw Error("should never happen");
              }
            }}
          >
            <option key={"NOK"} value={"NOK"}>
              NOK
            </option>
            <option key={"USD"} value={"USD"}>
              USD
            </option>
          </Select>
          <Select
            placeholder={t("Select time zone") ?? ""}
            ml="3px"
            value={selectedTimezone}
            onChange={(e) => {
              setSelectedTimezone(e.target.value);
              timezoneEventHandler(e.target.value);
            }}
          >
            {timezones.map((e) => (
              <option key={e} value={e}>
                {e}
              </option>
            ))}
          </Select>
        </Flex>
      </Box>
      <FormHelperText>{t("Provide the currency and time zone for this contract")}</FormHelperText>
      <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

const isCurrency = (e: Currency | unknown): e is Currency => {
  switch (e) {
    case "NOK":
    case "USD":
      return true;
    default:
      return false;
  }
};
