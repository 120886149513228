import { Box, Td } from "@chakra-ui/react";
import { isNumber } from "lodash";
import { BseDto, ReceivedBseBidDto } from "../../../../../autogen/bff-api";
import { Product, ProductGroup } from "../../../../../common/types";
import { useIsAwardedProduct } from "./useIsAwardedProduct";
import { useIsBestProductPrice } from "./useIsBestProductPrice";
import { useProductBid } from "./useProductBid";
import { useRemoveProductBid } from "./useRemoveProductBid";
import { useSelectProductBid } from "./useSelectProductBid";
import { useTotalProductPrice } from "./useTotalProductPrice";

export const SelectPriceCell = ({
  sourcingEvent,
  bid,
  bids,
  product,
  group,
}: {
  sourcingEvent: BseDto;
  bid: ReceivedBseBidDto;
  bids: ReceivedBseBidDto[];
  product: Product;
  group: ProductGroup;
}) => {
  const productBid = useProductBid({ productId: product.id, groupId: group.id, bid });
  const isBestProductPrice = useIsBestProductPrice({ bidId: bid.id, productId: product.id, group, bids });
  const isAwardedProduct = useIsAwardedProduct({ group, productId: product.id, bidId: bid.id, bids, sourcingEvent });
  const totalProductPrice = useTotalProductPrice({ productId: product.id, group, bid });
  const selectProductBid = useSelectProductBid({ bid, productId: product.id, group, eventId: sourcingEvent.id });
  const removeProductBid = useRemoveProductBid({ bid, productId: product.id, group, eventId: sourcingEvent.id });

  return (
    <Td
      key={bid.id}
      pt="3"
      textAlign={"right"}
      verticalAlign={"top"}
      cursor={productBid && isNumber(totalProductPrice) ? "pointer" : "default"}
      color={isBestProductPrice ? "green.500" : "yellow.500"}
      fontWeight={isAwardedProduct ? "bold" : "normal"}
      onClick={() => {
        if (!isNumber(totalProductPrice)) return;
        else if (isAwardedProduct) removeProductBid();
        else selectProductBid();
      }}
    >
      <Box
        width={"40"}
        height="full"
        rounded="md"
        px="4"
        py="1"
        border={isAwardedProduct ? "1px" : "none"}
        borderColor="gray.300"
        float={"right"}
      >
        {totalProductPrice?.toLocaleString()}
      </Box>
    </Td>
  );
};
