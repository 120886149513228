import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ContractState } from "../../../../common/redux/reducers/contractReducer";
import { useTranslationTools } from "../../../../common/useTranslationTools";
import { requireDataFields } from "../../typeguards";
import { GenericDetailRow, GenericDetails } from "./GenericDetails";

interface Props {
  data: ContractState["contract"];
}

export const Commercial = ({ data }: Props) => {
  const { t } = useTranslation();
  const dataFields = requireDataFields(data);
  const translationTools = useTranslationTools();
  const rows: GenericDetailRow[] = [];
  const addedFields = dataFields.addedDataFields.map((e) => e.name);
  const noValueProvided = <Text fontStyle={"italic"}>{t("Not provided")}</Text>;
  if (addedFields.includes("Risk")) {
    rows.push({
      type: "Data",
      name: t("Risk level"),
      value: dataFields.risk ? (
        <Text fontWeight={"semibold"}>{translationTools.getRiskTranslation(dataFields.risk)}</Text>
      ) : (
        noValueProvided
      ),
    });
  }
  if (addedFields.includes("Priority")) {
    rows.push({
      type: "Data",
      name: t("Priority level"),
      value: dataFields.priority ? (
        <Text fontWeight={"semibold"}>{translationTools.getPriorityTranslation(dataFields.priority)}</Text>
      ) : (
        noValueProvided
      ),
    });
  }

  return <GenericDetails title={t("Commercial")} rows={rows} />;
};
