import { Box, Grid, GridItem } from "@chakra-ui/react";
import { Layout } from "../../../common/layout/Layout";
import { CONTENT_WIDTH } from "../../../config";
import { Info } from "./Info";

export const ViewPersonalProfile = () => {
  return (
    <Layout>
      <Grid
        height="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={`auto 1fr`}
        templateAreas={`
                "infoLeft info infoRight"
                "linksLeft links linksRight"
                `}
        px="2"
      >
        <GridItem area={"info"}>
          <Box px="2.5">
            <Info />
          </Box>
        </GridItem>
        <GridItem backgroundColor="smBackground" area="linksLeft"></GridItem>
        <GridItem backgroundColor="smBackground" area="links" px="2.5"></GridItem>
        <GridItem backgroundColor="smBackground" area="linksRight"></GridItem>
      </Grid>
    </Layout>
  );
};
