import { Tag } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { OrganizationInviteStatus } from '../../../../autogen/bff-api'

interface Props {
    status: OrganizationInviteStatus
}

export const InvitedOrganizationStatusTag: React.FC<Props> = ({ status }) => {
    const { t } = useTranslation()
    const getTagMeta = (
        status: OrganizationInviteStatus
    ): {
        color: string
        text: string
    } => {
        switch (status) {
            case 'BidIsSubmitted':
                return {
                    color: 'purple',
                    text: t('Bid submitted'),
                }
            case 'BidWasNotNotSubmitted':
                return {
                    color: 'gray',
                    text: t('No bid submitted'),
                }
            case 'InvitationAccepted':
                return {
                    color: 'teal',
                    text: t('Intends to bid'),
                }
            case 'InvitationSent':
                return {
                    color: 'gray',
                    text: t('Has not seen event'),
                }
            case 'HasSeenEvent':
                return {
                    color: 'orange',
                    text: t('Has seen event'),
                }
            case 'IsLoser':
                return {
                    color: 'red',
                    text: t('Loser'),
                }
            case 'IsWinner':
                return {
                    color: 'green',
                    text: t('Winner!'),
                }
        }
    }

    const tagMeta = getTagMeta(status)

    return (
        <Tag
            colorScheme={tagMeta.color}
            mr="10px"
            size={'sm'}
            textAlign="center"
            paddingTop={'2px'}
            paddingBottom={'2px'}
        >
            {tagMeta.text}
        </Tag>
    )
}
