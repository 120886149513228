import { Input } from "@chakra-ui/react";
import { useState } from "react";

/**
 *
 * @param defaultDate - For an uncontrolled component, use this to initialize the date selector value.
 * @param date - For a controlled component, provide the date state as a string or null value.
 */
export const DateSelector = ({
  defaultDate,
  date,
  isDisabled,
  onChange,
  onBlur,
  autoFocus = false,
}: {
  defaultDate?: string;
  date?: string | null;
  onChange: (date?: string) => Promise<void> | void;
  onBlur?: () => Promise<void> | void;
  isDisabled?: boolean;
  autoFocus?: boolean;
}) => {
  const [internalDate, setInternalDate] = useState<string>(defaultDate || "");

  return (
    <Input
      fontSize="md"
      type="date"
      isDisabled={isDisabled}
      autoFocus={autoFocus}
      value={date !== undefined ? date ?? "" : internalDate}
      onChange={(e) => {
        const date = e.target.value;
        setInternalDate(date);
        onChange(date ? date : undefined);
      }}
      onBlur={onBlur}
      maxWidth={{ sm: "full", md: "60" }}
      border="1px solid"
      borderColor="smBorder"
      backgroundColor="smBackground"
      rounded="md"
      p="4"
    />
  );
};
