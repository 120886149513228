import { Box, Button, Flex, Icon, IconButton, Input, Tag, TagLabel, Text, useToast } from "@chakra-ui/react";
import { Editor } from "@tiptap/react";
import { components, OptionProps, Select } from "chakra-react-select";
import { t } from "i18next";
import { useCallback, useRef, useState } from "react";
import { FaFileImport, FaHighlighter, FaLink, FaListOl, FaListUl, FaTable, FaTimes } from "react-icons/fa";
import { useListDocumentTemplatesQuery } from "../../../autogen/bff-api";
import { useSub } from "../../subscription/useSub";
import { parseTiptapContent } from "./TipTap";

const rgbToHex = (rgb?: string) => {
  const regexRes = rgb?.match(/\d+/g)?.map(Number);
  if (!regexRes) return;
  const [r, g, b] = regexRes;
  if (!(isFinite(r) && isFinite(g) && isFinite(b))) return;
  return (
    "#" +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")
  );
};

export const MenuBar = ({ editor }: { editor?: Editor }) => {
  const toast = useToast();

  const sub = useSub();

  const { data, isLoading } = useListDocumentTemplatesQuery();

  const colorInputRef = useRef<HTMLInputElement>(null);
  const backgroundColorInputRef = useRef<HTMLInputElement>(null);

  const [showTemplateSelector, setShowTemplateSelector] = useState(false);

  const triggerColorInput = () => {
    colorInputRef.current?.click();
  };

  const triggerBackgroundColorInput = () => {
    backgroundColorInputRef.current?.click();
  };

  const setLink = useCallback(() => {
    if (!editor) return;

    if (editor.isActive("link")) {
      editor.chain().focus().unsetLink().run();
      return;
    }

    const protocol = "https://";

    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", !previousUrl ? protocol : previousUrl);

    if (url === null) return;

    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      return;
    }

    editor
      .chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: url.startsWith(protocol) ? url : protocol + url })
      .run();
  }, [editor]);

  if (!editor) return null;

  if (showTemplateSelector)
    return (
      <Flex width="full">
        <Select
          unstyled
          placeholder={`${t("Select document template")}...`}
          isLoading={isLoading}
          options={data?.documents.map((doc) => ({
            label: doc.name ? doc.name : t("No title") ?? "",
            value: doc.id,
            tags: doc.tags,
          }))}
          filterOption={(option, inputValue) => {
            const labelMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());
            const tagsMatch = option.data.tags.some((tag) => tag.toLowerCase().includes(inputValue.toLowerCase()));
            return labelMatch || tagsMatch;
          }}
          chakraStyles={{
            container: (provided) => ({
              ...provided,
              width: "full",
            }),
            control: (provided) => ({
              ...provided,
              rounded: "md",
              roundedBottom: "none",
              roundedRight: "none",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              _focus: { border: "none" },
            }),
            menu: (provided) => ({ ...provided, rounded: "md" }),
            menuList: (provided) => ({ ...provided, rounded: "md" }),
          }}
          components={{ Option: CustomOption }}
          onChange={(o) => {
            const doc = data?.documents.find((d) => d.id === o?.value);
            editor.commands.insertContent(parseTiptapContent(doc?.text));
            toast({
              variant: "subtle",
              status: "success",
              title: t("Content inserted"),
              description: `${t("Inserted content from the template called")} ${doc?.name}`,
            });
            setShowTemplateSelector(false);
          }}
        />
        <IconButton
          aria-label="close template selector"
          icon={<Icon as={FaTimes} />}
          roundedLeft="0"
          roundedBottom="0"
          onClick={() => setShowTemplateSelector(false)}
        />
      </Flex>
    );

  return (
    <Flex columnGap="1" justifyContent="center" maxHeight="10" py="2">
      <Button
        size="xs"
        onClick={() => editor.chain().focus().toggleBold().run()}
        backgroundColor={editor.isActive("bold") ? "smBackgroundTertiary" : ""}
        fontWeight={"bold"}
      >
        {t("B")}
      </Button>
      <Button
        size="xs"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        backgroundColor={editor.isActive("italic") ? "smBackgroundTertiary" : ""}
        fontStyle={"italic"}
      >
        {t("I")}
      </Button>
      <Button
        size="xs"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        backgroundColor={editor.isActive("underline") ? "smBackgroundTertiary" : ""}
        textDecoration={"underline"}
      >
        {t("U")}
      </Button>
      <Button
        size="xs"
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        backgroundColor={editor.isActive("heading", { level: 1 }) ? "smBackgroundTertiary" : ""}
        fontSize={"sm"}
      >
        {t("H1")}
      </Button>
      <Button
        size="xs"
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        backgroundColor={editor.isActive("heading", { level: 2 }) ? "smBackgroundTertiary" : ""}
        fontSize={"xs"}
      >
        {t("H2")}
      </Button>
      <Button
        size="xs"
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        backgroundColor={editor.isActive("heading", { level: 3 }) ? "smBackgroundTertiary" : ""}
        fontSize={"xx-small"}
      >
        {t("H3")}
      </Button>
      <Box position="relative" width="6" minWidth="6">
        <Input
          ref={colorInputRef}
          tabIndex={-1}
          type="color"
          size="xs"
          width="6"
          opacity="0"
          position="absolute"
          border="none"
          p="0"
          value={rgbToHex(editor.getAttributes("textStyle").color) ?? "#000000"}
          onChange={(e) => editor.chain().focus().setColor(e.target.value).run()}
        />
        <Button
          border="1px solid"
          borderColor="smBorder"
          backgroundColor="smBackground"
          color={editor.getAttributes("textStyle").color ?? "inherit"}
          size="xs"
          position="absolute"
          top="0"
          rounded="full"
          p="0"
          onClick={triggerColorInput}
        >
          A
        </Button>
      </Box>
      <Box position="relative" width="6" minWidth="6">
        <Input
          ref={backgroundColorInputRef}
          tabIndex={-1}
          type="color"
          size="xs"
          width="6"
          opacity="0"
          position="absolute"
          border="none"
          p="0"
          value={editor.getAttributes("highlight").color ?? "#ffffff"}
          onChange={(e) => editor.chain().focus().setHighlight({ color: e.target.value }).run()}
        />
        <Button
          aria-label="bg-color"
          border="1px solid"
          borderColor="smBorder"
          backgroundColor={editor.getAttributes("highlight").color ?? "smBackground"}
          size="xs"
          position="absolute"
          top="0"
          rounded="full"
          cursor="pointer"
          p="1"
          onClick={triggerBackgroundColorInput}
        >
          <Icon as={FaHighlighter} />
        </Button>
      </Box>
      <Button
        size="xs"
        aria-label="bullet-list"
        cursor="pointer"
        backgroundColor={editor.isActive("bulletList") ? "smBackgroundTertiary" : ""}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        <Icon as={FaListUl} />
      </Button>
      <Button
        size="xs"
        aria-label="ordered-list"
        cursor="pointer"
        backgroundColor={editor.isActive("orderedList") ? "smBackgroundTertiary" : ""}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        <Icon as={FaListOl} />
      </Button>
      <Button
        size="xs"
        onClick={setLink}
        backgroundColor={editor.isActive("link") ? "smBackgroundTertiary" : ""}
        fontSize={"xx-small"}
      >
        <Icon as={FaLink} />
      </Button>
      <Button
        size="xs"
        onClick={() => editor?.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
        isDisabled={editor.isActive("table")}
        backgroundColor={editor.isActive("table") ? "smBackgroundTertiary" : ""}
        fontSize={"xx-small"}
      >
        <Icon as={FaTable} />
      </Button>
      {editor.isActive("table") && (
        <Flex
          rowGap="1"
          flexDirection="column"
          overflow="visible"
          height="max-content"
          backgroundColor="smBackgroundSecondary"
          rounded="md"
          zIndex="2"
          shadow="sm"
          px="1"
          pb="1"
        >
          <Button
            zIndex="10"
            size="xs"
            minHeight="6"
            px="1"
            onClick={() => editor.chain().focus().addRowAfter().run()}
            fontSize={"xx-small"}
          >
            {t("Add row")}
          </Button>
          <Button
            zIndex="10"
            size="xs"
            minHeight="6"
            px="1"
            onClick={() => editor.chain().focus().addColumnAfter().run()}
            fontSize={"xx-small"}
          >
            {t("Add column")}
          </Button>
          <Button
            zIndex="10"
            size="xs"
            minHeight="6"
            px="1"
            onClick={() => editor.chain().focus().deleteRow().run()}
            fontSize={"xx-small"}
          >
            {t("Delete row")}
          </Button>
          <Button
            zIndex="10"
            size="xs"
            minHeight="6"
            px="1"
            onClick={() => editor.chain().focus().deleteColumn().run()}
            fontSize={"xx-small"}
          >
            {t("Delete column")}
          </Button>
          <Button
            zIndex="10"
            size="xs"
            minHeight="6"
            px="1"
            onClick={() => editor.chain().focus().deleteTable().run()}
            fontSize={"xx-small"}
          >
            {t("Delete table")}
          </Button>
        </Flex>
      )}
      {sub.hasDocumentTemplateAccess && (
        <Button
          size="xs"
          colorScheme="teal"
          onClick={() => setShowTemplateSelector(true)}
          fontSize={"xx-small"}
          leftIcon={<Icon as={FaFileImport} />}
        >
          {t("Insert template")}
        </Button>
      )}
    </Flex>
  );
};

const CustomOption = (props: OptionProps<{ label: string; value: string; tags: string[] }, false>) => {
  return (
    <components.Option {...props}>
      <Flex alignItems="center" _hover={{ backgroundColor: "smBackgroundSecondary", cursor: "pointer" }} p="2" m="0">
        <Text fontSize="sm" pr="1">
          {props.label}
        </Text>
        <Flex columnGap="1">
          {props.data.tags.map((tag) => (
            <Tag key={tag} size="sm" alignItems="center">
              <TagLabel>{tag}</TagLabel>
            </Tag>
          ))}
        </Flex>
      </Flex>
    </components.Option>
  );
};
