import { Button, Flex, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import {
  ContractPersonParticipantDto,
  ContractTaskDto,
  useListParticipantsForContractsQuery,
  useUpdateContractTaskMutation,
} from "../../../../../../autogen/bff-api";
import { SelectorValue } from "../../../../../../common/input/Selector/SelectorValue";
import { SingleSelector } from "../../../../../../common/input/Selector/SingleSelector";
import { displayPersonNameWithEmail } from "../../../sharing/AddExternalParticipantModal";

interface Props {
  task: ContractTaskDto;
  onEditComleted: (task: ContractTaskDto) => void;
  onCancel: () => void;
}

export const EditResponsiblePerson = ({ task, onEditComleted, onCancel }: Props) => {
  const [edit] = useUpdateContractTaskMutation();
  const [isLoadingResponsiblePerson, setIsLoadingResponsiblePerson] = useState(false);
  const toast = useToast();
  const [person, setPerson] = useState<SelectorValue>({
    label: displayPersonNameWithEmail(task.responsiblePerson),
    value: task.responsiblePerson.id,
  });

  const { data: participants, isLoading: isLoadingParticipants } = useListParticipantsForContractsQuery({
    contractId: task.contractId,
  });

  const requireParticipant = (personId: string): ContractPersonParticipantDto => {
    const participant = participants?.allPersons.find((e) => e.id === personId);
    if (!participant) throw Error("Participant not found.");
    return participant;
  };

  if (isLoadingParticipants) return <div>{t("Loading...")}</div>;

  if (!participants) return <div>{t("Something is wrong")}...</div>;

  return (
    <Flex flexDirection={"column"} width="full" mt="1">
      <SingleSelector
        value={person}
        options={participants.allPersons.map((p) => {
          if (!p.id) throw Error("Participant has no id.");
          return { label: displayPersonNameWithEmail(p), value: p.id };
        })}
        onChange={(value) => {
          if (value) setPerson(value);
        }}
        placeholder={{ text: t("Select responsible person"), color: "#a0aec0" }}
        noOptionsProvidedMessage={t("No persons available") ?? ""}
        noMatchingOptionsMessage={t("No matching persons") ?? ""}
      />
      <Flex mt="10px">
        <Button
          id="save-responsible-person-button"
          variant={"solid"}
          colorScheme="teal"
          size="xs"
          isLoading={isLoadingResponsiblePerson}
          onClick={async () => {
            setIsLoadingResponsiblePerson(true);
            const result = await edit({
              taskId: task.id,
              updateTaskRequest: {
                responsibleParty: {
                  organizationId: requireParticipant(person.value).organizationId,
                  personId: person.value,
                },
              },
            });
            setIsLoadingResponsiblePerson(false);

            if ("data" in result) onEditComleted(result.data);
            else toast({ title: t("Could not edit responsible person"), status: "error" });
          }}
        >
          {t("Save")}
        </Button>
        <Button variant={"solid"} colorScheme="gray" size="xs" ml="5px" onClick={onCancel}>
          {t("Cancel")}
        </Button>
      </Flex>
    </Flex>
  );
};
