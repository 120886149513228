import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BseAnnouncementDto, BseQuestionDto, BseQuestionStatus } from "../../../../../autogen/bff-api";
import { displayDateWithTime } from "../../../../../common/formatting/displayDateWithTime";
import { displayWrappedWithEllipsis } from "../../../../../common/formatting/displayWrappedWithEllipsis";
import { useQueryParams } from "../../../../../common/useQueryParams";
import { QuestionStatusTag } from "./QuestionStatusTag";
import { SupplierQuestionModal } from "./SupplierQuestionModal";
interface Props {
  canManageQuestions: boolean;
  addComment: (values: {
    comment: string;
    question: BseQuestionDto;
    createAnnouncementDraft: boolean;
  }) => Promise<boolean>;
  changeStatus: (status: BseQuestionStatus, questionId: string) => Promise<boolean>;
  questions: BseQuestionDto[];
  announcements: BseAnnouncementDto[];
}

export const SupplierQuestions = ({
  canManageQuestions,
  addComment,
  changeStatus,
  questions,
  announcements,
}: Props) => {
  const [questionId, setQuestionId] = useState<string>();
  const params = useQueryParams();
  const questionIdParam = params.get("question");
  const { t } = useTranslation();

  const matchingQuestions = useMemo(() => questions.filter((e) => e.id === questionId), [questionId, questions]);
  const sortedQuestions = useMemo(() => questions.slice().sort(sortByQuestionStatus), [questions]);

  useEffect(() => {
    if (questionIdParam) setQuestionId(questionIdParam);
  }, [questionIdParam]);

  return (
    <>
      {matchingQuestions.length > 0 && (
        <SupplierQuestionModal
          addCommentDisabledReason={null}
          canManageQuestions={canManageQuestions}
          addComment={async (values: { comment: string; createAnnouncementDraft: boolean }) => {
            const success = await addComment({
              comment: values.comment,
              question: matchingQuestions[0],
              createAnnouncementDraft: values.createAnnouncementDraft,
            });
            if (success && values.createAnnouncementDraft) {
              setQuestionId(undefined);
            }
            return success;
          }}
          question={matchingQuestions[0]}
          announcements={announcements}
          isOpen={true}
          close={() => {
            setQuestionId(undefined);
            params.clearSelectedQuestion();
          }}
          changeStatus={async (status: BseQuestionStatus) => {
            return changeStatus(status, matchingQuestions[0].id);
          }}
        />
      )}
      <Flex
        mt="20px"
        flexDirection={"column"}
        backgroundColor="smBackground"
        border="1px solid"
        borderColor="smBorder"
        rounded="md"
      >
        <Table variant="simple" mb="2.5">
          <Thead>
            <Tr>
              <Th>{t("Subject")}</Th>
              <Th>{t("Asked by")}</Th>
              <Th>{t("Asked at")}</Th>
              <Th>{t("Status")}</Th>
              <Th>{t("Comments")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedQuestions.map((e) => {
              return (
                <Tr
                  _hover={{ bg: "smBackgroundSecondary" }}
                  cursor={"pointer"}
                  key={e.id}
                  onClick={() => {
                    setQuestionId(e.id);
                    params.setSelectedQuestion(e.id);
                  }}
                >
                  <Td>
                    <Text fontSize={"md"}>{displayWrappedWithEllipsis(e.subject, 20)}</Text>
                  </Td>
                  <Td>
                    <Text fontSize={"md"}>
                      {e.askedByPerson.firstName} {e.askedByPerson.lastName}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize={"md"}>{displayDateWithTime(e.askedAt, t("at"))}</Text>
                  </Td>
                  <Td>
                    <QuestionStatusTag status={e.status} />
                  </Td>
                  <Td>{e.comments.length}</Td>
                </Tr>
              );
            })}
            {questions.length === 0 && (
              <Tr>
                <Td colSpan={5} width={"100%"} textAlign="center">
                  {t("No questions have been asked")}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Flex>
    </>
  );
};

export const sortByQuestionStatus = (
  a: {
    status: BseQuestionStatus;
  },
  b: {
    status: BseQuestionStatus;
  }
): number => {
  const statusOrder: BseQuestionStatus[] = ["Unresolved", "Resolved"];
  return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
};
