import {
    Box,
    Card,
    CardBody,
    Flex,
    Heading,
    Icon,
    List,
    ListIcon,
    ListItem,
    ModalBody,
    Text,
    useTimeout,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import {
    FaCheckCircle,
    FaInfoCircle,
    FaLightbulb,
    FaTimesCircle,
} from 'react-icons/fa'
import styles from './SustainabilityAnalysisContentResultStep.module.css'

type CardType = 'understanding' | 'included' | 'critical' | 'suggestions'

export const SustainabilityAnalysisContentResultStep: React.FC = () => {
    const [cardsToShow, setCardsToShow] = useState<CardType[]>([])
    const timeout = 500

    useTimeout(() => {
        setCardsToShow((e) => [...e, 'understanding'])
    }, 1 * timeout)

    useTimeout(() => {
        setCardsToShow((e) => [...e, 'included'])
    }, 2 * timeout)

    useTimeout(() => {
        setCardsToShow((e) => [...e, 'critical'])
    }, 3 * timeout)

    useTimeout(() => {
        setCardsToShow((e) => [...e, 'suggestions'])
    }, 4 * timeout)

    const understandingClassNames = !cardsToShow.includes('understanding')
        ? styles.fadeIn + ' ' + styles.hide
        : styles.fadeIn

    const includedClassNames = !cardsToShow.includes('included')
        ? styles.fadeIn + ' ' + styles.hide
        : styles.fadeIn

    const criticalClassNames = !cardsToShow.includes('critical')
        ? styles.fadeIn + ' ' + styles.hide
        : styles.fadeIn

    const suggestionsClassNames = !cardsToShow.includes('suggestions')
        ? styles.fadeIn + ' ' + styles.hide
        : styles.fadeIn

    return (
        <>
            <ModalBody pb="25px">
                <Card className={understandingClassNames}>
                    <CardBody borderRadius={'8px'}>
                        <Box>
                            <Flex>
                                <Flex mr="10px">
                                    <Icon
                                        as={FaInfoCircle}
                                        w="15px"
                                        h="15px"
                                        color="#3082ce"
                                    />
                                </Flex>
                                <Heading size="xs" textTransform="uppercase">
                                    Vår forståelse av anskaffelsen
                                </Heading>
                            </Flex>
                            <Text pt="2" pl="25px" fontSize="sm">
                                Anskaffelsen gjelder innkjøp av 5 stk varebiler.
                            </Text>
                        </Box>
                    </CardBody>
                </Card>
                <Card mt="20px" className={includedClassNames}>
                    <CardBody borderRadius={'8px'}>
                        <Box>
                            <Flex>
                                <Flex mr="10px">
                                    <Icon
                                        as={FaCheckCircle}
                                        w="15px"
                                        h="15px"
                                        color="#38a169"
                                    />
                                </Flex>
                                <Heading size="xs" textTransform="uppercase">
                                    Dine krav
                                </Heading>
                            </Flex>
                            <Text pt="2" pl="25px" fontSize="sm">
                                Vi fant 1 krav til bærekraft i
                                anskaffelsesdokumentene. Bra jobba! <br />{' '}
                                Følgende krav ble funnet:
                            </Text>
                            <List spacing={3} pl="40px" mt="10px">
                                <ListItem fontSize={'sm'}>
                                    <ListIcon
                                        as={FaCheckCircle}
                                        color="green.500"
                                    />
                                    Varebilen skal være elektrisk.
                                </ListItem>
                            </List>
                        </Box>
                    </CardBody>
                </Card>
                <Card mt="20px" className={criticalClassNames}>
                    <CardBody borderRadius={'8px'}>
                        <Box>
                            <Flex>
                                <Flex mr="10px">
                                    <Icon
                                        as={FaTimesCircle}
                                        w="15px"
                                        h="15px"
                                        color="#e53d3d"
                                    />
                                </Flex>
                                <Heading size="xs" textTransform="uppercase">
                                    Manglende krav
                                </Heading>
                            </Flex>
                            <Text pt="2" pl="25px" fontSize="sm">
                                Noen krav anses som kritiske når man gjør
                                anskaffelse av varebiler, og vi klarte ikke å
                                finne noe som beskriver følgende krav i
                                dokumentene:
                            </Text>
                            <List spacing={3} pl="40px" mt="10px">
                                <ListItem fontSize={'sm'}>
                                    <ListIcon
                                        as={FaTimesCircle}
                                        color="red.500"
                                    />
                                    Lette og tunge varebiler (N1-I, N1-II,
                                    N1-III) skal ha utslipp på 0g CO2/km.
                                </ListItem>
                            </List>
                        </Box>
                    </CardBody>
                </Card>
                <Card mt="20px" className={suggestionsClassNames}>
                    <CardBody borderRadius={'8px'}>
                        <Box>
                            <Flex>
                                <Flex mr="10px">
                                    <Icon
                                        as={FaLightbulb}
                                        w="16px"
                                        h="16px"
                                        color="#7f5ad5"
                                    />
                                </Flex>
                                <Heading size="xs" textTransform="uppercase">
                                    Inspirasjon
                                </Heading>
                            </Flex>
                            <Text pt="2" pl="25px" fontSize="sm">
                                Dette bør også tenkes på før man gjør
                                anskaffelser av lette varebiler:
                            </Text>
                            <List spacing={3} pl="40px" mt="10px">
                                <ListItem fontSize={'sm'}>
                                    <ListIcon
                                        as={FaLightbulb}
                                        color="purple.500"
                                    />
                                    Transport er ofte en av de største kildene
                                    til utslipp i en virksomhet.
                                </ListItem>
                                <ListItem fontSize={'sm'}>
                                    <ListIcon
                                        as={FaLightbulb}
                                        color="purple.500"
                                    />
                                    Å skifte fra fossile kjøretøy til
                                    nullutslippskjøretøy vil bidra vesentlig til
                                    å redusere klimautslipp.
                                </ListItem>
                                <ListItem fontSize={'sm'}>
                                    <ListIcon
                                        as={FaLightbulb}
                                        color="purple.500"
                                    />
                                    Før du vurderer å anskaffe nye kjøretøy bør
                                    du se på muligheter for å kartlegge og
                                    optimalisere egen kjøretøypark.
                                </ListItem>
                            </List>
                        </Box>
                    </CardBody>
                </Card>
            </ModalBody>
        </>
    )
}
