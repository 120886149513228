import {
  Configuration,
  FrontendApi,
  FrontendApiUpdateSettingsFlowRequest,
  LoginFlow,
  LogoutFlow,
  RecoveryFlow,
  RegistrationFlow,
  Session,
  SettingsFlow,
  UiContainer,
  UiNode,
  VerificationFlow,
} from "@ory/client";
import { isAxiosError } from "axios";

export class OryAuthService {
  public readonly ory: FrontendApi;

  constructor() {
    this.ory = new FrontendApi(
      new Configuration({
        basePath: import.meta.env.VITE_ORY_BASE_URL,
        baseOptions: {
          withCredentials: true,
          credentials: "include",
        },
      })
    );
  }

  async getSession(): Promise<Session> {
    return (await this.ory.toSession()).data;
  }

  async login(values: { email: string; password: string; refresh: boolean }): Promise<LoginResult> {
    try {
      const loginFlow = await this.createBrowserLoginFlow(values.refresh);

      await this.ory.updateLoginFlow({
        flow: loginFlow.id,
        updateLoginFlowBody: {
          csrf_token: getCsrfValue(loginFlow.ui.nodes),
          identifier: values.email,
          password: values.password,
          method: "password",
        },
      });

      return "Success";
    } catch (e) {
      if (errorContainsErrorMessage(e, "The provided credentials are invalid")) {
        return "NotAuthorized";
      }

      return "Failure";
    }
  }
  async signup(values: {
    email: string;
    password: string;
    firstName?: string;
    lastName?: string;
    roles: string[];
  }): Promise<SignupResult> {
    try {
      const registrationFlow = await this.createBrowserRegistrationFlow();

      await this.ory.updateRegistrationFlow({
        flow: registrationFlow.id,
        updateRegistrationFlowBody: {
          csrf_token: getCsrfValue(registrationFlow.ui.nodes),
          password: values.password,
          method: "password",
          traits: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            roles: values.roles,
          },
        },
      });
      return "Success";
    } catch (error) {
      if (errorContainsErrorMessage(error, "An account with the same identifier")) {
        return "EmailAlreadyTaken";
      } else if (errorContainsErrorMessage(error, "The password has been found in data breaches")) {
        return "PasswordBreached";
      } else if (
        errorContainsErrorMessage(error, "The password can not be used because it is too similar to the identifier")
      ) {
        return "SimilarToIdentifier";
      } else if (errorContainsErrorMessage(error, "The password can not be used")) {
        return "WeakPassword";
      }
      return "Failure";
    }
  }

  async signupWithAzureAD(): Promise<boolean> {
    try {
      const registrationFlow = await this.createBrowserRegistrationFlow();

      await this.ory.updateRegistrationFlow({
        flow: registrationFlow.id,
        updateRegistrationFlowBody: {
          csrf_token: getCsrfValue(registrationFlow.ui.nodes),
          method: "oidc",
          provider: "microsoft",
        },
      });

      return true;
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.response?.status === 422) {
          const redirectUrl = `${e.response.data.redirect_browser_to}`;

          window.location.replace(redirectUrl);
          return true;
        }
      }
      return false;
    }
  }

  async createBrowserRegistrationFlow(): Promise<RegistrationFlow> {
    try {
      const result = await this.ory.createBrowserRegistrationFlow();
      return result.data;
    } catch (err) {
      throw Error("ory.createBrowserRegistrationFlow failed");
    }
  }

  async createBrowserLoginFlow(refresh: boolean): Promise<LoginFlow> {
    try {
      const result = await this.ory.createBrowserLoginFlow({
        refresh: refresh,
      });
      return result.data;
    } catch (err) {
      throw Error("ory.createBrowserLoginFlow failed");
    }
  }

  async createBrowserLogoutFlow(): Promise<LogoutFlow> {
    try {
      const result = await this.ory.createBrowserLogoutFlow();
      return result.data;
    } catch (err) {
      throw Error("ory.createBrowserLogoutFlow failed");
    }
  }

  async createBrowserSettingsFlow(): Promise<SettingsFlow> {
    try {
      const result = await this.ory.createBrowserSettingsFlow();
      return result.data;
    } catch (err) {
      throw Error("ory.createBrowserSettingsFlow failed");
    }
  }

  async createBrowserRecoveryFlow(): Promise<RecoveryFlow> {
    try {
      const result = await this.ory.createBrowserRecoveryFlow();
      return result.data;
    } catch (err) {
      throw Error("ory.createBrowserLogoutFlow failed");
    }
  }

  async createBrowserVerificationFlow(): Promise<VerificationFlow> {
    try {
      const result = await this.ory.createBrowserVerificationFlow();
      return result.data;
    } catch (err) {
      throw Error("ory.createBrowserLogoutFlow failed");
    }
  }

  async logout(): Promise<boolean> {
    try {
      const logoutFlow = await this.createBrowserLogoutFlow();
      await this.ory.updateLogoutFlow({
        token: logoutFlow.logout_token,
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  async sendVerificationLink(email: string): Promise<VerificationFlow | undefined> {
    try {
      const verificationFlow = await this.createBrowserVerificationFlow();

      const result = await this.ory.updateVerificationFlow({
        flow: verificationFlow.id,
        updateVerificationFlowBody: {
          email: email,
          csrf_token: getCsrfValue(verificationFlow.ui.nodes),
          method: "code",
        },
      });

      if (responseContainsErrorMessage(result.data.ui)) {
        return undefined;
      }

      return verificationFlow;
    } catch (err) {
      return undefined;
    }
  }

  async updateTraits(props: {
    firstName?: string;
    lastName?: string;
    email: string;
  }): Promise<"success" | "reauth" | "failure"> {
    try {
      const settingsFlow = await this.createBrowserSettingsFlow();

      const result = await this.ory.updateSettingsFlow({
        flow: settingsFlow.id,
        updateSettingsFlowBody: {
          csrf_token: getCsrfValue(settingsFlow.ui.nodes),
          method: "profile",
          traits: {
            firstName: props.firstName,
            lastName: props.lastName,
            email: props.email,
          },
        },
      });

      if (responseContainsErrorMessage(result.data.ui)) {
        return "failure";
      }

      return "success";
    } catch (err) {
      return "reauth";
    }
  }

  async provideEmailCode(values: { code: string; flowId: string }): Promise<boolean> {
    try {
      const flow = await this.ory.getVerificationFlow({
        id: values.flowId,
      });

      const body = {
        csrf_token: getCsrfValue(flow.data.ui.nodes),
        method: "code",
        code: values.code,
      } as any;

      const result = await this.ory.updateVerificationFlow({
        flow: flow.data.id,
        updateVerificationFlowBody: body,
      });
      if (responseContainsErrorMessage(result.data.ui)) {
        return false;
      }
      return true;
    } catch (err) {
      return false;
    }
  }

  async initForgottenPasswordFlow(email: string, recoveryFlow: RecoveryFlow): Promise<InitForgottenPasswordFlowResult> {
    try {
      await this.ory.updateRecoveryFlow({
        flow: recoveryFlow.id,
        updateRecoveryFlowBody: {
          csrf_token: getCsrfValue(recoveryFlow.ui.nodes),
          email: email,
          method: "code",
        },
      });

      return "Success";
    } catch (e) {
      return "Failure";
    }
  }

  async getPrivilegedRecoverySession(props: { code: string; recoveryFlow: RecoveryFlow }): Promise<boolean> {
    try {
      const result = await this.ory.updateRecoveryFlow({
        flow: props.recoveryFlow.id,
        updateRecoveryFlowBody: {
          csrf_token: getCsrfValue(props.recoveryFlow.ui.nodes),
          method: "code",
          code: props.code,
        },
      });

      if (responseContainsErrorMessage(result.data.ui)) {
        return false;
      } else {
        return true;
      }
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.response?.status === 422) {
          return true;
        }
      }
      return false;
    }
  }

  async changePassword(props: { email: string; newPassword: string }): Promise<ChangePasswordResult> {
    try {
      const updateSettingsFlow = await this.ory.createBrowserSettingsFlow();

      await this.ory.updateSettingsFlow({
        flow: updateSettingsFlow.data.id,
        updateSettingsFlowBody: {
          csrf_token: getCsrfValue(updateSettingsFlow.data.ui.nodes),
          password: props.newPassword,
          traits: {
            email: props.email,
          },
          method: "password",
        },
      } as FrontendApiUpdateSettingsFlowRequest);

      return "Success";
    } catch (e) {
      if (errorContainsErrorMessage(e, "The password has been found in data breaches")) {
        return "PasswordBreached";
      } else if (
        errorContainsErrorMessage(e, "The password can not be used because it is too similar to the identifier")
      ) {
        return "SimilarToIdentifier";
      } else if (errorContainsErrorMessage(e, "The password can not be used")) {
        return "WeakPassword";
      } else {
        return "Failure";
      }
    }
  }
}

export interface CsrfNode {
  attributes: {
    name: string;
    value: string;
  };
}

export const getCsrfValue = (nodes: UiNode[]): string => {
  const csrfNode = nodes.filter((e) => {
    const node = e as CsrfNode;
    return node.attributes.name === "csrf_token";
  })[0] as CsrfNode;

  return csrfNode.attributes.value;
};

interface OryAuthError {
  id: string;
  ui: UiContainer;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isOryAuthError = (e: any): e is OryAuthError => {
  return !!e.ui.nodes;
};

const errorContainsErrorMessage = (e: unknown, message: string): boolean => {
  if (isAxiosError(e)) {
    const data = e.response?.data;
    const isOryError = isOryAuthError(data);

    if (isOryError) {
      return responseContainsErrorSpecificErrorMessage(data.ui, message);
    }
  }
  return false;
};

const responseContainsErrorSpecificErrorMessage = (ui: UiContainer, message: string): boolean => {
  const genericTexts = ui.messages?.map((e) => e.text) ?? [];

  const inputTexts = ui.nodes.flatMap((e) => e.messages.map((e) => e.text));

  const errorTexts = genericTexts.concat(inputTexts);

  const matchingTexts = errorTexts.filter((e) => e.indexOf(message) !== -1);
  return matchingTexts.length > 0;
};

const responseContainsErrorMessage = (ui: UiContainer): boolean => {
  const errorMessages = ui.messages?.filter((e) => e.type === "error") ?? [];
  return errorMessages.length > 0 ?? false;
};

export type LoginResult = "Success" | "UserDoesNotExist" | "NotAuthorized" | "Failure";

export type SignupResult =
  | "Success"
  | "EmailAlreadyTaken"
  | "WeakPassword"
  | "PasswordBreached"
  | "SimilarToIdentifier"
  | "Failure";

export type ProvideEmailCodeResult = "Success" | "WrongCode" | "ExpiredCode" | "Failure";

export type InitForgottenPasswordFlowResult = "Success" | "UserNotFound" | "Failure";

export type ChangePasswordResult = "Success" | "Failure" | "WeakPassword" | "PasswordBreached" | "SimilarToIdentifier";
