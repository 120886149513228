import {
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Select
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useBaseData } from '../../../../../common/useBaseData'

interface FormValues {
    country: string
}

export const Country: React.FC = () => {
    const { t } = useTranslation()
    const baseData = useBaseData()
    const {
        register,
        formState: { errors },
    } = useFormContext<FormValues>()

    return (
        <FormControl id="country" mt="5px" isInvalid={errors.country != null}>
            <FormLabel>{t('Company location')}</FormLabel>
            <Select
                placeholder={t('Select a country') ?? ''}
                {...register('country', {
                    required: {
                        value: true,
                        message: t('Select a country for your company'),
                    },
                })}
            >
                {baseData.countries.map((e) => (
                    <option key={e.id} value={e.id}>
                        {e.name}
                    </option>
                ))}
            </Select>
            <FormErrorMessage>
                {errors.country && errors.country.message}
            </FormErrorMessage>
            <FormHelperText>
                {t('Please select the country where your company is located')}
            </FormHelperText>
        </FormControl>
    )
}