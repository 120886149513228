import { Select } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    onChange: (type: LatestContractsFilterType) => void
}

export type LatestContractsFilterType = 'all' | 'imResponsible'

export const Filter: React.FC<Props> = ({ onChange }) => {
    const { t } = useTranslation()
    return (
        <Select
            size={'sm'}
            onChange={(e) => {
                const selected = toType(e.target.value)

                onChange(selected)
            }}
        >
            <option key={'all'} value={'all'}>
                {t('All')}
            </option>
            <option key={'imResponsible'} value={'imResponsible'}>
                {t("I'm responsible")}
            </option>
        </Select>
    )
}

const toType = (raw: string): LatestContractsFilterType => {
    if (raw === 'all') {
        return 'all'
    } else if (raw === 'imResponsible') {
        return 'imResponsible'
    } else {
        throw Error('should never happen')
    }
}
