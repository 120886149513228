import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useUpdateOrganizationMutation } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../common/errors/useApiError";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { OrgDto, updateSelectedOrg } from "../../../../common/redux/reducers/authStateReducer";
import { useBaseData } from "../../../../common/useBaseData";

interface FormValues {
  country: string;
}

interface Props {
  currentValue: string;
}

export const ChangeCountry = ({ currentValue }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const [editOrg, { isLoading }] = useUpdateOrganizationMutation();
  const baseData = useBaseData();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { t } = useTranslation();
  const displayer = useApiError();

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      country: currentValue,
    },
  });

  if (!baseData) return <div>{t("Loading")}...</div>;

  const newValue = watch("country");

  const onSubmit = async (values: FormValues) => {
    setIsRefreshing(true);
    const response = await editOrg({
      orgId: authState.selectedOrg.id,
      editOrganizationRequest: {
        country: {
          countryId: values.country,
        },
      },
    });
    if ("data" in response) {
      dispatch(updateSelectedOrg(response.data as OrgDto));
    } else {
      displayer.trigger(response.error);
    }
    setIsRefreshing(false);
    toast({ title: t("Country has been updated!"), status: "success" });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="country" mt="20px" isInvalid={errors.country != null}>
        <FormLabel>{t("Company location")}</FormLabel>
        <Select placeholder={t("Select a country for your company") ?? ""} {...register("country")}>
          {baseData.countries.map((e) => (
            <option key={e.id} value={e.id}>
              {e.name}
            </option>
          ))}
        </Select>
        <FormHelperText>{t("Please select the country your company is located in")}.</FormHelperText>
        <FormErrorMessage>{errors.country && errors.country.message}</FormErrorMessage>
      </FormControl>
      {currentValue.trim() !== newValue.trim() && (
        <Button
          mt="10px"
          leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
          variant={"solid"}
          colorScheme="teal"
          size={"sm"}
          isLoading={isLoading || isRefreshing}
          type="submit"
        >
          {t("Save change")}
        </Button>
      )}
    </form>
  );
};
