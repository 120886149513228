import { Alert, AlertIcon, Box, Button, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmEmailModal } from "../auth/ConfirmEmailModal";
import { useLoggedInWithOrgContextState } from "../auth/useLoggedInWithOrgContextState";
import { useAppDispatch } from "../redux/hooks";
import { reloadMeThunk } from "../redux/thunks/auth/reload-me-thunk";
import { Banner } from "./Banner";

export const VerifyEmailBanner = () => {
  const authState = useLoggedInWithOrgContextState();
  const dispatch = useAppDispatch();
  const [isReloading, setIsReloading] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();

  const [showConfirmEmailModal, setShowConfirmEmailModal] = useState(false);

  return (
    <>
      {showConfirmEmailModal && <ConfirmEmailModal onClose={() => setShowConfirmEmailModal(false)} />}
      <Banner>
        <Box mt="20px">
          <Alert status="warning" borderRadius={"10px"}>
            <AlertIcon />
            <Text>
              {t("Please verify your email address")}
              <Button
                variant="link"
                colorScheme="black"
                ml="3px"
                mr="3px"
                onClick={() => setShowConfirmEmailModal(true)}
              >
                <u>
                  <b>{authState.me.email}</b>
                </u>
              </Button>
              {t("or")}
              <Button
                variant="link"
                colorScheme="black"
                ml="3px"
                mr="3px"
                isLoading={isReloading}
                onClick={async () => {
                  setIsReloading(true);
                  await dispatch(reloadMeThunk());
                  setIsReloading(false);
                  toast({
                    title: `${t("Page reloaded")}!`,
                    status: "info",
                    position: "top-right",
                  });
                }}
              >
                <u>
                  <b>{t("refresh the page")}</b>
                </u>
              </Button>
              {t("if it's already done")}
            </Text>
          </Alert>
        </Box>
      </Banner>
    </>
  );
};
