import { Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ContractTaskCommentDto } from '../../../../../../autogen/bff-api'
import { displayDateWithTime } from '../../../../../../common/formatting/displayDateWithTime'

interface Props {
    comment: ContractTaskCommentDto
}

export const CommentContent: React.FC<Props> = ({ comment }) => {
    const { t } = useTranslation()
    if (comment.text) {
        return <Text>{comment.text}</Text>
    } else if (comment.deletedAt) {
        return (
            <Text fontStyle={'italic'} fontSize="sm">
                {t('Comment was deleted')}{' '}
                {displayDateWithTime(comment.deletedAt, t('at'))}
            </Text>
        )
    } else {
        throw new Error('Invalid comment')
    }
}
