import { Icon, IconButton, Input, Select, Td, Tr, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { isNil } from "lodash";
import { Dispatch, JSXElementConstructor, ReactElement, SetStateAction } from "react";
import { FaTrash } from "react-icons/fa";
import { parseString } from "../parsers/parseString";
import {
  Product,
  ProductField,
  ProductFieldType,
  ProductGroup,
  ProductGroupDraft,
  ProductTemplateField,
  productFieldTypes,
} from "../types";
import { ConfirmationModalConfig } from "../useConfirmationModal";

export const ProductFieldForm = ({
  field,
  setGroup,
  group,
  isDisabled,
  confirmationModal,
}: {
  field: ProductTemplateField;
  group: ProductGroup | ProductGroupDraft;
  setGroup: Dispatch<SetStateAction<ProductGroup | ProductGroupDraft>>;
  isDisabled?: boolean;
  confirmationModal: {
    show: (config: ConfirmationModalConfig) => void;
    modal: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  };
}) => {
  const toast = useToast();

  const typeMap: { [key in ProductFieldType]: string } = {
    Number: t("Number"),
    Text: t("Text"),
    Boolean: t("Boolean"),
    Quantity: t("Quantity"),
  };

  const handleChange = ({ type, name }: { type?: ProductFieldType | "Quantity"; name?: string; oldName?: string }) => {
    if (type === "Quantity") {
      confirmationModal.show({
        title: t("Convert to quantity column?"),
        description: `${t("Are you sure you want to convert this column to a quantity column")}? 
          ${t("This will replace the current quantity column")}. 
          ${t("When provided, quantity will be used to calculate total product price")}.`,
        onConfirmed: () => {
          setGroup(convertFieldToQuantity({ name }));
        },
        confirmButtonColor: "teal",
      });
    } else setGroup(updateGroup({ type, name }));
  };

  const handleRemoveField = () => {
    const { updatedProducts, updatedTemplateFields } = removeField();
    setGroup({ ...group, productFields: updatedTemplateFields, products: updatedProducts });
  };

  const updateGroup = ({
    type,
    name,
  }: {
    type?: ProductFieldType;
    name?: string;
  }): ProductGroup | ProductGroupDraft => {
    const fieldIndex = group.productFields.findIndex((f) => f.id === field.id);
    const updatedTemplateFields = [...group.productFields];
    const updatedTemplateField = { ...field, type: type ? type : field.type, name: name ?? field.name };
    updatedTemplateFields[fieldIndex] = updatedTemplateField;
    try {
      const updatedProducts = group.products?.map((product, i) => {
        const updatedProduct: Product = { ...product };
        const productField = updatedProduct.productFields.find((f) => f.id === field.id);
        if (productField) {
          if (type) setProductField({ productField, type, i });
          if (name || name === "") productField.name = name;
          return updatedProduct;
        } else {
          updatedProduct.productFields.splice(fieldIndex, 0, { ...updatedTemplateField, value: undefined });
          return {
            id: product.id,
            productFields: updatedProduct.productFields,
            quantity: updatedProduct.quantity,
            conversation: [],
          };
        }
      });
      return { ...group, productFields: updatedTemplateFields, products: updatedProducts };
    } catch (e) {
      return { ...group };
    }
  };

  const convertFieldToQuantity = ({ name }: { name?: string }): ProductGroup | ProductGroupDraft => {
    const updatedTemplateFields = [...group.productFields];
    const fieldIndex = group.productFields.findIndex((f) => f.id === field.id);
    updatedTemplateFields.splice(fieldIndex, 1);
    const updatedProducts = group.products?.map((product, i) => {
      const updatedProduct: Product = { ...product };
      const fieldIndex = updatedProduct.productFields.findIndex((f) => f.id === field.id);
      if (fieldIndex !== -1) {
        const quantityField = updatedProduct.productFields[fieldIndex];
        const value = quantityField.value;
        if (!isNil(value) && typeof parseString(`${value}`) !== "number") {
          toast({ description: `${t("Could not change field type - invalid number on row")} ${i + 1}` });
          throw Error(`Could not change field type - invalid number on row=${i}`);
        }
        updatedProduct.quantity = {
          id: quantityField.id,
          name: name ?? quantityField.name ?? t("Quantity"),
          isRequired: quantityField.isRequired,
          populatedBy: quantityField.populatedBy,
          value: isNil(value) ? undefined : Number(value),
        };
        return updatedProduct;
      }
      return updatedProduct;
    });
    return { ...group, productFields: updatedTemplateFields, products: updatedProducts };
  };

  const setProductField = ({
    productField,
    type,
    i,
  }: {
    productField: ProductField;
    type: ProductFieldType;
    i: number;
  }) => {
    productField.type = type;
    const value = productField.value;
    if (productField.populatedBy === "Supplier") return;
    if (type === "Text") productField.value = `${value ?? ""}`;
    if (type === "Number") {
      if (value !== undefined && value !== null && typeof parseString(`${value}`) !== "number") {
        toast({ description: `${t("Could not change field type - invalid number on row")} ${i + 1}` });
        throw Error(`Could not change field type - invalid number on row=${i}`);
      }
      productField.value = isNil(value) ? null : Number(value);
    }
    if (type === "Boolean") {
      if (value !== undefined && value !== null && typeof parseString(`${value}`) !== "boolean") {
        toast({ description: `${t("Could not change field type - invalid boolean on row")} ${i + 1}` });
        throw Error(`Could not change field type - invalid boolean on row=${i}`);
      }
      productField.value = isNil(value) ? null : parseString(`${value}`);
    }
  };

  const removeField = () => {
    const updatedTemplateFields = [...group.productFields];
    const fieldIndex = group.productFields.findIndex((f) => f.id === field.id);
    updatedTemplateFields.splice(fieldIndex, 1);
    const updatedProducts = group.products?.map((product) => {
      const updatedProduct: Product = { ...product };
      const productFieldIndex = updatedProduct.productFields.findIndex((f) => f.id === field.id);
      if (productFieldIndex !== -1) updatedProduct.productFields.splice(productFieldIndex, 1);
      return updatedProduct;
    });
    return { updatedProducts, updatedTemplateFields };
  };

  return (
    <Tr>
      <Td>
        <Input
          size="xs"
          rounded="md"
          backgroundColor="smBackground"
          isDisabled={isDisabled}
          value={field.name}
          onChange={(e) => handleChange({ name: e.target.value })}
        />
      </Td>
      <Td>
        <Select
          backgroundColor="smBackground"
          size="xs"
          isDisabled={isDisabled}
          value={field.type}
          rounded={"md"}
          onChange={(option) => handleChange({ type: option.target.value as ProductFieldType | "Quantity" })}
        >
          {productFieldTypes.map((type) => (
            <option key={type} value={type}>
              {typeMap[type]}
            </option>
          ))}
          {field.populatedBy === "Buyer" && <option value={"Quantity"}>{t("Quantity")}</option>}
        </Select>
      </Td>
      <Td textAlign="center">
        {!isDisabled && (
          <IconButton
            aria-label="remove field"
            icon={<Icon as={FaTrash} />}
            size="xs"
            mr="2"
            variant={"ghost"}
            color="gray.500"
            onClick={handleRemoveField}
          />
        )}
      </Td>
    </Tr>
  );
};
