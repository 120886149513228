import { Box, Flex, Icon, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { Dispatch, SetStateAction, useState } from "react";
import { FaCompressAlt, FaExpandAlt } from "react-icons/fa";
import { BseDto, ReceivedBseBidDto, useUpdateBasicSourcingEventMutation } from "../../../../../autogen/bff-api";
import { Message } from "../../../../../common/ConversationModal";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { selectAllBidsInGroup } from "../../../../../common/redux/reducers/basicSourcingEventReducer";
import { Product, ProductDraft, ProductGroup } from "../../../../../common/types";
import { eventIsCompleted } from "../../../eventIsCompleted";
import {
  findProductGroupBid,
  getProductGroupPrice,
  getSelectedProductGroupPrice,
  getSumOfBestGroupPrices,
} from "./BidEvaluationUtils";
import { EvaluationReportRow } from "./EvaluationReportRow";
import { EvaluationRow } from "./EvaluationRow";

export const EvaluationTable = ({
  group,
  sourcingEvent,
  bids,
  setProductConversation,
}: {
  group: ProductGroup;
  sourcingEvent: BseDto;
  bids?: ReceivedBseBidDto[];
  setProductConversation: Dispatch<
    SetStateAction<
      | {
          conversation: Message[];
          title: string;
          productGroup: ProductGroup;
          product: Product | ProductDraft;
        }
      | undefined
    >
  >;
}) => {
  const dispatch = useAppDispatch();

  const [updateSourcingEvent] = useUpdateBasicSourcingEventMutation();

  const [isExpanded, setIsExpanded] = useState<boolean>();

  const selectAllBidsInGroupFromSupplier = ({ bid, group }: { bid: ReceivedBseBidDto; group: ProductGroup }) => {
    const groupBid = findProductGroupBid({ groupId: group.id, bid });
    if (!groupBid) return;
    dispatch(selectAllBidsInGroup({ bid, groupBid }));
    updateSourcingEvent({
      eventId: sourcingEvent.id,
      editSourcingEventRequest: {
        selectProductBids: [
          {
            productGroupId: group.id,
            bids: groupBid.productBids.map((productBid) => ({
              productId: productBid.productId,
              bids: [bid.id],
            })),
          },
        ],
      },
    });
  };

  return (
    <Box
      key={group.id}
      position={isExpanded ? "absolute" : "relative"}
      zIndex={isExpanded ? "docked" : "auto"}
      left="0"
      width={"full"}
      maxWidth={"full"}
      backgroundColor="smBackground"
      border="1px solid"
      borderColor={"teal.100"}
      rounded={"lg"}
      mb="8"
      p="5"
      overflow="hidden"
    >
      <Flex alignItems={"center"} pb="4" pl="4" justifyContent={"space-between"}>
        <Text size="lg" fontWeight={"bold"}>
          {group.name}
        </Text>
        <IconButton
          aria-label="Toggle product group size"
          icon={<Icon as={isExpanded ? FaCompressAlt : FaExpandAlt} />}
          size="sm"
          variant={"ghost"}
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </Flex>
      <Box width={"full"} maxWidth={"full"} overflow={"auto"} rounded={"lg"}>
        <Table variant={"simple"} size="sm">
          <Thead>
            <Tr>
              {group.productFields
                .filter((field) => field.populatedBy === "Buyer")
                .map((field) => (
                  <Th key={field.name} minWidth="40" p="1">
                    {field.name}
                  </Th>
                ))}
              <Th minWidth="40" p="1">
                {t("Quantity")}
              </Th>
              {!eventIsCompleted(sourcingEvent) &&
                bids?.map((bid) => (
                  <Th
                    key={bid.id}
                    p="1"
                    textAlign={"right"}
                    cursor="pointer"
                    onClick={() => selectAllBidsInGroupFromSupplier({ group, bid })}
                  >
                    {bid.owningOrganization.name}
                  </Th>
                ))}
              {!eventIsCompleted(sourcingEvent) && (
                <>
                  <Th p="1" textAlign={"right"}>
                    {t("Best Price")}
                  </Th>
                  <Th p="1" textAlign={"right"}>
                    {t("Selected Price")}
                  </Th>
                </>
              )}
              {eventIsCompleted(sourcingEvent) && (
                <>
                  <Th p="1" textAlign={"right"}>
                    {t("Total Price")}
                  </Th>
                  <Th p="1" textAlign={"center"}>
                    {t("Selected Supplier")}
                  </Th>
                </>
              )}
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {group.products?.map((product) =>
              eventIsCompleted(sourcingEvent) ? (
                <EvaluationReportRow
                  key={product.id}
                  sourcingEvent={sourcingEvent}
                  bids={bids}
                  group={group}
                  product={product as Product}
                  setProductConversation={setProductConversation}
                />
              ) : (
                <EvaluationRow
                  key={product.id}
                  sourcingEvent={sourcingEvent}
                  bids={bids}
                  group={group}
                  product={product as Product}
                  setProductConversation={setProductConversation}
                />
              )
            )}
            <Tr fontWeight={"bold"}>
              <Td p="1" colSpan={group.productFields.filter((field) => field.populatedBy === "Buyer").length + 1}>
                SUM
              </Td>
              {!eventIsCompleted(sourcingEvent) &&
                bids?.map((bid) => (
                  <Td key={bid.id} p="1" textAlign={"right"}>
                    {getProductGroupPrice({
                      groupBid: findProductGroupBid({ groupId: group.id, bid }),
                      productGroup: group,
                    })?.toLocaleString()}
                  </Td>
                ))}
              {!eventIsCompleted(sourcingEvent) && (
                <Td p="1" textAlign={"right"}>
                  {getSumOfBestGroupPrices({ group, bids: bids ?? [] }).toLocaleString()}
                </Td>
              )}
              <Td p="1" textAlign={"right"}>
                {getSelectedProductGroupPrice({ group, bids: bids ?? [], sourcingEvent }).toLocaleString()}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};
