import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ContractState } from "../../../../common/redux/reducers/contractReducer";
import { requireDataFields } from "../../typeguards";
import { GenericDetailRow, GenericDetails } from "./GenericDetails";

interface Props {
  data: ContractState["contract"];
}

export const TermsAndConditions = ({ data }: Props) => {
  const { t } = useTranslation();
  const dataFields = requireDataFields(data);
  const rows: GenericDetailRow[] = [];
  const addedFields = dataFields.addedDataFields.map((e) => e.name);
  const noValueProvided = <Text fontStyle={"italic"}>{t("Not provided")}</Text>;
  if (addedFields.includes("PaymentTermsInDays")) {
    rows.push({
      type: "Data",
      name: t("Payment terms"),
      value: dataFields.paymentTermsInDays ? (
        <Text fontSize={"sm"}>{`${dataFields.paymentTermsInDays} ${t("days")}`}</Text>
      ) : (
        noValueProvided
      ),
      displayBelow: true,
    });
  }
  if (addedFields.includes("DeliveryTerms")) {
    rows.push({
      type: "Data",
      name: t("Delivery terms"),
      value: dataFields.deliveryTerms ? (
        <Text fontWeight={"light"} color="#717171">
          {dataFields.deliveryTerms}
        </Text>
      ) : (
        noValueProvided
      ),
      displayBelow: true,
    });
  }

  if (addedFields.includes("PurchasingPolicy")) {
    rows.push({
      type: "Data",
      name: t("Purchasing policy"),
      value: dataFields.purchasingPolicy ? (
        <Text fontWeight={"light"} color="#717171">
          {dataFields.purchasingPolicy}
        </Text>
      ) : (
        noValueProvided
      ),
      displayBelow: true,
    });
  }

  return <GenericDetails title={t("Terms and Conditions")} rows={rows} />;
};
