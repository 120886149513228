import { Grid, GridItem, Heading, Select, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { AdminEntityType } from "../../autogen/bff-api";
import { Layout } from "../../common/layout/Layout";
import { CONTENT_WIDTH } from "../../config";
import { AllEntities } from "./all-entities/AllEntities";
import { OrgsList } from "./org/OrgsList";

export const Admin = () => {
  const [selectedEntityType, setSelectedEntityType] = useState<AdminEntityType>();

  const options: AdminEntityType[] = [
    "Bid",
    "Contract",
    "Document",
    "EmailInvite",
    "EmailOutboxItem",
    "Organization",
    "Person",
    "SourcingEvent",
    "Upload",
  ];
  return (
    <Layout>
      <Grid
        height="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"1fr"}
        templateAreas={`
                "leftContent content rightContent"
                `}
      >
        <GridItem area={"content"} paddingLeft={"0px"} paddingRight={"0px"}>
          <Grid h="100%" gridTemplateColumns={"1fr"} gridTemplateRows={"70px 1fr"}>
            <GridItem display="flex" alignItems="center" borderBottom={"1px solid #e1e6ee"}>
              <Heading as="h2" size="lg">
                {t("Admin dashboard")}
              </Heading>
            </GridItem>
            <GridItem>
              <Tabs>
                <TabList>
                  <Tab>Organizations</Tab>
                  <Tab>Raw JSON</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <OrgsList />
                  </TabPanel>
                  <TabPanel>
                    <Select
                      value={selectedEntityType}
                      placeholder="Select option"
                      onChange={(e) => setSelectedEntityType(e.target.value as AdminEntityType)}
                    >
                      {options.map((e) => (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      ))}
                    </Select>
                    {selectedEntityType && <AllEntities entityType={selectedEntityType} />}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};
