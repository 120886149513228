import { Flex, Icon, Spacer, Text } from '@chakra-ui/react'
import React from 'react'
import { FaFile } from 'react-icons/fa'
import { FileUpload } from './FileRow'

interface Props {
    file: FileUpload
    isChecked: boolean
    toggle: () => void
}

export const SustainabilityFileRow: React.FC<Props> = ({
    file,
    isChecked,
    toggle,
}) => {
    return (
        <Flex
            key={file.uploadId}
            bg="#f6f8fb"
            minHeight={'50px'}
            alignItems="center"
            borderRadius={'10px'}
            mt="5px"
            paddingTop={'5px'}
            paddingBottom={'5px'}
        >
            <Flex>
                <Icon as={FaFile} mr="20px" ml="20px" color={'blue.500'} />
            </Flex>
            <Flex flexDirection={'column'} mr="15px" w="100%">
                <Text fontSize={'sm'} color="#565555" fontWeight={'normal'}>
                    {file.name}
                </Text>
            </Flex>
            <Spacer />
            <Flex mr="20px">
                <input
                    type="checkbox"
                    value={'true'}
                    checked={isChecked}
                    onChange={toggle}
                    style={{
                        width: '20px',
                        height: '20px',
                        accentColor: '#38a169',
                    }}
                />
            </Flex>
        </Flex>
    )
}
