import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  ContractDto,
  ContractPersonParticipantDto,
  useCreateContractTaskMutation,
  useListParticipantsForContractsQuery,
} from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { DateFormControl } from "../../../../common/input/DateTimeSelectors/DateFormControl";
import { SelectorValue } from "../../../../common/input/Selector/SelectorValue";
import { SingleFormSelector } from "../../../../common/input/Selector/SingleFormSelector";
import { SimpleFormInput } from "../../../../common/input/SimpleInput/SimpleFormInput";
import { TextAreaFormInput } from "../../../../common/input/TextAreaInput/TextAreaFormInput";
import { ModalSkeleton } from "../../../../common/ModalSkeleton";
import { displayPersonNameWithEmail } from "../sharing/AddExternalParticipantModal";

interface Props {
  contract: ContractDto;
  onClose: () => void;
}

interface FormValues {
  title: string;
  description: string;
  dueDate: string;
  person: SelectorValue;
}

export const CreateTaskModal = ({ contract, onClose }: Props) => {
  const methods = useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [createTask] = useCreateContractTaskMutation();
  const toast = useToast();
  const displayer = useApiError();
  const { data: participants, isLoading: isLoadingParticipants } = useListParticipantsForContractsQuery({
    contractId: contract.id,
  });

  const requireParticipant = (personId: string): ContractPersonParticipantDto => {
    const participant = participants?.allPersons.find((e) => e.id === personId);

    if (!participant) throw Error("Unexpected state - undefined participant");

    return participant;
  };

  const create = async (values: FormValues) => {
    setIsLoading(true);
    const result = await createTask({
      contractId: contract.id,
      createContractTaskRequest: {
        title: values.title,
        description: values.description,
        dueDate: values.dueDate,
        responsibleOrganizationId: requireParticipant(values.person.value).organizationId,
        responsiblePersonId: values.person.value,
      },
    });
    if ("data" in result) {
      toast({
        title: t("Task added!"),
        status: "success",
      });
      onClose();
    } else {
      displayer.trigger(result.error);
    }
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Add new task")}</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(create)}>
            <ModalBody>
              {participants && (
                <>
                  <SimpleFormInput
                    label={t("Title")}
                    helperText={t("Please provide a task title")}
                    control={methods.control}
                    name="title"
                    required={{
                      value: true,
                      message: t("Please provide a task title"),
                    }}
                    errorMessage={methods.formState.errors.title?.message}
                  />
                  <Box mt="20px">
                    <TextAreaFormInput
                      label={t("Description")}
                      helperText={t("Please provide a task description")}
                      control={methods.control}
                      name="description"
                      required={{
                        value: true,
                        message: t("Please provide a task description"),
                      }}
                      errorMessage={methods.formState.errors.description?.message}
                    />
                  </Box>
                  <Box pt="5">
                    <DateFormControl
                      id="due date"
                      isRequiredButNotProvided={true}
                      helperText={t("Please provide a due date")}
                      title={t("Due date")}
                      errorMessage={methods.formState.errors.dueDate?.message ?? null}
                      onChange={(date) => {
                        if (date) methods.setValue("dueDate", date);
                      }}
                    />
                  </Box>
                  <Box mt="20px">
                    <SingleFormSelector<FormValues>
                      options={
                        participants?.allPersons.map((e) => ({
                          label: displayPersonNameWithEmail(e),
                          value: e.id,
                        })) ?? []
                      }
                      label={t("Select a responsible person")}
                      control={methods.control}
                      name={"person"}
                      helperText={t("Please select a person") ?? ""}
                      placeholderText={""}
                      noMatchingOptionsMessage={t("No matching persons")}
                      noOptionsProvidedMessage={t("No persons available")}
                      required={{
                        value: true,
                        message: t("Please select a responsible person"),
                      }}
                      errorMessage={methods.formState.errors.person?.message}
                    />
                  </Box>
                </>
              )}
              {isLoadingParticipants && !participants && <ModalSkeleton />}
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose} isDisabled={isLoading}>
                {t("Cancel")}
              </Button>
              <Button type="submit" variant="solid" colorScheme={"blue"} isLoading={isLoading}>
                {t("Create")}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};
