import { Box, Button, FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectorValue } from "../../../../../common/input/Selector/SelectorValue";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { editBseThunk } from "../../../../../common/redux/thunks/basic-sourcing-event/edit-bse-thunk";
import { useSub } from "../../../../../common/subscription/useSub";
import { ProjectsSelector } from "../../../../projects/ProjectsSelector";
import { useBasicSourcingEventState } from "../../../useBasicSourcingEventState";
import { DeadlineSelector } from "./form-elements/DeadlineSelector";
import { Description } from "./form-elements/Description";
import { ReferenceId } from "./form-elements/ReferenceId";
import { Title } from "./form-elements/Title";

interface Props {
  nextStep: () => void;
}

export const Intro = ({ nextStep }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sub = useSub();

  const eventState = useBasicSourcingEventState();

  const [selectedProjects, setSelectedProjects] = useState<SelectorValue[]>([]);

  useEffect(() => {
    if (eventState.projects.values) {
      setSelectedProjects(
        eventState.projects.values.map((p) => ({
          label: `${p.externalId ? `${p.externalId} - ` : ""}${p.name}`,
          value: p.id,
        }))
      );
    }
  }, [eventState.projects.values]);

  const onProjectChange = useCallback(
    async (values: SelectorValue[]) => {
      await dispatch(
        editBseThunk({
          command: {
            type: "SetProjects",
            values: values.map((v) => v.value),
          },
        })
      );
    },
    [dispatch]
  );

  return (
    <Box pb="8">
      <Box py="10">
        <Title />
      </Box>
      <Box pb="10">
        <ReferenceId />
      </Box>
      <Box pb="10">
        <Description />
      </Box>
      <Box pb="10">
        <DeadlineSelector />
      </Box>
      {sub.hasProjectAccess && (
        <FormControl pb="10">
          <FormLabel>{t("Projects")}</FormLabel>
          <ProjectsSelector selectedProjects={selectedProjects} onChange={onProjectChange} />
          <FormHelperText>{t("Select one or more projects the sourcing event is connected to")}.</FormHelperText>
        </FormControl>
      )}
      <Button variant="solid" width="full" colorScheme="teal" onClick={nextStep}>
        {t("Next")}
      </Button>
    </Box>
  );
};
