import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { FaArrowRight, FaFileContract } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BseDto, ReceivedBseBidDto, useCreateContractFromSourcingEventMutation } from "../../../../autogen/bff-api";
import { urls } from "../../../../urls";

export const CreateContractDetail = ({ bid, sourcingEvent }: { bid: ReceivedBseBidDto; sourcingEvent: BseDto }) => {
  const navigate = useNavigate();
  const [createContract, { isLoading }] = useCreateContractFromSourcingEventMutation();

  return (
    <Flex key={bid.id} alignItems={"center"} py="4">
      <Text fontWeight={"semibold"} pr="4">
        {bid.owningOrganization.name}
      </Text>
      {bid.contractId ? (
        <Button
          colorScheme="teal"
          variant={"ghost"}
          rightIcon={<Icon as={FaArrowRight} />}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          onClick={() => navigate(urls.contracts.edit.go(bid.contractId!, sourcingEvent.id).review)}
        >
          {t("Go to contract")}
        </Button>
      ) : (
        <Button
          size="sm"
          variant={"outline"}
          colorScheme="teal"
          leftIcon={<Icon as={FaFileContract} />}
          isLoading={isLoading}
          onClick={async () => {
            const res = await createContract({
              createContractFromSourcingEventRequest: { eventId: sourcingEvent.id, bidId: bid.id },
            });
            if ("data" in res) navigate(urls.contracts.edit.go(res.data.id, sourcingEvent.id).details);
          }}
        >
          {t("Create contract")}
        </Button>
      )}
    </Flex>
  );
};
