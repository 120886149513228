import { Box, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Spinner } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../../useContractState";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const InternalReferenceNumber = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const contractState = useContractState();
  const [isLoading, setIsLoading] = useState(false);
  const errorMessage = containsError(contractState, "EditInternalReferenceNumber") ? t("Update failed") : null;
  const dispatch = useAppDispatch();
  const [referenceNumber, setReferenceNumber] = useState<string>();

  useEffect(() => {
    if (contractState.contract.dataFields?.internalReferenceNumber) {
      setReferenceNumber(contractState.contract.dataFields.internalReferenceNumber);
    }
  }, []);

  const eventHandler = async (value: string) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "EditInternalReferenceNumber",
          value: value,
        },
      })
    );
    setIsLoading(false);
  };
  const debouncedEventHandler = useMemo(() => debounce(eventHandler, 300), []);

  useEffect(() => {
    return () => {
      debouncedEventHandler.cancel();
    };
  }, []);

  return (
    <FormControl isInvalid={errorMessage !== null} isRequired={isRequiredButNotProvided}>
      <FormLabel htmlFor={"value"}>
        {t("Internal reference number")} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Flex w="100%">
        <Box borderRadius={"10px"} backgroundColor="smBackground" w="100%">
          <Input
            type="text"
            value={referenceNumber ?? ""}
            onChange={(e) => {
              setReferenceNumber(e.target.value);
              debouncedEventHandler(e.target.value);
            }}
          />
        </Box>
        <RemoveDataFieldButton removeDataField={removeDataField} />
      </Flex>
      <FormHelperText>{t("Provide internal reference number")}</FormHelperText>
      <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
