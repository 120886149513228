import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from "@chakra-ui/react";
import { GroupBase, OptionsOrGroups, Select, useChakraSelectProps } from "chakra-react-select";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./PersonsSelector.module.css";

export interface PersonSelectValue {
  value: string;
  label: string;
}
interface Props {
  options: OptionsOrGroups<PersonSelectValue, GroupBase<PersonSelectValue>>;
}

export const PersonsSelector: React.FC<Props> = ({ options }) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
  } = useFormContext<{ persons: PersonSelectValue[] }>();
  return (
    <FormControl id="receivers" isInvalid={errors.persons != null}>
      <FormLabel>{t("Receivers")}</FormLabel>
      <Controller
        name="persons"
        control={control}
        rules={{
          required: {
            value: true,
            message: t("Please select at least 1 receiver"),
          },
          minLength: {
            value: 2,
            message: t("Please select at least 1 receiver"),
          },
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <Select
              size="md"
              // eslint-disable-next-line react-hooks/rules-of-hooks
              {...useChakraSelectProps({
                isMulti: true,
                value: value,
                onChange: (e) => {
                  onChange(
                    e.map((e) => ({
                      value: e.value,
                      label: e.label,
                    }))
                  );
                },
                isClearable: false,
                selectedOptionStyle: "check",
                selectedOptionColor: "blue",
                placeholder: "",
              })}
              options={options}
              className={styles.multiSelect}
            />
          );
        }}
      />

      <FormHelperText>{t("Select who will receive the reminder")}</FormHelperText>
      <FormErrorMessage>{errors.persons && errors.persons.message}</FormErrorMessage>
    </FormControl>
  );
};
