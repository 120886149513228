import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Currency, IfrsAdjustmentDto } from "../../../../autogen/bff-api";
import { displayDate } from "../../../../common/formatting/displayDate";
import { displaySum } from "../../../../common/formatting/displaySum";
import { ContractState } from "../../../../common/redux/reducers/contractReducer";
import { GenericDetailRow, GenericDetails } from "./GenericDetails";

interface Props {
  data: ContractState["contract"];
}

const getAdjustment = (adjustment: IfrsAdjustmentDto, currency: Currency) =>
  adjustment.type === "Monetary" ? displaySum({ value: adjustment.value, currency }) : `${adjustment.value}%`;

export const Ifrs = ({ data }: Props) => {
  const { t } = useTranslation();
  const rows: GenericDetailRow[] = [];

  const ifrsValues = data.dataFields?.ifrsValues;
  const startDate = ifrsValues?.startDate;
  const monthlyPayment = ifrsValues?.monthlyPayment;
  const paymentIntervalInMonths = ifrsValues?.paymentIntervalInMonths;
  const contractLengthInMonths = ifrsValues?.contractLengthInMonths;
  const interestRate = ifrsValues?.interestRate;
  const adjustments = ifrsValues?.adjustments;

  const noValueProvided = (
    <Text fontStyle={"italic"} color="smMuted">
      {t("No value provided")}
    </Text>
  );

  rows.push({
    type: "Data",
    name: t("Start date"),
    value: startDate ? <Text>{displayDate(startDate)}</Text> : noValueProvided,
  });

  rows.push({
    type: "Data",
    name: t("Monthly payment"),
    value:
      monthlyPayment || monthlyPayment === 0 ? (
        <Text>
          {displaySum({
            value: monthlyPayment,
            currency: data.currency,
          })}
        </Text>
      ) : (
        noValueProvided
      ),
  });

  rows.push({
    type: "Data",
    name: t("Payment interval"),
    value:
      paymentIntervalInMonths || paymentIntervalInMonths === 0 ? (
        <Text>{paymentIntervalInMonths} month(s)</Text>
      ) : (
        noValueProvided
      ),
  });

  rows.push({
    type: "Data",
    name: t("Contract length"),
    value:
      contractLengthInMonths || contractLengthInMonths === 0 ? (
        <Text>{contractLengthInMonths} month(s)</Text>
      ) : (
        noValueProvided
      ),
  });

  rows.push({
    type: "Data",
    name: t("Interest rate"),
    value: interestRate || interestRate === 0 ? <Text>{interestRate * 100}%</Text> : noValueProvided,
  });

  adjustments?.forEach((adjustment) => {
    rows.push({
      type: "Data",
      key: `${t("Adjustment")} (${t("month")} ${adjustment.monthNumber}${adjustment.type})`,
      name: `${t("Adjustment")} (${t("month")} ${adjustment.monthNumber})`,
      value: <Text>{getAdjustment(adjustment, data.currency)}</Text>,
    });
  });

  return <GenericDetails title={t("IFRS")} rows={rows} />;
};
