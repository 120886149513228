import { Tag } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { EmailInviteStatusDto } from "../../../../autogen/bff-api";
import { displayDateWithTime } from "../../../../common/formatting/displayDateWithTime";
import { Explanation } from "../../../../common/support/Explanation/Explanation";

interface Props {
  status: EmailInviteStatusDto;
  openedAt?: string;
  size?: "sm" | "md" | "lg";
}

export const EmaiInviteStatusTag: React.FC<Props> = ({ status, openedAt, size }) => {
  const { t } = useTranslation();
  const getEventTagMeta = (
    status: EmailInviteStatusDto
  ): {
    color: string;
    text: string;
  } => {
    switch (status) {
      case "Opened":
        return {
          text: t("Opened!"),
          color: "purple",
        };
      case "SendingFailed":
        return {
          text: t("Sending failed!"),
          color: "red",
        };
      case "Sent":
        return {
          text: t("Invitation sent"),
          color: "gray",
        };
    }
  };

  const tagMeta = getEventTagMeta(status);

  const tag = (
    <Tag colorScheme={tagMeta.color} size={size} textAlign="center" paddingTop={"2px"} paddingBottom={"2px"}>
      {tagMeta.text}
    </Tag>
  );

  if (status == "Opened" && openedAt !== undefined) {
    return (
      <Explanation
        enabled={true}
        text={`${t("The email was registered as opened")} ${displayDateWithTime(openedAt, t("at"))}`}
      >
        {tag}
      </Explanation>
    );
  } else if (status === "SendingFailed") {
    return (
      <Explanation
        enabled={true}
        text={`${t("The email sending failed")}. ${t("Please check that the email address is correct")}. ${t(
          "If everything seems fine you can retry sending the email by removing the email address and adding it again in edit-mode"
        )}`}
      >
        {tag}
      </Explanation>
    );
  } else {
    return tag;
  }
};
