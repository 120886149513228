import { PayloadAction, SerializedError, createSlice } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { ProductGroupDto } from "../../../autogen/bff-api";
import { Product, ProductField, ProductGroup } from "../../types";
import { createProductGroupThunk } from "../thunks/product/createProductGroupThunk";
import { editProductGroupThunk } from "../thunks/product/editProductGroupThunk";

const findProductGroup = (state: ProductState, groupId: string): ProductGroup | undefined => {
  return state.data.productGroups.find((g) => g.id === groupId);
};

interface ProductState {
  data: { productGroups: ProductGroup[] };
  error: FetchBaseQueryError | SerializedError | null;
}

const initialState: ProductState = {
  data: { productGroups: [] },
  error: null,
};

const productSlice = createSlice<
  ProductState,
  {
    setProductGroups: (state: ProductState, action: PayloadAction<ProductGroup[] | undefined>) => void;
    updateProductGroup: (state: ProductState, action: PayloadAction<ProductGroup>) => void;
    addProduct: (state: ProductState, action: PayloadAction<{ groupId: string; product: Product }>) => void;
    editProduct: (state: ProductState, action: PayloadAction<{ groupId: string; product: Product }>) => void;
    removeProduct: (state: ProductState, action: PayloadAction<{ groupId: string; productId?: string }>) => void;
    removeProductGroup: (state: ProductState, action: PayloadAction<{ groupId: string }>) => void;
  },
  "product"
>({
  name: "product",
  initialState,
  reducers: {
    setProductGroups: (state, action) => {
      if (action.payload) state.data.productGroups = action.payload;
    },
    updateProductGroup: (state, action) => {
      const index = state.data.productGroups.findIndex((g) => g.id === action.payload.id);
      if (index !== -1) {
        state.data.productGroups.splice(index, 1, action.payload);
      }
    },
    addProduct: (state, action) => {
      const productGroup = findProductGroup(state, action.payload.groupId);
      productGroup?.products?.push(action.payload.product);
    },
    editProduct: (state, action) => {
      const productGroup = findProductGroup(state, action.payload.groupId);
      const productIndex = productGroup?.products?.findIndex((p) => p.id === action.payload.product.id);
      if (productIndex !== undefined && productIndex !== -1) {
        productGroup?.products?.splice(productIndex, 1, action.payload.product);
      }
    },
    removeProduct: (state, action) => {
      const productGroup = findProductGroup(state, action.payload.groupId);
      if (productGroup) productGroup.products = productGroup.products?.filter((p) => p.id !== action.payload.productId);
    },
    removeProductGroup: (state, action) => {
      state.data.productGroups = state.data.productGroups.filter((g) => g.id !== action.payload.groupId);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createProductGroupThunk.fulfilled, (state, action) => {
      if ("data" in action.payload) state.data.productGroups.push(mapDtoToProductGroup(action.payload.data));
      if ("error" in action.payload) state.error = action.payload.error;
    });
    builder.addCase(editProductGroupThunk.fulfilled, (state, action) => {
      if ("error" in action.payload) {
        state.error = action.payload.error;
      }
    });
  },
});

export const mapDtoToProductGroup = (dto: ProductGroupDto): ProductGroup => ({
  id: dto.id,
  sourcingEventId: dto.sourcingEventId,
  projectId: dto.projectId,
  name: dto.name,
  originProductGroupId: dto.originProductGroupId,
  productFields: dto.productFields,
  products: dto.products.map((productDto) => ({
    id: productDto.id,
    quantity: productDto.quantity,
    productFields: productDto.productFields
      .map((fieldDto) => {
        const templateField = dto.productFields.find(
          (t) => t.name === fieldDto.name && t.populatedBy === fieldDto.populatedBy
        );
        if (!templateField) return null;
        const field: ProductField = {
          id: templateField.id,
          name: templateField.name,
          type: templateField.type,
          isRequired: templateField.isRequired,
          populatedBy: templateField.populatedBy,
          value: fieldDto.value,
        };
        return field;
      })
      .filter((field) => !!field) as ProductField[],
    conversation: productDto.conversation ?? [],
  })),
});

export const { setProductGroups, addProduct, editProduct, removeProduct, removeProductGroup, updateProductGroup } =
  productSlice.actions;
export const productReducer = productSlice.reducer;
