import { t } from "i18next";
import { useCallback } from "react";
import { ContractNotificationTypeDto } from "../../autogen/bff-api";

export const useDisplayNotificationEvent = () => {
  const displayNotificationEvent = useCallback((type: ContractNotificationTypeDto): string => {
    switch (type) {
      case "EndDateReminder":
        return t("End date reminder");
      case "RenewalDateReminder":
        return t("Renewal date reminder");
      case "WarrantyExpirationDateReminder":
        return t("Warranty expiration date reminder");
      case "StartDateReminder":
        return t("Start date reminder");
      case "TerminationDateReminder":
        return t("Termination date reminder");
      case "NoticePeriodReminder":
        return t("Notice period reminder");
      case "OptionExecutionDeadlineReminder":
        return t("Option execution deadline reminder");
      case "OptionStartDateReminder":
        return t("Option start date reminder");
      case "NoEndDateReminder":
        return t("Contract without end date reminder");
    }
  }, []);

  return displayNotificationEvent;
};
