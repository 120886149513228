import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { SimpleInput } from './SimpleInput'

interface Props<T extends FieldValues> {
    label: string
    helperText: string
    control: Control<T>
    name: FieldPath<T>
    required?: {
        value: boolean
        message: string
    }
    errorMessage: string | undefined
}

export const SimpleFormInput = <T extends FieldValues>({
    control,
    helperText,
    name,
    label,
    required,
    errorMessage,
}: Props<T>) => {
    return (
        <Controller
            control={control}
            name={name}
            rules={{
                required: required,
            }}
            render={({ field: { onChange, value } }) => (
                <SimpleInput
                    name={name}
                    helperText={helperText}
                    label={label}
                    onChange={onChange}
                    value={value}
                    errorMessage={errorMessage}
                    isLoading={false}
                    isRequiredButNotProvided={required !== undefined}
                />
            )}
        />
    )
}
