import { createAsyncThunk } from '@reduxjs/toolkit'
import { getOrganizationId } from '../../../local-storage/selected-organization'
import { bffApi, MeDto, OrgDto } from '../../../../autogen/bff-api'

export const reloadMeThunk = createAsyncThunk(
    'authState/reloadMe',
    async (
        props: void,
        { dispatch }
    ): Promise<{
        me: MeDto
        organizations: OrgDto[]
        selectedOrg: OrgDto
    }> => {
        const meResponse = dispatch(bffApi.endpoints.getMe.initiate({}))
        meResponse.unsubscribe()
        const meResult = await meResponse

        const orgResponse = dispatch(
            bffApi.endpoints.listOrganizationsForMe.initiate({})
        )
        orgResponse.unsubscribe()
        const orgResult = await orgResponse

        if (
            'data' in meResult &&
            meResult.data &&
            'data' in orgResult &&
            orgResult.data
        ) {
            const selectedOrgId = getOrganizationId()
            const matchingOrgs = orgResult.data.orgs.filter(
                (e) => e.id === selectedOrgId
            )
            if (matchingOrgs.length > 0) {
                return {
                    me: meResult.data,
                    organizations: orgResult.data.orgs,
                    selectedOrg: matchingOrgs[0],
                }
            } else {
                return {
                    me: meResult.data,
                    organizations: orgResult.data.orgs,
                    selectedOrg: orgResult.data.orgs[0],
                }
            }
        } else {
            throw Error('Could not reload me Me')
        }
    }
)
