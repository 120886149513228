export const sanitizeFilename = (filename: string) => {
  const pattern = /[^a-zA-Z0-9 \-_.]/g;
  return filename
    .normalize("NFKD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(pattern, "_")
    .replace(/[\s_]+/g, "_")
    .replace(/^_+|_+$/g, "")
    .substring(0, 255);
};
