import { Grid, GridItem, Heading } from "@chakra-ui/react";
import { ListSourcingEventsFilterStatus } from "../../../../autogen/bff-api";
import { CONTENT_WIDTH } from "../../../../config";

import { Layout } from "../../../../common/layout/Layout";
import { SourcingEventsTableForSupplier } from "./SourcingEventsTableForSupplier";

interface Props {
  title: string;
  subtitle: string;
  status: ListSourcingEventsFilterStatus;
}

export const EventsForSupplier = ({ title, subtitle, status }: Props) => {
  return (
    <Layout>
      <Grid
        height="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"1fr"}
        templateAreas={`
                "leftContent content rightContent"
                `}
      >
        <GridItem area={"content"}>
          <Grid h="100%" gridTemplateColumns={"1fr"} gridTemplateRows={"70px 1fr"}>
            <GridItem display="flex" alignItems="center" borderBottom={"1px solid"} borderColor="smBorder" px="2">
              <Heading as="h2" size="lg">
                {title}
              </Heading>
            </GridItem>
            <GridItem px="2">
              <SourcingEventsTableForSupplier status={status} subtitle={subtitle} />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};
