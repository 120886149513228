import { Box } from "@chakra-ui/react";
import { useState } from "react";
import { ContractTaskDto } from "../../../../../../autogen/bff-api";
import { EditButton } from "../EditButton";
import { EditTitle } from "./EditTitle";

interface Props {
  task: ContractTaskDto;
  onEditComleted: (task: ContractTaskDto) => void;
}

export const Title = ({ task, onEditComleted }: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Box mt="10px">
      {!isEditing && (
        <>
          {task.title}
          {task.canEdit && <EditButton name="edit-title-button" onClick={() => setIsEditing(true)} />}
        </>
      )}
      {isEditing && (
        <EditTitle
          task={task}
          onEditComleted={(e) => {
            setIsEditing(false);
            onEditComleted(e);
          }}
          onCancel={() => setIsEditing(false)}
        />
      )}
    </Box>
  );
};
