import { ReactNode, useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";
import { useAuthState } from "../../auth/useAuthState";

interface Props {
  children: ReactNode;
}

export const CustomIntercomProvider = ({ children }: Props) => {
  const authState = useAuthState();
  const { update } = useIntercom();
  const [currentState, setCurrentState] = useState(authState.type);

  useEffect(() => {
    if (authState.type !== currentState) {
      setCurrentState(authState.type);
      updateIntercom();
    }
  }, [authState.type]);

  useEffect(() => {
    updateIntercom();
  }, []);

  const updateIntercom = () => {
    switch (authState.type) {
      case "LoggedIn": {
        update({
          userHash: authState.me.intercomSecretHash,
          email: authState.me.email,
          name: `${authState.me.firstName} ${authState.me.lastName}`,
          userId: authState.me.id,
        });
        break;
      }
      case "LoggedInWithOrgContext":
        update({
          userHash: authState.me.intercomSecretHash,
          email: authState.me.email,
          name: `${authState.me.firstName} ${authState.me.lastName}`,
          userId: authState.me.id,
          company: {
            companyId: authState.selectedOrg.id,
            name: authState.selectedOrg.name,
          },
        });
        break;
      case "LoggedOut":
        break;
    }
  };

  return <>{children}</>;
};
