import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BaseDataDto } from '../../../autogen/bff-api'

interface BaseDataState {
    baseData?: BaseDataDto
}

// Define the initial state using that type
const initialState: BaseDataState = {
    baseData: undefined,
}

export const slice = createSlice({
    name: 'baseDataState',
    initialState,
    reducers: {
        setBaseData: (state, action: PayloadAction<BaseDataDto>) => {
            state.baseData = action.payload
        },
    },
})

export const { setBaseData } = slice.actions
export default slice.reducer
