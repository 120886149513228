import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  ContractDataFieldNameDto,
  CustomFieldIdentifierDto,
  useCreateContractTemplateMutation,
} from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../common/errors/useApiError";
import { SimpleFormInput } from "../../../../common/input/SimpleInput/SimpleFormInput";
import { TextAreaFormInput } from "../../../../common/input/TextAreaInput/TextAreaFormInput";
import { DataFieldFormSelector } from "../../edit/details/optional/DataFieldFormSelector";
import { ContractFieldOption } from "../../edit/details/optional/DataFieldSelector";

interface Props {
  onClose: () => void;
}

interface FormValues {
  name: string;
  description: string;
  requiredDataFields: ContractFieldOption[];
}

export const CreateTemplateModal = ({ onClose }: Props) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      requiredDataFields: [],
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [createTemplate] = useCreateContractTemplateMutation();
  const authState = useLoggedInWithOrgContextState();
  const displayer = useApiError();
  const toast = useToast();

  const alreadyAddedFields = methods.watch("requiredDataFields");

  const create = async (values: FormValues) => {
    setIsLoading(true);
    const result = await createTemplate({
      orgId: authState.selectedOrg.id,
      upsertContractTemplateRequest: {
        name: values.name,
        description: values.description,
        requiredDataFields: values.requiredDataFields
          .filter((o) => !o.sectionId)
          .map((o) => o.name) as ContractDataFieldNameDto[],
        requiredCustomDataFields: values.requiredDataFields
          ?.filter((o) => o.sectionId)
          .map((o) => ({ sectionId: o.sectionId, fieldId: o.fieldId })) as CustomFieldIdentifierDto[],
      },
    });
    if ("data" in result) {
      toast({
        title: t("Type created!"),
        status: "success",
      });
      onClose();
    } else {
      displayer.trigger(result.error);
    }
    setIsLoading(false);
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Create new type")}</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(create)}>
            <ModalBody>
              <SimpleFormInput
                label={t("Name")}
                helperText={t("Please provide a type name")}
                control={methods.control}
                name="name"
                errorMessage={methods.formState.errors.name?.message}
                required={{
                  value: true,
                  message: t("Please provide a name"),
                }}
              />
              <Box mt="20px">
                <TextAreaFormInput
                  label={t("Description")}
                  helperText={t("Please provide a description")}
                  control={methods.control}
                  name="description"
                  errorMessage={methods.formState.errors.description?.message}
                  required={{
                    value: true,
                    message: t("Please provide a description"),
                  }}
                />
              </Box>
              <Box mt="20px">
                <DataFieldFormSelector
                  alreadyAddedFields={alreadyAddedFields.map((e) => e.name)}
                  control={methods.control}
                  name="requiredDataFields"
                  helperText={t("Please select a data field")}
                  label={t("Required data fields") ?? ""}
                  errorMessage={methods.formState.errors.requiredDataFields?.message}
                />
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose} isDisabled={isLoading}>
                {t("Cancel")}
              </Button>
              <Button type="submit" variant="solid" colorScheme={"blue"} isLoading={isLoading}>
                {t("Create")}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};
