import { Tag } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ContractStateDto } from "../../../autogen/bff-api";

interface Props {
  state: ContractStateDto;
  size?: "sm" | "md" | "lg";
}

export const ContractStateTag = ({ state, size }: Props) => {
  const { t } = useTranslation();
  const getEventTagMeta = (
    status: ContractStateDto
  ): {
    color: string;
    text: string;
  } => {
    switch (status) {
      case "Published":
        return {
          color: "green",
          text: t("Published"),
        };
      case "Draft":
        return {
          color: "orange",
          text: "Draft",
        };
    }
  };

  const tagMeta = getEventTagMeta(state);

  return (
    <Tag
      variant="outline"
      colorScheme={tagMeta.color}
      size={size}
      textAlign="center"
      paddingTop={"2px"}
      paddingBottom={"2px"}
    >
      {tagMeta.text}
    </Tag>
  );
};
