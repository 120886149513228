import {
    BidDto,
    BseBidDeliveredFieldsDto,
    BseBidDto,
} from '../../autogen/bff-api'

export const isBseBidDraft = (
    bid: BseBidDto
): bid is BseBidDto & { draftFields: BseBidDeliveredFieldsDto } => {
    return bid.draftFields !== null
}

export const isDeliveredBseBid = (
    bid: BseBidDto
): bid is BseBidDto & { deliveredFields: BseBidDeliveredFieldsDto } => {
    return bid.deliveredFields !== null
}

export const isBseBidDto = (bid: unknown): bid is BseBidDto => {
    return (bid as BseBidDto).uniqueForBseBidDto === true
}

export const isBseDto = (bid: unknown): bid is BidDto => {
    return (bid as BidDto).uniqueForBidDto === true
}
