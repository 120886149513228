import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  Spinner,
} from "@chakra-ui/react";
import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { editBseThunk } from "../../../../../../common/redux/thunks/basic-sourcing-event/edit-bse-thunk";
import { eventIsLive } from "../../../../eventIsLive";
import { useBasicSourcingEventState } from "../../../../useBasicSourcingEventState";

export const Title: React.FC = () => {
  const dispatch = useAppDispatch();
  const eventState = useBasicSourcingEventState();
  const errorMessage = eventState.title.errorMessage;
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<string>("");
  const { t } = useTranslation();

  useEffect(() => {
    setValue(eventState.title.value);
  }, []);

  useEffect(() => {
    return () => {
      debouncedEventHandler.cancel();
    };
  }, []);

  const eventHandler = async (value: string) => {
    setIsLoading(true);
    await dispatch(
      editBseThunk({
        command: {
          type: "EditTitle",
          value: value,
        },
      })
    );
    setIsLoading(false);
  };

  const debouncedEventHandler = useMemo(
    () =>
      debounce(async (value: string) => {
        await eventHandler(value);
      }, 300),
    []
  );

  const eventIsPublished = eventIsLive(eventState.event);
  const valueHasBeenChanged = value !== eventState.event.title;
  const showSaveButton = eventIsPublished && valueHasBeenChanged;

  return (
    <FormControl isInvalid={errorMessage !== null} isRequired>
      <FormLabel htmlFor={"title"}>
        {t("Event title")} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Input
        id={"title"}
        type="text"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          if (!eventIsPublished) {
            debouncedEventHandler(e.target.value);
          }
        }}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
      <FormHelperText>
        {t("Provide a short title describing what you're looking for, for example 'Office rental in Oslo")}
      </FormHelperText>
      {showSaveButton && (
        <Button
          mt="10px"
          leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
          variant={"solid"}
          colorScheme="teal"
          size={"sm"}
          isLoading={isLoading}
          onClick={() => eventHandler(value)}
        >
          {t("Save")}
        </Button>
      )}
    </FormControl>
  );
};
