/* eslint-disable react/no-children-prop */
import { Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPaperPlane, FaSearch } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { Layout } from "../../../common/layout/Layout";
import { useOrganizationSearch } from "../../../common/organization/useOrganizationSearch";
import { CONTENT_WIDTH } from "../../../config";
import { Paging } from "../../contracts/view-all/Paging";
import { Invites } from "../../email-invites/manage-supplier-invites/Invites";
import { SearchResults } from "./SearchResults";

export const OrganizationsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showSendInvites, setShowSendInvites] = useState(false);
  const authState = useLoggedInWithOrgContextState();
  const { t } = useTranslation();

  const [filters, setFilters] = useState(() => {
    const params = new URLSearchParams(location.search);
    return {
      name: params.get("name") || "",
      skip: +(params.get("skip") || 0),
      limit: +(params.get("limit") || 20),
    };
  });

  useEffect(() => {
    const params = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value || value === 0) params.set(key, `${value}`);
    });

    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [filters, navigate, location.pathname]);

  const { organizationCombinations, isSearching, count } = useOrganizationSearch({
    name: filters.name,
    limit: filters.limit,
    skip: filters.skip,
  });

  return (
    <Layout>
      {showSendInvites && <Invites isOpen={true} onClose={() => setShowSendInvites(false)} />}
      <Grid
        height="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={`auto 1fr`}
        templateAreas={`"contentLeft content contentRight"`}
      >
        <GridItem area={"content"} px="2">
          <Box width="full">
            <Grid gridTemplateColumns={"1fr"} gridTemplateRows={"80px minmax(0,1fr)"}>
              <GridItem display="flex" alignItems={"center"} borderBottom={"1px solid"} borderColor="smBorder">
                <Flex width="full">
                  <InputGroup mr="5px">
                    <InputLeftElement pointerEvents="none" children={<Search2Icon color="gray.300" />} />
                    <Input
                      type="text"
                      placeholder={t("Search for company name") ?? ""}
                      value={filters.name}
                      onChange={(e) =>
                        setFilters((prev) => ({
                          ...prev,
                          name: e.target.value,
                          skip: 0,
                        }))
                      }
                    />
                  </InputGroup>
                  <IconButton
                    mr="5px"
                    width="60px"
                    aria-label="Remove file"
                    variant={"solid"}
                    colorScheme="teal"
                    icon={<Icon as={FaSearch} w="15px" h="15px" />}
                  />
                  {authState.selectedOrg.canManageSupplierInvites && (
                    <Button
                      leftIcon={<Icon as={FaPaperPlane} w="15px" h="15px" />}
                      paddingLeft="20px"
                      paddingRight="20px"
                      variant={"solid"}
                      colorScheme="teal"
                      onClick={() => setShowSendInvites(true)}
                    >
                      {t("Invites")}
                    </Button>
                  )}
                </Flex>
              </GridItem>
              <GridItem>
                <Grid marginTop={"9px"} gridTemplateColumns={"auto 1fr auto"} gridTemplateRows={"minmax(0,auto)"}>
                  <GridItem></GridItem>
                  <GridItem>
                    <Paging
                      limit={filters.limit}
                      skip={filters.skip}
                      setSkip={(skip) => setFilters((prev) => ({ ...prev, skip }))}
                      isLoading={isSearching}
                      count={count}
                    />
                    <SearchResults organizations={organizationCombinations} isSearching={isSearching} count={count} />
                  </GridItem>
                </Grid>
              </GridItem>
            </Grid>
          </Box>
        </GridItem>
      </Grid>
    </Layout>
  );
};
