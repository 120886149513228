import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { requireDataFields } from "../../../../typeguards";
import { useContractState } from "../../../useContractState";
import { GenericMonetaryValue } from "./GenericMonetaryValue";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const PricePerYear: React.FC<Props> = ({ removeDataField, isRequiredButNotProvided }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const contractState = useContractState();
  const dataFields = requireDataFields(contractState.contract);
  const amount = dataFields.pricePerYear ?? null;
  const errorMessage = containsError(contractState, "EditPricePerYear") ? t("Update failed") : null;

  return (
    <GenericMonetaryValue
      label={t("Price per year")}
      placeholderText={""}
      helperText={t("Provide the price per year of the contract")}
      amount={amount}
      errorMessage={errorMessage}
      editAmount={async (newAmount) => {
        await dispatch(
          editContractThunk({
            command: {
              type: "EditPricePerYear",
              value: newAmount ?? null,
            },
          })
        );
      }}
      removeDataField={removeDataField}
      isRequiredButNotProvided={isRequiredButNotProvided}
    />
  );
};
