import { Box, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

export interface RadioOption {
  label: string;
  description?: React.ReactElement;
  value: string;
}

interface Props {
  value: string;
  options: RadioOption[];
  onChange: (value: RadioOption) => void;
}

export const RadioSelector = ({ value, options, onChange }: Props) => {
  const [selectedValue, setSelectedValue] = useState<RadioOption>();

  useEffect(() => {
    if (selectedValue) {
      onChange(selectedValue);
    }
  }, [onChange, selectedValue]);

  useEffect(() => {
    const matches = options.filter((option) => option.value === value);
    setSelectedValue(matches[0]);
  }, [options, value]);

  return (
    <RadioGroup
      defaultValue={options[0].value}
      value={selectedValue?.value}
      onChange={(e) => {
        const matches = options.filter((option) => option.value === e);
        setSelectedValue(matches[0]);
      }}
    >
      <Stack>
        {options.map((e) => (
          <Box key={e.value}>
            <Radio value={e.value} size="sm">
              {e.label}
            </Radio>
            {e.description && <Box ml="25px">{e.description}</Box>}
          </Box>
        ))}
      </Stack>
    </RadioGroup>
  );
};
