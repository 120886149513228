import { Box, Button, Table, Tbody, Td, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteOrgUserEmailInviteMutation, useListOrgUserEmailInvitesQuery } from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { OrgDto } from "../../../../common/redux/reducers/authStateReducer";
import { getRoleName } from "./utils";

interface Props {
  org: OrgDto;
}

export const InvitedUsersTable = ({ org }: Props) => {
  const { data } = useListOrgUserEmailInvitesQuery({
    orgId: org.id,
  });
  const toast = useToast();
  const [deleteInvite] = useDeleteOrgUserEmailInviteMutation();
  const [deletingInviteIds, setDeletingInviteIds] = useState<string[]>([]);
  const { t } = useTranslation();
  const displayer = useApiError();
  if (!data) {
    return <div>Loading...</div>;
  }
  return (
    <Box
      backgroundColor="smBackgroundSecondary"
      borderRadius="lg"
      mt="5"
      boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
    >
      <Table variant="simple" mb="30px">
        <Thead>
          <Tr>
            <Th>{t("Email")}</Th>
            <Th>{t("Role")}</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.invitations.map((e) => {
            return (
              <Tr key={e.id}>
                <Td>{e.email}</Td>
                <Td>{t(getRoleName(e.role))}</Td>
                <Td>
                  <Button
                    variant={"solid"}
                    colorScheme="red"
                    size="sm"
                    onClick={async () => {
                      setDeletingInviteIds((ids) => [...ids, e.id]);
                      const result = await deleteInvite({
                        orgId: org.id,
                        inviteId: e.id,
                      });
                      setDeletingInviteIds((ids) => ids.filter((id) => id !== e.id));

                      if ("data" in result) {
                        toast({
                          title: t("Invite deleted!"),
                          status: "success",
                        });
                      } else {
                        displayer.trigger(result.error);
                      }
                    }}
                    isLoading={deletingInviteIds.indexOf(e.id) !== -1}
                  >
                    {t("Remove")}
                  </Button>
                </Td>
              </Tr>
            );
          })}
          {data.invitations.length === 0 && (
            <Tr>
              <Td colSpan={3} width={"100%"} textAlign="center">
                {t("No users have been invited")}.
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};
