import { Button, Flex, Icon, Spacer, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaBuilding, FaHourglass } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAcceptSeEmailInviteMutation, useGetSeEmailInviteQuery } from "../../../autogen/bff-api";
import { useApiError } from "../../../common/errors/useApiError";
import { displayDateWithTime } from "../../../common/formatting/displayDateWithTime";
import { requireStringEnvVar } from "../../../config";
import { urls } from "../../../urls";

interface Props {
  inviteId: string;
  secret: string;
}

export const AcceptSourcingEventEmailInvite = ({ inviteId, secret }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isAccepting, setIsAccepting] = useState(false);
  const {
    data,
    error,
    isLoading: isGetting,
  } = useGetSeEmailInviteQuery({
    inviteId: inviteId,
    secret: secret,
  });
  const displayer = useApiError();
  const toast = useToast();

  const [acceptInvite] = useAcceptSeEmailInviteMutation();

  if (isGetting || isAccepting) {
    return <div>{t("Loading")}...</div>;
  }

  if (error) {
    toast({
      title: t("Whooops!"),
      description: t("Did you click an expired or already accepted email invite?"),
      status: "warning",
    });
    navigate(urls.home);
  }

  if (!data) {
    return <div>{t("Loading")}...</div>;
  }
  return (
    <Flex flexDirection={"column"}>
      <Text fontSize="2xl" mt="5px">
        <b>{t("Invitation to bid")}</b>
      </Text>
      <Text fontSize="md" mt="20px">
        {t("You have been invited to bid on")}: <b>{data.eventTitle}</b>.
      </Text>
      <Flex alignItems="center" mt="20px">
        <Flex>
          <Icon as={FaBuilding} color="teal" w="12px" h="12px" />
        </Flex>
        <Flex ml="5px">
          <Text fontSize={"md"} color="teal">
            <b>{t("Buyer")}: </b> {data.owningOrganizationName}
          </Text>
        </Flex>
        <Spacer />
      </Flex>
      <Flex alignItems="center" mt="20px">
        <Flex>
          <Icon as={FaHourglass} color="teal" w="12px" h="12px" />
        </Flex>
        <Flex ml="5px">
          <Text fontSize={"md"} color="teal">
            <b>{t("Bid delivery deadline")}: </b>
            {displayDateWithTime(data.eventDeadline, t("at"))}
          </Text>
        </Flex>
        <Spacer />
      </Flex>
      <Button
        mt="20px"
        variant={"solid"}
        colorScheme="blue"
        isLoading={isAccepting}
        onClick={async () => {
          setIsAccepting(true);
          const result = await acceptInvite({
            inviteId: inviteId,
            secret: secret,
          });
          if ("data" in result) {
            toast({
              title: t("Invitation accepted!"),
              status: "success",
            });
            window.location.replace(requireStringEnvVar("VITE_WEBAPP_BASE_URL"));
          } else {
            setIsAccepting(false);
            displayer.trigger(result.error);
          }
        }}
      >
        {t("Click here to accept the invitation")}
      </Button>
      <>
        <Text mt="20px">{t("or you can")}</Text>
        <Button
          mt="20px"
          variant={"solid"}
          colorScheme="purple"
          onClick={() => {
            navigate(urls.home);
          }}
        >
          {t("Go home")}
        </Button>
      </>
    </Flex>
  );
};
