import { Box, Button, Icon } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaMicrosoft } from "react-icons/fa";
import { authService } from "../../../config";

export const AzureLoginButton = () => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { t } = useTranslation();

  const login = async () => {
    setIsLoggingIn(true);
    await authService.signupWithAzureAD();
    setIsLoggingIn(false);
  };

  return (
    <Box w="100%" pt="25px" pb="15px">
      <form onSubmit={login}>
        <Button
          leftIcon={<Icon as={FaMicrosoft} w="15px" h="15px" />}
          variant={"solid"}
          isLoading={isLoggingIn}
          colorScheme="teal"
          w="100%"
          bgGradient={"linear(to-l, teal, blue.300)"}
          onClick={login}
        >
          {t("Log in with Entra ID")}
        </Button>
      </form>
    </Box>
  );
};
