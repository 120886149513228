import {
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import { FaCheck, FaLock } from "react-icons/fa";

export interface Message {
  id: string;
  value: string;
  createdBy?: string;
  createdAt: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  conversation?: Message[];
  handleMessage: (msg: string) => unknown;
  isLoading: boolean;
  canComment: boolean;
  title?: string;
}

export const ConversationModal = ({
  conversation,
  handleMessage,
  isLoading,
  isOpen,
  onClose,
  canComment,
  title,
}: Props) => {
  const endOfMessagesRef = useRef<HTMLDivElement>(null);

  const [newMessage, setNewMessage] = useState<string>("");

  const scrollToBottom = useCallback(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (isOpen) scrollToBottom();
  }, [conversation, isOpen, scrollToBottom]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
      <ModalOverlay />
      <ModalContent width="full" height={"80%"} maxHeight={"80%"} padding="5" overflow={"hidden"}>
        <ModalCloseButton />
        <ModalBody height={"full"}>
          <Flex height={"full"} flexDirection={"column"}>
            <Heading as="h2" size="md" pb="2">
              {title ?? t("Internal conversation")}
            </Heading>
            <Flex fontSize={"x-small"} color="gray.500" alignItems={"center"} pb="5">
              <Icon as={FaLock} mr="1" />
              {t("This conversation is not visible to suppliers")}
            </Flex>
            <Flex grow={1} flexDirection={"column"} overflow={"scroll"}>
              {conversation?.map((message) => (
                <Box key={message.id} py="2">
                  <Text color="gray.500" fontSize={"x-small"}>
                    <b>{message.createdBy ?? t("Unkown")}</b>, {message.createdAt}
                  </Text>
                  <Text>{message.value}</Text>
                </Box>
              ))}
              {conversation?.length === 0 && (
                <Text width={"full"} textAlign={"center"} pt="8">
                  {t("No messages yet")}
                </Text>
              )}
              <div ref={endOfMessagesRef} />
            </Flex>
            {canComment && (
              <Flex>
                <Input placeholder={t("Aa") ?? ""} value={newMessage} onChange={(e) => setNewMessage(e.target.value)} />
                <IconButton
                  isLoading={isLoading}
                  colorScheme="teal"
                  variant={"outline"}
                  aria-label="save comment"
                  icon={<Icon as={FaCheck} />}
                  onClick={async () => {
                    if (!newMessage) return;
                    await handleMessage(newMessage);
                    setNewMessage("");
                  }}
                />
              </Flex>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
