import { Search2Icon } from "@chakra-ui/icons";
import { Box, Flex, Grid, GridItem, Heading, Input, InputGroup, InputLeftElement, Text } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetContractStatisticsQuery,
  useListContractTemplatesQuery,
  useListContractsQuery,
} from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { Layout } from "../../../common/layout/Layout";
import { CONTENT_WIDTH } from "../../../config";
import { ContractsTable } from "./ContractsTable";
import { ContractFilters } from "./filtering/ContractFilters";

export const PublishedContracts = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const authState = useLoggedInWithOrgContextState();
  const displayer = useApiError();

  const [filters, setFilters] = useState(() => {
    const params = new URLSearchParams(location.search);
    return {
      title: params.get("title") || "",
      status: params.get("status") || "",
      project: params.get("project") || "",
      counterparty: params.get("counterparty") || "",
      internalParty: params.get("internalParty") || "",
      owner: params.get("owner") || "",
      type: params.get("type") || "",
      skip: +(params.get("skip") || 0),
      limit: +(params.get("limit") || 10),
    };
  });

  useEffect(() => {
    const params = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value || value === 0) params.set(key, `${value}`);
    });

    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [filters, navigate, location.pathname]);

  const [searchString, setSearchString] = useState(filters.title);
  const { data, error, isLoading, isFetching } = useListContractsQuery({
    orgId: authState.selectedOrg.id,
    title: filters.title,
    state: "Published",
    statuses: filters.status,
    owners: filters.owner,
    counterparties: filters.counterparty,
    templates: filters.type,
    internalparties: filters.internalParty,
    projects: filters.project,
    limit: filters.limit,
    skip: filters.skip,
  });
  const { data: stats } = useGetContractStatisticsQuery({
    orgId: authState.selectedOrg.id,
    organizationId: authState.selectedOrg.id,
  });
  const { data: templateData } = useListContractTemplatesQuery({
    orgId: authState.selectedOrg.id,
    organizationId: authState.selectedOrg.id,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchString = useCallback(
    debounce(
      (searchString) =>
        setFilters((prev) => ({
          ...prev,
          title: searchString,
          skip: 0,
        })),
      300
    ),
    [setFilters]
  );

  if (error) return displayer.show(error);

  return (
    <Layout>
      <Grid
        height="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"1fr"}
        templateAreas={`"leftContent content rightContent"`}
      >
        <GridItem area={"content"} px="2">
          <Grid h="100%" gridTemplateColumns={"1fr"} gridTemplateRows={"auto 1fr"}>
            <GridItem display="flex" justifyContent={"space-between"}>
              <Box>
                <Heading as="h2" size="lg" mt="10px">
                  {t("All contracts")}
                </Heading>
                <Box mt="5px">
                  <Text fontSize="medium" color="smMuted" mb="10px">
                    {t("All contracts that you have access to")}
                  </Text>
                </Box>
              </Box>
              <Flex justifyContent={"center"} alignItems={"center"}>
                <Flex
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  p="4"
                  rounded="lg"
                  backgroundColor="smBackgroundSecondary"
                  mr="2"
                >
                  <Text fontSize={"sm"}>{t("All contracts")}</Text>
                  <Text fontWeight={"bold"} fontSize={"sm"}>
                    {stats?.numberOfContracts}
                  </Text>
                </Flex>
                <Flex
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  p="4"
                  rounded="lg"
                  backgroundColor="smBackgroundSecondary"
                >
                  <Text fontSize={"sm"}>{t("Active contracts")}</Text>
                  <Text fontWeight={"bold"} fontSize={"sm"}>
                    {stats?.numberOfActiveContracts}
                  </Text>
                </Flex>
              </Flex>
            </GridItem>
            {data && templateData && (
              <GridItem width={"full"} maxWidth={"full"} overflow={"hidden"} pb="2">
                <Box mt="15px" mb="10px">
                  <Flex w="100%">
                    <InputGroup size="sm">
                      <InputLeftElement pointerEvents="none">
                        <Search2Icon />
                      </InputLeftElement>
                      <Input
                        type="text"
                        size="sm"
                        placeholder={t("Contract title") ?? ""}
                        rounded="md"
                        value={searchString}
                        onChange={(e) => {
                          setSearchString(e.target.value);
                          handleDebouncedSearchString(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </Flex>
                  <Flex mt="15px" w="100%">
                    <ContractFilters
                      selectedCounterparties={filters.counterparty === "" ? [] : filters.counterparty.split(",")}
                      onCounterpartsChange={(values) => {
                        setFilters((prev) => ({
                          ...prev,
                          counterparty: values.map((v) => v.value).join(","),
                          skip: 0,
                        }));
                      }}
                      selectedOwners={filters.owner === "" ? [] : filters.owner.split(",")}
                      onOwnersChange={(values) => {
                        setFilters((prev) => ({ ...prev, owner: values.map((v) => v.value).join(","), skip: 0 }));
                      }}
                      selectedStatuses={filters.status === "" ? [] : filters.status.split(",")}
                      onStatusesChange={(values) => {
                        setFilters((prev) => ({ ...prev, status: values.map((v) => v.value).join(","), skip: 0 }));
                      }}
                      selectedTypes={filters.type === "" ? [] : filters.type.split(",")}
                      onTypesChange={(values) => {
                        setFilters((prev) => ({ ...prev, type: values.map((v) => v.value).join(","), skip: 0 }));
                      }}
                      templates={templateData.templates}
                      selectedInternalParties={filters.internalParty === "" ? [] : filters.internalParty.split(",")}
                      onInternalPartiesChange={(values) => {
                        setFilters((prev) => ({
                          ...prev,
                          internalParty: values.map((v) => v.value).join(","),
                          skip: 0,
                        }));
                      }}
                      selectedProjects={filters.project === "" ? [] : filters.project.split(",")}
                      onProjectsChange={(values) => {
                        setFilters((prev) => ({ ...prev, project: values.map((v) => v.value).join(","), skip: 0 }));
                      }}
                    />
                  </Flex>
                </Box>
                <ContractsTable
                  contracts={data.contracts}
                  isLoading={isLoading || isFetching}
                  limit={filters.limit}
                  skip={filters.skip}
                  setSkip={(skip) => setFilters((prev) => ({ ...prev, skip }))}
                />
              </GridItem>
            )}
            {(!data || !templateData) && !isLoading && !isFetching && <Text>{t("Something went wrong")}...</Text>}
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};
