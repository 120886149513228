import { useMemo } from "react";
import { BseDto, ReceivedBseBidDto } from "../../../../../autogen/bff-api";
import { ProductGroup } from "../../../../../common/types";
import { isAwardedProduct } from "./BidEvaluationUtils";

export const useIsAwardedProduct = ({
  group,
  productId,
  bidId,
  bids,
  sourcingEvent,
}: {
  group: ProductGroup;
  productId: string;
  bidId: string;
  bids: ReceivedBseBidDto[];
  sourcingEvent: BseDto;
}) =>
  useMemo(
    () => isAwardedProduct({ group, productId, bidId, bids, sourcingEvent }),
    [bidId, bids, group, productId, sourcingEvent]
  );
