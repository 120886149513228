import { Box, Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ContractDto, IfrsAdjustmentType } from "../../../../../../../autogen/bff-api";
import { displayMonth } from "../../../../../../../common/formatting/displayMonth";
import { displaySum } from "../../../../../../../common/formatting/displaySum";
import { useAppDispatch } from "../../../../../../../common/redux/hooks";
import { editContractThunk } from "../../../../../../../common/redux/thunks/contract/edit-contract-thunk";

interface Props {
  contract: ContractDto;
}

export const AdjustmentsTable: React.FC<Props> = ({ contract }) => {
  const { t } = useTranslation();
  const ifrsValues = contract.dataFields?.ifrsValues;
  if (!ifrsValues) throw new Error("No IFRS values");
  const startDate = ifrsValues.startDate;
  if (!startDate) throw new Error("No start date");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const adjustments: {
    date: string;
    type: IfrsAdjustmentType;
    value: number;
  }[] = ifrsValues.adjustments.map((e) => {
    return {
      date: moment(startDate)
        .add(e.monthNumber - 1, "months")
        .format("YYYY-MM-DD"),
      type: e.type,
      value: e.value,
    };
  });

  const removeCurrencyAmountAdjustment = async (date: string) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "RemoveMonetaryAdjustment",
          value: date,
        },
      })
    );
    setIsLoading(false);
  };

  const removePercentageAmountAdjustment = async (date: string) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "RemovePercentageAdjustment",
          value: date,
        },
      })
    );
    setIsLoading(false);
  };

  return (
    <Box bg="#f6f8fb" borderRadius={10} mt="20px" boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)">
      <Table variant="simple" mb="30px">
        <Thead>
          <Tr>
            <Th>{t("From date")}</Th>
            <Th>{t("Type")}</Th>
            <Th>{t("Value")}</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {adjustments.map((e) => {
            return (
              <Tr key={e.date + e.type}>
                <Td>{displayMonth(e.date)}</Td>
                <Td>{e.type === "Monetary" ? t("Amount") : t("Percentage")}</Td>
                <Td>
                  {e.type === "Monetary"
                    ? displaySum({ value: e.value, currency: contract.currency })
                    : `${e.value * 100}%`}
                </Td>
                <Td>
                  <Button
                    variant={"solid"}
                    colorScheme="red"
                    onClick={() =>
                      e.type === "Monetary"
                        ? removeCurrencyAmountAdjustment(e.date)
                        : removePercentageAmountAdjustment(e.date)
                    }
                    isLoading={isLoading}
                  >
                    {t("Remove")}
                  </Button>
                </Td>
              </Tr>
            );
          })}
          {adjustments.length === 0 && (
            <Tr>
              <Td colSpan={3} width={"100%"} textAlign="center">
                {t("No adjustments have been added")}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};
