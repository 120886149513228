import { Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { assets } from "../../../assets/assets";

export const EmailVerification: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Grid
        gridTemplateColumns={"1fr 450px 1fr"}
        height="100%"
        gridTemplateRows={"1fr auto 2fr"}
        templateAreas={`
                "left top right"
                "left login right"
                "left bottom right"
                `}
      >
        <GridItem area={"left"} bg="#f8f8f8"></GridItem>
        <GridItem area={"top"} bg="#f8f8f8"></GridItem>
        <GridItem area={"bottom"} bg="#f8f8f8"></GridItem>
        <GridItem area={"right"} bg="#f8f8f8"></GridItem>
        <GridItem area={"login"} borderRadius={10} boxShadow="0 9px 46px 8px rgb(0,0,0,0.2)" padding={10}>
          <Flex w="100%" h={"100%"} flexDirection="column">
            <Flex>
              <Image src={assets.logo} alt="Logo" h="30px" mt="10px" />
            </Flex>
            <br />
            <Text color={"teal.600"}>
              <b>{t("Email confirmed!")}</b> <br />
              {t("Feel free to continue where you left off")}.
            </Text>
          </Flex>
        </GridItem>
      </Grid>
    </div>
  );
};
