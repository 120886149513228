import {
  Button,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useOutsideClick,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes, FaTrashAlt } from "react-icons/fa";

interface Props {
  confirmRemoval: () => Promise<void>;
}

export const RemoveFileButton = ({ confirmRemoval }: Props) => {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const ref = useRef(null);
  const { t } = useTranslation();

  useOutsideClick({
    ref: ref,
    handler: () => setShowConfirmation(false),
  });

  return (
    <Flex ref={ref}>
      <Popover
        returnFocusOnClose={true}
        isOpen={showConfirmation}
        placement="bottom"
        closeOnBlur={true}
        offset={[0, -3]}
      >
        <PopoverTrigger>
          <IconButton
            aria-label="Remove file"
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="smMuted"
            variant={"unstyled"}
            onClick={async () => setShowConfirmation(true)}
            icon={<Icon as={FaTimes} />}
          />
        </PopoverTrigger>
        <PopoverContent p="1" width="fit-content">
          <PopoverArrow />
          <Button
            rightIcon={<Icon as={FaTrashAlt} />}
            colorScheme="red"
            variant="ghost"
            isLoading={isDeleting}
            onClick={async () => {
              setIsDeleting(true);
              await confirmRemoval();
              setIsDeleting(false);
              setShowConfirmation(false);
            }}
          >
            {t("Confirm")}
          </Button>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};
