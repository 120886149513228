import { Box, Divider, Flex, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ContractState } from "../../../../common/redux/reducers/contractReducer";
import { urls } from "../../../../urls";
import { requireDataFields } from "../../typeguards";

interface Props {
  data: ContractState["contract"];
}

export const LinkedContracts = ({ data }: Props) => {
  const { t } = useTranslation();
  const dataFields = requireDataFields(data);
  const linkedContracts = dataFields.linkedContracts;
  const addedDataFields = dataFields.addedDataFields.map((e) => e.name);

  if (!addedDataFields.includes("LinkedContracts")) {
    return null;
  }

  if (!linkedContracts || (linkedContracts && linkedContracts.length === 0)) {
    return (
      <Box padding="15px" paddingBottom={"20px"} w="100%">
        <Text fontWeight={"bold"} fontSize="sm" color="#808B9B">
          {t("Linked contracts")}
        </Text>
        <Divider marginTop={"5px"} marginBottom={"5px"} />
        <Text fontStyle={"italic"}>{t("Not provided")}</Text>
      </Box>
    );
  }

  return (
    <Box padding="15px" paddingBottom={"20px"} w="100%">
      <Text fontWeight={"bold"} fontSize="sm" color="#808B9B">
        {t("Linked contracts")}
      </Text>
      <Divider marginTop={"5px"} marginBottom={"5px"} />
      {linkedContracts.map((e) => (
        <Flex mt="7px" key={e.id}>
          <Link href={urls.contracts.viewSingle.go(e.id)} color="blue.500" target="_blank">
            {e.title}
          </Link>
        </Flex>
      ))}
    </Box>
  );
};
