import { Flex, Spinner, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { BseDto, ReceivedBseBidDto, useListReceivedBseBidsQuery } from "../../../../autogen/bff-api";
import { CreateContractDetail } from "./CreateContractDetail";

export const CreateContracts = ({ sourcingEvent }: { sourcingEvent: BseDto }) => {
  const [winningBids, setWinningBids] = useState<ReceivedBseBidDto[]>();

  const { data: bidsResponse, error, isLoading } = useListReceivedBseBidsQuery({ eventId: sourcingEvent.id });

  useEffect(() => {
    setWinningBids(bidsResponse?.bids.filter((bid) => sourcingEvent.awardedFields?.bidIds.includes(bid.id)));
  }, [bidsResponse?.bids, sourcingEvent.awardedFields?.bidIds]);

  if (error) return <Text>{t("Something went wrong, could not load bids")}...</Text>;

  if (isLoading) {
    return (
      <Flex width={"full"} justifyContent={"center"} pt="8">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Flex flexDirection={"column"} pt="4">
      <Text fontWeight={"semibold"} fontSize={"lg"}>
        {t("Winning suppliers")}
      </Text>
      {winningBids?.map((bid) => (
        <CreateContractDetail key={bid.id} bid={bid} sourcingEvent={sourcingEvent} />
      ))}
    </Flex>
  );
};
