import { useCallback } from "react";
import { ReceivedBseBidDto, useUpdateBasicSourcingEventMutation } from "../../../../../autogen/bff-api";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { removeBid } from "../../../../../common/redux/reducers/basicSourcingEventReducer";
import { ProductGroup } from "../../../../../common/types";

export const useRemoveProductBid = ({
  bid,
  productId,
  group,
  eventId,
}: {
  bid: ReceivedBseBidDto;
  productId: string;
  group: ProductGroup;
  eventId: string;
}) => {
  const [updateSourcingEvent] = useUpdateBasicSourcingEventMutation();
  const dispatch = useAppDispatch();
  return useCallback(() => {
    dispatch(removeBid({ bid, productId, group }));
    updateSourcingEvent({
      eventId,
      editSourcingEventRequest: {
        removeProductBids: [{ productGroupId: group.id, bids: [{ productId, bids: [bid.id] }] }],
      },
    });
  }, [bid, dispatch, eventId, group, productId, updateSourcingEvent]);
};
