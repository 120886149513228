import { Button, Icon, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaArchive, FaEdit, FaInbox, FaPaperPlane, FaPlay, FaPlus, FaShoppingBasket } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useCreateBasicSourcingEventModal } from "../../../../pages/sourcing-events/buyer/useCreateBasicSourcingEventModal";
import { urls } from "../../../../urls";
import { useLoggedInWithOrgContextState } from "../../../auth/useLoggedInWithOrgContextState";
import { useSub } from "../../../subscription/useSub";
import { Explanation } from "../../../support/Explanation/Explanation";

export const Sourcing = () => {
  const navigate = useNavigate();
  const authState = useLoggedInWithOrgContextState();
  const { showCreateBasicSourcingEventModal, createBasicSourcingEventModal } = useCreateBasicSourcingEventModal();
  const { t } = useTranslation();
  const sub = useSub();

  const canCreateEvent = authState.selectedOrg.canCreateEvent;

  return (
    <>
      {createBasicSourcingEventModal}
      {sub.hasBuyerAccess && (
        <>
          <Menu>
            <MenuButton
              as={Button}
              leftIcon={<Icon as={FaShoppingBasket} w="15px" h="15px" />}
              colorScheme="teal"
              variant="ghost"
              size={"sm"}
            >
              {t("Sourcing")}
            </MenuButton>
            <MenuList>
              <Explanation
                enabled={!canCreateEvent}
                placement={"left-start"}
                text={t("Only admins and creators can create new events")}
              >
                <MenuItem
                  isDisabled={!canCreateEvent}
                  icon={<Icon as={FaPlus} w="15px" h="15px" marginBottom={-0.5} />}
                  onClick={showCreateBasicSourcingEventModal}
                >
                  {t("New event")}
                </MenuItem>
              </Explanation>
              <MenuItem
                icon={<Icon as={FaEdit} w="15px" h="15px" marginBottom={-0.5} />}
                onClick={() => {
                  navigate(urls.events.viewDrafts);
                }}
              >
                {t("Event drafts")}
              </MenuItem>
              <MenuItem
                icon={<Icon as={FaPlay} w="15px" h="15px" marginBottom={-0.5} />}
                onClick={() => {
                  navigate(urls.events.viewOngoing);
                }}
              >
                {t("Ongoing events")}
              </MenuItem>
              <MenuItem
                icon={<Icon as={FaArchive} w="15px" h="15px" marginBottom={-0.5} />}
                onClick={() => {
                  navigate(urls.events.viewCompleted);
                }}
              >
                {t("Completed events")}
              </MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuList>
              <MenuItem
                icon={<Icon as={FaPaperPlane} w="15px" h="15px" marginBottom={-0.5} />}
                onClick={() => {
                  navigate(urls.bids.viewAll);
                }}
              >
                {t("Delivered bids")}
              </MenuItem>
              <MenuItem
                icon={<Icon as={FaInbox} w="15px" h="15px" marginBottom={-0.5} />}
                onClick={() => {
                  navigate(urls.bids.received);
                }}
              >
                {t("Received bids")}
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      )}
      {!sub.hasBuyerAccess && (
        <>
          <Menu>
            <MenuButton
              as={Button}
              leftIcon={<Icon as={FaShoppingBasket} w="15px" h="15px" />}
              colorScheme="teal"
              variant="ghost"
              size={"sm"}
            >
              {t("Bids")}
            </MenuButton>
            <MenuList>
              <MenuItem
                icon={<Icon as={FaInbox} w="15px" h="15px" marginBottom={-0.5} />}
                onClick={() => navigate(urls.events.viewInvitations)}
              >
                {t("Bid invitations")}
              </MenuItem>
              <MenuItem
                icon={<Icon as={FaPaperPlane} w="15px" h="15px" marginBottom={-0.5} />}
                onClick={() => navigate(urls.bids.viewAll)}
              >
                {t("Delivered bids")}
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      )}
    </>
  );
};
