import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    Spacer,
    Textarea,
    useToast,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useCreateContractTaskCommentMutation } from '../../../../../../autogen/bff-api'

interface Props {
    taskId: string
    onComplete: () => void
}

interface FormValues {
    comment: string
}

export const AddComment: React.FC<Props> = ({ taskId, onComplete }) => {
    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation()
    const [createComment] = useCreateContractTaskCommentMutation()
    const toast = useToast()
    const {
        formState: { errors },
        register,
        handleSubmit,
        watch,
        setValue,
    } = useForm<FormValues>({
        defaultValues: {
            comment: '',
        },
    })

    const currentValue = watch('comment')
    return (
        <form
            onSubmit={handleSubmit(async (values: FormValues) => {
                setIsLoading(true)
                const result = await createComment({
                    taskId: taskId,
                    createContractTaskCommentRequest: {
                        text: values.comment,
                    },
                })
                setIsLoading(false)

                if ('data' in result) {
                    setValue('comment', '')
                    onComplete()
                } else {
                    toast({
                        title: t('Could not add comment'),
                        status: 'error',
                    })
                }
            })}
        >
            <FormControl mt="10px" isInvalid={errors.comment !== undefined}>
                <Textarea
                    placeholder={t('Add a comment') ?? ''}
                    {...register('comment', {
                        minLength: {
                            value: 2,
                            message: t('Must be at least 2 characters long'),
                        },
                    })}
                />
                <FormErrorMessage>
                    {errors.comment && errors.comment.message}
                </FormErrorMessage>
            </FormControl>
            {currentValue.trim().length > 1 && (
                <Flex flexDirection={'column'} alignItems="end">
                    <Flex mt="5px">
                        <Spacer />
                        <Button
                            id="add-comment-button"
                            variant={'solid'}
                            colorScheme="teal"
                            size={'sm'}
                            isLoading={isLoading}
                            type="submit"
                        >
                            {t('Save')}
                        </Button>
                    </Flex>
                </Flex>
            )}
        </form>
    )
}
