import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useState } from "react";
import { ReceivedBseBidDto, useGetProductGroupsForSourcingEventQuery } from "../../autogen/bff-api";
import { mapDtoToProductGroup } from "../redux/reducers/productReducer";
import { Product, ProductGroup } from "../types";

export const useBidProductGroupsAsBuyer = (bid?: ReceivedBseBidDto) => {
  const [productGroups, setProductGroups] = useState<ProductGroup[]>();

  const {
    data,
    isFetching: isLoadingProductGroups,
    error: productGroupsError,
  } = useGetProductGroupsForSourcingEventQuery(
    bid?.forSourcingEventId ? { sourcingEventId: bid.forSourcingEventId } : skipToken
  );

  useEffect(() => {
    if (
      data?.productGroups.length &&
      (!productGroups?.length || data.productGroups[0].sourcingEventId !== productGroups[0].sourcingEventId)
    ) {
      setProductGroups(
        data.productGroups
          .map((g) => mapDtoToProductGroup(g))
          .map((group) => {
            const groupBid = bid?.products?.find((g) => g.productGroupId === group.id);
            return {
              ...group,
              products: group.products?.map((product: Product) => {
                const productBid = groupBid?.productBids.find((p) => p.productId === product.id);
                return {
                  ...product,
                  unitPrice: productBid?.unitPrice,
                  productFields: [
                    ...product.productFields.filter((f) => f.populatedBy === "Buyer"),
                    ...product.productFields
                      .filter((f) => f.populatedBy === "Supplier")
                      .map((field) => {
                        const value = productBid?.productFields.find((f) => f.name === field.name)?.value;
                        return { ...field, value };
                      }),
                  ],
                };
              }),
            };
          })
      );
    }
  }, [bid?.products, data?.productGroups, productGroups]);

  return { productGroups, isLoading: isLoadingProductGroups, error: productGroupsError };
};
