import { IconButton } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useListMyNotificationsQuery } from "../../../../autogen/bff-api";
import { urls } from "../../../../urls";
import { NotificationsIcon } from "./NotificationsIcon";

export const Notifications = () => {
  const navigate = useNavigate();
  const { data } = useListMyNotificationsQuery({
    unseen: true,
  });

  return (
    <IconButton
      mr="5px"
      ml="-10px"
      aria-label="notifications"
      variant={"ghost"}
      colorScheme="teal"
      onClick={() => {
        navigate(urls.notifications);
      }}
      icon={<NotificationsIcon unseenNotificationCount={data?.notifications.length ?? 0} size={5} />}
    />
  );
};
