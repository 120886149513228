import { Flex, Switch, Tooltip } from "@chakra-ui/react";
import { ReactNode, forwardRef, useState } from "react";

interface Props {
  children?: ReactNode;
}

const Toggle = forwardRef<HTMLDivElement, Props>(({ children, ...rest }, ref) => (
  <Flex ref={ref} {...rest} justifyContent={"center"} alignItems={"center"}>
    {children}
  </Flex>
));

Toggle.displayName = "Toggle";

export const ToggleInternalField = ({
  isChecked,
  onChange,
  label,
}: {
  label: string;
  isChecked: boolean;
  onChange: (checked: boolean) => void;
}) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  return (
    <Toggle>
      <Tooltip label={label} isOpen={isTooltipOpen} placement="bottom" gutter={20}>
        <Switch
          rounded="md"
          mr="2"
          size="sm"
          isChecked={isChecked}
          onChange={(e) => onChange(e.target.checked)}
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
        />
      </Tooltip>
    </Toggle>
  );
};
