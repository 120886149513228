import { Button, Flex, Icon, Td, Text, Tr, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import {
  ContractOrgParticipantDto,
  ContractRoleType,
  useDeleteContractParticipantMutation,
} from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";

interface Props {
  contractId: string;
  onRemoved: () => Promise<void>;
  participant: ContractOrgParticipantDto;
  canDelete: boolean;
}

export const OrganizationParticipantRow = ({ contractId, onRemoved, participant, canDelete }: Props) => {
  const { t } = useTranslation();
  const [remove] = useDeleteContractParticipantMutation();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const displayer = useApiError();

  const removeParticipant = async () => {
    setIsLoading(true);
    const result = await remove({
      contractId: contractId,
      deleteContractParticipantRequest: {
        organizationId: participant.id,
      },
    });
    if ("data" in result) {
      await onRemoved();
      toast({
        title: t("Participant removed"),
        status: "success",
      });
    } else {
      displayer.trigger(result.error);
    }
    setIsLoading(false);
  };

  const getPermissionNameTranslationKey = (name: ContractRoleType): string => {
    switch (name) {
      case "Counterparty":
        return t("Counterparty");
      case "Editor":
        return t("Editor");
      case "Viewer":
        return t("Viewer");
    }
  };

  return (
    <Tr key={participant.id}>
      <Td>
        <Text fontSize={"sm"}>{participant.name}</Text>
      </Td>
      <Td>
        <Text fontSize={"sm"}>{t(getPermissionNameTranslationKey(participant.roleType))}</Text>
      </Td>
      <Td>
        {canDelete && (
          <Flex justifyContent="end">
            <Button
              leftIcon={<Icon as={FaTimes} w="15px" h="15px" />}
              colorScheme="red"
              variant="outline"
              size={"xs"}
              isLoading={isLoading}
              marginRight={1}
              onClick={removeParticipant}
            >
              {t("Delete")}
            </Button>
          </Flex>
        )}
      </Td>
    </Tr>
  );
};
