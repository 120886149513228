import { Box, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Select, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Category } from "../../../../../../autogen/bff-api";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../../useContractState";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const SupplierCategory = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const contractState = useContractState();
  const [isLoading, setIsLoading] = useState(false);
  const [supplierCategory, setSupplierCategory] = useState<Category>();
  const errorMessage = containsError(contractState, "EditSupplierCategory") ? t("Update failed") : null;

  const dispatch = useAppDispatch();
  const options: Category[] = ["A", "B", "C", "D", "E"];

  const eventHandler = async (value: Category) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "EditSupplierCategory",
          value: value === t("None") ? undefined : value,
        },
      })
    );
    setIsLoading(false);
  };

  useEffect(() => {
    setSupplierCategory(contractState.contract.dataFields?.supplierCategory);
  }, [contractState.contract.dataFields?.supplierCategory]);

  return (
    <FormControl isInvalid={errorMessage !== null} isRequired={isRequiredButNotProvided}>
      <FormLabel htmlFor={"value"}>
        {t("Supplier category")} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Flex w="100%">
        <Box borderRadius={"10px"} backgroundColor="smBackground" w="100%">
          <Select
            value={supplierCategory ?? undefined}
            onChange={(e) => {
              setSupplierCategory(e.target.value as Category);
              eventHandler(e.target.value as Category);
            }}
          >
            <option value={undefined}>{t("None")}</option>
            {options.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </Select>
        </Box>
        <RemoveDataFieldButton removeDataField={removeDataField} />
      </Flex>
      <FormHelperText>{t("Provide the supplier category for this contract")}</FormHelperText>
      <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
