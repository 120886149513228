import { Box, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import { ListSourcingEventsFilterStatus, useListEventsForBuyerQuery } from "../../../../autogen/bff-api";
import { CONTENT_WIDTH } from "../../../../config";

import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { Layout } from "../../../../common/layout/Layout";
import { SourcingEventsTableForBuyer } from "./SourcingEventsTableForBuyer";

interface Props {
  title: string;
  subtitle: string;
  status: ListSourcingEventsFilterStatus;
}

export const EventsForBuyer = ({ title, subtitle, status }: Props) => {
  const authState = useLoggedInWithOrgContextState();

  const { data, error, isLoading } = useListEventsForBuyerQuery({
    orgId: authState.selectedOrg.id,
    status,
  });

  return (
    <Layout>
      <Grid
        height="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"1fr"}
        templateAreas={`"leftContent content rightContent"`}
      >
        <GridItem area={"content"}>
          <Grid height="full" gridTemplateColumns={"1fr"} gridTemplateRows={"70px 1fr"}>
            <GridItem display="flex" alignItems="center" borderBottom={"1px solid"} borderColor="smBorder" px="2">
              <Heading as="h2" size="lg">
                {title}
              </Heading>
            </GridItem>
            <GridItem px="2">
              <Box mt="15px">
                <Text fontSize="medium" color="smMuted">
                  {subtitle}
                </Text>
              </Box>
              <SourcingEventsTableForBuyer status={status} events={data?.events} error={error} isLoading={isLoading} />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};
