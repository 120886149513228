import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BseDto,
  TextDocumentDto,
  useAwardToBidMutation,
  useListTextDocumentsForSourcingEventQuery,
} from "../../../../autogen/bff-api";
import { TextDocumentModal } from "../../../../common/documents/TextDocumentModal";
import { useApiError } from "../../../../common/errors/useApiError";
import { TextDocumentTable } from "../../../../common/input/TipTap/TextDocumentTable";
import { BidItem } from "./BidItem";
import { Documents } from "./Documents";
import { EmailInvites } from "./EmailInvites";
import { InvitedOrganizations } from "./InvitedOrganizations";

interface Props {
  data: BseDto;
  reloadEvent: () => void;
}

export const BseEventInfo = ({ data, reloadEvent }: Props) => {
  const [isAwarding, setIsAwarding] = useState(false);
  const { t } = useTranslation();
  const toast = useToast();
  const [awardToBidMutation] = useAwardToBidMutation();
  const apiErrorDisplayer = useApiError();

  const { data: textDocRes, isLoading: isLoadingTextDocuments } = useListTextDocumentsForSourcingEventQuery({
    eventId: data.id,
  });
  const [textDocumentToView, setTextDocumentToView] = useState<TextDocumentDto>();

  const awardToBid = async (bidId: string) => {
    setIsAwarding(true);
    const result = await awardToBidMutation({
      eventId: data.id,
      awardToBidRequest: {
        bidIds: [bidId],
      },
    });

    if ("data" in result) {
      toast({
        title: t("Event awarded!"),
        description: t("The event has been successfully awarded!"),
        status: "success",
      });
      setIsAwarding(false);
    } else apiErrorDisplayer.trigger(result.error);
  };

  const intendsToBid = data.organizationInvites.filter(
    ({ status }) =>
      status === "InvitationAccepted" || status === "BidIsSubmitted" || status === "IsWinner" || status === "IsLoser"
  ).length;

  const numberOfInvitedParties = data.emailInvites.length + data.organizationInvites.length;

  const numberOfDeliveredBids = data.organizationInvites.filter(
    (e) => e.status === "BidIsSubmitted" || e.status === "IsWinner" || e.status === "IsLoser"
  ).length;

  return (
    <>
      {textDocumentToView && (
        <TextDocumentModal
          textDocument={textDocumentToView}
          onClose={() => setTextDocumentToView(undefined)}
          isUpdating={false}
          editable={false}
        />
      )}
      <Flex width="full" columnGap="5" pt="5">
        <BidItem
          count={intendsToBid}
          total={numberOfInvitedParties}
          title={t("Intend to bid")}
          description={t("Number of invited parties that intend to bid")}
        />
        <BidItem
          count={numberOfDeliveredBids}
          total={numberOfInvitedParties}
          title={t("Delivered bids")}
          description={t("Number of invited parties that have delivered their bid")}
        />
      </Flex>
      <Box display={"flex"} borderRadius={"md"} pt="10" flexDirection={"column"}>
        <Text fontSize={"lg"} fontWeight="semibold">
          {t("Invited companies & bids")}
        </Text>
        <Box h="15px"></Box>
        <InvitedOrganizations event={data} awardToBid={awardToBid} isAwarding={isAwarding} />
      </Box>
      <Box display={"flex"} borderRadius={"md"} pt="8" flexDirection={"column"}>
        <Text fontWeight="semibold" pb="4">
          {t("Email invites")}
        </Text>
        <EmailInvites event={data} reloadEvent={reloadEvent} />
      </Box>
      <Box display={"flex"} borderRadius={"md"} pt="8" flexDirection={"column"}>
        <Text fontWeight="semibold" pb="4">
          {t("Uploaded documents")}
        </Text>
        <Documents documents={data.documents} />
      </Box>
      <Box display={"flex"} borderRadius={"md"} py="8" flexDirection={"column"}>
        <Text fontWeight="semibold" pb="4">
          {t("Text documents")}
        </Text>
        <TextDocumentTable
          isLoading={isLoadingTextDocuments}
          documents={textDocRes?.documents}
          onClick={setTextDocumentToView}
          isDownloadable={true}
        />
      </Box>
    </>
  );
};
