import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from "@chakra-ui/react";
import { GroupBase, OptionsOrGroups, Select, useChakraSelectProps } from "chakra-react-select";

export interface PersonSelectValue {
  value: string;
  label: string;
}
interface Props {
  value: PersonSelectValue | null;
  onChange: (value: PersonSelectValue | null) => void;
  label?: string;
  placeholder: string | null;
  helperText: string;
  errorMessage: string | null;
  options: OptionsOrGroups<PersonSelectValue, GroupBase<PersonSelectValue>>;
  isDisabled?: boolean;
}

export const PersonSelector = ({
  value,
  onChange,
  options,
  label,
  placeholder,
  helperText,
  errorMessage,
  isDisabled,
}: Props) => {
  return (
    <FormControl id="persons" isInvalid={errorMessage != null}>
      {label && <FormLabel>{label}</FormLabel>}
      <Select
        size="md"
        {...useChakraSelectProps({
          isDisabled: isDisabled,
          isMulti: false,
          value: value,
          onChange: (e) => {
            if (e) {
              onChange({
                value: e.value,
                label: e.label,
              });
            } else {
              onChange(null);
            }
          },
          isClearable: true,
          selectedOptionStyle: "check",
          selectedOptionColor: "blue",
          placeholder: placeholder,
        })}
        options={options}
        chakraStyles={{
          control: (provided) => ({
            ...provided,
            backgroundColor: "smBackground",
            rounded: "md",
          }),
          container: (provided) => ({
            ...provided,
            width: "full",
          }),
          menuList: (provided) => ({
            ...provided,
            rounded: "md",
          }),
        }}
      />
      <FormHelperText>{helperText}</FormHelperText>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
