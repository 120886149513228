import { useMemo } from "react";
import { ReceivedBseBidDto } from "../../../../../autogen/bff-api";
import { ProductGroup } from "../../../../../common/types";
import { findProductGroupBid, getTotalProductPrice } from "./BidEvaluationUtils";

export const useTotalProductPrice = ({
  productId,
  group,
  bid,
}: {
  productId: string;
  group: ProductGroup;
  bid: ReceivedBseBidDto;
}) =>
  useMemo(
    () =>
      getTotalProductPrice({
        group,
        productId,
        bid: findProductGroupBid({ groupId: group.id, bid }),
      }),
    [bid, group, productId]
  );
