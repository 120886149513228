import { createAsyncThunk } from "@reduxjs/toolkit";
import { UploadDto, bffApi } from "../../../../autogen/bff-api";
import { isBse, isBseDraft, isPublishedBse } from "../../../../pages/sourcing-events/typeguards";
import { BasicSourcingEventState } from "../../reducers/basicSourcingEventReducer";

export const loadBseThunk = createAsyncThunk(
  "bse/load",
  async (
    props: {
      eventId: string;
    },
    { dispatch }
  ): Promise<BasicSourcingEventState | null> => {
    const response = dispatch(
      bffApi.endpoints.getBasicSourcingEvent.initiate({
        eventId: props.eventId,
      })
    );
    response.unsubscribe();
    const result = await response;

    if ("data" in result && result.data) {
      const body = result.data;

      if (!isBse(body)) throw Error("Invalid sourcing event");

      let description: string | null;
      let deadline: string | undefined;
      let uploads: UploadDto[];

      if (isBseDraft(body)) {
        description = body.draftFields.description ?? null;
        deadline = body.draftFields.deadline;
        uploads = body.draftFields.uploads;
      } else if (isPublishedBse(body)) {
        description = body.publishedFields.description;
        deadline = body.publishedFields.deadline;
        uploads = body.publishedFields.uploads;
      } else throw Error("Invalid state: Event is not in draft state and does not have published fields.");

      return {
        id: body.id,
        event: body,
        lastChanged: body.modifiedAt,
        title: {
          value: body.title,
          errorMessage: null,
        },
        description: {
          value: description,
          errorMessage: null,
        },
        deadline: {
          value: deadline,
          errorMessage: null,
        },
        timezone: {
          value: body.timezone,
          errorMessage: null,
        },
        referenceId: {
          value: body.referenceId ?? null,
          errorMessage: null,
        },
        emailInvites: {
          value: body.emailInvites,
          errorMessage: null,
        },
        organizationInvites: {
          value: body.organizationInvites,
          errorMessage: null,
        },
        documents: {
          value: body.documents,
          errorMessage: null,
        },
        uploads: {
          value: uploads,
          errorMessage: null,
        },
        projects: {
          values: body.projects,
          errorMessage: null,
        },
      };
    } else {
      return null;
    }
  }
);
