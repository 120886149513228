import { Flex, Text } from "@chakra-ui/react";
import { PhoneNumberDto } from "../../../../autogen/bff-api";
import { displayPhoneNumber } from "../../../../common/formatting/displayPhoneNumber";

export enum BidStatus {
  INVITATION_SENT = "INVITATION_SENT",
  EVENT_SEEN = "EVENT_SEEN",
  INTEND_TO_BID = "INTEND_TO_BID",
  SUBMITTED = "SUBMITTED",
}

interface Props {
  firstName?: string;
  lastName?: string;
  email: string;
  phoneNumber: PhoneNumberDto | null;
  corporateTitle: string | null;
}

export const EmployeeInfo = ({ firstName, lastName, email, phoneNumber, corporateTitle }: Props) => {
  return (
    <Flex flexDirection={"column"} bg="smBackgroundSecondary" borderRadius={"10px"} padding="10px">
      <Flex>
        <Text fontSize={"sm"}>
          {firstName} {lastName}&nbsp;
          {corporateTitle && `(${corporateTitle})`}
        </Text>
      </Flex>
      <Text fontSize={"sm"} color="smMuted">
        {email}
      </Text>
      {phoneNumber && (
        <Text fontSize={"sm"} color="smMuted">
          {displayPhoneNumber(phoneNumber)}
        </Text>
      )}
    </Flex>
  );
};
