import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from "@chakra-ui/react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLoggedInWithOrgContextState } from "../../../../../common/auth/useLoggedInWithOrgContextState";
import { useSub } from "../../../../../common/subscription/useSub";
import { ContractDataFieldSection, CustomFieldType } from "../../../../../common/types";
import { useBaseData } from "../../../../../common/useBaseData";
import { DataFieldSelector } from "./DataFieldSelector";

interface Props<T extends FieldValues> {
  alreadyAddedFields?: string[];
  alreadyAddedCustomFields?: { sectionId: string; fieldId: string }[];
  helperText: string;
  control: Control<T>;
  name: FieldPath<T>;
  label?: string;
  showAddSelectedButton?: boolean;
  autoFocus?: boolean;
  errorMessage: string | undefined;
}

export const DataFieldFormSelector = <T extends FieldValues>({
  alreadyAddedFields,
  alreadyAddedCustomFields,
  control,
  helperText,
  name,
  label,
  showAddSelectedButton,
  autoFocus,
  errorMessage,
}: Props<T>) => {
  const { t } = useTranslation();
  const baseData = useBaseData();
  const sub = useSub();
  const contractSections = useLoggedInWithOrgContextState().selectedOrg.customContractSections as {
    [id: string]: ContractDataFieldSection;
  };

  return (
    <FormControl isInvalid={errorMessage !== undefined}>
      {label && <FormLabel mb="0px">{label}</FormLabel>}
      <Controller
        control={control}
        name={name}
        rules={{
          validate: (value) => {
            if (value.length === 0) return t("Please select data at least 1 data field") ?? "";
            return true;
          },
        }}
        render={({ field: { onChange, value } }) => (
          <DataFieldSelector
            options={[
              ...baseData.contractDataFields
                .filter((f) => !alreadyAddedFields?.includes(f.name))
                .filter((f) => (sub.hasProjectAccess ? true : f.name !== "Projects")),
              ...Object.values(contractSections ?? {})
                .map((section) =>
                  section.fields.map((field) => ({
                    fieldId: field.id,
                    name: field.name,
                    type: field.type,
                    sectionId: section.id,
                    sectionName: section.name,
                    ...(field.type === CustomFieldType.CustomEnum || field.type === CustomFieldType.CustomMultiEnum
                      ? { options: field.options }
                      : {}),
                  }))
                )
                .flat()
                .filter(
                  (option) =>
                    !alreadyAddedCustomFields?.find(
                      (f) => f.fieldId === option.fieldId && f.sectionId === option.sectionId
                    )
                ),
            ]}
            values={value}
            onChange={onChange}
            showAddSelectedButton={showAddSelectedButton}
            autoFocus={autoFocus}
          />
        )}
      />
      <FormHelperText>{helperText}</FormHelperText>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
