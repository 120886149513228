import { Box, Grid, GridItem, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Layout } from "../../../common/layout/Layout";
import { CONTENT_WIDTH } from "../../../config";
import { ContractTemplates } from "./ContractTemplates/ContractTemplates";
import { CustomContractFields } from "./CustomContractFields/CustomContractFields";

export const ContractSettings = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Grid
        height="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"1fr"}
        templateAreas={`"leftContent content rightContent"`}
      >
        <GridItem area={"content"} px="2">
          <Grid h="100%" gridTemplateColumns={"1fr"} gridTemplateRows={"70px 1fr"}>
            <GridItem display="flex" alignItems="center" borderBottom={"1px solid #e1e6ee"}>
              <Heading as="h2" size="lg">
                {t("Contract Settings")}
              </Heading>
            </GridItem>
            <GridItem>
              <Box pt="2" pb="5">
                <Text fontSize="medium" color="smMuted" mb="10px">
                  {t("Configure contracts for your organization")}
                </Text>
              </Box>
              <Tabs colorScheme="teal">
                <TabList>
                  <Tab>{t("Contract Types")}</Tab>
                  <Tab>{t("Custom Contract Fields")}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel px="0">
                    <ContractTemplates />
                  </TabPanel>
                  <TabPanel px="0">
                    <CustomContractFields />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};
