import { Box, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BseDto, useCreateEmailInviteReminderMutation } from "../../../../autogen/bff-api";
import { displayDateWithTime } from "../../../../common/formatting/displayDateWithTime";
import { useConfirmationModal } from "../../../../common/useConfirmationModal";
import { eventIsLive } from "../../eventIsLive";
import { EmaiInviteStatusTag } from "./EmailInviteStatusTag";

interface Props {
  event: BseDto;
  reloadEvent: () => void;
}

export const EmailInvites = ({ event, reloadEvent }: Props) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [sendReminder] = useCreateEmailInviteReminderMutation();
  const confirmationModal = useConfirmationModal();

  const invitedParties = event.emailInvites.map((e) => ({
    id: e.id,
    email: e.email,
    lastReminderSentAt: e.lastReminderSentAt,
    status: e.status,
    openedAt: e.openedAt,
  }));

  return (
    <Box width="full" border="1px solid" borderColor="smBorder" borderRadius={"md"} py="2.5" overflowX={"scroll"}>
      {confirmationModal.modal}
      <Table variant="simple" size={"sm"}>
        <Thead>
          <Tr>
            <Th>{t("Email")} </Th>
            <Th>{t("Status")}</Th>
            <Th></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          <>
            {invitedParties.length === 0 && (
              <Tr>
                <Td colSpan={4} width={"100%"} textAlign="center">
                  {t("No remaining email invites")}.
                </Td>
              </Tr>
            )}
            {invitedParties.map((e) => {
              return (
                <Tr key={e.id}>
                  <Td>
                    <Text fontSize={"sm"}>{e.email}</Text>
                  </Td>
                  <Td>
                    <Flex>
                      <EmaiInviteStatusTag openedAt={e.openedAt} status={e.status} size="sm" />
                    </Flex>
                  </Td>
                  <Td>
                    {event.canEdit && e.status !== "SendingFailed" && eventIsLive(event) && (
                      <Button
                        variant={"solid"}
                        size="xs"
                        colorScheme={"orange"}
                        onClick={async () => {
                          confirmationModal.show({
                            title: t("Send email reminder?"),
                            description: t("Are you sure you want to send an email reminder to") + ` ${e.email}`,
                            confirmButtonText: "Send reminder",
                            onConfirmed: async () => {
                              const result = await sendReminder({
                                eventId: event.id,
                                inviteId: e.id,
                              });
                              if ("data" in result) {
                                toast({
                                  title: t("Email reminder sent!"),
                                  description: t("The email reminder was successfully sent"),
                                  status: "success",
                                });
                                reloadEvent();
                              } else {
                                toast({
                                  title: t("Could not send reminder"),
                                  description: t("Something went wrong, please try again"),
                                  status: "error",
                                });
                              }
                            },
                          });
                        }}
                      >
                        {t("Send reminder")}
                      </Button>
                    )}
                  </Td>
                  <Td>
                    {e.lastReminderSentAt && (
                      <Text fontSize={"sm"} fontStyle="italic">
                        {t("Last reminder sent")} {displayDateWithTime(e.lastReminderSentAt, t("at"))}
                      </Text>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </>
        </Tbody>
      </Table>
    </Box>
  );
};
