import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BseDto } from "../../../../autogen/bff-api";
import { TipTap } from "../../../../common/input/TipTap/TipTap";

interface Props {
  event: BseDto;
}

export const MessagesToWinnersAndLosers = ({ event }: Props) => {
  const { t } = useTranslation();
  return (
    <Flex width="full" flexDirection={"column"} mt="5">
      <Flex flexDirection="column">
        <Text fontWeight={"semibold"} color="smPrimary">
          {t("Message to winners")}
        </Text>
        {event.awardedFields?.messageToWinners ? (
          <TipTap content={event.awardedFields.messageToWinners} editable={false} />
        ) : (
          <Text fontSize={"sm"} fontStyle="italic">
            {t("No message added")}
          </Text>
        )}
      </Flex>
      <Flex flexDirection={"column"} mt="15px">
        <Text fontWeight={"semibold"} color="smTertiary">
          {t("Message to losers")}
        </Text>
        {event.awardedFields?.messageToLosers ? (
          <TipTap content={event.awardedFields.messageToLosers} editable={false} />
        ) : (
          <Text fontSize={"sm"} fontStyle="italic">
            {t("No message added")}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
