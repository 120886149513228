import { Flex, Text } from "@chakra-ui/react";

interface Props {
  title: string;
  value: string;
}

export const Stat = ({ title, value }: Props) => {
  return (
    <Flex
      flexDirection={"column"}
      alignItems="center"
      justifyContent={"center"}
      width={"full"}
      rounded="lg"
      shadow="md"
      bg="smBackground"
      p="4"
      mt="2.5"
    >
      <Text fontSize="xl" fontWeight={"semibold"}>
        {value}
      </Text>
      <Text fontSize="sm" fontWeight={"light"} color="gray.500">
        {title}
      </Text>
    </Flex>
  );
};
