import { Flex, Spacer, Text } from '@chakra-ui/react'
import React from 'react'
import { CertificateStatus, CertificateStatusTag } from './CertificateStatusTag'

interface Props {
    name: string
    status: CertificateStatus
}

export const Certificate: React.FC<Props> = ({ name, status }) => {
    return (
        <Flex w="100%" mt="15px">
            <Flex>
                <Text>{name}</Text>
            </Flex>
            <Spacer />
            <Flex>
                <CertificateStatusTag status={status} />
            </Flex>
        </Flex>
    )
}
