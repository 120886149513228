import { Grid, GridItem } from '@chakra-ui/react'
import React from 'react'
import { CONTENT_WIDTH } from '../../config'

interface Props {
    children: React.ReactNode
}

export const Banner: React.FC<Props> = ({ children }) => {
    return (
        <Grid
            gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
            templateAreas={`
            "leftContent content rightContent"
            `}
        >
            <GridItem area={'content'}>{children}</GridItem>
        </Grid>
    )
}
