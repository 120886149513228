import { Box, Button, Heading, Icon } from "@chakra-ui/react";
import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { ContractDataFieldDto, ContractDataFieldNameDto } from "../../../../autogen/bff-api";
import { useAppSelector } from "../../../../common/redux/hooks";
import {
  COMMERCIAL_COLOR,
  DATES_COLOR,
  FINANCIAL_COLOR,
  OPTIONS_COLOR,
  OTHER_COLOR,
  TERMS_AND_CONDITIONS_COLOR,
} from "../../../../config";
import { requireDataFields } from "../../typeguards";
import { useContractState } from "../useContractState";
import { AddDataFieldModal } from "./AddDataFieldModal";
import { Counterparty } from "./mandatory/Counterparty";
import { CurrencyAndTimezone } from "./mandatory/CurrencyAndTimezone";
import { InternalOwner } from "./mandatory/InternalOwner";
import { Template } from "./mandatory/Template";
import { Title } from "./mandatory/Title";
import { CustomField } from "./optional/CustomField";
import { DataField } from "./optional/DataField";
import { Description } from "./optional/Description";

interface Props {
  nextStep: () => void;
}

export const DatesContext = createContext<{
  expirationDate: string | null;
  setExpirationDate: (date: string | null) => void;
}>({ expirationDate: null, setExpirationDate: () => null });

export const Details = ({ nextStep }: Props) => {
  const { t } = useTranslation();
  const [showAddDataFieldModal, setShowAddDataFieldModal] = useState(false);
  const contractState = useContractState();
  const customFields = useAppSelector((state) => state.contract.customFields);
  const [expirationDate, setExpirationDate] = useState(() => contractState.contract.dataFields.endDate?.date ?? null);

  const dataFields = requireDataFields(contractState.contract);

  const addedCommercialFields = dataFields.addedDataFields.filter((e) => e.type === "Commercial");
  const addedDateFields = dataFields.addedDataFields.filter((e) => e.type === "Date");
  const addedFinancialFields = dataFields.addedDataFields.filter((e) => e.type === "Financial");
  const addedOtherFields = dataFields.addedDataFields
    .filter((e) => e.type === "Other")
    .filter((f) => f.name !== "Options");
  const addedOptions = dataFields.addedDataFields.find((e) => e.name === "Options");
  const addedTermsAndConditionsFields = dataFields.addedDataFields.filter((e) => e.type === "TermsAndConditions");

  return (
    <Box paddingBottom={"30px"}>
      {showAddDataFieldModal && (
        <AddDataFieldModal
          alreadyAddedFields={dataFields.addedDataFields.map((e) => e.name)}
          alreadyAddedCustomFields={Object.values(customFields ?? {})
            .map((section) => section.fields.map((f) => ({ sectionId: section.id, fieldId: f.id })))
            .flat()}
          onClose={() => setShowAddDataFieldModal(false)}
        />
      )}
      <Box mt="33px">
        <Title />
      </Box>
      <Box mt="33px">
        <Description />
      </Box>
      <Box mt="33px">
        <Counterparty />
      </Box>
      <Box mt="33px">
        <InternalOwner />
      </Box>
      <Box mt="33px">
        <Template />
      </Box>
      <Box mt="33px">
        <CurrencyAndTimezone />
      </Box>
      <DatesContext.Provider value={{ expirationDate, setExpirationDate }}>
        {addedDateFields.length > 0 &&
          getDataFields({
            title: t("Dates"),
            fields: addedDateFields,
            bgColor: DATES_COLOR,
          })}
        {addedFinancialFields.length > 0 &&
          getDataFields({
            title: t("Financial"),
            fields: addedFinancialFields,
            bgColor: FINANCIAL_COLOR,
          })}
        {addedCommercialFields.length > 0 &&
          getDataFields({
            title: t("Commercial"),
            fields: addedCommercialFields,
            bgColor: COMMERCIAL_COLOR,
          })}
        {addedTermsAndConditionsFields.length > 0 &&
          getDataFields({
            title: t("Terms & Conditions"),
            fields: addedTermsAndConditionsFields,
            bgColor: TERMS_AND_CONDITIONS_COLOR,
          })}
        {addedOtherFields.length > 0 &&
          getDataFields({
            title: t("Other"),
            fields: addedOtherFields,
            bgColor: OTHER_COLOR,
          })}
        {addedOptions &&
          getDataFields({
            title: t("Optional contract extensions"),
            fields: [addedOptions],
            bgColor: OPTIONS_COLOR,
          })}
      </DatesContext.Provider>
      {Object.values(customFields ?? {}).map((section) => (
        <Box key={section.id} border={"1px solid"} borderColor="smPrimarySofter" mt="7" rounded="lg" p="5">
          <Heading as="h2" size="md" noOfLines={1} pb="5">
            {section.name}
          </Heading>
          {section.fields.map((field) => (
            <CustomField key={field.id} sectionId={section.id} field={field} />
          ))}
        </Box>
      ))}
      <Box mt="30px">
        <Button
          variant={"link"}
          colorScheme="blue"
          leftIcon={<Icon as={FaPlus} w="15px" h="15px" />}
          onClick={() => setShowAddDataFieldModal(true)}
        >
          {t("Add data field")}
        </Button>
      </Box>
      <Button variant={"solid"} width="full" mt="33px" colorScheme={"teal"} onClick={nextStep}>
        {t("Next")}
      </Button>
    </Box>
  );
};

const getDataFields = (props: { title: string; fields: ContractDataFieldDto[]; bgColor: string }) => {
  const sortOrder: ContractDataFieldNameDto[] = [
    "AwardDate",
    "StartDate",
    "WarrantyExpirationDate",
    "RenewalDate",
    "EndDate",
    "NoticePeriod",
  ];
  return props.fields.length > 0 ? (
    <Box mt="33px" bg={props.bgColor} pt="5" px="5" pb="6" rounded="md">
      <Heading as="h2" size="md" noOfLines={1}>
        {props.title}
      </Heading>
      <Box pt="5">
        {props.fields
          .sort((a, b) => sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name))
          .map((e) => (
            <Box key={e.name} pt="5">
              <DataField name={e.name} />
            </Box>
          ))}
      </Box>
    </Box>
  ) : null;
};
