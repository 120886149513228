import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  BseDto,
  BseQuestionDto,
  BseQuestionStatus,
  EmailInviteDto,
  OrganizationInviteDto,
  ProductGroupBidDto,
  ProjectDto,
  ReceivedBseBidDto,
  UploadDto,
  ViewDocumentAsOwnerDto,
  WinningProductBidsDto,
  WinningProductGroupBidsDto,
} from "../../../autogen/bff-api";
import { ProductGroup } from "../../types";
import { editBseThunk } from "../thunks/basic-sourcing-event/edit-bse-thunk";
import { loadBseThunk } from "../thunks/basic-sourcing-event/load-bse-thunk";

export type BasicSourcingEventState = {
  id: string;
  event: BseDto;
  lastChanged: string;
  title: {
    value: string;
    errorMessage: string | null;
  };
  description: {
    value: string | null;
    errorMessage: string | null;
  };
  deadline: {
    value?: string;
    errorMessage: string | null;
  };
  timezone: {
    value: string;
    errorMessage: string | null;
  };
  referenceId: {
    value: string | null;
    errorMessage: string | null;
  };
  emailInvites: {
    value: EmailInviteDto[];
    errorMessage: string | null;
  };
  organizationInvites: {
    value: OrganizationInviteDto[];
    errorMessage: string | null;
  };
  documents: {
    value: ViewDocumentAsOwnerDto[];
    errorMessage: string | null;
  };
  uploads: {
    value: UploadDto[];
    errorMessage: string | null;
  };
  projects: {
    values?: ProjectDto[];
    errorMessage: string | null;
  };
};

interface State {
  state: BasicSourcingEventState | null;
}

const initialState: State = {
  state: null,
};

export const slice = createSlice<
  State,
  {
    setSourcingEvent: (state: State, action: PayloadAction<BseDto | null>) => void;
    selectBid: (
      state: State,
      action: PayloadAction<{
        bid: ReceivedBseBidDto;
        productId: string;
        group: ProductGroup;
      }>
    ) => void;
    selectAllBids: (state: State, action: PayloadAction<ReceivedBseBidDto>) => void;
    selectAllBidsInGroup: (
      state: State,
      action: PayloadAction<{ bid: ReceivedBseBidDto; groupBid: ProductGroupBidDto }>
    ) => void;
    removeBid: (
      state: State,
      action: PayloadAction<{
        bid: ReceivedBseBidDto;
        productId: string;
        group: ProductGroup;
      }>
    ) => void;
    setSupplierQuestionStatus: (
      state: State,
      action: PayloadAction<{ question: BseQuestionDto; status: BseQuestionStatus }>
    ) => void;
  },
  "basicSourcingEvent"
>({
  name: "basicSourcingEvent",
  initialState,
  reducers: {
    setSourcingEvent: (state, action) => {
      state.state = action.payload ? mapDtoToSourcingEventState(action.payload) : null;
    },
    selectBid: (state, action) => {
      const { bid, productId, group } = action.payload;
      const selectedGroupBids = getSelectedGroupBids(state, group.id);
      const selectedProductBids = getSelectedProductBids(selectedGroupBids, productId);
      const bidIdsSet = new Set(selectedProductBids.bids);
      bidIdsSet.add(bid.id);
      selectedProductBids.bids = Array.from(bidIdsSet);
    },
    selectAllBids: (state, action) => {
      const bidId = action.payload.id;
      action.payload.products?.forEach((groupBid) => {
        const selectedGroupBids = getSelectedGroupBids(state, groupBid.productGroupId);
        groupBid.productBids.forEach((productBid) => {
          const selectedProductBids = getSelectedProductBids(selectedGroupBids, productBid.productId);
          const bidIdsSet = new Set(selectedProductBids.bids);
          bidIdsSet.add(bidId);
          selectedProductBids.bids = Array.from(bidIdsSet);
        });
      });
    },
    selectAllBidsInGroup: (state, action) => {
      const bidId = action.payload.bid.id;
      const groupId = action.payload.groupBid.productGroupId;
      const selectedGroupBids = getSelectedGroupBids(state, groupId);
      action.payload.groupBid.productBids.forEach((productBid) => {
        const selectedProductBids = getSelectedProductBids(selectedGroupBids, productBid.productId);
        const bidIdsSet = new Set(selectedProductBids.bids);
        bidIdsSet.add(bidId);
        selectedProductBids.bids = Array.from(bidIdsSet);
      });
    },
    removeBid: (state, action) => {
      const { bid, productId, group } = action.payload;
      const selectedGroupBids = getSelectedGroupBids(state, group.id);
      const selectedProductBids = getSelectedProductBids(selectedGroupBids, productId);
      const bidIdsSet = new Set(selectedProductBids.bids);
      bidIdsSet.delete(bid.id);
      selectedProductBids.bids = Array.from(bidIdsSet);
    },
    setSupplierQuestionStatus: (state, action) => {
      const question = state.state?.event.questions?.find((q) => q.id === action.payload.question.id);
      if (question) question.status = action.payload.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadBseThunk.fulfilled, (state, action) => {
      state.state = action.payload;
    });
    builder.addCase(editBseThunk.fulfilled, (state, action) => {
      state.state = action.payload;
    });
  },
});

export const {
  selectBid,
  selectAllBids,
  selectAllBidsInGroup,
  removeBid,
  setSourcingEvent,
  setSupplierQuestionStatus,
} = slice.actions;
export default slice.reducer;

const getSelectedGroupBids = (state: State, groupId: string): WinningProductGroupBidsDto => {
  const bids = state.state?.event.publishedFields?.selectedProductGroupBids?.find((b) => b.productGroupId === groupId);
  if (!bids) {
    const emptyGroupBids = { productGroupId: groupId, bids: [] };
    state.state?.event.publishedFields?.selectedProductGroupBids?.push(emptyGroupBids);
    return emptyGroupBids;
  }
  return bids;
};

const getSelectedProductBids = (groupBids: WinningProductGroupBidsDto, productId: string): WinningProductBidsDto => {
  const selectedProductBids = groupBids.bids.find((b) => b.productId === productId);
  if (!selectedProductBids) {
    const emptyProductBids = { productId, bids: [] };
    groupBids.bids.push(emptyProductBids);
    return emptyProductBids;
  }
  return selectedProductBids;
};

const mapDtoToSourcingEventState = (dto: BseDto): BasicSourcingEventState => ({
  id: dto.id,
  event: dto,
  lastChanged: dto.modifiedAt,
  title: {
    value: dto.title,
    errorMessage: null,
  },
  description: {
    value: mapDescription(dto),
    errorMessage: null,
  },
  deadline: {
    value: mapDeadline(dto),
    errorMessage: null,
  },
  timezone: {
    value: dto.timezone,
    errorMessage: null,
  },
  referenceId: {
    value: dto.referenceId ?? null,
    errorMessage: null,
  },
  emailInvites: {
    value: dto.emailInvites,
    errorMessage: null,
  },
  organizationInvites: {
    value: dto.organizationInvites,
    errorMessage: null,
  },
  documents: {
    value: dto.documents,
    errorMessage: null,
  },
  uploads: {
    value: mapUploads(dto),
    errorMessage: null,
  },
  projects: {
    values: dto.projects,
    errorMessage: null,
  },
});

const mapDescription = (dto: BseDto) => dto.publishedFields?.description ?? dto.publishedFields?.description ?? null;
const mapDeadline = (dto: BseDto) => dto.publishedFields?.deadline ?? dto.draftFields?.deadline;
const mapUploads = (dto: BseDto) => dto.publishedFields?.uploads ?? dto.draftFields?.uploads ?? [];
