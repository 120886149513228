import { useAppSelector } from '../redux/hooks'

export const useLoggedInState = () => {
    const authState = useAppSelector((state) => state.authState.state)

    if (!authState || authState.type !== 'LoggedIn') {
        throw Error(
            '"useLoggedInWithOrgContextState" can only be used in LoggedIn state'
        )
    }

    return authState
}
