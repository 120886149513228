import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EmailInviteLanguage, useCreateSupplierEmailInviteMutation } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { getPreferredLanguage } from "../../../common/local-storage/language";
import { AcceptedInvitesTable } from "./AcceptedInvitesTable";
import { InvitedSupplierRow } from "./InvitedSupplierRow";
import { SentInvitesTable } from "./SentInvitesTable";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export interface Party {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  language: EmailInviteLanguage;
}

export interface SupplierInvitesForm {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  language: EmailInviteLanguage;
}

export const Invites = ({ isOpen, onClose }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const [createInvites, { isLoading }] = useCreateSupplierEmailInviteMutation();
  const { t } = useTranslation();
  const toast = useToast();
  const displayer = useApiError();
  const methods = useForm<SupplierInvitesForm>({
    reValidateMode: "onSubmit",
    defaultValues: {
      language: getPreferredLanguage(),
    },
  });

  const handleSendInvites = async (values: SupplierInvitesForm) => {
    const result = await createInvites({
      orgId: authState.selectedOrg.id,
      createEmailInviteRequest: {
        email: values.email,
        supplierInvite: {
          personFirstName: values.firstName,
          personLastName: values.lastName,
          organizationName: values.companyName,
        },
        language: values.language,
      },
    });

    if ("data" in result) {
      toast({
        title: t("Invite sent!"),
        description: t("The invite was successfully sent"),
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } else {
      displayer.trigger(result.error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSendInvites)}>
            <ModalHeader>{t("Invites")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb="20px" fontSize={"md"}>
                {t("Want to work with a company that's not registered in SourceMagnet?")} <br />{" "}
                {t("Send them an invite and get them onboarded")}.
              </Text>
              <InvitedSupplierRow />
              <Flex mt="15px" flexDirection={"row"} justifyContent="flex-end">
                <Button type="submit" colorScheme={"blue"} variant="solid" isLoading={isLoading}>
                  {t("Send")}
                </Button>
              </Flex>
            </ModalBody>
            <ModalHeader>{t("Sent invites")}</ModalHeader>
            <ModalBody>
              <SentInvitesTable />
            </ModalBody>
            <ModalHeader>{t("Accepted invites")}</ModalHeader>
            <ModalBody>
              <AcceptedInvitesTable />
            </ModalBody>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};
