import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useUpdateOrganizationMutation } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../common/errors/useApiError";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { OrgDto, updateSelectedOrg } from "../../../../common/redux/reducers/authStateReducer";

interface FormValues {
  homePageUrl: string;
}

interface Props {
  currentValue: string;
}

export const ChangeHomePageUrl = ({ currentValue }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const [editOrg, { isLoading }] = useUpdateOrganizationMutation();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { t } = useTranslation();
  const displayer = useApiError();

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      homePageUrl: currentValue,
    },
  });

  const newValue = watch("homePageUrl");

  const onSubmit = async (values: FormValues) => {
    setIsRefreshing(true);

    const response = await editOrg({
      orgId: authState.selectedOrg.id,
      editOrganizationRequest: {
        homePageUrl: {
          url: values.homePageUrl,
        },
      },
    });
    if ("data" in response) {
      dispatch(updateSelectedOrg(response.data as OrgDto));
    } else {
      displayer.trigger(response.error);
    }
    setIsRefreshing(false);

    toast({
      title: t("Home page URL has been updated!"),
      status: "success",
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl mt="15px" isInvalid={errors.homePageUrl !== undefined}>
          <FormLabel htmlFor={"homePageUrl"}>{t("Home page url")}</FormLabel>
          <Input
            id={"homePageUrl"}
            type="text"
            {...register("homePageUrl", {
              minLength: {
                value: 2,
                message: t("Must be at least 2 characters long."),
              },
            })}
          />
          <FormErrorMessage>{errors.homePageUrl && errors.homePageUrl.message}</FormErrorMessage>
          <FormHelperText>{t("Please provide a valid URL")}</FormHelperText>
        </FormControl>
        {currentValue.trim() !== newValue.trim() && (
          <Button
            mt="10px"
            leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
            variant={"solid"}
            colorScheme="teal"
            size={"sm"}
            isLoading={isLoading || isRefreshing}
            type="submit"
          >
            {t("Save change")}
          </Button>
        )}
      </form>
    </>
  );
};
