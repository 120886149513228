import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EmailInviteContextDto } from "../../../../autogen/bff-api";
import { useLoggedOutState } from "../../../../common/auth/useLoggedOutState";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { signupAsPersonThunk } from "../../../../common/redux/thunks/auth/signup-as-person-thunk";

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  tos: boolean;
  pp: boolean;
}

interface Props {
  invitationContext: EmailInviteContextDto | null;
}

export const SignupAsPersonForm: React.FC<Props> = ({ invitationContext }) => {
  const toast = useToast();
  const methods = useForm<FormValues>();
  const dispatch = useAppDispatch();
  const authState = useLoggedOutState();
  const errors = methods.formState.errors;
  const register = methods.register;
  const { t } = useTranslation();

  const signup = async (values: FormValues) => {
    dispatch(
      signupAsPersonThunk({
        loginCredentials: {
          email: values.email,
          password: values.password,
        },
        personCredentials: {
          firstName: values.firstName,
          lastName: values.lastName,
        },
        invitationContext: invitationContext,
      })
    );
  };

  useEffect(() => {
    if (authState.signupError) {
      switch (authState.signupError) {
        case "EmailAlreadyTaken":
          methods.setError("email", {
            message: t("An account already belongs to this email!") ?? "",
          });
          break;
        case "WeakPassword":
          methods.setError("password", {
            message: t("This password has been found in password breaches and you need to use another password") ?? "",
          });
          break;
        case "PasswordBreached":
          methods.setError("password", {
            message: t("This password has been found in password breaches and cannot be used") ?? "",
          });
          break;
        case "SimilarToIdentifier":
          methods.setError("password", {
            message: t("This password is too similar to your email") ?? "",
          });
          break;
        case "UnknownError":
          toast({
            title: t("Uknown error occurred, please try again!"),
            status: "error",
          });
          break;
      }
    } else {
      methods.clearErrors();
    }
  }, [authState.signupError, methods, t, toast]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(signup)}>
        <FormControl isInvalid={errors.firstName !== undefined} mt="5px">
          <FormLabel htmlFor={"firstName"}>{t("First name")}</FormLabel>
          <Input
            id={"firstName"}
            type="text"
            {...register("firstName", {
              required: t("Please provide your first name") ?? "",
              minLength: {
                value: 2,
                message: t("Must be at least 2 characters long"),
              },
            })}
          />
          <FormErrorMessage>{errors.firstName && errors.firstName.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.lastName !== undefined} mt="5px">
          <FormLabel htmlFor={"lastName"}>{t("Last name")}</FormLabel>
          <Input
            id={"lastName"}
            type="text"
            {...register("lastName", {
              required: t("Please provide your last name") ?? "",
              minLength: {
                value: 2,
                message: t("Must be at least 2 characters long"),
              },
            })}
          />
          <FormErrorMessage>{errors.lastName && errors.lastName.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.email !== undefined} mt="5px">
          <FormLabel htmlFor={"email"}>{t("Email")}</FormLabel>
          <Input
            id={"email"}
            type="email"
            {...register("email", {
              required: t("Please provide your email") ?? "",
              minLength: {
                value: 2,
                message: t("Must be at least 2 characters long"),
              },
            })}
          />
          <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.password !== undefined} mt="5px">
          <FormLabel htmlFor={"password"}>{t("Password")}</FormLabel>
          <Input
            id={"password"}
            type="password"
            {...register("password", {
              required: t("Please provide your desired passord") ?? "",
              minLength: {
                value: 8,
                message: t("Your password must be at least 8 characters long"),
              },
            })}
          />
          <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.pp !== undefined} mt="10px">
          <Controller
            name="pp"
            control={methods.control}
            rules={{
              required: {
                value: true,
                message: t("Please accept our Privacy Policy before logging in"),
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <Checkbox isChecked={value} onChange={onChange}>
                  {t("I accept the")}{" "}
                  <Link href="https://sourcemagnet.com/privacy-policy" color="blue.500" target={"_blank"}>
                    <u>{t("Privacy Policy")}</u>
                  </Link>
                </Checkbox>
              );
            }}
          />
          <FormErrorMessage>{errors.pp && errors.pp.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.tos !== undefined} mt="10px">
          <Controller
            name="tos"
            control={methods.control}
            rules={{
              required: {
                value: true,
                message: t("Please accept our Terms of Service before logging in"),
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <Checkbox isChecked={value} onChange={onChange}>
                  {t("I accept the")}{" "}
                  <Link href="https://sourcemagnet.com/avtalevilkar" color="blue.500" target={"_blank"}>
                    <u>{t("Terms of Service")}</u>
                  </Link>
                </Checkbox>
              );
            }}
          />
          <FormErrorMessage>{errors.tos && errors.tos.message}</FormErrorMessage>
        </FormControl>
        <Flex mt="10px" justifyContent={"end"}>
          <Button type="submit" colorScheme="teal" isLoading={authState.isSigningUp}>
            {t("Proceed")}
          </Button>
        </Flex>
      </form>
    </FormProvider>
  );
};
