import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { RecoveryFlow } from "@ory/client";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { authService } from "../../../config";
interface Props {
  email: string;
  recoveryFlow: RecoveryFlow;
  onComplete: () => void;
}

interface FormValues {
  code: string;
  newPassword: string;
}

export const CompleteForgottenPasswordFlowForm = ({ email, recoveryFlow, onComplete }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<FormValues>();
  const [isActiveSession, setIsActiveSession] = useState(false);

  const completeFlow = async (values: FormValues) => {
    setIsLoading(true);
    clearErrors();

    if (isActiveSession) {
      await changePassword(values.newPassword);
    } else {
      const success = await authService.getPrivilegedRecoverySession({
        code: values.code,
        recoveryFlow: recoveryFlow,
      });

      if (success) {
        setIsActiveSession(true);
        await changePassword(values.newPassword);
      } else {
        setError("code", {
          message: t("Invalid code, please make sure the code matches the one you received in your email") ?? "",
        });
      }
    }
    setIsLoading(false);
  };

  const changePassword = async (newPassword: string) => {
    const result = await authService.changePassword({
      email: email,
      newPassword: newPassword,
    });
    switch (result) {
      case "Failure":
        setError("code", {
          message: t("Unkown error") ?? "",
        });
        setError("newPassword", {
          message: t("Unkown error") ?? "",
        });
        break;
      case "Success":
        toast({
          title: t("Password reset!"),
          description: t("You can now log in with your new password"),
          status: "success",
        });
        await authService.logout();
        onComplete();
        break;
      case "WeakPassword":
        setError("newPassword", {
          message: t("This password has been found in password breaches and cannot be used") ?? "",
        });
        break;
      case "PasswordBreached":
        setError("newPassword", {
          message: t("This password has been found in password breaches and cannot be used") ?? "",
        });
        break;
      case "SimilarToIdentifier":
        setError("newPassword", {
          message: t("This password is too similar to your email") ?? "",
        });
        break;
    }
  };

  return (
    <form onSubmit={handleSubmit(completeFlow)}>
      <FormControl isInvalid={errors.code !== undefined} mt="10px">
        <FormLabel htmlFor={"code"}>{t("Code from email")}</FormLabel>
        <Input
          id={"code"}
          type="text"
          {...register("code", {
            required: t("Please provide the email address of your account") ?? "",
            minLength: {
              value: 6,
              message: t("Must be 6 characters long"),
            },
            maxLength: {
              value: 6,
              message: t("Must be 6 characters long"),
            },
          })}
        />
        <FormHelperText>
          {t("Please provide the 6-digit code we sent to")} <b>{email}</b>
        </FormHelperText>
        <FormErrorMessage>{errors.code && errors.code.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={errors.newPassword !== undefined} mt="10px">
        <FormLabel htmlFor={"newPassword"}>{t("New password")}</FormLabel>
        <Input
          id={"newPassword"}
          type="password"
          {...register("newPassword", {
            required: t("Please provide a new password with at least 8 characters") ?? "",
            minLength: {
              value: 8,
              message: t("Must be at least 8 characters long"),
            },
          })}
        />
        <FormHelperText>{t("Please provide a new password for your account")}</FormHelperText>
        <FormErrorMessage>{errors.newPassword && errors.newPassword.message}</FormErrorMessage>
      </FormControl>
      <Flex mt="20px" justifyContent={"end"}>
        <Button type="submit" colorScheme="teal" isLoading={isLoading}>
          {t("Set new password")}
        </Button>
      </Flex>
    </form>
  );
};
