import { Box, Flex, Grid, GridItem, Image } from "@chakra-ui/react";
import { assets } from "../../../assets/assets";
import { useLoggedInState } from "../../../common/auth/useLoggedInState";
import { AcceptOrgUserInvite } from "../../../common/email-invites/AcceptOrgUserInvite";
import { NoOrgsFoundExplanation } from "./NoOrgsFoundExplanation";

export const NoOrganizationsFound = () => {
  const authState = useLoggedInState();
  if (authState.invitationContext?.emailInviteType === "OrgUser") {
    return (
      <AcceptOrgUserInvite
        inviteId={authState.invitationContext.emailInviteId}
        secret={authState.invitationContext.secret}
      />
    );
  }
  return (
    <Box
      width="full"
      height="100vh"
      bgGradient="linear(to-br, smTealShadow, smBackground, smBackground, smBackground, smBlueShadow)"
    >
      <Grid
        gridTemplateColumns={"1fr 450px 1fr"}
        height="100%"
        gridTemplateRows={"1fr auto 2fr"}
        templateAreas={`
                "left top right"
                "left login right"
                "left bottom right"
                `}
      >
        <GridItem area={"left"}></GridItem>
        <GridItem area={"top"}></GridItem>
        <GridItem area={"bottom"}></GridItem>
        <GridItem area={"right"}></GridItem>
        <GridItem area={"login"} backgroundColor="smBackground" rounded="lg" padding={10}>
          <Flex w="100%" h={"100%"} flexDirection="column">
            <Flex>
              <Image src={assets.logo} alt="Logo" h="30px" mt="10px" />
            </Flex>
            <br /> <br />
            <NoOrgsFoundExplanation />
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};
