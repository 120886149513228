import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { ViewDocumentAsOwnerDto } from "../../../../autogen/bff-api";
import { DownloadDocumentIconButton } from "../../../../common/documents/DownloadDocumentIconButton/DownloadDocumentIconButton";

interface Props {
  documents: ViewDocumentAsOwnerDto[];
}

export const Documents = ({ documents }: Props) => {
  return (
    <Box width="full" border="1px solid" borderColor="smBorder" borderRadius={"md"} py="2.5">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>{t("Filename")}</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {documents.length === 0 && (
            <Tr>
              <Td colSpan={4} width={"100%"} textAlign="center">
                {t("No documents")}.
              </Td>
            </Tr>
          )}
          {documents.map((e) => (
            <Tr key={e.id}>
              <Td>
                <Text fontSize={"sm"}>{e.name}</Text>
              </Td>
              <Td display={"flex"} justifyContent="end">
                <DownloadDocumentIconButton documentId={e.id} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
