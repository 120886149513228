import { useAppSelector } from '../redux/hooks'

export const useAuthState = () => {
    const authState = useAppSelector((state) => state.authState.state)

    if (!authState) {
        throw Error('AuthState not available')
    }

    return authState
}
