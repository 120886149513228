import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Icon, Text } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaPaperPlane } from "react-icons/fa";
import isEmail from "validator/lib/isEmail";
import {
  DocumentSignerMethod,
  PersonDto,
  SignerDto,
  TextDocumentDto,
  useGetContractQuery,
  ViewDocumentAsOwnerDto,
} from "../../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../../common/auth/useLoggedInWithOrgContextState";
import { DateTimeSelector } from "../../../../../common/input/DateTimeSelectors/DateTimeSelector";
import { MultiSelector } from "../../../../../common/input/Selector/MultiSelector";
import { SelectorValue } from "../../../../../common/input/Selector/SelectorValue";
import { SingleSelector } from "../../../../../common/input/Selector/SingleSelector";
import { useSub } from "../../../../../common/subscription/useSub";
import { Explanation } from "../../../../../common/support/Explanation/Explanation";
import { useSkeletons } from "../../../../../common/useSkeletons";
import { InvitedSigners } from "./InvitedSigners";

interface Props {
  contractId: string;
  documentId: string;
  documents: ViewDocumentAsOwnerDto[];
  textDocuments?: TextDocumentDto[];
  sendForSigning: (values: {
    mainDocumentId: string;
    attachments: SelectorValue[];
    signers?: SignerDto[];
    deadline?: string;
  }) => Promise<void>;
}

interface FormValues {
  mainDocumentId: string;
  attachments: SelectorValue[];
  internalSigners: SelectorValue[];
  counterpartySigners: SelectorValue[];
  manualSigners?: SignerDto[];
  deadline?: string;
  signatureMethod: DocumentSignerMethod;
}

export const CreateSignedDocument = ({ contractId, documentId, documents, textDocuments, sendForSigning }: Props) => {
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();
  const skeletons = useSkeletons();
  const sub = useSub();
  const { data, error, isLoading: isLoadingContract } = useGetContractQuery({ contractId });

  const signatureMethods: { [key in DocumentSignerMethod]: string } = {
    Handwritten: t("Handwritten"),
    NoBankId: t("BankID"),
  };

  const registeredCounterpartyPersons = useMemo(
    () => data?.counterparty?.organization?.persons,
    [data?.counterparty?.organization?.persons]
  );

  const selfRegisteredCounterpartyPerson = useMemo(
    () => data?.dataFields?.counterpartyContactPerson,
    [data?.dataFields?.counterpartyContactPerson]
  );

  const counterpartyPersons: PersonDto[] = useMemo(() => {
    const selfRegisteredContact: PersonDto | undefined = selfRegisteredCounterpartyPerson?.email
      ? {
          ...selfRegisteredCounterpartyPerson,
          id: selfRegisteredCounterpartyPerson.email,
          firstName: selfRegisteredCounterpartyPerson.fullName,
          email: selfRegisteredCounterpartyPerson.email,
        }
      : undefined;
    return [...(registeredCounterpartyPersons ?? []), ...(selfRegisteredContact ? [selfRegisteredContact] : [])];
  }, [registeredCounterpartyPersons, selfRegisteredCounterpartyPerson]);

  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<FormValues>({
    defaultValues: {
      mainDocumentId: documentId,
      manualSigners: [],
      signatureMethod: sub.hasBankIdAccess ? "NoBankId" : "Handwritten",
    },
  });

  const mapSigners = useCallback(
    ({
      internalSigners,
      counterpartySigners,
      manualSigners,
      signatureMethod,
    }: {
      internalSigners: SelectorValue[];
      counterpartySigners: SelectorValue[];
      manualSigners?: SignerDto[];
      signatureMethod: DocumentSignerMethod;
    }): SignerDto[] => {
      return [
        ...(manualSigners?.map((s) => ({
          ...s,
          signatureMethod,
        })) ?? []),
        ...(internalSigners?.map((s) => {
          const signer = authState.selectedOrg.users.find((u) => u.person.id === s.value)?.person;
          if (!signer) throw Error("Signer not found");
          return {
            firstName: signer.firstName ?? "",
            lastName: signer.lastName ?? "",
            email: signer.email.trim(),
            signatureMethod,
          };
        }) ?? []),
        ...(counterpartySigners?.map((s) => {
          const signer = counterpartyPersons?.find((u) => u.id === s.value);
          if (!signer) throw Error("Signer not found");
          return {
            firstName: signer.firstName ?? "",
            lastName: signer.lastName ?? "",
            email: signer.email.trim(),
            signatureMethod,
          };
        }) ?? []),
      ];
    },
    [authState.selectedOrg.users, counterpartyPersons]
  );

  const send = async ({
    mainDocumentId,
    attachments,
    internalSigners,
    counterpartySigners,
    manualSigners,
    deadline,
    signatureMethod,
  }: FormValues) => {
    setIsLoading(true);
    await sendForSigning({
      mainDocumentId,
      attachments,
      signers: mapSigners({ internalSigners, counterpartySigners, manualSigners, signatureMethod }),
      deadline,
    });
    setIsLoading(false);
  };

  const mainDocumentId = methods.watch("mainDocumentId");
  const manualSigners = methods.watch("manualSigners");
  const internalSigners = methods.watch("internalSigners");
  const counterpartySigners = methods.watch("counterpartySigners");

  const allSignersAreValid =
    manualSigners?.every(
      (e) => isEmail(e.email.trim()) && e.firstName.trim().length > 1 && e.lastName.trim().length > 1
    ) ?? true;

  if (error) return <Text>{t("Something went wrong")}...</Text>;

  if (isLoadingContract && !data) return skeletons.stackedLines(0, 5);

  return (
    <Box backgroundColor="smBackgroundSecondary" pt="5" px="5" rounded="md">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(send)}>
          <FormControl id="documentToBeSigned" isInvalid={methods.formState.errors.mainDocumentId !== undefined} pb="5">
            <FormLabel>{t("Document to be signed")}</FormLabel>
            <Controller
              name="mainDocumentId"
              control={methods.control}
              render={({ field: { onChange, value } }) => {
                const selectedOption =
                  documents.find((d) => d.id === value) ?? textDocuments?.find((t) => t.id === value);
                return (
                  <SingleSelector
                    size="sm"
                    value={selectedOption ? { label: selectedOption.name, value } : null}
                    options={[
                      ...documents.map((d) => ({ label: d.name, value: d.id })),
                      ...(textDocuments?.map((d) => ({ label: d.name, value: d.id })) ?? []),
                    ]}
                    onChange={(value) => onChange(value?.value)}
                    placeholder={{
                      text: t("Select document"),
                      color: "inherit",
                    }}
                    noOptionsProvidedMessage={t("No documents have been uploaded") ?? ""}
                    noMatchingOptionsMessage={t("No matching documents") ?? ""}
                  />
                );
              }}
            />
            <FormErrorMessage>
              {methods.formState.errors.mainDocumentId && methods.formState.errors.mainDocumentId.message}
            </FormErrorMessage>
          </FormControl>
          {mainDocumentId && (
            <>
              <FormControl id="attachments" isInvalid={methods.formState.errors.attachments !== undefined} pb="5">
                <FormLabel>{t("Attachments")}</FormLabel>
                <Controller
                  name="attachments"
                  control={methods.control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <MultiSelector
                        size="sm"
                        value={value}
                        options={[
                          ...documents.map((e) => ({ label: e.name, value: e.id })),
                          ...(textDocuments?.map((d) => ({ label: d.name, value: d.id })) ?? []),
                        ].filter((d) => d.value !== mainDocumentId)}
                        onChange={(value) => onChange(value)}
                        placeholder={{ text: t("Select document"), color: "inherit" }}
                        noOptionsAvailableMessage={t("No matching documents") ?? ""}
                      />
                    );
                  }}
                />
                <FormErrorMessage>
                  {methods.formState.errors.attachments && methods.formState.errors.attachments.message}
                </FormErrorMessage>
              </FormControl>
              {sub.hasBankIdAccess && (
                <FormControl
                  id="signatureMethod"
                  isInvalid={methods.formState.errors.signatureMethod !== undefined}
                  pb="5"
                >
                  <FormLabel>{t("Signature method")}</FormLabel>
                  <Controller
                    name="signatureMethod"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <SingleSelector
                          size="sm"
                          value={{ value, label: signatureMethods[value] }}
                          options={Object.keys(signatureMethods).map((value) => ({
                            label: signatureMethods[value as DocumentSignerMethod],
                            value,
                          }))}
                          onChange={(value) => onChange(value?.value)}
                          placeholder={{
                            text: t("Select signature method"),
                            color: "inherit",
                          }}
                        />
                      );
                    }}
                  />
                  <FormErrorMessage>
                    {methods.formState.errors.signatureMethod && methods.formState.errors.signatureMethod.message}
                  </FormErrorMessage>
                </FormControl>
              )}
              <FormControl
                id="internalSigners"
                isInvalid={methods.formState.errors.internalSigners !== undefined}
                pb="5"
              >
                <FormLabel>{t("Internal signers")}</FormLabel>
                <Controller
                  name="internalSigners"
                  control={methods.control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <MultiSelector
                        size="sm"
                        value={value}
                        options={authState.selectedOrg.users.map((u) => ({
                          label: `${u.person.firstName ?? ""} ${u.person.lastName ?? ""}`.trim(),
                          value: u.person.id,
                        }))}
                        onChange={(value) => onChange(value)}
                        placeholder={{ text: t("Select internal signers"), color: "inherit" }}
                        noOptionsAvailableMessage={t("No matching users") ?? ""}
                      />
                    );
                  }}
                />
                <FormErrorMessage>
                  {methods.formState.errors.internalSigners && methods.formState.errors.internalSigners.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="counterpartySigners"
                isInvalid={methods.formState.errors.counterpartySigners !== undefined}
                pb="5"
              >
                <FormLabel>{t("Counterparty signers")}</FormLabel>
                <Controller
                  name="counterpartySigners"
                  control={methods.control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <MultiSelector
                        size="sm"
                        value={value}
                        options={
                          counterpartyPersons?.map((u) => ({
                            label: `${u.firstName ?? ""} ${u.lastName ?? ""}`.trim(),
                            value: u.id,
                          })) ?? []
                        }
                        onChange={(value) => onChange(value)}
                        placeholder={{ text: t("Select counterparty signers"), color: "inherit" }}
                        noOptionsAvailableMessage={t("No matching users") ?? ""}
                      />
                    );
                  }}
                />
                <FormErrorMessage>
                  {methods.formState.errors.counterpartySigners && methods.formState.errors.counterpartySigners.message}
                </FormErrorMessage>
              </FormControl>
              <FormLabel htmlFor="signedDocuments" pb="2">
                {t("Other signers")}
              </FormLabel>
              <InvitedSigners />
              <FormLabel htmlFor="deadline">{t("Deadline")}</FormLabel>
              <Controller
                name="deadline"
                control={methods.control}
                render={({ field: { onChange, value } }) => {
                  return <DateTimeSelector defaultDate={value} onChange={onChange} />;
                }}
              />
              <Flex justifyContent={"end"} mt="30px">
                <Explanation
                  fontSize="sm"
                  enabled={!allSignersAreValid}
                  text={t(
                    `Make sure all signers have a valid email, first name and last name before sending the document for signing.`
                  )}
                >
                  <Button
                    type="submit"
                    variant={"solid"}
                    size="sm"
                    colorScheme={"blue"}
                    isLoading={isLoading}
                    isDisabled={
                      !allSignersAreValid ||
                      !(manualSigners?.length || internalSigners?.length || counterpartySigners?.length)
                    }
                    mb="5"
                    leftIcon={<Icon as={FaPaperPlane} w="15px" h="15px" />}
                  >
                    {t("Send out for signing")}
                  </Button>
                </Explanation>
              </Flex>
            </>
          )}
        </form>
      </FormProvider>
    </Box>
  );
};
