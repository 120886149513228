import { t } from "i18next";
import { useCallback } from "react";
import { ContractNotificationDurationType } from "../../autogen/bff-api";

export const useNotificationLeadTimeConfig = () => {
  const displayNotificationTypeConfig = useCallback(
    (durationType?: ContractNotificationDurationType, amount?: number): string => {
      if (!durationType || amount === undefined) throw Error("Invalid leadtime config!");
      switch (durationType) {
        case "Days":
          return `${amount} ${t("days before")}`;
        case "Months":
          return `${amount} ${t("months before")}`;
      }
    },
    []
  );

  return displayNotificationTypeConfig;
};
