import { Box, Flex } from "@chakra-ui/react";
import Color from "@tiptap/extension-color";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { BubbleMenu, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { debounce } from "lodash";
import { useCallback, useEffect } from "react";
import { MenuBar } from "./MenuBar";
import "./TipTap.scss";

export const extensions = [
  StarterKit,
  Underline,
  TextStyle,
  Color,
  Highlight.configure({ multicolor: true }),
  Link.configure({
    openOnClick: true,
    autolink: true,
    linkOnPaste: true,
    defaultProtocol: "https",
  }),
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
];

export const parseTiptapContent = (value?: string) => {
  try {
    return JSON.parse(value ?? "");
  } catch (e) {
    return value;
  }
};

export const TipTap = ({
  content,
  editable = true,
  onUpdate = () => {
    console.warn("To be implemented");
  },
  hasBubbleMenu = false,
  hasBorder = true,
  padding = "2",
  maxHeight = "96",
}: {
  content: string;
  editable?: boolean;
  onUpdate?: (value: string) => void | Promise<void>;
  hasBubbleMenu?: boolean;
  hasBorder?: boolean;
  padding?: string;
  maxHeight?: string;
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnUpdate = useCallback(debounce(onUpdate, 300), [onUpdate]);

  const editor = useEditor({
    extensions,
    content: parseTiptapContent(content),
    editable,
    onUpdate: ({ editor }) => debouncedOnUpdate(JSON.stringify(editor.getJSON())),
  });

  useEffect(() => {
    if (!editable) editor?.commands.setContent(parseTiptapContent(content));
  }, [content, editable, editor?.commands]);

  useEffect(() => {
    () => editor?.destroy();
  }, [editor]);

  return (
    <Flex
      flexDirection="column"
      border={hasBorder ? "1px solid" : ""}
      borderColor="smBorder"
      rounded="md"
      width="full"
      maxHeight={maxHeight}
    >
      {editor && (
        <>
          {editable ? (
            <Flex justifyContent={"center"} roundedTop="md" backgroundColor="smBackgroundSecondary" shadow="sm">
              <MenuBar editor={editor} />
            </Flex>
          ) : null}
          {hasBubbleMenu && (
            <BubbleMenu tippyOptions={{ duration: 100, maxWidth: "none" }} editor={editor}>
              <Flex justifyContent={"center"} backgroundColor={"smBackground"} rounded="md" shadow="md" p="2">
                <MenuBar editor={editor} />
              </Flex>
            </BubbleMenu>
          )}
          <Flex
            flexDirection="column"
            grow={1}
            minWidth="full"
            width="full"
            maxWidth="full"
            height="full"
            maxHeight="full"
            overflowX="hidden"
            overflowY="auto"
            p={padding}
          >
            <Box>
              <EditorContent editor={editor} />
            </Box>
          </Flex>
        </>
      )}
    </Flex>
  );
};
