import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Icon,
  Input,
  Select,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAward } from "react-icons/fa";
import {
  Currency,
  EventValueDto,
  useAwardToBidMutation,
  useListReceivedBseBidsQuery,
} from "../../../../../autogen/bff-api";
import { useApiError } from "../../../../../common/errors/useApiError";
import { TipTap } from "../../../../../common/input/TipTap/TipTap";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { setSourcingEvent } from "../../../../../common/redux/reducers/basicSourcingEventReducer";

interface Props {
  eventId: string;
}

export interface AwardBasicSourcingEventRequest {
  bidIds: string[];
  messageToWinners: string;
  messageToLosers: string;
  eventValue?: EventValueDto;
}

export const AwardBasicSourcingEvent = ({ eventId }: Props) => {
  const dispatch = useAppDispatch();
  const { data } = useListReceivedBseBidsQuery({ eventId });
  const [awardToBids, { isLoading }] = useAwardToBidMutation();
  const toast = useToast();
  const { t } = useTranslation();
  const apiErrorDisplayer = useApiError();

  const [awardRequest, setAwardRequest] = useState<AwardBasicSourcingEventRequest>({
    bidIds: [],
    messageToLosers: "",
    messageToWinners: "",
    eventValue: undefined,
  });

  const awardEvent = async () => {
    const result = await awardToBids({
      eventId: eventId,
      awardToBidRequest: awardRequest,
    });

    if ("data" in result) {
      dispatch(setSourcingEvent(result.data));
      toast({
        title: t("Award success!"),
        description: t("The event was awarded successfully"),
        status: "success",
      });
    } else {
      apiErrorDisplayer.trigger(result.error);
    }
  };

  return (
    <>
      <CheckboxGroup colorScheme="purple" value={awardRequest.bidIds}>
        <Stack direction="column" pb="5">
          {data?.bids?.map((bid) => (
            <Checkbox
              key={bid.id}
              p="2"
              value={bid.id}
              onChange={() => {
                setAwardRequest((req) => {
                  if (awardRequest.bidIds.indexOf(bid.id) === -1) return { ...req, bidIds: [...req.bidIds, bid.id] };
                  return { ...req, bidIds: req.bidIds.filter((id) => id !== bid.id) };
                });
              }}
            >
              {bid.owningOrganization.name}
            </Checkbox>
          ))}
        </Stack>
      </CheckboxGroup>
      <Flex flexDirection="column">
        <Text fontWeight="medium">{t("Event value")}</Text>
        <Flex>
          <Input
            type="number"
            value={awardRequest.eventValue?.value ?? ""}
            onChange={(e) => {
              const value = e.target.value === "" ? undefined : parseFloat(e.target.value);
              setAwardRequest((req) => ({
                ...req,
                eventValue: value ? { currency: req.eventValue?.currency ?? "NOK", value } : undefined,
              }));
            }}
          />
          <Select
            placeholder={t("Currency") ?? ""}
            ml="1"
            value={awardRequest.eventValue?.currency ?? "NOK"}
            onChange={(e) => {
              const currency = e.target.value as Currency;
              setAwardRequest((req) => ({
                ...req,
                eventValue: req.eventValue ? { currency, value: req.eventValue.value } : undefined,
              }));
            }}
          >
            <option key={"NOK"} value={"NOK"}>
              NOK
            </option>
            <option key={"USD"} value={"USD"}>
              USD
            </option>
          </Select>
        </Flex>
      </Flex>
      <Box pt="5">
        <Text fontWeight="medium">{t("Message to winners")}</Text>
        <TipTap content="" onUpdate={(value) => setAwardRequest((req) => ({ ...req, messageToWinners: value }))} />
        <Text fontSize="sm" color="gray.500">
          {t("Provide a message to the winners")}.
        </Text>
      </Box>
      <Box pt="5">
        <Text fontWeight="medium">{t("Message to losers")}</Text>
        <TipTap content="" onUpdate={(value) => setAwardRequest((req) => ({ ...req, messageToLosers: value }))} />
        <Text fontSize="sm" color="gray.500">
          {t("Provide a message to the losers")}.
        </Text>
      </Box>
      <Flex justifyContent="end" pt="5">
        <Button
          leftIcon={<Icon as={FaAward} width="4" height="4" />}
          colorScheme="purple"
          variant="solid"
          size="md"
          isLoading={isLoading}
          marginRight={1}
          onClick={() => awardEvent()}
        >
          {t("Award to selected winner(s)")}
        </Button>
      </Flex>
    </>
  );
};
