import { Flex, Spinner, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TipTap } from "../../../../../common/input/TipTap/TipTap";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { containsError } from "../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../useContractState";

export const Description = () => {
  const dispatch = useAppDispatch();
  const contractState = useContractState();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<string>();
  const { t } = useTranslation();
  const errorMessage = containsError(contractState, "EditDescription") ? t("Update failed") : undefined;

  const update = useCallback(
    async (value: string) => {
      setIsLoading(true);
      await dispatch(
        editContractThunk({
          command: {
            type: "EditDescription",
            value: value,
          },
        })
      );
      setIsLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    if (value === undefined && contractState.contract.description !== undefined) {
      setValue(contractState.contract.description);
    }
  }, [contractState.contract.description, value]);

  return (
    <>
      <Flex pb="2">
        <Text fontWeight={"medium"} fontSize={"medium"}>
          {t("Description")}
        </Text>
        {isLoading && <Spinner size="xs" ml="2" />}
      </Flex>
      {value === undefined ? null : <TipTap content={value ?? ""} onUpdate={update} />}
      <Text fontSize={"sm"} color={"red"} pt="2">
        {errorMessage}
      </Text>
    </>
  );
};
