import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../common/redux/hooks";
import { loadContractThunk } from "../../../common/redux/thunks/contract/load-contract-thunk";
import { EditContractForm } from "./EditContractForm";

export const EditContract = () => {
  const { contractId } = useParams();
  const dispatch = useAppDispatch();
  const [contractLoaded, setContractLoaded] = useState(false);
  const contractState = useAppSelector((state) => state.contract.state);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (!contractId) return;
      await dispatch(loadContractThunk({ contractId }));
      setContractLoaded(true);
    })();
  }, [contractId, dispatch]);

  if (!contractId) {
    return <div>{t("404 not found")}</div>;
  }

  if (contractState && contractLoaded) {
    return <EditContractForm />;
  } else {
    return <div>{t("Loading")}...</div>;
  }
};
