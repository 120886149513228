import { Box, Divider, Flex, Icon, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { PersonDto } from "../../../../autogen/bff-api";
import { displayPhoneNumber } from "../../../../common/formatting/displayPhoneNumber";
import { ProfileImageRounded } from "../../../sourcing-events/buyer/view-all/ProfileImageRounded";

interface Props {
  person: PersonDto;
}

export const InternalContractOwner = ({ person }: Props) => {
  const { t } = useTranslation();

  return (
    <Box padding="15px" paddingBottom={"20px"} w="100%">
      <Text fontWeight={"bold"} fontSize="sm" color="smMuted">
        {t("Internal contract owner")}
      </Text>
      <Divider marginTop={"5px"} marginBottom={"5px"} />
      <Flex mt="13px">
        <Flex>
          <ProfileImageRounded name={person.firstName} width="30px" backgroundColor="orange.500" fontSize={"xs"} />
        </Flex>
        <Flex justifyContent={"center"} mt="-1px" ml="7px">
          <Text fontSize={"xl"} fontWeight="semibold">
            {`${person.firstName} ${person.lastName}`}
          </Text>
        </Flex>
      </Flex>
      <Flex mt="15px">
        <Icon as={FaEnvelope} ml="7px" w="15px" h="15px" color="smMuted" />
        <Text fontSize={"sm"} color="smMuted" ml="17px" mt="-3px">
          {person.email}
        </Text>
      </Flex>
      {person.phoneNumber && (
        <Flex mt="15px">
          <Icon as={FaPhone} ml="7px" w="15px" h="15px" color="smMuted" />
          <Text fontSize={"sm"} color="smMuted" ml="17px" mt="-3px">
            {displayPhoneNumber(person.phoneNumber)}
          </Text>
        </Flex>
      )}
    </Box>
  );
};
