import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import { EmailInviteLanguage } from "../../../../../autogen/bff-api";
import { getPreferredLanguage } from "../../../../../common/local-storage/language";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { editBseThunk } from "../../../../../common/redux/thunks/basic-sourcing-event/edit-bse-thunk";
import { useConfirmationModal } from "../../../../../common/useConfirmationModal";
import { eventIsLive } from "../../../eventIsLive";
import { useBasicSourcingEventState } from "../../../useBasicSourcingEventState";
import { EmailInvitesTable } from "./EmailInvitesTable";

export const AddEmailInvite = () => {
  const dispatch = useAppDispatch();
  const eventState = useBasicSourcingEventState();
  const [email, setEmail] = useState<string>("");
  const [language, setLanguage] = useState<EmailInviteLanguage>(getPreferredLanguage());
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const confirmationModal = useConfirmationModal();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const value = eventState.emailInvites.value;
  let errorMessage = eventState.emailInvites.errorMessage;
  if (isInvalidEmail) {
    errorMessage = t("Please provide a valid email");
  }

  const add = async (value: { email: string; language: EmailInviteLanguage }) => {
    setIsLoading(true);
    await dispatch(
      editBseThunk({
        command: {
          type: "AddEmailInvite",
          email: value.email,
          language: value.language,
        },
      })
    );
    setIsLoading(false);
  };

  const remove = async (id: string) => {
    setIsLoading(true);
    await dispatch(
      editBseThunk({
        command: {
          type: "RemoveEmailInvite",
          value: id,
        },
      })
    );
    setIsLoading(false);
  };

  return (
    <>
      {confirmationModal.modal}
      <FormControl id="email" mt={8} h="100%" isInvalid={errorMessage !== null}>
        <FormLabel mt={8}>{t("Add emails for sending out invites")}</FormLabel>
        <Flex w="100%">
          <Flex flex={3}>
            <Input
              type={"email"}
              placeholder={t("Email address for invited supplier") ?? ""}
              mr="5px"
              _placeholder={{ color: "#a4b2c3" }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Flex>
          <Flex flex={2}>
            <Select
              placeholder={t("Email language") ?? ""}
              ml="3px"
              backgroundColor="smBackground"
              value={language}
              onChange={(e) => {
                setLanguage(e.target.value as EmailInviteLanguage);
              }}
            >
              <option key={"En"} value={"En"}>
                {t("English")}
              </option>
              <option key={"No"} value={"No"}>
                {t("Norwegian")}
              </option>
            </Select>
          </Flex>
          <Flex w="100px">
            <Button
              variant={"solid"}
              colorScheme="teal"
              ml="7px"
              w="100%"
              isLoading={isLoading}
              onClick={() => {
                const isValidEmail = isEmail(email);

                if (!isValidEmail) {
                  setIsInvalidEmail(true);
                  return;
                } else {
                  setIsInvalidEmail(false);
                }
                if (eventIsLive(eventState.event)) {
                  confirmationModal.show({
                    title: t("Confirm sending"),
                    description:
                      t("You are live, and an email invite wil be sent to") + ` ${email} ` + t("immediately"),
                    onConfirmed: async () => {
                      add({
                        email: email,
                        language: language,
                      });
                      setEmail("");
                    },
                    confirmButtonColor: "blue",
                    confirmButtonText: t("Send") ?? "",
                  });
                } else {
                  add({
                    email: email,
                    language: language,
                  });
                  setEmail("");
                }
              }}
            >
              {t("Add")}
            </Button>
          </Flex>
        </Flex>
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </FormControl>
      <Box mt="20px">
        <EmailInvitesTable
          invites={value}
          removeInvite={(inviteId) => {
            if (eventIsLive(eventState.event)) {
              confirmationModal.show({
                title: t("Are you sure?"),
                description: t("The invitation that has already been sent will no longer be valid"),
                onConfirmed: async () => {
                  remove(inviteId);
                },
                confirmButtonColor: "red",
                confirmButtonText: t("Remove") ?? "",
              });
            } else {
              remove(inviteId);
            }
          }}
        />
      </Box>
    </>
  );
};
