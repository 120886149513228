import { SerializedError, createAsyncThunk } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { EditProductGroupDto, ProductGroupDto, bffApi } from "../../../../autogen/bff-api";

export type EditProductGroupResponse =
  | {
      data: ProductGroupDto;
    }
  | {
      error: FetchBaseQueryError | SerializedError | null;
    };

export const editProductGroupThunk = createAsyncThunk(
  "products/group/edit",
  async (editProductGroupDto: EditProductGroupDto, { dispatch }): Promise<EditProductGroupResponse> => {
    return dispatch(bffApi.endpoints.editProductGroup.initiate({ editProductGroupDto }));
  }
);
