import { Link, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ContractState } from "../../../../common/redux/reducers/contractReducer";
import { requireDataFields } from "../../typeguards";
import { GenericDetailRow, GenericDetails } from "./GenericDetails";

interface Props {
  data: ContractState["contract"];
}

export const Other = ({ data }: Props) => {
  const { t } = useTranslation();
  const dataFields = requireDataFields(data);
  const rows: GenericDetailRow[] = [];
  const addedDataFields = dataFields.addedDataFields.map((e) => e.name);
  const noValueProvided = <Text fontStyle={"italic"}>{t("Not provided")}</Text>;

  const parseLink = (link: string) => {
    let url = dataFields.externalLink;
    const urlContainsHttp = link.startsWith("http");
    if (!urlContainsHttp) {
      url = "http://" + url;
    }

    return url;
  };

  if (addedDataFields.includes("InternalReferenceNumber")) {
    rows.push({
      type: "Data",
      name: t("Internal reference number"),
      value: dataFields.internalReferenceNumber ? <Text>{dataFields.internalReferenceNumber}</Text> : noValueProvided,
      displayBelow: true,
    });
  }
  if (addedDataFields.includes("ExternalLink")) {
    rows.push({
      type: "Data",
      name: t("External link"),
      value: dataFields.externalLink ? (
        <Link color="blue.500" href={parseLink(dataFields.externalLink)} target={"_blank"}>
          {dataFields.externalLink}
        </Link>
      ) : (
        noValueProvided
      ),
      displayBelow: true,
    });
  }

  if (addedDataFields.includes("SupplierInfo")) {
    rows.push({
      type: "Data",
      name: t("Is climate committed?"),
      value: dataFields.supplierInfo ? (
        <Text>{dataFields.supplierInfo.isClimateCommitted ? t("Yes") : t("No")}</Text>
      ) : (
        noValueProvided
      ),
    });
    rows.push({
      type: "Data",
      name: t("Has signed code of conduct?"),
      value: dataFields.supplierInfo ? (
        <Text>{dataFields.supplierInfo.hasSignedCodeOfConduct ? t("Yes") : t("No")}</Text>
      ) : (
        noValueProvided
      ),
    });
    rows.push({
      type: "Data",
      name: t("Has customer contact?"),
      value: dataFields.supplierInfo ? (
        <Text>{dataFields.supplierInfo.hasCustomerContact ? t("Yes") : t("No")}</Text>
      ) : (
        noValueProvided
      ),
    });
  }

  if (addedDataFields.includes("SupplierCategory")) {
    rows.push({
      type: "Data",
      name: t("Supplier category"),
      value: dataFields.supplierCategory ? <Text>{dataFields.supplierCategory}</Text> : noValueProvided,
    });
  }

  return <GenericDetails title={t("Other")} rows={rows} />;
};
