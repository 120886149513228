import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { useMemo, useState } from "react";
import {
  ContractDto,
  SignedDocumentDto,
  SignerDto,
  TextDocumentDto,
  ViewDocumentAsOwnerDto,
  useCreateSignedDocumentMutation,
  useListTextDocumentsForContractQuery,
} from "../../../../autogen/bff-api";
import { TextDocumentModal } from "../../../../common/documents/TextDocumentModal";
import { useApiError } from "../../../../common/errors/useApiError";
import { SelectorValue } from "../../../../common/input/Selector/SelectorValue";
import { generateHTMLFromTextDocument } from "../../../../common/input/TipTap/downloadTextDocument";
import { TextDocumentTable } from "../../../../common/input/TipTap/TextDocumentTable";
import { DocumentSignersModal } from "../../edit/docs/signatures/DocumentSignersModal";
import { SendForSigningModal } from "../../edit/docs/signatures/SendForSigningModal";
import { requireDocumentFields } from "../../typeguards";
import { AttachmentsModal } from "./AttachmentsModal";
import { ContractDocumentRow } from "./ContractDocumentRow";
import { DocumentAnalysisModal } from "./DocumentAnalysisModal";

interface Props {
  data: ContractDto;
  reload: () => void;
}

export const Documents = ({ data, reload }: Props) => {
  const [documentToSign, setDocumentToSign] = useState<string>();
  const [documentForAnalysis, setDocumentForAnalysis] = useState<ViewDocumentAsOwnerDto | SignedDocumentDto>();
  const documentFields = requireDocumentFields(data);
  const [createSignedDocument] = useCreateSignedDocumentMutation();
  const toast = useToast();
  const displayer = useApiError();

  const hasSignatureAccess = useMemo(() => {
    return data.owningOrgHasSignatureAccess && data.canEditEverything;
  }, [data.canEditEverything, data.owningOrgHasSignatureAccess]);

  const signableDocuments = useMemo(() => {
    return documentFields.documents.filter((d) => d.fileExtension === ".pdf");
  }, [documentFields.documents]);

  const { data: textDocRes, isLoading: isLoadingTextDocs } = useListTextDocumentsForContractQuery({
    contractId: data.id,
  });
  const [textDocumentToView, setTextDocumentToView] = useState<TextDocumentDto>();
  const [documentForSignersModal, setDocumentForSignersModal] = useState<SignedDocumentDto>();
  const [documentForAttachmentsModal, setDocumentForAttachmentsModal] = useState<SignedDocumentDto>();

  const sendForSigning = async (values: {
    mainDocumentId: string;
    attachments?: SelectorValue[];
    signers?: SignerDto[];
    deadline?: string;
  }) => {
    if (!values.signers) throw new Error("Signers are not defined");

    const mainDocumentId = signableDocuments.find((s) => s.id === values.mainDocumentId)?.id;
    const textDocument = textDocRes?.documents.find((d) => d.id === values.mainDocumentId);

    const documentAttachments = signableDocuments?.filter((s) =>
      values.attachments?.map((a) => a.value).includes(s.id)
    );
    const textDocumentAttachments = textDocRes?.documents?.filter((d) =>
      values.attachments?.map((a) => a.value).includes(d.id)
    );

    const result = await createSignedDocument({
      contractId: data.id,
      createSignedDocumentRequest: {
        mainDocumentId,
        textDocumentInput: textDocument
          ? {
              textDocumentId: textDocument.id,
              htmlContent: generateHTMLFromTextDocument(textDocument.text),
            }
          : undefined,
        attachments: documentAttachments?.map((a) => a.id),
        textDocumentAttachments: textDocumentAttachments?.map((d) => ({
          textDocumentId: d.id,
          htmlContent: generateHTMLFromTextDocument(d.text),
        })),
        signers: values.signers,
        language: "No",
        deadline: values.deadline,
      },
    });
    if ("data" in result) {
      toast({
        title: t("Contract sent for signing"),
        status: "success",
      });
      reload();
      setDocumentToSign(undefined);
    } else displayer.trigger(result.error);
  };

  const documents: (ViewDocumentAsOwnerDto | SignedDocumentDto)[] = [
    ...documentFields.documents,
    ...documentFields.signedDocuments,
  ];

  return (
    <>
      {textDocumentToView && (
        <TextDocumentModal
          textDocument={textDocumentToView}
          onClose={() => setTextDocumentToView(undefined)}
          isUpdating={false}
          editable={false}
        />
      )}
      {documentForSignersModal && (
        <DocumentSignersModal
          contractId={data.id}
          signedDocument={documentForSignersModal}
          onClose={() => setDocumentForSignersModal(undefined)}
        />
      )}
      {documentForAttachmentsModal && (
        <AttachmentsModal
          signedDocument={documentForAttachmentsModal}
          onClose={() => setDocumentForAttachmentsModal(undefined)}
        />
      )}
      <Flex w="100%" flexDirection={"column"}>
        {documentToSign && (
          <SendForSigningModal
            contractId={data.id}
            mainDocumentId={documentToSign}
            textDocuments={textDocRes?.documents}
            documents={signableDocuments}
            sendForSigning={sendForSigning}
            onClose={() => setDocumentToSign(undefined)}
          />
        )}
        <Box borderRadius={10} width="full" border="1px solid" borderColor={"smBorder"} pb="2">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>{t("Name")}</Th>
                <Th></Th>
                <Th>{t("Uploaded by")}</Th>
                <Th>{t("Date")}</Th>
                <Th colSpan={2}></Th>
              </Tr>
            </Thead>
            <Tbody>
              <>
                {documents.map((e) => {
                  return (
                    <ContractDocumentRow
                      key={e.id}
                      contractDocument={e}
                      setDocumentForAnalysis={setDocumentForAnalysis}
                      setDocumentForSignersModal={setDocumentForSignersModal}
                      setDocumentForAttachmentsModal={setDocumentForAttachmentsModal}
                      setDocumentToSign={hasSignatureAccess ? setDocumentToSign : undefined}
                    />
                  );
                })}
                {!documents.length && (
                  <Tr>
                    <Td colSpan={5} width="full" textAlign="center" fontSize="small">
                      {t("No documents found")}
                    </Td>
                  </Tr>
                )}
              </>
            </Tbody>
          </Table>
        </Box>
        {documentForAnalysis && (
          <DocumentAnalysisModal document={documentForAnalysis} setDocumentForAnalysis={setDocumentForAnalysis} />
        )}
      </Flex>
      <Text fontSize={"md"} fontWeight="bold" pt="5" pb="3">
        {t("Text documents")}
      </Text>
      <TextDocumentTable
        isLoading={isLoadingTextDocs}
        documents={textDocRes?.documents}
        onClick={setTextDocumentToView}
        isDownloadable={true}
        setDocumentToSign={hasSignatureAccess ? setDocumentToSign : undefined}
      />
    </>
  );
};
