import { Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Spinner } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useListContractsQuery } from "../../../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../../../common/auth/useLoggedInWithOrgContextState";
import { MultiSelector } from "../../../../../../common/input/Selector/MultiSelector";
import { SelectorValue } from "../../../../../../common/input/Selector/SelectorValue";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../../useContractState";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const LinkedContacts = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const contractState = useContractState();
  const [isUpdating, setIsUpdating] = useState(false);
  const errorMessage = containsError(contractState, "EditLinkedContracts") ? t("Update failed") : null;
  const dispatch = useAppDispatch();
  const [contracts, setContracts] = useState<SelectorValue[]>([]);
  const authState = useLoggedInWithOrgContextState();

  const [searchName, setSearchName] = useState("");
  const [debouncedSearchName, setDebouncedSearchName] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchName = useCallback(
    debounce((name: string) => setDebouncedSearchName(name), 300),
    []
  );

  const { data, isLoading } = useListContractsQuery({
    orgId: authState.selectedOrg.id,
    state: "Published",
    title: debouncedSearchName ? debouncedSearchName : undefined,
    limit: 10,
  });

  useEffect(() => {
    if (contractState.contract.dataFields?.linkedContracts) {
      setContracts(
        contractState.contract.dataFields.linkedContracts.map((e) => ({
          label: e.title,
          value: e.id,
        }))
      );
    }
  }, []);

  const eventHandler = async (contractIds: string[]) => {
    setIsUpdating(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "EditLinkedContracts",
          value: contractIds,
        },
      })
    );
    setIsUpdating(false);
  };

  if (isLoading) return <div>{t("Loading...")}</div>;

  return (
    <FormControl isInvalid={errorMessage !== null} isRequired={isRequiredButNotProvided}>
      <FormLabel htmlFor={"value"}>
        {t("Linked contracts")} {isLoading || (isUpdating && <Spinner size="xs" />)}
      </FormLabel>
      <Flex width="full">
        <Flex rounded="md" backgroundColor="smBackground" width="full">
          <MultiSelector
            inputValue={searchName}
            value={contracts}
            options={
              data?.contracts
                .filter((e) => e.id !== contractState.contract.id)
                .map((e) => ({
                  label: e.title,
                  value: e.id,
                })) ?? []
            }
            noOptionsAvailableMessage={t("No contracts available")}
            onInputChange={(name: string) => {
              setSearchName(name);
              handleDebouncedSearchName(name);
            }}
            onChange={(value) => {
              setContracts(value);
              eventHandler(value.map((e) => e.value));
            }}
          />
        </Flex>
        <Flex>
          <RemoveDataFieldButton removeDataField={removeDataField} />
        </Flex>
      </Flex>

      <FormHelperText>{t("Provide linked contracts")}</FormHelperText>
      <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
