import { Button, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaCity } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { urls } from "../../../../urls";

export const Organizations = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Button
      leftIcon={<Icon as={FaCity} w="15px" h="15px" />}
      colorScheme="teal"
      variant="ghost"
      size={"sm"}
      marginRight={1}
      onClick={() => {
        navigate(urls.organizations.search);
      }}
    >
      {t("Companies")}
    </Button>
  );
};
