import { Button, Flex, Icon, IconButton, Td, Text, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";
import { FaEye, FaPaperclip, FaSignature } from "react-icons/fa";
import { PersonDto, SignedDocumentDto, ViewDocumentAsOwnerDto } from "../../../../autogen/bff-api";
import { DownloadDocumentIconButton } from "../../../../common/documents/DownloadDocumentIconButton/DownloadDocumentIconButton";
import { isSignable } from "../../../../common/documents/UppyUploader/FileRow";
import { displayDate } from "../../../../common/formatting/displayDate";
import { Logo } from "../../../../common/Logo";
import { useSub } from "../../../../common/subscription/useSub";
import { Explanation } from "../../../../common/support/Explanation/Explanation";
import { ProfileImageRounded } from "../../../sourcing-events/buyer/view-all/ProfileImageRounded";
import { SignedDocumentStatusTag } from "../../edit/docs/signatures/SignedDocumentStatusTag";
import { isDocument, isSignedDocument } from "./typeguards";

interface Props {
  contractDocument: ViewDocumentAsOwnerDto | SignedDocumentDto;
  setDocumentForAnalysis: Dispatch<SetStateAction<ViewDocumentAsOwnerDto | SignedDocumentDto | undefined>>;
  setDocumentForSignersModal: Dispatch<SetStateAction<SignedDocumentDto | undefined>>;
  setDocumentForAttachmentsModal: Dispatch<SetStateAction<SignedDocumentDto | undefined>>;
  setDocumentToSign?: (id: string) => Promise<void> | void;
}

export const ContractDocumentRow = ({
  contractDocument,
  setDocumentForAnalysis,
  setDocumentForSignersModal,
  setDocumentForAttachmentsModal,
  setDocumentToSign,
}: Props) => {
  const sub = useSub();

  const getDocumentName = (): string => {
    if (isSignedDocument(contractDocument)) return contractDocument.name;
    else if (isDocument(contractDocument)) return contractDocument.name;
    else throw new Error("No document name");
  };

  const getCreatedBy = (): PersonDto => {
    if (isSignedDocument(contractDocument)) return contractDocument.sentForSigningBy;
    else if (isDocument(contractDocument)) return contractDocument.uploadedBy;
    else throw new Error("No created by");
  };

  const uploadedBy = getCreatedBy();

  const showDownloadButton =
    isDocument(contractDocument) || (isSignedDocument(contractDocument) && contractDocument.status === "Signed");

  return (
    <Tr key={contractDocument.id}>
      <Td>
        <Text fontSize={"sm"}>{getDocumentName()}</Text>
      </Td>
      <Td>
        <Flex>
          {isSignedDocument(contractDocument) && <SignedDocumentStatusTag status={contractDocument.status} />}
          {isSignedDocument(contractDocument) && (
            <Flex pl="10px">
              <Button
                leftIcon={<Icon as={FaEye} w="15px" h="15px" />}
                size="xs"
                variant={"solid"}
                colorScheme="blue"
                onClick={() => setDocumentForSignersModal(contractDocument)}
              >
                {t("View signers")}
              </Button>
            </Flex>
          )}
        </Flex>
      </Td>
      <Td>
        <Flex alignItems="center">
          <Flex>
            <ProfileImageRounded
              name={uploadedBy.firstName}
              backgroundColor={"smPrimary"}
              width={"5"}
              fontSize="xx-small"
            />
          </Flex>
          <Flex mt="-2px" ml="5px">
            <Text fontSize={"sm"}>{`${uploadedBy.firstName} ${uploadedBy.lastName}`}</Text>
          </Flex>
        </Flex>
      </Td>
      <Td>
        <Text fontSize={"sm"}>{displayDate(contractDocument.uploadedAt)}</Text>
      </Td>
      <Td>
        <Flex>
          {showDownloadButton && (
            <Explanation text={t("Download document")} shouldWrapChildren>
              <DownloadDocumentIconButton
                documentId={contractDocument.id}
                isSignedDocument={isSignedDocument(contractDocument)}
              />
            </Explanation>
          )}
          {isSignedDocument(contractDocument) && contractDocument.attachments?.length ? (
            <IconButton
              variant="ghost"
              aria-label="see attachments"
              icon={<Icon as={FaPaperclip} />}
              onClick={() => setDocumentForAttachmentsModal(contractDocument)}
            />
          ) : null}
          {setDocumentToSign && !isSignedDocument(contractDocument) && (
            <Explanation
              text={
                isSignable(contractDocument)
                  ? t("Sign document")
                  : t("A document must be a valid pdf with less than 25 MB to be signed")
              }
            >
              <IconButton
                aria-label="sign document"
                variant="ghost"
                icon={<Icon as={FaSignature} />}
                isDisabled={!isSignable(contractDocument)}
                onClick={() => setDocumentToSign(contractDocument.id)}
              />
            </Explanation>
          )}
          {sub.hasAiAccess && (!isSignedDocument(contractDocument) || contractDocument.signingCompleted) && (
            <Explanation text={t("Chat with document")} shouldWrapChildren>
              <IconButton
                aria-label="analyze with AI"
                variant="ghost"
                color="smPrimary"
                icon={<Logo />}
                onClick={() => setDocumentForAnalysis(contractDocument)}
              />
            </Explanation>
          )}
        </Flex>
      </Td>
    </Tr>
  );
};
