import {
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface FormValues {
    organizationNumber: string
}

export const OrganizationNumber: React.FC = () => {
    const { t } = useTranslation()
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useFormContext<FormValues>()

    return (
        <FormControl
            isInvalid={errors.organizationNumber !== undefined}
            mt="10px"
        >
            <FormLabel htmlFor={'organizationNumber'}>
                {t('Organization number')}
            </FormLabel>
            <Input
                id={'organizationNumber'}
                type="organizationNumber"
                {...register('organizationNumber', {
                    required:
                        t(
                            'Please provide the company organization number (registration number)'
                        ) ?? '',
                    minLength: {
                        value: 2,
                        message: t('Must be at least 2 characters long'),
                    },
                })}
            />
            <FormErrorMessage>
                {errors.organizationNumber && errors.organizationNumber.message}
            </FormErrorMessage>
            <FormHelperText>
                {t(
                    'Please provide the company organization number (registration number)'
                )}
            </FormHelperText>
        </FormControl>
    )
}
