import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ContractState } from "../../../../common/redux/reducers/contractReducer";
import { ProfileImageRounded } from "../../../sourcing-events/buyer/view-all/ProfileImageRounded";
import { requireInternalParties } from "../../typeguards";

interface Props {
  data: ContractState["contract"];
}

export const InternalParties = ({ data }: Props) => {
  const { t } = useTranslation();

  const internalParties = requireInternalParties(data);

  return (
    <Box padding="15px" w="100%">
      <Text fontWeight={"bold"} fontSize="sm" color="#808B9B">
        {t("Internal parties")}
      </Text>
      <Divider marginTop={"5px"} marginBottom={"5px"} />
      {internalParties.parties.map((e) => (
        <Flex key={e.organizationId} mt="13px">
          <Flex>
            <ProfileImageRounded name={e.organizationName} width="30px" backgroundColor="blue.400" fontSize={"xs"} />
          </Flex>
          <Flex justifyContent={"center"} mt="-1px" ml="7px">
            <Text fontSize={"xl"} fontWeight="semibold">
              {e.organizationName}
            </Text>
          </Flex>
        </Flex>
      ))}
    </Box>
  );
};
