import { Box, Button, Icon, Td, Text, Tr } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";
import { ContractTemplateDto } from "../../../../autogen/bff-api";
import { displayDate } from "../../../../common/formatting/displayDate";
import { CustomContractFieldSections } from "../../../../common/types";
import { getDataFieldTranslationKey } from "../../edit/details/optional/DataFieldSelector";
import { useTemplateMeta } from "./useTemplateMeta";

interface Props {
  template: Omit<ContractTemplateDto, "requiredCustomDataFields"> & {
    requiredCustomDataFields: CustomContractFieldSections;
  };
  setTemplateToEdit: Dispatch<SetStateAction<ContractTemplateDto | undefined>>;
}

export const TemplateRow = ({ template, setTemplateToEdit }: Props) => {
  const { t } = useTranslation();
  const templateMeta = useTemplateMeta();
  return (
    <>
      <Tr key={template.id}>
        <Td fontSize={"sm"}>{templateMeta.getTemplateName(template)}</Td>
        <Td fontSize={"sm"}>{displayDate(template.createdAt)}</Td>
        <Td fontSize={"sm"}>
          {template.requiredFields.map((e) => (
            <Box key={e.name}>{t(getDataFieldTranslationKey(e.name))}</Box>
          ))}
          {Object.values(template.requiredCustomDataFields ?? {}).map((s) =>
            s.fields.length ? (
              <Box key={s.id}>
                <Text fontWeight={"bold"}>{s.name}</Text>
                {s.fields.map((f) => (
                  <Text key={f.id} size={"xs"}>
                    {f.name}
                  </Text>
                ))}
              </Box>
            ) : null
          )}
        </Td>
        <Td textAlign={"right"}>
          {!template.commonType && (
            <Button
              leftIcon={<Icon as={FaEdit} w="15px" h="15px" />}
              colorScheme="teal"
              variant="outline"
              size={"sm"}
              marginRight={1}
              onClick={() => setTemplateToEdit(template as unknown as ContractTemplateDto)}
            >
              {t("Edit")}
            </Button>
          )}
        </Td>
      </Tr>
    </>
  );
};
