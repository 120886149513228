import { useSearchParams } from "react-router-dom";

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return {
    setSelectedBseTab: (tab: BseTab) => setSearchParams(`?${new URLSearchParams({ tab })}`),
    setSelectedContractsTab: (tab: ContractsTab) => setSearchParams(`?${new URLSearchParams({ tab })}`),
    setSelectedProjectTab: (tab: ProjectTab) => setSearchParams(`?${new URLSearchParams({ tab })}`),
    setSelectedSettingsTab: (tab: SettingsTab) => setSearchParams(`?${new URLSearchParams({ tab })}`),
    setSelectedUserManagementTab: (tab: UserManagementTab) => setSearchParams(`?${new URLSearchParams({ tab })}`),
    setSelectedQuestion: (question: string) => setSearchParams(`?${new URLSearchParams({ tab: "qa", question })}`),
    setSelectedAnnouncement: (announcement: string) =>
      setSearchParams(`?${new URLSearchParams({ tab: "announcements", announcement })}`),
    setSelectedContractTask: (task: string) => setSearchParams(`?${new URLSearchParams({ tab: "tasks", task })}`),
    clearSelectedContractTask: () => setSearchParams(`?${new URLSearchParams({ tab: "tasks" })}`),
    clearSelectedQuestion: () => setSearchParams(`?${new URLSearchParams({ tab: "qa" })}`),
    clearSelectedAnnouncement: () => {
      setSearchParams(`?${new URLSearchParams({ tab: "announcements" })}`);
    },
    setCreateAnnouncement: () =>
      setSearchParams(`?${new URLSearchParams({ tab: "announcements", createannouncement: "true" })}`),
    get: (name: ParamNameWhiteList): string | null => searchParams.get(name),
    getContractsTab: (): ContractsTab => {
      const tab = searchParams.get("tab");
      if (tab === "documents" || tab === "reminders" || tab === "tasks" || tab === "sharing") return tab;
      return "info";
    },
    getEditContractStep: (): EditContractStep => {
      const step = searchParams.get("step");
      if (step === "details" || step === "docs" || step === "review") return step;
      return "details";
    },
    getProjectTab: (): ProjectTab | undefined => {
      const tab = searchParams.get("tab");
      if (tab === "documents" || tab === "products" || tab === "sourcing-events" || tab === "contracts") return tab;
      return "details";
    },
    getSettingsTab: (): SettingsTab | undefined => {
      const tab = searchParams.get("tab");
      if (tab === "document-templates" || tab === "internal-parties") return tab;
      return "notifications";
    },
  };
};

export type EditContractStep = "details" | "docs" | "review";

export type ParamNameWhiteList =
  | "tab"
  | "question"
  | "step"
  | "secret"
  | "type"
  | "announcement"
  | "task"
  | "flow"
  | "createannouncement"
  | "search";

export type BseTab = "info" | "qa" | "announcements" | "evaluation" | "reports";
export type ContractsTab = "info" | "documents" | "reminders" | "tasks" | "sharing";
export type UserManagementTab = "users" | "invites" | "azure" | "advanced" | "api";
export type ProjectTab = "details" | "documents" | "products" | "sourcing-events" | "contracts";
export type SettingsTab = "notifications" | "document-templates" | "internal-parties";
