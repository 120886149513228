import { Button, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AddEmailInvite } from "./AddEmailInvite";
import { AddOrganizationInvites } from "./AddOrganizationInvites";

export enum InviteType {
  REGISTERED_COMPANY = "REGISTERED_COMPANY",
  INVITE_BY_EMAIL = "INVITE_BY_EMAIL",
}

interface Props {
  nextStep: () => void;
  previousStep: () => void;
}

export const Parties = ({ nextStep, previousStep }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <AddOrganizationInvites />
      <AddEmailInvite />
      <Flex mt="40px">
        <Button variant={"outline"} mr="5px" w="100%" colorScheme={"teal"} onClick={previousStep}>
          {t("Previous")}
        </Button>
        <Button variant={"solid"} w="100%" colorScheme={"teal"} onClick={nextStep}>
          {t("Next")}
        </Button>
      </Flex>
    </>
  );
};
