import { t } from "i18next";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { ProjectStatus, useGetProjectsQuery } from "../../autogen/bff-api";
import { MultiSelector } from "../../common/input/Selector/MultiSelector";
import { SelectorValue } from "../../common/input/Selector/SelectorValue";

export const ProjectsSelector = ({
  selectedProjects,
  onChange,
  statuses = ["NotStarted", "Ongoing"],
}: {
  selectedProjects: SelectorValue[];
  onChange: (values: SelectorValue[]) => void | Promise<void>;
  statuses?: ProjectStatus[];
}) => {
  const [searchName, setSearchName] = useState("");
  const [debouncedSearchName, setDebouncedSearchName] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchName = useCallback(
    debounce((name: string) => setDebouncedSearchName(name), 300),
    []
  );

  const { data, isFetching: isLoadingProjects } = useGetProjectsQuery({
    name: debouncedSearchName ? debouncedSearchName : undefined,
    status: statuses.join(","),
    limit: 10,
  });

  return (
    <MultiSelector
      placeholder={{ text: `${t("Select projects")}...`, color: "" }}
      value={selectedProjects}
      inputValue={searchName}
      isLoading={isLoadingProjects}
      onInputChange={(name: string) => {
        setSearchName(name);
        handleDebouncedSearchName(name);
      }}
      options={
        data?.projects
          .filter((o) => !selectedProjects?.map((p) => p.value).includes(o.id))
          .map((o) => ({ label: `${o.externalId ? `${o.externalId} - ` : ""}${o.name}`, value: o.id })) ?? []
      }
      noOptionsAvailableMessage={t("No projects available")}
      onChange={onChange}
      searchValues={false}
    />
  );
};
