export const getOrganizationId = (): string | null => {
    return localStorage.getItem('organizationId')
}

export const setOrganizationId = (id: string) => {
    localStorage.setItem('organizationId', id)
}

export const clearOrganizationId = () => {
    localStorage.removeItem('organizationId')
}
