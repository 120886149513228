import { Box, Button, Flex, Grid, GridItem, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaFolderOpen, FaInfo, FaPaperPlane } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useQueryParams } from "../../../common/useQueryParams";
import { urls } from "../../../urls";
import { requireDataFields } from "../typeguards";
import { MissingDataFieldsBanner } from "./details/MissingDataFieldsBanner";
import { useContractState } from "./useContractState";

interface Props {
  navigateToDetails: () => void;
  navigateToDocs: () => void;
  navigateToReview: () => void;
  navigateToEvent?: () => void;
}

export const EditContractFormNavigation = ({
  navigateToDetails,
  navigateToDocs,
  navigateToReview,
  navigateToEvent,
}: Props) => {
  const params = useQueryParams();
  const currentStep = params.getEditContractStep();
  const { t } = useTranslation();
  const contractState = useContractState();
  const dataFields = requireDataFields(contractState.contract);
  const navigate = useNavigate();
  return (
    <Grid h="100%" gridTemplateColumns={"1fr"} gridTemplateRows={"1fr"}>
      <GridItem width="full" maxWidth={"full"} overflowX={"auto"}>
        <Flex width="full" maxWidth={"full"}>
          <Box width="full" maxWidth={"full"}>
            <Button
              leftIcon={<Icon as={FaInfo} w="15px" h="15px" />}
              colorScheme="teal"
              variant={currentStep === "details" ? "solid" : "outline"}
              size={"xs"}
              marginRight={1}
              w="100%"
              display={"flex"}
              justifyContent="start"
              onClick={navigateToDetails}
            >
              1. {t("Details")}
            </Button>
            <Button
              leftIcon={<Icon as={FaFolderOpen} w="15px" h="15px" />}
              colorScheme="teal"
              variant={currentStep === "docs" ? "solid" : "outline"}
              size={"xs"}
              marginRight={1}
              w="100%"
              display={"flex"}
              justifyContent="start"
              mt="1.5"
              onClick={navigateToDocs}
            >
              2. {t("Documents")}
            </Button>
            <Button
              leftIcon={<Icon as={FaPaperPlane} w="15px" h="15px" />}
              colorScheme="teal"
              variant={currentStep === "review" ? "solid" : "outline"}
              size={"xs"}
              marginRight={1}
              w="100%"
              display={"flex"}
              justifyContent="start"
              mt="1.5"
              onClick={navigateToReview}
            >
              3. {t("Review")}
            </Button>
            {contractState.contract.state === "Published" && (
              <Button
                leftIcon={<Icon as={FaArrowLeft} w="15px" h="15px" color="smMuted" />}
                colorScheme="gray"
                color="smMuted"
                variant={"solid"}
                size={"xs"}
                marginRight={1}
                w="100%"
                display={"flex"}
                justifyContent="start"
                mt="1.5"
                onClick={() => {
                  navigate(urls.contracts.viewSingle.go(contractState.contract.id));
                }}
              >
                {t("Back to contract")}
              </Button>
            )}
            {dataFields.missingDataFields.length > 0 && (
              <MissingDataFieldsBanner
                missingDataFields={dataFields.missingDataFields}
                missingCustomFields={dataFields.missingCustomFields}
              />
            )}
            {navigateToEvent && (
              <Button
                leftIcon={<Icon as={FaArrowLeft} w="15px" h="15px" color="#586173" />}
                colorScheme="gray"
                color="#586173"
                variant={"solid"}
                size={"xs"}
                marginRight={1}
                w="100%"
                display={"flex"}
                justifyContent="start"
                mt="1.5"
                onClick={navigateToEvent}
              >
                {t("Back to sourcing event")}
              </Button>
            )}
          </Box>
        </Flex>
      </GridItem>
    </Grid>
  );
};
