import { Box, Checkbox, Input, Td, Textarea, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { debounce, isBoolean } from "lodash";
import { useCallback, useState } from "react";
import { v4 as uuid } from "uuid";
import { NumberFieldDto, Populater } from "../../../../autogen/bff-api";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { setProduct } from "../../../../common/redux/reducers/basicBidReducer";
import { editBasicBidThunk } from "../../../../common/redux/thunks/basic-bid/edit-basic-bid-thunk";
import { Product, ProductField, ProductFieldType, ProductTemplateField } from "../../../../common/types";

const initiateUnitPrice = (): NumberFieldDto => ({
  id: uuid(),
  name: "Unit Price",
  isRequired: false,
  populatedBy: "Supplier",
  value: undefined,
});

export const ProductRowForm = ({
  groupId,
  product,
  productFields,
}: {
  groupId: string;
  product: Product;
  productFields: ProductTemplateField[];
}) => {
  const dispatch = useAppDispatch();

  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = ({
    id,
    name,
    type,
    isRequired,
    populatedBy,
    value,
  }: {
    id: string;
    name: string;
    type: ProductFieldType;
    value: string | boolean;
    isRequired: boolean;
    populatedBy: Populater;
  }) => {
    const productFields = [...product.productFields];
    const fieldIndex = product.productFields.findIndex((f) => f.name === name && f.populatedBy === populatedBy);
    productFields.splice(fieldIndex, 1, {
      id,
      name,
      type,
      isRequired,
      populatedBy,
      value: parseInputValue(value, type),
    });
    dispatch(
      setProduct({
        groupId,
        product: {
          id: product.id,
          quantity: product.quantity,
          unitPrice: product.unitPrice ?? initiateUnitPrice(),
          productFields: productFields.map((f) => ({ ...f, value: f.value ?? undefined })),
        },
      })
    );
    debouncedEditProductBid({ productFields, unitPrice: product.unitPrice ?? initiateUnitPrice() });
  };

  const handleUnitPriceChange = (value?: number) => {
    const unitPrice = { ...(product.unitPrice ?? initiateUnitPrice()), value };
    dispatch(
      setProduct({
        groupId,
        product: { ...product, unitPrice },
      })
    );
    debouncedEditProductBid({ unitPrice, productFields: product.productFields });
  };

  const editProductBid = useCallback(
    ({ productFields, unitPrice }: { productFields: ProductField[]; unitPrice: NumberFieldDto }) => {
      dispatch(
        editBasicBidThunk({
          command: {
            type: "EditProducts",
            value: {
              products: [
                {
                  productGroupId: groupId,
                  productBids: [
                    {
                      productId: product.id,
                      unitPrice,
                      productFields: productFields
                        .filter((f) => f.populatedBy === "Supplier")
                        .map((field) => ({ ...field, value: field.value ? field.value : undefined })),
                    },
                  ],
                },
              ],
            },
          },
        })
      );
    },
    [dispatch, groupId, product.id]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedEditProductBid = useCallback(
    debounce(
      ({ productFields, unitPrice }: { productFields: ProductField[]; unitPrice: NumberFieldDto }) =>
        editProductBid({ productFields, unitPrice }),
      500
    ),
    [editProductBid]
  );

  const parseInputValue = (value: string | boolean, type: ProductField["type"]) => {
    if (type === "Number") return value === "" ? "" : Number(value);
    if (type === "Boolean") return Boolean(value);
    else return value;
  };

  return (
    <Tr maxWidth={"full"} overflow={"hidden"}>
      {productFields
        .filter((f) => f.populatedBy === "Buyer")
        .map((field) => (
          <Td key={field.id} position={"relative"} verticalAlign={"top"}>
            <Box
              maxHeight={isExpanded ? "none" : "10"}
              overflow={"hidden"}
              cursor={"pointer"}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {field.type === "Boolean" ? (
                <Checkbox
                  isDisabled={true}
                  isChecked={!!product.productFields.find((f) => f.name === field.name)?.value}
                />
              ) : (
                <Box as="pre" fontFamily={"body"} whiteSpace={"pre-wrap"}>
                  {product.productFields.find((f) => f.name === field.name)?.value}
                </Box>
              )}
            </Box>
            <Box
              width={"full"}
              position={"absolute"}
              top="5"
              bottom="0"
              left="0"
              bgGradient={isExpanded ? "" : "linear(to bottom, transparent, smBackground)"}
              pointerEvents={"none"}
            ></Box>
          </Td>
        ))}
      <Td textAlign={"center"} verticalAlign={"top"}>
        {product.quantity.value}
      </Td>
      <Td textAlign={"center"} verticalAlign={"top"}>
        <Input
          size="xs"
          minWidth={"24"}
          rounded="md"
          type={"number"}
          placeholder={`${t("Enter price")}...`}
          value={product.unitPrice?.value ?? ""}
          onChange={(e) => handleUnitPriceChange(e.target.value === "" ? undefined : Number(e.target.value))}
        />
      </Td>
      {product.productFields
        .filter((f) => f.populatedBy === "Supplier")
        .map((field) => (
          <Td key={field.id} textAlign={"center"} verticalAlign={"top"} p="1">
            <Box maxHeight={isExpanded ? "none" : "10"} overflow={"hidden"} p="1">
              {field.type === "Boolean" ? (
                <Checkbox
                  colorScheme="teal"
                  py="2"
                  isChecked={isBoolean(field.value) ? field.value : false}
                  onChange={(e) =>
                    handleChange({
                      id: field.id,
                      name: field.name,
                      type: field.type,
                      value: e.target.checked,
                      isRequired: field.isRequired,
                      populatedBy: field.populatedBy,
                    })
                  }
                />
              ) : field.type === "Text" ? (
                <Textarea
                  size="xs"
                  minWidth={"24"}
                  rounded="base"
                  minHeight={"6"}
                  rows={1}
                  pt="1"
                  pb="0"
                  placeholder={`${t("Enter text")}...`}
                  isRequired={field.isRequired}
                  value={(field.value as string | number) ?? ""}
                  onChange={(e) =>
                    handleChange({
                      id: field.id,
                      name: field.name,
                      type: field.type,
                      value: e.target.value,
                      isRequired: field.isRequired,
                      populatedBy: field.populatedBy,
                    })
                  }
                />
              ) : (
                <Input
                  size="xs"
                  minWidth={"24"}
                  rounded="md"
                  type={"number"}
                  placeholder={`${t("Enter number")}...`}
                  isRequired={field.isRequired}
                  value={(field.value as string | number) ?? ""}
                  onChange={(e) =>
                    handleChange({
                      id: field.id,
                      name: field.name,
                      type: field.type,
                      value: e.target.value,
                      isRequired: field.isRequired,
                      populatedBy: field.populatedBy,
                    })
                  }
                />
              )}
            </Box>
          </Td>
        ))}
    </Tr>
  );
};
