import { Tag } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ContractStatusDto } from "../../../autogen/bff-api";

interface Props {
  status: ContractStatusDto;
  size?: "sm" | "md" | "lg";
}

export const ContractStatusTag = ({ status, size }: Props) => {
  const { t } = useTranslation();
  const getEventTagMeta = (
    status: ContractStatusDto
  ): {
    color: string;
    text: string;
  } => {
    switch (status) {
      case "Awarded":
        return {
          color: "purple",
          text: t("Awarded"),
        };
      case "Active":
        return {
          color: "green",
          text: t("Active"),
        };
      case "Expired":
        return {
          color: "orange",
          text: t("Expired"),
        };
      case "Terminated":
        return {
          color: "red",
          text: t("Terminated"),
        };
    }
  };

  const tagMeta = getEventTagMeta(status);

  return (
    <Tag
      variant="outline"
      colorScheme={tagMeta.color}
      size={size}
      textAlign="center"
      paddingTop={"2px"}
      paddingBottom={"2px"}
    >
      {tagMeta.text}
    </Tag>
  );
};
