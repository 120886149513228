import { Box, Flex, Grid, GridItem, Image, useColorMode } from "@chakra-ui/react";
import { assets } from "../../../assets/assets";
import { LoginCredentialsForm } from "../../../common/auth/LoginForm/LoginCredentialsForm";

export const Login = () => {
  const theme = useColorMode();

  return (
    <Box
      width="full"
      height="100vh"
      bgGradient="linear(to-br, smTealShadow, smBackground, smBackground, smBackground, smBlueShadow)"
    >
      <Grid
        gridTemplateColumns={"1fr 450px 1fr"}
        height="full"
        gridTemplateRows={"1fr auto 2fr"}
        templateAreas={`
                "left top right"
                "left login right"
                "left bottom right"
                `}
      >
        <GridItem area={"left"}></GridItem>
        <GridItem area={"top"}></GridItem>
        <GridItem area={"bottom"}></GridItem>
        <GridItem area={"right"}></GridItem>
        <GridItem
          area={"login"}
          borderRadius={10}
          bg="smBackground"
          boxShadow="0 9px 46px 8px rgb(0,0,0,0.2)"
          padding={10}
        >
          <Flex w="full" h="full" flexDirection="column">
            <Flex pb="10">
              <Image src={theme.colorMode === "dark" ? assets.logo_dark : assets.logo} alt="Logo" h="30px" mt="10px" />
            </Flex>
            <LoginCredentialsForm invitationContext={null} />
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};
