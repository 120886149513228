import { Flex, Icon, IconButton, Select, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaCheck, FaPen } from "react-icons/fa";
import { BseAnnouncementDto, BseQuestionDto, BseQuestionStatus } from "../../../../../autogen/bff-api";
import { displayDateWithTime } from "../../../../../common/formatting/displayDateWithTime";
import { useAppDispatch, useAppSelector } from "../../../../../common/redux/hooks";
import { setSupplierQuestionStatus } from "../../../../../common/redux/reducers/basicSourcingEventReducer";
import { Explanation } from "../../../../../common/support/Explanation/Explanation";
import { QuestionStatusTag } from "./QuestionStatusTag";

interface Props {
  canChangeQuestionStatus: boolean;
  question: BseQuestionDto;
  announcements: BseAnnouncementDto[];
  changeStatus: (status: BseQuestionStatus) => Promise<boolean>;
}

interface FormValues {
  status: BseQuestionStatus;
}

export const SupplierQuestionMeta = ({ canChangeQuestionStatus, question, changeStatus }: Props) => {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const sourcingEvent = useAppSelector((state) => state.basicSourcingEvent.state);

  const [isLoading, setIsLoading] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const { register, handleSubmit } = useForm<FormValues>({ defaultValues: { status: question.status } });

  return (
    <form
      onSubmit={handleSubmit(async (values: FormValues) => {
        setIsLoading(true);
        const result = await changeStatus(values.status);
        setIsLoading(false);
        if (result && sourcingEvent) {
          setEditStatus(false);
          toast({ title: t("Status changed!"), status: "success" });
          dispatch(setSupplierQuestionStatus({ question, status: values.status }));
        } else {
          toast({
            title: t("Could not change status!"),
            status: "error",
          });
        }
      })}
    >
      <Flex borderRadius={"10px"} w="100%">
        <Flex w="100%" alignItems={"center"}>
          <Text pr="2" fontSize={"sm"}>
            {t("Asked by")}{" "}
            <b>
              {question.askedByPerson.firstName} {question.askedByPerson.lastName}
            </b>
            , {displayDateWithTime(question.askedAt, t("at"))}
          </Text>
          {editStatus ? (
            <>
              <Select {...register("status")} bg="smBackground" size={"sm"} width={"40"} rounded={"md"}>
                <option key={"Unresolved"} value={"Unresolved"}>
                  {t("Unresolved")}
                </option>
                <option key={"Resolved"} value={"Resolved"}>
                  {t("Resolved")}
                </option>
              </Select>
              <IconButton
                aria-label="save status"
                icon={<Icon as={FaCheck} />}
                variant={"outline"}
                size="sm"
                colorScheme={"teal"}
                type="submit"
                isLoading={isLoading}
              />
            </>
          ) : (
            <>
              <QuestionStatusTag status={question.status} size="sm" />
              <Explanation
                enabled={!canChangeQuestionStatus}
                text={t("Only admins and creators can change the status for questions")}
              >
                <IconButton
                  aria-label="change status"
                  icon={<Icon as={FaPen} />}
                  variant={"ghost"}
                  size="sm"
                  isDisabled={!canChangeQuestionStatus}
                  colorScheme={"blackAlpha"}
                  onClick={() => setEditStatus(true)}
                >
                  {t("Change")}
                </IconButton>
              </Explanation>
            </>
          )}
        </Flex>
      </Flex>
    </form>
  );
};
