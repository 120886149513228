import { Button, Flex, Icon, Td, Text, Tr, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { ContractNotificationDto, useUpdateContractMutation } from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { useDisplayNotificationEvent } from "../../../../common/hooks/useContractNotificationName";
import { useNotificationLeadTimeConfig } from "../../../../common/hooks/useNotificationLeadTimeConfig";

interface Props {
  contractId: string;
  onRemoved: () => Promise<void>;
  notification: ContractNotificationDto;
}

export const ContractNotificationRow = ({ contractId, onRemoved, notification }: Props) => {
  const { t } = useTranslation();
  const [update] = useUpdateContractMutation();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const displayer = useApiError();

  const displayNotificationEvent = useDisplayNotificationEvent();
  const displayNotificationTypeConfig = useNotificationLeadTimeConfig();

  const deleteNotification = async () => {
    setIsLoading(true);
    const result = await update({
      contractId: contractId,
      updateContractRequest: {
        removeNotification: notification.id,
      },
    });
    if ("data" in result) {
      await onRemoved();
      toast({
        title: t("Notification removed"),
        status: "success",
      });
    } else {
      displayer.trigger(result.error);
    }
    setIsLoading(false);
  };

  return (
    <Tr key={notification.id}>
      <Td>
        <Text fontSize={"sm"}>{displayNotificationEvent(notification.type)}</Text>
      </Td>
      <Td>
        <Text fontSize={"sm"}>
          {displayNotificationTypeConfig(
            notification.inAdvanceConfig?.durationType,
            notification.inAdvanceConfig?.amount
          )}
        </Text>
      </Td>
      <Td>
        {notification.receivers.map((e) => (
          <Text key={e.id} fontSize={"sm"}>
            {e.email}
          </Text>
        ))}
      </Td>
      <Td>
        <Flex justifyContent="end">
          <Button
            leftIcon={<Icon as={FaTimes} w="15px" h="15px" />}
            colorScheme="red"
            variant="outline"
            size={"xs"}
            isLoading={isLoading}
            marginRight={1}
            onClick={deleteNotification}
          >
            {t("Delete")}
          </Button>
        </Flex>
      </Td>
    </Tr>
  );
};
