import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { t } from "i18next";
import { ReactElement, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  ContractDto,
  ContractRoleType,
  MinimalOrgDto,
  PersonDto,
  useCreateContractParticipantMutation,
  useGetOrganizationQuery,
} from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../common/errors/useApiError";
import { RadioFormSelector } from "../../../../common/input/Radio/RadioFormSelector";
import { OrganizationFormSelector } from "../../../../common/input/Selector/OrganizationFormSelector";
import { SelectorValue } from "../../../../common/input/Selector/SelectorValue";
import { SingleFormSelector } from "../../../../common/input/Selector/SingleFormSelector";

interface Props {
  data: ContractDto;
  onClose: () => void;
}

interface FormValues {
  organization?: MinimalOrgDto;
  person?: SelectorValue;
  roleType: ContractRoleType;
  shouldNotifyByEmail: boolean;
}

export const AddExternalParticipantModal = ({ data, onClose }: Props) => {
  const toast = useToast();
  const displayer = useApiError();
  const authState = useLoggedInWithOrgContextState();

  const [createParticipant] = useCreateContractParticipantMutation();

  const methods = useForm<FormValues>({
    defaultValues: { roleType: "Editor", shouldNotifyByEmail: true },
  });
  const [isLoading, setIsLoading] = useState(false);

  const minimalOrg = methods.watch("organization");

  const { data: organization, isFetching: isLoadingOrganization } = useGetOrganizationQuery(
    minimalOrg?.id ? { orgId: minimalOrg.id } : skipToken
  );

  const create = async (values: FormValues) => {
    if (!values.organization) {
      toast({ title: t("Please select an organization"), status: "error" });
      return;
    }
    setIsLoading(true);
    const result = await createParticipant({
      contractId: data.id,
      createContractParticipantRequest: {
        organizationId: values.organization.id,
        personId: values.person?.value,
        roleType: values.roleType,
        shouldNotifyByEmail: values.shouldNotifyByEmail,
      },
    });
    if ("data" in result) {
      toast({ title: t("Participant added!"), status: "success" });
      onClose();
    } else displayer.trigger(result.error);
  };

  const options: {
    label: string;
    description: ReactElement;
    value: ContractRoleType;
  }[] = [
    {
      label: t("Editor"),
      description: (
        <Text fontSize="sm" color="smMuted">
          {t("Can see and edit everything")}.
        </Text>
      ),
      value: "Editor",
    },
    {
      label: t("Viewer"),
      description: (
        <Text fontSize="sm" color="smMuted">
          {t("Can see everything, but not edit anything")}.
        </Text>
      ),
      value: "Viewer",
    },
    {
      label: t("Counterparty"),
      description: (
        <>
          <Text fontSize="sm" color="smMuted">
            {t("Can see all details, data-fields and documents")}.
          </Text>{" "}
          <Text fontSize="sm" color="smMuted">
            {t("Can see and edit all tasks that are delegated to them")}.
          </Text>
        </>
      ),
      value: "Counterparty",
    },
  ];

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Share contract externally")}</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(create)}>
            <ModalBody>
              <OrganizationFormSelector
                type="Organization"
                label={t("Organization")}
                helperText={t("Please select an organization to share this contract with")}
                control={methods.control}
                name="organization"
                orgsToExclude={[authState.selectedOrg.id]}
              />
              <Box mt="30px">
                <SingleFormSelector<FormValues>
                  isDisabled={!organization || minimalOrg?.id !== organization?.id}
                  isLoading={isLoadingOrganization}
                  options={
                    organization && minimalOrg?.id === organization?.id
                      ? organization?.persons.map((p) => ({ label: displayPersonNameWithEmail(p), value: p.id }))
                      : []
                  }
                  size="sm"
                  label={t("Share with specific person in organization (optional)")}
                  control={methods.control}
                  name={"person"}
                  helperText={`${t("Select a person to share the contract with")}.`}
                  placeholderText={`${t("Select a person from the selected organization")}...`}
                  noMatchingOptionsMessage={t("No matching persons")}
                  noOptionsProvidedMessage={t("No persons available")}
                  errorMessage={undefined}
                />
              </Box>
              <Box mt="30px">
                <RadioFormSelector options={options} label={t("Role")} control={methods.control} name={"roleType"} />
              </Box>
              <FormControl pt="5">
                <Controller<FormValues>
                  name={"shouldNotifyByEmail"}
                  control={methods.control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox isChecked={!!value} name="shouldNotifyByEmail" onChange={onChange}>
                      {t("Send email notification to participant(s)")}
                    </Checkbox>
                  )}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose} isDisabled={isLoading}>
                {t("Cancel")}
              </Button>
              <Button
                type="submit"
                isDisabled={!methods.getValues().organization}
                variant="solid"
                colorScheme={"blue"}
                isLoading={isLoading}
              >
                {t("Add")}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

export const displayPersonNameWithEmail = (person: PersonDto): string => {
  return `${person.firstName} ${person.lastName} (${person.email})`;
};

export const displayPersonName = (person: PersonDto): string => {
  return `${person.firstName} ${person.lastName}`;
};
