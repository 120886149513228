import moment from "moment";

export const displayDateWithTimeAndTz = (props: {
    timestamp: string
    timezone: string
    at: string
}): string => {
    return `${moment(props.timestamp).format('DD MMM YYYY')} ${props.at} ${moment(
        props.timestamp
    ).format('HH:mm')} (${props.timezone})`
}