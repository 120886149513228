import { createAsyncThunk } from "@reduxjs/toolkit";
import { bffApi, EmailInviteContextDto } from "../../../../autogen/bff-api";
import { authService } from "../../../../config";
import { clearOrganizationId } from "../../../local-storage/selected-organization";
import { AuthState, LoginCredentials, PersonCredentials } from "../../reducers/authStateReducer";

export type SignupError =
  | "EmailAlreadyTaken"
  | "WeakPassword"
  | "UnknownError"
  | "CouldNotCreateUserInBff"
  | "PasswordBreached"
  | "SimilarToIdentifier";

export const signupAsPersonThunk = createAsyncThunk(
  "authState/signupAsPerson",
  async (
    props: {
      loginCredentials: LoginCredentials;
      personCredentials: PersonCredentials;
      invitationContext: EmailInviteContextDto | null;
    },
    { dispatch }
  ): Promise<AuthState> => {
    clearOrganizationId();
    const result = await authService.signup({
      email: props.loginCredentials.email,
      password: props.loginCredentials.password,
      firstName: props.personCredentials.firstName,
      lastName: props.personCredentials.lastName,
      roles: [],
    });

    switch (result) {
      case "EmailAlreadyTaken":
        return {
          type: "LoggedOut",
          isLoggingIn: false,
          loginError: null,
          isSigningUp: false,
          signupError: "EmailAlreadyTaken",
        };
      case "WeakPassword":
        return {
          type: "LoggedOut",
          isLoggingIn: false,
          loginError: null,
          isSigningUp: false,
          signupError: "WeakPassword",
        };
      case "PasswordBreached":
        return {
          type: "LoggedOut",
          isLoggingIn: false,
          loginError: null,
          isSigningUp: false,
          signupError: "PasswordBreached",
        };
      case "SimilarToIdentifier":
        return {
          type: "LoggedOut",
          isLoggingIn: false,
          loginError: null,
          isSigningUp: false,
          signupError: "SimilarToIdentifier",
        };
      case "Success": {
        // get me and login without org
        const meResponse = dispatch(bffApi.endpoints.getMe.initiate({}));
        meResponse.unsubscribe();
        const meResult = await meResponse;

        if ("data" in meResult && meResult.data) {
          return {
            type: "LoggedIn",
            me: meResult.data,
            organizations: [],
            invitationContext: props.invitationContext,
            isCreatingOrg: false,
            createOrgError: null,
          };
        } else {
          return {
            type: "LoggedOut",
            isLoggingIn: false,
            loginError: null,
            isSigningUp: false,
            signupError: "UnknownError",
          };
        }
      }
      case "Failure":
        return {
          type: "LoggedOut",
          isLoggingIn: false,
          loginError: null,
          isSigningUp: false,
          signupError: "UnknownError",
        };
    }
  }
);
