import { Button, Flex, FormControl, FormHelperText, FormLabel, Icon } from "@chakra-ui/react";
import { t } from "i18next";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { FaFileAlt } from "react-icons/fa";
import {
  ProjectDto,
  TextDocumentDto,
  useCreateProjectTextDocumentMutation,
  useDeleteTextDocumentMutation,
  useListTextDocumentsForProjectQuery,
  useUpdateTextDocumentMutation,
} from "../../../autogen/bff-api";
import { TextDocumentModal } from "../../../common/documents/TextDocumentModal";
import { UppyUploader } from "../../../common/documents/UppyUploader/UppyUploader";
import { useApiError } from "../../../common/errors/useApiError";
import { TextDocumentTable } from "../../../common/input/TipTap/TextDocumentTable";

export const Documents = ({ project, refetch }: { project: ProjectDto; refetch: () => void }) => {
  const displayer = useApiError();

  const [createTextDocument, { isLoading: isCreatingTextDocument }] = useCreateProjectTextDocumentMutation();
  const [updateTextDocument, { isLoading: isUpdatingTextDocument }] = useUpdateTextDocumentMutation();
  const [deleteTextDocument] = useDeleteTextDocumentMutation();

  const { data, isLoading: isLoadingTextDocuments } = useListTextDocumentsForProjectQuery({
    projectId: project.id,
  });

  const [textDocumentToEdit, setTextDocumentToEdit] = useState<TextDocumentDto>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateTextDocument = useCallback(
    debounce(
      ({
        textDocumentId,
        name,
        text,
        tags,
      }: {
        textDocumentId: string;
        name?: string;
        text?: string;
        tags?: string[];
      }) => {
        updateTextDocument({
          updateTextDocumentRequest: {
            id: textDocumentId,
            name,
            text,
            tags,
          },
        });
      },
      300
    ),
    []
  );

  return (
    <>
      {textDocumentToEdit && (
        <TextDocumentModal
          textDocument={textDocumentToEdit}
          onClose={() => setTextDocumentToEdit(undefined)}
          onUpdate={({ title, content, tags }: { title?: string; content?: string; tags?: string[] }) => {
            if (!textDocumentToEdit) throw Error("No text document id - could not edit");
            debouncedUpdateTextDocument({
              textDocumentId: textDocumentToEdit.id,
              name: title,
              text: content,
              tags,
            });
          }}
          isUpdating={isUpdatingTextDocument}
          editable={project.canEdit}
        />
      )}
      <UppyUploader
        uploadEntityType="Project"
        entityId={project.id}
        uploads={project.uploads ?? []}
        documents={project.documents ?? []}
        reloadEntity={refetch}
        target="projectFileTarget"
        helperText={t("Upload relevant project documents")}
        isDisabled={!project.canEdit}
      />
      {project.canEdit && (
        <FormControl pt={8}>
          <FormLabel>{t("Create document")}</FormLabel>
          <Flex flexDirection="column" alignItems="start">
            <Button
              colorScheme="teal"
              rightIcon={<Icon as={FaFileAlt} />}
              isLoading={isCreatingTextDocument}
              onClick={async () => {
                const res = await createTextDocument({
                  createProjectTextDocumentRequest: {
                    name: "",
                    text: "",
                    projectId: project.id,
                    tags: [],
                  },
                });
                if ("error" in res) displayer.show(res.error);
                else setTextDocumentToEdit(res.data);
              }}
            >
              {t("Create")}
            </Button>
            <FormHelperText>{t("Create a new document from scratch or based on templates")}.</FormHelperText>
          </Flex>
        </FormControl>
      )}
      <FormControl pt={8}>
        <FormLabel>{t("Text documents")}</FormLabel>
        <TextDocumentTable
          isLoading={isLoadingTextDocuments}
          isDownloadable
          documents={data?.documents}
          onClick={setTextDocumentToEdit}
          onDelete={
            project.canEdit
              ? async (id: string) => {
                  await deleteTextDocument({ id });
                }
              : undefined
          }
        />
      </FormControl>
    </>
  );
};
