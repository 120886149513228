import { t } from "i18next";
import { debounce, uniqBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { MinimalOrgDto, useSearchOrganizationsAndEntriesQuery } from "../../autogen/bff-api";
import { MultiSelector } from "../../common/input/Selector/MultiSelector";
import { SelectorValue } from "../../common/input/Selector/SelectorValue";

export const OrganizationsSelector = ({
  selectedOrganizations,
  onChange,
  size = "sm",
}: {
  selectedOrganizations: MinimalOrgDto[];
  onChange: ({ organizations }: { organizations?: MinimalOrgDto[] }) => void | Promise<void>;
  size?: "sm" | "md";
}) => {
  const [searchName, setSearchName] = useState("");
  const [debouncedSearchName, setDebouncedSearchName] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchName = useCallback(
    debounce((name: string) => setDebouncedSearchName(name), 300),
    []
  );

  const { data, isFetching } = useSearchOrganizationsAndEntriesQuery({ name: debouncedSearchName, limit: 10 });

  const organizationOptions = useMemo(
    () => uniqBy([...selectedOrganizations, ...(data?.organizations ?? [])], (o) => o.id),
    [data?.organizations, selectedOrganizations]
  );

  return (
    <MultiSelector
      size={size}
      placeholder={{ text: `${t("Select organizations")}...`, color: "" }}
      value={selectedOrganizations.map((o) => ({
        label: `${o.organizationNumber ? `${o.organizationNumber} - ` : ""}${o.name}`,
        value: o.id,
      }))}
      inputValue={searchName}
      isLoading={isFetching}
      onInputChange={(name: string) => {
        setSearchName(name);
        handleDebouncedSearchName(name);
      }}
      options={
        organizationOptions?.map((o) => ({
          label: `${o.organizationNumber ? `${o.organizationNumber} - ` : ""}${o.name}`,
          value: o.id,
        })) ?? []
      }
      noOptionsAvailableMessage={t("No organizations available")}
      onChange={(values: SelectorValue[]) => {
        const ids = values.map((v) => v.value);
        const organizations = organizationOptions?.filter((o) => ids.includes(o.id));
        onChange({ organizations });
      }}
      searchValues={false}
    />
  );
};
