import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay } from "@chakra-ui/react";
import { EmailInviteContextDto } from "../../../autogen/bff-api";
import { LoginCredentialsForm } from "../../../common/auth/LoginForm/LoginCredentialsForm";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  invitationContext: EmailInviteContextDto | null;
}

export const LoginDrawer = ({ isOpen, onClose, invitationContext }: Props) => {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} closeOnOverlayClick={false} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody>
          <LoginCredentialsForm invitationContext={invitationContext} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
