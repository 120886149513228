import { OrganizationRoleType } from "../../../../autogen/bff-api";
import { roles } from "./config";

export const getRoleName = (role: OrganizationRoleType): string =>
  roles.find((userRole) => userRole.value === role)?.label ?? "Unknown role";

export const organizationContractRoles: OrganizationRoleType[] = [
  "Owner",
  "Admin",
  "ContractCreator",
  "ContractViewer",
];

export const hasContractViewerAccess = (roles: OrganizationRoleType[]) =>
  roles.some((role) => organizationContractRoles.includes(role));
