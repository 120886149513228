import { Box, Flex, Skeleton } from "@chakra-ui/react";

export const ModalSkeleton = () => {
  return (
    <Flex flexDirection="column" align="center" width="full" p="2.5">
      <Skeleton
        width="40%"
        height="12"
        rounded="md"
        startColor="smBackgroundTertiary"
        endColor="smBackgroundSecondary"
      />
      <Box width="full">
        <Skeleton
          width="25%"
          height="6"
          rounded="md"
          startColor="smBackgroundTertiary"
          endColor="smBackgroundSecondary"
          mt="4"
        />
        <Skeleton
          width="full"
          height="10"
          rounded="md"
          startColor="smBackgroundTertiary"
          endColor="smBackgroundSecondary"
          mt="2"
        />
        <Skeleton
          width="50%"
          height="4"
          rounded="md"
          startColor="smBackgroundTertiary"
          endColor="smBackgroundSecondary"
          mt="2"
        />
      </Box>
      <Box width="full" pt="4">
        <Skeleton
          width="25%"
          height="6"
          rounded="md"
          startColor="smBackgroundTertiary"
          endColor="smBackgroundSecondary"
          mt="4"
        />
        <Skeleton
          width="full"
          height="10"
          rounded="md"
          startColor="smBackgroundTertiary"
          endColor="smBackgroundSecondary"
          mt="2"
        />
        <Skeleton
          width="50%"
          height="4"
          rounded="md"
          startColor="smBackgroundTertiary"
          endColor="smBackgroundSecondary"
          mt="2"
        />
      </Box>
      <Flex width="full" justify="end">
        <Skeleton
          width="30%"
          height="10"
          rounded="md"
          startColor="smBackgroundTertiary"
          endColor="smBackgroundSecondary"
        />
      </Flex>
    </Flex>
  );
};
