import { useAppSelector } from "../redux/hooks";

export const useLoggedOutState = () => {
  const authState = useAppSelector((state) => state.authState.state);

  if (!authState || authState.type !== "LoggedOut") {
    throw Error('"useLoggedOutState" can only be used in LoggedOut state');
  }

  return authState;
};
