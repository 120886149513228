import { Box, Button, Flex, Icon, Spinner, Table, Tbody, Td, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { ContractDto, ContractTaskDto, useListContractTasksQuery } from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { loadContractThunk } from "../../../../common/redux/thunks/contract/load-contract-thunk";
import { useQueryParams } from "../../../../common/useQueryParams";
import { CreateTaskModal } from "./CreateTaskModal";
import { TaskRow } from "./TaskRow";
import { ShowTaskModal } from "./show/ShowTaskModal";

interface Props {
  contract: ContractDto;
}

export const Tasks = ({ contract }: Props) => {
  const { data, error, isLoading } = useListContractTasksQuery({ contractId: contract.id });
  const displayer = useApiError();
  const dispatch = useAppDispatch();
  const [showCreate, setShowCreate] = useState(false);
  const params = useQueryParams();
  const [showTaskId, setShowTaskId] = useState<string>();
  const selectedTaskId = params.get("task");
  const toast = useToast();
  const [tasks, setTasks] = useState<ContractTaskDto[]>();

  useEffect(() => {
    if (selectedTaskId && tasks) {
      let found = false;

      tasks.forEach((e) => {
        if (e.id === selectedTaskId) {
          found = true;
          setShowTaskId(e.id);
        }
      });

      if (!found) {
        toast({
          title: t("Task not found"),
          description: t("The task you are looking for does not exist"),
          status: "warning",
        });
        params.clearSelectedContractTask();
      }
    }
  }, [params, selectedTaskId, tasks, toast]);

  useEffect(() => {
    if (data) setTasks(data.tasks);
  }, [data]);

  if (error) return displayer.show(error);

  return (
    <>
      {showCreate && <CreateTaskModal contract={contract} onClose={() => setShowCreate(false)} />}
      {showTaskId && (
        <ShowTaskModal
          taskId={showTaskId}
          onClose={() => {
            params.clearSelectedContractTask();
            setShowTaskId(undefined);
          }}
        />
      )}
      <Flex w="100%" flexDirection={"column"}>
        {contract.canCreateTasks && (
          <Flex w="100%" justifyContent={"end"} mb="25px">
            <Button
              leftIcon={<Icon as={FaPlus} w="15px" h="15px" />}
              variant="solid"
              size="sm"
              colorScheme="blue"
              onClick={() => setShowCreate(true)}
            >
              {t("New task")}
            </Button>
          </Flex>
        )}
        <Box borderRadius={10} width="full" border="1px solid" borderColor={"gray.200"} pb="2">
          {isLoading && (
            <Flex height={"40"} justifyContent={"center"} alignItems={"center"}>
              <Spinner />
            </Flex>
          )}
          {data && (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>{t("Title")}</Th>
                  <Th>{t("Due date")}</Th>
                  <Th>{t("Responsible")}</Th>
                  <Th>{t("Status")}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                <>
                  {data.tasks.map((e) => {
                    return (
                      <TaskRow
                        key={e.id}
                        task={e}
                        showTask={(e) => {
                          setShowTaskId(e);
                          params.setSelectedContractTask(e);
                        }}
                        onRemoved={async () => {
                          await dispatch(loadContractThunk({ contractId: contract.id }));
                        }}
                      />
                    );
                  })}
                  {data.tasks.length === 0 && (
                    <Tr>
                      <Td colSpan={4} width={"100%"} textAlign="center">
                        {t("No tasks added")}
                      </Td>
                    </Tr>
                  )}
                </>
              </Tbody>
            </Table>
          )}
        </Box>
      </Flex>
    </>
  );
};
