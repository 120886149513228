import { Badge, Flex, Grid, GridItem, Icon, Skeleton, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaBullhorn, FaInfo, FaQuestion } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { bffApi, useGetBseSupplierViewQuery, useMarkAsViewedMutation } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { isFetchBaseQueryError } from "../../../../common/errors/isFetchBaseQueryError";
import { useApiError } from "../../../../common/errors/useApiError";
import { Layout } from "../../../../common/layout/Layout";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { BseTab, useQueryParams } from "../../../../common/useQueryParams";
import { CONTENT_WIDTH } from "../../../../config";
import { Announcements } from "../../buyer/view-single/announcements/Announcements";
import { CompanyPitch } from "./CompanyPitch";
import { BseSupplierViewEventInfo } from "./EventInfo";
import { EventMeta } from "./EventMeta";
import { Questions } from "./questions/Questions";

export const ViewBseSupplierView = () => {
  const { eventId } = useParams();
  const authState = useLoggedInWithOrgContextState();
  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const params = useQueryParams();
  const tab = params.get("tab");
  const { t } = useTranslation();
  const displayer = useApiError();
  const [markAsViewed] = useMarkAsViewedMutation();
  const { data, error, isLoading } = useGetBseSupplierViewQuery(eventId ? { eventId } : skipToken);

  useEffect(() => {
    const run = async () => {
      if (data && data.stateName === "Published" && !data.hasRegisteredViewing) {
        await markAsViewed({
          organizationId: authState.selectedOrg.id,
          eventId: data.id,
        });
      }
    };

    run();
  }, [authState.selectedOrg.id, data, markAsViewed]);

  useEffect(() => {
    if (tab === "info") setTabIndex(0);
    else if (tab === "qa") setTabIndex(1);
    else if (tab === "announcements") setTabIndex(2);
  }, [tab]);

  if (error) {
    if (isFetchBaseQueryError(error) && error.status === 403) return displayer.showNoAccess();
    else return displayer.show(error);
  }

  const unresolvedQuestionsCount = data?.questions.filter((e) => e.status === "Unresolved").length;

  return (
    <Layout>
      <Grid
        height="100%"
        gap={1}
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"1fr"}
        templateAreas={`
                "leftContent content rightContent"
                `}
      >
        <GridItem area={"content"}>
          <Grid
            w="100%"
            gridTemplateColumns={"3fr 4fr 300px"}
            gridTemplateRows="auto auto auto auto auto minmax(0,1fr)"
            templateAreas={`
                        "intro intro companyInfo"
                        "intro intro companyInfo"
                        "intro intro companyInfo"
                        "intro intro companyInfo"
                        "content content content"
                        "content content content"
                        `}
            px="1"
          >
            {isLoading && (
              <>
                <GridItem area={"intro"} display={"flex"} flexDirection={"column"} px="2" pt="2">
                  <Flex flexDirection={"column"} rowGap={"2"} justifyContent={"space-between"}>
                    <Skeleton
                      width={"60"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"60"}
                      height="6"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                  </Flex>
                </GridItem>
                <GridItem area="companyInfo" px="2" pt="2">
                  <Skeleton
                    width={"full"}
                    height={"40"}
                    rounded="lg"
                    startColor="smBackgroundSecondary"
                    endColor="smBackgroundTertiary"
                  />
                </GridItem>
                <GridItem area={"content"} display={"flex"} flexDirection={"column"} px="2" pt="8">
                  <Flex columnGap={"2"} pt="2">
                    <Skeleton
                      width={"32"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"32"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"32"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"32"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"32"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                  </Flex>
                  <Flex flexDirection={"column"} rowGap={"4"} pt="2">
                    <Skeleton
                      width={"full"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"80%"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"20%"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"20%"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                    <Skeleton
                      width={"60%"}
                      height="8"
                      rounded="md"
                      startColor="smBackgroundSecondary"
                      endColor="smBackgroundTertiary"
                    />
                  </Flex>
                </GridItem>
              </>
            )}
            {data && (
              <>
                <GridItem area={"intro"} alignItems={"end"} paddingBottom={"18px"} paddingTop={"30px"}>
                  <EventMeta data={data} />
                </GridItem>
                <GridItem area={"companyInfo"} pt={"8"}>
                  <CompanyPitch organization={data.owningOrganization} />
                </GridItem>
                <GridItem area={"content"}>
                  <Tabs
                    mt="20px"
                    w="100%"
                    index={tabIndex}
                    onChange={(index) => {
                      setTabIndex(index);
                      params.setSelectedBseTab(indexToQueryValue(index));
                    }}
                    variant="line"
                    colorScheme="teal"
                  >
                    <TabList>
                      <Tab>
                        <Icon as={FaInfo} mr="1" />
                        {t("Event info")}
                      </Tab>
                      <Tab>
                        <Icon as={FaQuestion} mr="1" />
                        {t("My questions")} ({data.questions.length}){" "}
                        {unresolvedQuestionsCount && (
                          <Badge colorScheme="red" ml="6px">
                            {unresolvedQuestionsCount} {t("unresolved")}
                          </Badge>
                        )}
                      </Tab>
                      <Tab>
                        <Icon as={FaBullhorn} mr="1" />
                        {t("Announcements")} ({data.announcements.length})
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <BseSupplierViewEventInfo data={data} />
                      </TabPanel>
                      <TabPanel>
                        <Questions
                          data={data}
                          addComment={async (comment: string, questionId: string) => {
                            const response = dispatch(
                              bffApi.endpoints.createBseQuestionCommentAsSupplier.initiate({
                                eventId: data.id,
                                questionId: questionId,
                                createBseQuestionCommentRequest: {
                                  content: comment,
                                },
                              })
                            );
                            response.reset();
                            const result = await response;
                            if ("data" in result) return true;
                            else return false;
                          }}
                          createNewQuestion={async (values: {
                            subject: string;
                            content: string;
                            announcementId: string | null;
                          }) => {
                            const response = dispatch(
                              bffApi.endpoints.createBseQuestion.initiate({
                                eventId: data.id,
                                createBseQuestionRequest: {
                                  subject: values.subject,
                                  content: values.content,
                                  announcementId: values.announcementId ?? undefined,
                                },
                              })
                            );
                            response.reset();
                            const result = await response;
                            if ("data" in result) {
                              return true;
                            } else {
                              return false;
                            }
                          }}
                        />
                      </TabPanel>
                      <TabPanel>
                        <Announcements
                          canCreateAnnouncement={false}
                          announcements={data.announcements}
                          initialAnnouncementValues={null}
                        />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </GridItem>
              </>
            )}
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};

const indexToQueryValue = (index: number): BseTab => {
  if (index === 0) {
    return "info";
  } else if (index === 1) {
    return "qa";
  } else if (index === 2) {
    return "announcements";
  } else {
    throw Error("Invalid tab index");
  }
};
