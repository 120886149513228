import { Box, Button, FormControl, FormErrorMessage, FormLabel, NumberInput, NumberInputField } from "@chakra-ui/react";
import React, { useState } from "react";
import { FormState, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ContractDto } from "../../../../../../../autogen/bff-api";
import { DateFormControl } from "../../../../../../../common/input/DateTimeSelectors/DateFormControl";
import { useAppDispatch } from "../../../../../../../common/redux/hooks";
import { editContractThunk } from "../../../../../../../common/redux/thunks/contract/edit-contract-thunk";

interface FormValues {
  fromDate: string;
  percentageValue: number;
  contractId: string;
}

interface Props {
  contract: ContractDto;
  onCancel: () => void;
}

export const AddPercentageAdjustment: React.FC<Props> = ({ contract, onCancel }) => {
  const methods = useForm<FormValues>();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [dateErrorMsg, setDateErrorMsg] = useState<string | null>(null);

  const isInvalid = ({
    contract,
    formState,
    values,
  }: {
    contract: ContractDto;
    formState: FormState<FormValues>;
    values: FormValues;
  }): boolean => {
    if (formState.errors.percentageValue !== undefined) return true;
    if (isDateInvalid({ contract, values })) return true;
    return false;
  };

  const isDateInvalid = ({ contract, values }: { contract: ContractDto; values: FormValues }) => {
    const startDate = contract.dataFields?.ifrsValues?.startDate;
    const endDate = contract.dataFields?.endDate?.date;
    const adjustmentDate = values.fromDate;
    if (!adjustmentDate) {
      setDateErrorMsg(t("Adjustment date must be set"));
      return true;
    }
    if (startDate && adjustmentDate < startDate) {
      setDateErrorMsg(t("Adjustment date must be after contract start date"));
      return true;
    }
    if (endDate && endDate < adjustmentDate) {
      setDateErrorMsg(t("Adjustment date must be before contract end date"));
      return true;
    } else {
      setDateErrorMsg(null);
      return false;
    }
  };

  const handleSubmit = async ({
    contract,
    formState,
    values,
  }: {
    contract: ContractDto;
    formState: FormState<FormValues>;
    values: FormValues;
  }) => {
    if (isInvalid({ contract, formState, values })) return;
    await addPercentageAdjustment(values);
  };

  const addPercentageAdjustment = async (values: FormValues) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "AddPercentageAdjustment",
          value: {
            date: values.fromDate,
            value: values.percentageValue / 100,
          },
        },
      })
    );
    setIsLoading(false);
    onCancel();
  };

  return (
    <form
      onSubmit={methods.handleSubmit(() =>
        handleSubmit({ contract, formState: methods.formState, values: methods.getValues() })
      )}
    >
      <Box mt="15px">
        <DateFormControl
          id="month"
          isRequiredButNotProvided={false}
          helperText=""
          title={t("Month")}
          errorMessage={dateErrorMsg}
          onChange={(date) => {
            if (date) methods.setValue("fromDate", date);
          }}
        />
      </Box>
      <FormControl isInvalid={methods.formState.errors.percentageValue !== undefined}>
        <FormLabel mt="10px">{t("Value")}</FormLabel>
        <NumberInput mt="5px" id={"percentageValue"} w="100%" bg="smBackground">
          <NumberInputField
            autoComplete="off"
            placeholder={`${t("Value in")} %`}
            {...methods.register("percentageValue", {
              required: t("Please provide a percentage amount") ?? "",
              min: { value: 0, message: t("Please provide a positive number") },
            })}
          />
        </NumberInput>
        <FormErrorMessage>
          {methods.formState.errors.percentageValue && methods.formState.errors.percentageValue.message}
        </FormErrorMessage>
      </FormControl>
      <Button mt="25px" type="submit" variant={"solid"} colorScheme="teal" size={"md"} isLoading={isLoading}>
        {t("Add")}
      </Button>
      <Button
        mt="25px"
        ml="5px"
        variant={"solid"}
        colorScheme="orange"
        size={"md"}
        isLoading={isLoading}
        onClick={onCancel}
      >
        {t("Cancel")}
      </Button>
    </form>
  );
};
