import { Button, Icon, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useUpdateOrganizationMutation } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../common/errors/useApiError";
import { TipTap } from "../../../../common/input/TipTap/TipTap";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { OrgDto, updateSelectedOrg } from "../../../../common/redux/reducers/authStateReducer";

interface Props {
  currentValue: string;
}

export const ChangeAboutUs = ({ currentValue }: Props) => {
  const toast = useToast();
  const authState = useLoggedInWithOrgContextState();
  const [editOrg, { isLoading }] = useUpdateOrganizationMutation();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const displayer = useApiError();
  const [newValue, setNewValue] = useState<string>(currentValue);

  const updateOrg = async () => {
    setIsRefreshing(true);
    const response = await editOrg({
      orgId: authState.selectedOrg.id,
      editOrganizationRequest: {
        aboutUs: {
          value: newValue,
        },
      },
    });
    if ("data" in response) {
      dispatch(updateSelectedOrg(response.data as OrgDto));
    } else displayer.trigger(response.error);
    setIsRefreshing(false);
    toast({ title: t("About us has been updated!"), status: "success" });
  };
  return (
    <>
      <Text fontWeight="medium" pt="5">
        {t("About us")}
      </Text>
      <TipTap content={currentValue} onUpdate={(value) => setNewValue(value)} />
      {currentValue.trim() !== newValue.trim() && (
        <Button
          mt="10px"
          leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
          variant={"solid"}
          colorScheme="teal"
          size={"sm"}
          isLoading={isLoading || isRefreshing}
          onClick={updateOrg}
        >
          {t("Save change")}
        </Button>
      )}
    </>
  );
};
