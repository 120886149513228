import { Box, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Spinner } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoggedInWithOrgContextState } from "../../../../../../common/auth/useLoggedInWithOrgContextState";
import { MultiSelector } from "../../../../../../common/input/Selector/MultiSelector";
import { SelectorValue } from "../../../../../../common/input/Selector/SelectorValue";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../../useContractState";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const InternalParties = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const internalParties = useLoggedInWithOrgContextState().selectedOrg.internalParties ?? [];
  const contractState = useContractState();
  const [isLoading, setIsLoading] = useState(false);
  const errorMessage = containsError(contractState, "EditInternalParties") ? t("Update failed") : null;
  const dispatch = useAppDispatch();

  const selectedInternalParties = useMemo(
    () => contractState.contract.dataFields.internalParties,
    [contractState.contract.dataFields.internalParties]
  );
  const [selectedOrganizations, setSelectedOrganizations] = useState<SelectorValue[]>(() => {
    return selectedInternalParties?.parties?.map((o) => ({ label: o.organizationName, value: o.organizationId })) ?? [];
  });

  const eventHandler = async (orgIds: string[]) => {
    setIsLoading(true);
    const parties = orgIds.filter((id) =>
      internalParties
        ?.filter((o) => o.type === "Organization")
        .map((o) => o.id)
        ?.includes(id)
    );
    const selfRegisteredParties = orgIds.filter((id) =>
      internalParties
        ?.filter((o) => o.type === "OrganizationEntry")
        .map((o) => o.id)
        ?.includes(id)
    );
    await dispatch(
      editContractThunk({
        command: {
          type: "EditInternalParties",
          value: {
            parties,
            selfRegisteredParties,
          },
        },
      })
    );
    setIsLoading(false);
  };

  return (
    <FormControl isInvalid={errorMessage !== null} isRequired={isRequiredButNotProvided}>
      <FormLabel htmlFor={"value"}>
        {t("Internal parties")} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Flex w="100%">
        <Box rounded="md" bg="smBackground" width="full">
          <MultiSelector
            value={selectedOrganizations}
            options={internalParties?.map((o) => ({ label: o.name, value: o.id }))}
            noOptionsAvailableMessage={t("No contracts available")}
            onChange={(value) => {
              setSelectedOrganizations(value);
              eventHandler(value.map((e) => e.value));
            }}
          />
        </Box>
        <RemoveDataFieldButton removeDataField={removeDataField} />
      </Flex>
      <FormHelperText>{t("Provide one or more internal parties")}</FormHelperText>
      <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
