import { Button, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface Props {
  onSignup: () => void;
  onLogin: () => void;
}

export const SignupOrLogin = ({ onSignup, onLogin }: Props) => {
  const { t } = useTranslation();
  return (
    <Flex mt="25px" mb="10px" flexDirection={"column"}>
      <Flex h={10} w="100%">
        <Button variant={"solid"} colorScheme="teal" w="100%" onClick={onSignup}>
          {t("Sign up")}
        </Button>
      </Flex>
      <Flex mt="20px">
        <Text>
          {t("Or")}{" "}
          <Button variant={"link"} onClick={onLogin} colorScheme="blue" fontWeight={"bold"}>
            {t("log in singular")}
          </Button>{" "}
          {t("if you already have an account")}.
        </Text>
      </Flex>
    </Flex>
  );
};
