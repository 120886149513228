import { Button, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ContractTaskDto } from '../../../autogen/bff-api'
import { useLoggedInWithOrgContextState } from '../../../common/auth/useLoggedInWithOrgContextState'
import { urls } from '../../../urls'

interface Props {
    task: ContractTaskDto
    size?: 'sm' | 'md' | 'lg'
}

export const ContractOwner: React.FC<Props> = ({ task }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const authState = useLoggedInWithOrgContextState()

    return (
        <>
            {authState.selectedOrg.id === task.owningOrganizationId ? (
                <Text fontSize={'sm'}>{t('Current org')}</Text>
            ) : (
                <Button
                    variant="link"
                    mr={3}
                    size="sm"
                    colorScheme={'blue'}
                    onClick={() => {
                        navigate(
                            urls.organizations.profile.go(
                                task.owningOrganizationId
                            )
                        )
                    }}
                >
                    {task.owningOrganizationName}
                </Button>
            )}
        </>
    )
}
